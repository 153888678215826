import { Chip, Typography } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'
import {
  OutstandingLessonPlanRequestStatus,
  OutstandingRequestType,
  PaginationParameters,
} from '@polyu-dip/apis'
import { useComputed } from '@polyu-dip/helpers'
import { useOutstandingLessonPlanRequests } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  ConsultDetailDialog,
  ListPage,
  Tab,
  Table,
  Tabs,
  useRowCountState,
} from '../../components'
import { contentPaths } from '../../content-paths'
import { useOutstandingLessonPlanRequestColumn } from './use-lesson-plan-request-column'

const StyledChip = styled(Chip)`
  font-size: 14px;
  line-height: 120%;
  height: auto;
  margin-left: 12px;

  .MuiChip-label {
    padding: 0px 8px;
  }
`

const FlexRow = styled.div`
  display: flex;
  direction: row;
  white-space: nowrap;
`

const PAGINATION_LIMIT = 15

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

enum TabMenuEnum {
  PENDING = 'pending',
  FOLLOWING_UP = 'followingUp',
  COMPLETED = 'completed',
}

export const LessonPlanConsultRequestsManagement = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { renderColumn } = useOutstandingLessonPlanRequestColumn()

  const [activeTab, setActionTab] = useState<TabMenuEnum>(TabMenuEnum.PENDING)
  const [openConsultDetailDialog, setOpenConsultDetailDialog] = useState(false)
  const [targetRequestId, setTargetRequestId] = useState<string>()
  const [requestText, setRequestText] = useState<string>()
  const [requireObservation, setRequireObservation] = useState<boolean>()

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [pendingRequestSort, setPendingRequestSort] = useState<GridSortModel>()
  const [followingUpRequestSort, setFollowingUpRequestSort] =
    useState<GridSortModel>()
  const [completedRequestSort, setCompletedRequestSort] =
    useState<GridSortModel>()

  const { data: pendingRequests, isLoading: isPendingRequestsLoading } =
    useOutstandingLessonPlanRequests({
      status: [OutstandingLessonPlanRequestStatus.pending],
      type: [OutstandingRequestType.consultRequest],
      sort: pendingRequestSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      ...pagination,
    })

  const { data: followingUpRequests, isLoading: isFollowingUpRequestsLoading } =
    useOutstandingLessonPlanRequests({
      status: [OutstandingLessonPlanRequestStatus.followingUp],
      type: [OutstandingRequestType.consultRequest],
      sort: followingUpRequestSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      ...pagination,
    })

  const { data: completedRequests, isLoading: isCompletedRequestsLoading } =
    useOutstandingLessonPlanRequests({
      status: [OutstandingLessonPlanRequestStatus.completed],
      type: [OutstandingRequestType.consultRequest],
      sort: completedRequestSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      ...pagination,
    })

  const activeData = useComputed(
    () =>
      // eslint-disable-next-line no-nested-ternary
      activeTab === TabMenuEnum.PENDING
        ? pendingRequests
        : activeTab === TabMenuEnum.FOLLOWING_UP
        ? followingUpRequests
        : completedRequests,
    [activeTab, pendingRequests, followingUpRequests, completedRequests],
  )

  const isLoading = useComputed(
    () =>
      // eslint-disable-next-line no-nested-ternary
      activeTab === TabMenuEnum.PENDING
        ? isPendingRequestsLoading
        : activeTab === TabMenuEnum.FOLLOWING_UP
        ? isFollowingUpRequestsLoading
        : isCompletedRequestsLoading,
    [
      activeTab,
      isPendingRequestsLoading,
      isFollowingUpRequestsLoading,
      isCompletedRequestsLoading,
    ],
  )

  const computedOutstandingLessonPlanData = useComputed(() => {
    return (
      activeData?.data.map((it) => ({
        ...it,
        id: it.id,
        assignedToUserName: it.assignedToUserName ?? '---',
      })) ?? []
    )
  }, [activeData])

  const { rowCountState } = useRowCountState(activeData?.total ?? 0)

  const handleTabChange = useCallback((event: any, value: any) => {
    setActionTab(value)
    setPagination(DEFAULT_PAGINATION)
  }, [])

  const handleLetterOnClick = useCallback(
    (requestId: string, consultContent: string, isSiteVisitNeeded: boolean) => {
      setOpenConsultDetailDialog(true)
      setTargetRequestId(requestId)
      setRequestText(consultContent)
      setRequireObservation(isSiteVisitNeeded)
    },
    [],
  )

  return (
    <ListPage
      sectionTitleProps={{
        title: t('lessonPlanRequest.consultManagement.title', {
          pendingCount: activeData?.total ?? 0,
        }),
        color: 'orange',
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        $activeColor={'orange'}
        $activeIndicatorColor={'orange'}
      >
        <Tab
          value={TabMenuEnum.PENDING}
          label={
            <FlexRow>
              <Typography>
                {t('lessonPlanRequest.consultManagement.tabs.pending')}
              </Typography>
              <StyledChip
                label={pendingRequests?.total ?? 0}
                color={activeTab === TabMenuEnum.PENDING ? 'orange' : 'default'}
              />
            </FlexRow>
          }
          $width={320}
        />
        <Tab
          value={TabMenuEnum.FOLLOWING_UP}
          label={
            <FlexRow>
              <Typography>
                {t('lessonPlanRequest.consultManagement.tabs.followingUp')}
              </Typography>
              <StyledChip
                label={followingUpRequests?.total ?? 0}
                color={
                  activeTab === TabMenuEnum.FOLLOWING_UP ? 'orange' : 'default'
                }
              />
            </FlexRow>
          }
          $width={320}
        />
        <Tab
          value={TabMenuEnum.COMPLETED}
          label={
            <FlexRow>
              <Typography>
                {t('lessonPlanRequest.consultManagement.tabs.completed')}
              </Typography>
              <StyledChip
                label={completedRequests?.total ?? 0}
                color={
                  activeTab === TabMenuEnum.COMPLETED ? 'orange' : 'default'
                }
              />
            </FlexRow>
          }
          $width={320}
        />
      </Tabs>

      <Table
        columns={renderColumn('consult', activeTab, handleLetterOnClick)}
        rows={computedOutstandingLessonPlanData}
        pageSize={PAGINATION_LIMIT}
        loading={isLoading}
        paginationMode="server"
        page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
        hideFooterPagination={
          rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
        }
        rowCount={rowCountState}
        sortModel={
          // eslint-disable-next-line no-nested-ternary
          activeTab === TabMenuEnum.PENDING
            ? pendingRequestSort
            : activeTab === TabMenuEnum.FOLLOWING_UP
            ? followingUpRequestSort
            : completedRequestSort
        }
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastModifiedDateTime', sort: 'desc' }],
          },
        }}
        onSortModelChange={
          // eslint-disable-next-line no-nested-ternary
          activeTab === TabMenuEnum.PENDING
            ? setPendingRequestSort
            : activeTab === TabMenuEnum.FOLLOWING_UP
            ? setFollowingUpRequestSort
            : setCompletedRequestSort
        }
        onRowClick={({ row }) => {
          navigate(contentPaths.lessonPlanConsultRequest(row.id))
        }}
        onPageChange={(page) =>
          setPagination((old) => ({
            ...old,
            offset: page * PAGINATION_LIMIT,
          }))
        }
      />

      <ConsultDetailDialog
        open={openConsultDetailDialog}
        onClose={() => setOpenConsultDetailDialog(false)}
        requestId={targetRequestId}
        requestText={requestText}
        requireObservation={requireObservation}
        onSubmit={(requestId: string) => {
          navigate(contentPaths.lessonPlanConsultRequest(requestId))
        }}
      />
    </ListPage>
  )
})
