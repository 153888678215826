import { useState } from 'react'

export function useMasterDataManagementFilter() {
  const [inputTitle, setInputTitle] = useState<string>()

  return {
    inputTitle,
    setInputTitle,
  }
}
