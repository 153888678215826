export const attachment = {
  uploadByUrl: {
    sectionTitle: 'Insert Link',
    label:
      'If the uploaded file is larger than 500MB and is uploaded via One Drive or Google Drive, please fill in the download link here.',
    placeholder: 'http://www.example.com',
  },
  uploadFile: {
    sectionTitle: 'Add Attachment',
    title: 'Drag files here or click to upload',
    description: 'No more than {{maxSizeInMb}} MB file',
  },
  bulkDownload: {
    title: 'Download all',
    noAttachmentDownloaded: 'No files can be downloaded',
  },
  errorMessage: {
    popupReminder: {
      title: 'Allow pop-ups',
      content: 'Pop-ups should be allowed in order to download attachments',
    },
    invalidUrlFormat:
      'Please enter the correct download link with https:// included',
    uploadAttachment: {
      title: 'Failed to upload file',
      exceedMaxFileSize: {
        content:
          'The size of the file cannot be greater than {{maxSizeInMb}} MB',
      },
    },
    downloadAttachment: {
      title: 'Failed to download file ',
    },
  },
}
