import { Button, Grid, Pagination } from '@mui/material'
import { BaseEntity, LessonPlanExamplesResponsePayload } from '@polyu-dip/apis'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LessonPlanExampleCard } from './lesson-plan-example-card'

type Props = {
  computedLessonPlanData?: (LessonPlanExamplesResponsePayload & BaseEntity)[]
  totalPage: number
  page: number
  handleGridModePageOnChange: (value: number) => void
  handleOnItemClick: (lessonPlanId: string) => void
}

const Container = styled(Grid)`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding: ${({ theme }) => theme.spacings.general[4]}px;
`

const StyledNextButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
`

export const LessonPlanExamplesGridList = memo<Props>(
  ({
    computedLessonPlanData,
    totalPage,
    page,
    handleGridModePageOnChange,
    handleOnItemClick,
  }) => {
    const { t } = useTranslation()

    return (
      <Container container>
        <Grid container spacing={4} direction="row">
          {computedLessonPlanData?.map((lessonPlanData) => (
            <Grid key={lessonPlanData.id} item xs={3}>
              <LessonPlanExampleCard
                lessonPlanData={lessonPlanData}
                handleOnClick={handleOnItemClick}
              />
            </Grid>
          ))}
        </Grid>
        {totalPage > 1 && (
          <Grid item mt={4} xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Pagination
                  color="primary"
                  count={totalPage}
                  page={page}
                  onChange={(event, value) => handleGridModePageOnChange(value)}
                  hideNextButton
                />
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  onClick={() => {
                    if (page >= totalPage) return
                    handleGridModePageOnChange(page + 1)
                  }}
                >
                  <StyledNextButtonText>
                    {t('myLessonPlan.pagination.next')}
                  </StyledNextButtonText>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    )
  },
)
