import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'

export const LessonPlanPublishRequestModel = BaseEntityModel.named(
  'LessonPlanPublishRequestModel',
)
  .props({
    id: types.string,
    initiatedLessonPlanId: types.string,
    publishingLessonPlanId: types.string,
    status: types.union(
      types.literal('pending'),
      types.literal('cancelled'),
      types.literal('approved'),
      types.literal('rejected'),
      types.literal('completed'),
    ),
    publishedDateTime: types.maybe(types.string),
    isPublishing: types.boolean,
  })
  .extend(withUpdate)

export type LessonPlanPublishRequest = Instance<
  typeof LessonPlanPublishRequestModel
>
