import { Box, Button, Grid, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { File, FileSource } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Svg } from '../../../../assets'
import { SelectOption } from '../../../../components'
import { useLessonPlanAttachmentsDownload } from '../../../../hooks'
import { useBlobStorage } from '../../../../services'
import { useStores } from '../../../../stores'
import { sortByOrder } from '../../../../utilities'
import { useLessonPlanDetail } from '../../use-lesson-plan-detail-context'
import { ResourcesList } from './resources-list'

type AttachmentTabProps = {
  lessonPlanId: string
  printable?: boolean
}

export const AttachmentTab = observer<AttachmentTabProps>(
  ({ lessonPlanId, printable }) => {
    const { t } = useTranslation()
    const { masterDataStore } = useStores()
    const { lessonPlan } = useLessonPlanDetail()
    const { getDownloadUrl } = useBlobStorage()

    const handleDownloadAttachments =
      useLessonPlanAttachmentsDownload(lessonPlanId)

    const fileResources = useComputed(
      () =>
        lessonPlan.lessonPlanAttachments.filter(
          (attachment) => attachment.file?.source === FileSource.blobStorage,
        ),
      [lessonPlan.lessonPlanAttachments],
    )

    const linkResources = useComputed(
      () =>
        lessonPlan.lessonPlanAttachments
          .filter((attachment) => attachment.file?.source === FileSource.user)
          .slice()
          .sort(sortByOrder),
      [lessonPlan.lessonPlanAttachments],
    )

    const resourceTypeOptions = useComputed((): SelectOption[] => {
      return masterDataStore.resourceTypes.map((it) => ({
        value: it.id,
        label: it.displayName,
      }))
    }, [masterDataStore.resourceTypes])

    const handleOnFilenameClick = useCallback(
      (file?: File) => {
        if (file == null) {
          return
        }
        if (file.source == FileSource.user) {
          window.open(file.url, '_blank')
        } else {
          getDownloadUrl(file.id).then((downloadUrl: string) => {
            window.open(downloadUrl, '_blank')
          })
        }
      },
      [getDownloadUrl],
    )

    return (
      <Box>
        <Grid container justifyContent="flex-end" columnSpacing={3}>
          <Grid item mr="auto">
            <Typography fontWeight={600}>
              {t('lessonPlan.detail.general.link', {
                count: linkResources.length,
              })}
            </Typography>
          </Grid>
        </Grid>
        <ResourcesList
          printable={printable}
          resources={linkResources}
          handleOnFileNameClick={handleOnFilenameClick}
          resourceTypeOptions={resourceTypeOptions}
        />

        <Grid container justifyContent="flex-end" columnSpacing={3} mt={6}>
          <Grid item mr="auto">
            <Typography fontWeight={600}>
              {t('lessonPlan.detail.general.annex', {
                count: fileResources.length,
              })}
            </Typography>
          </Grid>
          {printable ? (
            <></>
          ) : (
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Button
                    color="sharpRed"
                    startIcon={<Svg.Download />}
                    onClick={handleDownloadAttachments}
                    disabled={fileResources.length == 0}
                  >
                    {t('attachment.bulkDownload.title')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <ResourcesList
          isFileResource
          printable={printable}
          resources={fileResources}
          handleOnFileNameClick={handleOnFilenameClick}
          resourceTypeOptions={resourceTypeOptions}
        />
      </Box>
    )
  },
)
