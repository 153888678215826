import { Box } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { RoleType, User } from '@polyu-dip/models'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDisplayName } from '../../services'
import { RadioGroup, TextField } from '../form'
import { TextWithTickIcon } from '../text'
import { TextFieldList } from '../text-field-list'
import { observer } from 'mobx-react-lite'

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palettes.general.white};
  border-radius: 20px;
  padding: 20px;
`

type UserProfileProps = {
  rolesOptions?: { label: string; value: string }[]
  isEditView: boolean
  handleRoleOnChange: (roleId: string) => void
  handleNameOnChange: (name: string) => void
} & User

export const UserProfile = observer<UserProfileProps>(
  ({
    rolesOptions,
    name,
    email,
    role,
    school,
    isEditView,
    handleRoleOnChange,
    handleNameOnChange,
  }) => {
    const { t } = useTranslation()
    const { getDisplayName } = useDisplayName()

    const [inputNameError, setInputNameError] = useState<string>()

    const isRenderSchoolRelated = useMemo(
      () => role?.label !== RoleType.diTeam && role?.label !== RoleType.expert,
      [role?.label],
    )

    const renderItems = useComputed(() => {
      return [
        {
          isRowDirection: true,
          label: t('users.fields.role'),
          valueMinHeight: 40,
          render:
            isEditView && rolesOptions != null ? (
              <RadioGroup
                defaultValue={role?.id}
                options={rolesOptions ?? []}
                row
                onChange={(e, value) => {
                  handleRoleOnChange(value)
                }}
              />
            ) : (
              <TextWithTickIcon title={role?.displayName ?? ''} />
            ),
        },
        ...(isRenderSchoolRelated
          ? [
              {
                isRowDirection: true,
                label: t('users.fields.educationLevel'),
                valueMinHeight: 40,
                render: (
                  <TextWithTickIcon
                    title={school?.educationLevel?.displayName ?? ''}
                  />
                ),
              },
            ]
          : [{}]),
        {
          label: t('users.fields.email'),
          valueMinHeight: 40,
          value: email,
        },
        ...(isRenderSchoolRelated
          ? [
              {
                label: t('schools.fields.name'),
                valueMinHeight: 40,
                value: getDisplayName(school?.name),
              },
            ]
          : [{}]),
        {
          label: t('users.fields.name'),
          valueMinHeight: 40,
          render: (
            <TextField
              placeholder={t('users.fields.name')}
              defaultValue={name}
              disabled={!isEditView}
              onChange={(event) => {
                if (event.target.value.length === 0) {
                  setInputNameError(t('error.required'))
                } else {
                  setInputNameError(undefined)
                }
                handleNameOnChange(event.target.value)
              }}
              error={inputNameError}
              isReserverForHelperText
            />
          ),
        },
      ]
    }, [
      t,
      isEditView,
      rolesOptions,
      role,
      isRenderSchoolRelated,
      school,
      email,
      getDisplayName,
      name,
      inputNameError,
      handleRoleOnChange,
      handleNameOnChange,
    ])

    return (
      <Container>
        <TextFieldList items={renderItems} />
      </Container>
    )
  },
)
