import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  ClassLevelResponsePayload,
  ClassLevelsQueryParameters,
  ClassLevelsResponsePayload,
  PostClassLevelPayload,
  PutClassLevelPayload,
} from './class-levels-api.type'

const PATH = '/class-levels'

export function getClassLevels(param?: ClassLevelsQueryParameters) {
  return ApiCore.primary.get<ClassLevelsResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}

export function postClassLevelBulk(payloads: PostClassLevelPayload[]) {
  return ApiCore.primary.post<ClassLevelResponsePayload>(`${PATH}/bulk`, {
    body: payloads,
  })
}

export function putClassLevelById(id: string, payload: PutClassLevelPayload) {
  return ApiCore.primary.put<ClassLevelResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}
