import { Grid } from '@mui/material'
import { memo } from 'react'
import { Text } from '../text'

type IndexedListItemProps = {
  index: number
  description: string
}

export const IndexedListItem = memo<IndexedListItemProps>(
  ({ index, description }) => {
    return (
      <Grid container flexDirection="row">
        <Grid item flexShrink={1}>
          <Text>{index + 1}.</Text>
        </Grid>
        <Grid item flexGrow={4} ml={3}>
          <Text>{description}</Text>
        </Grid>
      </Grid>
    )
  },
)
