import { useComputed } from '@polyu-dip/helpers'
import { useStores } from '../stores'
import { sortByOrder } from '../utilities'

export function useActiveDiStrategyMaster(selectedDiStrategyIds?: string[]) {
  const { masterDataStore } = useStores()

  const activeDiStrategies = useComputed(() => {
    return masterDataStore.diStrategies
      .slice()
      .filter(
        (diStrategy) =>
          (!diStrategy.isDisabled && !diStrategy.diStrategyClass?.isDisabled) ||
          selectedDiStrategyIds?.some(
            (selectedDiStrategyId) => selectedDiStrategyId == diStrategy.id,
          ),
      )
      .sort(sortByOrder)
  }, [masterDataStore.diStrategies])

  const activeDiStrategyClasses = useComputed(() => {
    const selectedDiStrategies = activeDiStrategies.filter((diStrategy) =>
      selectedDiStrategyIds?.some(
        (selectedDiStrategyId) => selectedDiStrategyId == diStrategy.id,
      ),
    )
    return masterDataStore.diStrategyClasses
      .slice()
      .filter(
        (diStrategyClass) =>
          !diStrategyClass.isDisabled ||
          selectedDiStrategies.some(
            (selectedDiStrategy) =>
              selectedDiStrategy.diStrategyClassId == diStrategyClass.id,
          ),
      )
      .sort(sortByOrder)
  }, [masterDataStore.diStrategyClasses])

  return { activeDiStrategyClasses, activeDiStrategies }
}
