import { observer } from 'mobx-react-lite'
import {
  CircularProgress,
  Grid,
  Stepper as MuiStepper,
  Typography,
} from '@mui/material'
import styled from 'styled-components'
import { StepIcon } from './step-icon'
import { SubStepIcon, SubStepItemProps } from './sub-step-icon'
import { useCallback } from 'react'

export type StepType = {
  id: number
  numberToDisplay?: number
  name: string
  label: string
  isFinished?: boolean
  subStepOf?: number
  subSteps?: SubStepItemProps[]
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const StyledMuiStepper = styled(MuiStepper)`
  width: 650px;

  .MuiStepLabel-label {
    margin-top: 4px;
    color: ${({ theme }) => theme.palettes.general.black};
  }
`

const Connector = styled.div`
  width: 50px;
  height: 4px;
  background-color: ${({ theme }) => theme.palettes.general.greys[4]};
  border-radius: 10px;
  position: absolute;
  left: 0px;
  top: 24px;
  transform: translate(-50%, -50%);
`

const StepperTitle = styled(Typography)`
  font-size: 14px;
  text-align: center;
`

type Props = {
  steps: StepType[]
  activeStep: number
  handleStepClick: (step: number) => void
  isNonLinear?: boolean
  isLoading?: boolean
  prevStep?: number
  completedSteps?: number[]
}

export const Stepper = observer<Props>(
  ({
    steps,
    activeStep,
    handleStepClick,
    isNonLinear,
    isLoading,
    prevStep,
    completedSteps,
  }) => {
    const isCompleted = useCallback(
      (currentStep: number) => {
        if (activeStep === currentStep || completedSteps == null) return false
        return completedSteps?.some((completed) => completed === currentStep)
      },
      [activeStep, completedSteps],
    )
    return (
      <Container>
        <StyledMuiStepper
          nonLinear={isNonLinear}
          alternativeLabel
          activeStep={activeStep}
          connector={<Connector />}
        >
          {steps.map((step, index) => (
            <Grid container key={index} direction="column" alignItems="center">
              <Grid>
                {step.subSteps != null ? (
                  <SubStepIcon
                    activeStep={activeStep}
                    stepId={step.id}
                    subSteps={step.subSteps}
                    handleStepClick={handleStepClick}
                    step={0}
                    icon={<CircularProgress />}
                    itemID={`${step.id + 1}`}
                    prevStep={prevStep}
                    isLoading={isLoading}
                  />
                ) : (
                  <StepIcon
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...step}
                    handleStepClick={handleStepClick}
                    itemID={`${step.id + 1}`}
                    id={`${step.id}`}
                    step={step.id}
                    active={step.id == activeStep}
                    icon={<CircularProgress />}
                    completed={isCompleted(step.id)}
                    prevStep={prevStep}
                    isLoading={isLoading}
                  />
                )}
              </Grid>
              <Grid>
                <StepperTitle fontWeight={400}>{step.label}</StepperTitle>
              </Grid>
            </Grid>
          ))}
        </StyledMuiStepper>
      </Container>
    )
  },
)
