import { Box, Button, Dialog, Grid, Typography } from '@mui/material'
import { LessonPlanConsult } from '@polyu-dip/models'
import {
  clearLessonPlansQueryCaches,
  useCreateLessonPlanComment,
  useCreateLessonPlanConsultRequestById,
} from '@polyu-dip/queries'
import { useQueryClient } from '@tanstack/react-query'
import {
  ChangeEvent,
  Fragment,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Svg } from '../../../assets'
import { Checkbox, TextField, useOverlay } from '../../../components'
import { useApiErrorHandle, useMasterData } from '../../../hooks'
import { useStores } from '../../../stores'
import { ConfirmationDialog } from './confirmation-dialog'

type Props = {
  lessonPlanId: string
  latestLessonPlanConsult?: LessonPlanConsult
  canConsult?: boolean
}

export const RequestConsultingButton = memo<Props>(
  ({ lessonPlanId, latestLessonPlanConsult, canConsult }) => {
    const isReadyOnly = useMemo(
      () =>
        latestLessonPlanConsult != null &&
        (latestLessonPlanConsult?.status == 'pending' ||
          latestLessonPlanConsult?.status == 'followingUp'),
      [latestLessonPlanConsult],
    )

    const [content, setContent] = useState<string | undefined>(
      isReadyOnly ? latestLessonPlanConsult?.content : undefined,
    )
    const [isSiteVisitNeeded, setIsSiteVisitNeeded] = useState<
      boolean | undefined
    >(isReadyOnly ? latestLessonPlanConsult?.isSiteVisitNeeded : false)

    const [visible, setVisible] = useState(false)
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

    const { t } = useTranslation()
    const { showSpinner, hideSpinner, showSnackbar } = useOverlay()
    const { standardErrorHandler } = useApiErrorHandle()
    const queryClient = useQueryClient()

    const { mutateAsync: createComment } = useCreateLessonPlanComment(
      useStores,
      lessonPlanId,
      {
        onSuccess() {
          showSnackbar({
            message: t(
              'lessonPlan.action.createLessonPlanConsultRequest.successMessage',
            ),
          })
          setContent('')
        },
        onError(error) {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.createLessonPlanConsultRequest.title',
            ),
          })
        },
      },
    )

    const { mutateAsync: createConsultRequest } =
      useCreateLessonPlanConsultRequestById(useStores, lessonPlanId, {
        onSuccess(res) {
          showSnackbar({
            message: t(
              'lessonPlan.action.createLessonPlanConsultRequest.successMessage',
            ),
          })
          createComment({
            content: content ?? '',
            LessonPlanCommentAttachments: [],
            lessonPlanConsultId: res.latestLessonPlanConsult.id,
          })
          clearLessonPlansQueryCaches(queryClient, res.id)
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.createLessonPlanConsultRequest.title',
            ),
          })
        },
      })

    const handleOnClose = useCallback(() => {
      setVisible(false)
      setOpenConfirmationDialog(false)
    }, [])

    const handleOnSubmit = useCallback(async () => {
      if (content == null || isSiteVisitNeeded == null) {
        return
      }
      try {
        showSpinner()
        await createConsultRequest({
          content: content,
          isSiteVisitNeeded: isSiteVisitNeeded,
        })
        handleOnClose()
      } finally {
        hideSpinner()
      }
    }, [
      content,
      createConsultRequest,
      handleOnClose,
      hideSpinner,
      isSiteVisitNeeded,
      showSpinner,
    ])

    const handleCheckboxOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsSiteVisitNeeded(checked)
      },
      [],
    )

    const { systemParameters } = useMasterData()

    const isRequestConsultingEnabledBySystem = useMemo(
      () =>
        systemParameters.some(
          (it) => it.name === 'consult-function-enabled' && it.value_Boolean,
        ),
      [systemParameters],
    )

    return (
      <Fragment>
        <Button
          startIcon={<Svg.Comment width={16} height={16} />}
          color="blue"
          onClick={() => setVisible(true)}
          disabled={
            lessonPlanId.length === 0 ||
            !canConsult ||
            !isRequestConsultingEnabledBySystem
          }
        >
          {t('lessonPlan.actions.requestConsulting')}
        </Button>
        <ConfirmationDialog
          onClose={() => setOpenConfirmationDialog(false)}
          onSubmit={handleOnSubmit}
          open={openConfirmationDialog}
        />
        <Dialog maxWidth={false} open={visible} onClose={handleOnClose}>
          <Box width={875} p={10}>
            <Typography fontSize={22} fontWeight={600} mb={5}>
              {t('lessonPlan.createForm.requestConsultingPopup.title')}
            </Typography>

            <TextField
              label={t('lessonPlan.createForm.requestConsultingPopup.label')}
              placeholder={t(
                'lessonPlan.createForm.requestConsultingPopup.placeholder',
              )}
              multiline
              value={content}
              onChange={(e) => setContent(e.target.value)}
              rows={5}
              inputProps={{
                readOnly: isReadyOnly,
              }}
            />

            <Box mt={3}>
              <Checkbox
                checked={isSiteVisitNeeded}
                label={t(
                  'lessonPlan.createForm.requestConsultingPopup.requireObservation',
                )}
                onChange={isReadyOnly ? undefined : handleCheckboxOnChange}
                readOnly={isReadyOnly}
              />
            </Box>

            <Grid mt={10} container justifyContent="flex-end" columnSpacing={5}>
              <Grid item width={200}>
                <Button variant="outlined" onClick={handleOnClose} fullWidth>
                  {t(
                    'lessonPlan.createForm.generalInformation.teachingStrategyModal.actions.close',
                  )}
                </Button>
              </Grid>
              <Grid item width={200}>
                <Button
                  disabled={
                    content == null || content.length === 0 || isReadyOnly
                  }
                  fullWidth
                  onClick={() => setOpenConfirmationDialog(true)}
                >
                  {t('common.submit')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </Fragment>
    )
  },
)
