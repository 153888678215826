import { Box, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { MasterDataTable } from '@polyu-dip/stores'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  FullscreenLoading,
  ListPage,
  MasterDataManagementDialog,
} from '../../components'
import {
  useMasterData,
  useMasterDataInit,
  useMasterDataManagementFilter,
} from '../../hooks'
import { MasterDataContent } from './master-data-content'
import { MasterDataFilterBar } from './master-data-filter-bar'
import { MasterDataTabs } from './master-data-tabs'
import { SelectGroupTagTypeDialog } from './select-group-tag-type-dialog'

type Props = {}

const Section = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 28px;
  background: ${({ theme }) => theme.palettes.general.white};
  border-radius: 30px;
  height: 100%;
`

const masterDataClasses: MasterDataTable[] = [
  'learningPhases',
  'subjects',
  'diStrategies',
  'resourceTypes',
  'tags',
  'systemParameters',
]

const learningPhaseClasses: MasterDataTable[] = [
  'educationLevels',
  'learningPhases',
  'classLevels',
]

export const MasterDataManagement = observer<Props>(() => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [activeMasterDataTab, setActiveMasterDataTab] = useState(0)
  const [activeMasterDataContentTab, setActiveMasterDataContentTab] =
    useState(0)

  const { isAllMasterDataFetchSuccess } = useMasterDataInit()
  const { inputTitle, setInputTitle } = useMasterDataManagementFilter()

  const handleMasterDataTabOnClick = useCallback((id: number) => {
    setActiveMasterDataTab(id)
    setActiveMasterDataContentTab(0)
  }, [])

  const masterDataTabMenu = useComputed(
    () =>
      masterDataClasses.map((masterDataClass, index) => ({
        id: index,
        label: (
          <Typography>
            {t(`masterData.${masterDataClass}.entityName`)}
          </Typography>
        ),
      })),
    [t],
  )

  const selectedMasterDataTab = useComputed(
    () => masterDataClasses[activeMasterDataTab],
    [activeMasterDataTab],
  )

  const selectedTab = useComputed(() => {
    if (selectedMasterDataTab === 'learningPhases') {
      return learningPhaseClasses[activeMasterDataContentTab]
    }
    return selectedMasterDataTab
  }, [activeMasterDataContentTab, selectedMasterDataTab])

  const { diStrategyClasses, tagTypes } = useMasterData()

  const targetMasterData = useComputed(() => {
    switch (selectedMasterDataTab) {
      case 'learningPhases':
        return learningPhaseClasses.map((learningPhaseClass) => ({
          id: learningPhaseClass,
          label: t(`masterData.${learningPhaseClass}.entityName`),
        }))
      case 'subjects':
      case 'resourceTypes':
      case 'systemParameters':
        return [
          {
            id: selectedMasterDataTab,
            label: t(`masterData.${selectedMasterDataTab}.entityName`),
          },
        ]
      case 'diStrategies':
        return diStrategyClasses.map((diStrategyClass) => ({
          id: diStrategyClass.id,
          label: t('lessonPlan.createForm.diStrategyClass.label', {
            label: diStrategyClass?.label,
            name: diStrategyClass.displayName,
          }),
        }))
      case 'tags':
        return tagTypes.map((tagType) => ({
          id: tagType.id,
          label: tagType.displayName,
        }))
      default:
        return []
    }
  }, [diStrategyClasses, selectedMasterDataTab, t, tagTypes])

  const [showSelectTagGroupType, setShowSelectTagGroupType] = useState(false)
  const [withTagGroupWithName, setWithTagGroupWithName] = useState(false)

  const handleSelectTagGroupType = useCallback((value: boolean) => {
    setWithTagGroupWithName(value)
    setShowSelectTagGroupType(false)
    setIsOpen(true)
  }, [])

  const handleAddButtonOnClick = useCallback(() => {
    if (selectedMasterDataTab === 'tags') {
      setShowSelectTagGroupType(true)
    } else setIsOpen(true)
  }, [selectedMasterDataTab])

  if (!isAllMasterDataFetchSuccess) return <FullscreenLoading />

  return (
    <ListPage
      fullHeight
      sectionTitleProps={{
        title: t('sidebar.systemParameter'),
        color: 'grey',
      }}
    >
      <Section>
        <MasterDataTabs
          activeTab={activeMasterDataTab}
          onActiveTabChange={handleMasterDataTabOnClick}
          tabMenus={masterDataTabMenu}
        />
        <MasterDataFilterBar
          inputTitle={inputTitle}
          setInputTitle={setInputTitle}
          handleAddButtonOnClick={handleAddButtonOnClick}
          selectedMasterDataTab={selectedMasterDataTab}
        />
        <MasterDataContent
          inputTitle={inputTitle}
          targetMasterData={targetMasterData}
          selectedMasterDataTab={selectedMasterDataTab}
          activeMasterDataContentTab={activeMasterDataContentTab}
          setActiveMasterDataContentTab={setActiveMasterDataContentTab}
        />
      </Section>
      <SelectGroupTagTypeDialog
        onClose={() => setShowSelectTagGroupType(false)}
        onSelect={handleSelectTagGroupType}
        open={showSelectTagGroupType}
      />
      <MasterDataManagementDialog
        mode="create"
        masterDataTable={selectedTab === 'tags' ? 'tagGroups' : selectedTab}
        isOpen={isOpen}
        withTagGroupWithName={withTagGroupWithName}
        setIsOpen={setIsOpen}
      />
    </ListPage>
  )
})
