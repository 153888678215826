import { GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { LessonPlan } from '@polyu-dip/models'
import { Tag, TruncatedCellContent } from '../../components'
import { useDisplayName } from '../../services'
import { useTranslation } from 'react-i18next'

export function useLessonPlanExamplesColumn() {
  const { t } = useTranslation()
  const { getDisplayName } = useDisplayName()

  const columns: GridColDef<LessonPlan>[] = [
    {
      field: 'teachingTopic',
      headerName: t('myLessonPlan.tableFields.teachingTopic'),
      width: 400,
      renderCell: ({ row }) => (
        <TruncatedCellContent content={row.teachingTopic} />
      ),
    },
    {
      field: 'classLevel.learningPhase',
      headerName: t('lessonPlanExample.tableFields.learningPhase'),
      width: 120,
      renderCell: ({ row }) => (
        <Tag
          label={getDisplayName(row.classLevel?.learningPhase?.name)}
          colorCustomize={row.classLevel?.learningPhase?.colorCode}
        />
      ),
    },
    {
      field: 'classLevel.order',
      headerName: t('lessonPlanExample.tableFields.class'),
      width: 100,
      renderCell: ({ row }) => (
        <Tag
          label={getDisplayName(row.classLevel?.name)}
          colorCustomize={row.classLevel?.colorCode}
        />
      ),
    },
    {
      field: 'subject.name',
      headerName: t('lessonPlanExample.tableFields.subject'),
      width: 200,
      renderCell: ({ row }) => (
        <Tag
          label={getDisplayName(row.subject?.name)}
          colorCustomize={row.subject?.colorCode}
        />
      ),
    },
    {
      field: 'ownedByUser.school.name',
      headerName: t('lessonPlanExample.tableFields.sharedBy'),
      width: 200,
      renderCell: ({ row }) => (
        <TruncatedCellContent
          content={getDisplayName(row.ownedByUser?.school?.name)}
        />
      ),
    },
    {
      field: 'code',
      headerName: t('lessonPlanExample.tableFields.code'),
      width: 150,
      renderCell: ({ row }) => row.code,
    },
    {
      field: 'latestSourcePublishRequest.publishedDateTime',
      headerName: t('lessonPlanExample.tableFields.publishedDateTime'),
      width: 200,
      renderCell: ({ row }: any) =>
        row.latestSourcePublishRequest.isPublishing
          ? row.latestSourcePublishRequest?.publishedDateTime != null
            ? dayjs(row.latestSourcePublishRequest?.publishedDateTime).format(
                t('common.format.date'),
              )
            : '---'
          : '---',
    },
    {
      field: 'lastModifiedDateTime',
      headerName: t('lessonPlanExample.tableFields.lastModifiedDateTime'),
      width: 200,
      renderCell: ({ row }: any) =>
        row.lastModifiedDateTime != null
          ? dayjs(row.lastModifiedDateTime).format(t('common.format.datetime'))
          : '---',
    },
  ]

  return { columns }
}
