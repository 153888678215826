import { useLessonPlanPublishRequest } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FullscreenLoading } from '../../components'
import { contentPaths } from '../../content-paths'
import { useApiErrorHandle } from '../../hooks'
import {
  LessonPlanDetailPage,
  LessonPlanExampleDetailPage,
} from '../lesson-plan/'

type Props = {
  isViewComment?: boolean
}

export const LessonPlanPublishRequestDetailPage = observer<Props>(
  ({ isViewComment }) => {
    const { standardErrorHandler } = useApiErrorHandle()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { lessonPlanPublishRequestId } = useParams<{
      lessonPlanPublishRequestId: string
    }>()

    const { data, isLoading } = useLessonPlanPublishRequest(
      lessonPlanPublishRequestId ?? '',
      {},
      {
        enabled: lessonPlanPublishRequestId != null,
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t('error.fetchEntityFailure', {
              entityName: t('lessonPlanRequest.entityName.publishRequest'),
            }),
            onDismiss: () => {
              navigate(contentPaths.lessonPlanPublishRequests())
            },
          })
        },
      },
    )

    if (isLoading || !data) {
      return <FullscreenLoading />
    }

    if (isViewComment) {
      return (
        <LessonPlanDetailPage
          lessonPlanId={data.initiatedLessonPlanId}
          processingLessonPlanPublishRequest={data}
        />
      )
    }

    return (
      <LessonPlanExampleDetailPage
        lessonPlanId={data.publishingLessonPlanId}
        processingLessonPlanPublishRequest={data}
      />
    )
  },
)
