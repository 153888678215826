import { Grid } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'
import {
  OutstandingLessonPlanRequestStatus,
  OutstandingRequestType,
  PaginationParameters,
} from '@polyu-dip/apis'
import { useComputed } from '@polyu-dip/helpers'
import { useOutstandingLessonPlanRequests } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ConsultDetailDialog, SectionTitle, Table } from '../../../components'
import { contentPaths } from '../../../content-paths'
import {
  TabType,
  useOutstandingLessonPlanRequestColumn,
} from '../../lesson-plan-requests'

type Props = {
  tabType?: TabType
}

const Container = styled.div`
  margin-top: 56px;
`

const PAGINATION_LIMIT = 5

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

export const OutstandingLessonPlansTable = observer<Props>(({ tabType }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { renderColumn } = useOutstandingLessonPlanRequestColumn()

  const [sort, setSort] = useState<GridSortModel>()
  const [approvedSort, setApprovedSort] = useState<GridSortModel>()
  const [openConsultDetailDialog, setOpenConsultDetailDialog] = useState(false)
  const [targetRequestId, setTargetRequestId] = useState<string>()
  const [requestText, setRequestText] = useState<string>()
  const [requireObservation, setRequireObservation] = useState<boolean>()

  const handleLetterOnClick = useCallback(
    (requestId: string, consultContent: string, isSiteVisitNeeded: boolean) => {
      setOpenConsultDetailDialog(true)
      setTargetRequestId(requestId)
      setRequestText(consultContent)
      setRequireObservation(isSiteVisitNeeded)
    },
    [],
  )

  const {
    data: pendingConsultRequests,
    isLoading: isPendingConsultRequestsLoading,
  } = useOutstandingLessonPlanRequests({
    status: [OutstandingLessonPlanRequestStatus.pending],
    type: [OutstandingRequestType.consultRequest],
    sort: sort?.map((it) => ({
      direction: it.sort ?? 'desc',
      parameter: it.field,
    })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
    ...DEFAULT_PAGINATION,
  })

  const { data: approvedRequests, isLoading: isApprovedRequestsLoading } =
    useOutstandingLessonPlanRequests({
      status: [
        OutstandingLessonPlanRequestStatus.pending,
        OutstandingLessonPlanRequestStatus.approved,
      ],
      type: [OutstandingRequestType.publishRequest],
      sort: approvedSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      ...DEFAULT_PAGINATION,
    })

  const computedConsultRequestData = useComputed(() => {
    return (
      pendingConsultRequests?.data.map((it) => ({
        ...it,
        id: it.id,
        assignedToUserName: it.assignedToUserName ?? '---',
      })) ?? []
    )
  }, [pendingConsultRequests])

  const computedPublishRequestData = useComputed(() => {
    // eslint-disable-next-line no-nested-ternary
    return approvedRequests?.data ?? []
  }, [approvedRequests])

  const consultRequestTotalRecord = useComputed(() => {
    return pendingConsultRequests?.total ?? 0
  }, [pendingConsultRequests])

  const isConsultRequestLoading = useComputed(() => {
    return isPendingConsultRequestsLoading
  }, [isPendingConsultRequestsLoading])

  return (
    <Container>
      <Grid container direction="column">
        <Grid item>
          <SectionTitle
            title={t('home.outstandingLessonPlanTable.consult.headerTitle', {
              pendingCount: consultRequestTotalRecord,
            })}
            color="brown"
          />

          <Table
            columns={renderColumn(
              'consult',
              tabType,
              handleLetterOnClick,
              true,
            )}
            rows={computedConsultRequestData}
            pageSize={PAGINATION_LIMIT}
            loading={isConsultRequestLoading}
            hideFooterPagination
            showMoreButton
            handleShowMore={() => {
              navigate(contentPaths.lessonPlanConsultRequests())
            }}
            sortModel={sort}
            onSortModelChange={setSort}
            onRowClick={({ row }) => {
              if (row.type == 'consultRequest') {
                navigate(contentPaths.lessonPlanConsultRequest(row.id))
              } else {
                navigate(contentPaths.lessonPlanPublishRequest(row.id, 'view'))
              }
            }}
          />
        </Grid>

        <Grid item mt={8}>
          <SectionTitle
            title={t('home.outstandingLessonPlanTable.publish.headerTitle', {
              approvedCount: approvedRequests?.total ?? 0,
            })}
            color="brown"
          />

          <Table
            columns={renderColumn(
              'outstanding',
              tabType,
              handleLetterOnClick,
              true,
            )}
            rows={computedPublishRequestData}
            pageSize={PAGINATION_LIMIT}
            loading={isApprovedRequestsLoading}
            hideFooterPagination
            showMoreButton
            handleShowMore={() => {
              navigate(contentPaths.lessonPlanPublishRequests())
            }}
            sortModel={sort}
            onSortModelChange={setApprovedSort}
            onRowClick={({ row }) => {
              navigate(contentPaths.lessonPlanPublishRequest(row.id, 'view'))
            }}
          />
        </Grid>
      </Grid>
      <ConsultDetailDialog
        open={openConsultDetailDialog}
        onClose={() => setOpenConsultDetailDialog(false)}
        requestId={targetRequestId}
        requestText={requestText}
        requireObservation={requireObservation}
        onSubmit={() => {
          navigate(contentPaths.lessonPlanConsultRequest(targetRequestId ?? ''))
        }}
      />
    </Container>
  )
})
