import { PaginationParameters } from './payload.types'

export function convertSortParameters(params?: PaginationParameters) {
  if (params?.sort == null) return null
  return params.sort
    .map((it) => `${it.direction === 'asc' ? '' : '---'}${it.parameter}`)
    .join(',')
}

export function convertExpandParameters(
  params?: PaginationParameters & { expand?: string[] },
) {
  if (params?.expand == null) return null
  return params.expand.join(',')
}
