import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { Svg } from '../../assets'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UserPopover } from './user-popover'
import { contentPaths } from '../../content-paths'
import { useStores } from '../../stores'
import {useMyNotifications} from '@polyu-dip/queries'
type TopBarPropType = {}

const Container = styled.div`
  height: 64px;
  background-color: ${({ theme }) => theme.palettes.general.white};
`

const TopDiv = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

const FunctionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`

const LanguageButton = styled(Link)<{ selected: boolean }>`
  display: inline-block;
  text-decoration: none;
  color: #4f4f4f;
  font-size: 14px;
  padding: 0 4px;
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
`

const Notification = styled.div`
  display: flex;
  align-content: center;
  margin: 0 20px;
  &:hover {
    background-color: ${({ theme }) => theme.palettes.general.lightBlue};
    border-radius: 99px;
    cursor: pointer;
  }
`

export const TopBar = memo<TopBarPropType>(() => {
  const { i18n } = useTranslation()
  const { uiStore } = useStores()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: unreadNotifications } = useMyNotifications(
    {isRead:false}
  )
  const isFilled = useMemo(
    () => location?.pathname === contentPaths.notifications(),
    [location?.pathname],
  )
  const svgBell = useMemo(
    () => {
    
      if(location?.pathname === contentPaths.notifications())
      {
        if(unreadNotifications && unreadNotifications.total > 0)
          {
            return <Svg.BellRed />
          } 
          else
          {
            return <Svg.BellFill />
          }
        
      }
      else{
        if(unreadNotifications && unreadNotifications.total > 0)
          {
            return <Svg.BellRed />
          } 
          else
          {
            return <Svg.Bell />
          }
      }
     
    },[location?.pathname,unreadNotifications]
  )

  
  useEffect(() => {
    if (uiStore.language !== i18n.language) {
      uiStore.changeLanguage(i18n.language)
    }
  }, [i18n.language, uiStore])

  const changeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language)
      window.localStorage.setItem('i18n-language', language)
    },
    [i18n],
  )

  const handleNotificationClick = useCallback(() => {
    if (isFilled) {
      navigate(-1)
    } else {
      navigate(contentPaths.notifications())
    }
  }, [isFilled, navigate])
  

  return (
    <Container>
      <TopDiv>
        <FunctionDiv>
          <LanguageButton
            selected={i18n.language === 'en'}
            to="#"
            onClick={() => changeLanguage('en')}
          >
            EN
          </LanguageButton>
          <LanguageButton
            selected={i18n.language === 'zh-Hant'}
            to="#"
            onClick={() => changeLanguage('zh-Hant')}
          >
            繁
          </LanguageButton>
          <Notification onClick={handleNotificationClick}>
            {svgBell}
            {/*isFilled ?({unreadNotifications?.total>0?<Svg.BellFill />:<Svg.BellFill />}) : <Svg.Bell />*/}
          </Notification>

          <UserPopover />
        </FunctionDiv>
      </TopDiv>
    </Container>
  )
})
