import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from '../base-entity'
import { LessonPlanAttachmentModel } from '../lesson-plan-attachment.model'
import { LessonPlanDiStrategyModel } from '../lesson-plan-di-strategy.model'
import { LessonPlanStudentGroupModel } from '../lesson-plan-student-group.model'
import { LessonPlanTagModel } from '../lesson-plan-tag.model'
import { LessonPlanStatisticsModel } from '../lesson-plan-statistics.model'
import { User } from '../user.model'
import { BaseLessonPlanModel } from './base-lesson-plan.model'
import { LessonPlanCommentModel } from '../lesson-plan-comment.model'
import { LessonPlanSharingModel } from '../lesson-plan-sharing.model'
import { LessonPlanPublishRequestModel } from '../lesson-plan-publish-requests.model'
import { LessonPlanConsultModel } from '../lesson-plan-consult.model'

export const LessonPlanModel = types
  .compose(BaseEntityModel, BaseLessonPlanModel)
  .props({
    lessonPlanAttachments: types.optional(
      types.array(LessonPlanAttachmentModel),
      [],
    ),
    lessonPlanStudentGroups: types.optional(
      types.array(LessonPlanStudentGroupModel),
      [],
    ),
    lessonPlanDiStrategies: types.optional(
      types.array(LessonPlanDiStrategyModel),
      [],
    ),
    lessonPlanTags: types.array(LessonPlanTagModel),
    lessonPlanStatistics: types.maybe(LessonPlanStatisticsModel),
    lessonPlanSharings: types.array(LessonPlanSharingModel),
    lessonPlanComments: types.array(LessonPlanCommentModel),
    lessonPlanPublishRequests: types.array(LessonPlanPublishRequestModel),
    sourcePublishRequests: types.array(LessonPlanPublishRequestModel),
    lessonPlanConsults: types.array(LessonPlanConsultModel),
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .named('LessonPlanModel')
  .views((self) => ({
    get sortedLessonPlanStudentGroups() {
      if (self.lessonPlanStudentGroups == null) return []
      return self.lessonPlanStudentGroups
        .slice()
        .sort((a, b) => (a?.order ?? 0) - (b.order ?? 0))
    },
    get latestLessonPlanComment() {
      if (self.lessonPlanComments == null) return undefined
      const sortedLessonPlanComment = self.lessonPlanComments
        .slice()
        .sort((a, b) =>
          a.createdTimeDayJs.isBefore(b.createdTimeDayJs) ? 1 : -1,
        )
      return sortedLessonPlanComment.length > 0
        ? sortedLessonPlanComment[0]
        : null
    },
    get latestLessonPlanConsult() {
      if (self.lessonPlanConsults == null) return undefined
      const sortedLessonPlanConsult = self.lessonPlanConsults
        .slice()
        .sort((a, b) =>
          a.createdTimeDayJs.isBefore(b.createdTimeDayJs) ? 1 : -1,
        )
      return sortedLessonPlanConsult.length > 0
        ? sortedLessonPlanConsult[0]
        : null
    },
    get latestLessonPlanPublishRequest() {
      if (self.lessonPlanPublishRequests == null) return undefined
      const sortedLessonPlanPublishRequest = self.lessonPlanPublishRequests
        .slice()
        .sort((a, b) =>
          a.createdTimeDayJs.isBefore(b.createdTimeDayJs) ? 1 : -1,
        )
      return sortedLessonPlanPublishRequest.length > 0
        ? sortedLessonPlanPublishRequest[0]
        : null
    },
    get latestSourcePublishRequest() {
      if (self.sourcePublishRequests == null) return undefined
      const sortedSourcePublishRequest = self.sourcePublishRequests
        .slice()
        .sort((a, b) =>
          a.createdTimeDayJs.isBefore(b.createdTimeDayJs) ? 1 : -1,
        )
      return sortedSourcePublishRequest.length > 0
        ? sortedSourcePublishRequest[0]
        : null
    },
    get createdByUser() {
      if (self.createdByUserId == null) return undefined
      return (self.rootStore.usersStore as { users: User[] }).users.find(
        (user) => user.id === self.createdByUserId,
      )
    },
    get lastModifiedByUser() {
      if (self.lastModifiedByUserId == null) return undefined
      return (self.rootStore.usersStore as { users: User[] }).users.find(
        (user) => user.id === self.lastModifiedByUserId,
      )
    },
  }))

export type LessonPlan = Instance<typeof LessonPlanModel>

const emptyBaseEntity = {
  createdByUserId: '',
  lastModifiedByUserId: '',
  createdDateTime: '',
  lastModifiedDateTime: '',
  rowVersion: '',
} as const

export const emptyLessonPlan = {
  ...emptyBaseEntity,
  id: '',
  ownedByUserId: '',
  authorUserId: '',
  code: undefined,
  subjectId: '',
  learningUnit: '',
  classLevelId: '',
  teachingClass: '',
  teachingTopic: '',
  sectionDuration: undefined,
  sectionTotal: undefined,
  academicYear: undefined,
  requiredKnowledge: undefined,
  remark: undefined,
  followUp: undefined,
  selfLearningResource: undefined,
  diStrategyTeachingConceptExcerpt: '',
  isAgreePublish: undefined,
  isCompleted: false,
  isMultiGroup: false,
  isDisabled: false,
  lessonPlanAttachments: undefined,
  lessonPlanStudentGroups: undefined,
  lessonPlanDiStrategies: undefined,
  lessonPlanTags: undefined,
  lessonPlanStatistics: undefined,
} as const
