import {
  useClassLevels,
  useDiStrategies,
  useDiStrategyClasses,
  useEducationLevels,
  useLearningPhases,
  useLearningUnits,
  useResourceTypes,
  useSubjects,
  useSystemParameters,
  useTagGroups,
  useTags,
  useTagTypes,
  useTeachingClasses,
} from '@polyu-dip/queries'
import { useStores } from '../stores'
import { useRestoreSession } from '../services'
import { useCallback, useEffect, useState } from 'react'
import { useApiErrorHandle } from './use-api-error-handle'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const queryParameters = {}
const queryOptions = {
  cacheTime: Infinity,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
}

export function useMasterDataInit(dismissPath?: string) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const sessionRestored = useRestoreSession()
  const { standardErrorHandler } = useApiErrorHandle()

  const [isAllMasterDataFetchSuccess, setIsAllMasterDataFetchSuccess] =
    useState(false)

  const handleOnError = useCallback(
    (error: unknown, refetch: () => void, entityName: string) => {
      standardErrorHandler(error, {
        onExtraActions: () => {
          if (dismissPath == null) return
          navigate(dismissPath)
        },
        onRetry: refetch,
        errorFormatter: {
          formatted: (apiError) => {
            return {
              title: t('error.fetchEntityFailure', {
                entityName: entityName,
              }),
              content: t('error.default.content', {
                error: apiError.kind,
              }),
              actions: [
                { text: t('common.cancel'), value: '' },
                { text: t('error.action.retry'), value: 'retry' },
              ],
            }
          },
        },
      })
    },
    [dismissPath, navigate, standardErrorHandler, t],
  )

  const { isSuccess: isLearningUnitsSuccess, refetch: refetchLearningUnits } =
    useLearningUnits(useStores, queryParameters, {
      enabled: sessionRestored,
      onError: (error) => {
        handleOnError(
          error,
          refetchLearningUnits,
          t('masterData.learningUnits.entityName'),
        )
      },
      ...queryOptions,
    })

  const {
    isSuccess: isEducationLevelsSuccess,
    refetch: refetchEducationLevels,
  } = useEducationLevels(useStores, queryParameters, {
    enabled: sessionRestored,
    onError: (error) => {
      handleOnError(
        error,
        refetchEducationLevels,
        t('masterData.educationLevels.entityName'),
      )
    },
    ...queryOptions,
  })

  const {
    isSuccess: isTeachingClassesSuccess,
    refetch: refetchTeachingClasses,
  } = useTeachingClasses(useStores, queryParameters, {
    enabled: sessionRestored,
    onError: (error) => {
      handleOnError(
        error,
        refetchTeachingClasses,
        t('masterData.teachingClasses.entityName'),
      )
    },
    ...queryOptions,
  })

  const { isSuccess: isDiStrategiesSuccess, refetch: refetchDiStrategies } =
    useDiStrategies(
      useStores,
      {},
      {
        enabled: sessionRestored,
        onError: (error) => {
          handleOnError(
            error,
            refetchDiStrategies,
            t('masterData.diStrategies.entityName'),
          )
        },
        ...queryOptions,
      },
    )

  const { isSuccess: isTagsSuccess, refetch: refetchTags } = useTags(
    useStores,
    queryParameters,
    {
      enabled: sessionRestored,
      onError: (error) => {
        handleOnError(error, refetchTags, t('masterData.tags.entityName'))
      },
      ...queryOptions,
    },
  )

  const { isSuccess: isTagGroupsSuccess, refetch: refetchTagGroups } =
    useTagGroups(useStores, queryParameters, {
      enabled: sessionRestored,
      onError: (error) => {
        handleOnError(error, refetchTagGroups, t('masterData.tags.entityName')) // TODO: correct tag-group entity name
      },
      ...queryOptions,
    })

  const { isSuccess: isSubjectsSuccess, refetch: refetchSubjects } =
    useSubjects(
      useStores,
      { expand: ['SubjectEducationLevelMappings'] },
      {
        enabled: sessionRestored,
        onError: (error) => {
          handleOnError(
            error,
            refetchSubjects,
            t('masterData.subjects.entityName'),
          )
        },
        ...queryOptions,
      },
    )

  const { isSuccess: isLearningPhasesSuccess, refetch: refetchLearningPhases } =
    useLearningPhases(useStores, queryParameters, {
      enabled: sessionRestored,
      onError: (error) => {
        handleOnError(
          error,
          refetchLearningPhases,
          t('masterData.learningPhases.entityName'),
        )
      },
      ...queryOptions,
    })

  const { isSuccess: isClassLevelsSuccess, refetch: refetchClassLevels } =
    useClassLevels(useStores, queryParameters, {
      enabled: sessionRestored,
      onError: (error) => {
        handleOnError(
          error,
          refetchClassLevels,
          t('masterData.classLevels.entityName'),
        )
      },
      ...queryOptions,
    })

  const {
    isSuccess: isDiStrategyClassesSuccess,
    refetch: refetchDiStrategyClasses,
  } = useDiStrategyClasses(useStores, queryParameters, {
    enabled: sessionRestored,
    onError: (error) => {
      handleOnError(
        error,
        refetchDiStrategyClasses,
        t('masterData.diStrategyClasses.entityName'),
      )
    },
    ...queryOptions,
  })

  const { isSuccess: isTagTypesSuccess, refetch: refetchTagTypes } =
    useTagTypes(useStores, queryParameters, {
      enabled: sessionRestored,
      onError: (error) => {
        handleOnError(
          error,
          refetchTagTypes,
          t('masterData.tagTypes.entityName'),
        )
      },
      ...queryOptions,
    })

  const { isSuccess: isResourceTypeSuccess, refetch: refetchResourceTypes } =
    useResourceTypes(useStores, queryParameters, {
      enabled: sessionRestored,
      onError: (error) => {
        handleOnError(
          error,
          refetchResourceTypes,
          t('masterData.resourceTypes.entityName'),
        )
      },
      ...queryOptions,
    })

  const {
    isSuccess: isSystemParametersSuccess,
    refetch: refetchSystemParameters,
  } = useSystemParameters(useStores, queryParameters, {
    enabled: sessionRestored,
    onError: (error) => {
      handleOnError(
        error,
        refetchSystemParameters,
        t('masterData.systemParameters.entityName'),
      )
    },
  })

  useEffect(() => {
    setIsAllMasterDataFetchSuccess(
      isLearningUnitsSuccess &&
        isEducationLevelsSuccess &&
        isTeachingClassesSuccess &&
        isDiStrategiesSuccess &&
        isTagsSuccess &&
        isTagGroupsSuccess &&
        isSubjectsSuccess &&
        isLearningPhasesSuccess &&
        isClassLevelsSuccess &&
        isDiStrategyClassesSuccess &&
        isTagTypesSuccess &&
        isResourceTypeSuccess &&
        isSystemParametersSuccess,
    )
  }, [
    isSystemParametersSuccess,
    isResourceTypeSuccess,
    isClassLevelsSuccess,
    isDiStrategiesSuccess,
    isDiStrategyClassesSuccess,
    isEducationLevelsSuccess,
    isLearningPhasesSuccess,
    isLearningUnitsSuccess,
    isSubjectsSuccess,
    isTagTypesSuccess,
    isTagsSuccess,
    isTagGroupsSuccess,
    isTeachingClassesSuccess,
  ])

  return {
    isAllMasterDataFetchSuccess,
  }
}
