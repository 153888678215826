import { Box, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Dropdown,
  FormLabel,
  RadioGroup,
  TextField,
  TextWithTickIcon,
} from '../../../../../components'
import { useStores } from '../../../../../stores'
import { SectionBox } from '../../../components'
import { GeneralInformationFormData } from '../../../lesson-plan-form-provider'
import { TabHeader } from '../tab-header'
import { OverviewTabExtra } from './overview-tab-extra'
import { useComputed } from '@polyu-dip/helpers'
import { useCallback, ChangeEvent } from 'react'
import { sortByOrder } from '../../../../../utilities'
import { getInputNumberValue } from '@polyu-dip/utilities'
import { useDisplayName } from '../../../../../services'

type OverviewTabProps = {}

export const OverviewTab = observer<OverviewTabProps>(() => {
  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<GeneralInformationFormData>()

  const { t } = useTranslation()
  const { getDisplayName } = useDisplayName()

  const { masterDataStore } = useStores()

  const selectedEducationLevel = useComputed(
    () =>
      masterDataStore.educationLevels.find(
        (it) => it.id == getValues('overview.educationLevelId'),
      ),
    [getValues, masterDataStore.educationLevels],
  )

  const subjectOptions = useComputed(
    () =>
      masterDataStore.subjects
        .slice()
        .filter(
          (subject) =>
            (!subject.isDisabled || subject.id == getValues('subjectId')) &&
            subject.subjectEducationLevelMappings.some(
              (it) => it.educationLevelId === selectedEducationLevel?.id,
            ),
        )
        .sort(sortByOrder)
        .map((subject) => ({
          label: subject.displayName,
          value: subject.id,
        })),
    [getValues, masterDataStore.subjects, selectedEducationLevel?.id],
  )

  const classLevelOptions = useComputed(
    () =>
      masterDataStore.classLevels
        .slice()
        .filter(
          (classLevel) =>
            (!classLevel.isDisabled ||
              classLevel.id == getValues('classLevelId')) &&
            classLevel.learningPhase?.educationLevelId ===
              selectedEducationLevel?.id,
        )
        .sort(sortByOrder)
        .map((classLevel) => ({
          value: classLevel.id,
          educationLevelId: classLevel.learningPhase?.educationLevelId,
          label: classLevel.displayName,
        })),
    [getValues, masterDataStore.classLevels, selectedEducationLevel?.id],
  )

  const onClassLevelChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: string) => {
      if (selectedEducationLevel?.id == null) return
      setValue('classLevelId', value)
    },
    [selectedEducationLevel?.id, setValue],
  )

  const onNumberChange = useCallback(
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      fieldName: 'sectionTotal' | 'sectionDuration',
    ) => {
      const numberValue = getInputNumberValue(e.target.value)
      setValue(fieldName, numberValue ?? 0)
    },
    [setValue],
  )

  return (
    <Box>
      <SectionBox>
        <TabHeader />
        <Grid container columnSpacing={5} mt={4}>
          <Grid item sm={6}>
            <Grid container direction="row">
              <Grid item mr={12} textAlign="center">
                <FormLabel
                  label={t(
                    'lessonPlan.createForm.generalInformation.fields.educationLevel.label',
                  )}
                />
              </Grid>
              <Grid item>
                {selectedEducationLevel == null ? (
                  <></>
                ) : (
                  <TextWithTickIcon
                    title={getDisplayName(selectedEducationLevel.name)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Controller
              control={control}
              render={({ field }) => (
                <RadioGroup
                  label={t(
                    'lessonPlan.createForm.generalInformation.fields.classLevel.label',
                  )}
                  options={classLevelOptions}
                  row
                  required
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  value={watch('classLevelId') ?? ''}
                  onChange={onClassLevelChange}
                  error={errors.classLevelId?.message}
                  isReserverForHelperText
                  size="small"
                />
              )}
              name="classLevelId"
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              control={control}
              render={({ field }) => (
                <Dropdown
                  label={t(
                    'lessonPlan.createForm.generalInformation.fields.subject.label',
                  )}
                  placeholder={t(
                    'lessonPlan.createForm.generalInformation.fields.subject.placeholder',
                  )}
                  options={subjectOptions}
                  required
                  error={errors?.subjectId?.message}
                  isReserverForHelperText
                  {...field}
                />
              )}
              name="subjectId"
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  label={t(
                    'lessonPlan.createForm.generalInformation.fields.learningUnit.label',
                  )}
                  placeholder={t(
                    'lessonPlan.createForm.generalInformation.fields.learningUnit.placeholder',
                  )}
                  {...field}
                />
              )}
              name="learningUnit"
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  label={t(
                    'lessonPlan.createForm.generalInformation.fields.teachingTopic.label',
                  )}
                  placeholder={t(
                    'lessonPlan.createForm.generalInformation.fields.teachingTopic.placeholder',
                  )}
                  required
                  error={errors?.teachingTopic?.message}
                  isReserverForHelperText
                  {...field}
                />
              )}
              name="teachingTopic"
            />
          </Grid>
          <Grid item sm={6}>
            <Grid container columnSpacing={4}>
              <Grid item sm={4}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t(
                        'lessonPlan.createForm.generalInformation.fields.sectionTotal.label',
                      )}
                      placeholder={t(
                        'lessonPlan.createForm.generalInformation.fields.sectionTotal.placeholder',
                      )}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      type="number"
                      decimalPlace={0}
                      inputProps={{ min: 0 }}
                      value={getValues('sectionTotal')}
                      onChange={(e) => onNumberChange(e, 'sectionTotal')}
                      required
                      error={errors.sectionTotal?.message}
                      isReserverForHelperText
                    />
                  )}
                  name="sectionTotal"
                />
              </Grid>
              <Grid item sm={6} flexDirection="row" display="flex">
                <Grid item flex={4}>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={t(
                          'lessonPlan.createForm.generalInformation.fields.sectionDuration.label',
                        )}
                        placeholder={t(
                          'lessonPlan.createForm.generalInformation.fields.sectionDuration.placeholder',
                        )}
                        type="number"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        inputProps={{ min: 25, step: 5 }}
                        value={getValues('sectionDuration')}
                        onChange={(e) => onNumberChange(e, 'sectionDuration')}
                        required
                        error={errors.sectionDuration?.message}
                        isReserverForHelperText
                      />
                    )}
                    name="sectionDuration"
                  />
                </Grid>
                <Grid item flex={2} mt={8} ml={2}>
                  <Typography>
                    {t(
                      'lessonPlan.createForm.generalInformation.fields.minute',
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SectionBox>

      <OverviewTabExtra
        control={control}
        errors={errors}
        setValue={setValue}
        tagIds={watch('classCharacteristicTags')}
      />
    </Box>
  )
})
