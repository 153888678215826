import { Avatar as MuiAvatar, Typography } from '@mui/material'
import { firstLetter, stringToHslColor } from '@polyu-dip/utilities'
import { memo } from 'react'
import styled from 'styled-components'
import { Svg } from '../../assets'

const StyledTypography = styled(Typography)<{ size?: string }>`
  font-size: ${({ theme, size }) =>
    theme.fontSizes[size === 'small' ? 'small' : 'medium']}px;
`

function stringAvatar(name: string, size?: string) {
  const iconSize = size === 'small' ? 28 : 36
  return {
    sx: {
      bgcolor: stringToHslColor(name, 25, 67),
      width: iconSize,
      height: iconSize,
    },
  }
}

type Props = {
  name?: string
  size?: 'medium' | 'small'
}

export const Avatar = memo<Props>(({ name, size }) => {
  if (name == null) return <Svg.Propic />
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiAvatar {...stringAvatar(name, size)}>
      <StyledTypography size={size}>
        {firstLetter(name)?.toUpperCase()}
      </StyledTypography>
    </MuiAvatar>
  )
})
