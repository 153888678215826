import { convertNullToUndefined } from '@polyu-dip/utilities'

export const withUpdate = (self: any) => ({
  actions: {
    update(snapshot: any): void {
      Object.keys(snapshot).forEach((key) => {
        if (key === 'id') return // avoid overriding key
        if (snapshot[key] === undefined) return
        if (snapshot[key] == null) {
          self[key] = undefined
        } else if (Array.isArray(snapshot[key])) {
          self[key] = snapshot[key].map((it: any) => it ?? undefined)
        } else if (typeof snapshot[key] === 'object') {
          if (self[key] == null || self[key].update == null) {
            self[key] = convertNullToUndefined(snapshot[key])
          } else {
            self[key].update(snapshot[key])
          }
        } else {
          self[key] = snapshot[key] ?? undefined
        }
      })
    },
  },
})
