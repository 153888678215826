import { Button, Divider, Grid, Pagination, Typography } from '@mui/material'
import {
  LessonPlanCommentsQueryParameters,
  LessonPlanConsultRequestResponsePayload,
  PaginationParameters,
} from '@polyu-dip/apis'
import { useComputed } from '@polyu-dip/helpers'
import { useTheme } from '@polyu-dip/theme'
import {
  useCreateLessonPlanComment,
  useCreateLessonPlanConsultRequestComplete,
  useLessonPlanComments,
} from '@polyu-dip/queries'
import { FileSource } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import {
  BYTES_FOR_ONE_MB,
  MAX_FILE_SIZE_IN_MB,
  TextField,
  useOverlay,
} from '../../../components'
import { useApiErrorHandle } from '../../../hooks'
import { useStores } from '../../../stores'
import { LessonPlanCommentCard } from './lesson-plan-comment-card'
import { LessonPlanCommentLoadingCard } from './lesson-plan-comment-loading-card'
import { useBlobStorage } from '../../../services'
import { CommentAttachmentType } from './comment-action-modal'

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacings.general[4]}px; ;
`

const StyledDivider = styled(Divider)`
  border-bottom-width: 4px;
  border-bottom-color: ${({ theme }) => theme.palettes.general.greys[3]};
  margin-top: ${({ theme }) => theme.spacings.general[4]}px;
`

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palettes.general.greys[1]};
  font-weight: 600
  font-size: ${({ theme }) => theme.fontSizes.heading4}px;
`

const StyledButton = styled(Button)`
  width: 193px;
`

const StyledExpandButton = styled(Button)`
  color: ${({ theme }) => theme.palettes.general.greys[1]};
  padding: 0;
`

const StyledNextButton = styled(Button)``

const StyledNextButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
`

const CommentSectionContainer = styled(Grid)`
  background-color: ${({ theme }) => theme.palettes.general.white};
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  gap: 8px;
  border-radius: 20px;
`

const AttachmentButton = styled(Button)<{ component: ReactNode }>`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding: 4px;
  min-width: 100%;
`

const DeleteButtonContainer = styled.div`
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacings.general[1]}px;
`

const AttachmentLabel = styled(Button)`
  padding-left: ${({ theme }) => theme.spacings.general[2]}px;
  margin-bottom: ${({ theme }) => theme.spacings.general[1]}px;
  border: 1px solid ${({ theme }) => theme.palettes.general.greys[3]};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palettes.general.lightBlue};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  margin-right: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.palettes.general.lightBlue};
    cursor: unset;
  }
`

type Props = {
  canCommentLessonPlan?: boolean
  lessonPlanId: string
  consultRequest?: LessonPlanConsultRequestResponsePayload
}

const PAGINATION_LIMIT = 5

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

const DEFAULT_FILTERING: LessonPlanCommentsQueryParameters = {
  expand: [
    'CreatedByUser',
    'RepliedToComment',
    'RepliedToComment.CreatedByUser',
    'LessonPlanCommentAttachments',
  ],
  sort: [
    {
      direction: 'desc',
      parameter: 'CreatedDateTime',
    },
  ],
  isDeleted: false,
}

export const LessonPlanCommentSection = observer<Props>(
  ({ lessonPlanId, canCommentLessonPlan, consultRequest }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { standardErrorHandler } = useApiErrorHandle()
    const { showSnackbar, showDialog } = useOverlay()
    const { userProfileStore } = useStores()
    const { handleUpload } = useBlobStorage()

    const [isExpanded, setIsExpanded] = useState(true)
    const [content, setContent] = useState<string>()
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
    const [page, setPage] = useState(1)
    const [attachments, setAttachments] = useState<CommentAttachmentType[]>([])

    const maxSizeInMb = useMemo(() => MAX_FILE_SIZE_IN_MB, [])

    const currentUserId = useComputed(
      () => userProfileStore.userProfile?.id,
      [],
    )

    const {
      data: latestLessonPlanCommentsData,
      isLoading: isLatestLessonPlanCommentLoading,
    } = useLessonPlanComments(useStores, lessonPlanId, {
      ...DEFAULT_FILTERING,
      limit: 1,
    })

    const { data: lessonPlanCommentsData, isLoading } = useLessonPlanComments(
      useStores,
      lessonPlanId,
      {
        ...DEFAULT_FILTERING,
        ...pagination,
      },
    )

    const handleExpand = useCallback(() => {
      setIsExpanded(!isExpanded)
    }, [isExpanded])

    const { mutateAsync: createComment } = useCreateLessonPlanComment(
      useStores,
      lessonPlanId,
      {
        onSuccess() {
          showSnackbar({
            message: t(
              'lessonPlan.action.createLessonPlanComment.successMessage',
            ),
          })
          setContent('')
        },
        onError(error) {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.createLessonPlanComment.title',
            ),
          })
        },
      },
    )

    const { mutateAsync: complete } = useCreateLessonPlanConsultRequestComplete(
      useStores,
      consultRequest?.id ?? '',
      {
        onSuccess() {},
        onError(error) {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.completeConsultRequest.title',
            ),
          })
        },
      },
    )

    const resetPage = useCallback(() => {
      setPagination({
        ...pagination,
        offset: 0,
      })
      setPage(1)
    }, [pagination])

    const createLessonPlanComment = useCallback(async () => {
      if (content == null) return
      if (
        consultRequest?.status != 'completed' &&
        consultRequest?.rowVersion != null
      ) {
        await complete(consultRequest.rowVersion)
      }
      await createComment({
        content: content,
        LessonPlanCommentAttachments: attachments.map((attachment) => ({
          attachmentName: attachment.name,
          file: {
            url: attachment.file.url,
            source: attachment.file.source,
          },
        })),
      })
      setAttachments([])
      resetPage()
    }, [
      attachments,
      complete,
      consultRequest?.rowVersion,
      consultRequest?.status,
      content,
      createComment,
      resetPage,
    ])

    const handleContentOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setContent(e.target.value)
      },
      [],
    )

    const computedLessonPlanCommentsData = useComputed(
      () =>
        (isExpanded
          ? lessonPlanCommentsData?.data
          : latestLessonPlanCommentsData?.data
        )?.map((it) => {
          return {
            ...it,
            displayCommentNumber: it.displayCommentNumber,
            createdByUser: {
              ...it.createdByUser,
              displayName: it.createdByUser?.displayName,
            },
            repliedToComment:
              it?.repliedToComment == null
                ? undefined
                : {
                    ...it.repliedToComment,
                    displayCommentNumber:
                      it.repliedToComment.displayCommentNumber,
                    createdByUser: {
                      ...it.repliedToComment.createdByUser,
                      displayName:
                        it.repliedToComment.createdByUser?.displayName,
                    },
                  },
          }
        }),
      [isExpanded, lessonPlanCommentsData, latestLessonPlanCommentsData],
    )

    const totalPage = useComputed(
      () =>
        lessonPlanCommentsData?.total == null
          ? 0
          : Math.ceil(lessonPlanCommentsData.total / PAGINATION_LIMIT),
      [lessonPlanCommentsData],
    )

    const handlePageOnChange = useCallback(
      (value: number) => {
        setPagination({
          ...pagination,
          offset: (value - 1) * PAGINATION_LIMIT,
        })
        setPage(value)
      },
      [pagination],
    )

    const renderLoadingCardSection = useMemo(() => {
      if (isExpanded) {
        return (
          <>
            <LessonPlanCommentLoadingCard />
            <LessonPlanCommentLoadingCard />
            <LessonPlanCommentLoadingCard />
            <LessonPlanCommentLoadingCard />
            <LessonPlanCommentLoadingCard />
          </>
        )
      }
      return <LessonPlanCommentLoadingCard />
    }, [isExpanded])

    const handleSelectedFile = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        const { files: fileList } = event.target
        let appendedAttachment: CommentAttachmentType[] = []

        if (!fileList || fileList?.length === 0) {
          return
        }

        for (let i = 0; i < fileList.length; i++) {
          if (
            maxSizeInMb != null &&
            fileList[i].size > maxSizeInMb * BYTES_FOR_ONE_MB
          ) {
            showDialog({
              title: t('attachment.errorMessage.uploadAttachment.title'),
              content: t(
                'attachment.errorMessage.uploadAttachment.exceedMaxFileSize.content',
                { maxSizeInMb },
              ),
              actions: [{ text: t('common.ok'), value: null }],
            })
            return
          }

          // eslint-disable-next-line no-await-in-loop
          const url = await handleUpload(fileList[i])
          appendedAttachment = [
            ...appendedAttachment,
            {
              name: fileList[i].name,
              file: {
                url: url,
                source: FileSource.blobStorage,
              },
            },
          ]
        }

        setAttachments([...attachments, ...appendedAttachment])
      },
      [maxSizeInMb, handleUpload, attachments, showDialog, t],
    )

    return (
      <Container>
        <Grid container direction="row">
          <Grid item mt={4} mb={4}>
            <StyledTypography fontSize={22} fontWeight={600}>
              {t('lessonPlan.detail.lessonPlanComments.reply')}
            </StyledTypography>
          </Grid>
          <Grid item mt={4} mb={4} xs>
            <StyledDivider variant="middle" />
          </Grid>
        </Grid>

        {canCommentLessonPlan && (
          <CommentSectionContainer container direction="row">
            <Grid container>
              <Grid item xs>
                <TextField
                  placeholder={t(
                    'lessonPlan.detail.lessonPlanComments.inputContent',
                  )}
                  value={content}
                  onChange={handleContentOnChange}
                  multiline
                  maxRows={8}
                  mb={0}
                />
              </Grid>
              <Grid item ml={2} alignSelf="center">
                <AttachmentButton
                  variant="text"
                  component="label"
                  disableFocusRipple
                  disableRipple
                  onSubmit={(e) => e.preventDefault()}
                >
                  <Svg.Attachment fill={theme.palettes.general.greys[2]} />
                  <input
                    hidden
                    type="file"
                    onChange={handleSelectedFile}
                    multiple
                  />
                </AttachmentButton>
              </Grid>
              <Grid item ml={2}>
                <StyledButton
                  onClick={createLessonPlanComment}
                  color="warning"
                  startIcon={<Svg.Comment />}
                  disabled={
                    !canCommentLessonPlan ||
                    content == null ||
                    content.length === 0
                  }
                >
                  {t('lessonPlan.detail.lessonPlanComments.actions.create')}
                </StyledButton>
              </Grid>
            </Grid>
            <Grid container>
              {attachments.map((attachment, idx) => (
                <AttachmentLabel
                  key={attachment.name}
                  disableFocusRipple
                  disableRipple
                >
                  <DeleteButtonContainer>
                    <Svg.Close
                      cursor="pointer"
                      onClick={() => {
                        const newAttachments = attachments.slice()
                        newAttachments.splice(idx, 1)
                        setAttachments(newAttachments)
                      }}
                      width={14}
                    />
                  </DeleteButtonContainer>
                  {attachment.name}
                </AttachmentLabel>
              ))}
            </Grid>
          </CommentSectionContainer>
        )}

        {computedLessonPlanCommentsData != null &&
        computedLessonPlanCommentsData.length > 0 ? (
          <>
            <StyledExpandButton
              onClick={handleExpand}
              variant="text"
              endIcon={isExpanded ? <Svg.ChevronUp /> : <Svg.ChevronDown />}
            >
              {t(
                `lessonPlan.detail.lessonPlanComments.${
                  isExpanded ? 'collapse' : 'expand'
                }`,
              )}
            </StyledExpandButton>
            {isExpanded ? (
              <>
                {computedLessonPlanCommentsData.map((comment) => (
                  <LessonPlanCommentCard
                    key={comment.id}
                    lessonPlanId={lessonPlanId}
                    lessonPlanComment={comment}
                    isOwner={currentUserId === comment.createdByUserId}
                    resetPage={resetPage}
                    canComment={canCommentLessonPlan}
                  />
                ))}
                <Grid pt={3} container justifyContent="space-between">
                  <Grid item>
                    <Pagination
                      color="primary"
                      count={totalPage}
                      page={page}
                      onChange={(event, value) => handlePageOnChange(value)}
                      hideNextButton
                    />
                  </Grid>
                  {totalPage > 1 ? (
                    <Grid item>
                      <StyledNextButton
                        variant="text"
                        onClick={() => {
                          if (page >= totalPage) return
                          handlePageOnChange(page + 1)
                        }}
                      >
                        <StyledNextButtonText>
                          {t('myLessonPlan.pagination.next')}
                        </StyledNextButtonText>
                        <Svg.ChevronRight />
                      </StyledNextButton>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </>
            ) : (
              <LessonPlanCommentCard
                key={computedLessonPlanCommentsData[0].id}
                lessonPlanId={lessonPlanId}
                lessonPlanComment={computedLessonPlanCommentsData[0]}
                isOwner={
                  currentUserId ===
                  computedLessonPlanCommentsData[0].createdByUserId
                }
                resetPage={resetPage}
                canComment={canCommentLessonPlan}
              />
            )}
          </>
        ) : (
          <>
            {isLoading || isLatestLessonPlanCommentLoading ? (
              renderLoadingCardSection
            ) : (
              <></>
            )}
          </>
        )}
      </Container>
    )
  },
)
