import { Grid } from '@mui/material'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonGroup, ButtonGroupItemProps } from '../button-group'
type UserProfileActionButtonProps = {
  isEditView: boolean
  handleTransferLessonPlan?: () => void
  handleDisableUser?: () => void
  handleEnableUser?: () => void
  handleSave?: () => void
  handleEdit?: () => void
}

export const UserProfileActionButtons = memo<UserProfileActionButtonProps>(
  ({
    isEditView,
    handleTransferLessonPlan,
    handleDisableUser,
    handleEnableUser,
    handleSave,
    handleEdit,
  }) => {
    const { t } = useTranslation()

    const renderButtons = useMemo((): ButtonGroupItemProps[] => {
      const transferLessonPlanButtonProps: ButtonGroupItemProps = {
        width: 280,
        variant: 'contained',
        color: 'primary',
        fullWidth: true,
        onClick: () => {
          handleTransferLessonPlan?.()
        },
        title: t('users.actions.transferOwnership.title'),
      }

      const disableUserButton: ButtonGroupItemProps = {
        width: 200,
        variant: 'contained',
        color: 'sharpRed',
        fullWidth: true,
        onClick: () => {
          handleDisableUser?.()
        },
        title: t('users.actions.disableAccount.title'),
      }

      const enableUserButton: ButtonGroupItemProps = {
        width: 200,
        variant: 'contained',
        color: 'success',
        fullWidth: true,
        onClick: () => {
          handleEnableUser?.()
        },
        title: t('users.actions.enableAccount.title'),
      }

      if (isEditView) {
        return [
          {
            width: 200,
            variant: 'contained',
            color: 'blue',
            fullWidth: true,
            onClick: () => {
              handleSave?.()
            },
            title: t('users.actions.save.title'),
          },
        ]
      }
      return [
        ...(handleDisableUser != null ? [disableUserButton] : []),
        ...(handleEnableUser != null ? [enableUserButton] : []),
        ...(handleTransferLessonPlan != null
          ? [transferLessonPlanButtonProps]
          : []),
        {
          width: 200,
          color: 'blue',
          fullWidth: true,
          onClick: () => {
            handleEdit?.()
          },
          title: t('users.actions.edit.title'),
        },
      ]
    }, [
      handleDisableUser,
      handleEdit,
      handleEnableUser,
      handleSave,
      handleTransferLessonPlan,
      isEditView,
      t,
    ])

    return (
      <Grid container justifyContent="flex-end">
        <ButtonGroup items={renderButtons} gap={3} />
      </Grid>
    )
  },
)
