export const sidebar = {
  home: 'Homepage',
  myLessonPlan: 'My lesson plan',
  disposedLessonPlan: 'Disposed Lesson Plan',
  lessonPlanSharing: 'Lesson plan exchange',
  consultManagement: 'Consultation management',
  consultFollowingUp: 'Follow up the consultation',
  publishManagement: 'Approve to publish',
  lessonPlanExamples: 'DI examples',
  diStrategies: 'DI strategies',
  resources: 'Resources',
  contactUs: 'Introduction and contact',
  disclaimer: 'Disclaimer',
  lessonPlanSummary: 'Overview of lesson plans',
  schoolSummary: 'School overview',
  submitRecord: 'Submission record',
  systemUser: 'System users',
  schoolUser: 'On -campus users',
  manageContent: 'Content management',
  systemParameter: 'System data',
}
