import { GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { OutstandingLessonPlanResponsePayload } from '@polyu-dip/apis'
import { Grid, Tooltip } from '@mui/material'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { Tag, TruncatedTitle } from '../../components'
import { getTextWidth, useDisplayName } from '../../services'
import { useCallback } from 'react'

export type RequestType = 'consult' | 'publish' | 'outstanding'

export type PublishTabType = 'pending' | 'published' | 'other'

export type ConsultTabType = 'pending' | 'followingUp' | 'completed'

export type TabType = PublishTabType | ConsultTabType

const StyledHeader = styled(TruncatedTitle)`
  color: ${({ theme }) => theme.palettes.general.blue};
`

export function useOutstandingLessonPlanRequestColumn() {
  const { t } = useTranslation()
  const { getDisplayName } = useDisplayName()

  const renderColumn = useCallback(
    (
      requestType: RequestType,
      tabType?: TabType,
      handleLetterOnClick?: (
        requestId: string,
        requestText: string,
        requireObservation: boolean,
      ) => void,
      isHomeTable?: boolean,
    ) => {
      const consultContentColumn: GridColDef<OutstandingLessonPlanResponsePayload>[] =
        [
          {
            field: 'requestType',
            headerName: t('lessonPlanRequest.columnTitles.isConsultRequest'),
            width: 60,
            renderCell: ({ row }: any) =>
              row.consultContent != null ? (
                <Grid item mt={2}>
                  <Svg.Letter
                    onClick={(e) => {
                      e.stopPropagation()
                      handleLetterOnClick?.(
                        row?.id ?? '',
                        row?.consultContent ?? '',
                        row?.isSiteVisitNeeded ?? false,
                      )
                    }}
                  />
                </Grid>
              ) : (
                <Grid item ml={1}>
                  <StyledHeader>---</StyledHeader>
                </Grid>
              ),
            sortable: false,
          },
        ]

      const lessonPlanDetailColumn: GridColDef<OutstandingLessonPlanResponsePayload>[] =
        [
          {
            field: 'classLevel.order',
            headerName: t('lessonPlanRequest.columnTitles.class'),
            width: 120,
            renderCell: ({ row }: any) => (
              <Tag
                label={getDisplayName(row.classLevel?.name)}
                colorCustomize={row.classLevel?.colorCode}
              />
            ),
          },
          {
            field: 'subject.name',
            headerName: t('lessonPlanRequest.columnTitles.subject'),
            width: 200,
            renderCell: ({ row }: any) => (
              <Tag
                label={getDisplayName(row.subject?.name)}
                colorCustomize={row.subject?.colorCode}
              />
            ),
          },
        ]

      const assigneeColumn: GridColDef<OutstandingLessonPlanResponsePayload>[] =
        [
          {
            field: 'assignedToUserName',
            headerName: t('lessonPlanRequest.columnTitles.expert'),
            width: 200,
            renderCell: ({ row }: any) => (
              <Tooltip
                title={
                  (getTextWidth(row.assignedToUserName, 'lato') ?? 0) > 100
                    ? row.assignedToUserName
                    : ''
                }
                arrow
              >
                <StyledHeader>{row.assignedToUserName ?? '---'}</StyledHeader>
              </Tooltip>
            ),
          },
        ]

      const isAgreePublishColumn: GridColDef<OutstandingLessonPlanResponsePayload>[] =
        [
          {
            field: 'isAgreePublish',
            headerName: t('lessonPlanRequest.columnTitles.isAgreePublish'),
            width: 200,
            valueFormatter: ({ value }: any) =>
              t(value ? 'common.yes' : 'common.no'),
          },
        ]
      const dateTimeColumn: GridColDef<OutstandingLessonPlanResponsePayload>[] =
        // eslint-disable-next-line no-nested-ternary
        tabType === 'other'
          ? [
              {
                field: 'lastModifiedDateTime',
                headerName: t(
                  'lessonPlanRequest.columnTitles.unPublishedOrRejectedDateTime',
                ),
                width: 200,
                renderCell: ({ row }: any) => (
                  <StyledHeader>
                    {row.lastModifiedDateTime
                      ? dayjs(row.lastModifiedDateTime).format(
                          t('common.format.datetime'),
                        )
                      : '---'}
                  </StyledHeader>
                ),
              },
            ]
          : tabType === 'published'
          ? [
              {
                field: 'publishedDateTime',
                headerName: t(
                  'lessonPlanRequest.columnTitles.publishedDateTime',
                ),
                width: 200,
                renderCell: ({ row }: any) => (
                  <StyledHeader>
                    {row.publishedDateTime != null
                      ? dayjs(row.publishedDateTime).format(
                          t('common.format.datetime'),
                        )
                      : null}
                  </StyledHeader>
                ),
              },
            ]
          : [
              {
                field: 'lastModifiedDateTime',
                headerName: t(
                  'lessonPlanRequest.columnTitles.lastModifiedDateTime',
                ),
                width: 200,
                renderCell: ({ row }: any) => (
                  <StyledHeader>
                    {row.lastModifiedDateTime != null
                      ? dayjs(row.lastModifiedDateTime).format(
                          t('common.format.datetime'),
                        )
                      : '---'}
                  </StyledHeader>
                ),
              },
            ]

      return [
        ...(requestType === 'consult'
          ? consultContentColumn
          : lessonPlanDetailColumn),
        {
          field: 'targetLessonPlan.teachingTopic',
          headerName: t('lessonPlanRequest.columnTitles.teachingTopic'),
          width: 400,
          renderCell: ({ row }: any) => (
            <Tooltip
              title={
                (getTextWidth(row.targetLessonPlan?.teachingTopic, 'lato') ??
                  0) > 200
                  ? row.targetLessonPlan?.teachingTopic
                  : ''
              }
              arrow
            >
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item overflow="hidden">
                  <StyledHeader fontWeight={600}>
                    {row.targetLessonPlan.teachingTopic}
                  </StyledHeader>
                </Grid>
              </Grid>
            </Tooltip>
          ),
        },
        ...(requestType === 'consult' && !isHomeTable ? assigneeColumn : []),
        ...((requestType === 'outstanding' && isHomeTable) ||
        requestType === 'publish'
          ? isAgreePublishColumn
          : []),
        ...dateTimeColumn,
        {
          field: 'status',
          headerName: t('lessonPlanRequest.columnTitles.status'),
          width: 170,
          renderCell: ({ row }: any) => (
            <Tag
              isStatus
              statusType={row.type}
              label={
                // eslint-disable-next-line no-nested-ternary
                row.type !== 'consultRequest' && row.status == 'completed'
                  ? row.isPublishing
                    ? 'publishing'
                    : 'unPublishing'
                  : row.status
              }
            />
          ),
        },
      ]
    },
    [getDisplayName, t],
  )

  return { renderColumn }
}
