import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { applicationNamespace, Resources } from './definitions'

export function initI18n(resources: Resources, useSuspense?: boolean) {
  const supportedLanguages = Object.keys(resources)
  const previousLanguages = window.localStorage.getItem('i18n-language')

  return i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    defaultNS: applicationNamespace,
    ns: [applicationNamespace],
    react: { useSuspense: useSuspense ?? false },

    resources: supportedLanguages.reduce(
      (acc, lang) => ({
        ...acc,
        [lang]: {
          [applicationNamespace]: resources[lang],
        },
      }),
      {},
    ),
    interpolation: {
      escapeValue: false,
    },

    lng: previousLanguages ?? supportedLanguages[0],
  })
}
