import { createContext, useContext } from 'react'

type Context = {
  activeStep: number
  setActiveStep: (step: number) => void
}

export const GeneralInformationFormContext = createContext<Context>({} as any)

export function useGeneralInformationFormContext() {
  return useContext(GeneralInformationFormContext)
}
