import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogAction } from '../dialog'
import { SelectOption } from '../form'
import { useOverlay } from '../overlay-provider'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Divider, Grid } from '@mui/material'
import { UploadFile, UploadFileByUrl } from '../upload-attachment'
import { useBlobStorage } from '../../services'
import { FileSource } from '@polyu-dip/models'
import {
  NewDownloadableResourceFormData,
  NewDownloadableResourceList,
} from './new-downloadable-resource-list'
import {
  clearResourcesQueryCaches,
  useCreateResources,
  useDisableResource,
  useUpdateResource,
} from '@polyu-dip/queries'
import { useStores } from '../../stores'
import { useApiErrorHandle, useFormErrorTranslationTrigger } from '../../hooks'
import { useQueryClient } from '@tanstack/react-query'
import { PutResourcePayload } from '@polyu-dip/apis'
import styled from 'styled-components'
import { isValidUrl } from '../../utilities'

type CommonProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  classLevelOptions: (SelectOption & {
    educationLevelId?: string
  })[]
  subjectOptions: (SelectOption & {
    educationLevelIds: string[]
  })[]
  resourceTypeOptions: SelectOption[]
}

export type EditDownloadableResourceDataRowType = {
  id: string
  title: string
  resourceTypeId: string
  subjectId?: string
  classLevelId?: string
  file: {
    url: string
    source: string
  }
  rowVersion: string
}

type Props =
  | ({
      mode: 'create'
      resourceData?: never
    } & CommonProps)
  | ({
      mode: 'edit'
      resourceData?: EditDownloadableResourceDataRowType
    } & CommonProps)

type DialogActionButtonValueType = 'create' | 'close' | 'update' | 'delete'

export const MAX_FILE_SIZE_IN_MB = 100

const StyledDivider = styled(Divider)`
  margin-top: 8px;
  margin-bottom: 8px;
`

export const DownloadableResourceDialog = observer<Props>(
  ({
    mode,
    isOpen,
    setIsOpen,
    classLevelOptions,
    subjectOptions,
    resourceTypeOptions,
    resourceData,
  }) => {
    const { t } = useTranslation()
    const { showSpinner, hideSpinner, showSnackbar } = useOverlay()
    const { handleUpload } = useBlobStorage()
    const { standardErrorHandler } = useApiErrorHandle()
    const queryClient = useQueryClient()

    const [canSubmit, setCanSubmit] = useState(false)
    const [canUpdate, setCanUpdate] = useState(false)
    const [canDelete, setCanDelete] = useState(false)

    const schema = useMemo(
      () =>
        yup.object({
          newResourcesFile: yup.array(
            yup.object({
              title: yup.string().required(t('error.required')),
              resourceTypeId: yup.string().required(t('error.required')),
              subjectId: yup.string(),
              classLevelId: yup.string(),
              file: yup.object({
                url: yup.string(),
                source: yup.string(),
              }),
            }),
          ),
          newResourcesLink: yup.array(
            yup.object({
              title: yup.string().required(t('error.required')),
              resourceTypeId: yup.string().required(t('error.required')),
              subjectId: yup.string(),
              classLevelId: yup.string(),
              file: yup.object({
                url: yup
                  .string()
                  .required(t('error.required'))
                  .url(t('error.invalidUrlFormat')),
                source: yup.string(),
              }),
            }),
          ),
        }),
      [t],
    )

    const {
      control,
      formState: { errors },
      getValues,
      setValue,
      trigger,
      reset,
      clearErrors,
      watch,
    } = useForm<NewDownloadableResourceFormData>({
      resolver: yupResolver(schema),
      defaultValues: {
        newResourcesFile: [],
        newResourcesLink: [],
      },
    })

    useFormErrorTranslationTrigger(errors, trigger)

    useEffect(() => {
      if (mode === 'create') {
        reset({
          newResourcesFile: [],
          newResourcesLink: [],
        })
      }
      if (mode === 'edit') {
        const newResource = {
          title: resourceData?.title,
          resourceTypeId: resourceData?.resourceTypeId,
          subjectId: resourceData?.subjectId,
          classLevelId: resourceData?.classLevelId,
          file: {
            url: resourceData?.file?.url,
            source: resourceData?.file?.source,
          },
        }

        reset({
          newResourcesFile:
            resourceData?.file?.source === FileSource.blobStorage
              ? [newResource]
              : [],
          newResourcesLink:
            resourceData?.file?.source === FileSource.user ? [newResource] : [],
        })
      }
      if (resourceData != null) {
        setCanDelete(true)
      }
    }, [
      mode,
      reset,
      resourceData,
      resourceData?.classLevelId,
      resourceData?.file?.source,
      resourceData?.file?.url,
      resourceData?.resourceTypeId,
      resourceData?.subjectId,
      resourceData?.title,
    ])

    const handleUploadFile = useCallback(
      async (file: File) => {
        const url = await handleUpload(file)
        setValue('newResourcesFile', [
          ...getValues('newResourcesFile'),
          {
            title: file.name,
            file: {
              url: url,
              source: FileSource.blobStorage,
            },
            classLevelId: undefined,
            subjectId: undefined,
            resourceTypeId: undefined,
          },
        ])
      },
      [getValues, handleUpload, setValue],
    )

    const handleAddUrl = useCallback(
      (url: string) => {
        setValue('newResourcesLink', [
          ...getValues('newResourcesLink'),
          {
            title: url,
            file: {
              url: url,
              source: FileSource.user,
            },
            classLevelId: undefined,
            subjectId: undefined,
            resourceTypeId: undefined,
          },
        ])
      },
      [getValues, setValue],
    )

    const createDownloadableResourceDialogActions =
      useMemo((): DialogAction<DialogActionButtonValueType>[] => {
        if (mode === 'create') {
          return [
            {
              text: t('common.close'),
              type: 'outlined',
              value: 'close',
            },
            {
              text: t('resources.createDownloadableResource.action.create'),
              type: 'contained',
              value: 'create',
              color: 'blue',
              disabled: !canSubmit,
            },
          ]
        }
        return [
          {
            text: t('common.close'),
            type: 'outlined',
            value: 'close',
          },
          {
            text: t('resources.editDownloadableResource.action.delete'),
            type: 'contained',
            value: 'delete',
            color: 'sharpRed',
            disabled: !canDelete,
          },
          {
            text: t('resources.editDownloadableResource.action.update'),
            type: 'contained',
            value: 'update',
            color: 'blue',
            disabled: !canUpdate,
          },
        ]
      }, [canDelete, canSubmit, canUpdate, mode, t])

    const handleOnClose = useCallback(() => {
      reset()
      clearErrors()
      setIsOpen(false)
    }, [clearErrors, reset, setIsOpen])

    const { mutateAsync: createResources } = useCreateResources(useStores, {
      onSuccess: () => {
        handleOnClose()
        showSnackbar({
          message: t('resources.uploadResources.success'),
        })
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('resources.uploadResources.error'),
        })
      },
    })

    const { mutateAsync: updateResource } = useUpdateResource(
      useStores,
      resourceData?.id ?? '',
      {
        onSuccess: () => {
          clearResourcesQueryCaches(queryClient)
          handleOnClose()
          showSnackbar({
            message: t('resources.updateResources.success'),
          })
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t('resources.updateResources.error'),
          })
        },
      },
    )

    const { mutateAsync: disableResource } = useDisableResource(
      useStores,
      resourceData?.id ?? '',
      {
        onSuccess: () => {
          clearResourcesQueryCaches(queryClient)
          handleOnClose()
          showSnackbar({
            message: t('resources.deleteResources.success'),
          })
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t('resources.deleteResources.error'),
          })
        },
      },
    )

    const processCreateModeAction = useCallback(async () => {
      const fileResource = getValues('newResourcesFile')
      const linkResource = getValues('newResourcesLink')
      const formData = [...fileResource, ...linkResource]
      const hasUnFinishedField = formData.some(
        (data) =>
          !(
            (data?.title == null || data.title?.length === 0) &&
            (data?.file?.url == null || data.file.url?.length === 0) &&
            (data?.resourceTypeId == null || data.resourceTypeId?.length === 0)
          ) &&
          !(
            data?.title?.length > 0 &&
            data?.file?.url?.length > 0 &&
            isValidUrl(data.file.url) &&
            data?.resourceTypeId != null &&
            data.resourceTypeId?.length > 0
          ),
      )
      if (hasUnFinishedField && !(await trigger())) return

      const payload = formData.map(
        ({ title, subjectId, classLevelId, file, resourceTypeId }) => ({
          title,
          resourceTypeId: resourceTypeId ?? '',
          isOnlineResource: false,
          subjectId,
          classLevelId,
          file,
        }),
      )
      await createResources(payload)
    }, [createResources, getValues, trigger])

    const processEditModeAction = useCallback(
      async (action: DialogActionButtonValueType) => {
        if (action === 'delete') {
          await disableResource(resourceData?.rowVersion ?? '')
          return
        }

        if (!(await trigger())) return
        const fileResource = getValues('newResourcesFile')
        const linkResource = getValues('newResourcesLink')
        const updatedResourceData = [...fileResource, ...linkResource]
        const formData = updatedResourceData[0]
        const payload: PutResourcePayload = {
          rowVersion: resourceData?.rowVersion ?? '',
          isOnlineResource: false,
          subjectId: formData.subjectId ?? '',
          classLevelId: formData.classLevelId ?? '',
          isDisabled: false,

          title: formData.title,
          resourceTypeId: formData?.resourceTypeId ?? '',
          file: formData.file,
        }
        await updateResource(payload)
      },
      [
        disableResource,
        getValues,
        resourceData?.rowVersion,
        trigger,
        updateResource,
      ],
    )

    const handleOnSelectedDialogAction = useCallback(
      async (value: DialogActionButtonValueType) => {
        if (value === 'close' || value == null) {
          handleOnClose()
          return
        }
        try {
          showSpinner()
          if (mode === 'create') {
            await processCreateModeAction()
          } else {
            await processEditModeAction(value)
          }
        } finally {
          hideSpinner()
        }
      },
      [
        handleOnClose,
        hideSpinner,
        mode,
        processCreateModeAction,
        processEditModeAction,
        showSpinner,
      ],
    )

    useEffect(() => {
      const subscription = watch(async () => {
        clearErrors()
        const shouldEnableSubmitButtons =
          getValues('newResourcesFile').some(
            (data) =>
              data.title?.length > 0 ||
              data.file?.url?.length > 0 ||
              (data?.resourceTypeId != null && data.resourceTypeId?.length > 0),
          ) ||
          getValues('newResourcesLink').some(
            (data) =>
              data.title?.length > 0 ||
              data.file?.url?.length > 0 ||
              (data?.resourceTypeId != null && data.resourceTypeId?.length > 0),
          )
        setCanSubmit(shouldEnableSubmitButtons)
        setCanUpdate(shouldEnableSubmitButtons)
      })
      return () => subscription.unsubscribe()
    }, [clearErrors, getValues, watch])

    const allowDialogDeleteRow = useMemo(() => {
      return mode === 'create' ? true : false
    }, [mode])

    return (
      <Dialog
        open={isOpen}
        title={t('resources.createDownloadableResource.title')}
        actions={createDownloadableResourceDialogActions}
        onSelected={handleOnSelectedDialogAction}
        maxWidth="lg"
        fullWidth
      >
        <Grid container direction="column" wrap="nowrap">
          <NewDownloadableResourceList
            fieldName="newResourcesFile"
            control={control}
            errors={errors}
            classLevelOptions={classLevelOptions}
            subjectOptions={subjectOptions}
            resourceTypeOptions={resourceTypeOptions}
            allowDeleteRow={allowDialogDeleteRow}
            getValues={getValues}
            setValue={setValue}
          />
          {mode === 'edit' ? (
            <></>
          ) : (
            <>
              <Grid container justifyContent="center" mt={5} mb={5}>
                <UploadFile
                  onUploadFile={handleUploadFile}
                  maxSizeInMb={MAX_FILE_SIZE_IN_MB}
                />
              </Grid>
              <StyledDivider />
            </>
          )}
          <NewDownloadableResourceList
            fieldName="newResourcesLink"
            control={control}
            errors={errors}
            classLevelOptions={classLevelOptions}
            subjectOptions={subjectOptions}
            resourceTypeOptions={resourceTypeOptions}
            allowDeleteRow={allowDialogDeleteRow}
            getValues={getValues}
            setValue={setValue}
          />
          {mode === 'edit' ? (
            <></>
          ) : (
            <Grid item mt={5}>
              <UploadFileByUrl onConfirmButtonClicked={handleAddUrl} />
            </Grid>
          )}
        </Grid>
      </Dialog>
    )
  },
)
