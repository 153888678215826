import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { env } from '../utilities'

export const DevOnlyPageGuard = observer(() => {
  const navigate = useNavigate()
  useEffect(() => {
    if (env.environment !== 'DEV') {
      navigate('/')
    }
  }, [navigate])
  if (env.environment === 'DEV')
    return (
      <>
        <Outlet />
      </>
    )
  return <></>
})
