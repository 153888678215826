import { LessonPlan } from '@polyu-dip/models'
import { createContext, useContext } from 'react'

type ContextProps = {
  lessonPlan: LessonPlan
}

export const LessonPlanDetailContext = createContext<ContextProps>(
  {} as ContextProps,
)

export const useLessonPlanDetail = () => useContext(LessonPlanDetailContext)
