import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  PostTagGroupPayload,
  PutTagGroupPayload,
  TagGroupResponsePayload,
  TagGroupsQueryParameters,
  TagGroupsResponsePayload,
} from './tag-groups-api.type'

const PATH = '/tag-groups'

export function getTagGroups(param?: TagGroupsQueryParameters) {
  return ApiCore.primary.get<TagGroupsResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}

export function postTagGroupBulk(payloads: PostTagGroupPayload[]) {
  return ApiCore.primary.post<TagGroupResponsePayload>(`${PATH}/bulk`, {
    body: payloads,
  })
}

export function putTagGroupById(id: string, payload: PutTagGroupPayload) {
  return ApiCore.primary.put<TagGroupResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}
