import { ApiCore } from '@polyu-dip/api-core'
import { convertExpandParameters, convertSortParameters } from '../base'
import {
  PostSubjectPayload,
  PutSubjectPayload,
  SubjectResponsePayload,
  SubjectsQueryParameters,
  SubjectsResponsePayload,
} from './subjects-api.type'

const PATH = '/subjects'

export function getSubjects(param?: SubjectsQueryParameters) {
  return ApiCore.primary.get<SubjectsResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: convertExpandParameters(param),
    },
  })
}

export function postSubjectsBulk(payloads: PostSubjectPayload[]) {
  return ApiCore.primary.post<SubjectResponsePayload[]>(`${PATH}/bulk`, {
    body: payloads,
  })
}

export function putSubjectById(id: string, payload: PutSubjectPayload) {
  return ApiCore.primary.put<SubjectResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}
