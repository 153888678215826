import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { NameModel } from './languages-json'

export const EducationLevelModel = types
  .compose(BaseEntityModel, NameModel)
  .named('EducationLevelModel')
  .props({
    id: types.string,
    order: types.number,
    colorCode: types.string,
    isDisabled: types.boolean,
  })
  .extend(withUpdate)

export type EducationLevel = Instance<typeof EducationLevelModel>
