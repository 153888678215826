import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled.span<{ isActive?: boolean }>`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme, isActive }) =>
    theme.palettes.general.greys[isActive ? 1 : 2]};
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
`

const Separator = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palettes.general.greys[2]};
`

const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)`
  margin: 12px 0px;
`

export type BreadcrumbsProps = {
  breadcrumbs: LinkProps[]
}

export const Breadcrumbs = observer(({ breadcrumbs }: BreadcrumbsProps) => {
  return (
    <StyledMuiBreadcrumbs
      aria-label="breadcrumb"
      separator={<Separator>/</Separator>}
    >
      {breadcrumbs.map((item, index, self) => (
        // this is an exact match of prop types
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Link {...item} key={index}>
          <StyledLink isActive={index === self.length - 1}>
            {item.children}
          </StyledLink>
        </Link>
      ))}
    </StyledMuiBreadcrumbs>
  )
})
