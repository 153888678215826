import { Button, Typography } from '@mui/material'
import { MasterDataTable } from '@polyu-dip/stores'
import { observer } from 'mobx-react-lite'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../assets'
import {
  MasterDataManagementDialog,
  VerticalTabPanel,
  VerticalTabs,
} from '../../components'
import { MasterDataList } from './master-data-list'

type Props = {
  selectedMasterDataTab: MasterDataTable
  targetMasterData: { label: string; id: string }[]
  inputTitle?: string
  setActiveMasterDataContentTab: Dispatch<SetStateAction<number>>
  activeMasterDataContentTab: number
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
`

const StyledButton = styled(Button)``

export const MasterDataContent = observer<Props>(
  ({
    targetMasterData,
    selectedMasterDataTab,
    inputTitle,
    setActiveMasterDataContentTab,
    activeMasterDataContentTab,
  }) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)
    const [isEditOpen, setIsEditOpen] = useState(false)
    const [editClassId, setEditClassId] = useState<string>()

    const handleMasterDataContentTabOnClick = useCallback(
      (id: number) => {
        setActiveMasterDataContentTab(id)
      },
      [setActiveMasterDataContentTab],
    )

    const handleEditClass = useCallback((id: string) => {
      setIsEditOpen(true)
      setEditClassId(id)
    }, [])

    return (
      <Container>
        <VerticalTabs
          activeTab={activeMasterDataContentTab}
          tabMenus={targetMasterData}
          onActiveTabChange={handleMasterDataContentTabOnClick}
          minTabWidth={250}
          isTabHidden={
            targetMasterData.length == 0 ||
            selectedMasterDataTab === 'subjects' ||
            selectedMasterDataTab === 'resourceTypes' ||
            selectedMasterDataTab === 'systemParameters'
          }
        >
          {targetMasterData.map((content, index) => (
            <VerticalTabPanel
              key={index}
              index={index}
              value={activeMasterDataContentTab}
              isTabHidden={
                targetMasterData.length == 0 ||
                selectedMasterDataTab === 'subjects' ||
                selectedMasterDataTab === 'resourceTypes' ||
                selectedMasterDataTab === 'systemParameters'
              }
            >
              <MasterDataList
                selectedContentId={content.id}
                title={content.label}
                selectedMasterDataTab={selectedMasterDataTab}
                inputTitle={inputTitle}
                handleEditClass={handleEditClass}
              />
            </VerticalTabPanel>
          ))}
        </VerticalTabs>
        {selectedMasterDataTab === 'diStrategies' ||
        selectedMasterDataTab === 'tags' ? (
          <>
            <StyledButton
              variant="text"
              startIcon={<Svg.Plus />}
              color="darkestGrey"
              onClick={() => setIsOpen(true)}
            >
              <Typography>{t('masterData.action.addClass')}</Typography>
            </StyledButton>
            <MasterDataManagementDialog
              mode="create"
              masterDataTable={
                selectedMasterDataTab === 'diStrategies'
                  ? 'diStrategyClasses'
                  : 'tagTypes'
              }
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </>
        ) : (
          <></>
        )}
        <MasterDataManagementDialog
          mode="edit"
          masterDataTable={
            selectedMasterDataTab === 'diStrategies'
              ? 'diStrategyClasses'
              : 'tagTypes'
          }
          selectedId={editClassId}
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
        />
      </Container>
    )
  },
)
