import { withRootStore } from '@polyu-dip/model-core'
import { types } from 'mobx-state-tree'

export const LanguagesJsonModel = types
  .model('LanguageJson', {
    zh: types.maybe(types.string),
    en: types.maybe(types.string),
  })
  .extend(withRootStore)
  .views((self) => ({
    translation: (targetLanguage?: 'zh' | 'en') => {
      const target = targetLanguage ?? self.rootStore.uiStore.language
      if (target === 'en' && self.en) return self.en
      if (self.zh) return self.zh
      return undefined
    },
  }))
