import { Grid, Tooltip } from '@mui/material'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { StepIcon, StepIconProps } from './step-icon'

const QuadrantCircleContainer = styled.div`
  width: 30px;
  height: 30px;
  overflow: hidden;
`

const SemiCircleContainer = styled.div`
  width: 30px;
  height: 60px;
  overflow: hidden;
`

const LeftTopStepIcon = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
`

const RightTopStepIcon = styled.div`
  position: relative;
  top: 0px;
  left: -30px;
`

const LeftBottomStepIcon = styled.div`
  position: relative;
  top: -30px;
  left: 0px;
`

const RightBottomStepIcon = styled.div`
  position: relative;
  top: -30px;
  left: -30px;
`

export type SubStepItemProps = {
  id: number
  tooltipTitle?: string
}

export type SubStepIconProps = {
  activeStep: number
  stepId: number
  subSteps: SubStepItemProps[]
  handleStepClick: (step: number) => void
  isLoading?: boolean
  prevStep?: number
} & StepIconProps

export const SubStepIcon = memo<SubStepIconProps>(
  ({
    activeStep,
    stepId,
    subSteps,
    handleStepClick,
    isLoading,
    prevStep,
    ...props
  }) => {
    const stepIds = useMemo(
      (): number[] => subSteps.map((it) => it.id),
      [subSteps],
    )

    if (subSteps.length != 2 && subSteps.length != 4) {
      console.error('SubStepIcon supports 2 or 4 subSteps only')
      return <></>
    }

    return (
      <Grid container direction="column">
        {subSteps.length == 4 ? (
          <>
            <Grid container direction="row">
              <Tooltip title={subSteps[3].tooltipTitle} arrow placement="left">
                <QuadrantCircleContainer>
                  <LeftTopStepIcon>
                    <StepIcon
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      handleStepClick={handleStepClick}
                      step={stepIds[3]}
                      active={
                        activeStep >= stepIds[3] && activeStep < stepId + 1
                      }
                      completed={activeStep > stepIds[3]}
                      isLoading={isLoading}
                      prevStep={prevStep}
                    />
                  </LeftTopStepIcon>
                </QuadrantCircleContainer>
              </Tooltip>
              <Tooltip title={subSteps[0].tooltipTitle} arrow placement="right">
                <QuadrantCircleContainer>
                  <RightTopStepIcon>
                    <StepIcon
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      handleStepClick={handleStepClick}
                      step={stepIds[0]}
                      active={
                        activeStep >= stepIds[0] && activeStep < stepId + 1
                      }
                      completed={activeStep > stepIds[3]}
                      isLoading={isLoading}
                      prevStep={prevStep}
                    />
                  </RightTopStepIcon>
                </QuadrantCircleContainer>
              </Tooltip>
            </Grid>
            <Grid container direction="row">
              <Tooltip title={subSteps[2].tooltipTitle} arrow placement="left">
                <QuadrantCircleContainer>
                  <LeftBottomStepIcon>
                    <StepIcon
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      handleStepClick={handleStepClick}
                      step={stepIds[2]}
                      active={
                        activeStep >= stepIds[2] && activeStep < stepId + 1
                      }
                      completed={activeStep > stepIds[3]}
                      isLoading={isLoading}
                      prevStep={prevStep}
                    />
                  </LeftBottomStepIcon>
                </QuadrantCircleContainer>
              </Tooltip>
              <Tooltip title={subSteps[1].tooltipTitle} arrow placement="right">
                <QuadrantCircleContainer>
                  <RightBottomStepIcon>
                    <StepIcon
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      handleStepClick={handleStepClick}
                      step={stepIds[1]}
                      active={
                        activeStep >= stepIds[1] && activeStep < stepId + 1
                      }
                      completed={activeStep > stepIds[3]}
                      isLoading={isLoading}
                      prevStep={prevStep}
                    />
                  </RightBottomStepIcon>
                </QuadrantCircleContainer>
              </Tooltip>
            </Grid>
          </>
        ) : (
          <>
            <Grid container direction="row">
              <Tooltip title={subSteps[1].tooltipTitle} arrow placement="left">
                <SemiCircleContainer>
                  <LeftTopStepIcon>
                    <StepIcon
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      handleStepClick={handleStepClick}
                      step={stepIds[0]}
                      active={
                        activeStep >= stepIds[0] && activeStep < stepId + 1
                      }
                      completed={activeStep > stepIds[1]}
                      isLoading={isLoading}
                      prevStep={prevStep}
                    />
                  </LeftTopStepIcon>
                </SemiCircleContainer>
              </Tooltip>
              <Tooltip title={subSteps[1].tooltipTitle} arrow placement="right">
                <SemiCircleContainer>
                  <RightTopStepIcon>
                    <StepIcon
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      handleStepClick={handleStepClick}
                      step={stepIds[1]}
                      active={
                        activeStep >= stepIds[1] && activeStep < stepId + 1
                      }
                      completed={activeStep > stepIds[1]}
                      isLoading={isLoading}
                      prevStep={prevStep}
                    />
                  </RightTopStepIcon>
                </SemiCircleContainer>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>
    )
  },
)
