import { useComputed } from '@polyu-dip/helpers'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { useStores } from '../../stores'
import { FullscreenLoading } from '../fullscreen-loading'
import { FullScreenText } from '../text'
import { SideBar } from './side-bar'
import { TopBar } from './top-bar'

type PageFramePropType = {}

const PageDiv = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const BottomDiv = styled.div`
  padding-left: 272px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palettes.general.background};
`

const ContentDiv = styled.div`
  flex-grow: 1;
  padding-left: 32px;
  padding-top: 12px;
  padding-right: 96px;
  padding-bottom: 64px;
  background-color: ${({ theme }) => theme.palettes.general.background};
`

export const PageFrame = observer<PageFramePropType>(() => {
  const { userProfileStore } = useStores()
  const { t } = useTranslation()

  const isCurrentUserForbidden = useComputed(() => {
    return userProfileStore.userProfile?.isAccountDisabled
  }, [userProfileStore.userProfile])

  return (
    <PageDiv>
      <SideBar />
      <TopBar />
      <BottomDiv>
        <ContentDiv>
          {
            // eslint-disable-next-line no-nested-ternary
            isCurrentUserForbidden == null ? (
              <FullscreenLoading />
            ) : isCurrentUserForbidden ? (
              <FullScreenText title={t('users.forbidden')} />
            ) : (
              <Outlet />
            )
          }
        </ContentDiv>
      </BottomDiv>
    </PageDiv>
  )
})
