export const general = {
  transparent: 'rgba(0, 0, 0, 0)',
  blackTransparent: 'rgba(0,0,0,0.8)',
  greyTransparent: 'rgba(0,0,0,0.4)',
  whiteTransparent: 'rgba(255 ,255 ,255 ,0.8)',

  black: '#000000',
  white: '#FFFFFF',

  background: '#F4F4F4',

  blacks: ['#000000', '#1D1D1D', '#2A2A2A'],
  greys: ['#333333', '#4F4F4F', '#828282', '#BDBDBD', '#E0E0E0'],
  oranges: ['#FFEAD6', '#F4E7D4'],

  info: '#2F80ED',
  success: '#19B862',
  warning: '#FAB123',
  error: '#FF3434',
  disabled: '#E0E0E0',

  borderStroke: '#DADADA',
  highlighted: '#FFFEF2',
  blue: '#3888C6',
  highlight: '#3CADFE',
  lightBlue: '#EFF8FF',
  lightRed: '#EC796A',
  sharpRed: '#C95D63',
  cascade: '#74938A',
  lightGreen: '#DAF3D6',
  lightGray: '#ECECEC',
}
