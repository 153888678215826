import { LearningUnitsQueryParameters, PaginatedPayload } from '@polyu-dip/apis'
import { LearningUnit } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'learning-units'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useLearningUnits(
  useStores: UseStoresType,
  param?: LearningUnitsQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<LearningUnit>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getLearningUnits(param),
    opts,
  )
}
