import styled from 'styled-components'
import { Api } from '@polyu-dip/apis'
import { useQuery } from '@tanstack/react-query'
import pkg from '../../package.json'

type HealthCheckPagePropType = {}

const Page = styled.article`
  margin: 20px;
`

export const HealthCheckPage: React.FunctionComponent<
  HealthCheckPagePropType
> = () => {
  const { data } = useQuery(['healthCheck'], () =>
    Api.healthCheck.getServerStatus(),
  )

  return (
    <Page>
      <h4>health check page</h4>
      <p>
        web server status: running <br />
        version: {pkg.version}
      </p>
      <div>
        <div>api server status:</div>
        <div>
          <pre>{JSON.stringify(data, null, '\t')}</pre>
        </div>
      </div>
    </Page>
  )
}
