import { Divider, Grid } from '@mui/material'
import { Dispatch, memo, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ViewModeType } from '../../../hooks'
import { AutoComplete, Dropdown, SelectOption, TextField } from '../../form'
import { SearchLeftIcon } from '../search-left-icon'
import { ViewModeButtons } from '../view-mode-buttons'

const Container = styled.div`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding: ${({ theme }) => theme.spacings.general[4]}px;
`

const StyledDivider = styled(Divider)`
  border-bottom-width: 2px;
  border-bottom-color: ${({ theme }) => theme.palettes.general.lightRed};
`

const StyledDropdown = styled(Dropdown)``

export type LessonPlanFilterProps = {
  viewMode?: ViewModeType
  setViewMode?: Dispatch<SetStateAction<ViewModeType>>
  educationLevelOptions?: SelectOption[]
  subjectOptions?: SelectOption[]
  learningPhaseOptions?: SelectOption[]
  diStrategyClassOptions?: SelectOption[]
  diStrategyOptions?: SelectOption[]
  resourceTypeOptions?: SelectOption[]
  selectedEducationLevel?: SelectOption
  setSelectedEducationLevel?: Dispatch<SetStateAction<SelectOption | undefined>>
  selectedSubject?: SelectOption
  setSelectedSubject?: Dispatch<SetStateAction<SelectOption | undefined>>
  selectedLearningPhase?: SelectOption
  setSelectedLearningPhase?: Dispatch<SetStateAction<SelectOption | undefined>>
  selectedDiStrategyClass?: SelectOption
  setSelectedDiStrategyClass?: Dispatch<
    SetStateAction<SelectOption | undefined>
  >
  selectedDiStrategy?: SelectOption
  setSelectedDiStrategy?: Dispatch<SetStateAction<SelectOption | undefined>>
  selectedResourceType?: SelectOption
  setSelectedResourceType?: Dispatch<SetStateAction<SelectOption | undefined>>
  inputTeachingTopic?: string
  setInputTeachingTopic?: Dispatch<SetStateAction<string | undefined>>
  sortItemOptions: SelectOption[]
  selectedSortItem?: string
  setSelectedSortItem?: Dispatch<SetStateAction<string>>
}

export const LessonPlanFilter = memo<LessonPlanFilterProps>(
  ({
    viewMode,
    setViewMode,
    educationLevelOptions,
    subjectOptions,
    learningPhaseOptions,
    diStrategyClassOptions,
    diStrategyOptions,
    resourceTypeOptions,
    selectedEducationLevel,
    setSelectedEducationLevel,
    selectedSubject,
    setSelectedSubject,
    selectedLearningPhase,
    setSelectedLearningPhase,
    selectedDiStrategyClass,
    setSelectedDiStrategyClass,
    selectedDiStrategy,
    setSelectedDiStrategy,
    selectedResourceType,
    setSelectedResourceType,
    inputTeachingTopic,
    setInputTeachingTopic,
    sortItemOptions,
    selectedSortItem,
    setSelectedSortItem,
  }) => {
    const { t } = useTranslation()
    const [teachingTopic, setTeachingTopic] = useState(inputTeachingTopic)

    const handleSelectedOnChange = useCallback(
      (
        action?: Dispatch<SetStateAction<SelectOption | undefined>>,
        value?: SelectOption,
      ) => {
        if (action == null) return
        action(value)
      },
      [],
    )

    const handleInputOnChange = useCallback(
      (
        action?: Dispatch<SetStateAction<string | undefined>>,
        value?: string,
      ) => {
        if (action == null) return
        action(value)
      },
      [],
    )

    const handleApplyTeachingTopicFilter = useCallback(() => {
      setInputTeachingTopic?.(teachingTopic)
    }, [setInputTeachingTopic, teachingTopic])

    return (
      <Container>
        <Grid container columnGap={2}>
          <Grid item sm={2}>
            <TextField
              value={teachingTopic ?? ''}
              placeholder={t('lessonPlan.filter.teachingTopic')}
              leftIcon={<SearchLeftIcon />}
              onChange={(e) => {
                handleInputOnChange(setTeachingTopic, e.target.value)
              }}
              onStopInput={handleApplyTeachingTopicFilter}
            />
          </Grid>
          {educationLevelOptions != null && (
            <Grid item sm={1}>
              <AutoComplete
                sx={{ borderRadius: 2 }}
                placeholder={t('lessonPlan.filter.educationLevel')}
                options={educationLevelOptions}
                value={selectedEducationLevel}
                onChange={(e, value) => {
                  handleSelectedOnChange(setSelectedEducationLevel, value)
                  handleSelectedOnChange(setSelectedLearningPhase)
                }}
              />
            </Grid>
          )}
          {learningPhaseOptions != null && (
            <Grid item sm={1}>
              <AutoComplete
                sx={{ borderRadius: 2 }}
                placeholder={t('lessonPlan.filter.learningPhase')}
                options={learningPhaseOptions}
                value={selectedLearningPhase}
                onChange={(e, value) =>
                  handleSelectedOnChange(setSelectedLearningPhase, value)
                }
              />
            </Grid>
          )}
          {subjectOptions != null && (
            <Grid item sm={1.5}>
              <AutoComplete
                sx={{ borderRadius: 2 }}
                placeholder={t('lessonPlan.filter.subject')}
                options={subjectOptions}
                value={selectedSubject}
                onChange={(e, value) =>
                  handleSelectedOnChange(setSelectedSubject, value)
                }
              />
            </Grid>
          )}
          {diStrategyClassOptions != null && (
            <Grid item sm={2}>
              <AutoComplete
                sx={{ borderRadius: 2 }}
                placeholder={t('lessonPlan.filter.diStrategyClass')}
                options={diStrategyClassOptions}
                value={selectedDiStrategyClass}
                onChange={(e, value) => {
                  handleSelectedOnChange(setSelectedDiStrategyClass, value)
                  handleSelectedOnChange(setSelectedDiStrategy)
                }}
              />
            </Grid>
          )}
          {diStrategyOptions != null && (
            <Grid item sm={2.5}>
              <AutoComplete
                sx={{ borderRadius: 2 }}
                placeholder={t('lessonPlan.filter.diStrategy')}
                options={diStrategyOptions}
                value={selectedDiStrategy}
                disabled={selectedDiStrategyClass == null}
                onChange={(e, value) =>
                  handleSelectedOnChange(setSelectedDiStrategy, value)
                }
              />
            </Grid>
          )}
          {resourceTypeOptions != null && (
            <Grid item sm={1}>
              <AutoComplete
                sx={{ borderRadius: 2 }}
                placeholder={t('lessonPlan.filter.resourceType')}
                options={resourceTypeOptions}
                value={selectedResourceType}
                disabled={setSelectedResourceType == null}
                onChange={(e, value) =>
                  handleSelectedOnChange(setSelectedResourceType, value)
                }
              />
            </Grid>
          )}
          {viewMode == null || setViewMode == null ? (
            <></>
          ) : (
            <ViewModeButtons viewMode={viewMode} setViewMode={setViewMode} />
          )}
          <Grid item sm={1}>
            <StyledDropdown
              variant="standard"
              disableUnderline
              options={sortItemOptions}
              placeholder={
                (sortItemOptions.find(
                  (option) => option.value === selectedSortItem,
                )?.label as string) ?? (sortItemOptions[0].label as string)
              }
              onChange={(e) => {
                if (setSelectedSortItem == null) return
                const value =
                  (e.target.value as string) ?? sortItemOptions[0].value
                setSelectedSortItem(value)
              }}
            />
          </Grid>
        </Grid>
        <StyledDivider />
      </Container>
    )
  },
)
