import {
  DiStrategiesQueryParameters,
  DiStrategyExpandableParameter,
  PaginatedPayload,
  PostDiStrategyPayload,
  PutDiStrategyPayload,
} from '@polyu-dip/apis'
import { DiStrategy } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'di-strategies'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useDiStrategies(
  useStores: UseStoresType,
  param?: DiStrategiesQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<DiStrategy>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getDiStrategies(param),
    opts,
  )
}

export function useDiStrategyDetails(
  useStores: UseStoresType,
  id: string,
  param?: DiStrategyExpandableParameter,
  opts?: UseQueryOptions<DiStrategy>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, id] as QueryKey,
    () => masterDataStore.getDiStrategyDetails(id, param),
    opts,
  )
}

export function useCreateDiStrategies(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostDiStrategyPayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payloads) => masterDataStore.createDiStrategies(payloads),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useUpdateDiStrategy(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<DiStrategy, unknown, PutDiStrategyPayload>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => masterDataStore.updateDiStrategy(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useSwitchDiStrategyStatus(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    DiStrategy,
    unknown,
    PutDiStrategyPayload & { id: string }
  >,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => masterDataStore.updateDiStrategy(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export async function clearDiStrategyQueryCaches(queryClient: QueryClient) {
  await queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
