import {
  Alert,
  AlertProps as MuiAlertProps,
  Snackbar as MuiSnackbar,
} from '@mui/material'
import { memo } from 'react'

export type SnackbarProps = Pick<MuiAlertProps, 'severity'> & {
  message: string
}

export const Snackbar = memo<SnackbarProps>(({ severity, message }) => {
  return (
    <MuiSnackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert severity={severity} variant="filled">
        {message}
      </Alert>
    </MuiSnackbar>
  )
})
