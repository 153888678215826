import { Box, Typography } from '@mui/material'
import { DiStrategy, DiStrategyClass } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '../form'

export type DiStrategyDetailProps = {
  diStrategies: DiStrategy[]
  diStrategyClasses: DiStrategyClass[]
  diStrategyId: string
  reason?: string
  isShowChoiceReasonInput?: boolean
  onSelectReasonChange?: (id: string, reason: string) => void
}

export const DiStrategyDetail = observer<DiStrategyDetailProps>(
  ({
    diStrategies,
    diStrategyClasses,
    diStrategyId,
    reason,
    isShowChoiceReasonInput,
    onSelectReasonChange,
  }) => {
    const { t } = useTranslation()

    const diStrategy = useMemo(
      () => diStrategies.find((it) => it.id == diStrategyId),
      [diStrategies, diStrategyId],
    )
    const diStrategyClass = useMemo(
      () =>
        diStrategyClasses.find((it) => it.id == diStrategy?.diStrategyClassId),
      [diStrategy, diStrategyClasses],
    )

    const handleReasonChange = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (onSelectReasonChange == null) {
          return
        }
        onSelectReasonChange(diStrategyId, event.target.value)
      },
      [diStrategyId, onSelectReasonChange],
    )

    return (
      <>
        <Typography fontSize="14" fontWeight="bold">
          {t('lessonPlan.createForm.diStrategies.label', {
            diStrategyClassLabel: diStrategyClass?.label,
            diStrategyLabelNumber: diStrategy?.order,
            diStrategyName: diStrategy?.displayName,
          })}
        </Typography>
        <Typography fontSize="14" mt={3}>
          {diStrategy?.displayDetail}
        </Typography>
        {onSelectReasonChange != null && isShowChoiceReasonInput && (
          <Box mt={6}>
            <TextField
              key={diStrategyId}
              multiline
              placeholder={t(
                'lessonPlan.createForm.diStrategies.reasonForSelect.placeholder',
              )}
              label={t(
                'lessonPlan.createForm.diStrategies.reasonForSelect.label',
              )}
              rows={7}
              value={reason}
              onChange={handleReasonChange}
            />
          </Box>
        )}
      </>
    )
  },
)
