import styled from 'styled-components'
import { memo, ReactNode } from 'react'
import { Grid } from '@mui/material'
import { Breadcrumbs, BreadcrumbsProps } from '../breadcrumbs'
import { SectionTitle, SectionTitleProps } from '../text'

const Container = styled.div<{ fullHeight?: boolean }>`
  height: ${({ fullHeight }) => (fullHeight ? 100 + '%' : undefined)};
`

type ListPageProps = {
  fullHeight?: boolean
  breadcrumbsProps?: BreadcrumbsProps
  sectionTitleProps?: SectionTitleProps
  sectionTitleChildren?: ReactNode
  children?: ReactNode
}
export const ListPage = memo<ListPageProps>(
  ({
    fullHeight,
    breadcrumbsProps,
    sectionTitleProps,
    sectionTitleChildren,
    children,
  }) => {
    return (
      <Container fullHeight={fullHeight}>
        {breadcrumbsProps != null && (
          <Breadcrumbs
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...breadcrumbsProps}
          />
        )}

        <Grid display="flex" justifyContent="space-between" alignItems="center">
          {sectionTitleProps != null && (
            <Grid item>
              <SectionTitle
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...sectionTitleProps}
              />
            </Grid>
          )}
          {sectionTitleChildren != null && sectionTitleChildren}
        </Grid>

        {children != null && children}
      </Container>
    )
  },
)
