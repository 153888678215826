import { Typography } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Spinner } from '../spinner'

const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled(Typography)`
  padding: ${({ theme }) => theme.spacings.general[2]}px;
`

export const LoginLoading = memo(() => {
  const { t } = useTranslation()

  return (
    <Root>
      <Title variant="h5">{t('login.loading')}</Title>
      <Spinner />
    </Root>
  )
})
