export const lessonPlan = {
  createForm: {
    steps: {
      generalInformation: '概覽',
      diStrategies: '適異教學策略清單',
      uploadDocument: '上傳教學設計',
      shareAndRequestPublish: '分享',
      additionalInfo: '補充背景資料',
    },
    generalInformation: {
      tabs: {
        overview: '1.1 概覽',
        learningTarget: '1.2 學習目標',
        advancedKnowledge: '1.3 已有知識',
        teachingProcess: '1.4 教學流程',
      },
      teachingProcess: {
        teachingProcessForEachGroupTitle: '多元的學習目標: 各組教學流程',
      },
      fields: {
        educationLevel: {
          label: '小學/中學',
          options: {
            primary: '小學',
            secondary: '中學',
          },
        },
        classLevel: {
          label: '年級',
        },
        subject: {
          label: '科目',
          placeholder: '選擇科目',
        },
        learningUnit: {
          label: '學習範疇',
          placeholder: '例如小學常識科：「健康與生活」、「人與環境」',
        },
        teachingTopic: {
          label: '課題',
          placeholder: '課題',
        },
        sectionTotal: {
          label: '所需課節',
          placeholder: '數量',
        },
        sectionDuration: {
          label: '每節課時',
          placeholder: '課時',
        },
        minute: '分鐘',
        academicYear: {
          displayFormat: `{{academicYearStart}} - {{academicYearEnd}}`,
          label: '學年',
          placeholder: '選擇學年',
        },
        teachingClass: {
          label: '班別',
          placeholder: '班別',
        },
        classCharacteristics: {
          label: '學生特性',
          placeholder: '選擇學生特性',
        },
        authorName: {
          label: '教師全名',
          placeholder: '教師全名',
        },
        date: {
          label: '日期',
          placeholder: 'DD/MM/YYYY',
        },
        teachingType: {
          label: '全班/分組學習目標',
          options: {
            basedOnStudentTraitGroups: '多元的學習目標',
            wholeClass: '全班的學習目標',
          },
          remark:
            '註解:\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
          changeTypeConfirmTitle:
            '請注意：填寫完「全班的學習目標」後改選「多元的學習目標」（或相反亦然），有關內容將不會保留！',
        },
        wholeClassTeachingType: {
          fields: {
            teachingContents: {
              label: '教學內容重點（如適用）',
              placeholder: '教學內容重點',
            },
            expectedResultLessons: {
              label: '預期學習成果',
              subLabel: '在課堂完結時，學生能夠：',
              placeholder: '預期學習成果',
            },
          },
        },
        basedOnStudentTraitGroupsTeachingType: {
          characteristic: {
            label: '分組性質',
            placeholder: '進階/基礎/保底 （如屬其他，請註明）',
          },
          teachingContents: {
            label: '分組教學內容重點（如適用）',
            placeholder: '分層教學內容重點',
          },
          expectedResultLessons: {
            label: '預期學習成果',
            placeholder: '預期學習成果',
          },
        },
        advancedKnowledge: {
          requiredKnowledge: {
            label: '學生已有知識',
            placeholder: '已有知識',
          },
          expectedDifficulty: {
            label: '預期學習難點',
            placeholder: '預期學習難點',
          },
          remark: {
            label: '備註',
            placeholder: '補充說明',
          },
        },
        wholeClassTeachingProcess: {
          teachingFlow: {
            duration: {
              label: '時間 （分鐘)',
              placeholder: '時間',
            },
            activity: {
              label: '學習活動',
              placeholder: '活動',
            },
            description: {
              label: '活動描述',
              placeholder: '活動描述',
            },
            diStrategyButton: '適異教學策略',
            order: '次序',
          },
        },
        followUpItems: {
          label: '跟進事項',
          placeholder: '小測/ 功課（如有）',
        },
        learningResource: {
          label: '自學資源/延展學習',
          placeholder: '補充說明',
        },
      },
      teachingStrategyModal: {
        title: '適異教學策略',
        maxNumberWarning: '最多只可以輸入{{maxDIStrategyNumber}}個適異教學策略',

        actions: {
          close: '關閉',
          submit: '提交',
        },
      },
    },
    diStrategies: {
      title: '適異教學策略',
      subTitle: '(單節課堂可選3至5個; 多節課堂可選5至8個)',
      reasonForSelect: {
        label: '選擇以上適異教學策略的原因',
        placeholder: '原因',
      },
      selectedStrategies: '已選適異教學策略',
      label:
        '{{diStrategyClassLabel}}{{diStrategyLabelNumber}}. {{diStrategyName}}',
    },
    diStrategyClass: {
      label: '{{label}}. {{name}}',
    },
    uploadDocuments: {
      label: `1.	請透過「附件」上載文字、音頻或視訊等形式的設計理念及所有相關教材，並選擇適當的「資源類別」。\n2.	如教學過程需運用網上互動平台（如GeoGebra, Nearpod等）或需要透過One Drive, Google Drive等上載檔案大於100MB的教材，請填寫這平台的「標題」、登入「連結」及「資源類別」。
      `,
      title: '輸入標題',
      url: '輸入連結',
      resourceType: '資源類別',
    },
    shareAndRequestPublish: {
      shareTo: '分享給系统内教師',
      shareOutsideTo: '分享給系统外教師',
      shareInputPlaceholder: '@ newmember@ccss.edu.hk',
      diStrategyTeachingConceptExcerpt: '「適異教學」設計解說',
      schoolBackgroundInformation: '學校背景',
      confirmation: {
        title: '確認分享',
        content: '您的教案將分享給非系統用戶，請注意信息安全！',
      },
    },
    requestConsultingPopup: {
      title: '諮詢專家',
      label: '與適異教學策略相關的疑問或查詢',
      placeholder: '查詢內容',
      requireObservation: '需要專家觀課（錄影或實體）',
      confirmation: {
        title: '確認提交諮詢',
        content: '請確認是否提交諮詢予專家？',
      },
    },
    saveSuccess: '教案已儲存 !',
    uploadSuccess: '教案及教材已成功上傳 !',
    sharingsSuccess: '教案及教材已成功分享 !',
    updateSharingsSuccess: '已更新分享名單 !',
  },

  actions: {
    requestConsulting: '諮詢專家',
    return: '返回',
    saveAndContinue: '儲存及繼續',
    saveAndView: '儲存及檢視',
    sharedWithOthers: '分享',
    addUrl: '新增',
  },

  educationLevel: {
    primary: '小學',
    secondary: '中學',
  },

  consultRequestStatus: {
    pending: '待跟進',
    followingUp: '跟進中',
    completed: '已回覆',
    noPendingRequest: '---',
  },
  publishRequestStatus: {
    publishing: '已發佈',
    pending: '已投稿',
    approved: '已接納',
    rejected: '已被拒絕',
    completed: '已完成',
    unPublishing: '已下架',
    noPendingRequest: '備課中',
    cancelled: '已撤回',
  },

  status: {
    created: '備課中',
    toBeViewed: '待檢視',
    agreeToPublish: '已投稿', // deprecated
    toBeProcess: '已投稿',
    accepted: '已接納', // deprecated
    approved: '已接納',
    rejected: '已被拒絕',
    published: '已發佈',
    toBeProcessed: '待跟進',
    replied: '已回覆',
    followingUp: '跟進中',
    replyReceived: '已獲回覆',
  },

  detail: {
    actions: {
      transferOwnership: '轉移擁有權',
      agreeToPublish: '投稿',
      withdraw: '撤回投稿',
      editLessonPlan: '編輯教案',
      accept: '接納投稿',
      reject: '拒絕投稿',
      withdrawApproval: '接納撤回',
      publish: '發佈',
      unpublish: '取消發佈',
    },
    basic: {
      dateCreated: '建立日期',
      datePublished: '發佈日期',
      school: '學校',
      owner: '作者',
      subjectRelated: '分類',
      latestUpdated: '最後更新',
      actionOptions: '操作選項',
      status: '教案狀態',
      consultDisplayStatus: '諮詢狀態',
      publishDisplayStatus: '投稿狀態',
      isAgreePublish: '同意公開',
    },
    assignExpert: {
      createdByUser: '教師姓名',
      consultDetail: '諮詢',
      assignedToUser: '負責專家',
      assignedDate: '委派日期',
      lastModifiedDateTime: '最後更新',
      status: {
        notComplete: '未回覆',
        completed: '已回覆',
      },

      assign: {
        title: '委派專家',
        email: '@ newmember@ccss.edu.hk',
        actions: {
          close: '關閉',
          confirm: '確認',
        },
      },

      actions: {
        assignExpert: '委派專家',
      },
    },
    expectedDifficulty: '學生特性/學習難點',
    general: {
      menuTab: {
        teachingPlan: '教案',
        diStrategies: '適異教學策略',
        additionalTextbook: '附加教材',
        schoolBackground: '學校背景',
        designExplanation: '「適異教學」設計解說',
      },
      designExplanation: '設計解說',
      annex: '附件 ({{count}})',
      link: '連結 ({{count}})',
      teachingPlanTab: {
        educationLevel: '小學/中學',
        classLevel: '年級',
        subject: '科目',
        learningUnit: '學習範疇',
        teachingTopic: '課題',
        sectionTotal: '所需課節',
        sectionDuration: '每節課時',
        durationUnit: '分鐘',
        academicYear: '學年',
        teachingClass: '班別',
        lessonPlanTags: {
          classCharacteristic: '學生特性',
        },

        teachingProcess: {
          processOfEachGroup: {
            title: '多元的學習目標：各組的教學流程',
            timeInMinute: '時間 （分鐘）',
            activity: '學習活動',
            strategy: '適異教學策略',
            description: '活動描述',
          },
        },
      },
      diStrategiesTab: {
        title: '已選適異教學策略',
      },
    },
    lessonPlanComments: {
      reply: '回應',
      inputContent: '發表回應...',
      expand: '更多回應',
      collapse: '收起回應',
      deletedComment: '此留言已被刪除',
      actions: {
        reply: '引用回覆',
        edit: '編輯',
        delete: '刪除',
        create: '添加評論',
      },
      consultComment: '諮詢提問',
      commentActionModal: {
        edit: '編輯回覆',
        reply: '引用回覆',
        delete: '刪除留言',
        deleteMessage: '請確認是否刪除此則留言 ?',
        replyContent: '輸入文字',
        actions: {
          send: '發送',
          close: '關閉',
          cancel: '取消',
          confirm: '確認',
        },
      },
    },
    publishRequest: {
      withdraw: {
        title: '撤回投稿',
        content: '請確認是否撤回教案？',
      },
      approve: {
        title: '確認投稿',
        content: '請確認是否將教案投稿？',
        teacherIntention: '教師意向',
        confirmation: '同意分享教學設計',
        acknowledgement:
          '如勾選此格，表示同意讓團隊將教案及教材發佈，與其他參與學校分享',
        remind:
          '教案及教材經適異教學團隊審閱及發佈後，將作為教學示例，促進同工之間專業交流。',
      },
      actions: {
        close: '關閉',
        confirm: '確認',
      },
    },
    transferOwnership: {
      title: '確定轉移擁有權',
      content: '請確認是否轉移此教案之擁有權？',
      field: {
        label: '此教案的擁有權將轉移到以下用戶帳號：',
        placeholder: '@ newmember@ccss.edu.hk',
      },
      remark: '注意：此操作將無法復原',
    },
  },
  errorMessage: {
    createLessonPlan: { title: '建立教案失敗' },
    updateLessonPlan: { title: '更新教案失敗' },
    createLessonPlanSharings: { title: '分享教案及教材失敗' },

    createLessonPlanComment: { title: '添加評論失敗' },
    updateLessonPlanComment: { title: '更新評論失敗' },
    deleteLessonPlanSharings: { title: '更新分享名單失敗' },
    deleteLessonPlanComment: { title: '刪除評論失敗' },

    createLessonPlanPublishRequest: { title: '投稿失敗' },
    withdrawLessonPlanPublishRequest: { title: '拒絕投稿失敗' },

    approveLessonPlanPublishRequest: { title: '接納投稿失敗' },
    rejectLessonPlanPublishRequest: { title: '駁回投稿失敗' },
    completeAndPublishLessonPlanPublishRequest: { title: '發佈失敗' },
    createLessonPlanConsultRequest: { title: '提交諮詢失敗' },
    assignExpert: { title: '委派專家失敗' },
    completeConsultRequest: { title: '回覆諮詢失敗' },
    transferLessonPlan: { title: '轉移教案擁有權失敗' },
  },
  action: {
    createLessonPlanComment: { successMessage: '已成功添加評論' },
    updateLessonPlanComment: { successMessage: '已成功更新評論' },
    deleteLessonPlanSharings: { successMessage: '已成功更新分享名單' },
    deleteLessonPlanComment: { successMessage: '已成功刪除評論' },

    createLessonPlanPublishRequest: { successMessage: '已成功投稿' },
    withdrawLessonPlanPublishRequest: { successMessage: '已成功撤回投稿' },
    createLessonPlanConsultRequest: {
      successMessage:
        '謝謝！您已成功投稿。如有要求「諮詢專家」，專家會盡可能於兩周內回覆閣下。',
    },
    assignExpert: { successMessage: '已成功委派專家' },
    transferLessonPlan: { successMessage: '教案擁有權已成功轉移！' },
  },
  filter: {
    educationLevel: '小學/中學',
    subject: '任何科目',
    learningPhase: '學習階段',
    diStrategyClass: '適異教學策略分類',
    diStrategy: '適異教學策略',
    teachingTopic: '教案名稱',
    resourceType: '資源分類',
  },
}
