export function isNumeric(str: string) {
  return /^\d+$/.test(str)
}

export function isDecimalPlacesMeet(num: number, dp: number = 1) {
  return num === parseFloat(num.toFixed(dp))
}

export function isNotEmpty(str: string | null | undefined) {
  if (str == null) return false
  if (str.trim().length === 0) return false

  return true
}

export function isLengthWithin(str: string | null | undefined, length: number) {
  if (str == null) return false
  return str.trim().length <= length
}

export const domainFormatRegExp = /@\w+([.-]?\w+)*(\.\w{2,3})+$/

export function isDomainFormat(string: string | null | undefined) {
  if (string == null) return false
  return string.match(domainFormatRegExp)
}
