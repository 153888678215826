import { Button, Link } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { SponsorFooter } from '../../components'
import { useLogin, useRestoreSession } from '../../services'
import { env } from '../../utilities'

const Container = styled.div`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palettes.general.background};
  min-height: 100vh;
  padding-left: 80px;
  padding-right: 80px;
`

const LoginCard = styled.div`
  margin-top: 40px;
  padding: 32px 112px;
  width: 644px;
  height: 360px;
  border: 1px solid ${({ theme }) => theme.palettes.general.borderStroke};
  background-color: ${({ theme }) => theme.palettes.general.white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 10px 16px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const VersionContainer = styled.div`
  width: 644px;
  display: flex;
  justify-content: end;
  padding-top: ${({ theme }) => theme.spacings.general[2]}px;
  color: ${({ theme }) => theme.palettes.general.greys[2]};
`

const StyledEnvironment = styled.div`
  margin-right: ${({ theme }) => theme.spacings.general[2]}px;
  display: flex;
  align-items: center;
`

const StyledVersion = styled.div`
  display: flex;
  align-items: center;
`

const ActivateAccountButton = styled(Link)`
  margin-top: 10px;
  cursor: pointer;
`

type Props = {}

export const LoginPage = observer<Props>(() => {
  const { t } = useTranslation()

  const login = useLogin()

  const restoreSessionResult = useRestoreSession()

  const handleLogin = useCallback(async () => {
    try {
      login()
      // await rootStore.login(instance)
    } catch (error) {
      console.error('Err-id:131736 ,Error when: Login Redirect', error)
    }
  }, [login])

  const handleActiveAccount = useCallback(async () => {
    try {
      login(undefined, true)
    } catch (error) {
      console.error(
        'Err-id:131736 ,Error when: Activate Account Redirect',
        error,
      )
    }
  }, [login])

  return (
    <Container>
      <Svg.JCPrimaryLogo />
      <LoginCard>
        <ButtonContainer>
          {restoreSessionResult != null && (
            <>
              <Button onClick={handleLogin} size="large" fullWidth color="blue">
                {t('login.login')}
              </Button>
              <ActivateAccountButton onClick={handleActiveAccount}>
                {t('login.activeAccount')}
              </ActivateAccountButton>
            </>
          )}
        </ButtonContainer>
      </LoginCard>
      <VersionContainer>
        <StyledEnvironment>{env.environment}</StyledEnvironment>
        <StyledVersion>
          {t('common.version', { version: env.version })}
        </StyledVersion>
      </VersionContainer>
      <SponsorFooter />
    </Container>
  )
})
