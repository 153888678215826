import { getEnv } from 'mobx-state-tree'
import { Environment } from './environment'

export const withEnvironment = (self: any) => ({
  views: {
    get environment() {
      return getEnv<Environment>(self)
    },
  },
})
