/* eslint-disable no-nested-ternary */
import { MasterDataTable } from '@polyu-dip/stores'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useFormErrorTranslationTrigger,
  useMasterData,
  useMasterDataInit,
  useMasterDataManagement,
} from '../../hooks'
import { useOverlay } from '../overlay-provider'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDisplayName } from '../../services'
import {
  MasterDataFormExtraColumn,
  MasterDataList,
  NewFormData,
} from './master-data-list'
import { Grid } from '@mui/material'
import { Dialog, DialogAction } from '../dialog'
import { useMasterDataSchema } from './use-master-data-schema'
import { SelectOption } from '../form'
import {
  PostClassLevelPayload,
  PostDiStrategyClassPayload,
  PostDiStrategyPayload,
  PostEducationLevelPayload,
  PostLearningPhasePayload,
  PostResourceTypePayload,
  PostSubjectPayload,
  PostTagGroupPayload,
  PostTagPayload,
  PostTagTypePayload,
  PutTagGroupPayload,
} from '@polyu-dip/apis'
import { useComputed } from '@polyu-dip/helpers'
import { DiStrategyDetailManagement } from './di-strategy-detail-management'
import {
  FileSource,
  LessonPlanDiStrategyResourceType,
  SubjectEducationLevel,
} from '@polyu-dip/models'
import { TagGroupDetailManagement } from './tag-group-detail-management'

type CommonProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  masterDataTable: MasterDataTable
  selectedContentId?: string
  withTagGroupWithName?: boolean
}

type Props =
  | ({
      mode: 'create'
      selectedId?: never
    } & CommonProps)
  | ({
      mode: 'edit'
      selectedId?: string
    } & CommonProps)

type DialogActionButtonValueType =
  | 'create'
  | 'close'
  | 'update'
  | 'delete'
  | 'activate'

export const MasterDataManagementDialog = observer<Props>(
  ({
    isOpen,
    setIsOpen,
    masterDataTable,
    mode,
    selectedId,
    selectedContentId,
    withTagGroupWithName,
  }) => {
    const { t } = useTranslation()
    const { showSpinner, hideSpinner } = useOverlay()
    const { getDisplayName } = useDisplayName()

    const [canSubmit, setCanSubmit] = useState(false)
    const [canUpdate, setCanUpdate] = useState(false)
    const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false)

    useMasterDataInit()
    const {
      targetMasterData,
      educationLevelOptions,
      learningPhaseOptions,
      diStrategyClassOptions,
      tagTypesOptions,
      tags,
    } = useMasterData(masterDataTable, selectedContentId)

    const hasHexColor = useComputed(() => {
      return (
        masterDataTable !== 'diStrategyClasses' &&
        masterDataTable !== 'tagTypes'
      )
    }, [masterDataTable])

    const masterDataFormExtraColumn: MasterDataFormExtraColumn | undefined =
      useComputed(() => {
        if (
          masterDataTable === 'learningPhases' &&
          selectedContentId != null &&
          masterDataTable != selectedContentId
        ) {
          return selectedContentId === 'classLevels'
            ? 'learningPhase'
            : undefined
        }
        if (masterDataTable === 'learningPhases') return 'educationLevel'
        if (masterDataTable === 'classLevels') return 'learningPhase'
        if (masterDataTable === 'diStrategies') return 'diStrategyClass'
        if (masterDataTable === 'tagGroups') return 'tagType'
        return undefined
      }, [masterDataTable, selectedContentId])

    const formOptions: SelectOption[] = useComputed(() => {
      if (
        masterDataTable === 'learningPhases' &&
        selectedContentId != null &&
        masterDataTable != selectedContentId
      ) {
        return selectedContentId === 'classLevels' ? learningPhaseOptions : []
      }
      if (masterDataTable === 'learningPhases') return educationLevelOptions
      if (masterDataTable === 'classLevels') return learningPhaseOptions
      if (masterDataTable === 'diStrategies') return diStrategyClassOptions
      if (masterDataTable === 'tagGroups') return tagTypesOptions
      return []
    }, [
      masterDataTable,
      selectedContentId,
      educationLevelOptions,
      learningPhaseOptions,
      diStrategyClassOptions,
      tagTypesOptions,
    ])

    const selectedData = useComputed(() => {
      if (mode == 'create') return
      return targetMasterData.find(
        (data) => data.id === selectedId?.toLocaleLowerCase(),
      )
    }, [mode, selectedId, targetMasterData])

    const { targetSchema } = useMasterDataSchema(
      masterDataTable,
      withTagGroupWithName ?? false,
      selectedContentId,
    )

    const {
      getTargetMasterDataName,
      createClassLevels,
      createEducationLevels,
      createLearningPhases,
      createSubjects,
      createResourceTypes,
      createDiStrategies,
      createDiStrategyClasses,
      createTags,
      createTagTypes,
      createTagGroups,
      createSystemParameters,
      updateClassLevel,
      updateEducationLevel,
      updateLearningPhase,
      updateSubject,
      updateResourceType,
      updateDiStrategy,
      updateDiStrategyClass,
      updateTag,
      updateTagType,
      updateTagGroup,
      updateSystemParameter,
      isDisableConfirmationDialogOpen,
      handleSwitchMasterDataStatus,
      handleOpenDisableConfirmationDialog,
      renderDisableConfirmationDialog,
    } = useMasterDataManagement(selectedId)

    const defaultNewData = useComputed(
      () =>
        Array(masterDataTable === 'tagGroups' ? 1 : 5).fill({
          chineseName: '',
          englishName: '',
          colorCode: undefined,
          educationLevelId: undefined,
          learningPhaseId: undefined,
          order: undefined,
          code: undefined,
          tagTypeId: undefined,

          tagGroupTags: [{}],

          isMultiSelect: false,

          subjectId: undefined,
          name: '',
          description: undefined,
          category: undefined,
          dataType: undefined,
          value_Integer: undefined,
          value_Decimal: undefined,
          value_Datatime: undefined,
          value_Text: undefined,
          value_Boolean: undefined,
        }),
      [masterDataTable],
    )

    const {
      control,
      formState: { errors },
      getValues,
      trigger,
      reset,
      clearErrors,
      watch,
      setValue,
    } = useForm<NewFormData>({
      resolver: yupResolver(targetSchema),
      defaultValues: {
        newData: defaultNewData,
      },
    })

    useFormErrorTranslationTrigger(errors, trigger)

    const masterDataTableName = useComputed(
      () => getTargetMasterDataName(masterDataTable, selectedContentId),
      [masterDataTable, selectedContentId],
    )

    useEffect(() => {
      if (mode === 'create') {
        reset({
          newData: defaultNewData,
        })
      } else if (mode === 'edit' && masterDataTableName !== 'diStrategies') {
        reset({
          newData: [
            {
              chineseName: getDisplayName(selectedData?.name, 'zh-Hant') ?? '',
              englishName: getDisplayName(selectedData?.name, 'en') ?? '',
              label: selectedData?.label,
              colorCode: selectedData?.colorCode,
              educationLevelId: selectedData?.educationLevelId,
              learningPhaseId: selectedData?.learningPhaseId,
              diStrategyClassId: selectedData?.diStrategyClassId,
              educationLevelIds:
                selectedData?.subjectEducationLevelMappings?.map(
                  (it: SubjectEducationLevel) => ({
                    label: it.educationLevel?.displayName,
                    value: it.educationLevelId,
                    disabled: it.educationLevel?.isDisabled,
                  }),
                ),
              tagGroupTags: tags
                .filter((it) => it.tagGroupId === selectedId)
                .map((it) => ({
                  id: it.id,
                  chineseName: getDisplayName(it.name, 'zh-Hant'),
                  englishName: getDisplayName(it.name, 'en'),
                  isDisabled: it.isDisabled,
                })),
              order: selectedData?.order,
              tagTypeId: selectedData?.tagTypeId,
              subjectId: selectedData?.subjectId,
              name: selectedData?.name,
              description: selectedData?.description,
              category: selectedData?.category,
              dataType: selectedData?.dataType,
              isMultiSelect: selectedData?.isMultiSelect,
              value_Integer: selectedData?.value_Integer,
              value_Decimal: selectedData?.value_Decimal,
              value_DateTime: selectedData?.value_DateTime,
              value_Text: selectedData?.value_Text,
              value_Boolean: selectedData?.value_Boolean,
            },
          ],
        })
      }
    }, [
      defaultNewData,
      getDisplayName,
      mode,
      reset,
      selectedData,
      isOpen,
      masterDataTableName,
      tags,
      selectedId,
    ])

    const dialogActions =
      useComputed((): DialogAction<DialogActionButtonValueType>[] => {
        const deleteAction: DialogAction<DialogActionButtonValueType>[] =
          masterDataTable === 'systemParameters'
            ? []
            : [
                {
                  text: t(
                    `masterData.addMasterDataDialog.action.${
                      selectedData?.isDisabled ? 'activate' : 'delete'
                    }`,
                  ),
                  type: 'contained',
                  value: selectedData?.isDisabled ? 'activate' : 'delete',
                  color: selectedData?.isDisabled ? 'success' : 'sharpRed',
                },
              ]
        if (mode === 'create') {
          return [
            {
              text: t('common.close'),
              type: 'outlined',
              value: 'close',
            },
            {
              text: t('masterData.addMasterDataDialog.action.create'),
              type: 'contained',
              value: 'create',
              color: 'blue',
              disabled: !canSubmit,
            },
          ]
        }
        return [
          {
            text: t('common.close'),
            type: 'outlined',
            value: 'close',
          },
          ...deleteAction,
          {
            text: t('common.save'),
            type: 'contained',
            value: 'update',
            color: 'blue',
            disabled: !canUpdate,
          },
        ]
      }, [selectedData, canSubmit, canUpdate, mode, t])

    const handleOnClose = useCallback(() => {
      reset()
      clearErrors()
      setIsOpen(false)
    }, [clearErrors, reset, setIsOpen])

    const processCreateModeAction = useCallback(async () => {
      const result = await trigger()

      if (!result) {
        return
      }

      const newMasterData = getValues('newData')
      const hasUnfinishedField =
        masterDataTableName === 'systemParameters'
          ? newMasterData.some(
              (data) =>
                !(data?.name == null || data?.name?.length === 0) &&
                data?.dataType != null &&
                data.value_Boolean == null &&
                data.value_DateTime == null &&
                data.value_Decimal == null &&
                data.value_Integer == null &&
                data.value_Text == null,
            )
          : newMasterData.some(
              (data) =>
                !(
                  (data?.chineseName == null ||
                    data?.chineseName?.length === 0) &&
                  (data?.englishName == null || data?.englishName?.length === 0)
                ) &&
                !(
                  data?.chineseName?.length > 0 && data?.englishName?.length > 0
                ),
            )
      if (hasUnfinishedField && !(await trigger())) return

      const newFormData = newMasterData.filter(
        (data) => data.chineseName?.length > 0 || data.name?.length > 0,
      )
      if (masterDataTableName === 'classLevels') {
        const classLevelPayloads = newFormData.map((data) => ({
          name: JSON.stringify({
            'zh-Hant': data.chineseName,
            en: data.englishName,
          }),
          order: data.order,
          colorCode: data.colorCode,
          learningPhaseId: data.learningPhaseId,
        }))
        await createClassLevels(classLevelPayloads as PostClassLevelPayload[])
      }
      if (masterDataTableName === 'educationLevels') {
        const educationLevelPayloads = newFormData.map((data) => ({
          name: JSON.stringify({
            'zh-Hant': data.chineseName,
            en: data.englishName,
          }),
          order: data.order,
          colorCode: data.colorCode,
        }))
        await createEducationLevels(
          educationLevelPayloads as PostEducationLevelPayload[],
        )
      }
      if (masterDataTableName === 'learningPhases') {
        const learningPhasePayloads = newFormData.map((data) => ({
          name: JSON.stringify({
            'zh-Hant': data.chineseName,
            en: data.englishName,
          }),
          order: data.order,
          colorCode: data.colorCode,
          educationLevelId: data.educationLevelId,
        }))
        await createLearningPhases(
          learningPhasePayloads as PostLearningPhasePayload[],
        )
      }
      if (masterDataTableName === 'subjects') {
        const subjectPayloads = newFormData.map((data) => ({
          name: JSON.stringify({
            'zh-Hant': data.chineseName,
            en: data.englishName,
          }),
          order: data.order,
          colorCode: data.colorCode,
          subjectEducationLevelMappings:
            data.educationLevelIds?.map((it) => ({
              educationLevelId: it.value,
            })) ?? [],
        }))
        await createSubjects(subjectPayloads as PostSubjectPayload[])
      }
      if (masterDataTableName === 'diStrategies') {
        const diStrategyPayloads = newFormData.map((data) => ({
          name: JSON.stringify({
            'zh-Hant': data.chineseName,
            en: data.englishName,
          }),
          order: data.order,
          colorCode: data.colorCode,
          diStrategyClassId: data.diStrategyClassId,
        }))
        await createDiStrategies(diStrategyPayloads as PostDiStrategyPayload[])
      }
      if (masterDataTableName === 'resourceTypes') {
        const resourceTypePayloads = newFormData.map((data) => ({
          name: JSON.stringify({
            'zh-Hant': data.chineseName,
            en: data.englishName,
          }),
          order: data.order,
          colorCode: data.colorCode,
        }))
        await createResourceTypes(
          resourceTypePayloads as PostResourceTypePayload[],
        )
      }
      if (masterDataTableName === 'diStrategyClasses') {
        const diStrategyClassPayloads = newFormData.map((data) => ({
          name: JSON.stringify({
            'zh-Hant': data.chineseName,
            en: data.englishName,
          }),
          order: data.order,
          label: data.label,
        }))
        await createDiStrategyClasses(
          diStrategyClassPayloads as PostDiStrategyClassPayload[],
        )
      }
      if (masterDataTableName === 'tagTypes') {
        const tagTypePayloads = newFormData.map((data) => ({
          name: JSON.stringify({
            'zh-Hant': data.chineseName,
            en: data.englishName,
          }),
          order: data.order,
        }))
        await createTagTypes(tagTypePayloads as PostTagTypePayload[])
      }
      if (masterDataTableName === 'tags') {
        const tagPayloads = newFormData.map((data) => ({
          name: JSON.stringify({
            'zh-Hant': data.chineseName,
            en: data.englishName,
          }),
          order: data.order,
        }))
        await createTags(tagPayloads as PostTagPayload[])
      }
      if (masterDataTableName === 'tagGroups') {
        const tagGroupPayloads = newMasterData.map((data) => ({
          name: withTagGroupWithName
            ? JSON.stringify({
                'zh-Hant': data.chineseName,
                en: data.englishName,
              })
            : '',
          tagTypeId: data.tagTypeId,
          order: data.order,
          colorCode: data.colorCode,
          isMultiSelect: data.isMultiSelect,
          tags:
            data.tagGroupTags?.map((it, index) => ({
              id: it.id,
              order: index,
              name: JSON.stringify({
                'zh-Hant': it.chineseName,
                en: it.englishName,
              }),
              isDisabled: it.isDisabled,
            })) ?? [],
        }))
        await createTagGroups(tagGroupPayloads as PostTagGroupPayload[])
      }
      if (masterDataTableName === 'systemParameters') {
        const systemParameterPayloads = newFormData.map((data) => ({
          name: data.name,
          description: data.description,
          category: data.category,
          dataType: data.dataType,
          value_Integer: data.value_Integer,
          value_Decimal: data.value_Decimal,
          value_DateTime: data.value_DateTime,
          value_Text: data.value_Text,
          value_Boolean: data.value_Boolean,
        }))
        await createSystemParameters(systemParameterPayloads)
      }
      handleOnClose()
    }, [
      createClassLevels,
      createDiStrategies,
      createDiStrategyClasses,
      createEducationLevels,
      createLearningPhases,
      createResourceTypes,
      createSubjects,
      createSystemParameters,
      createTagGroups,
      createTagTypes,
      createTags,
      getValues,
      handleOnClose,
      masterDataTableName,
      trigger,
      withTagGroupWithName,
    ])

    const processEditModeAction = useCallback(
      async (action: DialogActionButtonValueType) => {
        const updatedMasterData = getValues('newData')
        if (!(await trigger())) return

        const {
          chineseName,
          englishName,
          chineseDetail,
          englishDetail,
          displayVideo,
          videos,
          furtherReadingAndExamples,
          ...formData
        } = updatedMasterData[0]
        const newName = {
          'zh-Hant': chineseName,
          en: englishName,
        }
        const payload = {
          ...selectedData,
          ...formData,
          name:
            masterDataTableName === 'systemParameters'
              ? formData.name
              : JSON.stringify(newName),
          rowVersion: selectedData?.rowVersion ?? '',
          isDisabled: action === 'delete',
          subjectEducationLevelMappings: formData.educationLevelIds?.map(
            (it) => ({
              educationLevelId: it.value,
            }),
          ),
        }
        if (action === 'delete' && masterDataTableName != null) {
          handleOpenDisableConfirmationDialog(payload, masterDataTableName)
          setIsDeleteDialogOpened(true)
          return
        }

        if (action === 'activate' && masterDataTableName != null) {
          await handleSwitchMasterDataStatus(payload, masterDataTableName)
          return handleOnClose()
        }

        if (masterDataTableName === 'classLevels') {
          await updateClassLevel(payload)
        } else if (
          masterDataTable === 'educationLevels' ||
          (masterDataTable === 'learningPhases' &&
            selectedContentId === 'educationLevels')
        ) {
          await updateEducationLevel(payload)
        } else if (masterDataTableName === 'learningPhases') {
          await updateLearningPhase(payload)
        } else if (masterDataTableName === 'subjects') {
          await updateSubject(payload)
        } else if (masterDataTableName === 'diStrategies') {
          const newDisplayVideos =
            displayVideo?.file?.url != null && displayVideo.file.url.length > 0
              ? [
                  {
                    order: displayVideo.order ?? 0,
                    name: displayVideo.file.url,
                    resourceType:
                      displayVideo.resourceType ??
                      LessonPlanDiStrategyResourceType.displayVideo,
                    file: {
                      url: displayVideo.file.url,
                      source: displayVideo.file.source ?? FileSource.user,
                    },
                    id: displayVideo.id,
                  },
                ]
              : []
          const newVideos =
            videos
              ?.filter((it) => it.file.url != null && it.file.url.length > 0)
              .map((it) => ({
                order: it.order,
                name: JSON.stringify({
                  'zh-Hant': it.chineseName,
                  en: it.englishName,
                }),
                resourceType: it.resourceType,
                file: it.file,
                id: it.id,
              })) ?? []
          const newFurtherReadingAndExamples =
            furtherReadingAndExamples
              ?.filter((it) => it.file.url != null && it.file.url.length > 0)
              .map((it) => ({
                order: it.order,
                name: JSON.stringify({
                  'zh-Hant': it.chineseName,
                  en: it.englishName,
                }),
                resourceType: it.resourceType,
                file: it.file,
                id: it.id,
              })) ?? []
          const newResourceList = [
            ...newDisplayVideos,
            ...newVideos,
            ...newFurtherReadingAndExamples,
          ]
          const diStrategyDetailPayload = {
            ...selectedData,
            name: JSON.stringify(newName),
            order: formData.order,
            detail: JSON.stringify({
              'zh-Hant': chineseDetail,
              en: englishDetail,
            }),
            diStrategyClassId: formData.diStrategyClassId,
            colorCode: formData.colorCode,
            rowVersion: selectedData?.rowVersion ?? '',
            diStrategyTags: formData.diStrategyTags?.map(
              (diStrategyTag, index) => ({
                id: diStrategyTag?.id,
                tagId: diStrategyTag.value,
                order: index,
              }),
            ),
            diStrategyResources: newResourceList,
          }
          await updateDiStrategy(diStrategyDetailPayload)
        } else if (masterDataTableName === 'resourceTypes') {
          await updateResourceType(payload)
        } else if (masterDataTableName === 'diStrategyClasses') {
          await updateDiStrategyClass(payload)
        } else if (masterDataTableName === 'tags') {
          await updateTag(payload)
        } else if (masterDataTableName === 'tagTypes') {
          await updateTagType(payload)
        } else if (masterDataTableName === 'tagGroups') {
          const data: PutTagGroupPayload = {
            tagTypeId: payload.tagTypeId,
            colorCode: payload.colorCode,
            isDisabled: payload.isDisabled,
            name: withTagGroupWithName ? payload.name : '',
            order: payload.order,
            rowVersion: payload.rowVersion,
            isMultiSelect: payload.isMultiSelect,
            tags: payload.tagGroupTags.map((it: any, index: number) => ({
              id: it.id,
              order: index,
              name: JSON.stringify({
                'zh-Hant': it.chineseName,
                en: it.englishName,
              }),
              isDisabled: it.isDisabled,
            })),
          }
          await updateTagGroup(data)
        } else if (masterDataTableName === 'systemParameters') {
          await updateSystemParameter(payload)
        }
        handleOnClose()
      },
      [
        getValues,
        handleOnClose,
        handleOpenDisableConfirmationDialog,
        handleSwitchMasterDataStatus,
        masterDataTable,
        masterDataTableName,
        selectedContentId,
        selectedData,
        trigger,
        updateClassLevel,
        updateDiStrategy,
        updateDiStrategyClass,
        updateEducationLevel,
        updateLearningPhase,
        updateResourceType,
        updateSubject,
        updateSystemParameter,
        updateTag,
        updateTagGroup,
        updateTagType,
        withTagGroupWithName,
      ],
    )

    const handleOnSelectedDialogAction = useCallback(
      async (value: DialogActionButtonValueType) => {
        if (value === 'close' || value == null) return handleOnClose()
        try {
          showSpinner()
          if (mode === 'create') {
            await processCreateModeAction()
          } else {
            await processEditModeAction(value)
          }
        } finally {
          hideSpinner()
        }
      },
      [
        handleOnClose,
        hideSpinner,
        mode,
        processCreateModeAction,
        processEditModeAction,
        showSpinner,
      ],
    )

    useEffect(() => {
      const subscription = watch(async () => {
        clearErrors()
        const shouldEnableSubmitButtons =
          !withTagGroupWithName ||
          getValues('newData')?.some(
            (data) =>
              data?.chineseName?.length > 0 ||
              data?.englishName?.length > 0 ||
              data?.name?.length > 0,
          )
        setCanSubmit(shouldEnableSubmitButtons)
        setCanUpdate(shouldEnableSubmitButtons)
      })
      return () => subscription.unsubscribe()
    }, [clearErrors, getValues, watch, withTagGroupWithName])

    const dialogHeaderTitle = useComputed(() => {
      return mode === 'create'
        ? t('masterData.addMasterDataDialog.title.create')
        : t('masterData.addMasterDataDialog.title.edit')
    }, [mode, t])

    useEffect(() => {
      if (!isDisableConfirmationDialogOpen && isDeleteDialogOpened) {
        setIsOpen(false)
      }
    }, [isDeleteDialogOpened, isDisableConfirmationDialogOpen, setIsOpen])

    const allowDialogAppendAndDeleteRow = useComputed(() => {
      return mode === 'create' ? true : false
    }, [mode])

    return (
      <>
        <Dialog
          dismissable
          open={isOpen}
          title={dialogHeaderTitle}
          actions={dialogActions}
          onSelected={handleOnSelectedDialogAction}
          maxWidth="lg"
          fullWidth
        >
          {mode === 'edit' && masterDataTableName === 'diStrategies' ? (
            <DiStrategyDetailManagement
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              selectedDataId={selectedData?.id ?? ''}
              reset={reset}
            />
          ) : masterDataTable === 'tagGroups' ? (
            <TagGroupDetailManagement
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              selectedDataId={selectedData?.id ?? ''}
              reset={reset}
              withTagGroupName={withTagGroupWithName}
            />
          ) : (
            <Grid container direction="column" wrap="nowrap">
              {masterDataTableName === 'systemParameters' ? (
                <Grid container direction="row">
                  <Grid item xs={2}>
                    {t('masterData.addMasterDataDialog.field.name.label')}
                  </Grid>
                  <Grid item xs={2}>
                    {t(
                      'masterData.addMasterDataDialog.field.description.label',
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {t('masterData.addMasterDataDialog.field.category.label')}
                  </Grid>
                  <Grid item xs={2}>
                    {t('masterData.addMasterDataDialog.field.dataType.label')}
                  </Grid>
                  <Grid item xs={4}>
                    {t('masterData.addMasterDataDialog.field.value.label')}
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction="row">
                  <Grid item xs={1}>
                    {t('masterData.addMasterDataDialog.field.order.label')}
                  </Grid>
                  {masterDataFormExtraColumn != null ? (
                    <Grid item xs={2}>
                      {t(
                        `masterData.addMasterDataDialog.field.${
                          masterDataFormExtraColumn as MasterDataFormExtraColumn
                        }.placeholder`,
                      )}
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid
                    item
                    xs={
                      masterDataFormExtraColumn != null ||
                      masterDataTable === 'subjects'
                        ? 3
                        : 5
                    }
                  >
                    {t(
                      'masterData.addMasterDataDialog.field.chineseName.label',
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {t(
                      'masterData.addMasterDataDialog.field.englishName.label',
                    )}
                  </Grid>
                  {masterDataTable === 'subjects' && (
                    <Grid item xs={2}>
                      {t(
                        'masterData.addMasterDataDialog.field.educationLevel.label',
                      )}
                    </Grid>
                  )}
                  {hasHexColor ? (
                    <Grid item xs={2}>
                      {t(
                        'masterData.addMasterDataDialog.field.colorCode.label',
                      )}
                    </Grid>
                  ) : (
                    masterDataTableName !== 'tagTypes' && (
                      <Grid item xs={2}>
                        {t('masterData.addMasterDataDialog.field.label.label')}
                      </Grid>
                    )
                  )}
                </Grid>
              )}
              <MasterDataList
                getValues={getValues}
                mode={mode}
                masterDataTable={masterDataTableName ?? masterDataTable}
                hasHexColor={hasHexColor}
                masterDataFormExtraColumn={masterDataFormExtraColumn}
                control={control}
                errors={errors}
                allowAppendRow={allowDialogAppendAndDeleteRow}
                allowDeleteRow={allowDialogAppendAndDeleteRow}
                watch={watch}
                setValue={setValue}
                formOptions={formOptions}
                trigger={trigger}
              />
            </Grid>
          )}
        </Dialog>
        {renderDisableConfirmationDialog}
      </>
    )
  },
)
