import {
  ApiError,
  ErrorDetailPayloadItem,
  formatApiError,
  FormattedApiError,
} from '@polyu-dip/api-core'
import { notEmpty } from '@polyu-dip/utilities'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogOption, useOverlay } from '../components'

type ActionType = null | 'retry' | string

type ErrorHandle = {
  title?: string
  content: string
  canRetry?: boolean
  onDismiss?(): void
  onRetry?(): void
}

type Options = {
  defaultTitle?: string
  disallowRetry?: boolean
  retryActions?: DialogOption<ActionType>['actions']
  onUnFormattedErrorActions?: DialogOption<ActionType>['actions']

  errorMappings?: {
    [errorCode: string]:
      | ErrorHandle
      | ((error: ErrorDetailPayloadItem) => ErrorHandle)
  }
  errorFormatter?: {
    formatted?: (
      apiError: FormattedApiError<any>,
      defaultOptions: Pick<DialogOption<ActionType>, 'title' | 'content'>,
      opts?: Options,
    ) => DialogOption<ActionType> & { onDismiss?(): void }
    unformatted?: (
      apiError: ApiError,
      defaultOptions: Pick<DialogOption<ActionType>, 'title' | 'content'>,
      opts?: Options,
    ) => DialogOption<ActionType> & { onDismiss?(): void }
  }
}

export enum DuplicatedValueErrorCode {
  duplicatedEntityValue = 'pending_duplicated_entity_value',
  duplicatedEmailInSameRequest = 'pending_duplicates_emails_in_same_request',
  duplicatedEmailInSystem = 'pending_duplicates_emails_in_system',
}

enum UniqueFormField {
  systemParametersName = 'SystemParameters_Name',
  schoolsShortCode = 'Schools_ShortCode',
  school = 'School',
  email = 'Email',
}

export type StandardErrorMapper = Omit<Options, 'disallowRetry'> & {
  onRetry?: () => void
  onDismiss?: () => void
  onExtraActions?: (action: string) => void
  retryActions?: DialogOption<ActionType>['actions']
  onUnFormattedErrorActions?: DialogOption<ActionType>['actions']
}

export function useApiErrorHandle() {
  const { t } = useTranslation()
  const { showDialog } = useOverlay()

  const retryActions = useMemo(
    (): DialogOption<ActionType>['actions'] => [
      { text: t('common.cancel'), value: null },
      { text: t('error.action.retry'), value: 'retry' },
    ],
    [t],
  )

  const defaultActions = useMemo(
    (): DialogOption<ActionType>['actions'] => [
      { text: t('common.ok'), value: null },
    ],
    [t],
  )

  const defaultErrorMappings = useCallback(
    (field?: string): { [K: string]: ErrorHandle } => {
      return {
        E1021: {
          content: t('error.conflict.content'),
        },
        [DuplicatedValueErrorCode.duplicatedEntityValue]: {
          content: t('error.duplicatedEntityValue', { field: field ?? '' }),
        },
        [DuplicatedValueErrorCode.duplicatedEmailInSystem]: {
          content: t('error.duplicatedEmailInSystem'),
        },
        [DuplicatedValueErrorCode.duplicatedEmailInSameRequest]: {
          content: t('error.duplicatedEmailInSameRequest'),
        },
      }
    },
    [t],
  )

  const getErrorField = useCallback(
    (targetEntity?: string) => {
      switch (targetEntity) {
        case UniqueFormField.systemParametersName:
          return t('masterData.addMasterDataDialog.field.name.label')
        case UniqueFormField.schoolsShortCode:
        case UniqueFormField.school:
          return t('schools.fields.shortCode')
        case UniqueFormField.email:
          return t('users.fields.email')
        default:
          return targetEntity
      }
    },
    [t],
  )

  const getFormattedError = useCallback(formatApiError, [])

  const getFormattedErrorDetailMessage = useCallback(
    (apiError: FormattedApiError<any>) => {
      const errorCodes = apiError.errors.map((it) => it.code)
      return t('error.errorDetail', {
        codes: errorCodes.length === 0 ? '---' : errorCodes.join(', '),
        traceId: apiError.traceId,
      })
    },
    [t],
  )

  const getFormattedErrorDialogOptions = useCallback(
    (
      apiError: FormattedApiError,
      defaultOptions: Pick<DialogOption<ActionType>, 'title' | 'content'>,
      opts?: Options,
    ) => {
      const mappedError = apiError.errors
        .map((err) => {
          const handle = opts?.errorMappings?.[err.code]
          if (typeof handle === 'function') {
            return handle(err)
          }
          const field = getErrorField(err.extra[0])
          return handle ?? defaultErrorMappings(field)[err.code]
        })
        .filter(notEmpty)

      const canRetry =
        !opts?.disallowRetry &&
        !mappedError.some((it) => it == null || !it.canRetry)

      const mappedTitles = _.uniq(
        mappedError.map((it) => it?.title).filter(notEmpty),
      )

      const title =
        (mappedTitles.length === 1
          ? mappedTitles[0]
          : opts?.defaultTitle ?? mappedTitles[0]) ?? defaultOptions.title

      const content =
        mappedError.length > 0
          ? mappedError.map((it) => it.content).join('\n')
          : defaultOptions.content

      const onDismissHandlers = mappedError
        .map((it) => it.onDismiss)
        .filter(notEmpty)

      return {
        title: title,
        content: `${content} ${
          canRetry ? t('error.retryContent') : ''
        }\n\n${getFormattedErrorDetailMessage(apiError)}`.trim(),
        actions: canRetry ? retryActions : defaultActions,
        onDismiss:
          onDismissHandlers.length > 0
            ? () => {
                onDismissHandlers.forEach((it) => it())
              }
            : undefined,
      }
    },
    [
      defaultActions,
      defaultErrorMappings,
      getErrorField,
      getFormattedErrorDetailMessage,
      retryActions,
      t,
    ],
  )

  const getUnformattedErrorDialogOptions = useCallback(
    (
      apiError: ApiError,
      defaultOptions: Pick<DialogOption<ActionType>, 'title' | 'content'>,
      opts?: Options,
    ) => {
      return {
        title: defaultOptions.title,
        content: defaultOptions.content,
        actions: opts?.onUnFormattedErrorActions ?? defaultActions,
      }
    },
    [defaultActions],
  )

  const getErrorDialogOption = useCallback(
    (
      error: any,
      opts?: Options,
    ): DialogOption<ActionType> & { onDismiss?(): void } => {
      const apiError = getFormattedError(error)

      if (apiError == null) {
        return {
          title: t('error.default.title'),
          content: t('error.default.content', {
            error,
          }),
          actions: [{ text: t('common.ok'), value: null }],
        }
      }

      const apiCanRetry = !opts?.disallowRetry && apiError.isTemporary

      switch (apiError.kind) {
        case 'timeout':
        case 'cancelled':
          return {
            title: t('error.timeout.title'),
            content: `${t('error.timeout.content')} ${
              apiCanRetry ? t('error.retryContent') : ''
            }`,
            actions: apiCanRetry
              ? opts?.retryActions ?? retryActions
              : defaultActions,
          }

        case 'cannot-connect':
          return {
            title: t('error.cannotConnect.title'),
            content: `${t('error.cannotConnect.content')} ${
              apiCanRetry ? t('error.retryContent') : ''
            }`,
            actions: apiCanRetry
              ? opts?.retryActions ?? retryActions
              : defaultActions,
          }
      }

      let defaultOptions: { title: string; content: string }

      switch (apiError.kind) {
        case 'forbidden':
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.forbidden.title'),
            content: t('error.forbidden.content'),
          }
          break
        case 'unauthorized':
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.unauthorized.title'),
            content: t('error.unauthorized.content'),
          }
          break
        case 'not-found':
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.notFound.title'),
            content: t('error.notFound.content'),
          }
          break
        case 'server':
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.serverError.title'),
            content: t('error.serverError.content', {
              error,
            }),
          }
          break
        case 'rejected':
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.badRequest.title'),
            content: t('error.badRequest.content', {
              error,
            }),
          }
          break

        default:
          defaultOptions = {
            title: opts?.defaultTitle ?? t('error.default.title'),
            content: t('error.default.content'),
          }
      }

      if (apiError.formatted) {
        return (
          opts?.errorFormatter?.formatted ?? getFormattedErrorDialogOptions
        )(apiError, defaultOptions, opts)
      } else {
        return (
          opts?.errorFormatter?.unformatted ?? getUnformattedErrorDialogOptions
        )(apiError, defaultOptions, opts)
      }
    },
    [
      defaultActions,
      getFormattedError,
      getFormattedErrorDialogOptions,
      getUnformattedErrorDialogOptions,
      retryActions,
      t,
    ],
  )

  const showErrorDialog = useCallback(
    async (error: any, opts?: Options) => {
      const dialogOptions = getErrorDialogOption(error, opts)
      const ret = await showDialog(dialogOptions)
      dialogOptions.onDismiss?.()
      return ret
    },
    [getErrorDialogOption, showDialog],
  )

  const standardErrorHandler = useCallback(
    async (error: any, opts?: StandardErrorMapper) => {
      const ret = await showErrorDialog(error, {
        ...opts,
        disallowRetry: opts?.onRetry == null,
      })
      if (ret == 'retry') {
        opts?.onRetry?.()
      } else if (ret != null && opts?.onExtraActions != null) {
        opts?.onExtraActions(ret)
      } else {
        opts?.onDismiss?.()
      }
    },
    [showErrorDialog],
  )

  return {
    retryActions,
    defaultActions,
    getFormattedError,
    getFormattedErrorDetailMessage,
    getErrorDialogOption,
    showErrorDialog,
    standardErrorHandler,
  }
}
