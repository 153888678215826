import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  DiStrategiesQueryParameters,
  DiStrategiesResponsePayload,
  DiStrategyExpandableParameter,
  DiStrategyResponsePayload,
  PostDiStrategyPayload,
  PutDiStrategyPayload,
} from './di-strategies-api.type'

const PATH = '/di-strategies'

export function getDiStrategies(param?: DiStrategiesQueryParameters) {
  return ApiCore.primary.get<DiStrategiesResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}

export function getDiStrategyDetails(
  id: string,
  param?: DiStrategyExpandableParameter,
) {
  return ApiCore.primary.get<DiStrategyResponsePayload>(
    `${PATH}/${id}/details`,
    {
      param: {
        ...param,
      },
    },
  )
}

export function postDiStrategiesBulk(payloads: PostDiStrategyPayload[]) {
  return ApiCore.primary.post<DiStrategyResponsePayload>(`${PATH}/bulk`, {
    body: payloads,
  })
}

export function putDiStrategyById(id: string, payload: PutDiStrategyPayload) {
  return ApiCore.primary.put<DiStrategyResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}
