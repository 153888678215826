import { Button, Grid, Typography } from '@mui/material'
import { ChangeEvent, memo, ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { useOverlay } from '../overlay-provider'

export const BYTES_FOR_ONE_MB = 1048576

const UploadButton = styled(Button)<{ component: ReactNode }>`
  width: 530px;
  height: 240px;
  border: 1px dashed ${({ theme }) => theme.palettes.general.greys[2]};
  border-radius: 10px;
`

const DragFileElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`

type UploadFileProps = {
  onUploadFile: (file: File) => void
  maxSizeInMb?: number
  acceptedFileType?: string
}

export const UploadFile = memo<UploadFileProps>(
  ({ onUploadFile, maxSizeInMb, acceptedFileType }) => {
    const { t } = useTranslation()
    const { showDialog } = useOverlay()
    const [dragActive, setDragActive] = useState(false)

    const handleSelectedFile = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        const { files: fileList } = event.target

        if (!fileList || fileList?.length === 0) {
          return
        }

        for (let i = 0; i < fileList.length; i++) {
          if (
            maxSizeInMb != null &&
            fileList[i].size > maxSizeInMb * BYTES_FOR_ONE_MB
          ) {
            showDialog({
              title: t('attachment.errorMessage.uploadAttachment.title'),
              content: t(
                'attachment.errorMessage.uploadAttachment.exceedMaxFileSize.content',
                { maxSizeInMb },
              ),
              actions: [{ text: t('common.ok'), value: null }],
            })
            return
          }

          onUploadFile(fileList[i])
        }
      },
      [maxSizeInMb, onUploadFile, showDialog, t],
    )

    const handleDrag = useCallback((e: any) => {
      e.preventDefault()
      e.stopPropagation()
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true)
      } else if (e.type === 'dragleave') {
        setDragActive(false)
      }
    }, [])

    const handleDrop = useCallback(
      (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files?.[0]) {
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            if (
              maxSizeInMb != null &&
              e.dataTransfer.files[i].size > maxSizeInMb * BYTES_FOR_ONE_MB
            ) {
              showDialog({
                title: t('attachment.errorMessage.uploadAttachment.title'),
                content: t(
                  'attachment.errorMessage.uploadAttachment.exceedMaxFileSize.content',
                  { maxSizeInMb },
                ),
                actions: [{ text: t('common.ok'), value: null }],
              })
              return
            }

            onUploadFile(e.dataTransfer.files[i])
          }
        }
      },
      [maxSizeInMb, onUploadFile, showDialog, t],
    )

    return (
      <UploadButton
        variant="text"
        component="label"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Grid item>
            <Svg.UploadCloud />
          </Grid>
          <Grid item mt={7}>
            <Typography color="black">
              {t('attachment.uploadFile.title')}
            </Typography>
          </Grid>
          <Grid item mt={3}>
            <Typography color="gray">
              {t('attachment.uploadFile.description', { maxSizeInMb })}
            </Typography>
          </Grid>
        </Grid>

        <input
          hidden
          accept={acceptedFileType}
          type="file"
          onChange={handleSelectedFile}
          multiple
        />
        {dragActive && (
          <DragFileElement
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              minHeight="100%"
            >
              <Grid item>
                <Typography color="white">
                  {t('attachment.uploadFile.dropTitle')}
                </Typography>
              </Grid>
            </Grid>
          </DragFileElement>
        )}
      </UploadButton>
    )
  },
)
