import { withEnvironment } from '@polyu-dip/model-core'
import {
  FilesStoreModel,
  LessonPlansStoreModel,
  MasterDataStoreModel,
  UiStoreModel,
  UserProfileStoreModel,
  UsersStoreModel,
} from '@polyu-dip/stores'
import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const RootStoreModel = types
  .model('RootStore')
  .props({
    masterDataStore: types.optional(MasterDataStoreModel, {}),
    userProfileStore: types.optional(UserProfileStoreModel, {}),
    usersStore: types.optional(UsersStoreModel, {}),
    uiStore: types.optional(UiStoreModel, {}),
    lessonPlansStore: types.optional(LessonPlansStoreModel, {}),
    filesStore: types.optional(FilesStoreModel, {}),
  })
  .extend(withEnvironment)
// .actions((self) => {
//   const actions = {
//     // TODO: an action to reset/re-initialize self

//     acquireAuthToken: flow(function* acquireAuthTokenFlow(
//       instance: IPublicClientApplication,
//     ) {
//       // get access token from msal and set as api header
//       let result
//       try {
//         const accounts = instance.getAllAccounts()
//         if (accounts?.length) {
//           // user has already logged in, acquire token silently
//           result = yield instance.acquireTokenSilent({
//             scopes: loginRequest.scopes,
//             account: accounts[0],
//           })
//           self.userProfileStore.setAuthToken(result.accessToken)
//           console.debug('Aquire Token Silently <277535>', { result }, '{}')
//         } else {
//           throw new Error('No account')
//         }
//       } catch (error) {
//         console.debug(
//           'Err-id:562532 ,failed to get access token silently',
//           error,
//         )
//         throw error
//       }
//     }),
//   }
//   return actions
// })
// .actions((self) => {
//   const actions = {
//     login: flow(function* loginFlow(instance: IPublicClientApplication) {
//       // get access token from msal and set as api header
//       let result
//       try {
//         yield self.acquireAuthToken(instance)
//         return
//       } catch (error) {
//         result = yield instance.acquireTokenPopup(loginRequest)
//         console.debug('aquire token from pop up <934898>', {}, '{}')
//         self.userProfileStore.setAuthToken(result.accessToken)
//       }
//     }),
//     logout: (instance: IPublicClientApplication) => {
//       // clear session
//       self.userProfileStore.setAuthToken('')
//       // TODO: reset mst
//       // logout aadb2c
//       instance.logoutRedirect()
//     },
//   }
//   return actions
// })

export type RootStore = Instance<typeof RootStoreModel>
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>
