export const lessonPlanExample = {
  title: '教學示例',

  detail: {
    viewInitiatedLessonPlan: '查看原稿留言',
    viewPublishingLessonPlan: '返回審批頁面',
  },

  tags: {
    primarySchool: '小學',
    secondarySchool: '中學',
    lessonPlanExample: '教案示例',
    lessonPlanSummary: '教案概覽',
  },

  sorting: {
    viewCount: '最高瀏覽量',
    exportCount: '最高匯出次數',
    copiesCount: '最高複製次數',
  },

  tableFields: {
    teachingTopic: '教案名稱',
    learningPhase: '學習階段',
    class: '年級',
    subject: '科目',
    sharedBy: '分享自',
    code: '教案編號',
    publishedDateTime: '發佈日期',
    lastModifiedDateTime: '最後更新',
  },

  pagination: {
    next: '下頁',
  },

  action: {
    copy: {
      successMessage: '已成功複製',
      error: { title: '複製失敗' },
    },
    approve: {
      successMessage: '已成功接納',
      error: { title: '未能接納' },
    },
    reject: {
      successMessage: '已成功拒絕',
      error: { title: '未能拒絕' },
    },
    publish: {
      successMessage: '已成功發佈',
      error: { title: '未能發佈' },
    },
    unPublish: {
      successMessage: '已成功下架',
      error: { title: '下架失敗' },
    },
  },
}
