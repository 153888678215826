import { GridColDef } from '@mui/x-data-grid'
import { LessonPlansUpdatedExampleAndResourceResponsePayload } from '@polyu-dip/apis'
import { useComputed } from '@polyu-dip/helpers'
import { useLessonPlanUpdatedExamplesAndResources } from '@polyu-dip/queries'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  SectionTitle,
  Table,
  Tag,
  TruncatedCellContent,
} from '../../../components'
import { contentPaths } from '../../../content-paths'
import { useDisplayName } from '../../../services/'
import { useStores } from '../../../stores'

const Container = styled.div`
  margin-top: 56px;
`

export const LessonPlansUpdatedExamplesAndResourcesTable = observer(() => {
  const { t, i18n } = useTranslation()
  const { getDisplayName } = useDisplayName()

  const navigate = useNavigate()

  const { data: updatedExamplesAndResourcesData } =
    useLessonPlanUpdatedExamplesAndResources(useStores, {
      sort: [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      limit: 5,
    })

  const columns: GridColDef<LessonPlansUpdatedExampleAndResourceResponsePayload>[] =
    [
      {
        field: 'type',
        headerName: t(
          'home.teacher.updatedExamplesAndResourcesTable.fields.type',
        ),
        width: 200,
        renderCell: ({ row }) => (
          <Tag
            label={
              row.type === 'lesson-plan-example'
                ? t(
                    'home.teacher.updatedExamplesAndResourcesTable.dataType.example',
                  )
                : t(
                    'home.teacher.updatedExamplesAndResourcesTable.dataType.resource',
                  )
            }
            color={
              row.type === 'lesson-plan-example' ? 'redLabel' : 'greenLabel'
            }
          />
        ),
        sortable: false,
      },
      {
        field: 'title',
        headerName: t(
          'home.teacher.updatedExamplesAndResourcesTable.fields.title',
        ),
        width: 190,
        sortable: false,
        renderCell: ({ row }) => <TruncatedCellContent content={row.title} />,
      },
      {
        field: 'classLevel.learningPhase.order',
        headerName: t(
          'home.teacher.updatedExamplesAndResourcesTable.fields.learningPhase',
        ),
        width: 180,
        renderCell: ({ row }) =>
          row.learningPhase?.name == null ? (
            '---'
          ) : (
            <Tag
              label={getDisplayName(row.learningPhase?.name)}
              colorCustomize={row.learningPhase?.colorCode}
            />
          ),
        sortable: false,
      },
      {
        field: 'classLevel',
        headerName: t(
          'home.teacher.updatedExamplesAndResourcesTable.fields.classLevel',
        ),
        width: 100,
        renderCell: ({ row }) =>
          row.classLevel?.name == null ? (
            '---'
          ) : (
            <Tag
              label={getDisplayName(row.classLevel?.name)}
              colorCustomize={row.classLevel?.colorCode}
            />
          ),
        sortable: false,
      },
      {
        field: 'subject',
        headerName: t(
          'home.teacher.updatedExamplesAndResourcesTable.fields.subject',
        ),
        width: 200,
        renderCell: ({ row }) =>
          row.subject?.name == null ? (
            '---'
          ) : (
            <Tag
              label={getDisplayName(row.subject?.name)}
              colorCustomize={row.subject?.colorCode}
            />
          ),
        sortable: false,
      },
      {
        field: 'school.name',
        headerName: t(
          'home.teacher.updatedExamplesAndResourcesTable.fields.sharedBy',
        ),
        width: 300,
        renderCell: ({ row }) => (
          <TruncatedCellContent content={getDisplayName(row.school?.name)} />
        ),
        sortable: false,
      },
      {
        field: 'lastModifiedDateTime',
        headerName: t(
          'home.teacher.updatedExamplesAndResourcesTable.fields.lastModifiedDateTime',
        ),
        type: 'dateTime',
        valueFormatter: ({ value }) =>
          dayjs(value).format(t('common.format.datetime')),
        width: 190,
        sortable: false,
      },
    ]

  const computedLessonPlanData = useComputed(
    () => updatedExamplesAndResourcesData?.data ?? [],
    [updatedExamplesAndResourcesData],
  )
  return (
    <Container>
      <SectionTitle
        title={t('home.teacher.updatedExamplesAndResourcesTable.headerTitle')}
        color="orange"
      />
      <Table
        columns={columns}
        rows={computedLessonPlanData}
        autoHeight
        rowHeight={i18n.language === 'en' ? 64 : 50}
        hideFooterPagination
        contentFontSize={14}
        padding={12}
        onRowClick={({ row }) => {
          if (row.type === 'lesson-plan-example') {
            navigate(contentPaths.lessonPlanExamplesDetail(row.id))
          } else if (row.type === 'online-resource') {
            navigate(contentPaths.resources('online'))
          } else {
            navigate(contentPaths.resources('downloadable'))
          }
        }}
      />
    </Container>
  )
})
