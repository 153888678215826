import { Button, Divider, Grid } from '@mui/material'
import { Dispatch, memo, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import { AutoComplete, SelectOption, TextField } from '../../form'
import { SearchLeftIcon } from '../search-left-icon'

const Container = styled.div`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding: ${({ theme }) => theme.spacings.general[4]}px;
`

const StyledDivider = styled(Divider)`
  border-bottom-width: 2px;
  border-bottom-color: ${({ theme }) => theme.palettes.general.lightRed};
`

export type UserFilterProps = {
  roleOptions?: SelectOption[]
  setSelectedRole?: Dispatch<SetStateAction<SelectOption | undefined>>
  schoolOptions?: SelectOption[]
  setSelectedSchool?: Dispatch<SetStateAction<SelectOption | undefined>>
  selectedRole?: SelectOption
  selectedSchool?: SelectOption
  emailFilter?: string
  setEmailFilter?: Dispatch<SetStateAction<string | undefined>>
  onAddUserButtonClicked?: () => void
}

export const UserFilter = memo<UserFilterProps>(
  ({
    roleOptions,
    schoolOptions,
    setSelectedRole,
    setSelectedSchool,
    selectedRole,
    selectedSchool,
    emailFilter,
    setEmailFilter,
    onAddUserButtonClicked,
  }) => {
    const { t } = useTranslation()
    const [email, setEmail] = useState<string | undefined>(emailFilter)

    const handleApplyEmailFilter = useCallback(() => {
      setEmailFilter?.(email)
    }, [email, setEmailFilter])

    return (
      <Container>
        <Grid container display="flex" columnGap={3}>
          {setEmailFilter != null && (
            <Grid item sm={7}>
              <TextField
                placeholder={t('users.fields.email')}
                leftIcon={<SearchLeftIcon />}
                value={email ?? ''}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                onStopInput={handleApplyEmailFilter}
              />
            </Grid>
          )}
          {schoolOptions != null && setSelectedSchool != null && (
            <Grid item sm={1.5}>
              <AutoComplete
                sx={{ borderRadius: 2 }}
                placeholder={t('users.fields.schoolShortCode')}
                options={schoolOptions}
                value={selectedSchool}
                onChange={(e, value) => setSelectedSchool(value)}
              />
            </Grid>
          )}
          {roleOptions != null && setSelectedRole != null && (
            <Grid item sm={1.5}>
              <AutoComplete
                sx={{ borderRadius: 2 }}
                placeholder={t('users.fields.role')}
                options={roleOptions}
                value={selectedRole}
                onChange={(e, value) => setSelectedRole(value)}
              />
            </Grid>
          )}
          {onAddUserButtonClicked != null && (
            <Grid item sm={1.5}>
              <Button startIcon={<Svg.Plus />} onClick={onAddUserButtonClicked}>
                {t('users.actions.addUser.title')}
              </Button>
            </Grid>
          )}
        </Grid>
        <StyledDivider />
      </Container>
    )
  },
)
