import { contentPaths } from '../content-paths'
import { useStores } from '../stores'
import { useResourceTypes } from '@polyu-dip/queries'
import { useComputed } from '@polyu-dip/helpers'
import { useMasterDataInit } from './use-master-data-init'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectOption } from '../components'

export enum ViewModeType {
  list = 'LIST_MODE',
  Grid = 'GRID_MODE',
}

export function useLessonPlanFilter() {
  const { isAllMasterDataFetchSuccess } = useMasterDataInit(
    contentPaths.myLessonPlan(),
  )
  const { isSuccess: isResourceTypeFetchSuccess } = useResourceTypes(useStores)

  const { masterDataStore } = useStores()
  const { t } = useTranslation()

  const [viewMode, setViewMode] = useState<ViewModeType>(ViewModeType.list)
  const [selectedEducationLevel, setSelectedEducationLevel] =
    useState<SelectOption>()
  const [selectedSubject, setSelectedSubject] = useState<SelectOption>()
  const [selectedLearningPhase, setSelectedLearningPhase] =
    useState<SelectOption>()
  const [selectedDiStrategyClass, setSelectedDiStrategyClass] =
    useState<SelectOption>()
  const [selectedDiStrategy, setSelectedDiStrategy] = useState<SelectOption>()
  const [inputTeachingTopic, setInputTeachingTopic] = useState<string>()
  const [selectedResourceType, setSelectedResourceType] =
    useState<SelectOption>()

  const sortItemOptions = useMemo(
    () => [
      {
        label: t('lessonPlanExample.sorting.viewCount'),
        value: 'lessonPlanStatistics.viewCount',
      },
      {
        label: t('lessonPlanExample.sorting.exportCount'),
        value: 'lessonPlanStatistics.exportCount',
      },
      {
        label: t('lessonPlanExample.sorting.copiesCount'),
        value: 'copiesCount',
      },
    ],
    [t],
  )

  const [selectedSortItem, setSelectedSortItem] = useState<string>(
    sortItemOptions[0].value,
  )

  const educationLevelOptions = useComputed(
    () =>
      masterDataStore.activeEducationLevels?.map((educationLevel) => ({
        label: educationLevel.displayName,
        value: educationLevel.id,
      })),
    [masterDataStore.activeEducationLevels],
  )

  const subjectOptions = useComputed(
    () =>
      masterDataStore.activeSubjects?.map((subject) => ({
        label: subject.displayName,
        value: subject.id,
        educationLevelIds: subject.subjectEducationLevelMappings.map(
          (it) => it.educationLevelId,
        ),
      })),
    [masterDataStore.activeSubjects],
  )

  const learningPhaseOptions = useComputed(
    () =>
      masterDataStore.activeLearningPhases
        ?.filter((it) => {
          if (selectedEducationLevel == null) return true
          return it.educationLevelId == selectedEducationLevel.value
        })
        .map((learningPhases) => ({
          label: learningPhases.displayName,
          value: learningPhases.id,
          educationLevelId: learningPhases.educationLevelId,
        })),
    [masterDataStore.activeLearningPhases, selectedEducationLevel],
  )

  const diStrategyClassOptions = useComputed(
    () =>
      masterDataStore.activeDiStrategyClasses?.map((diStrategyClass) => ({
        label: t('lessonPlan.createForm.diStrategyClass.label', {
          label: diStrategyClass?.label,
          name: diStrategyClass.displayName,
        }),
        value: diStrategyClass.id,
      })),
    [masterDataStore.activeDiStrategyClasses],
  )

  const diStrategyOptions = useComputed(
    () =>
      masterDataStore.activeDiStrategies
        ?.filter((it) => it.diStrategyClassId == selectedDiStrategyClass?.value)
        .map((diStrategy) => ({
          label: t('lessonPlan.createForm.diStrategies.label', {
            diStrategyClassLabel: diStrategy.diStrategyClass?.label,
            diStrategyLabelNumber: diStrategy.order,
            diStrategyName: diStrategy.displayName,
          }),
          value: diStrategy.id,
        })),
    [masterDataStore.activeDiStrategies, selectedDiStrategyClass],
  )

  const resourceTypeOptions = useComputed(
    () =>
      masterDataStore.activeResourceTypes?.map((resourceType) => ({
        label: resourceType.displayName,
        value: resourceType.id,
      })),
    [masterDataStore.activeResourceTypes],
  )

  return {
    viewMode,
    setViewMode,
    isAllMasterDataFetchSuccess:
      isAllMasterDataFetchSuccess && isResourceTypeFetchSuccess,
    educationLevelOptions,
    subjectOptions,
    learningPhaseOptions,
    diStrategyClassOptions,
    diStrategyOptions,
    resourceTypeOptions,
    selectedEducationLevel,
    setSelectedEducationLevel,
    selectedLearningPhase,
    setSelectedLearningPhase,
    selectedSubject,
    setSelectedSubject,
    selectedDiStrategyClass,
    setSelectedDiStrategyClass,
    selectedDiStrategy,
    setSelectedDiStrategy,
    inputTeachingTopic,
    setInputTeachingTopic,
    selectedResourceType,
    setSelectedResourceType,
    selectedSortItem,
    setSelectedSortItem,
    sortItemOptions,
  }
}
