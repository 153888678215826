import { Dialog, Box, Grid, Button, Typography } from '@mui/material'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import { TextField } from '../../../components'

export type CommentAttachmentType = {
  name: string
  file: {
    url: string
    source: string
  }
}

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 22px;
`

const StyledReplyIcon = styled(Svg.Reply)``

const StyledContentContainer = styled(Grid)`
  border-left: 2px solid;
  border-color: ${({ theme }) => theme.palettes.general.greys[3]};
  padding-left: 8px;
`

const StyledContent = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.palettes.general.greys[2]};
  word-break: break-word;
`

const StyledButton = styled(Button)`
  width: 193px;
`

const AttachmentButton = styled(Button)<{ component: ReactNode }>`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding: 0px;
  min-width: 100%;
  height: auto;
  align-items: center;
`

const DeleteButtonContainer = styled.div`
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacings.general[1]}px;
`

const AttachmentLabel = styled(Button)`
  padding-left: ${({ theme }) => theme.spacings.general[2]}px;
  margin-bottom: ${({ theme }) => theme.spacings.general[2]}px;
  border: 1px solid ${({ theme }) => theme.palettes.general.greys[3]};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palettes.general.lightBlue};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  margin-right: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.palettes.general.lightBlue};
    cursor: unset;
  }
`

export type ReplyValue = {
  content?: string
  repliedToCommentId?: string
  LessonPlanCommentAttachments: {
    attachmentName: string
    file: {
      url: string
      source: string
    }
  }[]
}

export type ModalType = 'reply' | 'edit' | 'delete'

type Props = {
  open: boolean
  onClose(): void
  onSubmit: (action: ModalType, replyValue?: ReplyValue) => Promise<void>
  onAttachFile: (event: ChangeEvent<HTMLInputElement>) => Promise<void>
  id: string
  content?: string
  modalType: ModalType
  repliedToCommentContent?: string
  attachments: CommentAttachmentType[]
  setAttachments: Dispatch<SetStateAction<CommentAttachmentType[]>>
}

export const CommentActionModal = observer<Props>(
  ({
    open,
    onClose,
    onSubmit,
    id,
    content,
    modalType,
    repliedToCommentContent,
    onAttachFile,
    attachments,
    setAttachments,
  }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const [inputContent, setInputContent] = useState(content)
    const [replyContent, setReplyContent] = useState<string>()

    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (modalType === 'reply') {
          setReplyContent(e.target.value)
        }
        if (modalType === 'edit') {
          setInputContent(e.target.value)
        }
      },
      [modalType],
    )

    const handleReply = useCallback(async () => {
      await onSubmit(modalType, {
        repliedToCommentId: id,
        content: replyContent,
        LessonPlanCommentAttachments: attachments.map((attachment) => ({
          attachmentName: attachment.name,
          file: {
            url: attachment.file.url,
            source: attachment.file.source,
          },
        })),
      })
      if (modalType === 'reply') {
        setReplyContent('')
      }
      onClose()
    }, [onSubmit, modalType, id, replyContent, attachments, onClose])

    const handleEdit = useCallback(() => {
      onSubmit('edit', {
        content: inputContent,
        LessonPlanCommentAttachments: attachments.map((attachment) => ({
          attachmentName: attachment.name,
          file: {
            url: attachment.file.url,
            source: attachment.file.source,
          },
        })),
      })
    }, [attachments, inputContent, onSubmit])

    const handleDelete = useCallback(() => {
      onSubmit('delete')
    }, [onSubmit])

    const handleClose = useCallback(() => {
      onClose()
      setInputContent(content)
      setReplyContent('')
    }, [content, onClose])

    return (
      <Dialog open={open} fullWidth={true} maxWidth="md" onClose={handleClose}>
        <Box p={4}>
          <Title>
            {t(
              `lessonPlan.detail.lessonPlanComments.commentActionModal.${modalType}` as any,
            )}
          </Title>

          <Grid container direction="row" mt={4}>
            {modalType === 'delete' ? (
              <Typography>
                {t(
                  'lessonPlan.detail.lessonPlanComments.commentActionModal.deleteMessage',
                )}
              </Typography>
            ) : (
              <>
                <Grid item xs>
                  <Grid container direction="column">
                    <Grid container direction="row">
                      <Grid item mr={2} mt={2}>
                        <StyledReplyIcon />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          value={
                            modalType === 'reply' ? replyContent : inputContent
                          }
                          onChange={handleOnChange}
                          placeholder={t(
                            'lessonPlan.detail.lessonPlanComments.commentActionModal.replyContent',
                          )}
                          multiline
                          maxRows={8}
                          mb={2}
                        />
                      </Grid>
                      <Grid item ml={2} mr={2} mt={2}>
                        <AttachmentButton
                          variant="text"
                          component="label"
                          disableFocusRipple
                          disableRipple
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <Svg.Attachment
                            fill={theme.palettes.general.greys[2]}
                          />
                          <input
                            hidden
                            type="file"
                            onChange={onAttachFile}
                            multiple
                          />
                        </AttachmentButton>
                      </Grid>
                    </Grid>
                    <Grid container>
                      {attachments.map((attachment, idx) => (
                        <AttachmentLabel
                          key={attachment.name}
                          disableFocusRipple
                          disableRipple
                        >
                          <DeleteButtonContainer>
                            <Svg.Close
                              cursor="pointer"
                              width={14}
                              onClick={() => {
                                const newAttachments = attachments.slice()
                                newAttachments.splice(idx, 1)
                                setAttachments(newAttachments)
                              }}
                            />
                          </DeleteButtonContainer>
                          {attachment.name}
                        </AttachmentLabel>
                      ))}
                    </Grid>
                    <StyledContentContainer item mb={2}>
                      <StyledContent>
                        {modalType === 'reply'
                          ? content
                          : repliedToCommentContent}
                      </StyledContent>
                    </StyledContentContainer>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>

          <Grid mt={10} container display="flex" justifyContent="flex-end">
            <Grid item>
              <StyledButton variant="outlined" onClick={handleClose}>
                {t(
                  `lessonPlan.detail.lessonPlanComments.commentActionModal.actions.${
                    modalType === 'delete' ? 'close' : 'cancel'
                  }`,
                )}
              </StyledButton>
            </Grid>
            {modalType === 'delete' ? (
              <Grid item ml={2}>
                <StyledButton color="cascade" onClick={handleDelete}>
                  {t(
                    'lessonPlan.detail.lessonPlanComments.commentActionModal.actions.confirm',
                  )}
                </StyledButton>
              </Grid>
            ) : (
              <Grid item ml={2}>
                <StyledButton
                  onClick={modalType === 'reply' ? handleReply : handleEdit}
                  color="blue"
                  disabled={
                    modalType === 'reply'
                      ? replyContent == null || replyContent.length === 0
                      : inputContent == null || inputContent.length === 0
                  }
                >
                  {t(
                    'lessonPlan.detail.lessonPlanComments.commentActionModal.actions.send',
                  )}
                </StyledButton>
              </Grid>
            )}
          </Grid>
        </Box>
      </Dialog>
    )
  },
)
