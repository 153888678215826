import { Box, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import styled from 'styled-components'

const Text = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palettes.semantic.astronautBlue};
  white-space: pre-wrap;
  word-break: break-word;
`

type TextFieldItemProps = {
  isRowDirection?: boolean
  label?: string
  render?: ReactNode
  value?: string | number
  hidden?: boolean
  fieldXs?: number
  valueMinHeight?: number
}

type TextFieldListProps = {
  items: TextFieldItemProps[]
}

export const TextFieldList = observer<TextFieldListProps>(({ items }) => {
  return (
    <Box>
      <Grid container columnSpacing={5} rowSpacing={4}>
        {items.map((it) => {
          if (it?.hidden) return null
          return (
            <Grid key={it.label} item xs={it.fieldXs == null ? 6 : it.fieldXs}>
              <Grid container direction={it.isRowDirection ? 'row' : 'column'}>
                <Grid item>
                  <Typography fontWeight={600} mb={2} mr={12}>
                    {it.label}
                  </Typography>
                </Grid>
                <Grid item minHeight={it.valueMinHeight}>
                  {it.render != null ? it.render : <Text>{it.value}</Text>}
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
})
