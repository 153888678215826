import { Typography } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type DummyPagePropType = {}

const Root = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  color: grey;
  align-items: center;
  justify-content: center;
`

export const DummyPage = memo<DummyPagePropType>(() => {
  const { t } = useTranslation()
  return (
    <Root>
      <Typography variant="h4">{t('common.tbi')}</Typography>
    </Root>
  )
})
