import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { EducationLevel } from './education-level.model'
import { NameModel } from './languages-json'

export const SchoolModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    shortCode: types.string,
    domain: types.string,
    isDisabled: types.boolean,
    educationLevelId: types.maybe(types.string),
    mainSchoolAdminUserId: types.string,
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .named('SchoolModel')
  .views((self) => ({
    get educationLevel() {
      if (self.educationLevelId == null) return undefined
      return (
        self.rootStore.masterDataStore as { educationLevels: EducationLevel[] }
      ).educationLevels.find(
        (educationLevel) => educationLevel.id === self.educationLevelId,
      )
    },
    get schoolUsers() {
      const userList = self.rootStore.usersStore?.users
      if (userList == null) return []
      return userList.filter(
        (user: { schoolId: string }) => user.schoolId === self.id,
      )
    },
    get mainSchoolAdminUser() {
      if (self.mainSchoolAdminUserId == null) return undefined
      return (self.rootStore.usersStore as { users: any }).users.find(
        (user: any) => user.id === self.mainSchoolAdminUserId,
      )
    },
  }))

export type School = Instance<typeof SchoolModel>
