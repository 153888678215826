import { observer } from 'mobx-react-lite'
import { SectionTitle } from '../../../components'
import { useLessonPlanDetail } from '../use-lesson-plan-detail-context'
import { useMemo } from 'react'

export const Title = observer(() => {
  const { lessonPlan: data } = useLessonPlanDetail()
  const subTitle = useMemo(() => {
    if (data.learningUnit == null || data.learningUnit == '') {
      return data.classLevel?.displayName
    }
    return `${data.classLevel?.displayName} - ${data?.learningUnit}`
  }, [data.classLevel?.displayName, data.learningUnit])

  return (
    <SectionTitle
      title={data.teachingTopic ?? ''}
      subTitle={subTitle}
      color="grey"
    />
  )
})
