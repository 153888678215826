export const disposedLessonPlan = {
  title: 'Disposed Lesson Plan',

  tableFields: {
    class: 'Grade',
    subject: 'Subject',
    teachingTopic: 'Name of lesson plan',
    lastModifiedBy: 'Last Modified',
    lastModifiedDateTime: 'Last Modified Date Time',
  },

  pagination: {
    next: 'Next page',
  },
}
