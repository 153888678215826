export const lessonPlanExample = {
  title: 'DI examples',

  detail: {
    viewInitiatedLessonPlan: 'View draft reply',
    viewPublishingLessonPlan: 'Back to approve view',
  },

  tags: {
    primarySchool: 'Primary school',
    secondarySchool: 'Secondary school',
    lessonPlanExample: 'DI examples',
    lessonPlanSummary: 'Overview of lesson plans',
  },

  sorting: {
    viewCount: 'Highest views',
    exportCount: 'Highest export counts',
    copiesCount: 'Highest copy counts',
  },

  tableFields: {
    teachingTopic: 'Name of lesson plan',
    learningPhase: 'Learning phases ',
    class: 'Grade',
    subject: 'Subject',
    sharedBy: 'Shared by',
    code: 'Code of lesson plan',
    publishedDateTime: 'Publication date',
    lastModifiedDateTime: 'Last updated on',
  },

  pagination: {
    next: 'Next page',
  },

  action: {
    copy: {
      successMessage: 'Copied successfully',
      error: { title: 'Failed to copy' },
    },
    approve: {
      successMessage: 'Accepted',
      error: { title: 'Failed to accept' },
    },
    reject: {
      successMessage: 'Rejected ',
      error: { title: 'Failed to reject' },
    },
    publish: {
      successMessage: 'Published successfully',
      error: { title: 'Failed to publish' },
    },
    unPublish: {
      successMessage: 'Successfully removed',
      error: { title: 'Failed to remove' },
    },
  },
}
