import { Button, Grid } from '@mui/material'
import { Dispatch, memo, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import { AutoComplete, SelectOption, TextField } from '../../form'
import { SearchLeftIcon } from '../search-left-icon'

const Container = styled.div`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding: ${({ theme }) => theme.spacings.general[4]}px;
  padding-bottom: 0px;
`

export type SchoolFilterProps = {
  statusOptions?: SelectOption[]
  setSelectedStatus?: Dispatch<SetStateAction<SelectOption | undefined>>
  selectedStatus?: SelectOption
  nameFilter?: string
  setNameFilter?: Dispatch<SetStateAction<string | undefined>>
  shortCodeFilter?: string
  setShortCodeFilter?: Dispatch<SetStateAction<string | undefined>>
  onAddSchoolButtonClicked?: () => void
}

export const SchoolFilter = memo<SchoolFilterProps>(
  ({
    statusOptions,
    selectedStatus,
    setSelectedStatus,
    nameFilter,
    setNameFilter,
    shortCodeFilter,
    setShortCodeFilter,
    onAddSchoolButtonClicked,
  }) => {
    const { t } = useTranslation()
    const [name, setName] = useState<string | undefined>(nameFilter)
    const [shortCode, setShortCode] = useState<string | undefined>(
      shortCodeFilter,
    )

    return (
      <Container>
        <Grid container display="flex" columnGap={3}>
          {setNameFilter != null && (
            <Grid item sm={7}>
              <TextField
                placeholder={t('schools.fields.name')}
                leftIcon={<SearchLeftIcon />}
                value={name ?? ''}
                onChange={(e) => {
                  setName(e.target.value)
                }}
                onStopInput={() => {
                  setNameFilter(name)
                }}
              />
            </Grid>
          )}
          {setShortCodeFilter != null && (
            <Grid item sm={2}>
              <TextField
                placeholder={t('schools.fields.shortCode')}
                leftIcon={<SearchLeftIcon />}
                value={shortCode ?? ''}
                onChange={(e) => {
                  setShortCode(e.target.value)
                }}
                onStopInput={() => setShortCodeFilter(shortCode)}
              />
            </Grid>
          )}
          {statusOptions != null && setSelectedStatus != null && (
            <Grid item sm={1}>
              <AutoComplete
                sx={{ borderRadius: 2 }}
                placeholder={t('schools.fields.status')}
                options={statusOptions}
                value={selectedStatus}
                onChange={(e, value) => setSelectedStatus(value)}
              />
            </Grid>
          )}
          {onAddSchoolButtonClicked != null && (
            <Grid item sm={1.5}>
              <Button
                startIcon={<Svg.Plus />}
                onClick={onAddSchoolButtonClicked}
              >
                {t('schools.actions.add.title')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    )
  },
)
