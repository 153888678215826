import {
  PaginatedPayload,
  PostTagPayload,
  PutTagPayload,
  TagsQueryParameters,
} from '@polyu-dip/apis'
import { Tag } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'tags'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useTags(
  useStores: UseStoresType,
  param?: TagsQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<Tag>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getTags(param),
    opts,
  )
}

export function useCreateTags(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostTagPayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payloads) => masterDataStore.createTags(payloads), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useUpdateTag(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<Tag, unknown, PutTagPayload>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => masterDataStore.updateTag(id, payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useSwitchTagStatus(
  useStores: UseStoresType,
  opts?: UseMutationOptions<Tag, unknown, PutTagPayload & { id: string }>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => masterDataStore.updateTag(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export async function clearTagQueryCaches(queryClient: QueryClient) {
  await queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
