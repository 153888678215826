import { GridColDef } from '@mui/x-data-grid'
import { LessonPlan } from '@polyu-dip/models'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Tag, TruncatedCellContent } from '../../components'
import { useDisplayName } from '../../services'

export function useDisposedLessonPlansColumn() {
  const { t } = useTranslation()
  const { getDisplayName } = useDisplayName()

  const renderColumn = useCallback(() => {
    const columns: GridColDef<LessonPlan>[] = [
      {
        field: 'classLevel.order',
        headerName: t('disposedLessonPlan.tableFields.class'),
        width: 120,
        renderCell: ({ row }) => (
          <Tag
            label={getDisplayName(row.classLevel?.name)}
            colorCustomize={row.classLevel?.colorCode}
          />
        ),
      },
      {
        field: 'subject.name',
        headerName: t('disposedLessonPlan.tableFields.subject'),
        width: 200,
        renderCell: ({ row }) => (
          <Tag
            label={getDisplayName(row.subject?.name)}
            colorCustomize={row.subject?.colorCode}
          />
        ),
      },
      {
        field: 'teachingTopic',
        headerName: t('disposedLessonPlan.tableFields.teachingTopic'),
        width: 400,
        renderCell: ({ row }) => (
          <TruncatedCellContent content={row.teachingTopic} />
        ),
      },
      {
        field: 'lastModifiedByUser.name',
        headerName: t('disposedLessonPlan.tableFields.lastModifiedBy'),
        width: 400,
        renderCell: ({ row }) => (
          <TruncatedCellContent content={row.lastModifiedByUser?.name} />
        ),
      },
      {
        field: 'lastModifiedDateTime',
        headerName: t('disposedLessonPlan.tableFields.lastModifiedDateTime'),
        width: 400,
        type: 'dateTime',
        valueFormatter: ({ value }) =>
          dayjs(value).format(t('common.format.datetime')),
      },
    ]
    return columns
  }, [getDisplayName, t])

  return { renderColumn }
}
