import { MasterDataTable } from '@polyu-dip/stores'
import { Dispatch, memo, SetStateAction } from 'react'
import styled from 'styled-components'
import { MasterDataManagementFilter } from '../../components'

type Props = {
  inputTitle?: string
  setInputTitle?: Dispatch<SetStateAction<string | undefined>>
  handleAddButtonOnClick: () => void
  selectedMasterDataTab: MasterDataTable
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

export const MasterDataFilterBar = memo<Props>(
  ({
    inputTitle,
    setInputTitle,
    handleAddButtonOnClick,
    selectedMasterDataTab,
  }) => {
    return (
      <Container>
        <MasterDataManagementFilter
          inputTitle={inputTitle}
          setInputTitle={setInputTitle}
          handleAddButtonOnClick={handleAddButtonOnClick}
          selectedMasterDataTab={selectedMasterDataTab}
        />
      </Container>
    )
  },
)
