import { FormControlLabel, Switch } from '@mui/material'
import { GridColDef, GridSortModel } from '@mui/x-data-grid'
import { useSchools } from '@polyu-dip/queries'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { School } from '@polyu-dip/models'
import { useComputed } from '@polyu-dip/helpers'
import {
  Table,
  useRowCountState,
  ListPage,
  SchoolFilter,
  TruncatedCellContent,
} from '../../components'
import { useStores } from '../../stores'
import { observer } from 'mobx-react-lite'
import { PaginationParameters } from '@polyu-dip/apis'
import { useDisplayName, LANGUAGES_ENUM } from '../../services'
import { useSchoolFilter, useSchoolManagementActions } from '../../hooks'

const PAGINATION_LIMIT = 15

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

export const Schools = observer(() => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { filterItems, ...filterProps } = useSchoolFilter()
  const {
    handleOpenAddSchoolDialog,
    renderAddSchoolDialog,
    handleOpenDisableConfirmationDialog,
    renderDisableConfirmationDialog,
    handleEnableSchool,
  } = useSchoolManagementActions()

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [sort, setSort] = useState<GridSortModel>()

  const { getDisplayName } = useDisplayName()

  const { data, isLoading } = useSchools(useStores, {
    expand: ['MainSchoolAdminUser'],
    sort: sort?.map((it) => ({
      direction: it.sort ?? 'desc',
      parameter: it.field,
    })) ?? [{ parameter: 'shortCode', direction: 'asc' }],
    ...pagination,
    ...filterItems,
  })

  const totalRecord = useMemo(() => {
    return data?.total
  }, [data])

  const { rowCountState } = useRowCountState(totalRecord ?? 0)

  const handleSwitchSchoolStatusOnClicked = useCallback(
    (school: School, isDisabled: boolean) => {
      if (isDisabled) {
        handleEnableSchool(school)
      } else {
        handleOpenDisableConfirmationDialog(school)
      }
    },
    [handleEnableSchool, handleOpenDisableConfirmationDialog],
  )

  const columns: GridColDef<School>[] = [
    {
      field: 'name',
      headerName: t('schools.fields.chineseName'),
      width: 250,
      renderCell: ({ row }: any) => (
        <TruncatedCellContent
          content={getDisplayName(row?.name, LANGUAGES_ENUM.zhHant)}
        />
      ),
    },
    {
      field: 'englishName',
      headerName: t('schools.fields.englishName'),
      width: 400,
      sortable: false,
      renderCell: ({ row }: any) => (
        <TruncatedCellContent
          content={getDisplayName(row?.name, LANGUAGES_ENUM.en)}
        />
      ),
    },
    {
      field: 'shortCode',
      headerName: t('schools.fields.shortCode'),
      width: 150,
      renderCell: ({ row }: any) => (
        <TruncatedCellContent content={row.shortCode} maxContentWidth={50} />
      ),
    },
    {
      field: 'mainSchoolAdminUser.email',
      headerName: t('schools.fields.schoolAdminEmail'),
      width: 300,
      renderCell: ({ row }: any) => (
        <TruncatedCellContent content={row.mainSchoolAdminUser?.email} />
      ),
    },
    {
      field: 'domain',
      headerName: t('schools.fields.domain'),
      width: 200,
      renderCell: ({ row }: any) => (
        <TruncatedCellContent content={row.domain} maxContentWidth={100} />
      ),
    },
    {
      field: 'isDisabled',
      headerName: t('schools.fields.status'),
      width: 150,
      renderCell: ({ row }: any) => (
        <>
          <FormControlLabel
            control={<Switch color="success" checked={!row.isDisabled} />}
            label={
              !row.isDisabled
                ? t('schools.status.active')
                : t('schools.status.disabled')
            }
            onClick={(event) => {
              event.stopPropagation()
              handleSwitchSchoolStatusOnClicked(row, row.isDisabled)
            }}
          />
        </>
      ),
    },
  ]

  const computedData = useComputed(
    () =>
      (data?.data ?? []).map((it) => ({
        ...it,
        mainSchoolAdminUser: {
          ...it.mainSchoolAdminUser,
        },
      })),
    [data?.data, getDisplayName],
  )

  return (
    <ListPage
      sectionTitleProps={{
        title: t('schools.title.schoolSummary'),
        color: 'brown',
      }}
    >
      <SchoolFilter
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...filterProps}
        onAddSchoolButtonClicked={() => {
          handleOpenAddSchoolDialog('add')
        }}
      />
      <Table
        columns={columns}
        rows={computedData}
        padding={theme.spacings.general[4]}
        paddingTop={0}
        pageSize={PAGINATION_LIMIT}
        loading={isLoading}
        paginationMode="server"
        initialState={{
          sorting: {
            sortModel: [{ field: 'shortCode', sort: 'asc' }],
          },
        }}
        page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
        hideFooterPagination={
          rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
        }
        rowCount={rowCountState}
        sortModel={sort}
        onSortModelChange={setSort}
        onRowClick={({ row }) => {
          handleOpenAddSchoolDialog('edit', row)
        }}
        onPageChange={(page) =>
          setPagination((old) => ({
            ...old,
            offset: page * PAGINATION_LIMIT,
          }))
        }
      />
      {renderAddSchoolDialog}
      {renderDisableConfirmationDialog}
    </ListPage>
  )
})
