import {
  TextFieldProps,
  TextField as InputBase,
  FormControl,
} from '@mui/material'
import {
  ChangeEvent,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import styled from 'styled-components'
import { v4 as uuidV4 } from 'uuid'
import { FormLabel } from '../form-label'
import { FormHelperText } from './form-helper-text'

const DEFAULT_DELAY = 500

const Input = styled(InputBase)<{ $isHaveValue?: boolean }>`
  label + & {
    margin-top: 4px;
  }
  .MuiInputBase-root {
    padding: 0px;
    & .MuiInputBase-input {
      box-sizing: border-box;
      height: 35px;
      border-radius: 4px;
      position: relative;
      background-color: ${({ theme, $isHaveValue }) =>
        theme.palettes.general[$isHaveValue ? 'white' : 'highlighted']};
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.palettes.general.greys[1]};
      padding: 8px;
      &:focus {
        box-shadow: none;
        background-color: ${({ theme }) => theme.palettes.general.white};
      }
      &::placeholder {
        color: ${({ theme }) => theme.palettes.general.greys[2]};
        opacity: 1;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${({ theme }) => theme.palettes.general.greys[2]};
    }
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palettes.general.error};
      }
    }
  }
  & .Mui-error {
    color: ${({ theme }) => theme.palettes.general.error};
  }
`
const StyledFormControl = styled(FormControl)<{
  fullWidth?: boolean
  mb?: number
}>`
  margin-bottom: ${({ mb = 4 }) => mb * 4}px;
`

const IconWrapper = styled.div<{ isHaveValue?: boolean }>`
  height: 100%;
  background-color: ${({ theme, isHaveValue }) =>
    theme.palettes.general[isHaveValue ? 'white' : 'highlighted']};
`

type Props = Omit<TextFieldProps, 'error' | 'value'> & {
  error?: string
  decimalPlace?: number
  value?: any
  mb?: number
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  isReserverForHelperText?: boolean
  onStopInput?: () => void
  stopInputDelay?: number
}

export const TextField = memo<Props>(
  ({
    id,
    value,
    label,
    required,
    fullWidth = true,
    error,
    decimalPlace,
    mb,
    leftIcon,
    rightIcon,
    isReserverForHelperText,
    onStopInput,
    stopInputDelay,
    ...props
  }) => {
    const uuid = useMemo(() => uuidV4(), [])

    useEffect(() => {
      const timer = setTimeout(() => {
        if (onStopInput != null) {
          onStopInput()
        }
      }, stopInputDelay ?? DEFAULT_DELAY)

      return () => clearTimeout(timer)
    }, [onStopInput, stopInputDelay])

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        props.onChange?.(e)
      },
      [props],
    )

    const inputProps = useMemo(() => {
      return {
        ...props.InputProps,
        startAdornment: leftIcon ? (
          <IconWrapper isHaveValue={value && value?.length !== 0}>
            {leftIcon}
          </IconWrapper>
        ) : (
          props.InputProps?.startAdornment
        ),
        endAdornment: rightIcon ? (
          <IconWrapper isHaveValue={value && value?.length !== 0}>
            {rightIcon}
          </IconWrapper>
        ) : (
          props.InputProps?.endAdornment
        ),
      }
    }, [leftIcon, props.InputProps, rightIcon, value])

    return (
      <StyledFormControl fullWidth={fullWidth} variant="standard" mb={mb}>
        {label && (
          <FormLabel label={label} id={id ?? uuid} required={required} />
        )}

        <Input
          id={id ?? uuid}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          InputProps={{
            ...inputProps,
          }}
          fullWidth
          onChange={handleChange}
          value={value}
          label={undefined}
          $isHaveValue={value && value?.length !== 0}
          error={error != null}
        />
        {isReserverForHelperText && <FormHelperText error={error} />}
      </StyledFormControl>
    )
  },
)
