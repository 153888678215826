import { Box, Button, ButtonBase, Grid, Typography } from '@mui/material'
import { LessonPlanConsultRequestResponsePayload } from '@polyu-dip/apis'
import { useComputed } from '@polyu-dip/helpers'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import { useStores } from '../../../stores'

type Props =
  | {
      printable?: undefined
      openAssignExpertDialog: () => void
      openConsultDetailDialog: () => void
      consultRequest: LessonPlanConsultRequestResponsePayload
      isExpert?: boolean
    }
  | {
      printable?: true
      openAssignExpertDialog?: undefined
      openConsultDetailDialog?: undefined
      consultRequest: LessonPlanConsultRequestResponsePayload
      isExpert?: boolean
    }

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding: 12px 20px;
  border: solid 1px ${({ theme }) => theme.palettes.general.blue};
  border-left-width: 8px;
`

const Column = styled(Grid)`
  flex-direction: column;
  display: flex;
`

const Content = styled(Grid)`
  display: flex;
  align-items: center;
  flex: 1;
`

const StyledButton = styled(Button)`
  width: 170px;
`

const TextButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
`

export const AssignExpertHeader = observer<Props>(
  ({
    printable,
    openAssignExpertDialog,
    openConsultDetailDialog,
    consultRequest,
    isExpert,
  }) => {
    const { t } = useTranslation()
    const { usersStore } = useStores()

    const handleAssignExpert = useCallback(() => {
      if (openAssignExpertDialog == null) return
      openAssignExpertDialog()
    }, [openAssignExpertDialog])

    const handleViewConsultDetail = useCallback(() => {
      if (openConsultDetailDialog == null) return
      openConsultDetailDialog()
    }, [openConsultDetailDialog])

    const headerItems = useComputed(
      () => [
        {
          label: t('lessonPlan.detail.assignExpert.createdByUser'),
          value: usersStore.userName(consultRequest.createdByUserId),
          width: 2,
        },
        {
          label: t('lessonPlan.detail.assignExpert.consultDetail'),
          value: (
            <Grid container direction="row">
              <Grid item mr={3}>
                <ButtonBase>
                  <Svg.Letter onClick={handleViewConsultDetail} />
                </ButtonBase>
              </Grid>
              <Grid item>
                <Typography>
                  {consultRequest.status !== 'completed'
                    ? t('lessonPlan.detail.assignExpert.status.notComplete')
                    : t('lessonPlan.detail.assignExpert.status.completed')}
                </Typography>
              </Grid>
            </Grid>
          ),
          width: 1.5,
        },
        {
          label: t('lessonPlan.detail.assignExpert.assignedToUser'),
          value: printable ? (
            usersStore.userName(consultRequest?.assignedExpertUserId ?? '') ??
            '---'
          ) : (
            <>
              {consultRequest.assignedExpertUserId == null ? (
                <StyledButton
                  startIcon={<Svg.Assign fill="white" width={20} height={20} />}
                  color="blue"
                  onClick={handleAssignExpert}
                  fullWidth
                >
                  {t('lessonPlan.detail.assignExpert.actions.assignExpert')}
                </StyledButton>
              ) : (
                <>
                  {isExpert || consultRequest.status === 'completed' ? (
                    <Typography>
                      {usersStore.userName(
                        consultRequest?.assignedExpertUserId ?? '',
                      ) ?? '---'}
                    </Typography>
                  ) : (
                    <TextButton
                      variant="text"
                      onClick={handleAssignExpert}
                      disabled={isExpert}
                    >
                      {usersStore.userName(
                        consultRequest?.assignedExpertUserId ?? '',
                      ) ?? '---'}
                    </TextButton>
                  )}
                </>
              )}
            </>
          ),
          width: 2,
        },
        {
          label: t('lessonPlan.detail.assignExpert.assignedDate'),
          value:
            consultRequest.assignedDateTime == null
              ? '---'
              : dayjs(consultRequest.assignedDateTime).format(
                  t('common.format.dayMonthNameYear'),
                ),
          width: 1.5,
        },
        {
          label: t('lessonPlan.detail.assignExpert.lastModifiedDateTime'),
          value:
            consultRequest.lastModifiedDateTime == null
              ? '---'
              : dayjs(consultRequest.lastModifiedDateTime).format(
                  t('common.format.datetime'),
                ),
          width: 1.5,
        },
      ],
      [
        consultRequest,
        handleAssignExpert,
        handleViewConsultDetail,
        isExpert,
        printable,
        t,
        usersStore,
      ],
    )

    return (
      <Box mt={4}>
        <Container>
          <Grid container justifyContent="space-between">
            {headerItems.map((it) => (
              <Column key={it.label} item xs={printable ? 'auto' : it.width}>
                <Grid item>
                  <Typography mb={3} fontWeight="600" color="primary">
                    {it.label}
                  </Typography>
                </Grid>
                <Content>
                  <Box>{it.value}</Box>
                </Content>
              </Column>
            ))}
          </Grid>
        </Container>
      </Box>
    )
  },
)
