export const myLessonPlan = {
  title: 'My lesson plan',
  publicVersion: 'Public version',

  actions: {
    createLessonPlan: 'Establish a lesson plan',
    editLessonPlan: 'Edit a lesson plan',
  },

  tableFields: {
    class: 'Grade',
    subject: 'Subject',
    teachingTopic: 'Name of lesson plan',
    latestMessage: 'Latest message',
    isAgreePublish: 'Consent to publish',
    consultDisplayStatus: 'Consultation status',
    publishDisplayStatus: 'Submission status',
    status: 'Status',
    submissionDate: 'Submission date',
    releaseOrUnPublishedTime: 'Publication time/ Removal time',
  },

  pagination: {
    next: 'Next page',
  },
}
