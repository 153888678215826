import { Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogAction } from '../dialog'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { NewAccountList, NewUserFormData } from './new-account-list'
import { useOverlay } from '../overlay-provider'
import { useCreateUsers } from '@polyu-dip/queries'
import { useStores } from '../../stores'
import { observer } from 'mobx-react-lite'
import {
  DuplicatedValueErrorCode,
  useApiErrorHandle,
  useFormErrorTranslationTrigger,
} from '../../hooks'
import { PostUsersResponsePayload } from '@polyu-dip/apis'
import _ from 'lodash'
import { useComputed } from '@polyu-dip/helpers'
import { RoleType } from '@polyu-dip/models'

type UserTagProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  userRoleOptions: { label: string; value: string; roleType: string }[]
  schoolOptions?: { label: string; value: string; domain: string }[]
  currentUserSchoolId?: string
}

export const AddUserDialog = observer<UserTagProps>(
  ({
    isOpen,
    setOpen,
    userRoleOptions,
    schoolOptions,
    currentUserSchoolId,
  }) => {
    const { t } = useTranslation()
    const { standardErrorHandler } = useApiErrorHandle()
    const { showSnackbar, showSpinner, hideSpinner } = useOverlay()
    const { userProfileStore } = useStores()

    const isDiTeam = useComputed(() => {
      if (userProfileStore?.userProfile == null) return false
      return userProfileStore.userProfile.role?.label === RoleType.diTeam
    }, [])

    const schoolDomain = useComputed(() => {
      if (
        userProfileStore?.userProfile == null ||
        userProfileStore.userProfile?.school == null
      )
        return
      return userProfileStore.userProfile.school.domain
    }, [userProfileStore.userProfile])

    const [canSubmit, setCanSubmit] = useState(false)
    const [duplicatedEmailList, setDuplicatedEmailList] = useState<string[]>([])

    const [retryPayloads, setRetryPayloads] = useState<
      PostUsersResponsePayload | undefined
    >()

    const schema = useMemo(() => {
      return yup.object({
        newUsers: yup.array(
          yup.object({
            domain: yup.string().nullable(),
            schoolId: yup.string().nullable(),
            email: yup
              .string()
              .email(t('error.invalidEmail'))
              .when(
                'domain',
                (domain: string, stringSchema: yup.StringSchema) => {
                  if (domain == null || domain.length === 0) return stringSchema
                  return stringSchema
                    .required(t('error.required'))
                    .matches(
                      new RegExp(`^\\w+([-+.']\\w+)*${domain}$`),
                      t('error.invalidEmailDomain'),
                    )
                },
              ),
            roleId: yup
              .string()
              .when(
                'email',
                (email: string, stringSchema: yup.StringSchema) => {
                  if (email == null) return stringSchema
                  return stringSchema.required(t('error.required'))
                },
              ),
          }),
        ),
      })
    }, [t])

    const defaultNewUsers = useMemo(() => {
      return Array(5)
        .fill(null)
        .map(() => ({
          email: undefined,
          roleId: undefined,
          schoolId: currentUserSchoolId,
          domain: schoolDomain,
        }))
    }, [currentUserSchoolId, schoolDomain])

    const {
      control,
      formState: { errors },
      getValues,
      setValue,
      trigger,
      reset,
      clearErrors,
      watch,
    } = useForm<NewUserFormData>({
      resolver: yupResolver(schema),
      defaultValues: {
        newUsers: defaultNewUsers,
      },
    })

    useFormErrorTranslationTrigger(errors, trigger)

    const addUserDialogActions = useMemo((): DialogAction<boolean>[] => {
      return [
        {
          text: t('common.close'),
          type: 'outlined',
          value: false,
        },
        {
          text:
            retryPayloads == null
              ? t('users.actions.addUser.confirm')
              : t('users.actions.addUser.retryDialog.retry'),
          type: 'contained',
          value: true,
          color: retryPayloads == null ? 'blue' : 'primary',
          disabled: !canSubmit,
        },
      ]
    }, [canSubmit, retryPayloads, t])

    const handleOnClose = useCallback(() => {
      reset()
      setRetryPayloads(undefined)
      clearErrors()
      setOpen(false)
    }, [clearErrors, reset, setOpen])

    const { mutateAsync: createUsers } = useCreateUsers(useStores, {
      onSuccess: async (res) => {
        const failedItems = res.filter((it) => !it.result)
        if (failedItems.length > 0) {
          setRetryPayloads(failedItems)
        } else {
          setRetryPayloads(undefined)
          showSnackbar({
            message: t('users.actions.addUser.successMessage'),
          })
          handleOnClose()
        }
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('users.actions.addUser.errorMessage'),
        })
        handleOnClose()
      },
    })

    const handleAddUserDialogSelected = useCallback(
      async (value: boolean) => {
        if (!value) {
          handleOnClose()
          return
        }
        try {
          showSpinner()
          const formData = getValues('newUsers')
          const usedRows = formData
            .map((data, index) => ({
              ...data,
              index: index,
            }))
            .filter((data) => data.email?.length > 0 || data?.roleId != null)

          const duplicatedEmails = _.filter(
            _.uniq(
              _.map(usedRows, (item) => {
                if (_.filter(usedRows, { email: item.email }).length > 1) {
                  return item.email
                }
                return ''
              }),
            ),
          )

          if (duplicatedEmails.length > 0) {
            setDuplicatedEmailList(duplicatedEmails)
            await trigger()
            return
          }
          setDuplicatedEmailList([])

          const checkResults = await Promise.all(
            usedRows.map(async (row) => {
              const result = await trigger(`newUsers.${row.index}`)
              return result
            }),
          )

          if (checkResults.some((result) => !result) && !(await trigger()))
            return

          const payloads = formData
            .filter(
              ({ email, roleId }) =>
                retryPayloads?.some((it) => it.email === email) ||
                (retryPayloads == null &&
                  email?.length > 0 &&
                  roleId?.length != null),
            )
            .map((payload) => ({
              ...payload,
              name: payload.email,
              roleId: payload.roleId as string,
            }))
          await createUsers(payloads)
        } finally {
          hideSpinner()
        }
      },
      [
        createUsers,
        getValues,
        handleOnClose,
        hideSpinner,
        retryPayloads,
        showSpinner,
        trigger,
      ],
    )

    const errorMessage = useMemo(() => {
      const errorCode =
        retryPayloads?.length == null
          ? undefined
          : retryPayloads[0]?.errorMessage
      if (errorCode === DuplicatedValueErrorCode.duplicatedEmailInSameRequest)
        return t('error.duplicatedEmailInSameRequest')
      if (errorCode === DuplicatedValueErrorCode.duplicatedEmailInSystem)
        return t('error.duplicatedEmailInSystem')
      return errorCode
    }, [retryPayloads, t])

    useEffect(() => {
      reset({ newUsers: defaultNewUsers })
      const subscription = watch(async () => {
        clearErrors()
        setDuplicatedEmailList([])
        const shouldEnableSubmitButtons = getValues('newUsers')?.some(
          (data) => data?.email?.length > 0,
        )
        setCanSubmit(shouldEnableSubmitButtons)
      })
      return () => subscription.unsubscribe()
    }, [clearErrors, defaultNewUsers, getValues, reset, watch])

    return (
      <>
        <Dialog
          open={isOpen}
          title={
            retryPayloads == null
              ? t('users.actions.addUser.title')
              : t('users.actions.addUser.retryDialog.title')
          }
          actions={addUserDialogActions}
          onSelected={handleAddUserDialogSelected}
          maxWidth="md"
          fullWidth
        >
          <Grid container direction="column" wrap="nowrap">
            {retryPayloads == null ? (
              <>
                <Grid container direction="row">
                  {isDiTeam ? (
                    <Grid item xs={4}>
                      <Grid container direction="row">
                        <Typography>{t('users.fields.school')}</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={isDiTeam ? 4 : 6}>
                    <Grid container direction="row">
                      <Typography>{t('users.fields.email')}</Typography>
                      {isDiTeam ? (
                        <></>
                      ) : (
                        <Typography color="red">
                          {t('users.helpText.email')}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={isDiTeam ? 4 : 6}>
                    <Typography>{t('users.fields.role')}</Typography>
                  </Grid>
                </Grid>
                <NewAccountList
                  getValues={getValues}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  userRoleOptions={userRoleOptions}
                  schoolOptions={schoolOptions}
                  duplicatedEmailList={duplicatedEmailList}
                  currentUserSchoolId={currentUserSchoolId}
                  domain={schoolDomain}
                  watch={watch}
                />
              </>
            ) : (
              <>
                <Grid item pl={4} mb={7}>
                  <Typography color="red">({errorMessage})</Typography>
                </Grid>
                {retryPayloads.map((retryPayload) => (
                  <Grid item pl={2} key={retryPayload.email} mt={4}>
                    <Typography color="blue">{retryPayload.email}</Typography>
                  </Grid>
                ))}
                <Grid item mt={7}>
                  <Typography>
                    {t('users.actions.addUser.retryDialog.content')}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Dialog>
      </>
    )
  },
)
