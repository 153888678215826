import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { NameModel } from './languages-json'

export const DiStrategyClassModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    label: types.string,
    isDisabled: types.boolean,
    order: types.number,
  })
  .extend(withUpdate)
  .named('DiStrategyClassModel')

export type DiStrategyClass = Instance<typeof DiStrategyClassModel>
