import {
  Grid,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps,
  Typography,
} from '@mui/material'
import { useTheme } from '@polyu-dip/theme'
import { memo } from 'react'
import styled from 'styled-components'
import { Svg } from '../../assets'

type OwnProps = {
  $isActive?: boolean
}

type Props = {
  label: string
} & ListItemButtonProps &
  OwnProps

const StyledListItemButton = styled(MuiListItemButton)<OwnProps>`
  margin-bottom: 4px;
  font-weight: ${({ $isActive }) => ($isActive ? 600 : 400)};
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primary : theme.palettes.general.lightBlue};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.palettes.general.white : theme.palettes.general.black};
  border-radius: 10px;
  &:hover {
    font-weight: ${({ $isActive }) => ($isActive ? 600 : 400)};
    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary : theme.palettes.general.lightBlue};
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.palettes.general.white : theme.palettes.general.black};
  }
`

const StyledTypography = styled(Typography)<OwnProps>`
  font-weight: ${({ $isActive }) => ($isActive ? 600 : 400)};
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.palettes.general.white : theme.palettes.general.black};
`

export const ListItemButton = memo<Props>(({ label, ...props }) => {
  const theme = useTheme()
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledListItemButton {...props}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <StyledTypography $isActive={props.$isActive}>
            {label}
          </StyledTypography>
        </Grid>
        <Grid item>
          {props.$isActive ? (
            <Svg.ChevronUp fill={theme.palettes.general.white} />
          ) : (
            <Svg.ArrowDown />
          )}
        </Grid>
      </Grid>
    </StyledListItemButton>
  )
})
