import { ApiCore } from '@polyu-dip/api-core'
import { PostSwitchMasterDataOrderPayload } from './master-data-api.types'

const PATH = '/master-data'

export function postMasterDataSwitchStatus(
  type: string,
  id: string,
  rowVersion: string,
) {
  return ApiCore.primary.post(`${PATH}/${type}/${id}/switch-status`, {
    body: {
      rowVersion: rowVersion,
    },
  })
}

export function postMasterDataSwitchOrder(
  type: string,
  payload: PostSwitchMasterDataOrderPayload,
) {
  return ApiCore.primary.post(`${PATH}/${type}/switch-order`, {
    body: {
      ...payload,
    },
  })
}
