import { Box, Grid } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { LessonPlanDiStrategyResourceType } from '@polyu-dip/models'
import {
  useDiStrategies,
  useDiStrategyClasses,
  useTags,
  useTagTypes,
} from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FullscreenLoading, ListPage } from '../../components'
import { useStores } from '../../stores'
import { DiStrategyDetail } from './di-strategy-detail'
import { DiStrategySelectionList } from './di-strategy-selection-list'

type Props = {}

const Section = styled(Box)`
  background-color: ${({ theme }) => theme.palettes.general.white};
  border-radius: 30px;
  height: 828px;
  padding: 28px;
`

export const DiStrategies = observer<Props>(() => {
  const { t } = useTranslation()
  const { masterDataStore } = useStores()

  const { isLoading: isDiStrategyClassesLoading } =
    useDiStrategyClasses(useStores)
  const { isLoading: isTagsLoading } = useTags(useStores, {
    expand: ['TagGroup'],
  })
  const { isLoading: isTagTypesLoading } = useTagTypes(useStores)
  const { isLoading: isDiStrategiesLoading } = useDiStrategies(useStores)

  const diStrategyClassesData = useComputed(() => {
    return masterDataStore.activeDiStrategyClasses
  }, [])

  const diStrategiesData = useComputed(() => {
    return masterDataStore.activeDiStrategies
  }, [])

  const defaultSelectedDiStrategyClass = useMemo(() => {
    if (isDiStrategyClassesLoading || diStrategyClassesData == null)
      return undefined
    return diStrategyClassesData?.[0]
  }, [diStrategyClassesData, isDiStrategyClassesLoading])

  const defaultSelectedDiStrategy = useMemo(() => {
    if (
      isDiStrategiesLoading ||
      diStrategiesData == null ||
      defaultSelectedDiStrategyClass == null
    )
      return
    return diStrategiesData.filter(
      (it) => it.diStrategyClassId === defaultSelectedDiStrategyClass.id,
    )?.[0]
  }, [defaultSelectedDiStrategyClass, diStrategiesData, isDiStrategiesLoading])

  const [selectedDiStrategyId, setSelectedDiStrategyId] = useState<string>()
  const [selectedResourceType, setSelectedResourceType] =
    useState<LessonPlanDiStrategyResourceType>()

  if (
    isDiStrategyClassesLoading ||
    isDiStrategiesLoading ||
    isTagsLoading ||
    isTagTypesLoading
  )
    return <FullscreenLoading />

  return (
    <ListPage
      sectionTitleProps={{
        title: t('diStrategy.title'),
        color: 'lightBlue',
      }}
    >
      <Section>
        <Grid container sx={{ height: '80vh' }}>
          <Grid item xs={4}>
            <DiStrategySelectionList
              selectedDiStrategyId={selectedDiStrategyId}
              setSelectedDiStrategyId={setSelectedDiStrategyId}
              defaultSelectedDiStrategyClassId={
                defaultSelectedDiStrategyClass?.id
              }
              defaultSelectedDiStrategyId={defaultSelectedDiStrategy?.id}
              setSelectedResourceType={setSelectedResourceType}
            />
          </Grid>
          <Grid item xs={8}>
            {defaultSelectedDiStrategy?.id == null &&
            selectedDiStrategyId == null ? (
              <></>
            ) : (
              <DiStrategyDetail
                selectedDiStrategyId={selectedDiStrategyId}
                defaultSelectedDiStrategyId={
                  defaultSelectedDiStrategy?.id ?? ''
                }
                selectedResourceType={selectedResourceType}
                setSelectedResourceType={setSelectedResourceType}
              />
            )}
          </Grid>
        </Grid>
      </Section>
    </ListPage>
  )
})
