import { ThemeProvider as MUIThemeProvider } from '@mui/material'
import { LocalizationProvider as DateLocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ThemeProvider } from '@polyu-dip/theme'
import { memo, PropsWithChildren, useEffect, useState } from 'react'
import { FullscreenLoading, OverlayProvider } from '../components'
import { initI18n } from '../services'
import { colors } from './colors'
import { muiTheme } from './mui-theme'
import * as languagePacks from '@polyu-dip/language-packs'

type Props = PropsWithChildren<{}>

export const UiProvider = memo<Props>(({ children }) => {
  const [didInit, setInit] = useState(false)

  useEffect(() => {
    ;(async () => {
      await initI18n({
        'zh-Hant': languagePacks.zhHant,
        en: languagePacks.en,
      })
      setInit(true)
    })()
  }, [])

  if (!didInit) return <FullscreenLoading />

  return (
    <DateLocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIThemeProvider theme={muiTheme}>
        <ThemeProvider theme={{ colors }}>
          <OverlayProvider>{children}</OverlayProvider>
        </ThemeProvider>
      </MUIThemeProvider>
    </DateLocalizationProvider>
  )
})
