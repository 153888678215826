import { Button, ButtonProps, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

export type ButtonGroupItemProps = {
  width?: number
} & ButtonProps

type ButtonGroupProps = {
  gap?: number
  direction?: 'row' | 'column'
  items: ButtonGroupItemProps[]
}

export const ButtonGroup = observer<ButtonGroupProps>(
  ({ gap, direction, items }) => {
    const totalWidth = useMemo(() => {
      if (direction == 'column') {
        return items.reduce((x, y) => ((x.width ?? 0) > (y.width ?? 0) ? x : y))
          .width
      }
      return (
        items.reduce((x, y) => x + (y.width ?? 0), 0) +
        (gap ?? 0) * items.length * items.length
      )
    }, [direction, gap, items])

    return (
      <Grid container gap={gap} width={totalWidth}>
        {items.map((it) => (
          <Grid width={it.width ?? 200} key={it.title}>
            <Button
              variant={it.variant}
              color={it.color}
              fullWidth={it.fullWidth}
              onClick={it.onClick}
            >
              {it.title}
            </Button>
          </Grid>
        ))}
      </Grid>
    )
  },
)
