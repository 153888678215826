import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  PutSchoolsPayload,
  PostSchoolsPayload,
  SchoolExpandParameter,
  SchoolResponsePayload,
  SchoolsQueryParameters,
  SchoolsResponsePayload,
  SchoolSubmissionRecordsQueryParameters,
  SchoolSubmissionRecordsResponsePayload,
  PostSchoolDisablePayload,
  PostSchoolEnablePayload,
} from './api-schools.type'

const PATH = '/schools'

export function getSchools(param?: SchoolsQueryParameters) {
  return ApiCore.primary.get<SchoolsResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: param?.expand == null ? null : param.expand.join(','),
    },
  })
}

export function getSchoolById(id: string, param?: SchoolExpandParameter) {
  return ApiCore.primary.get<SchoolResponsePayload>(`${PATH}/${id}`, {
    param: {
      ...param,
      expand: param?.expand == null ? null : param.expand.join(','),
    },
  })
}

export function postSchool(payload: PostSchoolsPayload) {
  return ApiCore.primary.post<SchoolResponsePayload>(PATH, {
    body: payload,
  })
}

export function putSchool(id: string, payload: PutSchoolsPayload) {
  return ApiCore.primary.put<SchoolResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}

export function postSchoolDisable(
  id: string,
  payload: PostSchoolDisablePayload,
) {
  return ApiCore.primary.post<SchoolResponsePayload>(`${PATH}/${id}/disable`, {
    body: payload,
  })
}

export function postSchoolEnable(id: string, payload: PostSchoolEnablePayload) {
  return ApiCore.primary.post<SchoolResponsePayload>(`${PATH}/${id}/enable`, {
    body: payload,
  })
}

export function getSchoolSubmissionRecords(
  param?: SchoolSubmissionRecordsQueryParameters,
) {
  return ApiCore.primary.get<SchoolSubmissionRecordsResponsePayload>(
    `${PATH}/submission-records`,
    {
      param: {
        ...param,
        sort: convertSortParameters(param),
      },
    },
  )
}
