import { Box, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useLessonPlanDetail } from '../../use-lesson-plan-detail-context'

const Text = styled(Typography)`
  overflow-wrap: break-word;
  white-space: pre-wrap;
`

export const DesignExplanationTab = observer(() => {
  const { t } = useTranslation()
  const { lessonPlan } = useLessonPlanDetail()

  const displayDesignExplanation = useMemo(
    () =>
      lessonPlan.diStrategyTeachingConceptExcerpt?.length === 0 ||
      lessonPlan.diStrategyTeachingConceptExcerpt == null
        ? '---'
        : lessonPlan.diStrategyTeachingConceptExcerpt,
    [lessonPlan.diStrategyTeachingConceptExcerpt],
  )

  return (
    <Box>
      <Grid container justifyContent="flex-end" columnSpacing={3}>
        <Grid item mr="auto">
          <Typography fontWeight={600}>
            {t('lessonPlan.detail.general.menuTab.designExplanation')}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Text>{displayDesignExplanation}</Text>
      </Box>
    </Box>
  )
})
