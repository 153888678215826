import { Box } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useGetContactUsDetails } from '@polyu-dip/queries'
import { useComputed } from '@polyu-dip/helpers'
import { FullscreenLoading, PageTitle, SectionTitle } from '../../components'
import { useApiErrorHandle } from '../../hooks'
import { Img } from '../../assets'

const Container = styled.div``

const Section = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palettes.general.white,
  borderRadius: 30,
  marginBottom: 16,
}))

const Content = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palettes.general.greys[0]};
`

const StyledTitle = styled(PageTitle)`
  margin-top: 0px;
  color: ${({ theme }) => theme.palettes.general.greys[0]};
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 24px;
`

export const ContactUs = memo(() => {
  const { t } = useTranslation()
  const { standardErrorHandler } = useApiErrorHandle()
  const { i18n } = useTranslation()

  const { data, isLoading } = useGetContactUsDetails({
    onError: (error) => {
      standardErrorHandler(error, {
        defaultTitle: t('error.fetchEntityFailure', {
          entityName: t('contactUs.contactInformation'),
        }),
      })
    },
  })

  const computedData = useComputed(() => {
    if (i18n.language == 'en') {
      return {
        projectMembers: data?.['project-members_eng'],
        contactDetails: data?.['contact-details_eng'],
        projectBackgrounds: data?.['project-background_eng'],
      }
    }
    return {
      projectMembers: data?.['project-members'],
      contactDetails: data?.['contact-details'],
      projectBackgrounds: data?.['project-background'],
    }
  }, [data, i18n.language])

  if (isLoading) {
    return <FullscreenLoading />
  }

  return (
    <Container>
      <SectionTitle title={t('sidebar.contactUs')} color="brown" />
      <Image src={Img.ProjectBanner} alt="project-banner" />
      {data == null ? (
        <></>
      ) : (
        <>
          <Section p={7}>
            <Box>
              <Content>{computedData.projectBackgrounds}</Content>
            </Box>
          </Section>
          <Section p={7}>
            <StyledTitle>{t('contactUs.contactInformation')}</StyledTitle>
            <Box mb={6}>
              <Content>{computedData.projectMembers}</Content>
            </Box>
            <Box>
              <Content>{computedData.contactDetails}</Content>
            </Box>
          </Section>
        </>
      )}
    </Container>
  )
})
