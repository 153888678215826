import { Box, Grid, Typography } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FormLabel, TextField } from '../../../../../components'
import {
  GeneralInformationFormData,
  StudentGroup,
} from '../../../lesson-plan-form-provider'
import { TeachingFlowGroup } from './teaching-flow-group'

const Container = styled.div``

const Section = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palettes.general.white,
  borderRadius: 30,
  padding: 28,
  margin: '20px 0px',
}))

const StyledTypography = styled(Typography)`
  word-break: break-word;
`

type TeachingProcessExtraProps = {
  control: Control<GeneralInformationFormData, any>
  groups: StudentGroup[]
  isMultiGroup?: boolean
}

export const TeachingProcessExtra = ({
  control,
  isMultiGroup,
  groups,
}: TeachingProcessExtraProps) => {
  const { t } = useTranslation()

  if (isMultiGroup === undefined) return null
  return (
    <Container>
      {isMultiGroup &&
        groups.map((it, index) => (
          <Section key={index}>
            <Grid container>
              <Grid item sm={2}>
                <FormLabel
                  label={t(
                    'lessonPlan.createForm.generalInformation.fields.basedOnStudentTraitGroupsTeachingType.characteristic.label',
                  )}
                  required
                />
              </Grid>
              <Grid item sm={10}>
                <StyledTypography fontWeight={600} color="primary">
                  {it.groupCharacteristic}
                </StyledTypography>
              </Grid>
            </Grid>
            <TeachingFlowGroup
              control={control}
              fieldArrayName={`lessonPlanStudentGroups[${index}].lessonPlanTeachingSteps`}
              studentGroupIndex={index}
            />
          </Section>
        ))}
      <Section>
        <Controller
          control={control}
          name="followUp"
          render={({ field }) => (
            <TextField
              label={t(
                'lessonPlan.createForm.generalInformation.fields.followUpItems.label',
              )}
              placeholder={t(
                'lessonPlan.createForm.generalInformation.fields.followUpItems.placeholder',
              )}
              multiline
              rows={3}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
            />
          )}
        />
      </Section>
      <Section>
        <Controller
          control={control}
          name="selfLearningResource"
          render={({ field }) => (
            <TextField
              label={t(
                'lessonPlan.createForm.generalInformation.fields.learningResource.label',
              )}
              placeholder={t(
                'lessonPlan.createForm.generalInformation.fields.learningResource.placeholder',
              )}
              multiline
              rows={3}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
            />
          )}
        />
      </Section>
    </Container>
  )
}
