import { InputLabel } from '@mui/material'
import { memo, ReactNode } from 'react'
import styled from 'styled-components'

const Label = styled(InputLabel)<{
  fontWeight?: number | string
}>`
  font-size: 16px;
  line-height: 19px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 600};
  color: ${({ theme }) => theme.palettes.general.greys[0]};
  transform: none;
  position: static;
  text-overflow: ellipsis;
  white-space: pre-wrap;
`

const RequireLabel = styled.span`
  color: ${({ theme }) => theme.colors.accent};
`

type Props = {
  label: ReactNode
  id?: string
  required?: boolean
  fontWeight?: number | string
}

export const FormLabel = memo(({ label, id, required, fontWeight }: Props) => (
  <Label shrink htmlFor={id} fontWeight={fontWeight}>
    {label} {required && <RequireLabel>*</RequireLabel>}
  </Label>
))
