import {
  Control,
  Controller,
  FieldErrorsImpl,
  useFieldArray,
} from 'react-hook-form'
import { Box, Grid } from '@mui/material'
import { Svg } from '../../../../assets'
import { Dropdown, TextField } from '../../../../components'
import { memo } from 'react'
import { UploadDocumentFormData } from '../../lesson-plan-form-provider'
import { useTranslation } from 'react-i18next'

export type AttachmentListProps = {
  targetField: 'fileResources' | 'linkResources'
  control?: Control<UploadDocumentFormData, any> | undefined
  resourceTypeOptions: {
    label: any
    value: string
  }[]
  errors?: Partial<FieldErrorsImpl<UploadDocumentFormData>>
}

export const AttachmentList = memo<AttachmentListProps>(
  ({ targetField, control, resourceTypeOptions, errors }) => {
    const { t } = useTranslation()
    const { fields: attachmentFields, remove: removeAttachmentField } =
      useFieldArray({
        control,
        name: targetField,
      })

    if (!attachmentFields.length) return null

    return (
      <Box mt={4}>
        {attachmentFields.map((attachmentField, index) => (
          <Grid key={attachmentField.id} container columnSpacing={3}>
            <Grid item flexShrink={0} mt={1}>
              {targetField === 'fileResources' ? (
                <Svg.Uploaded />
              ) : (
                <Svg.Link />
              )}
            </Grid>
            <Grid item flexGrow={1}>
              <Controller
                name={`${targetField}.${index}.attachmentName`}
                control={control}
                render={({ field }) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    disabled={targetField === 'fileResources'}
                    placeholder={t(
                      'lessonPlan.createForm.uploadDocuments.title',
                    )}
                    error={
                      errors?.[targetField]?.[index]?.attachmentName?.message
                    }
                    isReserverForHelperText
                  />
                )}
              />
            </Grid>
            {targetField === 'linkResources' ? (
              <Grid item xs={4}>
                <Controller
                  name={`${targetField}.${index}.file.url`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      placeholder={t(
                        'lessonPlan.createForm.uploadDocuments.url',
                      )}
                      error={errors?.[targetField]?.[index]?.file?.url?.message}
                      isReserverForHelperText
                    />
                  )}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item flexShrink={0} xs={2}>
              <Controller
                name={`${targetField}.${index}.resourceTypeId`}
                control={control}
                render={({ field }) => (
                  <Dropdown
                    options={resourceTypeOptions}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    placeholder={t(
                      'lessonPlan.createForm.uploadDocuments.resourceType',
                    )}
                    error={
                      errors?.[targetField]?.[index]?.resourceTypeId?.message
                    }
                    isReserverForHelperText
                  />
                )}
              />
            </Grid>
            <Grid item flexShrink={0} mt={2}>
              <Svg.Close
                cursor="pointer"
                onClick={() => removeAttachmentField(index)}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
    )
  },
)
