import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  PostTagTypePayload,
  PutTagTypePayload,
  TagTypeResponsePayload,
  TagTypesQueryParameters,
  TagTypesResponsePayload,
} from './tag-types-api.type'

const PATH = '/tag-types'

export function getTagTypes(param?: TagTypesQueryParameters) {
  return ApiCore.primary.get<TagTypesResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}

export function postTagTypeBulk(payloads: PostTagTypePayload[]) {
  return ApiCore.primary.post<TagTypeResponsePayload[]>(`${PATH}/bulk`, {
    body: payloads,
  })
}

export function putTagTypeById(id: string, payload: PutTagTypePayload) {
  return ApiCore.primary.put<TagTypeResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}
