import { Button, Grid, Dialog, Box, Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useComputed } from '@polyu-dip/helpers'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,

} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../../assets'
import { FormLabel, Tag, TextField, TextFieldWhite, useOverlay } from '../../../../components'

import { useStores } from '../../../../stores'
import { RequestConsultingButton, SectionBox } from '../../components'
// eslint-disable-next-line import/no-internal-modules
//import { yupResolver } from '@hookform/resolvers/yup'
//import * as yup from 'yup'
import { useLessonPlanFormContext } from '../../use-lesson-plan-form-context'
import {
  clearLessonPlansQueryCaches,
  useCreateLessonPlanSharingsById,
  useDeleteLessonPlanSharings,
  useMySchool,
} from '@polyu-dip/queries'
import { LessonPlanSharing } from '@polyu-dip/models'
import { useQueryClient } from '@tanstack/react-query'
import {
  useApiErrorHandle,
  useFormErrorTranslationTrigger,
  useLessonPlanPermission,
  useMasterData
} from '../../../../hooks'
import { useDisplayName } from '../../../../services'
import {
  SharedToUsersFormData,
  UserSelectionDropdown,
} from './user-selection-dropdown'
import { GeneralInformationFormData } from '../../lesson-plan-form-provider'

const FormRoot = styled.form``

const StyledTag = styled(Tag)`
  margin-bottom: 10px;
  .MuiChip-deleteIcon {
    margin: 0px;
  }
`

const StyledCloseIcon = styled.div`
  display: flex;
`

type Props = {
  isExample?: boolean
}

export const ShareAndRequestPublish = observer<Props>(({ isExample }) => {
  const { t } = useTranslation()
  const { standardErrorHandler } = useApiErrorHandle()
  const { getDisplayName } = useDisplayName()
  const { systemParameters } = useMasterData()

  const isRequestConsultingEnabledBySystem = useMemo(
    () =>
      systemParameters.some(
        (it) => it.name === 'expert-switch' && it.value_Boolean,
      ),
    [systemParameters],
  )
  /*const schema: yup.SchemaOf<SharedToUsersFormData> = useMemo(
    () =>
      yup.object({
        users: yup
          .array(
            yup
              .object({
                value: yup.string().required(t('error.required')),
                label: yup.string().required(),
              })
              .required(t('error.required')),
          )
          .required(),
        textusers: yup
          .array(
            yup
              .object({
                value: yup.string().required(t('error.required')),
              })
              .required(t('error.required')),
          )
          .required(),
      }),
    [t],
  )*/
  const theme = useTheme()
  const { userProfileStore } = useStores()

  const { showSnackbar, showSpinner, hideSpinner } = useOverlay()

  const { lessonPlanPermissionHandler } = useLessonPlanPermission()
  const { lessonPlanFormData } = useLessonPlanFormContext()

  const { data: currentSchool, isLoading: isCurrentSchoolLoading } =
    useMySchool(useStores, {
      expand: ['Users'],
    })

  const sharedUsers: LessonPlanSharing[] = useComputed(() => {

    return lessonPlanFormData?.lessonPlanSharings
      ?.map((lessonPlanSharing: LessonPlanSharing) => ({
        ...lessonPlanSharing,
        sharedToUser: {
          ...lessonPlanSharing.sharedToUser,
          school: { ...lessonPlanSharing.sharedToUser?.school },
        },
        createdTimeDayJs: lessonPlanSharing.createdTimeDayJs,
      }))
      .slice()
      .sort((a: LessonPlanSharing, b: LessonPlanSharing) => {
        return a.createdTimeDayJs.isBefore(b.createdTimeDayJs) ? -1 : 1
      })
  }, [lessonPlanFormData])

  const filteredUserIds = useMemo(() => {

    return [
      userProfileStore.userProfile?.id ?? '',
      ...sharedUsers.map((it) => it.sharedToUserId),
    ]
  }, [sharedUsers, userProfileStore.userProfile?.id])

  const sharedToUserOptions = useComputed(
    () =>

      isCurrentSchoolLoading
        ? []
        : currentSchool?.schoolUsers
          ?.filter(
            (user: any) =>
              !filteredUserIds.includes(user.id) && !user.isAccountDisabled,
          )
          .map((user: any) => ({
            label: t('users.userDropdownLabel', {
              name: user?.name,
              school: getDisplayName(user?.school?.name) ?? '---',
              email: user?.email,
            }),
            value: user.id,
          })) ?? [],
    [
      isCurrentSchoolLoading,
      currentSchool?.schoolUsers,
      filteredUserIds,
      t,
      getDisplayName,
    ],
  )


  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    trigger
  } = useForm<SharedToUsersFormData>({
    //resolver: yupResolver(schema),
    defaultValues: {
      users: [
        {
          label: undefined,
          value: undefined,
        },
      ],
      textusers: [
        {
          value: undefined,
        },
      ],

    },
  })

  const { control: lpFormControl } =
    useFormContext<GeneralInformationFormData>()

  useFormErrorTranslationTrigger(errors, trigger)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  })
  const { fields: textFields, append: appendText, remove: removeText } = useFieldArray({
    control,
    name: 'textusers'
  })
  const queryClient = useQueryClient()

  const { mutateAsync: deleteLessonPlanSharings } = useDeleteLessonPlanSharings(
    useStores,
    {
      onSuccess() {
        showSnackbar({
          message: t('lessonPlan.createForm.updateSharingsSuccess'),
        })
        clearLessonPlansQueryCaches(queryClient, lessonPlanFormData.id)
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t(
            'lessonPlan.errorMessage.deleteLessonPlanSharings.title',
          ),
        })
      },
    },
  )

  const handleDelete = useCallback(
    async (id: string) => {
      showSpinner()
      try {
        await deleteLessonPlanSharings(id)
      } finally {
        hideSpinner()
      }
    },
    [deleteLessonPlanSharings, hideSpinner, showSpinner],
  )
  
  const { mutateAsync: createLessonPlanSharings } =
    useCreateLessonPlanSharingsById(useStores, lessonPlanFormData.id, {
      onSuccess() {
        showSnackbar({
          message: t('lessonPlan.createForm.sharingsSuccess'),
        })
        reset({
          users: [
            {
              label: undefined,
              value: undefined,
            },
          ],
          textusers: [
            {
              value: '',
            },
          ],
        })
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t(
            'lessonPlan.errorMessage.createLessonPlanSharings.title',
          ),
        })
      },
    })
  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim())
  }
  // 在组件中定义对话框的状态
  const [visible, setVisible] = useState(false)
  const handleOnClose = useCallback(() => {
    setVisible(false)
  }, [])

  const onSubmit: SubmitHandler<SharedToUsersFormData> = useCallback(

    async (data) => {
      if (!data.users.some((it) => it.value != null && it.value !== '') &&
        !data.textusers.some((it) => it.value!= null && it.value!== '')) {
        showSnackbar({ message: t('error.sharederror'), })
        return
      }


      if (data.users.some((it) => it.value != null && it.value !== '') &&
        data.textusers.every((it) => it.value == null || it.value === '')) {
        showSpinner()
        try {
          const sharingData = data.users.map((user) => ({
            sharedToUserId: user.value,
            sharedToOutUser: '',
          }))

          await createLessonPlanSharings(sharingData)

        } finally {
          hideSpinner()
        }
      }
      if (data.textusers.some((it) => it.value != null && it.value !== '' && !isValidEmail(it.value))) {
        showSnackbar({ message: t('error.invalidEmail'), })
        return
      }
      if (data.textusers.some((it) => it.value != null && it.value !== '' && isValidEmail(it.value))) {
        setVisible(true)
      }
    },
    [createLessonPlanSharings, hideSpinner, showSpinner, showSnackbar, t],
  )
  const dialogConfirm: SubmitHandler<SharedToUsersFormData> = useCallback(async (data) => {
  
    setVisible(false)
    showSpinner()
    try {
      // 确定哪个数组更长  
      const maxLength = Math.max(data.users.length, data.textusers.length)

      // 使用较长的数组来决定映射的逻辑  
      let sharingData
      if (data.users.length >= maxLength) {
        // 如果data.users更长或长度相同，使用它进行映射  
        sharingData = data.users.map((user, index) => ({
          sharedToUserId: user.value || '00000000-0000-0000-0000-000000000000',
          sharedToOutUser: index < data.textusers.length ? data.textusers[index]?.value.trim() || '' : '', // 处理data.textusers可能较短的情况  
        }))
        
      } else {
        // 如果data.textusers更长，你可能需要不同的逻辑来处理额外的textusers  
        // 但为了简单起见，我们只映射到现有的users，并为额外的textusers提供默认值  
        sharingData = data.textusers.map((textUser, index) => ({
          sharedToUserId: index < data.users.length ? data.users[index].value || '00000000-0000-0000-0000-000000000000' : '00000000-0000-0000-0000-000000000000', // 处理data.users可能较短的情况  
          sharedToOutUser: textUser.value.trim() || '',
        }))
      }

      await createLessonPlanSharings(sharingData)
    } finally {
      hideSpinner()
    }
  }, [createLessonPlanSharings, hideSpinner, showSpinner])
  const filterOptions = useCallback(
    (options: { value: string; label: string }[]) => {
      return options.filter((option) => {
        return !watch('users').some(
          (selected) => selected.value === option.value,
        )
      })
    },
    [watch],
  )

  const { canEditLessonPlan } = lessonPlanPermissionHandler(lessonPlanFormData)

  if (isExample) {
    return (
      <FormRoot onSubmit={handleSubmit(onSubmit)}>
        <SectionBox>
          <Controller
            control={lpFormControl}
            render={({ field }) => (
              <TextField
                label={t(
                  'lessonPlan.createForm.shareAndRequestPublish.schoolBackgroundInformation',
                )}
                placeholder={t(
                  'lessonPlan.createForm.shareAndRequestPublish.schoolBackgroundInformation',
                )}
                multiline
                rows={6}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                inputProps={{ min: 25, step: 5 }}
              />
            )}
            name="schoolBackgroundInformation"
          />
        </SectionBox>
        <SectionBox>
          <Controller
            control={lpFormControl}
            render={({ field }) => (
              <TextField
                label={t(
                  'lessonPlan.createForm.shareAndRequestPublish.diStrategyTeachingConceptExcerpt',
                )}
                placeholder={t(
                  'lessonPlan.createForm.shareAndRequestPublish.diStrategyTeachingConceptExcerpt',
                )}
                multiline
                rows={6}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                inputProps={{ min: 25, step: 5 }}
              />
            )}
            name="diStrategyTeachingConceptExcerpt"
          />
        </SectionBox>
      </FormRoot>
    )
  }

  return (
    <FormRoot onSubmit={handleSubmit(onSubmit)}>
      <SectionBox>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb={6}
        >
          <Grid item>
            <FormLabel
              label={t('lessonPlan.createForm.shareAndRequestPublish.shareTo')}
            />
          </Grid>
          {isRequestConsultingEnabledBySystem && (
            <Grid item>
              <RequestConsultingButton
                lessonPlanId={lessonPlanFormData.id}
                latestLessonPlanConsult={lessonPlanFormData.latestLessonPlanConsult}
                canConsult={canEditLessonPlan}
              />
            </Grid>
          )}
        </Grid>

        {sharedUsers
          .filter(sharedUser => sharedUser.sharedToUser?.roleId.toUpperCase() !== 'C11AC37F-547D-473F-BE7C-F19486044109')
          .map((sharedUser) => (
            <StyledTag
              colorCustomize={theme.colors.tertiary}
              label={t('users.userDropdownLabel', {
                name: sharedUser.sharedToUser?.name,
                school:
                  getDisplayName(sharedUser.sharedToUser?.school?.name) ?? '---',
                email: sharedUser.sharedToUser?.email,
              })}
              key={sharedUser.id}
              onDelete={() => handleDelete(sharedUser.id)}
              deleteIcon={
                <StyledCloseIcon>
                  <Svg.Close
                    width={20}
                    height={20}
                    cursor="pointer"
                    fill={theme.palettes.general.black}
                  />
                </StyledCloseIcon>
              }
            />
          ))}

        {fields.map((item, index) => (
          <UserSelectionDropdown
            key={index}
            item={item}
            index={index}
            control={control}
            errors={errors}
            remove={remove}
            isLoading={isCurrentSchoolLoading}
            sharedToUserOptions={sharedToUserOptions}
            filterOptions={filterOptions}
            filteredIds={filteredUserIds}
          />
        ))}
        <Grid container justifyContent="flex-end" mb={6}>
          <Svg.Plus
            cursor="pointer"
            onClick={() =>
              append({
                value: '',
                label: '',
              })
            }
            fill={theme.palettes.general.blue}
          />
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb={6}
        >
          <Grid item xs={12}>
            <FormLabel
              label={t('lessonPlan.createForm.shareAndRequestPublish.shareOutsideTo')}
            />
          </Grid>
        </Grid>
        {sharedUsers
          .filter(sharedUser => sharedUser.sharedToUser?.roleId.toUpperCase() == 'C11AC37F-547D-473F-BE7C-F19486044109')
          .map((sharedUser) => (
            <StyledTag
              colorCustomize={theme.colors.tertiary}
              label={t('users.userDropdownLabel', {
                name: sharedUser.sharedToUser?.name,
                school:
                  getDisplayName(sharedUser.sharedToUser?.school?.name) ?? '---',
                email: sharedUser.sharedToUser?.email,
              })}
              key={sharedUser.id}
              onDelete={() => handleDelete(sharedUser.id)}
              deleteIcon={
                <StyledCloseIcon>
                  <Svg.Close
                    width={20}
                    height={20}
                    cursor="pointer"
                    fill={theme.palettes.general.black}
                  />
                </StyledCloseIcon>
              }
            />
          ))}
        {textFields.map((itemout, indexout) => (
          <Grid key={indexout} container spacing={2} alignItems="center">
            <Grid item flexGrow={1}>
              <Controller
                
                name={`textusers.${indexout}.value`}
                control={control}
                render={({ field }) => (
                  <TextFieldWhite
                    fullWidth
                    placeholder={t('lessonPlan.createForm.shareAndRequestPublish.shareInputPlaceholder')}
                    inputProps={{ ...field }}
                    
                  />
                )}
              />
            </Grid>
            <Grid item flexShrink={0} mt={2}>
              <Svg.Close
                cursor="pointer"
                width={20}
                height={20}
                onClick={() => removeText(indexout)}
              />
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent="flex-end" mb={6}>
          <Svg.Plus
            cursor="pointer"
            onClick={() => appendText({
              value: '',

            })}
            fill={theme.palettes.general.blue}
          />
        </Grid>

        <Grid container justifyContent="flex-end">
          <Grid item width={132}>
            <Button
              fullWidth
              color="blue"
              type="submit"
              disabled={
                lessonPlanFormData?.id == null ||
                lessonPlanFormData.id?.length === 0
              }

            >
              {t('lessonPlan.actions.sharedWithOthers')}
            </Button>
          </Grid>
        </Grid>
      </SectionBox>
      <Dialog
        maxWidth={false} open={visible} onClose={handleOnClose}
      >
        <Box width={475} p={10}>
          <Typography fontSize={22} fontWeight={600} mb={5}>
            {t('lessonPlan.createForm.shareAndRequestPublish.confirmation.title')}
          </Typography>
          <Typography fontSize={theme.fontSizes.medium} fontWeight={400} mb={5}>
            {t(
              'lessonPlan.createForm.shareAndRequestPublish.confirmation.content',
            )}
          </Typography>
          <Grid container justifyContent="flex-end" columnSpacing={5}>
            <Grid item width={100}>
              <Button fullWidth variant="outlined" onClick={handleOnClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item width={100}>
              <Button fullWidth onClick={() => {
                dialogConfirm(watch())

              }}>
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </FormRoot>
  )
})
