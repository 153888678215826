export const lessonPlanRequest = {
  entityName: {
    consultRequest: '諮詢項目',
    publishRequest: '發佈項目',
  },
  followingUp: {
    title: '跟進諮詢',

    tabs: {
      all: '全部',
      followingUp: '跟進中',
      completed: '已回覆',
    },
  },
  consultManagement: {
    title: '諮詢管理',

    tabs: {
      pending: '待跟進',
      followingUp: '跟進中',
      completed: '已回覆',
    },

    pendingItems: '待處理項目',

    consultDetail: {
      title: '老師提交之疑問或查詢',
      label: '與適異教學策略相關的疑問或查詢',
      placeholder: '已填寫之查詢內容',
      requireObservation: '需要專家觀課（錄影或實體）',

      actions: {
        viewLessonPlan: '查看教案',
      },
    },
  },
  publishManagement: {
    title: '發佈審批',

    tabs: {
      pending: '待處理',
      published: '已發佈',
      unPublished: '已下架',
    },
  },

  columnTitles: {
    isConsultRequest: '諮詢',
    teachingTopic: '教案名稱',
    authorUser: '作者',
    expert: '負責專家',
    assignedDate: '委派日期',
    lastModifiedDateTime: '最後更新',
    publishedDateTime: '發佈時間',
    unPublishedOrRejectedDateTime: '下架/拒絕時間',
    class: '年級',
    subject: '科目',
    status: '狀態',
    isAgreePublish: '同意公開',
  },
}
