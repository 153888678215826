export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  if (value === null || value === undefined) return false
  return true
}

export function notFalsy<TValue>(
  value: TValue | null | undefined | false,
): value is TValue {
  if (!value) return false
  return true
}
