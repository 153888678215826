export const users = {
  entityName: '帳戶資料',
  userDropdownLabel: '{{name}} ({{school}}) <{{email}}>',
  forbidden: '帳户已被停用',

  title: {
    schoolUser: '校內用戶',
    systemUsers: '用户總覽',
    myProfile: '帳戶設定',
    userProfile: '用戶資料',
  },
  fields: {
    email: '登記電郵',
    schoolName: '學校名稱',
    role: '用戶組別',
    status: '用戶狀態',
    name: '用戶姓名',
    educationLevel: '小學/中學',
    schoolShortCode: '學校名稱簡寫',
    school: '學校',
  },
  helpText: {
    email: '（僅限使用學校電郵）',
  },

  actions: {
    editUser: {
      successMessage: '已成功修改 ',
    },
    addUser: {
      title: '新增用戶',
      confirm: '建立帳戶',
      successMessage: '用戶電郵已成功新增! ',
      errorMessage: '建立帳戶失敗',
      retryDialog: {
        title: '無法新增以下電郵用戶',
        content: '是否要新增用戶?',
        retry: '再試一次',
      },
    },
    transferOwnership: {
      title: '轉移教案擁有權',
      successMessage: '已成功轉移擁有權! ',
      confirmationDialog: {
        title: '確認轉移擁有權',
        content: '請確認是否轉移此用戶「我的教案」之擁有權？',
        dropdownLabel: '此用戶的全部教案擁有權將轉移到以下用戶帳號：',
        dropdownPlaceholder: '@ newmember@ccss.edu.hk',
      },
    },
    disableAccount: {
      title: '停用帳戶',
      successMessage: '已成功停用! ',
      confirmationDialog: {
        title: '此用戶即將停用',
        content: '此用戶即將停用，\n請確認是否轉移此用戶「我的教案」的擁有權？',
        dropdownLabel: '此用戶的全部教案擁有權將轉移到以下用戶帳號：',
        dropdownPlaceholder: '@ newmember@ccss.edu.hk',
        disableAction: '僅停用',
        disableAndTransferAction: '轉移',
      },
      disableOnlyConfirmationDialog: {
        title: '此用戶即將停用',
        content: '此用戶即將停用',
      },
    },
    enableAccount: { title: '啟用帳戶', successMessage: '已成功啟用! ' },
    save: { title: '儲存' },
    edit: { title: '編輯' },
    errorMessage: {
      save: { title: '儲存失敗' },
      transfer: { title: '轉移教案擁有權失敗' },
      disable: { title: '停用帳戶失敗' },
      enable: { title: '啟用帳戶失敗' },
    },
  },
  status: {
    active: '使用中',
    disabled: '已停用',
  },
}
