import { useCallback } from 'react'
import { useStores } from '../../stores'

export enum LANGUAGES_ENUM {
  zhHant = 'zh-Hant',
  en = 'en',
}
export type LANGUAGES = 'zh-Hant' | 'en'

export function useDisplayName() {
  const { uiStore } = useStores()

  const getDisplayName = useCallback(
    (name?: string, defaultLanguage?: LANGUAGES) => {
      if (name == null) return
      try {
        const language = uiStore.language
        return JSON.parse(name)[defaultLanguage ?? language]
      } catch (err) {
        return name
      }
    },
    [uiStore.language],
  )

  const createJsonName = useCallback(
    (englishName?: string, chineseName?: string) => {
      if (englishName == null && chineseName == null) {
        return ''
      }
      if (englishName == null || chineseName == null) {
        return englishName + '' + chineseName
      }
      let jsonObject = {
        en: englishName,
        'zh-Hant': chineseName,
      }
      return JSON.stringify(jsonObject)
    },
    [],
  )

  return { getDisplayName, createJsonName }
}
