import { Button, ButtonProps } from '@mui/material'
import { memo, ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  label: ReactNode
  $isActive?: boolean
} & ButtonProps

const StyledTabButton = styled(Button)<{ $isActive?: boolean }>(
  ({ theme, $isActive }) => ({
    ...($isActive
      ? {
          backgroundColor: theme.colors.tertiary,
        }
      : {
          backgroundColor: theme.palettes.general.white,
          color: theme.palettes.general.greys[1],
          fontWeight: 400,
        }),
  }),
)

export const TabButton = memo<Props>(({ label, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledTabButton variant="text" {...props}>
      {label}
    </StyledTabButton>
  )
})
