import {
  GetContactUsDetailResponse,
  getContactUsDetails,
} from '@polyu-dip/apis'
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'configs'

export function useGetContactUsDetails(
  opts?: UseQueryOptions<GetContactUsDetailResponse | null>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT] as QueryKey,
    () => getContactUsDetails(),
    opts,
  )
}
