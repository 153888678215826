export const schools = {
  title: {
    schoolSummary: 'School overview',
  },
  entityName: 'Account information',

  fields: {
    name: 'Name of school',
    chineseName: 'School name in Chinese',
    englishName: 'School name in English',
    shortCode: 'Name abbreviation',
    schoolAdminEmail: 'School administrator email',
    domain: 'School domain name',
    status: 'School status',
    educationLevel: 'Primary school / Secondary school',
  },

  actions: {
    add: {
      title: 'Add school',
      confirm: 'Establish account',
      successMessage: 'The school information is added successfully',
      errorMessage: 'Fail to establish account',
      placeholder: {
        chineseName: 'Full name in Chinese',
        englishName: 'Full name in English',
        shortCode: 'English abbreviation',
        schoolAdminEmail: 'Administrator email',
        domain: 'Domain name',
        educationLevel: 'Primary school / Secondary school',
      },
    },
    edit: {
      title: 'Edit school information',
      confirm: 'Edit account',
      successMessage: 'The school information is edited successfully',
      errorMessage: 'Fail to edit account',
    },
    assignNewSchoolAdmin: {
      title: 'Appoint school administrator(s)',
    },
    disableAccount: {
      title: 'Suspend account ',
      successMessage: 'The school is suspended successfully',
      errorMessage: 'Fail to suspend account',
      confirmationDialog: {
        title: 'Suspend account',
        content: 'This school account will be terminated',
      },
    },
    enableAccount: {
      title: 'Enable account',
      successMessage: 'The school account is enabled successfully!',
      errorMessage: 'Fail to enable account',
    },
  },

  status: {
    active: 'In use',
    disabled: 'Suspended',
  },
}
