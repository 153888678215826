import {
  Grid,
  Link,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps,
} from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'
import { Svg } from '../../assets'

type OwnProps = {}

type Props = {
  label: string
  isActive?: boolean
} & ListItemButtonProps &
  OwnProps

const StyledListItemButton = styled(MuiListItemButton)<OwnProps>`
  padding-left: 0;
  &:hover {
  }
`

export const ListItemLinkButton = memo<Props>(
  ({ label, isActive, ...props }) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <StyledListItemButton {...props}>
        <Grid container alignContent="center">
          <Grid item>{isActive ? <Svg.ChevronUp /> : <Svg.ArrowDown />}</Grid>
          <Grid item ml={4}>
            <Link>{label}</Link>
          </Grid>
        </Grid>
      </StyledListItemButton>
    )
  },
)
