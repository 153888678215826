import { Button, Grid } from '@mui/material'
import { createEmptyLessonPlanStudentGroup } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../../../assets'
import { GeneralInformationFormData } from '../../../lesson-plan-form-provider'
import { Characteristic } from './characteristic'

const Container = styled.div``

type LearningTargetExtraProps = {}

export const LearningTargetExtra = observer<LearningTargetExtraProps>(() => {
  const { t } = useTranslation()

  const { control, watch, getValues, setValue } =
    useFormContext<GeneralInformationFormData>()
  const isMultiGroup = watch('isMultiGroup')

  const { fields: studentTrailGroupFields } = useFieldArray({
    control,
    name: 'lessonPlanStudentGroups',
  })

  const handleAddGroup = useCallback(() => {
    setValue('lessonPlanStudentGroups', [
      ...getValues('lessonPlanStudentGroups'),
      createEmptyLessonPlanStudentGroup(studentTrailGroupFields.length),
    ])
  }, [getValues, setValue, studentTrailGroupFields.length])

  const handleRemoveGroup = useCallback(
    (groupIndex: number) => {
      const newGroups = [...getValues('lessonPlanStudentGroups')]
      delete newGroups[groupIndex]
      setValue(
        'lessonPlanStudentGroups',
        newGroups.filter((it) => it != null),
      )
    },
    [getValues, setValue],
  )

  if (isMultiGroup === undefined) {
    return null
  }

  return (
    <Container>
      {studentTrailGroupFields.map((group, groupIndex) => (
        <Characteristic
          key={group.id}
          groupIndex={groupIndex}
          group={group}
          onRemoveStudentTrailGroup={() => handleRemoveGroup(groupIndex)}
        />
      ))}
      {isMultiGroup && (
        <Grid container justifyContent="flex-end" mb={4}>
          <Button
            startIcon={<Svg.Plus width={16} height={16} />}
            onClick={handleAddGroup}
          >
            {t('common.new')}
          </Button>
        </Grid>
      )}
    </Container>
  )
})
