import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'

export enum FileSource {
  blobStorage = 'blob-storage',
  user = 'user',
}

export const FileModel = BaseEntityModel.props({
  id: types.string,
  url: types.string,
  source: types.string,
}).extend(withUpdate)

export type File = Instance<typeof FileModel>
