import { memo } from 'react'
import styled from 'styled-components'
import { Img } from '../../../assets'
import { SponsorFooter } from '../../../components'


const Container = styled.div`
  margin-left: -32px;
  margin-right: -96px;
  padding-left: 32px;
  padding-right: 96px;
  background-image: url('${Img.TeacherTopLinkBG}');
  background-size: 100% 20vh;
  background-repeat: no-repeat;
  background-position: 0px 20px;
`

export const OutTeacherHomePage = memo(() => {
  return (
    <Container>
     
      <SponsorFooter />
    </Container>
  )
})
