import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'

export const UserInfoModel = BaseEntityModel.named('UserInfoModel')
  .props({
    id: types.string,
    oid: types.string,
    username: types.optional(types.string, '??'),
    email: types.maybe(types.string),
    role: types.maybe(types.string), // TODO: define role
  })
  .views((self) => ({
    get displayName() {
      return self.username
    },
  }))

export type UserInfo = Instance<typeof UserInfoModel>
