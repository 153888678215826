import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  PostResourceTypePayload,
  PutResourceTypePayload,
  ResourceTypeResponsePayload,
  ResourceTypesQueryParameters,
  ResourceTypesResponsePayload,
} from './resource-types-api.type'

const PATH = '/resource-types'

export function getResourceTypes(param?: ResourceTypesQueryParameters) {
  return ApiCore.primary.get<ResourceTypesResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}

export function postResourceTypesBulk(payloads: PostResourceTypePayload[]) {
  return ApiCore.primary.post<ResourceTypeResponsePayload[]>(`${PATH}/bulk`, {
    body: payloads,
  })
}

export function putResourceTypeById(
  id: string,
  payload: PutResourceTypePayload,
) {
  return ApiCore.primary.put<ResourceTypeResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}
