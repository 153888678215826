import {
  PostCreateBlobStorageContainerResponsePayload,
  postCreateBlobStorageContainer,
  PostGetBlobDownloadUrlResponsePayload,
  getBlobDownloadUrl,
} from '@polyu-dip/apis'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'files'

export function useCreateBlobStorageContainer(
  opts?: UseMutationOptions<
    PostCreateBlobStorageContainerResponsePayload | null,
    unknown
  >,
) {
  const queryClient = useQueryClient()

  return useMutation(() => postCreateBlobStorageContainer(), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useGetBlobDownloadUrl(
  opts?: UseMutationOptions<
    PostGetBlobDownloadUrlResponsePayload | null,
    unknown,
    string
  >,
) {
  const queryClient = useQueryClient()

  return useMutation((id) => getBlobDownloadUrl(id), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}
