import { withRootStore } from '@polyu-dip/model-core'
import { types } from 'mobx-state-tree'

export const NameModel = types
  .model('Name', {
    name: types.string,
  })
  .extend(withRootStore)
  .views((self) => ({
    get displayName(): string {
      try {
        const language = self.rootStore.uiStore.language
        const json = JSON.parse(self.name)
        if (Object.keys(json).includes(language)) return json[language]
        return json[Object.keys(json)[0]]
      } catch {
        return self.name
      }
    },
  }))
