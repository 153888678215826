// by convention, use plural nouns
export const contentPaths = {
  home: () => '/',
  myLessonPlan: () => '/my-lesson-plans',
  disposedLessonPlan: () => '/disposed-lesson-plans',
  allLessonPlans: () => '/lesson-plans',
  lessonPlans: (lessonPlanId: string, mode: 'edit' | 'view') =>
    contentPaths.myLessonPlan() + '/' + lessonPlanId + '/' + mode,
  disposedLessonPlans: (lessonPlanId: string, mode: 'view') =>
    contentPaths.disposedLessonPlan() + '/' + lessonPlanId + '/' + mode,
  allLessonPlan: (lessonPlanId: string) =>
    contentPaths.allLessonPlans() + '/' + lessonPlanId,
  sharedLessonPlans: (lessonPlanId: string) =>
    contentPaths.lessonPlanSharing() + '/' + lessonPlanId,
  diStrategies: () => '/di-strategies',
  lessonPlanSharing: () => '/lesson-plan-sharing',
  lessonPlanExampleManagements: () => '/lesson-plan-examples-management',
  lessonPlanExampleManagement: (lessonPlanId: string, mode: 'edit' | 'view') =>
    '/lesson-plan-examples-management' + '/' + lessonPlanId + '/' + mode,
  lessonPlanExamples: () => '/lesson-plan-examples',
  lessonPlanPublishCopy: (lessonPlanId: string) =>
    '/my-lesson-plans/' + lessonPlanId,
  lessonPlanExamplesDetail: (lessonPlanId: string) =>
    '/lesson-plan-examples' + '/' + lessonPlanId,
  lessonPlanConsultRequests: () => '/lesson-plan-consult-requests',
  lessonPlanConsultRequest: (lessonPlanConsultRequestId: string) =>
    contentPaths.lessonPlanConsultRequests() + '/' + lessonPlanConsultRequestId,
  lessonPlanPublishRequests: () => '/lesson-plan-publish-requests',
  lessonPlanPublishRequest: (
    lessonPlanPublishRequestId: string,
    mode: 'edit' | 'view' | 'view-comments',
  ) =>
    contentPaths.lessonPlanPublishRequests() +
    '/' +
    lessonPlanPublishRequestId +
    '/' +
    mode,
  resourcesManagement: () => '/resources-management',
  resources: (category?: 'online' | 'downloadable') =>
    '/resources' + (category != null ? '/' + category : ''),
  resourcesDetail: (resourceId: string) => '/resources' + '/' + resourceId,
  masterDataManagement: () => '/master-data-management',
  contactUs: () => '/contact-us',
  disclaimer: () => '/disclaimer',
  health: () => '/health',
  login: () => '/login',
  notifications: () => '/notifications',
  submissionRecords: () => '/submission-records',

  schools: () => '/schools',
  schoolManagement: (schoolId: string) => '/schools-management/' + schoolId,

  schoolUsers: () => '/school-users',
  systemUsers: () => '/system-users',
  userManagement: (userId: string) => '/system-users/' + userId,

  uiComponent: () => '/ui-components',
  myProfile: () => '/my-profile',
  apiTests: () => '/api-tests',
} as const
