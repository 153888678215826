import { School } from '@polyu-dip/models'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { UsersStore } from '@polyu-dip/stores'
import {
  getSchoolSubmissionRecords,
  PaginatedPayload,
  PutSchoolsPayload,
  PostSchoolsPayload,
  SchoolExpandParameter,
  SchoolsQueryParameters,
  SchoolSubmissionRecordsQueryParameters,
  SchoolSubmissionRecordsResponsePayload,
  PostSchoolDisablePayload,
  PostSchoolEnablePayload,
} from '@polyu-dip/apis'

const QUERY_CACHE_KEY_ROOT = 'schools'

type UseStoresType = () => { usersStore: UsersStore }

export function useSchools(
  useStores: UseStoresType,
  param?: SchoolsQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<School>>,
) {
  const { usersStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => usersStore.getSchools(param),
    opts,
  )
}

export function useSchool(
  useStores: UseStoresType,
  id: string,
  param?: SchoolExpandParameter,
  opts?: UseQueryOptions<School>,
) {
  const { usersStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, id, param] as QueryKey,
    () => usersStore.getSchool(id, param),
    opts,
  )
}

export function useCreateSchool(
  useStores: UseStoresType,
  opts?: UseMutationOptions<School, unknown, PostSchoolsPayload>,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => usersStore.createSchool(payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useUpdateSchool(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<School, unknown, PutSchoolsPayload>,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => usersStore.updateSchool(id, payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useDisableSchool(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    School,
    unknown,
    PostSchoolDisablePayload & { id: string }
  >,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => usersStore.disableSchool(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useEnableSchool(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    School,
    unknown,
    PostSchoolEnablePayload & { id: string }
  >,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => usersStore.enableSchool(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useSchoolSubmissionRecords(
  param?: SchoolSubmissionRecordsQueryParameters,
  opts?: UseQueryOptions<SchoolSubmissionRecordsResponsePayload | null>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'submission-records', param] as QueryKey,
    () => getSchoolSubmissionRecords(param),
    opts,
  )
}

export function clearSchoolQueryCaches(queryClient: QueryClient) {
  queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
