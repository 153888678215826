import { List } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { LessonPlanDiStrategyResourceType } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemButton, NestedItem, NestedList } from '../../components'
import { useActiveDiStrategyMaster } from '../../hooks'

type Props = {
  selectedDiStrategyId?: string
  setSelectedDiStrategyId: Dispatch<SetStateAction<string | undefined>>
  defaultSelectedDiStrategyClassId?: string
  defaultSelectedDiStrategyId?: string
  setSelectedResourceType: Dispatch<
    SetStateAction<LessonPlanDiStrategyResourceType | undefined>
  >
}

export const DiStrategySelectionList = observer<Props>(
  ({
    selectedDiStrategyId,
    setSelectedDiStrategyId,
    defaultSelectedDiStrategyClassId,
    defaultSelectedDiStrategyId,
    setSelectedResourceType,
  }) => {
    const { t } = useTranslation()
    const [selectedDiStrategyClassId, setSelectedDiStrategyClassId] = useState<
      string | undefined
    >(defaultSelectedDiStrategyClassId)

    const { activeDiStrategyClasses, activeDiStrategies } =
      useActiveDiStrategyMaster()

    const handleDiStrategyClassOnClick = useCallback((id: string) => {
      setSelectedDiStrategyClassId((selectedId) =>
        selectedId === id ? undefined : id,
      )
    }, [])
    const handleDiStrategyOnClick = useCallback(
      (id: string) => {
        setSelectedDiStrategyId(id)
        setSelectedResourceType(undefined)
      },
      [setSelectedDiStrategyId, setSelectedResourceType],
    )

    const filteredDiStrategies: NestedItem[] = useComputed(() => {
      return (
        activeDiStrategies
          .filter((it) => it.diStrategyClassId === selectedDiStrategyClassId)
          .map((it) => ({
            isButton: true,
            id: it.id,
            label: t('diStrategy.diStrategyLabel', {
              diStrategyClassLabel: it.diStrategyClass?.label ?? '',
              diStrategyLabelNumber: it.order,
              diStrategyName: it.displayName,
            }),
            isSelected:
              it.id === (selectedDiStrategyId ?? defaultSelectedDiStrategyId),
          })) ?? []
      )
    }, [
      activeDiStrategies,
      selectedDiStrategyClassId,
      selectedDiStrategyId,
      defaultSelectedDiStrategyId,
    ])

    return (
      <List>
        {activeDiStrategyClasses.map((diStrategyClass) => (
          <>
            <ListItemButton
              key={diStrategyClass.id}
              $isActive={diStrategyClass.id === selectedDiStrategyClassId}
              onClick={() => handleDiStrategyClassOnClick(diStrategyClass.id)}
              label={t('diStrategy.diStrategyClassLabel', {
                label: diStrategyClass.label,
                name: diStrategyClass.displayName,
              })}
            />
            <NestedList
              open={selectedDiStrategyClassId === diStrategyClass.id}
              nestedItems={filteredDiStrategies}
              handleItemOnClick={handleDiStrategyOnClick}
            />
          </>
        ))}
      </List>
    )
  },
)
