import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { domainFormatRegExp } from '@polyu-dip/utilities'
import { useFormErrorTranslationTrigger } from './use-form-error-translation-trigger'

export type ModeType = 'add' | 'edit'
export type NewSchoolFormData = {
  chineseName: string
  englishName: string
  shortCode: string
  domain: string
  mainSchoolAdminUserEmail: string
  mainSchoolAdminUserId: string
  educationLevelId: string
  mode: ModeType
}

export function useSchoolFormProvider() {
  const { t } = useTranslation()

  const schema = useMemo(
    () =>
      yup.object({
        chineseName: yup.string().required(t('error.required')),
        englishName: yup.string().required(t('error.required')),
        shortCode: yup.string().required(t('error.required')),
        domain: yup
          .string()
          .required(t('error.required'))
          .matches(domainFormatRegExp, t('error.invalidSchoolDomain')),
        mode: yup.string(),
        mainSchoolAdminUserEmail: yup
          .string()
          .when('mode', {
            is: 'add',
            then: yup.string().required(t('error.required')),
          })
          .email(t('error.invalidEmail')),
        mainSchoolAdminUserId: yup.string().when('mode', {
          is: 'edit',
          then: yup.string().required(t('error.required')),
        }),
        educationLevelId: yup.string().required(t('error.required')),
      }),
    [t],
  )

  const defaultNewSchool = useMemo(() => {
    return {
      chineseName: undefined,
      englishName: undefined,
      shortCode: undefined,
      domain: undefined,
      mainSchoolAdminUserEmail: undefined,
      mainSchoolAdminUserId: undefined,
      educationLevelId: undefined,
      mode: undefined,
    }
  }, [])

  const form = useForm<NewSchoolFormData>({
    resolver: yupResolver(schema),
    defaultValues: defaultNewSchool,
  })

  useFormErrorTranslationTrigger(form.formState.errors, form.trigger)

  return { defaultNewSchool, form }
}
