import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import {
  FullscreenLoading,
  ListPage,
  PaginationFooter,
  useRowCountState,
  NotificationItem,
  FullScreenText,
} from '../../components'
import { useMyNotifications } from '@polyu-dip/queries'
import { useApiErrorHandle, useMasterDataInit } from '../../hooks'
import { contentPaths } from '../../content-paths'
import { useNavigate } from 'react-router-dom'
import { useComputed } from '@polyu-dip/helpers'
import { PaginationParameters } from '@polyu-dip/apis'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Box, Grid } from '@mui/material'
import { useStores } from '../../stores'

const PAGINATION_LIMIT = 10

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

const NotificationList = styled(Box)`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding-top: 20px;
  padding-bottom: 20px;
`

export const Notifications = observer(() => {
  const { t } = useTranslation()
  const { standardErrorHandler } = useApiErrorHandle()
  const navigate = useNavigate()
  const { isAllMasterDataFetchSuccess } = useMasterDataInit(contentPaths.home())

  const { masterDataStore } = useStores()

  const subjects = useComputed(
    () => masterDataStore.subjects.slice() ?? [],
    [masterDataStore.subjects],
  )

  const classLevels = useComputed(
    () => masterDataStore.classLevels.slice() ?? [],
    [masterDataStore.classLevels],
  )

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)

  const { data, isLoading } = useMyNotifications(
    {
      expand: ['CreatedByUser', 'Comment', 'LessonPlan'],
      sort: [{ parameter: 'createdDateTime', direction: 'desc' }],
      ...pagination,
    },
    {
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('error.fetchEntityFailure', {
            entityName: t('notifications.entityName'),
          }),
          onDismiss: () => {
            navigate(contentPaths.home())
          },
        })
      },
    },
  )

  const computedNotifications = useComputed(
    () =>
      data?.data.map((it) => {
        return {
          ...it,
          subject: subjects.find(
            (subject) => subject.id == it.lessonPlan?.subjectId,
          ),
          classLevel: classLevels.find(
            (classLevel) => classLevel.id == it.lessonPlan?.classLevelId,
          ),
        }
      }) ?? [],
    [classLevels, data?.data, subjects],
  )

  const { rowCountState: totalRecord } = useRowCountState(data?.total)

  const totalPage = useComputed(
    () => Math.ceil((totalRecord ?? 0) / PAGINATION_LIMIT),
    [totalRecord],
  )

  const handlePageOnChange = useCallback(
    (value: number) => {
      setPagination({
        ...pagination,
        offset: (value - 1) * PAGINATION_LIMIT,
      })
    },
    [pagination],
  )

  if (isLoading || !data || !isAllMasterDataFetchSuccess) {
    return <FullscreenLoading />
  }

  return (
    <>
      {computedNotifications.length > 0 ? (
        <ListPage
          sectionTitleProps={{
            title: t('notifications.title'),
            color: 'red',
          }}
          fullHeight
        >
          <>
            <NotificationList>
              <Grid container direction="column" wrap="nowrap">
                {computedNotifications.map((it, index) => (
                  <Grid key={it.id}>
                    <NotificationItem
                      key={it.id}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...it}
                      isDisplayConnectionLine={index != 0}
                      onNavigate={(path) => navigate(path)}
                    />
                  </Grid>
                ))}
              </Grid>
            </NotificationList>
            <PaginationFooter
              totalPage={totalPage}
              defaultPage={(data?.offset ?? 0) / PAGINATION_LIMIT + 1}
              paginationProps={{
                color: 'primary',
                hideNextButton: true,
              }}
              onPageChanged={handlePageOnChange}
            />
          </>
        </ListPage>
      ) : (
        <FullScreenText title={t('notifications.noRowsOverlayTitle')} />
      )}
    </>
  )
})
