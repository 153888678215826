import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { NameModel } from './languages-json'
import { Role } from './role.model'
import { School } from './school.model'

export enum Status {
  active = 'Active',
  disabled = 'Disabled',
}

export const UserModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    email: types.string,
    schoolId: types.maybe(types.string),
    roleId: types.string,
    isDisabled: types.boolean,
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .named('UserModel')
  .views((self) => ({
    get school() {
      if (self.schoolId == null) return undefined
      return (self.rootStore.usersStore as { schools: School[] }).schools.find(
        (school) => school.id === self.schoolId,
      )
    },
    get role() {
      if (self.roleId == null) return undefined
      return (self.rootStore.usersStore as { roles: Role[] }).roles.find(
        (role) => role.id === self.roleId,
      )
    },
    get isAccountDisabled() {
      if (self.isDisabled) return true
      if (self.schoolId == null) return false
      return (self.rootStore.usersStore as { schools: School[] }).schools.find(
        (school) => school.id === self.schoolId,
      )?.isDisabled
    },
  }))

export type User = Instance<typeof UserModel>
