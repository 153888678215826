import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { BaseEntity, LessonPlanExamplesResponsePayload } from '@polyu-dip/apis'
import dayjs from 'dayjs'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Tag } from '../../components'
import { useDisplayName } from '../../services'

const wordLimit = 40

type Props = {
  lessonPlanData: LessonPlanExamplesResponsePayload & BaseEntity
  handleOnClick: (id: string) => void
}

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palettes.general.white};
  box-shadow: 0px 1px 2px #828282;
  border-radius: 20px;
  padding: 12px 16px 12px 16px;
  min-height: 151px;
  cursor: pointer;
`

const StyledTitle = styled(Typography)`
  overflow-wrap: break-word;
  font-size: ${({ theme }) => theme.fontSizes.heading5}px;
`

const StyledTypography = styled(Typography)<{ isGreyColor?: boolean }>`
  overflow-wrap: break-word;
  font-size: ${({ theme }) => theme.fontSizes.normal}px;
  color: ${({ isGreyColor, theme }) =>
    isGreyColor
      ? theme.palettes.general.greys[2]
      : theme.palettes.general.black};
`

const StyledTag = styled(Tag)`
  cursor: pointer;
`

export const LessonPlanExampleCard = memo<Props>(
  ({ lessonPlanData, handleOnClick }) => {
    const { t } = useTranslation()
    const { getDisplayName } = useDisplayName()
    const tooltipRequired = useMemo(
      () => lessonPlanData.teachingTopic.length > wordLimit,
      [lessonPlanData.teachingTopic],
    )
    const teachingTopic = useMemo(
      () =>
        lessonPlanData.teachingTopic.length > wordLimit
          ? lessonPlanData.teachingTopic.substring(0, wordLimit).concat('...')
          : lessonPlanData.teachingTopic,
      [lessonPlanData.teachingTopic],
    )

    return (
      <Container onClick={() => handleOnClick(lessonPlanData.id)}>
        <Grid container direction="column" spacing={2} wrap="nowrap">
          <Grid item>
            <Tooltip
              title={tooltipRequired ? lessonPlanData.teachingTopic : ''}
              arrow
            >
              <StyledTitle fontWeight={600}>{teachingTopic}</StyledTitle>
            </Tooltip>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <StyledTag
                label={getDisplayName(
                  lessonPlanData.classLevel?.learningPhase?.name,
                )}
                colorCustomize={
                  lessonPlanData.classLevel?.learningPhase?.colorCode
                }
              />
            </Grid>
            <Grid item>
              <StyledTag
                label={getDisplayName(lessonPlanData.classLevel?.name)}
                colorCustomize={lessonPlanData.classLevel?.colorCode}
              />
            </Grid>
            <Grid item>
              <StyledTag
                label={getDisplayName(lessonPlanData.subject?.name)}
                colorCustomize={lessonPlanData.subject?.colorCode}
              />
            </Grid>
          </Grid>
          <Grid item>
            <StyledTypography>
              {getDisplayName(lessonPlanData.ownedByUser?.school?.name)}
            </StyledTypography>
          </Grid>
          <Grid item>
            <StyledTypography isGreyColor>
              {lessonPlanData.code}
            </StyledTypography>
          </Grid>
          <Grid item display="flex" justifyContent="flex-end">
            <StyledTypography isGreyColor>
              {lessonPlanData.lastModifiedDateTime == null
                ? '---'
                : dayjs(lessonPlanData.lastModifiedDateTime).format(
                    t('common.format.datetime'),
                  )}
            </StyledTypography>
          </Grid>
        </Grid>
      </Container>
    )
  },
)
