import {
  DiStrategyClassesQueryParameters,
  PaginatedPayload,
  PostDiStrategyClassPayload,
  PutDiStrategyClassPayload,
} from '@polyu-dip/apis'
import { DiStrategyClass } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'di-strategy-classes'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useDiStrategyClasses(
  useStores: UseStoresType,
  param?: DiStrategyClassesQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<DiStrategyClass>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getDiStrategyClasses(param),
    opts,
  )
}

export function useCreateDiStrategyClasses(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostDiStrategyClassPayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payloads) => masterDataStore.createDiStrategyClasses(payloads),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useUpdateDiStrategyClass(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<
    DiStrategyClass,
    unknown,
    PutDiStrategyClassPayload
  >,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => masterDataStore.updateDiStrategyClass(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useSwitchDiStrategyClassStatus(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    DiStrategyClass,
    unknown,
    PutDiStrategyClassPayload & { id: string }
  >,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => masterDataStore.updateDiStrategyClass(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export async function clearDiStrategyClassQueryCaches(
  queryClient: QueryClient,
) {
  await queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
