import { Box, Button, Dialog, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@polyu-dip/theme'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

export const WithdrawApprovePublishDialog = observer<Props>(
  ({ open, onClose, onSubmit }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    return (
      <Dialog maxWidth={false} open={open} onClose={onClose}>
        <Box width={875} p={10}>
          <Typography
            fontSize={theme.fontSizes.heading4}
            fontWeight={600}
            mb={5}
          >
            {t('lessonPlan.detail.publishRequest.withdraw.title')}
          </Typography>
          <Typography fontSize={theme.fontSizes.medium} fontWeight={400} mb={5}>
            {t('lessonPlan.detail.publishRequest.withdraw.content')}
          </Typography>
          <Grid container justifyContent="flex-end" columnSpacing={5}>
            <Grid item width={200}>
              <Button fullWidth variant="outlined" onClick={onClose}>
                {t('lessonPlan.detail.publishRequest.actions.close')}
              </Button>
            </Grid>
            <Grid item width={200}>
              <Button fullWidth color="cascade" onClick={onSubmit}>
                {t('lessonPlan.detail.publishRequest.actions.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    )
  },
)
