import { ApiCore } from '@polyu-dip/api-core'
import { convertExpandParameters, convertSortParameters } from '../base'
import {
  PostResourceDisableBulk,
  PostResourcePayload,
  PutResourcePayload,
  ResourceQueryParameters,
  ResourceResponsePayload,
  ResourcesResponsePayload,
} from './resources-api.type'

const PATH = '/resources'

export function getResources(param?: ResourceQueryParameters) {
  return ApiCore.primary.get<ResourcesResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: convertExpandParameters(param),
    },
  })
}

export function postResource(payload: PostResourcePayload) {
  return ApiCore.primary.post<ResourceResponsePayload>(PATH, {
    body: payload,
  })
}

export function postResourcesBulk(payloads: PostResourcePayload[]) {
  return ApiCore.primary.post<ResourceResponsePayload[]>(`${PATH}/bulk`, {
    body: payloads,
  })
}

export function putResourceById(id: string, payload: PutResourcePayload) {
  return ApiCore.primary.put<ResourceResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}

export function postResourceDisableById(id: string, rowVersion: string) {
  return ApiCore.primary.post<ResourceResponsePayload>(
    `${PATH}/${id}/disable`,
    {
      body: {
        rowVersion,
      },
    },
  )
}

export function postResourceDisableBulk(payloads: PostResourceDisableBulk) {
  return ApiCore.primary.post<ResourceResponsePayload[]>(
    `${PATH}/disable/bulk`,
    {
      body: payloads,
    },
  )
}
