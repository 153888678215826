import {
  FileResponsePayload,
  getResources,
  PaginatedPayload,
  postResource,
  postResourceDisableBulk,
  PostResourceDisableBulk,
  postResourceDisableById,
  PostResourcePayload,
  postResourcesBulk,
  putResourceById,
  PutResourcePayload,
  ResourceQueryParameters,
  ResourceResponsePayload,
  ResourcesResponsePayload,
} from '@polyu-dip/apis'
import { withRootStore } from '@polyu-dip/model-core'
import { FileModel, Resource, ResourceModel } from '@polyu-dip/models'
import { convertNullToUndefined, upsertList } from '@polyu-dip/utilities'
import { flow, Instance, types } from 'mobx-state-tree'

const emptyPayloadMessage = 'empty-payload'

export const FilesStoreModel = types
  .model('FilesStore')
  .props({
    files: types.array(FileModel),
    resources: types.array(ResourceModel),
  })
  .extend(withRootStore)
  .actions((self) => {
    const actions = {
      upsertFile(inPayload: FileResponsePayload) {
        const payload = inPayload
        const existing = self.files.find((file) => file.id === payload.id)
        if (existing == null) {
          self.files.push(convertNullToUndefined(payload))
          return self.files[self.files.length - 1]
        }
        existing.update(payload)
        return existing
      },
      upsertFiles(payloads: FileResponsePayload[]) {
        return payloads.map(actions.upsertFile)
      },
      upsertResource(inPayload: ResourceResponsePayload) {
        const { subject, classLevel, file, resourceType, ...maybeNullPayload } =
          inPayload
        if (subject != null) {
          self.rootStore.masterDataStore.upsertSubject(subject)
        }
        if (classLevel != null) {
          self.rootStore.masterDataStore.upsertClassLevel(classLevel)
        }
        if (file) {
          upsertList(self.files, file)
        }
        if (resourceType != null) {
          self.rootStore.masterDataStore.upsertResourceType(resourceType)
        }
        return upsertList(self.resources, maybeNullPayload)
      },
      upsertResources(payloads: ResourceResponsePayload[]) {
        return payloads.map(actions.upsertResource)
      },
      getResources: flow(function* getResourceAction(
        param?: ResourceQueryParameters,
      ): Generator<any, PaginatedPayload<Resource>, ResourcesResponsePayload> {
        const payload = yield getResources(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertResources(payload.data),
        }
      }),
      createResource: flow(function* createResourceAction(
        resource: PostResourcePayload,
      ): Generator<any, Resource, ResourceResponsePayload> {
        const payload = yield postResource(resource)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertResource(payload)
      }),
      createResources: flow(function* createResourcesAction(
        resources: PostResourcePayload[],
      ): Generator<any, Resource[], ResourceResponsePayload[]> {
        const payload = yield postResourcesBulk(resources)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertResources(payload)
      }),
      updateResource: flow(function* updateResourceAction(
        id: string,
        resource: PutResourcePayload,
      ): Generator<any, Resource, ResourceResponsePayload> {
        const payload = yield putResourceById(id, resource)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertResource(payload)
      }),
      disableResource: flow(function* disableResourceAction(
        id: string,
        rowVersion: string,
      ): Generator<any, Resource, ResourceResponsePayload> {
        const payload = yield postResourceDisableById(id, rowVersion)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertResource(payload)
      }),
      disableResources: flow(function* disableResourcesAction(
        resources: PostResourceDisableBulk,
      ): Generator<any, Resource[], ResourceResponsePayload[]> {
        const payload = yield postResourceDisableBulk(resources)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertResources(payload)
      }),
    }
    return actions
  })

export type FilesStore = Instance<typeof FilesStoreModel>
