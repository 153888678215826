import { Button, Chip, Typography } from '@mui/material'
import { GridSelectionModel, GridSortModel } from '@mui/x-data-grid'
import { PaginationParameters } from '@polyu-dip/apis'
import { useComputed } from '@polyu-dip/helpers'
import { File, FileSource } from '@polyu-dip/models'
import {
  useDisableResources,
  useResources,
  useResourceTypes,
} from '@polyu-dip/queries'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Svg } from '../../assets'
import {
  DownloadableResourceDialog,
  EditDownloadableResourceDataRowType,
  EditResourceDataRowType,
  ListPage,
  OnlineResourceDialog,
  ResourceFilter,
  Tab,
  Table,
  Tabs,
  useOverlay,
  useRowCountState,
} from '../../components'
import { contentPaths } from '../../content-paths'
import {
  useApiErrorHandle,
  useMasterData,
  useResourceFilter,
} from '../../hooks'
import { useBlobStorage } from '../../services'
import { useStores } from '../../stores'
import { sortByOrder } from '../../utilities'
import { ResourceTabMenu, useResourcesColumn } from './use-resources-column'

type Props = {
  isManagementPage?: boolean
  defaultTab?: ResourceTabMenu
}

const StyledChip = styled(Chip)`
  font-size: 14px;
  line-height: 120%;
  height: auto;
  margin-left: 12px;

  .MuiChip-label {
    padding: 0px 8px;
  }
`

const FlexRow = styled.div`
  display: flex;
  direction: row;
  white-space: nowrap;
`
const PAGINATION_LIMIT = 15

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

export const Resources = observer<Props>(
  ({ isManagementPage = false, defaultTab }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { showSpinner, hideSpinner, showSnackbar } = useOverlay()
    const { standardErrorHandler } = useApiErrorHandle()
    const navigate = useNavigate()

    const [
      isCreateOnlineResourceDialogOpen,
      setIsCreateOnlineResourceDialogOpen,
    ] = useState(false)
    const [
      isCreateDownloadableResourceDialogOpen,
      setIsCreateDownloadableResourceDialogOpen,
    ] = useState(false)
    const [
      isEditDownloadableResourceDialogOpen,
      setIsEditDownloadableResourceDialogOpen,
    ] = useState(false)

    const [isEditOnlineResourceDialogOpen, setIsEditOnlineResourceDialogOpen] =
      useState(false)

    const { masterDataStore } = useStores()
    const { renderColumn } = useResourcesColumn()
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
    const { isAllMasterDataFetchSuccess, ...filterProps } = useResourceFilter()
    useResourceTypes(useStores)
    const resourceTypeOptions = useComputed(
      () =>
        masterDataStore.resourceTypes
          .slice()
          .sort(sortByOrder)
          .map((resourceType) => ({
            label: resourceType.displayName,
            value: resourceType.id,
            disabled: resourceType.isDisabled,
          })) ?? [],
      [masterDataStore.resourceTypes],
    )
    const classLevelOptions = useComputed(
      () =>
        masterDataStore.classLevels
          .slice()
          .sort(sortByOrder)
          .map((classLevel) => ({
            label: classLevel.displayName,
            value: classLevel.id,
            educationLevelId: classLevel.learningPhase?.educationLevelId,
            disabled: classLevel.isDisabled,
          })) ?? [],
      [masterDataStore.classLevels],
    )
    const subjectOptions = useComputed(
      () =>
        masterDataStore.subjects
          .slice()
          .sort(sortByOrder)
          .map((subject) => ({
            label: subject.displayName,
            value: subject.id,
            educationLevelIds: subject.subjectEducationLevelMappings.map(
              (it) => it.educationLevelId,
            ),
            disabled: subject.isDisabled,
          })),
      [masterDataStore.subjects],
    )

    const filterItems = useMemo(() => {
      return {
        title: filterProps.inputTitle,
        resourceTypeId: filterProps.selectedResourceType?.value as string,
      }
    }, [filterProps.inputTitle, filterProps.selectedResourceType?.value])

    const downloadableResourceFilterItems = useMemo(() => {
      return {
        'classLevel.learningPhaseId': filterProps.selectedLearningPhase
          ?.value as string,
        classLevelId: filterProps.selectedClassLevel?.value as string,
        subjectId: filterProps.selectedSubject?.value as string,
      }
    }, [
      filterProps.selectedClassLevel?.value,
      filterProps.selectedLearningPhase?.value,
      filterProps.selectedSubject?.value,
    ])

    const [activeTab, setActiveTab] = useState<ResourceTabMenu>(
      defaultTab ?? ResourceTabMenu.onlineResources,
    )
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
    const [onlineResourcesTabSort, setOnlineResourcesTabSort] =
      useState<GridSortModel>()
    const [downloadableResourceTabSort, setDownloadableResourceTabSort] =
      useState<GridSortModel>()

    const handleTabChange = useCallback(
      (event: any, value: any) => {
        if (!isManagementPage) {
          if (value == ResourceTabMenu.downloadableResources) {
            navigate(contentPaths.resources('downloadable'))
          } else {
            navigate(contentPaths.resources('online'))
          }
        }
        setActiveTab(value)
        setSelectionModel([])
        setPagination({
          limit: PAGINATION_LIMIT,
          offset: 0,
        })
      },
      [isManagementPage, navigate],
    )

    const { data: onlineResources, isLoading: isOnlineResourcesLoading } =
      useResources(useStores, {
        isOnlineResource: true,
        isDisabled: false,
        expand: ['File'],
        sort: [
          ...(onlineResourcesTabSort?.map((it) => ({
            direction: it.sort ?? 'desc',
            parameter: it.field,
          })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }]),
        ],
        ...pagination,
        ...filterItems,
      })

    const {
      data: downloadableResources,
      isLoading: isDownloadableResourcesLoading,
    } = useResources(useStores, {
      isOnlineResource: false,
      isDisabled: false,
      sort: [
        ...(downloadableResourceTabSort?.map((it) => ({
          direction: it.sort ?? 'desc',
          parameter: it.field,
        })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }]),
      ],
      expand: ['ClassLevel', 'Subject', 'ClassLevel.LearningPhase', 'File'],
      ...pagination,
      ...filterItems,
      ...downloadableResourceFilterItems,
    })

    const { mutateAsync: disableResources } = useDisableResources(useStores, {
      onSuccess: () => {
        showSnackbar({
          message: t('resources.deleteResources.success'),
        })
        setSelectionModel([])
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('resources.deleteResources.error'),
        })
      },
    })

    const handleAddOnClick = useCallback(() => {
      setIsCreateOnlineResourceDialogOpen(true)
    }, [])

    const handleUploadOnClick = useCallback(() => {
      setIsCreateDownloadableResourceDialogOpen(true)
    }, [])

    const { getDownloadUrl } = useBlobStorage()
    const handleDownloadOnClick = useCallback(
      (file?: File) => {
        if (file == null) return
        if (file.source === FileSource.user) {
          window.open(file.url, '_blank')
        } else {
          getDownloadUrl(file.id).then((downloadUrl: string) => {
            window.open(downloadUrl, '_blank')
          })
        }
      },
      [getDownloadUrl],
    )
    const handleTitleOnClick = useCallback((url: string) => {
      window.open(url, '_blank')
    }, [])

    const totalRecord = useMemo(() => {
      return activeTab === ResourceTabMenu.onlineResources
        ? onlineResources?.total
        : downloadableResources?.total
    }, [activeTab, downloadableResources?.total, onlineResources?.total])

    const { rowCountState } = useRowCountState(totalRecord)

    const computedLessonPlanData = useComputed(() => {
      return (
        (activeTab === ResourceTabMenu.onlineResources
          ? onlineResources?.data
          : downloadableResources?.data) ?? []
      )
    }, [activeTab, onlineResources?.data, downloadableResources?.data])

    const [selectedDownloadableResource, setSelectedDownloadableResource] =
      useState<undefined | EditDownloadableResourceDataRowType>()
    const [dialogEditModeSelectedData, setDialogEditModeSelectedData] =
      useState<undefined | EditResourceDataRowType>()

    const isLoading = useComputed(() => {
      if (!isAllMasterDataFetchSuccess) return true
      return activeTab === ResourceTabMenu.onlineResources
        ? isOnlineResourcesLoading
        : isDownloadableResourcesLoading
    }, [
      isAllMasterDataFetchSuccess,
      activeTab,
      isOnlineResourcesLoading,
      isDownloadableResourcesLoading,
    ])

    const handleDeleteOnClick = useCallback(async () => {
      try {
        showSpinner()
        const payloads = selectionModel.map((selected) => {
          const target = computedLessonPlanData.find(
            (data) => data.id === selected,
          )
          return {
            id: target?.id ?? '',
            rowVersion: target?.rowVersion ?? '',
          }
        })
        await disableResources(payloads)
      } finally {
        hideSpinner()
      }
    }, [
      computedLessonPlanData,
      disableResources,
      hideSpinner,
      selectionModel,
      showSpinner,
    ])

    const renderTabs = useMemo(() => {
      return (
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          $activeColor={theme.palettes.general.greys[0]}
          $activeIndicatorColor={theme.palettes.general.cascade}
        >
          <Tab
            value={ResourceTabMenu.onlineResources}
            label={
              <FlexRow>
                <Typography>{t('resources.tags.onlineResources')}</Typography>
                <StyledChip
                  label={onlineResources?.total ?? 0}
                  color={
                    activeTab === ResourceTabMenu.onlineResources
                      ? 'cascade'
                      : 'default'
                  }
                />
              </FlexRow>
            }
            $width={320}
          />
          <Tab
            value={ResourceTabMenu.downloadableResources}
            label={
              <FlexRow>
                <Typography>
                  {t('resources.tags.downloadableResource')}
                </Typography>
                <StyledChip
                  label={downloadableResources?.total ?? 0}
                  color={
                    activeTab === ResourceTabMenu.downloadableResources
                      ? 'cascade'
                      : 'default'
                  }
                />
              </FlexRow>
            }
            $width={320}
          />
        </Tabs>
      )
    }, [
      activeTab,
      downloadableResources?.total,
      handleTabChange,
      onlineResources?.total,
      t,
      theme.palettes.general.cascade,
      theme.palettes.general.greys,
    ])

    const { systemParameters } = useMasterData()

    const selfLearningLink = useMemo(
      () =>
        systemParameters.find((it) => it.category === 'self-learning module')
          ?.value_Text,
      [systemParameters],
    )

    return (
      <ListPage
        sectionTitleProps={{
          title: t('resources.title'),
          colorCustomize: theme.palettes.general.cascade,
        }}
        sectionTitleChildren={
          selfLearningLink != null ? (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={selfLearningLink} target="_blank">
              <Button
                startIcon={
                  <Svg.ExternalLink fill={theme.palettes.general.white} />
                }
                color="brown"
              >
                {t('resources.actions.selfLearningModule')}
              </Button>
            </a>
          ) : undefined
        }
      >
        {renderTabs}
        <ResourceFilter
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...filterProps}
          activeTab={activeTab}
          isManagementPage={isManagementPage}
          handleDelete={handleDeleteOnClick}
          handleAdd={handleAddOnClick}
          handleUpload={handleUploadOnClick}
          selectionModel={selectionModel}
        />
        <Table
          checkboxSelection={isManagementPage}
          disableSelectionOnClick
          isReadOnly={!isManagementPage}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel)
          }}
          selectionModel={selectionModel}
          columns={renderColumn(
            isManagementPage,
            activeTab,
            handleTitleOnClick,
            handleDownloadOnClick,
          )}
          onRowClick={({ row }) => {
            if (isManagementPage) {
              if (activeTab === ResourceTabMenu.onlineResources) {
                //? RowClick handle resources mutation
                const data: EditResourceDataRowType = {
                  id: row?.id,
                  rowVersion: row?.rowVersion,
                  title: row?.title,
                  resourceTypeId: row?.resourceTypeId,
                  url: row?.file?.url,
                }
                setDialogEditModeSelectedData(data)
                setIsEditOnlineResourceDialogOpen(true)
              } else {
                setSelectedDownloadableResource({
                  id: row?.id,
                  title: row?.title,
                  classLevelId: row?.classLevelId,
                  subjectId: row?.subjectId,
                  resourceTypeId: row?.resourceTypeId,
                  file: row?.file,
                  rowVersion: row?.rowVersion,
                })
                setIsEditDownloadableResourceDialogOpen(true)
              }
            }
          }}
          rows={computedLessonPlanData}
          padding={theme.spacings.general[4]}
          paddingTop={0}
          pageSize={PAGINATION_LIMIT}
          loading={isLoading}
          paginationMode="server"
          page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
          hideFooterPagination={
            rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
          }
          rowCount={rowCountState}
          sortModel={
            activeTab === ResourceTabMenu.onlineResources
              ? onlineResourcesTabSort
              : downloadableResourceTabSort
          }
          onSortModelChange={
            activeTab === ResourceTabMenu.onlineResources
              ? setOnlineResourcesTabSort
              : setDownloadableResourceTabSort
          }
          onPageChange={(page) =>
            setPagination((old) => ({
              ...old,
              offset: page * PAGINATION_LIMIT,
            }))
          }
        />
        <OnlineResourceDialog
          mode="create"
          isOpen={isCreateOnlineResourceDialogOpen}
          setIsOpen={setIsCreateOnlineResourceDialogOpen}
          resourceTypeOptions={resourceTypeOptions}
        />

        <OnlineResourceDialog
          mode="edit"
          resourceData={dialogEditModeSelectedData}
          isOpen={isEditOnlineResourceDialogOpen}
          setIsOpen={setIsEditOnlineResourceDialogOpen}
          resourceTypeOptions={resourceTypeOptions}
        />

        <DownloadableResourceDialog
          mode="create"
          isOpen={isCreateDownloadableResourceDialogOpen}
          setIsOpen={setIsCreateDownloadableResourceDialogOpen}
          classLevelOptions={classLevelOptions}
          subjectOptions={subjectOptions}
          resourceTypeOptions={resourceTypeOptions}
        />
        <DownloadableResourceDialog
          mode="edit"
          resourceData={selectedDownloadableResource}
          isOpen={isEditDownloadableResourceDialogOpen}
          setIsOpen={setIsEditDownloadableResourceDialogOpen}
          classLevelOptions={classLevelOptions}
          subjectOptions={subjectOptions}
          resourceTypeOptions={resourceTypeOptions}
        />
      </ListPage>
    )
  },
)
