import { FormControl, MenuItem, Select, SelectProps } from '@mui/material'
import _ from 'lodash'
import { memo, ReactNode, useMemo, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuidV4 } from 'uuid'
import { Svg } from '../../../assets'
import { FormLabel } from '../form-label'

const StyledFormControl = styled(FormControl)<{
  fullWidth?: boolean
  mb?: number
}>`
  position: relative;
  margin-bottom: ${({ mb = 4 }) => mb * 4}px;
`

const StyledSelect = styled(Select)<{ $isHaveValue?: boolean }>`
  color: ${({ theme }) => theme.palettes.general.black};
  label + & {
    margin-top: 4px;
  }
  & .MuiInputBase-input {
    height: 35px;
    box-sizing: border-box;
    padding: 6px 8px;
    border-radius: 4px;
    background-color: ${({ theme, $isHaveValue }) =>
      theme.palettes.general[$isHaveValue ? 'white' : 'highlighted']};
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.palettes.general.greys[1]};
  }
  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: ${({ theme }) => theme.palettes.general.greys[3]};
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${({ theme }) => theme.palettes.general.greys[2]};
  }
  &.Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palettes.general.error};
    }
  }
  .MuiSelect-icon {
    top: calc(50% - 10px);
  }
`

const Error = styled.span`
  font-size: 0.75rem;
  margin-top: 3px;
  color: ${({ theme }) => theme.palettes.general.error};
`

const Placeholder = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.palettes.general.greys[2]};
`

export type SelectOption = {
  label: ReactNode
  value?: string | number | readonly string[]
  disabled?: boolean
}

type Props = Omit<SelectProps, 'error'> & {
  options: SelectOption[]
  error?: string
  mb?: number
  isReserverForHelperText?: boolean
  clearable?: boolean
  clearableEndAdornment?: ReactNode
}

export const Dropdown = memo(
  ({
    label,
    id,
    required,
    fullWidth = true,
    options,
    placeholder,
    multiline = false,
    error,
    mb,
    isReserverForHelperText,
    clearable,
    clearableEndAdornment,
    ...props
  }: Props) => {
    const [baseValue, setBaseValue] = useState<any>(
      props.defaultValue ?? props.value,
    )
    const uuid = useMemo(() => uuidV4(), [])

    return (
      <StyledFormControl fullWidth={fullWidth} mb={mb}>
        {label && (
          <FormLabel label={label} id={id ?? uuid} required={required} />
        )}

        <StyledSelect
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          value={props.value}
          onChange={(e, ...params) => {
            setBaseValue(e.target.value)
            props?.onChange?.(e, ...params)
          }}
          multiline={multiline}
          displayEmpty
          renderValue={(selected: any) => {
            if (baseValue == null || baseValue.length === 0) {
              return <Placeholder>{placeholder}</Placeholder>
            }

            if ((multiline && selected.length === 0) || selected == null) {
              return <Placeholder>{placeholder}</Placeholder>
            }

            return multiline
              ? selected.join(', ')
              : options.find((it) => it.value === _.toString(selected))?.label
          }}
          fullWidth
          IconComponent={Svg.ArrowDown}
          $isHaveValue={baseValue != null}
          error={error != null}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(clearable && {
            endAdornment: clearableEndAdornment,
            indicator: null,
          })}
          inputProps={
            clearable ? { IconComponent: () => null } : props?.inputProps
          }
        >
          {options.map(({ value, label: optionLabel, disabled }, i) => (
            <MenuItem key={`${value ?? i}`} value={value} disabled={disabled}>
              {optionLabel}
            </MenuItem>
          ))}
        </StyledSelect>
        {isReserverForHelperText && <Error>{error ?? ' '}</Error>}
      </StyledFormControl>
    )
  },
)
