import { Box, Button, Dialog, Grid, styled, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOverlay } from '../../../../../components'
import {
  useActiveDiStrategyMaster,
  Strategy,
  useMasterData,
} from '../../../../../hooks'
import { DIStrategySelection } from '../../../components'

type DIStrategyModalProps = {
  open: boolean
  onClose(): void
  onSubmit: (diStrategyIds: string[] | undefined) => void
  initialStrategyIds?: { diStrategyId: string }[]
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const DIStrategyModal = observer(
  ({ open, onClose, onSubmit, initialStrategyIds }: DIStrategyModalProps) => {
    const { t } = useTranslation()
    const { showSnackbar } = useOverlay()

    const { systemParameters } = useMasterData()

    const maxDIStrategyNumber = useMemo(() => {
      const systemParamRecord = systemParameters.find(
        (it) => it.name === 'max-di-strategy-number',
      )

      if (systemParamRecord == null) return 0

      return systemParamRecord.value_Integer ?? 0
    }, [systemParameters])

    const { activeDiStrategyClasses, activeDiStrategies } =
      useActiveDiStrategyMaster(
        initialStrategyIds != null
          ? initialStrategyIds.map((it) => it.diStrategyId)
          : undefined,
      )

    const initialStrategies = useComputed(
      () =>
        initialStrategyIds
          ?.map((it) =>
            activeDiStrategies
              .slice()
              ?.find((diStrategy) => diStrategy.id == it.diStrategyId),
          )
          .map((it) => ({
            diStrategyId: it?.id,
            order: (it?.order ?? 0) + (it?.diStrategyClass?.order ?? 0),
          })) as Strategy[],
      [],
    )

    const [selectedStrategy, setSelectedStrategy] = useState<
      Strategy[] | undefined
    >(initialStrategies)

    const handleSelectStrategyChange = useCallback((items: Strategy[]) => {
      const sortedItems = items
        .slice()
        .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
      setSelectedStrategy(sortedItems)
    }, [])

    const handleSubmit = useCallback(() => {
      if ((selectedStrategy ?? []).length > maxDIStrategyNumber) {
        showSnackbar({
          message: t(
            'lessonPlan.createForm.generalInformation.teachingStrategyModal.maxNumberWarning',
            { maxDIStrategyNumber: maxDIStrategyNumber },
          ),
          severity: 'warning',
          timeout: 3000,
        })
        return
      }
      onSubmit(selectedStrategy?.map((it) => it.diStrategyId))
    }, [maxDIStrategyNumber, onSubmit, selectedStrategy, showSnackbar, t])

    return (
      <Dialog maxWidth={false} open={open}>
        <StyledBox width={960} p={10} height={600}>
          <Box height={400}>
            <Typography fontSize={22} fontWeight={600}>
              {t(
                'lessonPlan.createForm.generalInformation.teachingStrategyModal.title',
              )}
            </Typography>

            <DIStrategySelection
              selectedStrategies={selectedStrategy ? selectedStrategy : []}
              onSelectStrategies={handleSelectStrategyChange}
              diStrategyOptions={activeDiStrategies}
              diStrategyClasses={activeDiStrategyClasses}
              isMultiSelect
              maxHeight={400}
            />
          </Box>

          <Grid mt={10} container justifyContent="flex-end" columnSpacing={5}>
            <Grid item width={200}>
              <Button variant="outlined" onClick={onClose} fullWidth>
                {t(
                  'lessonPlan.createForm.generalInformation.teachingStrategyModal.actions.close',
                )}
              </Button>
            </Grid>
            <Grid item width={200}>
              <Button fullWidth onClick={handleSubmit}>
                {t(
                  'lessonPlan.createForm.generalInformation.teachingStrategyModal.actions.submit',
                )}
              </Button>
            </Grid>
          </Grid>
        </StyledBox>
      </Dialog>
    )
  },
)
