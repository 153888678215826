import { Box, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import {
  File,
  FileSource,
  LessonPlanDiStrategyResourceType,
} from '@polyu-dip/models'
import { useDiStrategyDetails } from '@polyu-dip/queries'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  CardMedia,
  FullscreenLoading,
  ListItemLink,
  ListItemLinkButton,
  NestedList,
  ScrollableList,
} from '../../components'
import { useBlobStorage } from '../../services'
import { useStores } from '../../stores'

type Props = {
  selectedDiStrategyId?: string
  defaultSelectedDiStrategyId: string
  selectedResourceType?: string
  setSelectedResourceType: Dispatch<
    SetStateAction<LessonPlanDiStrategyResourceType | undefined>
  >
}

const Section = styled(Box)`
  background-color: ${({ theme }) => theme.palettes.general.white};
  border-radius: 30px;
  height: 800px;
  padding: 28px;
  display: flex;
  flex-direction: column;
`

const ResourceContainer = styled.div`
  height: 90%;
`

const DisplayVideoSection = styled.div`
  margin-bottom: 20px;
`

const DetailSection = styled.div`
  height: 50%;
`

const Title = styled(Typography)`
  font-weight: 600;
`
const Content = styled(Typography)`
  margin-top: 12px;
`

export const DiStrategyDetail = observer<Props>(
  ({
    selectedDiStrategyId,
    defaultSelectedDiStrategyId,
    selectedResourceType,
    setSelectedResourceType,
  }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { getDownloadUrl } = useBlobStorage()

    const { data, isLoading } = useDiStrategyDetails(
      useStores,
      selectedDiStrategyId ?? defaultSelectedDiStrategyId,
    )

    const selectedDiStrategyDetail = useComputed(
      () => ({
        ...data,
        displayName: data?.displayName,
        displayDetail: data?.displayDetail,
        diStrategyClass: data?.diStrategyClass,
        diStrategyResources:
          data?.diStrategyResources != null
            ? data.diStrategyResources.map((diStrategyResource) => ({
                ...diStrategyResource,
                file: diStrategyResource.file,
              }))
            : [],
      }),
      [data, isLoading],
    )

    const displayVideoUrl = useComputed(() => {
      return data?.displayVideo?.file?.url ?? undefined
    }, [data?.displayVideo?.file?.url])

    const handleResourceTypeOnClick = useCallback(
      (resourceType: LessonPlanDiStrategyResourceType) => {
        setSelectedResourceType((old) =>
          old === resourceType ? undefined : resourceType,
        )
      },
      [setSelectedResourceType],
    )

    const handleUrlOnClick = useCallback(
      (file?: File) => {
        if (file == null) return
        if (file.source === FileSource.blobStorage) {
          getDownloadUrl(file.id).then((downloadUrl: string) => {
            window.open(downloadUrl, '_blank')
          })
        } else {
          window.open(file.url, '_blank')
        }
      },
      [getDownloadUrl],
    )

    const videoList = useComputed(() => {
      return data?.videos.map((video) => ({
        id: video.id,
        item: (
          <ListItemLink
            label={video.displayName}
            file={video.file}
            handleOnClick={handleUrlOnClick}
            color={theme.colors.accent}
          />
        ),
      }))
    }, [data?.videos, selectedDiStrategyId])

    const furtherReadingAndExamplesList = useComputed(() => {
      return data?.furtherReadingAndExamples.map((video) => ({
        id: video.id,
        item: (
          <ListItemLink
            label={video.displayName}
            file={video.file}
            handleOnClick={handleUrlOnClick}
            color={theme.palettes.semantic.yellow}
          />
        ),
      }))
    }, [data?.videos, selectedDiStrategyId])

    if (isLoading || data == null) return <FullscreenLoading />
    return (
      <Section>
        <ResourceContainer>
          {displayVideoUrl == null ? (
            <></>
          ) : (
            <DisplayVideoSection>
              <CardMedia elementType="iframe" image={displayVideoUrl} />
            </DisplayVideoSection>
          )}
          <DetailSection>
            <Title>
              {t('diStrategy.diStrategyLabel', {
                diStrategyClassLabel:
                  selectedDiStrategyDetail.diStrategyClass?.label,
                diStrategyLabelNumber: selectedDiStrategyDetail.order,
                diStrategyName: selectedDiStrategyDetail.displayName,
              })}
            </Title>
            <Content>{selectedDiStrategyDetail.displayDetail}</Content>
            <ScrollableList $maxHeight={200}>
              {videoList != null && videoList.length > 0 ? (
                <ListItemLinkButton
                  label={t('diStrategy.video')}
                  isActive={
                    selectedResourceType ===
                    LessonPlanDiStrategyResourceType.video
                  }
                  onClick={() =>
                    handleResourceTypeOnClick(
                      LessonPlanDiStrategyResourceType.video,
                    )
                  }
                />
              ) : (
                <></>
              )}
              <NestedList
                dense
                open={
                  selectedResourceType ===
                  LessonPlanDiStrategyResourceType.video
                }
                nestedItems={videoList}
              />
              {furtherReadingAndExamplesList != null &&
              furtherReadingAndExamplesList.length > 0 ? (
                <ListItemLinkButton
                  label={t('diStrategy.furtherReadingAndExamples')}
                  isActive={
                    selectedResourceType ===
                    LessonPlanDiStrategyResourceType.furtherReadingAndExamples
                  }
                  onClick={() =>
                    handleResourceTypeOnClick(
                      LessonPlanDiStrategyResourceType.furtherReadingAndExamples,
                    )
                  }
                />
              ) : (
                <></>
              )}
              <NestedList
                dense
                open={
                  selectedResourceType ===
                  LessonPlanDiStrategyResourceType.furtherReadingAndExamples
                }
                nestedItems={furtherReadingAndExamplesList}
              />
            </ScrollableList>
          </DetailSection>
        </ResourceContainer>
      </Section>
    )
  },
)
