import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOverlay } from '../overlay-provider'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { NewResourceFormData, NewResourceList } from './new-resource-list'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dialog, DialogAction } from '../dialog'
import { FileSource } from '@polyu-dip/models'
import { Grid, Typography } from '@mui/material'
import { SelectOption } from '../form'
import {
  clearResourcesQueryCaches,
  useCreateResources,
  useDisableResource,
  useUpdateResource,
} from '@polyu-dip/queries'
import { useStores } from '../../stores'
import { useApiErrorHandle, useFormErrorTranslationTrigger } from '../../hooks'
import { PutResourcePayload } from '@polyu-dip/apis'
import { useQueryClient } from '@tanstack/react-query'
import { isValidUrl } from '../../utilities'

type CommonProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  resourceTypeOptions: SelectOption[]
}

export type EditResourceDataRowType = {
  id: string
  rowVersion: string
  title: string
  resourceTypeId: string
  url: string
}

type Props =
  | ({
      mode: 'create'
      resourceData?: never
    } & CommonProps)
  | ({
      mode: 'edit'
      resourceData?: EditResourceDataRowType
    } & CommonProps)

type DialogActionButtonValueType = 'create' | 'close' | 'update' | 'delete'

export const OnlineResourceDialog = observer<Props>(
  ({ isOpen, setIsOpen, resourceTypeOptions, mode, resourceData }) => {
    const { t } = useTranslation()
    const { showSpinner, hideSpinner, showSnackbar } = useOverlay()
    const { standardErrorHandler } = useApiErrorHandle()
    const queryClient = useQueryClient()

    const [canSubmit, setCanSubmit] = useState(false)
    const [canUpdate, setCanUpdate] = useState(false)
    const [canDelete, setCanDelete] = useState(false)

    const schema = useMemo(
      () =>
        yup.object({
          newResources: yup.array(
            yup.object({
              title: yup.string().required(t('error.required')),
              resourceTypeId: yup.string().required(t('error.required')),
              url: yup
                .string()
                .required(t('error.required'))
                .url(t('error.invalidUrlFormat')),
            }),
          ),
        }),
      [t],
    )

    const defaultNewResources = useMemo(
      () =>
        Array(5).fill({
          title: undefined,
          resourceTypeId: undefined,
          url: undefined,
        }),
      [],
    )

    const {
      control,
      formState: { errors },
      getValues,
      trigger,
      reset,
      clearErrors,
      watch,
    } = useForm<NewResourceFormData>({
      resolver: yupResolver(schema),
      defaultValues: {
        newResources: defaultNewResources,
      },
    })

    useFormErrorTranslationTrigger(errors, trigger)

    useEffect(() => {
      if (mode === 'create') {
        reset({
          newResources: defaultNewResources,
        })
      } else if (mode === 'edit') {
        reset({
          newResources: [
            {
              title: resourceData?.title,
              resourceTypeId: resourceData?.resourceTypeId,
              url: resourceData?.url,
            },
          ],
        })

        // enable delete button
        if (
          resourceData?.title != null &&
          resourceData?.url != null &&
          resourceData?.resourceTypeId != null
        ) {
          setCanDelete(true)
        }
      }
    }, [
      defaultNewResources,
      mode,
      reset,
      resourceData?.resourceTypeId,
      resourceData?.title,
      resourceData?.url,
    ])

    const onlineResourceDialogActions =
      useMemo((): DialogAction<DialogActionButtonValueType>[] => {
        if (mode === 'create') {
          return [
            {
              text: t('common.close'),
              type: 'outlined',
              value: 'close',
            },
            {
              text: t('resources.createOnlineResource.action.create'),
              type: 'contained',
              value: 'create',
              color: 'blue',
              disabled: !canSubmit,
            },
          ]
        }
        return [
          {
            text: t('common.close'),
            type: 'outlined',
            value: 'close',
          },
          {
            text: t('resources.editOnlineResource.action.delete'),
            type: 'contained',
            value: 'delete',
            color: 'sharpRed',
            disabled: !canDelete,
          },
          {
            text: t('resources.editOnlineResource.action.update'),
            type: 'contained',
            value: 'update',
            color: 'blue',
            disabled: !canUpdate,
          },
        ]
      }, [mode, t, canUpdate, canDelete, canSubmit])

    const handleOnClose = useCallback(() => {
      reset()
      clearErrors()
      setIsOpen(false)
    }, [clearErrors, reset, setIsOpen])

    const { mutateAsync: createResources } = useCreateResources(useStores, {
      onSuccess: () => {
        handleOnClose()
        showSnackbar({
          message: t('resources.addResources.success'),
        })
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('resources.addResources.error'),
        })
      },
    })

    const { mutateAsync: updateResource } = useUpdateResource(
      useStores,
      resourceData?.id ?? '',
      {
        onSuccess: () => {
          clearResourcesQueryCaches(queryClient)
          handleOnClose()
          showSnackbar({
            message: t('resources.updateResources.success'),
          })
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t('resources.updateResources.error'),
          })
        },
      },
    )

    const { mutateAsync: disableResource } = useDisableResource(
      useStores,
      resourceData?.id ?? '',
      {
        onSuccess: () => {
          clearResourcesQueryCaches(queryClient)
          handleOnClose()
          showSnackbar({
            message: t('resources.deleteResources.success'),
          })
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t('resources.deleteResources.error'),
          })
        },
      },
    )

    const processCreateModeAction = useCallback(async () => {
      const formData = getValues('newResources')
      const hasUnFinishedField = formData.some(
        (data) =>
          !(
            (data?.title == null || data.title?.length === 0) &&
            (data?.url == null || data.url?.length === 0) &&
            (data?.resourceTypeId == null || data.resourceTypeId?.length === 0)
          ) &&
          !(
            data?.title?.length > 0 &&
            data?.url?.length > 0 &&
            isValidUrl(data.url) &&
            data?.resourceTypeId?.length > 0
          ),
      )
      if (hasUnFinishedField && !(await trigger())) return

      const payload = formData
        .filter((data) => data.title != null)
        .map(({ title, url, resourceTypeId }) => ({
          title,
          resourceTypeId,
          isOnlineResource: true,
          file: {
            url,
            source: FileSource.user,
          },
        }))

      await createResources(payload)
    }, [createResources, getValues, trigger])

    const processEditModeAction = useCallback(
      async (action: DialogActionButtonValueType) => {
        if (action === 'delete') {
          await disableResource(resourceData?.rowVersion ?? '')
          return
        }
        const updatedResourceData = getValues('newResources')
        if (!(await trigger())) return
        const formData = updatedResourceData[0]
        const payload: PutResourcePayload = {
          rowVersion: resourceData?.rowVersion ?? '',
          isOnlineResource: true,
          subjectId: null,
          classLevelId: null,
          isDisabled: false,

          title: formData.title,
          resourceTypeId: formData.resourceTypeId,
          file: {
            url: formData.url ?? '',
            source: FileSource.user,
          },
        }
        await updateResource(payload)
      },
      [
        disableResource,
        getValues,
        resourceData?.rowVersion,
        trigger,
        updateResource,
      ],
    )

    const handleOnSelectedDialogAction = useCallback(
      async (value: DialogActionButtonValueType) => {
        if (value === 'close' || value == null) {
          handleOnClose()
          return
        }

        try {
          showSpinner()

          if (mode === 'create') {
            await processCreateModeAction()
          } else {
            await processEditModeAction(value)
          }
        } finally {
          hideSpinner()
        }
      },
      [
        handleOnClose,
        hideSpinner,
        mode,
        processCreateModeAction,
        processEditModeAction,
        showSpinner,
      ],
    )

    useEffect(() => {
      const subscription = watch(async () => {
        clearErrors()
        const shouldEnableSubmitButtons = getValues('newResources')?.some(
          (data) =>
            data?.title?.length > 0 ||
            data?.url?.length > 0 ||
            data?.resourceTypeId?.length > 0,
        )
        setCanUpdate(shouldEnableSubmitButtons)
        setCanSubmit(shouldEnableSubmitButtons)
      })
      return () => subscription.unsubscribe()
    }, [clearErrors, getValues, watch])

    const dialogHeaderTitle = useMemo(() => {
      return mode === 'create'
        ? t('resources.createOnlineResource.title')
        : t('resources.editOnlineResource.title')
    }, [mode, t])

    const allowDialogAppendAndDeleteRow = useMemo(() => {
      return mode === 'create' ? true : false
    }, [mode])

    return (
      <Dialog
        dismissable
        open={isOpen}
        title={dialogHeaderTitle}
        actions={onlineResourceDialogActions}
        onSelected={handleOnSelectedDialogAction}
        maxWidth="md"
        fullWidth
      >
        <Grid container direction="column" wrap="nowrap">
          <Grid container direction="row">
            <Grid item xs={5}>
              <Typography>
                {t('resources.createOnlineResource.field.title.label')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {t('resources.createOnlineResource.field.url.label')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {t('resources.createOnlineResource.field.type.label')}
              </Typography>
            </Grid>
          </Grid>
          <NewResourceList
            control={control}
            errors={errors}
            resourceTypeOptions={resourceTypeOptions}
            allowAppendRow={allowDialogAppendAndDeleteRow}
            allowDeleteRow={allowDialogAppendAndDeleteRow}
          />
        </Grid>
      </Dialog>
    )
  },
)
