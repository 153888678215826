import { Box, Grid } from '@mui/material'
import { FileSource, LessonPlanDiStrategyResourceType } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import {
  Control,
  Controller,
  FieldErrorsImpl,
  useFieldArray,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Svg } from '../../assets'
import { TextField } from '../form'
import { NewFormData } from './master-data-list'

type Props = {
  control: Control<NewFormData, any>
  type: 'videos' | 'furtherReadingAndExamples'
  errors?: Partial<FieldErrorsImpl<NewFormData>>
}

export const ResourceList = observer<Props>(({ control, type, errors }) => {
  const { t } = useTranslation()
  const fieldName = useMemo(
    () =>
      type === 'videos'
        ? 'newData.0.videos'
        : 'newData.0.furtherReadingAndExamples',
    [type],
  )
  const {
    fields: newResourceDataFields,
    remove: removeResourceDataField,
    append: appendNewResourceDataField,
  } = useFieldArray({
    control: control,
    name: fieldName,
  })
  return (
    <Box>
      {newResourceDataFields.length > 0 && (
        <Grid container direction="row">
          <Grid item xs={3.5}>
            {t('masterData.editDiStrategyDetailDialog.field.chineseName.label')}
          </Grid>
          <Grid item xs={3.5}>
            {t('masterData.editDiStrategyDetailDialog.field.englishName.label')}
          </Grid>
          <Grid item xs={4}>
            {t('masterData.editDiStrategyDetailDialog.field.url.label')}
          </Grid>
        </Grid>
      )}
      {newResourceDataFields.map((newResourceDataField, index) => (
        <Grid key={newResourceDataField.id} container direction="row" mt={1}>
          <Grid item xs={3.5} pr={5}>
            <Controller
              control={control}
              name={`${fieldName}.${index}.chineseName`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  placeholder={t(
                    'masterData.editDiStrategyDetailDialog.field.chineseName.placeholder',
                  )}
                  error={
                    (errors?.newData?.[0]?.[type] as any)?.[index]?.chineseName
                      ?.message
                  }
                  isReserverForHelperText
                />
              )}
            />
          </Grid>
          <Grid item xs={3.5} pr={5}>
            <Controller
              control={control}
              name={`${fieldName}.${index}.englishName`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  placeholder={t(
                    'masterData.editDiStrategyDetailDialog.field.englishName.placeholder',
                  )}
                  error={
                    (errors?.newData?.[0]?.[type] as any)?.[index]?.englishName
                      ?.message
                  }
                  isReserverForHelperText
                />
              )}
            />
          </Grid>
          <Grid item xs={4} pr={5}>
            <Controller
              control={control}
              name={`${fieldName}.${index}.file.url`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  placeholder={t(
                    'masterData.editDiStrategyDetailDialog.field.url.placeholder',
                  )}
                  error={
                    (errors?.newData?.[0]?.[type] as any)?.[index]?.file?.url
                      ?.message
                  }
                  isReserverForHelperText
                />
              )}
            />
          </Grid>
          <Grid container justifyContent="flex-end" xs={1}>
            <Grid item>
              <Svg.Close
                cursor="pointer"
                onClick={() => removeResourceDataField(index)}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Svg.Plus
            cursor="pointer"
            onClick={() =>
              appendNewResourceDataField({
                chineseName: undefined,
                englishName: undefined,
                order: newResourceDataFields.length,
                resourceType:
                  type === 'videos'
                    ? LessonPlanDiStrategyResourceType.video
                    : LessonPlanDiStrategyResourceType.furtherReadingAndExamples,
                file: {
                  url: undefined,
                  source: FileSource.user,
                },
                id: undefined,
              })
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
})
