export const submissionRecords = {
  title: 'Submission record',

  tabs: {
    primarySchool: 'Primary school',
    secondarySchool: 'Secondary school',
  },

  tableFields: {
    schoolName: 'Name of school',
    createdDateTime: 'Participation date',
    totalMyLessonPlan: 'Total number of my lesson plan(s)',
    totalUser: 'Total number of users',
    totalLessonPlanPerUser: 'Total number of lesson plans /per person',
    consultationRate: 'Consultation rate',
    totalLessonPlanExample: 'Total number of DI examples',
    latestLessonPlanSubmissionDateTime: 'Last submission date',
  },

  pagination: {
    more: 'More',
  },
}
