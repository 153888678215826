import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { NameModel } from './languages-json'
import { TagType } from './tag-type.model'
// eslint-disable-next-line import/no-cycle

export const TagGroupModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    name: types.string,
    tagTypeId: types.string,
    isDisabled: types.boolean,
    isMultiSelect: types.boolean,
    order: types.number,
    colorCode: types.string,
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .named('TagGroupModel')
  .views((self) => ({
    get tagType() {
      if (self.tagTypeId == null) return undefined
      return (
        self.rootStore.masterDataStore as { tagTypes: TagType[] }
      ).tagTypes.find((it) => it.id === self.tagTypeId)
    },
  }))

export type TagGroup = Instance<typeof TagGroupModel>
