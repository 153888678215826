import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { NameModel } from './languages-json'

export enum SystemParameterDataType {
  integer = 'value_Integer',
  decimal = 'value_Decimal',
  dateTime = 'value_DateTime',
  text = 'value_Text',
  boolean = 'value_Boolean',
}

export const SystemParameterModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    description: types.maybe(types.string),
    category: types.maybe(types.string),
    dataType: types.maybe(types.string),
    value_Integer: types.maybe(types.number),
    value_Decimal: types.maybe(types.number),
    value_DateTime: types.maybe(types.string),
    value_Text: types.maybe(types.string),
    value_Boolean: types.maybe(types.boolean),
  })
  .extend(withUpdate)
  .named('SystemParameterModel')

export type SystemParameter = Instance<typeof SystemParameterModel>
