import {
  ButtonBase,
  CircularProgress,
  StepIconProps as MuiStepIconProps,
  Typography,
} from '@mui/material'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { Svg } from '../../assets'

const StyledStepIcon = styled(ButtonBase)<{
  isActive?: boolean
  isCompleted?: boolean
  isLoading?: boolean
}>`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 4px solid
    ${({ isActive, isCompleted, isLoading, theme }) =>
      // eslint-disable-next-line no-nested-ternary
      isCompleted && !isLoading
        ? theme.palettes.general.blue
        : isActive
        ? 'rgba(53, 114, 183, 0.6)'
        : theme.palettes.general.background};
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${({ theme, isCompleted, isLoading }) =>
    isCompleted && !isLoading
      ? theme.palettes.general.white
      : theme.palettes.general.greys[2]};
  background-color: ${({ theme, isCompleted, isLoading }) =>
    isCompleted && !isLoading
      ? theme.palettes.general.blue
      : theme.palettes.general.white};
`

export type StepIconProps = {
  handleStepClick: (step: number) => void
  step: number
  isLoading?: boolean
  prevStep?: number
} & MuiStepIconProps

export const StepIcon = memo<StepIconProps>(
  ({
    itemID,
    active,
    completed,
    isLoading,
    prevStep,
    step,
    handleStepClick,
  }) => {
    const isCurrentStepLoading = useMemo(() => {
      if (isLoading !== true || prevStep == null) return false
      return Math.floor(step) === Math.floor(prevStep)
    }, [isLoading, prevStep, step])
    return (
      <StyledStepIcon
        isActive={active}
        isCompleted={completed}
        onClick={() => handleStepClick(step)}
        isLoading={isCurrentStepLoading}
      >
        {isCurrentStepLoading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            {completed ? (
              <Svg.Tick width={24} height={24} />
            ) : (
              <Typography fontSize="inherit">{itemID}</Typography>
            )}
          </>
        )}
      </StyledStepIcon>
    )
  },
)
