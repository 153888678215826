import { ButtonBase, Link } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { File, Resource } from '@polyu-dip/models'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { Tag, TruncatedCellContent } from '../../components'

export enum ResourceTabMenu {
  onlineResources = 'ONLINE_RESOURCES',
  downloadableResources = 'DOWNLOADABLE_RESOURCES',
}

type TabType = 'ONLINE_RESOURCES' | 'DOWNLOADABLE_RESOURCES'

const StyledLink = styled(Link)`
  cursor: pointer;
  width: 100%;
`

export function useResourcesColumn() {
  const { t } = useTranslation()

  const renderColumn = useCallback(
    (
      isManagementPage: boolean,
      tabType?: TabType,
      handleTitleOnClick?: (url: string) => void,
      handleDownloadOnClick?: (file?: File) => void,
    ) => {
      const downloadableResourcesColumn: GridColDef<Resource>[] = [
        {
          field: 'classLevel.learningPhase.order',
          headerName: t('resources.tableFields.learningPhase'),
          width: 100,
          renderCell: ({ row }) =>
            row.learningPhase == null ? (
              '---'
            ) : (
              <Tag
                label={row.learningPhase.displayName}
                colorCustomize={row.learningPhase.colorCode}
              />
            ),
        },
        {
          field: 'classLevel.order',
          headerName: t('resources.tableFields.classLevel'),
          width: 100,
          renderCell: ({ row }) =>
            row.classLevel == null ? (
              '---'
            ) : (
              <Tag
                label={row.classLevel.displayName}
                colorCustomize={row.classLevel.colorCode}
              />
            ),
        },
        {
          field: 'subject',
          headerName: t('resources.tableFields.subject'),
          width: 200,
          renderCell: ({ row }) =>
            row.subject == null ? (
              '---'
            ) : (
              <Tag
                label={row.subject.displayName}
                colorCustomize={row.subject.colorCode}
              />
            ),
        },
      ]

      const downloadColumn: GridColDef<Resource>[] = [
        {
          field: 'type',
          headerName: t('resources.tableFields.download'),
          width: 100,
          renderCell: ({ row }) =>
            row.file == null ? (
              '---'
            ) : (
              <ButtonBase>
                <Svg.Download
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDownloadOnClick?.(row.file)
                  }}
                />
              </ButtonBase>
            ),
          sortable: false,
        },
      ]

      return [
        {
          field: 'title',
          headerName: t('resources.tableFields.title'),
          width: tabType === ResourceTabMenu.onlineResources ? 650 : 300,
          renderCell: ({ row }: { row: Resource }) =>
            tabType === ResourceTabMenu.onlineResources ? (
              <StyledLink
                onClick={(e) => {
                  e.stopPropagation()
                  if (row?.file?.url == null) return
                  handleTitleOnClick?.(row.file.url)
                }}
              >
                <TruncatedCellContent content={row.title} />
              </StyledLink>
            ) : (
              <TruncatedCellContent content={row.title} />
            ),
        },
        ...(tabType === ResourceTabMenu.downloadableResources
          ? downloadableResourcesColumn
          : []),
        {
          field: 'resourceType',
          headerName: t('resources.tableFields.resourceType'),
          width: 200,
          renderCell: ({ row }: { row: Resource }) =>
            row.resourceType?.displayName,
        },
        {
          field: 'lastModifiedDateTime',
          headerName: t('resources.tableFields.lastModifiedDateTime'),
          width: 200,
          renderCell: ({ row }: { row: Resource }) =>
            row.lastModifiedDateTime
              ? dayjs(row.lastModifiedDateTime).format(
                  t('common.format.datetime'),
                )
              : '---',
        },
        ...(!isManagementPage &&
        tabType === ResourceTabMenu.downloadableResources
          ? downloadColumn
          : []),
      ]
    },
    [t],
  )

  return { renderColumn }
}
