import { Button, Grid } from '@mui/material'
import { PostLessonPlanPayload, PutLessonPlanPayload } from '@polyu-dip/apis'
import { emptyLessonPlan, LessonPlan, LessonPlanModel } from '@polyu-dip/models'
import dayjs from 'dayjs'
import { useCreateLessonPlan, useUpdateLessonPlan } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useStores } from '../../../stores'
import { DiStrategiesTab } from './di-strategies'
import { GeneralInformation } from './general-information'
import { ShareAndRequestPublish } from './share-and-request-publish'
import { UploadAttachmentTab } from './upload-attachment-tab'
import { LessonPlanFormContext } from '../use-lesson-plan-form-context'
import { useComputed } from '@polyu-dip/helpers'
import { Stepper, StepType, useOverlay } from '../../../components'
import { LessonPlanFormProvider } from '../lesson-plan-form-provider'
import { contentPaths } from '../../../content-paths'
import { useApiErrorHandle } from '../../../hooks/'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

const Container = styled.div``

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palettes.general.white};
`

export type FormStep =
  | 'GENERAL_INFORMATION'
  | 'DI_STRATEGIES'
  | 'UPLOAD_DOCUMENT'
  | 'SHARE_AND_REQUEST_PUBLISH'

type Props = {
  saveAndViewNavigatingPath?: string
  isExample?: boolean
  currentUserEducationLevelId?: string
  targetLessonPlan?: LessonPlan
}

export const LessonPlanForm = observer<Props>(
  ({
    isExample,
    currentUserEducationLevelId,
    targetLessonPlan,
    saveAndViewNavigatingPath,
  }) => {
    const { t } = useTranslation()
    const { userProfileStore } = useStores()
    const { showSpinner, hideSpinner, showSnackbar } = useOverlay()
    const { standardErrorHandler } = useApiErrorHandle()
    const navigate = useNavigate()

    const childRef = useRef<any>(null)
    const [activeStep, setActiveStep] = useState<number>(0.1)
    const [prevStep, setPrevStep] = useState<number>(0.1)
    const [completedSteps, setCompletedSteps] = useState<number[]>([])
    const [hasUploadError, setHasUploadError] = useState(false)

    const triggerValidation = useCallback(async () => {
      const isCompleted = await childRef?.current?.triggerValidation?.()
      return isCompleted
    }, [])

    const trimArrayFields = useCallback(() => {
      return childRef?.current?.trimArrayFields?.()
    }, [])

    const getGeneralInformationValues = useCallback(() => {
      return childRef?.current?.getGeneralInformationValues?.()
    }, [])

    const getDiStrategyValues = useCallback(() => {
      return childRef?.current?.getDiStrategyValues?.()
    }, [])

    const getUploadedAttachmentValues = useCallback(() => {
      return childRef?.current?.getUploadedAttachmentValues?.()
    }, [])

    const draftLessonPlan = useComputed(() => {
      return LessonPlanModel.create({
        ...emptyLessonPlan,
        authorUserId: userProfileStore.userProfile?.id ?? '',
        academicYear: dayjs().get('year'),
        lessonPlanAttachments: [],
        lessonPlanStudentGroups: [],
        lessonPlanDiStrategies: [],
        lessonPlanTags: [],
      })
    }, [userProfileStore.userProfile?.id])

    const [lessonPlanFormData, setLessonPlanFormData] = useState(
      targetLessonPlan ?? draftLessonPlan,
    )

    const {
      mutateAsync: createLessonPlan,
      isLoading: isCreateLessonPlanLoading,
    } = useCreateLessonPlan(useStores, {
      onSuccess(res) {
        setLessonPlanFormData(res)
        window.history.replaceState(
          null,
          '',
          contentPaths.lessonPlans(res.id, 'edit'),
        )
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('lessonPlan.errorMessage.createLessonPlan.title'),
        })
      },
    })

    const {
      mutateAsync: updateLessonPlan,
      isLoading: isUpdateLessonPlanLoading,
    } = useUpdateLessonPlan(useStores, lessonPlanFormData.id, {
      onSuccess(res) {
        setLessonPlanFormData(res)
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('lessonPlan.errorMessage.updateLessonPlan.title'),
        })
      },
    })

    const steps = useMemo(
      (): StepType[] => [
        {
          id: 0,
          numberToDisplay: 1,
          subSteps: [
            {
              id: 0.1,
              tooltipTitle: t(
                'lessonPlan.createForm.generalInformation.tabs.overview',
              ),
            },
            {
              id: 0.2,
              tooltipTitle: t(
                'lessonPlan.createForm.generalInformation.tabs.learningTarget',
              ),
            },
            {
              id: 0.3,
              tooltipTitle: t(
                'lessonPlan.createForm.generalInformation.tabs.advancedKnowledge',
              ),
            },
            {
              id: 0.4,
              tooltipTitle: t(
                'lessonPlan.createForm.generalInformation.tabs.teachingProcess',
              ),
            },
          ],
          name: 'GENERAL_INFORMATION',
          label: t('lessonPlan.createForm.steps.generalInformation'),
        },
        {
          id: 1,
          numberToDisplay: 2,
          name: 'DI_STRATEGIES',
          label: t('lessonPlan.createForm.steps.diStrategies'),
        },
        {
          id: 2,
          numberToDisplay: 3,
          name: 'UPLOAD_DOCUMENT',
          label: t('lessonPlan.createForm.steps.uploadDocument'),
        },

        {
          id: 3,
          numberToDisplay: 4,
          name: 'SHARE_AND_REQUEST_PUBLISH',
          label: isExample
            ? t('lessonPlan.createForm.steps.additionalInfo')
            : t('lessonPlan.createForm.steps.shareAndRequestPublish'),
        },
      ],
      [isExample, t],
    )

    const handleSave = useCallback(async () => {
      try {
        const hasGeneralInformationErrorMessages =
          activeStep < 1 && !(await triggerValidation())
        if (activeStep < 1) {
          await trimArrayFields()
        }
        if (hasGeneralInformationErrorMessages) return
        const payload = {
          ...lessonPlanFormData,
          lessonPlanAttachments:
            lessonPlanFormData.lessonPlanAttachments == null
              ? []
              : lessonPlanFormData.lessonPlanAttachments,
          lessonPlanStudentGroups:
            lessonPlanFormData.lessonPlanStudentGroups == null
              ? []
              : lessonPlanFormData.lessonPlanStudentGroups.map(
                  (lessonPlanStudent) => ({
                    ...lessonPlanStudent,
                    isLessonPlanMultiGroup: lessonPlanFormData.isMultiGroup,
                  }),
                ),
          lessonPlanDiStrategies:
            lessonPlanFormData.lessonPlanDiStrategies == null
              ? []
              : lessonPlanFormData.lessonPlanDiStrategies,
          lessonPlanTags:
            lessonPlanFormData.lessonPlanTags == null
              ? []
              : lessonPlanFormData.lessonPlanTags,
        }
        if (lessonPlanFormData.id.length > 0) {
          const generalInformationValues = getGeneralInformationValues()
          const diStrategyValues = getDiStrategyValues()
          const uploadedAttachmentValues = getUploadedAttachmentValues()
          await updateLessonPlan({
            ...payload,
            ...generalInformationValues,
            ...diStrategyValues,
            lessonPlanAttachments:
              uploadedAttachmentValues ??
              payload.lessonPlanAttachments.map((lessonPlanAttachment) => ({
                ...lessonPlanAttachment,
                file: lessonPlanAttachment.file,
              })),
          } as PutLessonPlanPayload)
        } else {
          if (isCreateLessonPlanLoading) return
          await createLessonPlan(payload as any as PostLessonPlanPayload)
        }
      } catch (err) {
        console.error(err)
      }
    }, [
      activeStep,
      createLessonPlan,
      getDiStrategyValues,
      getGeneralInformationValues,
      getUploadedAttachmentValues,
      isCreateLessonPlanLoading,
      lessonPlanFormData,
      triggerValidation,
      trimArrayFields,
      updateLessonPlan,
    ])

    const handleStep = useCallback(
      async (stepDifference: number) => {
        if (activeStep < 1) {
          const generalInformationChecker = await triggerValidation()
          if (!generalInformationChecker) return
        }
        const stepIds = steps.flatMap((it) =>
          it.subSteps != null
            ? it.subSteps.map((subSteps) => subSteps.id)
            : it.id,
        )
        const nextStepId = stepIds.findIndex((stepId) => stepId == activeStep)
        setPrevStep(activeStep)
        setActiveStep(stepIds[nextStepId + stepDifference])
        setCompletedSteps(_.uniq([...completedSteps, activeStep]))
      },
      [activeStep, completedSteps, steps, triggerValidation],
    )

    const handleStepClick = useCallback(
      async (step: number) => {
        if (isCreateLessonPlanLoading || isUpdateLessonPlanLoading) return
        if (activeStep < 1) {
          const check = await triggerValidation()
          if (!check) return
        }
        if (activeStep === 2 && hasUploadError) return
        setPrevStep(activeStep)
        setActiveStep(step)
        setCompletedSteps(_.uniq([...completedSteps, activeStep]))
        await handleSave()
      },
      [
        isCreateLessonPlanLoading,
        isUpdateLessonPlanLoading,
        activeStep,
        hasUploadError,
        completedSteps,
        handleSave,
        triggerValidation,
      ],
    )

    const handleBackOnClick = useCallback(() => {
      if (hasUploadError) return
      handleStep(-1)
    }, [handleStep, hasUploadError])

    const handleSaveAndContinueOnClick = useCallback(async () => {
      if (hasUploadError) return
      handleStep(1)
      await handleSave()
    }, [handleSave, handleStep, hasUploadError])

    const handleSaveAndViewOnClick = useCallback(async () => {
      if (hasUploadError) return
      try {
        showSpinner()
        await handleSave()
        if (lessonPlanFormData.id == null) return
        if (isExample) {
          showSnackbar({
            message: t('lessonPlan.createForm.saveSuccess'),
          })
          navigate(
            saveAndViewNavigatingPath ??
              contentPaths.lessonPlanExampleManagement(
                lessonPlanFormData.id,
                'view',
              ),
          )
        } else {
          navigate(
            saveAndViewNavigatingPath ??
              contentPaths.lessonPlans(lessonPlanFormData.id, 'view'),
          )
        }
      } finally {
        hideSpinner()
      }
    }, [
      hasUploadError,
      showSpinner,
      handleSave,
      lessonPlanFormData.id,
      isExample,
      showSnackbar,
      t,
      navigate,
      saveAndViewNavigatingPath,
      hideSpinner,
    ])

    return (
      <LessonPlanFormContext.Provider
        value={{
          lessonPlanFormData,
          setLessonPlanFormData,
        }}
      >
        <LessonPlanFormProvider
          currentUserEducationLevelId={currentUserEducationLevelId}
        >
          <Container>
            <Stepper
              steps={steps}
              activeStep={activeStep}
              handleStepClick={handleStepClick}
              isNonLinear
              prevStep={prevStep}
              isLoading={isCreateLessonPlanLoading || isUpdateLessonPlanLoading}
              completedSteps={completedSteps}
            />

            {activeStep < 1 && (
              <GeneralInformation
                ref={childRef}
                activeStep={activeStep}
                setActiveStep={handleStepClick}
              />
            )}
            {activeStep === 1 && <DiStrategiesTab ref={childRef} />}
            {activeStep === 2 && (
              <UploadAttachmentTab
                ref={childRef}
                setHasUploadError={setHasUploadError}
              />
            )}
            {activeStep === 3 && (
              <ShareAndRequestPublish isExample={isExample} />
            )}

            <Grid container justifyContent="flex-end" columnSpacing={5}>
              {activeStep > 0.1 && (
                <Grid item width={190}>
                  <StyledButton
                    fullWidth
                    color="error"
                    variant="outlined"
                    onClick={handleBackOnClick}
                  >
                    {t('lessonPlan.actions.return')}
                  </StyledButton>
                </Grid>
              )}
              {activeStep > 0.1 &&
                lessonPlanFormData.id != null &&
                lessonPlanFormData.id != '' && (
                  <>
                    <Grid item width={190}>
                      <Button
                        fullWidth
                        color="astronautBlue"
                        onClick={handleSaveAndViewOnClick}
                        disabled={
                          isCreateLessonPlanLoading || isUpdateLessonPlanLoading
                        }
                      >
                        {t('lessonPlan.actions.saveAndView')}
                      </Button>
                    </Grid>
                  </>
                )}
              {activeStep < steps.length - 1 && (
                <>
                  <Grid item width={190}>
                    <Button
                      fullWidth
                      color="blue"
                      onClick={handleSaveAndContinueOnClick}
                      disabled={
                        isCreateLessonPlanLoading || isUpdateLessonPlanLoading
                      }
                    >
                      {t('lessonPlan.actions.saveAndContinue')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </LessonPlanFormProvider>
      </LessonPlanFormContext.Provider>
    )
  },
)
