import {
  PaginatedPayload,
  PostSystemParameterPayload,
  PutSystemParameterPayload,
  SystemParametersQueryParameters,
} from '@polyu-dip/apis'
import { SystemParameter } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'system-parameters'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useSystemParameters(
  useStores: UseStoresType,
  param?: SystemParametersQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<SystemParameter>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getSystemParameters(param),
    opts,
  )
}

export function useCreateSystemParameters(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostSystemParameterPayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payloads) => masterDataStore.createSystemParameters(payloads),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useUpdateSystemParameter(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    SystemParameter,
    unknown,
    PutSystemParameterPayload
  >,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => masterDataStore.updateSystemParameter(payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}
