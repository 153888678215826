import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { types } from 'mobx-state-tree'
import { ClassLevel } from '../class-level.model'
import { Subject } from '../subject.model'
import { User } from '../user.model'

export const BaseLessonPlanModel = types
  .model('BaseLessonPlan', {
    id: types.string,
    ownedByUserId: types.string,
    authorUserId: types.string,
    code: types.maybe(types.string),
    consultDisplayStatus: types.maybe(
      types.union(
        types.literal('noPendingRequest'),
        types.literal('pending'),
        types.literal('completed'),
        types.literal('followingUp'),
      ),
    ),
    publishDisplayStatus: types.maybe(
      types.union(
        types.literal('publishing'),
        types.literal('noPendingRequest'),
        types.literal('pending'),
        types.literal('cancelled'),
        types.literal('approved'),
        types.literal('rejected'),
        types.literal('completed'),
      ),
    ),
    subjectId: types.string,
    learningUnit: types.maybe(types.string),
    classLevelId: types.string,
    teachingClass: types.maybe(types.string),
    teachingTopic: types.string,
    sectionDuration: types.maybe(types.number),
    sectionTotal: types.maybe(types.number),
    academicYear: types.maybe(types.number),
    requiredKnowledge: types.maybe(types.string),
    expectedDifficulty: types.maybe(types.string),
    remark: types.maybe(types.string),
    followUp: types.maybe(types.string),
    selfLearningResource: types.maybe(types.string),
    diStrategyTeachingConceptExcerpt: types.maybe(types.string),
    schoolBackgroundInformation: types.maybe(types.string),
    isAgreePublish: types.maybe(types.boolean),
    isMultiGroup: types.boolean,
    isDisabled: types.boolean,
    isCompleted: types.boolean,
    originLessonPlanId: types.maybe(types.string),
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .views((self) => ({
    get ownedByUser() {
      if (self.ownedByUserId == null) return undefined
      return (self.rootStore.usersStore as { users: User[] })?.users.find(
        (user) => user.id === self.ownedByUserId,
      )
    },
    get teacherUser() {
      if (self.authorUserId == null) return undefined
      return (self.rootStore.usersStore as { users: User[] })?.users.find(
        (user) => user.id === self.authorUserId,
      )
    },
    get subject() {
      if (self.subjectId == null) return undefined
      return (
        self.rootStore.masterDataStore as {
          subjects: Subject[]
        }
      ).subjects.find((subject) => subject.id === self.subjectId)
    },
    get classLevel() {
      if (self.classLevelId == null) return undefined
      return (
        self.rootStore.masterDataStore as {
          classLevels: ClassLevel[]
        }
      ).classLevels.find((classLevel) => classLevel.id === self.classLevelId)
    },
  }))
