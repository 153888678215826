import { Box, Typography } from '@mui/material'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Tab, TabPanel, Tabs } from '../../../../components'
import { AttachmentTab } from './attachment-tab'
import { DiStrategiesTab } from './di-strategies-tab'
import { TeachingPlanTab } from './teaching-plan-tab'
import { SchoolBackgroundTab } from './school-background-tab'
import { DesignExplanationTab } from './design-explanation-tab'

const Container = styled(Box)``

const PrintingRoot = styled.div`
  margin-bottom: 16px;
`

const Content = styled(Box)<{ printable?: boolean }>`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding: 28px;
  box-shadow: ${({ printable, theme }) =>
    printable ? `0 0 1px ${theme.palettes.general.borderStroke}` : ''};
`

type DetailTabProps = {
  lessonPlanId: string
  printable?: boolean
  isExample?: boolean
  isHideTeachingClass?: boolean
}

export const DetailTabs = observer<DetailTabProps>(
  ({ lessonPlanId, printable, isExample, isHideTeachingClass }) => {
    const [activeMenu, setActiveMenu] = useState<string>('TEACHING_PLAN')
    const { t } = useTranslation()
    const theme = useTheme()

    const tabMenu = useMemo(() => {
      const tabItems = [
        {
          id: 'TEACHING_PLAN',
          label: t('lessonPlan.detail.general.menuTab.teachingPlan'),
          render: (
            <TeachingPlanTab
              printable={printable}
              isExample={isExample}
              isHideTeachingClass={isHideTeachingClass}
            />
          ),
        },
        {
          hidden: !isExample,
          id: 'SCHOOL_BACKGROUND',
          label: t('lessonPlan.detail.general.menuTab.schoolBackground'),
          render: <SchoolBackgroundTab />,
        },
        {
          hidden: isExample,
          id: 'DI_STRATEGY',
          label: t('lessonPlan.detail.general.menuTab.diStrategies'),
          render: <DiStrategiesTab printable={printable} />,
        },
        {
          hidden: !isExample,
          id: 'DESIGN_EXPLANATION',
          label: t('lessonPlan.detail.general.menuTab.designExplanation'),
          render: <DesignExplanationTab />,
        },
        {
          id: 'ADDITIONAL_TEXTBOOK',
          label: t('lessonPlan.detail.general.menuTab.additionalTextbook'),
          render: (
            <AttachmentTab printable={printable} lessonPlanId={lessonPlanId} />
          ),
        },
      ]

      return tabItems.filter((tabItem) => !tabItem?.hidden)
    }, [isExample, lessonPlanId, printable, t, isHideTeachingClass])

    return (
      <Container mt={6}>
        {(printable
          ? tabMenu
          : [
              {
                id: 'ALL',
                render: null,
                label: '',
              },
            ]
        ).map((tab) => {
          return (
            <PrintingRoot key={tab.id}>
              <Tabs
                $activeColor={theme.colors.accent}
                value={tab.id === 'ALL' ? activeMenu : tab.id}
                onChange={(e, value) => setActiveMenu(value)}
                $activeBarPosition="top"
                $printable={printable}
              >
                {printable ? (
                  <Tab
                    value={tab.id}
                    key={tab.id}
                    label={<Typography>{tab.label}</Typography>}
                    $minWidth={215}
                    type="grey"
                  />
                ) : (
                  tabMenu.map((menu) => (
                    <Tab
                      value={menu.id}
                      key={menu.id}
                      label={<Typography>{menu.label}</Typography>}
                      $minWidth={215}
                      type="grey"
                    />
                  ))
                )}
              </Tabs>
              <Content printable={printable}>
                {tab.id === 'ALL' ? (
                  tabMenu.map((menu) => (
                    <TabPanel
                      key={menu.id}
                      activeValue={activeMenu}
                      value={menu.id}
                    >
                      {menu.render}
                    </TabPanel>
                  ))
                ) : (
                  <TabPanel key={tab.id} activeValue={tab.id} value={tab.id}>
                    {tab.render}
                  </TabPanel>
                )}
              </Content>
            </PrintingRoot>
          )
        })}
      </Container>
    )
  },
)
