import { useComputed } from '@polyu-dip/helpers'
import { MasterDataTable } from '@polyu-dip/stores'
import { useTranslation } from 'react-i18next'
import { useDisplayName } from '../services'
import { useStores } from '../stores'
import { sortByOrder } from '../utilities'

export function useMasterData(
  targetTable?: MasterDataTable,
  filterId?: string,
) {
  const { t } = useTranslation()
  const { masterDataStore } = useStores()
  const { getDisplayName } = useDisplayName()

  const subjects = useComputed(
    () => masterDataStore.subjects.slice().sort(sortByOrder),
    [masterDataStore.subjects],
  )

  const educationLevels = useComputed(
    () => masterDataStore.educationLevels.slice().sort(sortByOrder),
    [masterDataStore.educationLevels],
  )

  const learningPhases = useComputed(
    () =>
      masterDataStore.learningPhases
        .slice()
        .sort(sortByOrder)
        .map((it) => ({
          ...it,
          educationLevel: { ...it.educationLevel },
        })),
    [masterDataStore.learningPhases],
  )

  const classLevels = useComputed(
    () =>
      masterDataStore.classLevels
        .slice()
        .sort(sortByOrder)
        .map((it) => ({
          ...it,
          learningPhase: { ...it.learningPhase },
        })),
    [masterDataStore.classLevels],
  )

  const diStrategyClasses = useComputed(
    () => masterDataStore.diStrategyClasses.slice().sort(sortByOrder),
    [masterDataStore.diStrategyClasses],
  )

  const diStrategies = useComputed(
    () => masterDataStore.diStrategies.slice().sort(sortByOrder),
    [masterDataStore.diStrategies],
  )

  const resourceTypes = useComputed(
    () => masterDataStore.resourceTypes.slice().sort(sortByOrder),
    [masterDataStore.resourceTypes],
  )

  const tagTypes = useComputed(
    () => masterDataStore.tagTypes.slice().sort(sortByOrder),
    [masterDataStore.tagTypes],
  )

  const tagGroups = useComputed(
    () => masterDataStore.tagGroups.slice().sort(sortByOrder),
    [masterDataStore.tagGroups],
  )

  const tags = useComputed(
    () => masterDataStore.tags.slice().sort(sortByOrder),
    [masterDataStore.tags],
  )

  const systemParameters = useComputed(() =>
    masterDataStore.systemParameters
      .slice()
      .sort((a, b) =>
        a.createdTimeDayJs.isBefore(b.createdTimeDayJs) ? 1 : -1,
      ),
  )

  const tagOptions = useComputed(
    () =>
      masterDataStore.tags.map((tag) => ({
        label: getDisplayName(tag.name),
        value: tag.id,
        colorCode: tag.tagGroup?.colorCode,
        disabled: tag.isDisabled,
      })) ?? [],
    [tags],
  )

  const educationLevelOptions = useComputed(
    () =>
      educationLevels.map((educationLevel) => ({
        label: getDisplayName(educationLevel.name),
        value: educationLevel.id,
        disabled: educationLevel.isDisabled,
      })) ?? [],
    [educationLevels],
  )

  const classLevelOptions = useComputed(
    () =>
      classLevels.map((classLevel) => ({
        label: getDisplayName(classLevel.name),
        value: classLevel.id,
        disabled: classLevel.isDisabled,
      })) ?? [],
    [classLevels],
  )

  const learningPhaseOptions = useComputed(
    () =>
      learningPhases.map((learningPhase) => ({
        label: getDisplayName(learningPhase.name),
        value: learningPhase.id,
        disabled: learningPhase.isDisabled,
      })) ?? [],
    [learningPhases],
  )

  const diStrategyClassOptions = useComputed(
    () =>
      diStrategyClasses.map((diStrategyClass) => ({
        label: getDisplayName(diStrategyClass.name),
        value: diStrategyClass.id,
        disabled: diStrategyClass.isDisabled,
      })),
    [diStrategyClasses],
  )

  const tagTypesOptions = useComputed(
    () =>
      tagTypes.map((tagType) => ({
        label: getDisplayName(tagType.name),
        value: tagType.id,
        disabled: tagType.isDisabled,
      })),
    [tagTypes],
  )

  const targetMasterData: any[] = useComputed(() => {
    switch (targetTable) {
      case 'resourceTypes':
        return resourceTypes
      case 'subjects':
        return subjects
      case 'educationLevels':
      case 'classLevels':
      case 'learningPhases':
        // eslint-disable-next-line no-nested-ternary
        return filterId === 'classLevels'
          ? classLevels
          : filterId === 'educationLevels'
          ? educationLevels
          : learningPhases
      case 'diStrategies':
        return filterId == null
          ? diStrategies
          : diStrategies.filter((it) => it.diStrategyClassId === filterId)
      case 'diStrategyClasses':
        return diStrategyClasses
      case 'tags':
        return filterId == null
          ? tagGroups
          : tagGroups.filter((it) => it.tagTypeId === filterId)
      case 'tagTypes':
        return tagTypes
      case 'systemParameters':
        return systemParameters
      case 'tagGroups':
        return filterId == null
          ? tagGroups
          : tagGroups.filter((it) => it.tagTypeId === filterId)
      default:
        return []
    }
  }, [
    t,
    targetTable,
    diStrategies,
    filterId,
    learningPhases,
    classLevels,
    subjects,
    resourceTypes,
    educationLevels,
    diStrategyClasses,
    tags,
    tagTypes,
    tagGroups,
    systemParameters,
  ])

  return {
    educationLevels,
    learningPhases,
    classLevels,
    diStrategyClasses,
    diStrategies,
    tags,
    tagTypes,
    tagGroups,
    targetMasterData,
    subjects,
    systemParameters,
    tagOptions,
    educationLevelOptions,
    classLevelOptions,
    learningPhaseOptions,
    diStrategyClassOptions,
    tagTypesOptions,
  }
}
