import { useComputed } from '@polyu-dip/helpers'
import {
  useLessonPlanDetail,
  useCurrentUserProfile,
  useResourceTypes,
} from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Breadcrumbs, FullscreenLoading, SectionTitle } from '../../components'
import { contentPaths } from '../../content-paths'
import {
  useApiErrorHandle,
  useLessonPlanPermission,
  useMasterDataInit,
} from '../../hooks'
import { useStores } from '../../stores'
import { ForbiddenPage } from '../forbidden-page'
import { LessonPlanForm } from './lesson-plan-form'

const Container = styled.div``

type LessonPlanEditPageProps = {
  saveAndViewNavigatingPath?: string
  lessonPlanId?: string
  isExample?: boolean
}

export const LessonPlanEditPage = observer<LessonPlanEditPageProps>(
  ({ lessonPlanId, isExample, saveAndViewNavigatingPath }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { standardErrorHandler } = useApiErrorHandle()
    const { lessonPlanPermissionHandler } = useLessonPlanPermission()
    const { userProfileStore } = useStores()

    const { lessonPlanId: lessonPlanIdFromParam } = useParams<{
      lessonPlanId?: string
    }>()

    const currentLessonPlanId = useMemo(
      () => lessonPlanId ?? lessonPlanIdFromParam,
      [lessonPlanId, lessonPlanIdFromParam],
    )

    const isEditMode = useMemo(
      () => currentLessonPlanId != null && currentLessonPlanId != 'new',
      [currentLessonPlanId],
    )

    const { isAllMasterDataFetchSuccess } = useMasterDataInit(
      contentPaths.myLessonPlan(),
    )

    const { isSuccess: isResourceTypesSuccess } = useResourceTypes(useStores)
    const { isSuccess: isCurrentUserProfileSuccess } = useCurrentUserProfile(
      useStores,
      {
        expand: ['School', 'School.Users'],
      },
    )

    const { data: targetLessonPlan, isLoading: isLessonPlanLoading } =
      useLessonPlanDetail(
        useStores,
        currentLessonPlanId ?? '',
        {},
        {
          enabled: isEditMode,
          onError: (error) => {
            standardErrorHandler(error, {
              onDismiss: () => {
                navigate(contentPaths.myLessonPlan())
              },
            })
          },
        },
      )

    const { canEditLessonPlan, canEditLessonPlanExample } =
      lessonPlanPermissionHandler(targetLessonPlan)

    const isPermitted = useComputed(() => {
      if (isEditMode) {
        return !(
          (!isExample && !canEditLessonPlan) ||
          (isExample && !canEditLessonPlanExample)
        )
      }
      return userProfileStore?.userProfile?.school?.educationLevelId != null
    }, [
      canEditLessonPlan,
      canEditLessonPlanExample,
      isEditMode,
      isExample,
      userProfileStore?.userProfile?.school?.educationLevelId,
    ])

    const isLoading = useComputed(() => {
      return (
        (isEditMode && isLessonPlanLoading) ||
        !(
          isAllMasterDataFetchSuccess &&
          isCurrentUserProfileSuccess &&
          isResourceTypesSuccess
        )
      )
    }, [
      isAllMasterDataFetchSuccess,
      isCurrentUserProfileSuccess,
      isEditMode,
      isLessonPlanLoading,
      isResourceTypesSuccess,
    ])

    const pageContent = useComputed(() => {
      if (isLoading) {
        return <FullscreenLoading />
      }
      if (!isPermitted) {
        return <ForbiddenPage />
      }
      return (
        <LessonPlanForm
          isExample={isExample}
          targetLessonPlan={targetLessonPlan}
          currentUserEducationLevelId={
            userProfileStore?.userProfile?.school?.educationLevelId
          }
          saveAndViewNavigatingPath={saveAndViewNavigatingPath}
        />
      )
    }, [
      isExample,
      isLoading,
      isPermitted,
      targetLessonPlan,
      userProfileStore?.userProfile?.school?.educationLevelId,
    ])

    return (
      <Container>
        <Breadcrumbs breadcrumbs={[]} />
        <SectionTitle
          title={
            isEditMode
              ? t('myLessonPlan.actions.editLessonPlan')
              : t('myLessonPlan.actions.createLessonPlan')
          }
        />
        {pageContent}
      </Container>
    )
  },
)
