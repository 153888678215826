import { Button, Grid } from '@mui/material'
import {
  LessonPlanConsultRequestResponsePayload,
  LessonPlanPublishRequestResponsePayload,
} from '@polyu-dip/apis'
import { useComputed } from '@polyu-dip/helpers'
import { RoleType } from '@polyu-dip/models'
import {
  clearLessonPlansQueryCaches,
  useCreateLessonPlanConsultRequestWithExpert,
  useCreateLessonPlanPublishRequest,
  useLessonPlanDetail,
  useMySchoolUsers,
  useResourceTypes,
  useTransferLessonPlan,
  useWithdrawLessonPlanSubmitPublishRequest,
} from '@polyu-dip/queries'
import { delay } from '@polyu-dip/utilities'
import { useQueryClient } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinkProps, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Svg } from '../../assets'
import {
  Breadcrumbs,
  ConsultDetailDialog,
  FullscreenLoading,
  SelectUserDialog,
  TransferOwnershipConfirmDialog,
  useOverlay,
} from '../../components'
import { contentPaths } from '../../content-paths'
import {
  useApiErrorHandle,
  useLessonPlanAttachmentsDownload,
  useLessonPlanPermission,
  useMasterDataInit,
  useMasterData
} from '../../hooks'
import { useStores } from '../../stores'
import { ForbiddenPage } from '../forbidden-page'
import { AssignExpertHeader } from './assign-expert-header'
import {
  CreateApprovePublishDialog,
  Title,
  WithdrawApprovePublishDialog,
} from './components'
import { LessonPlanCommentSection } from './lesson-plan-comment'
import { BasicInformation, DetailTabs } from './lesson-plan-detail'
import { LessonPlanExportDocument } from './lesson-plan-export-document'
import { LessonPlanDetailContext } from './use-lesson-plan-detail-context'

type Props = {
  lessonPlanId?: string
  consultRequest?: LessonPlanConsultRequestResponsePayload
  processingLessonPlanPublishRequest?: LessonPlanPublishRequestResponsePayload
  isSharing?: boolean
  isDisposed?: boolean
}

const Container = styled.div``

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  flex: 3;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-shrink: 2;
  flex-direction: row;
`

export const LessonPlanDetailPage = observer<Props>(
  ({
    lessonPlanId,
    consultRequest,
    processingLessonPlanPublishRequest,
    isSharing,
    isDisposed,
  }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { userProfileStore, usersStore } = useStores()
    const { systemParameters } = useMasterData()

    const isRequestConsultingEnabledBySystem = useMemo(
      () =>
        systemParameters.some(
          (it) => it.name === 'expert-switch' && it.value_Boolean,
        ),
      [systemParameters],
    )
    const { data: mySchoolUsers } = useMySchoolUsers(
      useStores,
      {},
      { enabled: userProfileStore.userProfile?.schoolId != null },
    )

    const userOptions = useComputed(
      () =>
        mySchoolUsers?.data
          .filter(
            (user) =>
              user.id !== userProfileStore?.userProfile?.id && !user.isDisabled,
          )
          .map((user) => ({
            label: user.email,
            value: user.id,
          })) ?? [],
      [mySchoolUsers, userProfileStore.userProfile],
    )

    const currentUserRole = useMemo(
      () => userProfileStore.userProfile?.role?.label,
      [userProfileStore.userProfile],
    )

    const { isAllMasterDataFetchSuccess } = useMasterDataInit(
      contentPaths.home(),
    )
    const { lessonPlanId: lessonPlanIdFromParam } = useParams<{
      lessonPlanId: string
    }>()
    const currentLessonPlanId = useMemo(
      () => lessonPlanId ?? lessonPlanIdFromParam ?? '',
      [lessonPlanId, lessonPlanIdFromParam],
    )

    const redirectPath = useMemo(
      () =>
        isDisposed
          ? contentPaths.disposedLessonPlan()
          : contentPaths.myLessonPlan(),
      [isDisposed],
    )

    const queryClient = useQueryClient()
    const { standardErrorHandler } = useApiErrorHandle()
    const { showSnackbar, showSpinner, hideSpinner } = useOverlay()

    const [
      isTransferOwnershipConfirmDialogOpen,
      setIsTransferOwnershipConfirmDialogOpen,
    ] = useState(false)
    const [openCreateApprovePublishDialog, setOpenCreateApprovePublishDialog] =
      useState(false)
    const [
      openWithdrawApprovePublishDialog,
      setOpenWithdrawApprovePublishDialog,
    ] = useState(false)
    const [openAssignExpertDialog, setOpenAssignExpertDialog] = useState(false)
    const [openConsultDetailDialog, setOpenConsultDetailDialog] =
      useState(false)

    useResourceTypes(useStores)
    const { lessonPlanPermissionHandler } = useLessonPlanPermission()
    const { data, isLoading } = useLessonPlanDetail(
      useStores,
      currentLessonPlanId,
      {},
      {
        enabled: currentLessonPlanId.length > 0,
        onError: (error) => {
          standardErrorHandler(error, {
            onDismiss: () => {
              navigate(redirectPath)
            },
          })
        },
      },
    )

    const expertOptions = useComputed(
      () =>
        usersStore.experts
          .slice()
          .filter((expert) => !expert.isDisabled)
          .map((expert) => ({
            label: expert.email,
            value: expert.id,
          })),
      [usersStore],
    )

    const { mutateAsync: createLessonPlanPublishRequest } =
      useCreateLessonPlanPublishRequest(useStores, currentLessonPlanId, {
        onSuccess: () => {
          showSnackbar({
            message: t(
              'lessonPlan.action.createLessonPlanPublishRequest.successMessage',
            ),
          })
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.createLessonPlanPublishRequest.title',
            ),
          })
        },
      })

    const { mutateAsync: withdrawLessonPlanPublishRequest } =
      useWithdrawLessonPlanSubmitPublishRequest(
        useStores,
        data?.latestLessonPlanPublishRequest?.id ?? '',
        {
          onSuccess: () => {
            clearLessonPlansQueryCaches(queryClient, currentLessonPlanId ?? '')
            showSnackbar({
              message: t(
                'lessonPlan.action.withdrawLessonPlanPublishRequest.successMessage',
              ),
            })
          },
          onError: (error) => {
            standardErrorHandler(error, {
              defaultTitle: t(
                'lessonPlan.errorMessage.withdrawLessonPlanPublishRequest.title',
              ),
            })
          },
        },
      )

    const { mutateAsync: assignExpert } =
      useCreateLessonPlanConsultRequestWithExpert(
        useStores,
        consultRequest?.id ?? '',
        {
          onSuccess: () => {
            clearLessonPlansQueryCaches(queryClient, currentLessonPlanId ?? '')
            showSnackbar({
              message: t('lessonPlan.action.assignExpert.successMessage'),
            })
          },
          onError: (error) => {
            standardErrorHandler(error, {
              defaultTitle: t('lessonPlan.errorMessage.assignExpert.title'),
            })
          },
        },
      )

    const { mutateAsync: transferLessonPlan } = useTransferLessonPlan(
      useStores,
      currentLessonPlanId,
      {
        onSuccess: () => {
          showSnackbar({
            message: t('lessonPlan.action.transferLessonPlan.successMessage'),
          })
          navigate(redirectPath)
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t('lessonPlan.errorMessage.transferLessonPlan.title'),
          })
        },
      },
    )

    const handleDownloadAttachments = useLessonPlanAttachmentsDownload(data?.id)

    const [showPrintableWindow, setShowPrintableWindow] = useState(false)
    const handleRequestPrint = useCallback(() => {
      ; (async () => {
        setShowPrintableWindow(true)
        await delay(1000)
        // setShowPrintableWindow(false)
      })()
    }, [])

    const handleEdit = useCallback(() => {
      if (currentLessonPlanId.length === 0)
        throw new Error('Missing lesson plan id')
      navigate(contentPaths.lessonPlans(currentLessonPlanId, 'edit'))
    }, [currentLessonPlanId, navigate])

    const handleCreateApprovePublish = useCallback(
      async (isAgreePublish: boolean) => {
        try {
          showSpinner()
          await createLessonPlanPublishRequest({
            rowVersion: data?.rowVersion ?? '',
            isAgreePublish: isAgreePublish,
          })
          setOpenCreateApprovePublishDialog(false)
        } finally {
          hideSpinner()
        }
      },
      [
        createLessonPlanPublishRequest,
        data?.rowVersion,
        hideSpinner,
        showSpinner,
      ],
    )

    const handleAssignExpert = useCallback(
      async (selectedExpert: { label: string; value: string }) => {
        try {
          showSpinner()
          await assignExpert({
            assignedExpertUserId: selectedExpert.value,
            rowVersion: consultRequest?.rowVersion ?? '',
          })
          setOpenAssignExpertDialog(false)
        } finally {
          hideSpinner()
        }
      },
      [assignExpert, consultRequest, hideSpinner, showSpinner],
    )

    const handleTransferLessonPlan = useCallback(
      async (
        value: string,
        selectedUser?: { label: string; value: string },
      ) => {
        if (value != 'transfer' || selectedUser == null) return
        try {
          showSpinner()
          await transferLessonPlan({
            ownedByUserId: selectedUser.value,
            rowVersion: data?.rowVersion ?? '',
          })
        } finally {
          hideSpinner()
        }
      },
      [data?.rowVersion, hideSpinner, showSpinner, transferLessonPlan],
    )

    const handleWithdrawSubmittedPublishRequest = useCallback(async () => {
      if (
        data?.latestLessonPlanPublishRequest == null ||
        data?.latestLessonPlanPublishRequest.rowVersion == null
      ) {
        return
      }
      try {
        showSpinner()
        await withdrawLessonPlanPublishRequest(
          data?.latestLessonPlanPublishRequest?.rowVersion ?? '',
        )
        setOpenWithdrawApprovePublishDialog(false)
      } finally {
        hideSpinner()
      }
    }, [
      data?.latestLessonPlanPublishRequest,
      hideSpinner,
      showSpinner,
      withdrawLessonPlanPublishRequest,
    ])

    const {
      canViewLessonPlan,
      canEditLessonPlan,
      canCommentLessonPlan,
      canViewTeachingClass,
      canCompleteLessonPlanConsult,
    } = lessonPlanPermissionHandler(data)

    const breadcrumbs: LinkProps[] = useMemo(() => {
      if (data == null) return []
      const lessonPlanCode = [
        {
          to: '#',
          children: data.code,
        },
      ]
      if (consultRequest != null) {
        return [
          {
            to: contentPaths.lessonPlanConsultRequests(),
            children: t('lessonPlanRequest.consultManagement.pendingItems'),
          },
          ...lessonPlanCode,
        ]
      }
      if (isSharing) {
        return [
          {
            to: contentPaths.lessonPlanSharing(),
            children: t('sidebar.lessonPlanSharing'),
          },
          ...lessonPlanCode,
        ]
      }
      if (processingLessonPlanPublishRequest != null) {
        return [
          {
            to: contentPaths.lessonPlanPublishRequests(),
            children: t('sidebar.publishManagement'),
          },
          ...lessonPlanCode,
        ]
      }
      if (isDisposed) {
        return [
          {
            to: contentPaths.disposedLessonPlan(),
            children: t('sidebar.disposedLessonPlan'),
          },
          ...lessonPlanCode,
        ]
      }
      return [
        {
          to: contentPaths.myLessonPlan(),
          children: t('sidebar.myLessonPlan'),
        },
        ...lessonPlanCode,
      ]
    }, [
      data,
      consultRequest,
      isSharing,
      processingLessonPlanPublishRequest,
      isDisposed,
      t,
    ])

    if (isLoading || !data || !isAllMasterDataFetchSuccess) {
      return <FullscreenLoading />
    }

    if (!canViewLessonPlan && !canEditLessonPlan) {
      return <ForbiddenPage />
    }

    return (
      <LessonPlanDetailContext.Provider value={{ lessonPlan: data }}>
        <Container>
          <Breadcrumbs breadcrumbs={breadcrumbs} />

          <HeaderContainer>
            <TitleContainer>
              <Title />
            </TitleContainer>
            <ButtonContainer>
              {canEditLessonPlan && consultRequest == null && (
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      startIcon={<Svg.Transfer fill="white" />}
                      disabled={!canEditLessonPlan}
                      onClick={() =>
                        setIsTransferOwnershipConfirmDialogOpen(true)
                      }
                    >
                      {t('lessonPlan.detail.actions.transferOwnership')}
                    </Button>
                  </Grid>
                  {!isDisposed && (
                    <>
                      {isRequestConsultingEnabledBySystem && (
                        <Grid item>
                          <Button
                            startIcon={<Svg.Publish fill="white" />}
                            color="cascade"
                            disabled={
                              !data.isCompleted ||
                              data.publishDisplayStatus == 'approved' ||
                              data.publishDisplayStatus == 'completed' ||
                              data.publishDisplayStatus == 'publishing'
                            }
                            onClick={() => {
                              if (
                                data.publishDisplayStatus === 'noPendingRequest' ||
                                data.publishDisplayStatus === 'cancelled' ||
                                data.publishDisplayStatus === 'rejected'
                              ) {
                                setOpenCreateApprovePublishDialog(true)
                              } else {
                                setOpenWithdrawApprovePublishDialog(true)
                              }
                            }}
                          >
                            {data.publishDisplayStatus === 'noPendingRequest' ||
                              data.publishDisplayStatus === 'cancelled' ||
                              data.publishDisplayStatus === 'rejected'
                              ? t('lessonPlan.detail.actions.agreeToPublish')
                              : t('lessonPlan.detail.actions.withdraw')}
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Button
                          color="warning"
                          startIcon={<Svg.Create />}
                          onClick={handleEdit}
                        >
                          {t('lessonPlan.detail.actions.editLessonPlan')}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
              {processingLessonPlanPublishRequest != null && (
                <Grid container spacing={2}>
                  <Grid item width={193}>
                    <Button
                      fullWidth
                      color="astronautBlue"
                      onClick={() =>
                        navigate(
                          contentPaths.lessonPlanPublishRequest(
                            processingLessonPlanPublishRequest.id,
                            'view',
                          ),
                        )
                      }
                    >
                      {t('lessonPlanExample.detail.viewPublishingLessonPlan')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </ButtonContainer>
          </HeaderContainer>

          <BasicInformation
            onRequestPrint={() => {
              handleDownloadAttachments()
              handleRequestPrint()
            }}
          />
          {consultRequest != null ? (
            <AssignExpertHeader
              openAssignExpertDialog={() => setOpenAssignExpertDialog(true)}
              openConsultDetailDialog={() => setOpenConsultDetailDialog(true)}
              consultRequest={consultRequest}
              isExpert={currentUserRole === RoleType.expert}
            />
          ) : (
            <></>
          )}
          <DetailTabs
            lessonPlanId={data.id}
            isHideTeachingClass={
              consultRequest != null || !canViewTeachingClass
            }
          />
          <LessonPlanCommentSection
            canCommentLessonPlan={canCommentLessonPlan}
            lessonPlanId={data.id}
            consultRequest={
              canCompleteLessonPlanConsult ? consultRequest : undefined
            }
          />
        </Container>

        <CreateApprovePublishDialog
          open={openCreateApprovePublishDialog}
          onClose={() => setOpenCreateApprovePublishDialog(false)}
          onSubmit={handleCreateApprovePublish}
        />

        <WithdrawApprovePublishDialog
          open={openWithdrawApprovePublishDialog}
          onClose={() => setOpenWithdrawApprovePublishDialog(false)}
          onSubmit={handleWithdrawSubmittedPublishRequest}
        />

        {showPrintableWindow && (
          <LessonPlanExportDocument
            lessonPlanId={data.id}
            onClose={() => setShowPrintableWindow(false)}
          />
        )}

        <SelectUserDialog
          open={openAssignExpertDialog}
          onClose={() => setOpenAssignExpertDialog(false)}
          onSubmit={handleAssignExpert}
          userOptions={expertOptions}
          title={t('lessonPlan.detail.assignExpert.assign.title')}
        />

        <ConsultDetailDialog
          open={openConsultDetailDialog}
          onClose={() => setOpenConsultDetailDialog(false)}
          requestText={consultRequest?.content ?? ''}
          requireObservation={consultRequest?.isSiteVisitNeeded ?? false}
        />

        <TransferOwnershipConfirmDialog
          open={isTransferOwnershipConfirmDialogOpen}
          setOpen={setIsTransferOwnershipConfirmDialogOpen}
          onSubmit={handleTransferLessonPlan}
          userOptions={userOptions}
          title={t('lessonPlan.detail.transferOwnership.title')}
          content={t('lessonPlan.detail.transferOwnership.content')}
          dropdownLabel={t('lessonPlan.detail.transferOwnership.field.label')}
          dropdownPlaceholder={t(
            'lessonPlan.detail.transferOwnership.field.placeholder',
          )}
          remarks={t('lessonPlan.detail.transferOwnership.remark')}
        />
      </LessonPlanDetailContext.Provider>
    )
  },
)
