import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { prependNumber } from '@polyu-dip/utilities'
import { getParent, Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { LessonPlanCommentAttachmentModel } from './lesson-plan-comment-attachment.model'
import { User } from './user.model'

export const LessonPlanCommentModel = BaseEntityModel.named(
  'LessonPlanCommentModel',
)
  .props({
    id: types.string,
    content: types.string,
    isExpert: types.boolean,
    repliedToCommentId: types.maybe(types.string),
    lessonPlanConsultId: types.maybe(types.string),
    lessonPlanCommentNumber: types.string,
    isDeleted: types.boolean,
    lessonPlanId: types.string,
    lessonPlanCommentAttachments: types.optional(
      types.array(LessonPlanCommentAttachmentModel),
      [],
    ),
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .views((self) => ({
    get createdByUser() {
      return self.rootStore.usersStore?.users.find(
        (user: User) => user.id === self.createdByUserId,
      )
    },
    get repliedToComment(): LessonPlanComment | undefined {
      try {
        if (self.repliedToCommentId == null) return
        const targetLessonPlan = getParent(self) as LessonPlanComment[]
        if (targetLessonPlan == null) return
        return targetLessonPlan
          ?.slice()
          ?.find((it) => it.id === self.repliedToCommentId)
      } catch {
        return undefined
      }
    },
    get displayCommentNumber() {
      return `#${prependNumber('0000', self.lessonPlanCommentNumber)}`
    },
  }))

export type LessonPlanComment = Instance<typeof LessonPlanCommentModel>
