import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { PrintWindow } from '../../components'
import { Title } from './components'
import {
  BasicInformation,
  DetailTabs,
  DiStrategiesTab,
} from './lesson-plan-detail'
import { ExpectedDifficulty } from './lesson-plan-example-detail'

const Root = styled.div`
  padding: 12px;
  font-size: 1em;
`

type Props = {
  lessonPlanId: string
  isExample?: boolean
  onClose(): void
}

export const LessonPlanExportDocument = observer<Props>(
  ({ lessonPlanId, isExample, onClose }) => {
    return (
      <PrintWindow onClose={onClose}>
        <Root>
          <Title />
          <BasicInformation printable />
          {isExample ? (
            <>
              <ExpectedDifficulty />
              <DiStrategiesTab />
            </>
          ) : (
            <></>
          )}
          <DetailTabs
            printable
            lessonPlanId={lessonPlanId}
            isExample={isExample}
          />
        </Root>
      </PrintWindow>
    )
  },
)
