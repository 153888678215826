export const error = {
  default: {
    title: 'Error',
    content:
      'Something went wrong. Please try again. If the problem persists, please contact technical support. ({{error}})',
    contentWithError: '$t(error.default.content) ({{error}})',
  },

  timeout: {
    title: 'Connection timeout',
    content: 'Connection timeout',
  },

  cannotConnect: {
    title: 'No network connection',
    content: 'Failed to connect to network ',
  },

  forbidden: {
    title: 'No permission',
    content: 'You have no access right',
  },

  unauthorized: {
    title: 'Not verfied',
    content: 'Your verification has expired',
  },

  conflict: {
    title: 'Data conflict',
    content:
      'The data you are modifying is updated. Please refresh the page and try again.',
  },

  notFound: {
    title: 'Unable to find',
    content: 'The data you wish to access cannot be found.',
  },

  serverError: {
    title: 'Server error',
    content:
      'We are having an internal error, please try again. If the problem persists, please contact technical support. ({{error}})',
  },

  badRequest: {
    title: 'Error request',
    content:
      'We are having a request error, please try again. If the problem persists, please contact technical support. ({{error}})',
  },

  action: { retry: 'Retry' },
  retryContent: 'Do you want to try again?',

  fetchEntityFailure: 'Search {EntityName}} Failure',

  errorDetail: 'Error: {{codes}}\nTrace code: {{traceId}}',
  required: 'Required fields',
  invalidUrlFormat:
    'Please enter the correct download link with https:// included',
  invalidEmail: 'Please enter the correct email address',
  invalidSchoolDomain: 'Please enter the correct school network domain',
  invalidEmailDomain:
    'Please enter the email address of using school network domain',
  integer: 'Please enter a whole number',
  decimal: 'Please enter a decimal number',
  boolean: 'Please select either yes or no',
  minInteger: 'Not less than {{minValue}}',
  maxInteger: 'Not greater than {{maxValue}}',
  invalidHexFormat: 'Please enter the correct hex color code',
  duplicatedEmailInSystem: 'Inputted email already exists in the system',
  duplicatedEmailInSameRequest: 'Same email cannot be registered again',
  duplicatedEntityValue: '{{field}} has already been in use',
  sharederror:'Please select or enter at least one shared teacher email'
}
