import { Button, Grid, Pagination, PaginationProps } from '@mui/material'
import { memo, ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../assets'

const StyledFooterButton = styled(Button)``

const StyledFooterButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
`
export type PaginationFooterProps = {
  defaultPage?: number
  totalPage: number
  hideFooterPagination?: boolean
  hideNextButton?: boolean
  paginationProps: PaginationProps
  onClickFooterButton?: () => void
  onClickShowMoreButton?: () => void
  customizedButton?: ReactNode
  onPageChanged?: (value: number) => void
}

export const PaginationFooter = memo<PaginationFooterProps>(
  ({
    hideFooterPagination,
    paginationProps,
    defaultPage,
    totalPage,
    hideNextButton,
    customizedButton,
    onClickShowMoreButton,
    onPageChanged,
  }) => {
    const { t } = useTranslation()
    const [page, setPage] = useState<number>(defaultPage ?? 1)

    const handlePageChange = useCallback(
      (pageNumber: number) => {
        if (pageNumber <= totalPage) {
          setPage(pageNumber)
          onPageChanged?.(pageNumber)
        }
      },
      [totalPage, onPageChanged],
    )

    return (
      <Grid pt={3} container justifyContent="space-between">
        <Grid item>
          {!hideFooterPagination && totalPage != 1 && (
            <Pagination
              color="primary"
              page={page}
              count={totalPage}
              onChange={(event, value) => handlePageChange(value)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...paginationProps}
            />
          )}
        </Grid>

        {!hideNextButton && totalPage != 1 && (
          <Grid item>
            <StyledFooterButton
              variant="text"
              //disabled={page == totalPage}
              onClick={() => handlePageChange(page + 1)}
            >
              <StyledFooterButtonText>
                {t('pagination.next')}
              </StyledFooterButtonText>
              <Svg.ChevronRight />
            </StyledFooterButton>
          </Grid>
        )}

        {onClickShowMoreButton != null && (
          <Grid item>
            <StyledFooterButton variant="text" onClick={onClickShowMoreButton}>
              <StyledFooterButtonText>
                {`${t('pagination.more')} >`}
              </StyledFooterButtonText>
            </StyledFooterButton>
          </Grid>
        )}

        {customizedButton != null && <Grid item>{customizedButton}</Grid>}
      </Grid>
    )
  },
)
