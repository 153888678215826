import { Box, Grid, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { memo, useCallback } from 'react'
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormSetValue,
  useFieldArray,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Svg } from '../../assets'
import { Dropdown, SelectOption, TextField } from '../form'

type NewResource = {
  title: string
  resourceTypeId?: string
  subjectId?: string
  classLevelId?: string
  file: {
    url: string
    source: string
  }
}

export type NewDownloadableResourceFormData = {
  newResourcesLink: NewResource[]
  newResourcesFile: NewResource[]
}

type Props = {
  fieldName: 'newResourcesFile' | 'newResourcesLink'
  control: Control<NewDownloadableResourceFormData, any>
  errors?: Partial<FieldErrorsImpl<NewDownloadableResourceFormData>>
  classLevelOptions: (SelectOption & {
    educationLevelId?: string
  })[]
  subjectOptions: (SelectOption & {
    educationLevelIds: string[]
  })[]
  resourceTypeOptions: SelectOption[]
  allowDeleteRow?: boolean
  getValues: UseFormGetValues<NewDownloadableResourceFormData>
  setValue: UseFormSetValue<NewDownloadableResourceFormData>
}

export const NewDownloadableResourceList = memo<Props>(
  ({
    fieldName,
    control,
    errors,
    classLevelOptions,
    subjectOptions,
    resourceTypeOptions,
    allowDeleteRow,
    getValues,
    setValue,
  }) => {
    const { t } = useTranslation()
    const { fields: newResourceFields, remove: removeNewResourceField } =
      useFieldArray({
        control,
        name: fieldName,
      })

    const dropdownValueOnOpen = useComputed(() => {
      return {
        classLevelId: newResourceFields?.[0]?.classLevelId,
        subjectId: newResourceFields?.[0]?.subjectId,
        resourceTypeId: newResourceFields?.[0]?.resourceTypeId,
      }
    }, [fieldName])

    const getSubjectOptionsByGrade = useCallback(
      (index: number) => {
        const grade = classLevelOptions.find(
          (it) => it.value === getValues(`${fieldName}.${index}.classLevelId`),
        )

        return subjectOptions
          .filter(
            (option) =>
              (!option.disabled ||
                option.value == dropdownValueOnOpen.subjectId) &&
              (grade?.educationLevelId == null ||
                option.educationLevelIds.includes(grade.educationLevelId)),
          )
          .map((option) => ({
            ...option,
            disabled: false,
          }))
      },
      [
        classLevelOptions,
        dropdownValueOnOpen.subjectId,
        fieldName,
        getValues,
        subjectOptions,
      ],
    )

    return (
      <Box>
        {newResourceFields?.length > 0 ? (
          <Grid container direction="row">
            <Grid item xs={fieldName === 'newResourcesFile' ? 5 : 2.5}>
              <Typography>
                {t('resources.createDownloadableResource.field.title.label')}
              </Typography>
            </Grid>
            {fieldName === 'newResourcesLink' ? (
              <Grid item xs={2.5}>
                <Typography>
                  {t('resources.createDownloadableResource.field.url.label')}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={2}>
              <Typography>
                {t(
                  'resources.createDownloadableResource.field.classLevel.label',
                )}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {t('resources.createDownloadableResource.field.subject.label')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {t('resources.createDownloadableResource.field.type.label')}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {newResourceFields.map((newResourceField, index) => (
          <>
            <Grid key={newResourceField.id} container>
              <Grid item xs={fieldName === 'newResourcesFile' ? 5 : 2.5} pr={5}>
                <Controller
                  control={control}
                  name={`${fieldName}.${index}.title`}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      error={errors?.[fieldName]?.[index]?.title?.message}
                      isReserverForHelperText
                    />
                  )}
                />
              </Grid>
              {fieldName === 'newResourcesLink' ? (
                <Grid item xs={2.5} pr={5}>
                  <Controller
                    control={control}
                    name={`${fieldName}.${index}.file.url`}
                    render={({ field }) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        error={errors?.[fieldName]?.[index]?.file?.url?.message}
                        isReserverForHelperText
                      />
                    )}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={2} pr={5}>
                <Controller
                  control={control}
                  name={`${fieldName}.${index}.classLevelId`}
                  render={({ field }) => (
                    <Dropdown
                      placeholder={t(
                        'resources.createDownloadableResource.field.classLevel.placeholder',
                      )}
                      options={classLevelOptions
                        .filter(
                          (option) =>
                            !option.disabled ||
                            option.value == dropdownValueOnOpen.classLevelId,
                        )
                        .map((option) => ({
                          ...option,
                          disabled: false,
                        }))}
                      error={
                        errors?.[fieldName]?.[index]?.classLevelId?.message
                      }
                      isReserverForHelperText
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={(event) => {
                        field.onChange(event.target.value)
                        setValue(`${fieldName}.${index}.subjectId`, undefined)
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} pr={5}>
                <Controller
                  control={control}
                  name={`${fieldName}.${index}.subjectId`}
                  render={({ field }) => (
                    <Dropdown
                      placeholder={t(
                        'resources.createDownloadableResource.field.subject.placeholder',
                      )}
                      options={getSubjectOptionsByGrade(index)}
                      error={errors?.[fieldName]?.[index]?.subjectId?.message}
                      isReserverForHelperText
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      value={getValues(`${fieldName}.${index}.subjectId`)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} pr={5}>
                <Controller
                  control={control}
                  name={`${fieldName}.${index}.resourceTypeId`}
                  render={({ field }) => (
                    <Dropdown
                      placeholder={t(
                        'resources.createDownloadableResource.field.type.placeholder',
                      )}
                      options={resourceTypeOptions
                        .filter(
                          (option) =>
                            !option.disabled ||
                            option.value == dropdownValueOnOpen.resourceTypeId,
                        )
                        .map((option) => ({
                          ...option,
                          disabled: false,
                        }))}
                      error={
                        errors?.[fieldName]?.[index]?.resourceTypeId?.message
                      }
                      isReserverForHelperText
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                  )}
                />
              </Grid>
              {allowDeleteRow ? (
                <Grid container justifyContent="flex-end" xs={1}>
                  <Grid item>
                    <Svg.Close
                      cursor="pointer"
                      onClick={() => removeNewResourceField(index)}
                    />
                  </Grid>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </>
        ))}
      </Box>
    )
  },
)
