import { GridColDef } from '@mui/x-data-grid'
import { LessonPlan } from '@polyu-dip/models'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag, TruncatedCellContent } from '../../components'
import { useDisplayName } from '../../services'

export enum TabMenuEnum {
  MY_PLAN = 'MY_PLAN',
  PUBLIC_PLAN = 'PUBLIC_PLAN',
}

type TabType = 'MY_PLAN' | 'PUBLIC_PLAN'

export function useLessonPlansColumn() {
  const { t } = useTranslation()
  const { getDisplayName } = useDisplayName()

  const renderColumn = useCallback(
    (activeTab: TabType) => {
      const columns: GridColDef<LessonPlan>[] = [
        {
          field: 'classLevel.order',
          headerName: t('myLessonPlan.tableFields.class'),
          width: 120,
          renderCell: ({ row }) => (
            <Tag
              label={getDisplayName(row.classLevel?.name)}
              colorCustomize={row.classLevel?.colorCode}
            />
          ),
        },
        {
          field: 'subject.name',
          headerName: t('myLessonPlan.tableFields.subject'),
          width: 200,
          renderCell: ({ row }) => (
            <Tag
              label={getDisplayName(row.subject?.name)}
              colorCustomize={row.subject?.colorCode}
            />
          ),
        },
        {
          field: 'teachingTopic',
          headerName: t('myLessonPlan.tableFields.teachingTopic'),
          width: 400,
          renderCell: ({ row }) => (
            <TruncatedCellContent content={row.teachingTopic} />
          ),
        },
        ...(activeTab === TabMenuEnum.MY_PLAN
          ? [
              {
                field: 'latestLessonPlanComment.createdDateTime',
                headerName: t('myLessonPlan.tableFields.latestMessage'),
                renderCell: ({ row }: any) =>
                  row.latestLessonPlanComment?.createdDateTime != null
                    ? dayjs(
                        row.latestLessonPlanComment?.createdDateTime,
                      ).format(t('common.format.datetime'))
                    : '---',
                width: 200,
              },
              {
                field: 'isAgreePublish',
                headerName: t('myLessonPlan.tableFields.isAgreePublish'),
                width: 150,
                valueFormatter: ({ value }: any) =>
                  value != null ? t(value ? 'common.yes' : 'common.no') : '---',
              },
              {
                field: 'consultDisplayStatus',
                headerName: t('myLessonPlan.tableFields.consultDisplayStatus'),
                width: 170,
                renderCell: ({ row }: any) =>
                  row.consultDisplayStatus != null &&
                  row.consultDisplayStatus != 'noPendingRequest' ? (
                    <Tag
                      isStatus
                      statusType="consultRequest"
                      label={row.consultDisplayStatus}
                    />
                  ) : (
                    '---'
                  ),
              },
              {
                field: 'publishDisplayStatus',
                headerName: t('myLessonPlan.tableFields.publishDisplayStatus'),
                width: 170,
                renderCell: ({ row }: any) =>
                  row.publishDisplayStatus != null ? (
                    <Tag
                      isStatus
                      statusType="publishRequest"
                      label={row.publishDisplayStatus}
                    />
                  ) : (
                    '---'
                  ),
              },
            ]
          : [
              {
                field: 'createdDateTime',
                headerName: t('myLessonPlan.tableFields.submissionDate'),
                valueFormatter: ({ value }: any) =>
                  dayjs(value).format(t('common.format.date')),
                width: 150,
              },
              {
                field: 'latestSourcePublishRequest.publishedDateTime',
                headerName: t(
                  'myLessonPlan.tableFields.releaseOrUnPublishedTime',
                ),
                width: 200,
                renderCell: ({ row }: any) =>
                  row.latestSourcePublishRequest?.publishedDateTime == null
                    ? '---'
                    : dayjs(
                        row?.latestSourcePublishRequest.publishedDateTime,
                      ).format(t('common.format.datetime')),
              },
              {
                field: 'publishDisplayStatus',
                headerName: t('myLessonPlan.tableFields.status'),
                width: 170,
                renderCell: ({ row }: any) =>
                  row.publishDisplayStatus != null ? (
                    <Tag
                      isStatus
                      statusType="publishRequest"
                      label={
                        row.publishDisplayStatus == 'completed'
                          ? 'unPublishing'
                          : row.publishDisplayStatus
                      }
                    />
                  ) : (
                    '---'
                  ),
              },
            ]),
      ]
      return columns
    },
    [getDisplayName, t],
  )

  return { renderColumn }
}
