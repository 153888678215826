import { QueryClient } from '@tanstack/react-query'
import { RootStore, RootStoreModel } from '../stores'

export type InitResult = { rootStore: RootStore; queryClient: QueryClient }

export async function init(): Promise<InitResult> {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        structuralSharing: false,
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  })

  const rootStore = RootStoreModel.create({})

  return { rootStore, queryClient }
}
