import { Divider, Grid, GridProps } from '@mui/material'
import { memo, ReactNode } from 'react'
import styled from 'styled-components'

const StyledGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.palettes.general.blue};
  padding: 4px 0px;
  -webkit-print-color-adjust: exact;
`

const StyledDivider = styled(Divider)`
  margin-bottom: 4px;
  background-color: ${({ theme }) => theme.palettes.general.lightBlue};
  -webkit-print-color-adjust: exact;
`

type Props = {
  children: ReactNode
} & GridProps

export const HighlightedGrid = memo<Props>(({ children, ...props }) => {
  return (
    <>
      <StyledGrid {...props}>{children}</StyledGrid>
      <StyledDivider />
    </>
  )
})
