import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'

export const LessonPlanTeachingGoalModel = BaseEntityModel.named(
  'LessonPlanTeachingGoalModel',
)
  .props({
    id: types.string,
    description: types.string,
    order: types.number,
  })
  .extend(withUpdate)

export type LessonPlanTeachingGoal = Instance<
  typeof LessonPlanTeachingGoalModel
>
