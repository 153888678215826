import { memo } from 'react'
import { FormHelperText as MuiFormHelperText } from '@mui/material'
import styled from 'styled-components'

type Props = {
  error?: string
}

const StyledFormHelperText = styled(MuiFormHelperText)<{ error: true }>``

export const FormHelperText = memo<Props>(({ error, ...props }) => (
  <StyledFormHelperText {...props} error>
    {error ?? ' '}
  </StyledFormHelperText>
))
