import {
  Autocomplete as MuiAutoComplete,
  AutocompleteProps,
  FormControl,
} from '@mui/material'
import { ChangeEventHandler, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TextField } from '../text-field'
import { find } from 'lodash'

const StyledFormControl = styled(FormControl)<{ fullWidth?: boolean }>`
  position: relative;
  margin-bottom: 16px;

  .MuiOutlinedInput-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`

type OwnProps = {
  label?: string
  error?: string
  placeholder?: string
  required?: boolean
  isReserverForHelperText?: boolean
}

type Props = Omit<
  AutocompleteProps<any, boolean, boolean, boolean | undefined>,
  'renderInput'
> &
  OwnProps & {
    onTextFieldValueChanged?:
      | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      | undefined
    onStopInput?: () => void
  }

export const AutoComplete = memo<Props>(
  ({
    label,
    error,
    id,
    fullWidth = true,
    placeholder,
    required,
    isReserverForHelperText,
    onTextFieldValueChanged,
    onStopInput,
    value,
    ...props
  }) => {
    const { t } = useTranslation()

    const isObjectHasValueProperty = useCallback(
      (data: any) =>
        typeof data === 'object' &&
        Object.prototype.hasOwnProperty.call(data, 'value'),
      [],
    )

    const calculatedValue = useMemo(
      () =>
        value != null && isObjectHasValueProperty(value)
          ? find(props.options, (opt) => opt.value === value?.value) ?? value
          : value,
      [isObjectHasValueProperty, props.options, value],
    )

    return (
      <StyledFormControl fullWidth={fullWidth}>
        <MuiAutoComplete
          {...props}
          value={calculatedValue}
          isOptionEqualToValue={
            props.isOptionEqualToValue ??
            ((opt, actual) => {
              if (isObjectHasValueProperty(opt)) {
                return opt.value === actual.value
              }

              return opt === actual
            })
          }
          key={calculatedValue}
          fullWidth
          noOptionsText={t('common.noOptions')}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={onTextFieldValueChanged}
              onStopInput={onStopInput}
              value={calculatedValue}
              id={id ?? params.id}
              label={label}
              error={error}
              placeholder={placeholder}
              mb={0}
              required={required}
              isReserverForHelperText={isReserverForHelperText}
            />
          )}
        />
      </StyledFormControl>
    )
  },
)
