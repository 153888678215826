import { Box } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useComputed } from '@polyu-dip/helpers'
import { FullscreenLoading, PageTitle, SectionTitle } from '../../components'
import { useSystemParameters } from '@polyu-dip/queries'
import { useStores } from '../../stores'

const Container = styled.div``

const Section = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palettes.general.white,
  borderRadius: 30,
  marginBottom: 16,
}))

const Content = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.palettes.general.greys[0]};
`

const StyledTitle = styled(PageTitle)`
  margin-top: 0px;
  color: ${({ theme }) => theme.palettes.general.greys[0]};
`

export const Disclaimer = memo(() => {
  const { t } = useTranslation()
  const { i18n } = useTranslation()

  const { data: systemParametersData, isLoading } =
    useSystemParameters(useStores)

  const computedData = useComputed(() => {
    if (i18n.language == 'en') {
      return systemParametersData?.data
        .filter((it) => it.category === 'disclaimer' && it.name.includes('eng'))
        .sort((a, b) => a.name.localeCompare(b.name))
    }
    return systemParametersData?.data
      .filter((it) => it.category === 'disclaimer' && !it.name.includes('eng'))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [systemParametersData?.data])

  return (
    <Container>
      <SectionTitle title={t('sidebar.disclaimer')} color="brown" />
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (
          <FullscreenLoading />
        ) : computedData == null ? (
          <></>
        ) : (
          <>
            {computedData.map((it) => (
              <Section p={7} key={it.name}>
                <StyledTitle>{it.description}</StyledTitle>
                <Box mb={3}>
                  <Content>{it.value_Text}</Content>
                </Box>
              </Section>
            ))}
          </>
        )
      }
    </Container>
  )
})
