import { Button, Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogAction } from '../dialog'
import { useOverlay } from '../overlay-provider'
import { observer } from 'mobx-react-lite'
import { RadioGroup, SelectOption, TextField } from '../form'
import { NewSchoolFormData } from '../../hooks'
import { FullscreenLoading } from '../fullscreen-loading'
import { SelectUserDialog } from '../confirmation-dialogs'

type AddSchoolDialogProps = {
  open: boolean
  setOpen: (value: boolean) => void
  educationLevelOptions: SelectOption[]
  onSubmit: (value: string, formData?: NewSchoolFormData) => Promise<void>
  isSubmitting: boolean
  isLoading?: boolean
  schoolAdminOptions?: { label: string; value: string }[]
  extraActionButton?: DialogAction<string>
  form: UseFormReturn<NewSchoolFormData, any>
  mode: 'add' | 'edit'
}

export const AddSchoolDialog = observer<AddSchoolDialogProps>(
  ({
    open,
    setOpen,
    educationLevelOptions,
    schoolAdminOptions,
    onSubmit,
    isSubmitting,
    form,
    extraActionButton,
    mode,
    isLoading,
  }) => {
    const { t } = useTranslation()
    const { showSpinner, hideSpinner } = useOverlay()
    const {
      formState: { errors },
      control,
      getValues,
      trigger,
      setError,
      clearErrors,
      watch,
      reset,
    } = form
    const [isAssignSchoolAdminDialogOpen, setIsAssignSchoolAdminDialogOpen] =
      useState<boolean>(false)

    const addUserDialogActions = useMemo((): DialogAction<string>[] => {
      return [
        {
          text: t('common.close'),
          type: 'outlined',
          value: 'close',
        },
        ...(extraActionButton != null ? [extraActionButton] : []),
        {
          text:
            mode == 'add'
              ? t('schools.actions.add.confirm')
              : t('schools.actions.edit.confirm'),
          type: 'contained',
          value: 'confirm',
          color: 'blue',
          disabled: isSubmitting,
        },
      ]
    }, [t, mode, extraActionButton, isSubmitting])

    const handleSubmit = useCallback(
      async (value: string) => {
        if (isSubmitting) {
          return
        }
        if (value !== 'confirm') {
          reset()
          clearErrors()
          setOpen(false)
          if (value == 'disable') {
            onSubmit(value)
          }
          return
        }
        try {
          showSpinner()
          const formData = getValues()
          let isError = false
          if (!(await trigger())) {
            isError = true
          }
          if (
            formData.mode != null &&
            formData.mode == 'add' &&
            formData.mainSchoolAdminUserEmail != null &&
            formData.domain != null &&
            !formData.mainSchoolAdminUserEmail.endsWith(formData.domain)
          ) {
            setError('mainSchoolAdminUserEmail', {
              type: 'custom',
              message: t('error.invalidEmailDomain'),
            })
            return
          }
          if (isError) {
            return
          }
          if (Object.keys(errors).length == 0) {
            onSubmit(value, formData)
          }
        } finally {
          hideSpinner()
        }
      },
      [
        isSubmitting,
        reset,
        clearErrors,
        setOpen,
        showSpinner,
        getValues,
        trigger,
        errors,
        setError,
        t,
        onSubmit,
        hideSpinner,
      ],
    )

    useEffect(() => {
      const subscription = watch(async () => {
        clearErrors()
      })
      return () => subscription.unsubscribe()
    }, [clearErrors, watch])

    if (isLoading) return <FullscreenLoading />

    return (
      <>
        <Dialog
          open={open}
          title={
            mode == 'add'
              ? t('schools.actions.add.title')
              : t('schools.actions.edit.title')
          }
          actions={addUserDialogActions}
          onSelected={handleSubmit}
          maxWidth="md"
          fullWidth
        >
          <Grid container direction="column" wrap="nowrap">
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6}>
                <Typography>{t('schools.fields.chineseName')}</Typography>
                <Controller
                  control={control}
                  name={'chineseName'}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      placeholder={t(
                        'schools.actions.add.placeholder.chineseName',
                      )}
                      error={errors.chineseName?.message}
                      isReserverForHelperText
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>{t('schools.fields.englishName')}</Typography>
                <Controller
                  control={control}
                  name={'englishName'}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      placeholder={t(
                        'schools.actions.add.placeholder.englishName',
                      )}
                      error={errors.englishName?.message}
                      isReserverForHelperText
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} wrap="nowrap">
              <Grid item xs={6} wrap="nowrap">
                <Typography>{t('schools.fields.shortCode')}</Typography>
                <Controller
                  control={control}
                  name={'shortCode'}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      placeholder={t(
                        'schools.actions.add.placeholder.shortCode',
                      )}
                      error={errors.shortCode?.message}
                      isReserverForHelperText
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} wrap="nowrap">
                <Typography>{t('schools.fields.domain')}</Typography>
                <Controller
                  control={control}
                  name={'domain'}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      placeholder={t('schools.actions.add.placeholder.domain')}
                      error={errors.domain?.message}
                      isReserverForHelperText
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} wrap="nowrap">
              {mode == 'add' ? (
                <Grid item xs={6} wrap="nowrap">
                  <Typography>
                    {t('schools.fields.schoolAdminEmail')}
                  </Typography>
                  <Controller
                    control={control}
                    name={'mainSchoolAdminUserEmail'}
                    render={({ field }) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        placeholder={t(
                          'schools.actions.add.placeholder.schoolAdminEmail',
                        )}
                        error={errors.mainSchoolAdminUserEmail?.message}
                        isReserverForHelperText
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} wrap="nowrap">
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    paddingBottom={7}
                  >
                    <Typography>
                      {t('schools.fields.schoolAdminEmail')}
                    </Typography>
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={6} wrap="nowrap">
                        <Typography>
                          {schoolAdminOptions?.find(
                            (it) => it.value == watch('mainSchoolAdminUserId'),
                          )?.label ?? watch('mainSchoolAdminUserEmail')}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} wrap="nowrap">
                        <Button
                          variant="contained"
                          onClick={() => setIsAssignSchoolAdminDialogOpen(true)}
                        >
                          {t('schools.actions.assignNewSchoolAdmin.title')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid container direction="row" spacing={2} wrap="nowrap">
              <Grid item xs={6} wrap="nowrap" alignItems="center">
                <Typography>{t('schools.fields.educationLevel')}</Typography>
                <Controller
                  control={control}
                  name={'educationLevelId'}
                  render={({ field }) => (
                    <RadioGroup
                      options={educationLevelOptions
                        .filter(
                          (option) =>
                            !option.disabled || option.value == field.value,
                        )
                        .map((option) => ({ ...option, disabled: false }))}
                      row
                      isReserverForHelperText
                      error={errors.educationLevelId?.message}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
        {schoolAdminOptions != null && (
          <SelectUserDialog
            open={isAssignSchoolAdminDialogOpen}
            onClose={() => setIsAssignSchoolAdminDialogOpen(false)}
            title={t('schools.actions.assignNewSchoolAdmin.title')}
            onSubmit={async (selectedUser) => {
              setIsAssignSchoolAdminDialogOpen(false)
              form.setValue('mainSchoolAdminUserId', selectedUser.value)
            }}
            defaultSelectedUser={schoolAdminOptions.find(
              (it) => it.value == watch('mainSchoolAdminUserId'),
            )}
            userOptions={schoolAdminOptions}
          />
        )}
      </>
    )
  },
)
