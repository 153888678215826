import { GridSortModel } from '@mui/x-data-grid'
import { PaginationParameters } from '@polyu-dip/apis'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLessonPlanConsultRequestColumn } from './use-lesson-plan-consult-request-column'
import styled from 'styled-components'
import { Chip, Typography } from '@mui/material'
import {
  ConsultDetailDialog,
  ListPage,
  Tab,
  Table,
  Tabs,
  useRowCountState,
} from '../../components'
import { contentPaths } from '../../content-paths'
import { useLessonPlanConsultsAssigned } from '@polyu-dip/queries'
import { useComputed } from '@polyu-dip/helpers'

const StyledChip = styled(Chip)`
  font-size: 14px;
  line-height: 120%;
  height: auto;
  margin-left: 12px;

  .MuiChip-label {
    padding: 0px 8px;
  }
`

const FlexRow = styled.div`
  display: flex;
  direction: row;
  white-space: nowrap;
`

const PAGINATION_LIMIT = 15

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

enum TabMenuEnum {
  ALL = 'all',
  FOLLOWING_UP = 'followingUp',
  COMPLETED = 'completed',
}

export const LessonPlanConsultRequestFollowingUp = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { renderColumn } = useLessonPlanConsultRequestColumn()
  const [activeTab, setActionTab] = useState<TabMenuEnum>(TabMenuEnum.ALL)
  const [openConsultDetailDialog, setOpenConsultDetailDialog] = useState(false)
  const [targetRequestId, setTargetRequestId] = useState<string>()
  const [requestText, setRequestText] = useState<string>()
  const [requireObservation, setRequireObservation] = useState<boolean>()

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [allRequestSort, setAllRequestSort] = useState<GridSortModel>()
  const [followingUpRequestSort, setFollowingUpRequestSort] =
    useState<GridSortModel>()
  const [completedRequestSort, setCompletedRequestSort] =
    useState<GridSortModel>()

  const { data: allRequests, isLoading: isAllRequestsLoading } =
    useLessonPlanConsultsAssigned({
      expand: ['LessonPlan'],
      sort: allRequestSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      ...pagination,
    })

  const { data: followingUpRequests, isLoading: isFollowingUpRequestsLoading } =
    useLessonPlanConsultsAssigned({
      expand: ['LessonPlan'],
      status: 'followingUp',
      sort: followingUpRequestSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      ...pagination,
    })

  const { data: completedRequests, isLoading: isCompletedRequestsLoading } =
    useLessonPlanConsultsAssigned({
      expand: ['LessonPlan'],
      status: 'completed',
      sort: completedRequestSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      ...pagination,
    })

  const activeData = useComputed(
    () =>
      // eslint-disable-next-line no-nested-ternary
      activeTab === TabMenuEnum.ALL
        ? allRequests
        : activeTab === TabMenuEnum.FOLLOWING_UP
        ? followingUpRequests
        : completedRequests,
    [activeTab, allRequests, followingUpRequests, completedRequests],
  )

  const isLoading = useComputed(
    () =>
      // eslint-disable-next-line no-nested-ternary
      activeTab === TabMenuEnum.ALL
        ? isAllRequestsLoading
        : activeTab === TabMenuEnum.FOLLOWING_UP
        ? isFollowingUpRequestsLoading
        : isCompletedRequestsLoading,
    [
      activeTab,
      isAllRequestsLoading,
      isFollowingUpRequestsLoading,
      isCompletedRequestsLoading,
    ],
  )

  const computedLessonPlanConsultRequestsData = useComputed(() => {
    return activeData?.data ?? []
  }, [activeData])

  const { rowCountState } = useRowCountState(activeData?.total ?? 0)

  const handleTabChange = useCallback((event: any, value: any) => {
    setActionTab(value)
    setPagination(DEFAULT_PAGINATION)
  }, [])

  const handleLetterOnClick = useCallback(
    (requestId: string, consultContent: string, isSiteVisitNeeded: boolean) => {
      setOpenConsultDetailDialog(true)
      setTargetRequestId(requestId)
      setRequestText(consultContent)
      setRequireObservation(isSiteVisitNeeded)
    },
    [],
  )

  return (
    <ListPage
      sectionTitleProps={{
        title: t('lessonPlanRequest.followingUp.title'),
        color: 'astronautBlue',
      }}
    >
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab
          value={TabMenuEnum.ALL}
          label={
            <FlexRow>
              <Typography>
                {t('lessonPlanRequest.followingUp.tabs.all')}
              </Typography>
              <StyledChip
                label={allRequests?.total ?? 0}
                color={activeTab === TabMenuEnum.ALL ? 'primary' : 'default'}
              />
            </FlexRow>
          }
          $width={320}
        />
        <Tab
          value={TabMenuEnum.FOLLOWING_UP}
          label={
            <FlexRow>
              <Typography>
                {t('lessonPlanRequest.followingUp.tabs.followingUp')}
              </Typography>
              <StyledChip
                label={followingUpRequests?.total ?? 0}
                color={
                  activeTab === TabMenuEnum.FOLLOWING_UP ? 'primary' : 'default'
                }
              />
            </FlexRow>
          }
          $width={320}
        />
        <Tab
          value={TabMenuEnum.COMPLETED}
          label={
            <FlexRow>
              <Typography>
                {t('lessonPlanRequest.followingUp.tabs.completed')}
              </Typography>
              <StyledChip
                label={completedRequests?.total ?? 0}
                color={
                  activeTab === TabMenuEnum.COMPLETED ? 'primary' : 'default'
                }
              />
            </FlexRow>
          }
          $width={320}
        />
      </Tabs>

      <Table
        columns={renderColumn(handleLetterOnClick)}
        rows={computedLessonPlanConsultRequestsData}
        pageSize={PAGINATION_LIMIT}
        loading={isLoading}
        paginationMode="server"
        page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
        hideFooterPagination={
          rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
        }
        rowCount={rowCountState}
        sortModel={
          // eslint-disable-next-line no-nested-ternary
          activeTab === TabMenuEnum.ALL
            ? allRequestSort
            : activeTab === TabMenuEnum.FOLLOWING_UP
            ? followingUpRequestSort
            : completedRequestSort
        }
        onSortModelChange={
          // eslint-disable-next-line no-nested-ternary
          activeTab === TabMenuEnum.ALL
            ? setAllRequestSort
            : activeTab === TabMenuEnum.FOLLOWING_UP
            ? setFollowingUpRequestSort
            : setCompletedRequestSort
        }
        onRowClick={({ row }) => {
          navigate(contentPaths.lessonPlanConsultRequest(row.id))
        }}
        onPageChange={(page) =>
          setPagination((old) => ({
            ...old,
            offset: page * PAGINATION_LIMIT,
          }))
        }
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastModifiedDateTime', sort: 'desc' }],
          },
        }}
      />
      <ConsultDetailDialog
        open={openConsultDetailDialog}
        onClose={() => setOpenConsultDetailDialog(false)}
        requestId={targetRequestId}
        requestText={requestText}
        requireObservation={requireObservation}
        onSubmit={(requestId: string) => {
          navigate(contentPaths.lessonPlanConsultRequest(requestId))
        }}
      />
    </ListPage>
  )
})
