import { GetPayload, PaginatedPayload, PaginationParameters } from '../base'
import { ClassLevel, LessonPlan, Subject } from '../entity'

export type OutstandingLessonPlanRequestQueryParameters =
  PaginationParameters & {
    status?: OutstandingLessonPlanRequestStatus[]
    type?: OutstandingRequestType[]
    isPublishing?: boolean[]
  }

type OutstandingLessonPlanRequestType = 'consultRequest' | 'publishRequest'

type OutstandingLessonPlanRequestDetail = {
  targetLessonPlan?: GetPayload<LessonPlan>
  subject?: GetPayload<Subject>
  classLevel?: GetPayload<ClassLevel>
}

export enum OutstandingRequestType {
  'consultRequest' = 'consultRequest',
  'publishRequest' = 'publishRequest',
}

export enum OutstandingLessonPlanRequestStatus {
  'pending' = 'pending',
  'cancelled' = 'cancelled',
  'approved' = 'approved',
  'rejected' = 'rejected',
  'completed' = 'completed',
  'followingUp' = 'followingUp',
}

type OutstandingLessonPlanRequest = {
  id: string
  targetLessonPlanId: string
  type: OutstandingLessonPlanRequestType
  status: string
  consultContent?: string
  isSiteVisitNeeded?: boolean
  assignedExpertUserId?: string
  assignedDateTime?: string
  assignedToUserName?: string
  initiatedLessonPlanId?: string
  publishingLessonPlanId?: string
  publishDateTime?: string
  isPublishing?: boolean
}

export type OutstandingLessonPlanRequestResponsePayload =
  PaginatedPayload<OutstandingLessonPlanRequest> &
    OutstandingLessonPlanRequestDetail
