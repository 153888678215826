import { PatchLessonPlanCommentPayload } from '@polyu-dip/apis'
import { LessonPlanComment } from '@polyu-dip/models'
import { LessonPlansStore } from '@polyu-dip/stores'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'lesson-plan-comments'

type UseStoresType = () => { lessonPlansStore: LessonPlansStore }

export function useUpdateLessonPlanCommentById(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<
    LessonPlanComment,
    unknown,
    PatchLessonPlanCommentPayload
  >,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => lessonPlansStore.updateLessonPlanCommentById(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useDeleteLessonPlanComment(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, string>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (lessonPlanCommentId) =>
      lessonPlansStore.deleteLessonPlanComment(lessonPlanCommentId),
    {
      ...opts,
      onSuccess: (...params) => {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}
