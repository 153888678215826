import { LessonPlansStore } from '@polyu-dip/stores'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'lesson-plan-statistics'

type UseStoresType = () => { lessonPlansStore: LessonPlansStore }

export function useIncrementLessonPlanViewCountById(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<unknown>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(() => lessonPlansStore.incrementLessonPlanViewCount(id), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id, 'views'])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useIncrementLessonPlanExportCountById(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<unknown>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    () => lessonPlansStore.incrementLessonPlanExportCount(id),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id, 'exports'])
        opts?.onSuccess?.(...params)
      },
    },
  )
}
