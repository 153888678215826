import { MasterDataTable } from '@polyu-dip/stores'
import { signedInteger } from '@polyu-dip/utilities'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

const hexCodeFormat = /^#([0-9A-F]{3}){1,2}$/

export function useMasterDataSchema(
  masterDataTable: MasterDataTable,
  tagGroupWithName: boolean,
  filterId?: string,
) {
  const { t } = useTranslation()

  const tagTypeSchema = useMemo(
    () =>
      yup.object({
        newData: yup.array(
          yup.object({
            chineseName: yup.string().required(t('error.required')),
            englishName: yup.string().required(t('error.required')),
            order: yup
              .number()
              .max(
                signedInteger,
                t('error.maxInteger', { maxValue: signedInteger }),
              ),
          }),
        ),
      }),
    [t],
  )

  const tagGroupSchema = useMemo(
    () =>
      yup.object({
        newData: yup.array(
          yup.object({
            chineseName: yup
              .string()
              [tagGroupWithName ? 'required' : 'notRequired'](
                t('error.required'),
              ),
            englishName: yup
              .string()
              [tagGroupWithName ? 'required' : 'notRequired'](
                t('error.required'),
              ),
            tagTypeId: yup.string().required(t('error.required')),
            tagGroupTags: yup
              .array()
              .of(
                yup.object({
                  id: yup.string(),
                  chineseName: yup.string().required(t('error.required')),
                  englishName: yup.string().required(t('error.required')),
                }),
              )
              .min(1, t('error.required')),
            order: yup
              .number()
              .max(
                signedInteger,
                t('error.maxInteger', { maxValue: signedInteger }),
              ),
            colorCode: yup.string().required(t('error.required')),
            isMultiSelect: yup.bool(),
          }),
        ),
      }),
    [t, tagGroupWithName],
  )

  const subjectSchema = useMemo(
    () =>
      yup.object({
        newData: yup.array(
          yup.object({
            chineseName: yup.string().required(t('error.required')),
            englishName: yup.string().required(t('error.required')),
            educationLevelIds: yup.array().of(
              yup.object({
                value: yup.string().required(t('error.required')),
                label: yup.string().required(t('error.required')),
              }),
            ),
            order: yup
              .number()
              .max(
                signedInteger,
                t('error.maxInteger', { maxValue: signedInteger }),
              ),
            colorCode: yup.string().required(t('error.required')),
          }),
        ),
      }),
    [t],
  )

  const systemParameterSchema = useMemo(
    () =>
      yup.object({
        newData: yup.array(
          yup.object().shape({
            name: yup.string().required(t('error.required')),
            description: yup.string(),
            category: yup.string(),
            dataType: yup.string().required(t('error.required')),
            value_Integer: yup.number().when('dataType', {
              is: (dataType: string) => dataType === 'integer',
              then: yup
                .number()
                .required(t('error.required'))
                .typeError(t('error.decimal'))
                .max(
                  signedInteger,
                  t('error.maxInteger', { maxValue: signedInteger }),
                ),
            }),
            value_Decimal: yup.number().when('dataType', {
              is: (dataType: string) => dataType === 'decimal',
              then: yup
                .number()
                .required(t('error.required'))
                .typeError(t('error.decimal'))
                .max(
                  signedInteger,
                  t('error.maxInteger', { maxValue: signedInteger }),
                ),
            }),
            value_DateTime: yup.string().when('dataType', {
              is: (dataType: string) => dataType === 'dateTime',
              then: yup.string().required(t('error.required')),
            }),
            value_Text: yup.string().when('dataType', {
              is: (dataType: string) => dataType === 'text',
              then: yup.string().required(t('error.required')),
            }),
            value_Boolean: yup.boolean().when('dataType', {
              is: (dataType: string) => dataType === 'boolean',
              then: yup
                .boolean()
                .required(t('error.required'))
                .typeError(t('error.boolean')),
            }),
          }),
        ),
      }),
    [t],
  )

  const diStrategySchema = useMemo(
    () =>
      yup.object({
        newData: yup.array(
          yup.object({
            chineseName: yup.string().required(t('error.required')),
            englishName: yup.string().required(t('error.required')),
            order: yup
              .number()
              .max(
                signedInteger,
                t('error.maxInteger', { maxValue: signedInteger }),
              )
              .required(t('error.required')),
            chineseDetail: yup.string(),
            englishDetail: yup.string(),
            colorCode: yup.string().required(t('error.required')),
            diStrategyClassId: yup.string().required(t('error.required')),
            diStrategyTags: yup.array().of(
              yup.object({
                id: yup.string(),
                colorCode: yup.string(),
                label: yup.string(),
                value: yup.string(),
              }),
            ),
            displayVideo: yup.object({
              chineseName: yup.string(),
              englishName: yup.string(),
              order: yup.number(),
              resourceType: yup.string(),
              file: yup.object({
                url: yup.string().url(t('error.invalidUrlFormat')),
                source: yup.string(),
              }),
              id: yup.string(),
            }),
            videos: yup.array().of(
              yup.object({
                chineseName: yup.string().required(t('error.required')),
                englishName: yup.string().required(t('error.required')),
                order: yup.number(),
                resourceType: yup.string(),
                file: yup.object({
                  url: yup
                    .string()
                    .required(t('error.required'))
                    .url(t('error.invalidUrlFormat')),
                  source: yup.string(),
                }),
                id: yup.string(),
              }),
            ),
            furtherReadingAndExamples: yup.array().of(
              yup.object({
                chineseName: yup.string().required(t('error.required')),
                englishName: yup.string().required(t('error.required')),
                order: yup.number(),
                resourceType: yup.string(),
                file: yup.object({
                  url: yup
                    .string()
                    .required(t('error.required'))
                    .url(t('error.invalidUrlFormat')),
                  source: yup.string(),
                }),
                id: yup.string(),
              }),
            ),
          }),
        ),
      }),
    [t],
  )

  const diStrategyClassSchema = useMemo(
    () =>
      yup.object({
        newData: yup.array(
          yup.object({
            chineseName: yup.string().required(t('error.required')),
            englishName: yup.string().required(t('error.required')),
            order: yup
              .number()
              .max(
                signedInteger,
                t('error.maxInteger', { maxValue: signedInteger }),
              ),
          }),
        ),
      }),
    [t],
  )

  const learningPhaseSchema = useMemo(() => {
    return yup.object({
      newData: yup.array(
        yup.object({
          chineseName: yup.string().required(t('error.required')),
          englishName: yup.string().required(t('error.required')),
          colorCode: yup
            .string()
            .required(t('error.required'))
            .matches(hexCodeFormat, t('error.invalidHexFormat')),
          educationLevelId: yup.string().required(t('error.required')),
          order: yup
            .number()
            .max(
              signedInteger,
              t('error.maxInteger', { maxValue: signedInteger }),
            ),
        }),
      ),
    })
  }, [t])

  const classLevelSchema = useMemo(() => {
    return yup.object({
      newData: yup.array(
        yup.object({
          chineseName: yup.string().required(t('error.required')),
          englishName: yup.string().required(t('error.required')),
          colorCode: yup
            .string()
            .required(t('error.required'))
            .matches(hexCodeFormat, t('error.invalidHexFormat')),
          learningPhaseId: yup.string().required(t('error.required')),
          order: yup
            .number()
            .max(
              signedInteger,
              t('error.maxInteger', { maxValue: signedInteger }),
            ),
        }),
      ),
    })
  }, [t])

  const defaultSchema = useMemo(() => {
    return yup.object({
      newData: yup.array(
        yup.object({
          chineseName: yup.string().required(t('error.required')),
          englishName: yup.string().required(t('error.required')),
          colorCode: yup
            .string()
            .required(t('error.required'))
            .matches(hexCodeFormat, t('error.invalidHexFormat')),
          order: yup
            .number()
            .max(
              signedInteger,
              t('error.maxInteger', { maxValue: signedInteger }),
            ),
        }),
      ),
    })
  }, [t])

  const targetSchema = useMemo(() => {
    switch (masterDataTable) {
      case 'classLevels':
        return classLevelSchema
      case 'diStrategyClasses':
        return diStrategyClassSchema
      case 'learningPhases':
        // eslint-disable-next-line no-nested-ternary
        return filterId == null || filterId === 'learningPhases'
          ? learningPhaseSchema
          : filterId === 'classLevels'
          ? classLevelSchema
          : defaultSchema
      case 'systemParameters':
        return systemParameterSchema
      case 'tagTypes':
        return tagTypeSchema
      case 'diStrategies':
        return diStrategySchema
      case 'tagGroups':
        return tagGroupSchema
      case 'subjects':
        return subjectSchema
      default:
        return defaultSchema
    }
  }, [
    masterDataTable,
    classLevelSchema,
    diStrategyClassSchema,
    filterId,
    learningPhaseSchema,
    defaultSchema,
    systemParameterSchema,
    tagTypeSchema,
    diStrategySchema,
    tagGroupSchema,
    subjectSchema,
  ])

  return {
    targetSchema,
  }
}
