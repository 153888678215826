import { useMemo } from 'react'
import { useIsAuthenticated } from '@azure/msal-react'
import { useStores } from '../../stores'
import {
  useClassLevels,
  useDiStrategies,
  useDiStrategyClasses,
  useEducationLevels,
  useLearningPhases,
  useLearningUnits,
  useResourceTypes,
  useSubjects,
  useTags,
  useTagTypes,
  useTeachingClasses,
} from '@polyu-dip/queries'

const queryParameters = {}

const queryOptions = {
  cacheTime: Infinity,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
}

export function useTestInit() {
  const rootStore = useStores()
  const isAuthenticated = useIsAuthenticated()

  const isReady: boolean = useMemo(
    () =>
      isAuthenticated &&
      rootStore.userProfileStore.userSessionInfo.accessToken != null,
    [isAuthenticated, rootStore.userProfileStore.userSessionInfo.accessToken],
  )

  useResourceTypes(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })

  useLearningUnits(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })

  useEducationLevels(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })

  useTeachingClasses(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })

  useDiStrategies(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })

  useTags(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })

  useSubjects(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })

  useLearningPhases(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })

  useClassLevels(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })

  useDiStrategyClasses(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })

  useTagTypes(useStores, queryParameters, {
    enabled: isReady,
    ...queryOptions,
  })
}
