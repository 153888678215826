import { Box, Grid } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../../../assets'
import { TextFieldList, Text, TagGroupTag } from '../../../../../components'
import { useLessonPlanDetail } from '../../../use-lesson-plan-detail-context'

const TickIconWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  background: ${({ theme }) => theme.palettes.general.white};
  border: 1px solid #828282;
  border-radius: 30px;
`

type Props = {
  isExample?: boolean
  isHideTeachingClass?: boolean
}

export const Overview = observer<Props>(
  ({ isExample, isHideTeachingClass }) => {
    const { t } = useTranslation()

    const { lessonPlan } = useLessonPlanDetail()

    const list = useComputed(() => {
      return [
        {
          isRowDirection: true,
          label: t('lessonPlan.detail.general.teachingPlanTab.educationLevel'),
          render: (
            <Grid container alignItems="center" ml={4}>
              <Grid item flexShrink={0} mr={2}>
                <TickIconWrapper>
                  <Svg.Tick />
                </TickIconWrapper>
              </Grid>
              <Text>
                {
                  lessonPlan.classLevel?.learningPhase?.educationLevel
                    ?.displayName
                }
              </Text>
            </Grid>
          ),
        },
        {
          isRowDirection: true,
          label: t('lessonPlan.detail.general.teachingPlanTab.classLevel'),
          render: (
            <Grid container alignItems="center" ml={4}>
              <Grid item flexShrink={0} mr={2}>
                <TickIconWrapper>
                  <Svg.Tick />
                </TickIconWrapper>
              </Grid>
              <Text>{lessonPlan.classLevel?.displayName}</Text>
            </Grid>
          ),
        },
        {
          label: t('lessonPlan.detail.general.teachingPlanTab.subject'),
          value: lessonPlan.subject?.displayName,
        },
        {
          label: t('lessonPlan.detail.general.teachingPlanTab.learningUnit'),
          value: lessonPlan.learningUnit,
        },
        {
          label: t('lessonPlan.detail.general.teachingPlanTab.teachingTopic'),
          value: lessonPlan.teachingTopic,
        },
        {
          fieldXs: 3,
          label: t('lessonPlan.detail.general.teachingPlanTab.sectionTotal'),
          value: lessonPlan.sectionTotal,
        },
        {
          fieldXs: 3,
          label: t('lessonPlan.detail.general.teachingPlanTab.sectionDuration'),
          value: `${lessonPlan.sectionDuration} ${t(
            'lessonPlan.detail.general.teachingPlanTab.durationUnit',
          )}`,
        },
        {
          label: t('lessonPlan.detail.general.teachingPlanTab.academicYear'),
          value:
            lessonPlan.academicYear != null
              ? t(
                  'lessonPlan.createForm.generalInformation.fields.academicYear.displayFormat',
                  {
                    academicYearStart: lessonPlan.academicYear,
                    academicYearEnd: lessonPlan.academicYear + 1,
                  },
                )
              : undefined,
        },
        {
          hidden: (isExample ?? false) || (isHideTeachingClass ?? false),
          label: t('lessonPlan.detail.general.teachingPlanTab.teachingClass'),
          value: lessonPlan.teachingClass,
        },
        {
          fieldXs: 12,
          label: t(
            'lessonPlan.detail.general.teachingPlanTab.lessonPlanTags.classCharacteristic',
          ),
          render: (
            <Grid container direction="row" gap={2}>
              {lessonPlan.lessonPlanTags.map((lpTag) => (
                <Grid item key={lpTag.id}>
                  {lpTag.tag && <TagGroupTag tag={lpTag.tag} />}
                </Grid>
              ))}
            </Grid>
          ),
        },
      ]
    }, [
      lessonPlan.classLevel,
      lessonPlan.subject,
      lessonPlan.learningUnit,
      lessonPlan.teachingTopic,
      lessonPlan.sectionTotal,
      lessonPlan.sectionDuration,
      lessonPlan.academicYear,
      lessonPlan.teachingClass,
      lessonPlan.lessonPlanTags,
      t,
    ])

    return <TextFieldList items={list} />
  },
)
