import { Box, Button, Grid, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { notEmpty } from '@polyu-dip/utilities'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import { Tag, TruncatedCellContent } from '../../../components'
import { useStores } from '../../../stores'
import { useLessonPlanDetail } from '../use-lesson-plan-detail-context'

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palettes.general.white};
  box-shadow: 0px 0px 4px #828282;
  border-radius: 20px;
  padding: 12px 20px;
`

const StyledTypography = styled(Typography)`
  padding: 4px 12px;
`

const StyledTitle = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.palettes.general.greys[2]};
`

type Props = {
  isExample?: boolean
  isPublishCopy?: boolean
  isDisplayPublishStatus?: boolean
  isDisplayAgreePublish?: boolean
} & (
  | {
      printable?: undefined
      onRequestPrint(): void
      onRequestCopy?: () => void
    }
  | {
      printable: true
      onRequestPrint?: undefined
      onRequestCopy?: undefined
    }
)

const ItemColumn = styled(Grid)`
  flex-direction: column;
  display: flex;
`
const ItemContent = styled(Grid)`
  display: flex;
  align-items: center;
  flex: 1;
`

export const BasicInformation = observer<Props>(
  ({
    printable,
    onRequestPrint,
    onRequestCopy,
    isExample,
    isPublishCopy,
    isDisplayPublishStatus,
    isDisplayAgreePublish,
  }) => {
    const { t } = useTranslation()
    const { userProfileStore } = useStores()

    const { lessonPlan } = useLessonPlanDetail()

    const lessonPlanSharing = useComputed(() => {
      return lessonPlan.lessonPlanSharings.find(
        (sharing) => sharing.sharedToUserId == userProfileStore.userProfile?.id,
      )
    }, [lessonPlan.lessonPlanSharings, userProfileStore.userProfile?.id])

    const lessonPlanPublishedDate = useComputed(
      () =>
        lessonPlan.latestSourcePublishRequest?.isPublishing
          ? dayjs(lessonPlan.latestSourcePublishRequest?.publishedDateTime)
          : undefined,
      [lessonPlan],
    )

    const basicInformation = useComputed(
      () =>
        [
          {
            render: (
              <Grid height="100%" container direction="row">
                <Grid item xs={12}>
                  <Grid container wrap="nowrap">
                    <Grid mr={2} minWidth={45}>
                      <StyledTitle fontSize={14}>
                        {t('lessonPlan.detail.basic.school')}
                      </StyledTitle>
                    </Grid>
                    <Grid overflow="hidden">
                      <TruncatedCellContent
                        content={lessonPlan.ownedByUser?.school?.displayName}
                        maxContentWidth={100}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!isExample && (
                  <Grid item xs={12}>
                    <Grid container wrap="nowrap">
                      <Grid mr={2} minWidth={45}>
                        <StyledTitle fontSize={14}>
                          {t('lessonPlan.detail.basic.owner')}
                        </StyledTitle>
                      </Grid>
                      <Grid overflow="hidden">
                        <TruncatedCellContent
                          content={lessonPlan.ownedByUser?.displayName}
                          maxContentWidth={150}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ),

            width: 2.5,
            containerProps: {
              flexDirection: 'row',
              alignItems: 'center',
            },
          },
          {
            render: (
              <Grid height="100%" container direction="row">
                <Grid item xs={12}>
                  <Grid container wrap="nowrap">
                    <Grid mr={2} minWidth={65}>
                      <StyledTitle fontSize={14}>
                        {isExample
                          ? t('lessonPlan.detail.basic.datePublished')
                          : t('lessonPlan.detail.basic.dateCreated')}
                      </StyledTitle>
                    </Grid>
                    <Grid minWidth={235}>
                      <Typography>
                        {
                          // eslint-disable-next-line no-nested-ternary
                          isExample
                            ? lessonPlanPublishedDate != null
                              ? lessonPlanPublishedDate.format(
                                  t('common.format.date'),
                                )
                              : '---'
                            : lessonPlan.createdTimeDayJs.format(
                                t('common.format.datetime'),
                              )
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container wrap="nowrap">
                    <Grid mr={2} minWidth={65}>
                      <StyledTitle fontSize={14}>
                        {t('lessonPlan.detail.basic.latestUpdated')}
                      </StyledTitle>
                    </Grid>
                    <Grid minWidth={235}>
                      <Typography>
                        {lessonPlanSharing?.createdTimeDayJs.format(
                          t('common.format.datetime'),
                        ) ??
                          lessonPlan.lastModifiedTimeDayJs.format(
                            t('common.format.datetime'),
                          ) ??
                          '---'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ),
            minWidth: 300,
            width: 2,
            containerProps: {
              flexDirection: 'row',
              alignItems: 'center',
            },
          },
          {
            label: t('lessonPlan.detail.basic.subjectRelated'),
            value: (
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item>
                  <Tag
                    label={lessonPlan.classLevel?.learningPhase?.displayName}
                    colorCustomize={
                      lessonPlan.classLevel?.learningPhase?.colorCode
                    }
                  />
                </Grid>
                <Grid item>
                  <Tag
                    label={lessonPlan.classLevel?.displayName}
                    colorCustomize={lessonPlan.classLevel?.colorCode}
                  />
                </Grid>
                <Grid item>
                  <Tag
                    label={lessonPlan.subject?.displayName}
                    colorCustomize={lessonPlan.subject?.colorCode}
                  />
                </Grid>
              </Grid>
            ),
            minWidth: 200,
            width: 2.5,
          },
          printable
            ? undefined
            : {
                label: t('lessonPlan.detail.basic.actionOptions'),
                value: (
                  <Grid
                    container
                    columnSpacing={2}
                    alignItems="center"
                    rowSpacing={2}
                  >
                    <Grid
                      item
                      width={
                        onRequestCopy != null && !isPublishCopy ? 100 : 120
                      }
                      flexShrink={0}
                    >
                      <Button
                        fullWidth
                        startIcon={<Svg.Export />}
                        onClick={onRequestPrint}
                      >
                        {t('common.export')}
                      </Button>
                    </Grid>
                    {onRequestCopy != null && !isPublishCopy && (
                      <Grid item width={100} flexShrink={0}>
                        <Button
                          fullWidth
                          startIcon={<Svg.Copy />}
                          onClick={onRequestCopy}
                        >
                          {t('common.duplicate')}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                ),
                containerProps: {
                  alignItems: 'center',
                },
                minWidth: 120,
                width: onRequestCopy != null ? 2 : 1.5,
              },
          printable
            ? undefined
            : {
                hidden: isExample,
                label: t('lessonPlan.detail.basic.consultDisplayStatus'),
                width: 1,
                value: (
                  <Grid
                    container
                    columnSpacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      {lessonPlan.consultDisplayStatus?.length &&
                      lessonPlan.consultDisplayStatus !== 'noPendingRequest' ? (
                        <Tag
                          isStatus
                          statusType="consultRequest"
                          label={lessonPlan.consultDisplayStatus}
                        />
                      ) : (
                        <StyledTypography>---</StyledTypography>
                      )}
                    </Grid>
                  </Grid>
                ),
                minWidth: 100,
              },
          printable
            ? undefined
            : {
                hidden: isExample && !isDisplayPublishStatus,
                label: t('lessonPlan.detail.basic.publishDisplayStatus'),
                width: 1,
                value: (
                  <Grid container columnSpacing={2}>
                    <Grid item>
                      {lessonPlan.publishDisplayStatus?.length ? (
                        <Tag
                          isStatus
                          statusType="publishRequest"
                          label={lessonPlan.publishDisplayStatus}
                        />
                      ) : (
                        <StyledTypography>---</StyledTypography>
                      )}
                    </Grid>
                  </Grid>
                ),
                minWidth: 100,
              },
          printable
            ? undefined
            : {
                hidden: !isDisplayAgreePublish,
                label: t('lessonPlan.detail.basic.isAgreePublish'),
                width: 1,
                value: (
                  <Grid container columnSpacing={2}>
                    <Grid item>
                      {lessonPlan.isAgreePublish != null ? (
                        <Tag
                          label={
                            lessonPlan.isAgreePublish
                              ? t('common.yes')
                              : t('common.no')
                          }
                        />
                      ) : (
                        <StyledTypography>---</StyledTypography>
                      )}
                    </Grid>
                  </Grid>
                ),
                minWidth: 100,
              },
        ].filter(notEmpty),
      [
        lessonPlanPublishedDate,
        lessonPlan.classLevel?.displayName,
        lessonPlan.classLevel?.learningPhase?.displayName,
        lessonPlan.createdTimeDayJs,
        lessonPlan.lastModifiedTimeDayJs,
        lessonPlan.sectionTotal,
        lessonPlan.subject?.displayName,
        lessonPlan.teacherUser?.school?.displayName,
        onRequestPrint,
        t,
      ],
    )

    return (
      <Box>
        <Container>
          <Grid container justifyContent="space-between">
            {basicInformation.map((it) =>
              it.hidden ? null : (
                <ItemColumn
                  key={it.label}
                  item
                  xs={printable ? 'auto' : it.width}
                  minWidth={it?.minWidth}
                >
                  {it.render != null ? (
                    it.render
                  ) : (
                    <Grid
                      container
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...it.containerProps}
                    >
                      <StyledTitle mb={3} fontSize={14}>
                        {it.label}
                      </StyledTitle>
                    </Grid>
                  )}
                  <ItemContent>
                    <Box>{it.value}</Box>
                  </ItemContent>
                </ItemColumn>
              ),
            )}
          </Grid>
        </Container>
      </Box>
    )
  },
)
