import { Grid, Typography } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { HighlightedGrid } from '../../../components'

type GroupHeaderProps = {
  groupCharacteristic?: string
}

const StyledTypography = styled(Typography)`
  word-break: break-word;
`

export const GroupHeader = memo<GroupHeaderProps>(({ groupCharacteristic }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <HighlightedGrid container mb={2}>
      <Grid item flexShrink={0} sm={2}>
        <Typography color={theme.palettes.general.white} fontWeight={600}>
          {t(
            'lessonPlan.createForm.generalInformation.fields.basedOnStudentTraitGroupsTeachingType.characteristic.label',
          )}
        </Typography>
      </Grid>
      <Grid item pl={5} sm={10}>
        <StyledTypography color={theme.palettes.general.white} fontWeight={600}>
          {groupCharacteristic}
        </StyledTypography>
      </Grid>
    </HighlightedGrid>
  )
})
