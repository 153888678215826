export const common = {
  version: '版本 {{version}}',
  setting: '設定',
  logout: '登出',
  tbi: '此部分功能仍在建構中',
  forbidden: '沒有存取權限',
  more: '更多',
  submit: '提交',
  yes: '是',
  no: '否',
  confirm: '確定',
  cancel: '取消',
  delete: '刪除',
  add: '新增',
  search: '搜尋',
  clear: '清空',
  reset: '重設',
  change: '更改',
  warning: '警告',
  next: '下一個',
  approve: '批核',
  post: '提出',
  continue: '繼續',
  new: '新增',
  export: '匯出',
  duplicate: '複製',
  download: '下載',
  close: '關閉',

  view: '查看',
  edit: '修改',

  discard: '捨棄',
  back: '返回',
  save: '儲存',

  to: '由',
  from: '至',

  ok: '同意',

  today: '今天',
  format: {
    datetime: '$t(common.format.date) HH:mm',
    date: 'YYYY 年 M 月 D 日',
    dayMonthNameYear: 'DD MMM, YYYY',
    datepicker: 'DD/MM/YYYY',
    dateTimePicker: 'DD/MM/YYYY HH:mm',
    dateBefore: '{{diff}}天前',
  },

  retry: '重試',

  minute: '分鐘',

  sort: '排序',

  noRecord: '目前尚未有紀錄',
  remark: '注意：此操作將無法復原',
  noOptions: '沒有相符選項',
}
