import { GridColDef, GridSortModel } from '@mui/x-data-grid'
import { useComputed } from '@polyu-dip/helpers'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ListPage,
  Table,
  Tag,
  TruncatedCellContent,
  UserTag,
} from '../../components'
import { contentPaths } from '../../content-paths'
import { PaginationParameters } from '@polyu-dip/apis'
import { useLessonPlansShared } from '@polyu-dip/queries'
import { useStores } from '../../stores'
import { LessonPlan } from '@polyu-dip/models'
import { useNavigate } from 'react-router-dom'
import { useDisplayName } from '../../services'
import { useMasterDataInit } from '../../hooks'

const PAGINATION_LIMIT = 15

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

export const LessonPlanCommunication = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getDisplayName } = useDisplayName()
  const { isAllMasterDataFetchSuccess } = useMasterDataInit()
  const { userProfileStore } = useStores()

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [sharedTableSort, setSharedTableSort] = useState<GridSortModel>()

  const { data: sharedLessonPlanData, isLoading: isSharedLessonPlanLoading } =
    useLessonPlansShared(useStores, {
      sort: sharedTableSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [
        { parameter: 'lessonPlanSharing.createdDateTime', direction: 'desc' },
      ],
      ...pagination,
    })

  const [rowCountState, setRowCountState] = useState(
    sharedLessonPlanData?.total ?? 0,
  )

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      sharedLessonPlanData?.total !== undefined
        ? sharedLessonPlanData.total
        : prevRowCountState,
    )
  }, [sharedLessonPlanData?.total])

  const columns: GridColDef<LessonPlan>[] = [
    {
      field: 'classLevel.order',
      headerName: t('lessonPlanCommunication.tableFields.classLevel'),
      width: 100,
      renderCell: ({ row }) => (
        <Tag
          label={getDisplayName(row.classLevel?.name)}
          colorCustomize={row.classLevel?.colorCode}
        />
      ),
    },
    {
      field: 'subject.order',
      headerName: t('lessonPlanCommunication.tableFields.subject'),
      width: 200,
      renderCell: ({ row }) => (
        <Tag
          label={getDisplayName(row.subject?.name)}
          colorCustomize={row.subject?.colorCode}
        />
      ),
    },
    {
      field: 'lessonPlanSharing.createdDateTime',
      headerName: t(
        'lessonPlanCommunication.tableFields.sharingCreatedDateTime',
      ),
      renderCell: ({ row }: any) =>
        row.lessonPlanSharing?.createdDateTime
          ? dayjs(row.lessonPlanSharing?.createdDateTime).format(
              t('common.format.date'),
            )
          : '---',
      width: 150,
    },
    {
      field: 'ownedByUser.name',
      headerName: t('lessonPlanCommunication.tableFields.ownedByUserName'),
      width: 200,
      renderCell: ({ row }) => (
        <TruncatedCellContent
          content={row.ownedByUser?.name}
          maxContentWidth={100}
        />
      ),
    },
    {
      field: 'teachingTopic',
      headerName: t('lessonPlanCommunication.tableFields.teachingTopic'),
      width: 200,
      renderCell: ({ row }) => (
        <TruncatedCellContent
          content={row.teachingTopic}
          maxContentWidth={100}
        />
      ),
    },
    {
      field: 'latestLessonPlanComment.createdByUser.name',
      headerName: t(
        'lessonPlanCommunication.tableFields.latestCommentCreatedBy',
      ),
      width: 200,
      renderCell: ({ row }) =>
        row.latestLessonPlanComment?.createdByUser.name != null ? (
          <UserTag
            userName={getDisplayName(
              row.latestLessonPlanComment?.createdByUser.name,
            )}
            isExpert={row.latestLessonPlanComment?.isExpert}
            userNameMaxContentWidth={100}
          />
        ) : (
          '---'
        ),
    },
    {
      field: 'latestLessonPlanComment.createdDateTime',
      headerName: t('lessonPlanCommunication.tableFields.latestComment'),
      renderCell: ({ row }: any) =>
        row.latestLessonPlanComment?.createdDateTime
          ? dayjs(row.latestLessonPlanComment?.createdDateTime).format(
              t('common.format.datetime'),
            )
          : '---',
      width: 200,
    },
  ]

  const computedLessonPlanSharedData = useComputed(
    () =>
      (sharedLessonPlanData?.data ?? []).map((it) => ({
        ...it,
        latestLessonPlanComment: {
          ...it.latestLessonPlanComment,
          createdByUser: {
            ...it.latestLessonPlanComment?.createdByUser,
          },
        },
        classLevel: {
          ...it.classLevel,
        },
        subject: {
          ...it.subject,
        },
        ownedByUser: {
          ...it.ownedByUser,
        },
        lessonPlanSharing: {
          ...it.lessonPlanSharings.filter(
            (sharing) =>
              sharing.sharedToUserId == userProfileStore.userProfile?.id,
          )?.[0],
        },
      })),
    [sharedLessonPlanData],
  )

  return (
    <ListPage
      sectionTitleProps={{
        title: t('lessonPlanCommunication.title'),
      }}
    >
      <Table
        columns={columns}
        rows={computedLessonPlanSharedData}
        pageSize={PAGINATION_LIMIT}
        loading={!isAllMasterDataFetchSuccess || isSharedLessonPlanLoading}
        paginationMode="server"
        page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
        hideFooterPagination={
          rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
        }
        initialState={{
          sorting: {
            sortModel: [
              { field: 'lessonPlanSharing.createdDateTime', sort: 'desc' },
            ],
          },
        }}
        rowCount={rowCountState}
        sortModel={sharedTableSort}
        onSortModelChange={setSharedTableSort}
        onRowClick={({ row }) =>
          navigate(contentPaths.sharedLessonPlans(row.id))
        }
        onPageChange={(page) =>
          setPagination((old) => ({
            ...old,
            offset: page * PAGINATION_LIMIT,
          }))
        }
      />
    </ListPage>
  )
})
