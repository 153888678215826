/* eslint-disable no-nested-ternary */
import { Box, Grid } from '@mui/material'
import { SystemParameterDataType } from '@polyu-dip/models'
import { MasterDataTable } from '@polyu-dip/stores'
import { getInputNumberValue } from '@polyu-dip/utilities'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Control,
  Controller,
  FieldErrorsImpl,
  useFieldArray,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Svg } from '../../assets'
import {
  AutoComplete,
  DateTimePicker,
  Dropdown,
  SelectOption,
  TextField,
} from '../form'
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input'
import styled from 'styled-components'
import { useComputed } from '@polyu-dip/helpers'
import { useMasterData } from '../../hooks'
import { Tag } from '../tag'

export type MasterDataFormExtraColumn =
  | 'learningPhase'
  | 'educationLevel'
  | 'diStrategyClass'
  | 'tagType'

type DiStrategyResourceFormData = {
  chineseName?: string
  englishName?: string
  order: number
  resourceType: string
  file: {
    url?: string
    source: string
  }
  id?: string
}

export type FormDataField = {
  chineseName: string
  englishName: string
  colorCode?: string
  order?: number

  // learning phase
  educationLevelId?: string

  // class level
  learningPhaseId?: string

  //di strategy
  diStrategyClassId?: string

  // Subject
  educationLevelIds?: {
    label: string
    value: string
    disabled: boolean
  }[]

  //di strategy detail
  chineseDetail?: string
  englishDetail?: string
  diStrategyTags?: {
    id?: string
    colorCode: string
    label: string
    value: string
  }[]
  tagGroupTags?: {
    id?: string
    chineseName: string
    englishName: string
    isDisabled?: boolean
  }[]
  displayVideo?: DiStrategyResourceFormData
  videos?: DiStrategyResourceFormData[]
  furtherReadingAndExamples?: DiStrategyResourceFormData[]

  //di strategy class
  label?: string

  //tag
  tagTypeId?: string
  subjectId?: string

  // tag groups
  isMultiSelect?: boolean

  //system parameter
  valueCheck?: boolean
  name: string
  description?: string
  category?: string
  dataType?: string
  value_Integer?: number
  value_Decimal?: number
  value_DateTime?: string
  value_Text?: string
  value_Boolean?: boolean
}

export type NewFormData = {
  newData: FormDataField[]
}

type Props = {
  mode: 'create' | 'edit'
  masterDataTable: MasterDataTable
  hasHexColor: boolean
  masterDataFormExtraColumn?: MasterDataFormExtraColumn
  control: Control<NewFormData, any>
  errors?: Partial<FieldErrorsImpl<NewFormData>>
  allowAppendRow?: boolean
  allowDeleteRow?: boolean
  watch: UseFormWatch<NewFormData>
  setValue: UseFormSetValue<NewFormData>
  formOptions?: SelectOption[]
  getValues: UseFormGetValues<NewFormData>
  trigger: UseFormTrigger<NewFormData>
}

const fieldName = 'newData'

type DataType =
  | SystemParameterDataType.boolean
  | SystemParameterDataType.dateTime
  | SystemParameterDataType.decimal
  | SystemParameterDataType.integer
  | SystemParameterDataType.text

const systemParameterDataTypeOptions = [
  {
    label: SystemParameterDataType.boolean,
    value: 'boolean',
  },
  {
    label: SystemParameterDataType.dateTime,
    value: 'dateTime',
  },
  {
    label: SystemParameterDataType.decimal,
    value: 'decimal',
  },
  {
    label: SystemParameterDataType.integer,
    value: 'integer',
  },
  {
    label: SystemParameterDataType.text,
    value: 'text',
  },
]

const StyledMuiColorInput = styled(MuiColorInput)`
  width: 0px;
`

export const MasterDataList = observer<Props>(
  ({
    mode,
    masterDataTable,
    hasHexColor,
    masterDataFormExtraColumn,
    control,
    errors,
    allowAppendRow,
    allowDeleteRow,
    watch,
    setValue,
    formOptions,
    getValues,
    trigger,
  }) => {
    const { t } = useTranslation()
    const {
      fields: newMasterDataFields,
      remove: removeNewMasterDataField,
      append: appendNewMasterField,
    } = useFieldArray({
      control,
      name: fieldName,
    })

    const [isDataTypeChanged, setIsDataTypeChanged] = useState<
      number | undefined
    >()

    const onNumberChange = useCallback(
      (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldNumber: number,
        targetFieldName:
          | 'order'
          | SystemParameterDataType.integer
          | SystemParameterDataType.decimal,
      ) => {
        const numberValue = getInputNumberValue(e.target.value)
        setValue(`newData.${fieldNumber}.${targetFieldName}`, numberValue)
      },
      [setValue],
    )

    const resetValue = useCallback(
      (index: number) => {
        setValue(
          `${fieldName}.${index}.${SystemParameterDataType.boolean}`,
          undefined,
        )
        setValue(
          `${fieldName}.${index}.${SystemParameterDataType.dateTime}`,
          undefined,
        )
        setValue(
          `${fieldName}.${index}.${SystemParameterDataType.decimal}`,
          undefined,
        )
        setValue(
          `${fieldName}.${index}.${SystemParameterDataType.integer}`,
          undefined,
        )
        setValue(
          `${fieldName}.${index}.${SystemParameterDataType.text}`,
          undefined,
        )
        setIsDataTypeChanged(undefined)
      },
      [setValue],
    )

    const handleDropdownOnChange = useCallback(
      (index: number, value: string) => {
        setIsDataTypeChanged(index)
        setValue(`${fieldName}.${index}.dataType`, value)
      },
      [setValue],
    )

    const getDataType = useCallback(
      (index: number): DataType => {
        const selectedDataType = watch(`${fieldName}.${index}.dataType`)
        switch (selectedDataType) {
          case 'integer':
            return SystemParameterDataType.integer
          case 'decimal':
            return SystemParameterDataType.decimal
          case 'dateTime':
            return SystemParameterDataType.dateTime
          case 'text':
            return SystemParameterDataType.text
          case 'boolean':
            return SystemParameterDataType.boolean
          default:
            return SystemParameterDataType.boolean
        }
      },
      [watch],
    )

    const extraColumnIndex = useComputed(() => {
      return masterDataFormExtraColumn === 'educationLevel'
        ? 'educationLevelId'
        : masterDataFormExtraColumn === 'diStrategyClass'
        ? 'diStrategyClassId'
        : masterDataFormExtraColumn === 'tagType'
        ? 'tagTypeId'
        : 'learningPhaseId'
    }, [masterDataFormExtraColumn])

    const extraColumnValueOnOpen = useComputed(() => {
      return watch(`${fieldName}.0.${extraColumnIndex}`)
    }, [fieldName])

    useEffect(() => {
      if (isDataTypeChanged == null) return
      resetValue(isDataTypeChanged)
    }, [isDataTypeChanged, resetValue])

    const { educationLevelOptions, educationLevels } = useMasterData()

    const computedEducationLevelOptions = useMemo(() => {
      const activeIds =
        mode === 'edit' ? watch(`${fieldName}.0.educationLevelIds`) ?? [] : []

      return educationLevelOptions.filter(
        (it) => !it.disabled || activeIds.some((id) => it.value === id.value),
      )
    }, [educationLevelOptions, mode, watch])

    const renderDataTypeField = useCallback(
      (index: number, dataType: DataType) => {
        if (dataType === SystemParameterDataType.boolean) {
          return (
            <Controller
              control={control}
              name={`${fieldName}.${index}.${SystemParameterDataType.boolean}`}
              render={({ field }) => (
                <Dropdown
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  options={[
                    {
                      label: t('common.yes'),
                      value: 'true',
                    },
                    {
                      label: t('common.no'),
                      value: 'false',
                    },
                  ]}
                  placeholder={t(
                    `masterData.addMasterDataDialog.field.${SystemParameterDataType.boolean}.placeholder`,
                  )}
                  error={
                    errors?.newData?.[index]?.[SystemParameterDataType.boolean]
                      ?.message
                  }
                  isReserverForHelperText
                />
              )}
            />
          )
        }
        if (dataType === SystemParameterDataType.dateTime) {
          return (
            <Controller
              control={control}
              name={`${fieldName}.${index}.${SystemParameterDataType.dateTime}`}
              render={({ field }) => (
                <DateTimePicker
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  value={
                    (getValues(
                      `${fieldName}.${index}.${SystemParameterDataType.dateTime}`,
                    ) as string) ?? null
                  }
                  error={
                    errors?.newData?.[index]?.[SystemParameterDataType.dateTime]
                      ?.message
                  }
                />
              )}
            />
          )
        }
        if (dataType === SystemParameterDataType.decimal) {
          return (
            <Controller
              control={control}
              name={`${fieldName}.${index}.${SystemParameterDataType.decimal}`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  type="number"
                  inputProps={{ min: 0 }}
                  decimalPlace={2}
                  value={
                    getValues(
                      `${fieldName}.${index}.${SystemParameterDataType.decimal}`,
                    ) ?? ''
                  }
                  onChange={(e) => {
                    onNumberChange(e, index, SystemParameterDataType.decimal)
                  }}
                  placeholder={t(
                    `masterData.addMasterDataDialog.field.${SystemParameterDataType.decimal}.placeholder`,
                  )}
                  error={
                    errors?.newData?.[index]?.[SystemParameterDataType.decimal]
                      ?.message
                  }
                  isReserverForHelperText
                />
              )}
            />
          )
        }
        if (dataType === SystemParameterDataType.integer) {
          return (
            <Controller
              control={control}
              name={`${fieldName}.${index}.${SystemParameterDataType.integer}`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  type="number"
                  inputProps={{ min: 0 }}
                  decimalPlace={0}
                  value={
                    getValues(
                      `${fieldName}.${index}.${SystemParameterDataType.integer}`,
                    ) ?? ''
                  }
                  onChange={(e) => {
                    onNumberChange(e, index, SystemParameterDataType.integer)
                  }}
                  placeholder={t(
                    `masterData.addMasterDataDialog.field.${SystemParameterDataType.integer}.placeholder`,
                  )}
                  error={
                    errors?.newData?.[index]?.[SystemParameterDataType.integer]
                      ?.message
                  }
                  isReserverForHelperText
                />
              )}
            />
          )
        }
        return (
          <Controller
            control={control}
            name={`${fieldName}.${index}.${SystemParameterDataType.text}`}
            render={({ field }) => (
              <TextField
                multiline
                maxRows={5}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                placeholder={t(
                  `masterData.addMasterDataDialog.field.${SystemParameterDataType.text}.placeholder`,
                )}
                value={
                  getValues(
                    `${fieldName}.${index}.${SystemParameterDataType.text}`,
                  ) ?? ''
                }
                error={
                  errors?.newData?.[index]?.[SystemParameterDataType.text]
                    ?.message
                }
                isReserverForHelperText
              />
            )}
          />
        )
      },
      [control, errors?.newData, getValues, onNumberChange, t],
    )

    return (
      <Box>
        {newMasterDataFields.map((newMasterDataField, index) => (
          <Grid key={newMasterDataField.id} container>
            {masterDataTable === 'systemParameters' ? (
              <>
                <Grid item xs={2} pr={5}>
                  {mode === 'edit' ? (
                    <TextField value={watch(`${fieldName}.${index}.name`)} />
                  ) : (
                    <Controller
                      control={control}
                      name={`${fieldName}.${index}.name`}
                      render={({ field }) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          placeholder={t(
                            'masterData.addMasterDataDialog.field.name.placeholder',
                          )}
                          error={errors?.newData?.[index]?.name?.message}
                          isReserverForHelperText
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={2} pr={5}>
                  <Controller
                    control={control}
                    name={`${fieldName}.${index}.description`}
                    render={({ field }) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        placeholder={t(
                          'masterData.addMasterDataDialog.field.description.placeholder',
                        )}
                        error={errors?.newData?.[index]?.description?.message}
                        isReserverForHelperText
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} pr={5}>
                  <Controller
                    control={control}
                    name={`${fieldName}.${index}.category`}
                    render={({ field }) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        placeholder={t(
                          'masterData.addMasterDataDialog.field.category.placeholder',
                        )}
                        error={errors?.newData?.[index]?.category?.message}
                        isReserverForHelperText
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} pr={5}>
                  <Controller
                    control={control}
                    name={`${fieldName}.${index}.dataType`}
                    render={({ field }) => (
                      <Dropdown
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        onChange={(e) => {
                          const value = e.target.value as string
                          handleDropdownOnChange(index, value)
                          trigger(`${fieldName}.${index}.${getDataType(index)}`)
                        }}
                        options={systemParameterDataTypeOptions}
                        placeholder={t(
                          'masterData.addMasterDataDialog.field.dataType.placeholder',
                        )}
                        error={errors?.newData?.[index]?.dataType?.message}
                        isReserverForHelperText
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3} pr={5}>
                  {renderDataTypeField(index, getDataType(index))}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={1} pr={5}>
                  <Controller
                    control={control}
                    name={`${fieldName}.${index}.order`}
                    render={({ field }) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        type="number"
                        inputProps={{ min: 0 }}
                        value={watch(`${fieldName}.${index}.order`)}
                        onChange={(e) => onNumberChange(e, index, 'order')}
                        placeholder={t(
                          'masterData.addMasterDataDialog.field.order.placeholder',
                        )}
                        error={errors?.newData?.[index]?.order?.message}
                        isReserverForHelperText
                      />
                    )}
                  />
                </Grid>
                {masterDataFormExtraColumn != null ? (
                  <Grid item xs={2} pr={5}>
                    <Controller
                      control={control}
                      name={`${fieldName}.${index}.${extraColumnIndex}`}
                      render={({ field }) => (
                        <Dropdown
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          options={
                            formOptions != null
                              ? formOptions
                                  ?.filter(
                                    (option) =>
                                      !option.disabled ||
                                      option.value == extraColumnValueOnOpen,
                                  )
                                  .map((option) => ({
                                    ...option,
                                    disabled: false,
                                  }))
                              : []
                          }
                          placeholder={t(
                            `masterData.addMasterDataDialog.field.${masterDataFormExtraColumn}.placeholder`,
                          )}
                          error={
                            errors?.newData?.[index]?.[extraColumnIndex]
                              ?.message
                          }
                          isReserverForHelperText
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid
                  item
                  xs={
                    masterDataFormExtraColumn != null ||
                    masterDataTable === 'subjects'
                      ? 3
                      : 5
                  }
                  pr={5}
                >
                  <Controller
                    control={control}
                    name={`${fieldName}.${index}.chineseName`}
                    render={({ field }) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        placeholder={t(
                          'masterData.addMasterDataDialog.field.chineseName.placeholder',
                        )}
                        error={errors?.newData?.[index]?.chineseName?.message}
                        isReserverForHelperText
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3} pr={5}>
                  <Controller
                    control={control}
                    name={`${fieldName}.${index}.englishName`}
                    render={({ field }) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        placeholder={t(
                          'masterData.addMasterDataDialog.field.englishName.placeholder',
                        )}
                        error={errors?.newData?.[index]?.englishName?.message}
                        isReserverForHelperText
                      />
                    )}
                  />
                </Grid>
                {masterDataTable === 'subjects' && (
                  <Grid item xs={2} pr={5}>
                    <Controller
                      control={control}
                      name={`${fieldName}.${index}.educationLevelIds`}
                      render={({ field }) => (
                        <AutoComplete
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          options={[...computedEducationLevelOptions]}
                          multiple
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, values) =>
                            option.value === values.value
                          }
                          renderTags={(values, getTagProps) =>
                            values.map((option, i) => {
                              const selectedTag = educationLevels.find(
                                (tag) => tag.id === option.value,
                              )
                              const { key, ...tagProps } = getTagProps({
                                index: i,
                              })
                              return (
                                <Tag
                                  label={selectedTag?.displayName}
                                  colorCustomize={selectedTag?.colorCode}
                                  key={option}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...tagProps}
                                />
                              )
                            })
                          }
                          onChange={(e, value) =>
                            field.onChange({
                              target: {
                                value,
                              },
                            })
                          }
                          placeholder={
                            watch(`${fieldName}.${index}.educationLevelIds`)
                              ?.length
                              ? undefined
                              : t(
                                  'masterData.addMasterDataDialog.field.educationLevelIds.placeholder',
                                )
                          }
                          error={
                            errors?.newData?.[index]?.educationLevelIds?.message
                          }
                          isReserverForHelperText
                        />
                      )}
                    />
                  </Grid>
                )}
                {hasHexColor ? (
                  <>
                    <Grid item xs={2} pr={5}>
                      <Controller
                        control={control}
                        name={`${fieldName}.${index}.colorCode`}
                        render={({ field }) => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            placeholder={t(
                              'masterData.addMasterDataDialog.field.colorCode.placeholder',
                            )}
                            error={errors?.newData?.[index]?.colorCode?.message}
                            isReserverForHelperText
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        name={`${fieldName}.${index}.colorCode`}
                        render={({ field }) => (
                          <StyledMuiColorInput
                            value={field.value ?? '#ffffff'}
                            format="hex"
                            placeholder={t(
                              'masterData.addMasterDataDialog.field.colorCode.placeholder',
                            )}
                            isAlphaHidden
                            variant="standard"
                            onChange={(
                              val: string,
                              color: MuiColorInputColors,
                            ) => {
                              setValue(
                                `${fieldName}.${index}.colorCode`,
                                color.hex.toUpperCase(),
                              )
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : (
                  masterDataTable !== 'tagTypes' && (
                    <Grid item xs={2} pr={5}>
                      <Controller
                        control={control}
                        name={`${fieldName}.${index}.label`}
                        render={({ field }) => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            placeholder={t(
                              'masterData.addMasterDataDialog.field.label.placeholder',
                            )}
                            error={errors?.newData?.[index]?.label?.message}
                            isReserverForHelperText
                          />
                        )}
                      />
                    </Grid>
                  )
                )}
              </>
            )}
            {(allowAppendRow ?? true) && (
              <Grid container justifyContent="flex-end" xs={1}>
                <Grid item>
                  <Svg.Close
                    cursor="pointer"
                    onClick={() => removeNewMasterDataField(index)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
        {(allowDeleteRow ?? true) && (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Svg.Plus
                cursor="pointer"
                onClick={() =>
                  appendNewMasterField({
                    chineseName: '',
                    englishName: '',
                    order: undefined,
                    colorCode: undefined,
                    educationLevelId: undefined,
                    learningPhaseId: undefined,
                    diStrategyClassId: undefined,
                    label: undefined,
                    tagTypeId: undefined,
                    subjectId: undefined,
                    name: '',
                    description: undefined,
                    category: undefined,
                    dataType: undefined,
                    valueCheck: undefined,
                    value_Integer: undefined,
                    value_Decimal: undefined,
                    value_DateTime: undefined,
                    value_Text: undefined,
                    value_Boolean: undefined,
                    diStrategyTags: undefined,
                    chineseDetail: undefined,
                    englishDetail: undefined,
                    displayVideo: undefined,
                    videos: undefined,
                    furtherReadingAndExamples: undefined,
                  })
                }
              />
            </Grid>
          </Grid>
        )}
      </Box>
    )
  },
)
