import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  PostSystemParameterPayload,
  PutSystemParameterPayload,
  SystemParameterResponsePayload,
  SystemParametersQueryParameters,
  SystemParametersResponsePayload,
} from './system-parameters-api.type'

const PATH = '/system-parameters'

export function getSystemParameters(param?: SystemParametersQueryParameters) {
  return ApiCore.primary.get<SystemParametersResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}

export function postSystemParametersBulk(
  payloads: PostSystemParameterPayload[],
) {
  return ApiCore.primary.post<SystemParameterResponsePayload[]>(
    `${PATH}/bulk`,
    {
      body: payloads,
    },
  )
}

export function putSystemParameterByName(
  name: string,
  payload: PutSystemParameterPayload,
) {
  return ApiCore.primary.put<SystemParameterResponsePayload>(
    `${PATH}/${name}`,
    {
      body: payload,
    },
  )
}
