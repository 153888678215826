import { Grid } from '@mui/material'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { LessonPlanGeneralInformationTab } from '../../../../components'
import { GeneralInformationFormData } from '../../lesson-plan-form-provider'
import { useGeneralInformationFormContext } from './generalInformationContext'

type TabHeaderPropType = {}
export const TabHeader: React.FunctionComponent<TabHeaderPropType> = () => {
  const { activeStep, setActiveStep } = useGeneralInformationFormContext()
  const { trigger } = useFormContext<GeneralInformationFormData>()

  const onActiveTabChange = useCallback(
    async (val: number) => {
      const generalInformationChecker = await trigger()
      if (!generalInformationChecker) return
      setActiveStep(val)
    },
    [setActiveStep, trigger],
  )

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <LessonPlanGeneralInformationTab
          activeTab={activeStep}
          onActiveTabChange={onActiveTabChange}
        />
      </Grid>
    </Grid>
  )
}
