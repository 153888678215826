import {
  postMasterDataSwitchOrder,
  postMasterDataSwitchStatus,
} from '@polyu-dip/apis'
import { PostSwitchMasterDataOrderPayload } from '@polyu-dip/apis/src/master-data/master-data-api.types'
import { MasterDataTable } from '@polyu-dip/stores'
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'master-data'

export function useSwitchMasterDataStatus(
  opts?: UseMutationOptions<
    any,
    unknown,
    { type: MasterDataTable; id: string; rowVersion: string }
  >,
) {
  const queryClient = useQueryClient()
  return useMutation(
    ({ type, id, rowVersion }) =>
      postMasterDataSwitchStatus(type, id, rowVersion),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useSwitchMasterDataOrder(
  opts?: UseMutationOptions<
    any,
    unknown,
    { type: MasterDataTable; payload: PostSwitchMasterDataOrderPayload }
  >,
) {
  const queryClient = useQueryClient()
  return useMutation(
    ({ type, payload }) => postMasterDataSwitchOrder(type, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}
