export const masterData = {
  learningUnits: {
    entityName: 'Learning strand',
  },
  educationLevels: {
    entityName: 'Education level',
  },
  teachingClasses: {
    entityName: 'Class',
  },
  diStrategies: {
    entityName: 'DI strategies',
  },
  tags: {
    entityName: 'Tag',
  },
  tagGroups: {
    entityName: 'Tag Group',
  },
  subjects: {
    entityName: 'Subject',
  },
  learningPhases: {
    entityName: 'Learning phases',
  },
  classLevels: {
    entityName: 'Grade',
  },
  diStrategyClasses: {
    entityName: 'DI strategy category',
  },
  tagTypes: {
    entityName: 'Types of tag',
  },
  resourceTypes: {
    entityName: 'Resources category',
  },
  diStrategyTags: {
    entityName: 'DI strategies tag',
  },
  systemParameters: {
    entityName: 'Others',
  },

  filter: {
    title: 'Tag',
  },

  action: {
    add: 'Add',
    addClass: 'Add to category',
  },

  table: {
    chineseName: 'Tag (Chinese)',
    englishName: 'Tag (English)',
    labelNumber: 'Order',
    hexColorCode: 'Hex color code',
    status: 'status',
    educationLevel: 'Education level',
    learningPhase: 'Learning phases',

    name: 'Tag',
    description: 'Description',
    category: 'category',
    dataType: 'Type of data',
    valueInteger: 'Value_Integer',
    valueDecimal: 'Value_Decimal',
    valueDateTime: 'Value_DateTime',
    valueText: 'Value_Text',
    valueBoolean: 'Value_Boolean',
  },

  confirmationDialog: {
    title: 'Suspend data',
    content: 'This system data is about to be suspended.',
    action: {
      delete: {
        title: 'Suspend',
        successMessage: 'Suspended successfully',
      },
      activate: {
        successMessage: 'Enabled successfully',
      },
    },
  },

  selectTagGroupTypeDialog: {
    title: 'Add New Tag',
    description: 'Please select tag type',
    options: {
      withName: 'A. Include group and extent, style:',
      noName: 'B. Include group label only, style:',
    },
    tagExampleLabel: {
      withName: {
        text: `Learner's confidence`,
        highlighted: 'Moderate',
      },
      noName: {
        text: '',
        highlighted: 'Discovery Learning',
      },
    },
  },

  editDiStrategyDetailDialog: {
    field: {
      chineseName: {
        label: 'Tag (Chinese)',
        placeholder: 'Tag (Chinese)',
      },
      englishName: {
        label: 'Tag (English)',
        placeholder: 'Tag (English)',
      },
      tagGroup: {
        englishName: {
          label: 'Group (English)',
        },
        chineseName: {
          label: 'Group (Chinese)',
        },
      },
      order: {
        label: 'Order',
        placeholder: 'Order',
      },
      colorCode: {
        label: 'Hex color code',
        placeholder: 'Hex color code',
      },
      displayVideo: {
        label: 'Illustration URL',
        placeholder: 'URL',
      },
      chineseDetail: {
        label: 'Description (Chinese)',
        placeholder: 'Description (Chinese)',
      },
      englishDetail: {
        label: 'Description (English)',
        placeholder: 'Description (English)',
      },
      url: {
        label: 'Link',
        placeholder: 'URL',
      },
    },
    video: 'DI strategies lesson videos',
    furtherReadingAndExamples: 'Examples and extended readings ',
    diStrategyTag: 'Corresponding characteristics',
  },

  addMasterDataDialog: {
    title: {
      create: 'Add tag',
      edit: 'Modify the project',
    },
    field: {
      chineseName: {
        label: 'Tag (Chinese)',
        placeholder: 'Tag (Chinese)',
      },
      englishName: {
        label: 'Tag (English)',
        placeholder: 'Tag (English)',
      },
      order: {
        label: 'Order',
        placeholder: 'Order',
      },
      colorCode: {
        label: 'HEX color code',
        placeholder: 'HEX color code',
      },
      learningPhase: {
        label: 'Phase',
        placeholder: 'Phase',
      },
      educationLevel: {
        label: 'Education level',
        placeholder: 'Education level',
      },
      educationLevelIds: {
        placeholder: 'Please select education level',
      },
      diStrategyClass: {
        label: 'DI strategy category',
        placeholder: 'DI strategy category',
      },
      label: {
        label: 'DI strategy code',
        placeholder: 'DI strategy code',
      },
      tagType: {
        label: 'Types of tag',
        placeholder: 'Types of tag',
      },
      name: {
        label: 'Tag',
        placeholder: 'Tag',
      },
      description: {
        label: 'Description',
        placeholder: 'Description',
      },
      category: {
        label: 'Category',
        placeholder: 'Category',
      },
      dataType: {
        label: 'Type of data',
        placeholder: 'Type of data',
      },
      isMultiSelect: {
        label: 'Can select multiple',
      },
      value: {
        label: 'Value',
        placeholder: 'Value',
      },
      value_Integer: {
        label: 'Value_Integer',
        placeholder: 'Value_Integer',
      },
      value_Decimal: {
        label: 'Value_Decimal',
        placeholder: 'Value_Decimal',
      },
      value_DateTime: {
        label: 'Value_DateTime',
        placeholder: 'Value_DateTime',
      },
      value_Text: {
        label: 'Value_Text',
        placeholder: 'Value_Text',
      },
      value_Boolean: {
        label: 'Value_Boolean',
        placeholder: 'Value_Boolean',
      },
    },
    action: {
      create: 'Add project',
      delete: 'Suspend ',
      activate: 'Enable',
    },
    errorMessage: {
      create: {
        title: 'Fail to add tags',
      },
      edit: {
        title: 'Fail to modify project',
      },
    },
    successMessage: {
      create: {
        title: 'Tag added successfully',
      },
      edit: {
        title: 'Project modified successfully',
      },
    },
  },
}
