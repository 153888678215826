import { BlobServiceClient, ContainerClient } from '@azure/storage-blob'
import {
  useCreateBlobStorageContainer,
  useGetBlobDownloadUrl,
} from '@polyu-dip/queries'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useOverlay } from '../../components'
import { useApiErrorHandle } from '../../hooks'

export function useBlobStorage(uploadSuccessMessage?: string) {
  const { t } = useTranslation()
  const { showDialog, showSnackbar } = useOverlay()
  const { standardErrorHandler } = useApiErrorHandle()

  const { mutateAsync: createBlobStorageContainer } =
    useCreateBlobStorageContainer({
      onSuccess() {
        if (uploadSuccessMessage != null) {
          showSnackbar({
            message: uploadSuccessMessage,
          })
        }
      },
      onError(error) {
        standardErrorHandler(error, {
          defaultTitle: t('attachment.errorMessage.uploadAttachment.title'),
        })
      },
    })

  const { mutateAsync: getBlobDownloadUrl } = useGetBlobDownloadUrl({
    onError(error) {
      standardErrorHandler(error, {
        defaultTitle: t('attachment.errorMessage.downloadAttachment.title'),
      })
    },
  })

  const handleCreateBlobStorageContainer = useCallback(async () => {
    const res = await createBlobStorageContainer()
    if (res == null) {
      showDialog({
        title: t('attachment.errorMessage.uploadAttachment.title'),
        content: t('error.default.content'),
        actions: [{ text: t('common.ok'), value: null }],
      })
      throw new Error('Failed to upload attachment')
    }
    return res
  }, [createBlobStorageContainer, showDialog, t])

  const handleGetBlobDownloadUrl = useCallback(
    async (id: string) => {
      const res = await getBlobDownloadUrl(id)
      if (res == null) {
        showDialog({
          title: t('attachment.errorMessage.downloadAttachment.title'),
          content: t('error.default.content'),
          actions: [{ text: t('common.ok'), value: null }],
        })
        throw new Error('Failed to upload attachment')
      }
      return res
    },
    [getBlobDownloadUrl, showDialog, t],
  )

  const handleUpload = useCallback(
    async (file: File) => {
      const fileBuffer = await file.arrayBuffer()

      const containerInfo = await handleCreateBlobStorageContainer()
      const blobSasUrl = `${containerInfo.endpoint}${containerInfo.containerName}${containerInfo.sasToken}`

      // Create a new BlobServiceClient
      const blobServiceClient = new BlobServiceClient(blobSasUrl)
      // Get a container client from the BlobServiceClient
      const containerClient = new ContainerClient(blobSasUrl)
      blobServiceClient.getContainerClient(containerInfo.containerName)

      const blockBlobClient = containerClient.getBlockBlobClient(file.name)
      blockBlobClient.uploadData(fileBuffer)
      const urlWithoutSasToken = blockBlobClient.url.split('?sv=')[0]
      return urlWithoutSasToken
    },
    [handleCreateBlobStorageContainer],
  )

  const getDownloadUrl = useCallback(
    async (id: string) => {
      const response = await handleGetBlobDownloadUrl(id)
      return response.url
    },
    [handleGetBlobDownloadUrl],
  )

  return { handleUpload, getDownloadUrl }
}
