import {
  EducationLevelsQueryParameters,
  PaginatedPayload,
  PostEducationLevelPayload,
  PutEducationLevelPayload,
} from '@polyu-dip/apis'
import { EducationLevel } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'education-levels'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useEducationLevels(
  useStores: UseStoresType,
  param?: EducationLevelsQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<EducationLevel>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getEducationLevels(param),
    opts,
  )
}

export function useCreateEducationLevels(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostEducationLevelPayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payloads) => masterDataStore.createEducationLevels(payloads),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useUpdateEducationLevel(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<EducationLevel, unknown, PutEducationLevelPayload>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => masterDataStore.updateEducationLevel(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useSwitchEducationLevelStatus(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    EducationLevel,
    unknown,
    PutEducationLevelPayload & { id: string }
  >,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => masterDataStore.updateEducationLevel(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export async function clearEducationLevelQueryCaches(queryClient: QueryClient) {
  await queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
