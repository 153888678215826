import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { NameModel } from './languages-json'
import { SubjectEducationLevelModel } from './subject-education-level.model'

export const SubjectModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    isDisabled: types.boolean,
    colorCode: types.string,
    order: types.number,
    subjectEducationLevelMappings: types.optional(
      types.array(SubjectEducationLevelModel),
      [],
    ),
  })
  .extend(withUpdate)
  .named('SubjectModel')

export type Subject = Instance<typeof SubjectModel>
