import { ApiCore } from '@polyu-dip/api-core'
import QueryString from 'qs'

type ApiConfig = {
  baseUrl: string
  timeout: number
  headers?: Record<string, string>
}

export function setApiConfig(apiConfig: ApiConfig) {
  ApiCore.primary.setConfig({
    ...apiConfig,
    paramsSerializer: (params) =>
      QueryString.stringify(params, {
        arrayFormat: 'repeat', // ? dotnet 6 accept repeat by default
      }),
  })

  return ApiCore.primary
}
