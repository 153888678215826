import { flow, Instance, types } from 'mobx-state-tree'
import { UserSessionInfoModel } from './user-session-info.model'
import { User, UserModel } from '@polyu-dip/models/'
import { withEnvironment } from '@polyu-dip/model-core'
import { getMyProfile, GetPayload, UserResponsePayload } from '@polyu-dip/apis'
import { convertNullToUndefined } from '@polyu-dip/utilities'

const emptyPayloadMessage = 'empty-payload'

export const UserProfileStoreModel = types
  .model('UserProfileStore')
  .props({
    userSessionInfo: types.optional(UserSessionInfoModel, { accessToken: '' }),
    userProfile: types.maybe(UserModel),
  })
  .extend(withEnvironment)
  .actions((self) => {
    const action = {
      setAuthToken: (accessToken: string) => {
        const session = UserSessionInfoModel.create({ accessToken })
        self.userSessionInfo = session
      },
      upsertUserProfile(inPayload: UserResponsePayload) {
        const { school, role, ...maybeNullPayload } = inPayload
        const payload = convertNullToUndefined({
          ...maybeNullPayload,
          school: school,
          role: role,
        })
        self.userProfile = UserModel.create(payload)
        return self.userProfile
      },
      getMyProfile: flow(function* getUsersAction(): Generator<
        any,
        GetPayload<User>,
        UserResponsePayload
      > {
        const payload = yield getMyProfile()

        if (payload == null) throw new Error(emptyPayloadMessage)

        return {
          ...action.upsertUserProfile(payload),
        }
      }),
    }
    return action
  })

export type UserProfileStore = Instance<typeof UserProfileStoreModel>
