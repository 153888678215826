import { Button, Grid } from '@mui/material'
import { LessonPlanPublishRequestResponsePayload } from '@polyu-dip/apis'
import {
  clearLessonPlansQueryCaches,
  useApproveLessonPlanPublishRequest,
  useCompleteAndPublishLessonPlanPublishRequest,
  usePublishLessonPlanExample,
  useUnpublishLessonPlanExample,
  useRejectLessonPlanPublishRequest,
} from '@polyu-dip/queries'
import { useQueryClient } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Svg } from '../../../assets'
import { useOverlay } from '../../../components'
import { contentPaths } from '../../../content-paths'
import { useApiErrorHandle } from '../../../hooks'
import { useStores } from '../../../stores'
import { useLessonPlanDetail } from '../use-lesson-plan-detail-context'

type Props = {
  lessonPlanId: string
  processingLessonPlanPublishRequest?: LessonPlanPublishRequestResponsePayload
}

export const ExampleDetailActions = observer<Props>(
  ({ lessonPlanId, processingLessonPlanPublishRequest }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { lessonPlan } = useLessonPlanDetail()
    const { showSnackbar, showSpinner, hideSpinner } = useOverlay()
    const { standardErrorHandler } = useApiErrorHandle()

    const { mutateAsync: approve } = useApproveLessonPlanPublishRequest(
      useStores,
      processingLessonPlanPublishRequest?.id ?? '',
      {
        onSuccess: () => {
          showSnackbar({
            message: t('lessonPlanExample.action.approve.successMessage'),
          })
          clearLessonPlansQueryCaches(queryClient, lessonPlanId)
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.approveLessonPlanPublishRequest.title',
            ),
          })
        },
      },
    )

    const { mutateAsync: reject } = useRejectLessonPlanPublishRequest(
      useStores,
      processingLessonPlanPublishRequest?.id ?? '',
      {
        onSuccess: () => {
          showSnackbar({
            message: t('lessonPlanExample.action.reject.successMessage'),
          })
          clearLessonPlansQueryCaches(queryClient, lessonPlanId)
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.rejectLessonPlanPublishRequest.title',
            ),
          })
        },
      },
    )

    const { mutateAsync: completeAndPublish } =
      useCompleteAndPublishLessonPlanPublishRequest(
        useStores,
        processingLessonPlanPublishRequest?.id ?? '',
        {
          onSuccess: () => {
            showSnackbar({
              message: t('lessonPlanExample.action.publish.successMessage'),
            })
            navigate(
              contentPaths.lessonPlanExampleManagement(lessonPlanId, 'view'),
            )
            clearLessonPlansQueryCaches(queryClient, lessonPlanId)
          },
          onError: (error) => {
            standardErrorHandler(error, {
              defaultTitle: t(
                'lessonPlan.errorMessage.completeAndPublishLessonPlanPublishRequest.title',
              ),
            })
          },
        },
      )

    const { mutateAsync: publish } = usePublishLessonPlanExample(
      useStores,
      lessonPlanId,
      {
        onSuccess: () => {
          showSnackbar({
            message: t('lessonPlanExample.action.publish.successMessage'),
          })
          clearLessonPlansQueryCaches(queryClient, lessonPlanId)
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.completeAndPublishLessonPlanPublishRequest.title',
            ),
          })
        },
      },
    )

    const { mutateAsync: unpublish } = useUnpublishLessonPlanExample(
      useStores,
      lessonPlanId,
      {
        onSuccess: () => {
          showSnackbar({
            message: t('lessonPlanExample.action.unPublish.successMessage'),
          })
          clearLessonPlansQueryCaches(queryClient, lessonPlanId)
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.completeAndPublishLessonPlanPublishRequest.title',
            ),
          })
        },
      },
    )

    const handleApprove = useCallback(async () => {
      if (processingLessonPlanPublishRequest?.rowVersion == null) {
        return
      }
      try {
        showSpinner()
        await approve(processingLessonPlanPublishRequest?.rowVersion)
      } finally {
        hideSpinner()
      }
    }, [
      showSpinner,
      approve,
      processingLessonPlanPublishRequest?.rowVersion,
      hideSpinner,
    ])

    const handleReject = useCallback(async () => {
      if (processingLessonPlanPublishRequest?.rowVersion == null) {
        return
      }
      try {
        showSpinner()
        await reject(processingLessonPlanPublishRequest?.rowVersion)
      } finally {
        hideSpinner()
      }
    }, [
      hideSpinner,
      processingLessonPlanPublishRequest?.rowVersion,
      reject,
      showSpinner,
    ])

    const handleEdit = useCallback(() => {
      if (processingLessonPlanPublishRequest != null) {
        navigate(
          contentPaths.lessonPlanPublishRequest(
            processingLessonPlanPublishRequest.id,
            'edit',
          ),
        )
      } else {
        navigate(contentPaths.lessonPlanExampleManagement(lessonPlanId, 'edit'))
      }
    }, [lessonPlanId, navigate, processingLessonPlanPublishRequest])

    const handleComplete = useCallback(async () => {
      if (processingLessonPlanPublishRequest?.rowVersion == null) {
        return
      }
      try {
        showSpinner()
        await completeAndPublish(processingLessonPlanPublishRequest?.rowVersion)
      } finally {
        hideSpinner()
      }
    }, [
      showSpinner,
      completeAndPublish,
      processingLessonPlanPublishRequest?.rowVersion,
      hideSpinner,
    ])

    const handlePublish = useCallback(async () => {
      try {
        showSpinner()
        await publish(lessonPlan.rowVersion)
      } finally {
        hideSpinner()
      }
    }, [showSpinner, publish, lessonPlan.rowVersion, hideSpinner])

    const handleUnpublish = useCallback(async () => {
      try {
        showSpinner()
        await unpublish(lessonPlan.rowVersion)
      } finally {
        hideSpinner()
      }
    }, [showSpinner, unpublish, lessonPlan.rowVersion, hideSpinner])

    return (
      <Grid container spacing={2}>
        {processingLessonPlanPublishRequest != null &&
          (lessonPlan.publishDisplayStatus === 'pending' ? (
            <>
              <Grid item>
                <Button
                  startIcon={<Svg.Publish fill="white" />}
                  color="cascade"
                  onClick={handleApprove}
                >
                  {t('lessonPlan.detail.actions.accept')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<Svg.Close fill="white" />}
                  color="darkestGrey"
                  onClick={handleReject}
                >
                  {t('lessonPlan.detail.actions.reject')}
                </Button>
              </Grid>
            </>
          ) : (
            lessonPlan.publishDisplayStatus === 'approved' && (
              <>
                <Grid item>
                  <Button
                    color="warning"
                    startIcon={<Svg.Create />}
                    onClick={handleEdit}
                  >
                    {t('lessonPlan.detail.actions.editLessonPlan')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    startIcon={<Svg.Publish fill="white" />}
                    color="cascade"
                    onClick={handleReject}
                  >
                    {t('lessonPlan.detail.actions.withdrawApproval')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    startIcon={<Svg.Publish fill="white" />}
                    color="brown"
                    onClick={handleComplete}
                  >
                    {t('lessonPlan.detail.actions.publish')}
                  </Button>
                </Grid>
              </>
            )
          ))}
        {(lessonPlan.publishDisplayStatus === 'completed' ||
          lessonPlan.publishDisplayStatus === 'publishing') && (
          <>
            <Grid item>
              <Button
                color="warning"
                startIcon={<Svg.Create />}
                onClick={handleEdit}
              >
                {t('lessonPlan.detail.actions.editLessonPlan')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<Svg.Publish fill="white" />}
                color="brown"
                onClick={
                  lessonPlan.publishDisplayStatus === 'publishing'
                    ? handleUnpublish
                    : handlePublish
                }
              >
                {lessonPlan.publishDisplayStatus === 'publishing'
                  ? t('lessonPlan.detail.actions.unpublish')
                  : t('lessonPlan.detail.actions.publish')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    )
  },
)
