export const lessonPlanCommunication = {
  title: 'Lesson plan exchange',
  pagination: {
    next: 'Next page',
  },
  tableFields: {
    classLevel: 'Grade',
    subject: 'Learning areas',
    sharingCreatedDateTime: 'Sharing date',
    ownedByUserName: 'Author',
    teachingTopic: 'Name of lesson plan',
    latestCommentCreatedBy: 'Responded user',
    latestComment: 'Latest message',
  },
}
