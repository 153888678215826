import {
  LessonPlanExpandableParameter,
  LessonPlansOwnedQueryParameters,
  LessonPlansQueryParameters,
  LessonPlansUpdatedExampleAndResourceResponsePayload,
  LessonPlansUpdatedExamplesAndResourcesParameters,
  LessonPlansSharedQueryParameters,
  PaginatedPayload,
  PostLessonPlanPayload,
  PostLessonPlanSharingPayload,
  PutLessonPlanPayload,
  PostLessonPlanCommentPayload,
  LessonPlanCommentsQueryParameters,
  GetLessonPlanAttachmentDownloadUrlPayload,
  getLessonPlanAttachmentsDownloadUrl,
  LessonPlanExamplesQueryParameters,
  LessonPlanExamplesResponsePayload,
  PostLessonPlanConsultPayload,
  PostLessonPlanTransferOwnerPayload,
} from '@polyu-dip/apis'
import { LessonPlan, LessonPlanComment } from '@polyu-dip/models'
import { LessonPlansStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'lesson-plans'

type UseStoresType = () => { lessonPlansStore: LessonPlansStore }

export function useLessonPlans(
  useStores: UseStoresType,
  param?: LessonPlansQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<LessonPlan>>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => lessonPlansStore.getLessonPlans(param),
    opts,
  )
}

export function useLessonPlan(
  useStores: UseStoresType,
  id: string,
  param?: LessonPlanExpandableParameter,
  opts?: UseQueryOptions<LessonPlan>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, id] as QueryKey,
    () => lessonPlansStore.getLessonPlan(id, param),
    opts,
  )
}

export function useLessonPlanDetail(
  useStores: UseStoresType,
  id: string,
  param?: LessonPlanExpandableParameter,
  opts?: UseQueryOptions<LessonPlan>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, id] as QueryKey,
    () => lessonPlansStore.getLessonPlanDetail(id, param),
    opts,
  )
}

export function useCreateLessonPlan(
  useStores: UseStoresType,
  opts?: UseMutationOptions<LessonPlan, unknown, PostLessonPlanPayload>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => lessonPlansStore.createLessonPlan(payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useUpdateLessonPlan(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<LessonPlan, unknown, PutLessonPlanPayload>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => lessonPlansStore.updateLessonPlan(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useTransferLessonPlan(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<
    LessonPlan,
    unknown,
    PostLessonPlanTransferOwnerPayload
  >,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => lessonPlansStore.transferLessonPlan(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useLessonPlansOwned(
  useStores: UseStoresType,
  param?: LessonPlansOwnedQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<LessonPlan>>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'owned', param] as QueryKey,
    () => lessonPlansStore.getLessonPlansOwned(param),
    opts,
  )
}

export function useLessonPlansShared(
  useStores: UseStoresType,
  param?: LessonPlansSharedQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<LessonPlan>>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'shared', param] as QueryKey,
    () => lessonPlansStore.getLessonPlansShared(param),
    opts,
  )
}

export function useLessonPlansOwnedPublished(
  useStores: UseStoresType,
  param?: LessonPlansQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<LessonPlan>>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'owned-published', param] as QueryKey,
    () => lessonPlansStore.getLessonPlansOwnedPublished(param),
    opts,
  )
}

export function useLessonPlanExamples(
  useStores: UseStoresType,
  param?: LessonPlanExamplesQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<LessonPlanExamplesResponsePayload>>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'examples', param] as QueryKey,
    () => lessonPlansStore.getLessonPlanExamples(param),
    opts,
  )
}

export function useLessonPlanSummary(
  useStores: UseStoresType,
  param?: LessonPlanExamplesQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<LessonPlanExamplesResponsePayload>>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'summary', param] as QueryKey,
    () => lessonPlansStore.getLessonPlanSummary(param),
    opts,
  )
}

export function useLessonPlanUpdatedExamplesAndResources(
  useStores: UseStoresType,
  param?: LessonPlansUpdatedExamplesAndResourcesParameters,
  opts?: UseQueryOptions<
    PaginatedPayload<LessonPlansUpdatedExampleAndResourceResponsePayload>
  >,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'updated-examples-and-resources', param] as QueryKey,
    () => lessonPlansStore.getLessonPlansUpdatedExamplesAndResources(param),
    opts,
  )
}

export function useCreateLessonPlanSharingById(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown, PostLessonPlanSharingPayload>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => lessonPlansStore.createLessonPlanSharing(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useCreateLessonPlanSharingsById(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown, PostLessonPlanSharingPayload[]>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payloads) => lessonPlansStore.createLessonPlanSharings(id, payloads),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useLessonPlanComments(
  useStores: UseStoresType,
  id: string,
  param?: LessonPlanCommentsQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<LessonPlanComment>>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, id, 'comments', param] as QueryKey,
    () => lessonPlansStore.getLessonPlanComments(id, param),
    opts,
  )
}

export function useCreateLessonPlanComment(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown, PostLessonPlanCommentPayload>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => lessonPlansStore.createLessonPlanComment(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id, 'comments'])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useGetLessonPlanAttachmentsDownloadUrl(
  timeout: string,
  opts?: UseMutationOptions<
    GetLessonPlanAttachmentDownloadUrlPayload | null,
    unknown,
    string
  >,
) {
  const queryClient = useQueryClient()

  return useMutation((id) => getLessonPlanAttachmentsDownloadUrl(id, timeout), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useCreateLessonPlanPublishRequest(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<
    LessonPlan,
    unknown,
    { rowVersion: string; isAgreePublish: boolean }
  >,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ rowVersion, isAgreePublish }) =>
      lessonPlansStore.createLessonPlanPublishRequest(
        id,
        rowVersion,
        isAgreePublish,
      ),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([
          QUERY_CACHE_KEY_ROOT,
          id,
          'submit-publish-request',
        ])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function clearLessonPlansQueryCaches(
  queryClient: QueryClient,
  ...params: string[]
) {
  queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, ...params])
}

export function usePublishLessonPlanExample(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown, string>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (rowVersion) => lessonPlansStore.publishLessonPlanExample(id, rowVersion),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useUnpublishLessonPlanExample(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown, string>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (rowVersion) => lessonPlansStore.unpublishLessonPlanExample(id, rowVersion),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useCopyLessonPlanExample(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(() => lessonPlansStore.copyLessonPlanExample(id), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useCreateLessonPlanConsultRequestById(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown, PostLessonPlanConsultPayload>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => lessonPlansStore.createConsultRequest(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id, 'consult'])
        opts?.onSuccess?.(...params)
      },
    },
  )
}
