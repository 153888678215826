import { Grid, Switch, Typography } from '@mui/material'
import { ClassLevel, LearningPhase, Subject } from '@polyu-dip/models'
import { MasterDataTable } from '@polyu-dip/stores'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag, SwitchGroup } from '../../components'
import { useDisplayName } from '../../services'
import dayjs from 'dayjs'
import { TagGroupCell } from './tag-group-cell'

export function useMasterDataManagementColumn() {
  const { t } = useTranslation()
  const { getDisplayName } = useDisplayName()

  const renderColumn = useCallback(
    (
      totalRows: number,
      selectedTab?: MasterDataTable,
      selectedContentId?: MasterDataTable | string,
      handleSwitchStatusOnClicked?: (
        id: string,
        isDisabled?: boolean,
      ) => Promise<void>,
      handleSwitchOrderOnClicked?: (
        index1: number,
        index2: number,
      ) => Promise<void>,
    ) => {
      if (
        selectedTab === 'systemParameters' &&
        selectedContentId === 'systemParameters'
      ) {
        return [
          {
            field: 'name',
            headerName: t('masterData.table.name'),
            renderCell: ({ row }: { row: any }) => {
              return row.name
            },
            width: 300,
            sortable: false,
          },
          {
            field: 'description',
            headerName: t('masterData.table.description'),
            renderCell: ({ row }: { row: any }) => {
              return row.description ?? '---'
            },
            width: 300,
            sortable: false,
          },
          {
            field: 'category',
            headerName: t('masterData.table.category'),
            renderCell: ({ row }: { row: any }) => {
              return row.category ?? '---'
            },
            width: 100,
            sortable: false,
          },
          {
            field: 'dataType',
            headerName: t('masterData.table.dataType'),
            renderCell: ({ row }: { row: any }) => {
              return row.dataType ?? '---'
            },
            width: 100,
            sortable: false,
          },
          {
            field: 'value_Integer',
            headerName: t('masterData.table.valueInteger'),
            renderCell: ({ row }: { row: any }) => {
              return row.value_Integer ?? '---'
            },
            width: 300,
            sortable: false,
          },
          {
            field: 'value_Decimal',
            headerName: t('masterData.table.valueDecimal'),
            renderCell: ({ row }: { row: any }) => {
              return row.value_Decimal ?? '---'
            },
            width: 300,
            sortable: false,
          },
          {
            field: 'value_DateTime',
            headerName: t('masterData.table.valueDateTime'),
            renderCell: ({ row }: { row: any }) => {
              return row.value_DateTime
                ? dayjs(row.value_DateTime).format(t('common.format.datetime'))
                : '---'
            },
            width: 300,
            sortable: false,
          },
          {
            field: 'value_Text',
            headerName: t('masterData.table.valueText'),
            renderCell: ({ row }: { row: any }) => {
              return row.value_Text ?? '---'
            },
            width: 300,
            sortable: false,
          },
          {
            field: 'value_Boolean',
            headerName: t('masterData.table.valueBoolean'),
            renderCell: ({ row }: { row: any }) => {
              // eslint-disable-next-line no-nested-ternary
              return row.value_Boolean === undefined
                ? '---'
                : row.value_Boolean === true
                ? t('common.yes')
                : t('common.no')
            },
            width: 300,
            sortable: false,
          },
        ]
      }
      const learningPhaseColumns =
        selectedTab === 'learningPhases' &&
        selectedContentId === 'learningPhases'
          ? [
              {
                field: 'educationLevel',
                headerName: t('masterData.table.educationLevel'),
                renderCell: ({ row }: { row: LearningPhase }) => (
                  <Tag
                    label={getDisplayName(row.educationLevel?.name)}
                    colorCustomize={row.educationLevel?.colorCode}
                  />
                ),
                width: 100,
                sortable: false,
              },
            ]
          : []

      const classLevelColumns =
        selectedTab === 'learningPhases' && selectedContentId === 'classLevels'
          ? [
              {
                field: 'learningPhase',
                headerName: t('masterData.table.learningPhase'),
                renderCell: ({ row }: { row: ClassLevel }) => (
                  <Tag
                    label={getDisplayName(row.learningPhase?.name)}
                    colorCustomize={row.learningPhase?.colorCode}
                  />
                ),
                width: 100,
                sortable: false,
              },
            ]
          : []

      return [
        {
          field: 'order',
          headerName: t('masterData.table.labelNumber'),
          renderCell: ({ row }: { row: any }) =>
            handleSwitchOrderOnClicked == null ? (
              <Typography width="100%">{row.order}</Typography>
            ) : (
              <Grid container flexDirection="row">
                <Grid
                  sm={4}
                  alignItems="center"
                  textAlign="left"
                  display="flex"
                >
                  <Typography width="100%">{row.order}</Typography>
                </Grid>
                <Grid sm={8} container flexDirection="column" flexWrap="nowrap">
                  <SwitchGroup
                    index={row.index}
                    totalRows={totalRows}
                    onClickedSwitchUp={(event) => {
                      event.stopPropagation()
                      handleSwitchOrderOnClicked(row.index, row.index - 1)
                    }}
                    onClickedSwitchDown={(event) => {
                      event.stopPropagation()
                      handleSwitchOrderOnClicked(row.index, row.index + 1)
                    }}
                  />
                </Grid>
              </Grid>
            ),
          width: 100,
          sortable: true,
        },
        ...learningPhaseColumns,
        ...classLevelColumns,
        {
          field: 'chineseName',
          headerName: t('masterData.table.chineseName'),
          renderCell: ({ row }: { row: any }) =>
            selectedTab === 'tags' ? (
              <TagGroupCell language="zh-Hant" tagGroup={row} />
            ) : (
              <Tag
                label={getDisplayName(row.name, 'zh-Hant')}
                colorCustomize={row?.colorCode}
              />
            ),
          width: 300,
          sortable: false,
        },
        {
          field: 'englishName',
          headerName: t('masterData.table.englishName'),
          renderCell: ({ row }: { row: any }) =>
            selectedTab === 'tags' ? (
              <TagGroupCell language="en" tagGroup={row} />
            ) : (
              <Tag
                label={getDisplayName(row.name, 'en')}
                colorCustomize={row?.colorCode}
              />
            ),
          width: 300,
          sortable: false,
        },

        ...(selectedTab === 'subjects'
          ? [
              {
                field: 'educationLevels',
                headerName: t('masterData.table.educationLevel'),
                renderCell: ({ row }: { row: Subject }) => (
                  <Grid container flexDirection="row" gap={[2]}>
                    {row.subjectEducationLevelMappings.map((it) => (
                      <Grid item key={it.educationLevelId}>
                        {it.educationLevel && (
                          <Tag
                            label={getDisplayName(
                              it.educationLevel?.displayName,
                            )}
                            colorCustomize={it.educationLevel.colorCode}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                ),
                width: 320,
                sortable: false,
              },
            ]
          : []),
        {
          field: 'colorCode',
          headerName: t('masterData.table.hexColorCode'),
          renderCell: ({ row }: { row: any }) => row.colorCode,
          width: 100,
          sortable: false,
        },
        {
          field: 'isDisabled',
          headerName: t('masterData.table.status'),
          renderCell: ({ row }: { row: any }) => (
            <Switch
              color="success"
              checked={!row?.isDisabled}
              onClick={(event) => {
                event.stopPropagation()
                handleSwitchStatusOnClicked?.(row.id, row?.isDisabled)
              }}
            />
          ),
          width: 100,
          sortable: false,
        },
      ]
    },
    [getDisplayName, t],
  )

  return { renderColumn }
}
