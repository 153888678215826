import { useComputed } from '@polyu-dip/helpers'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogAction,
  TransferOwnershipConfirmDialog,
  useOverlay,
} from '../components'
import {
  clearUserQueryCaches,
  useDisableUser,
  useEnableUser,
  useMySchoolUsers,
  useTransferUserLessonPlans,
} from '@polyu-dip/queries'
import { useStores } from '../stores'
import { PostUserStatusPayload } from '@polyu-dip/apis'
import { useApiErrorHandle } from './use-api-error-handle'
import { useQueryClient } from '@tanstack/react-query'

export function useUserManagementActions(
  isTransferOwnership?: boolean,
  defaultUserId?: string,
) {
  const { t } = useTranslation()
  const { showSpinner, hideSpinner, showSnackbar } = useOverlay()
  const { standardErrorHandler } = useApiErrorHandle()
  const queryClient = useQueryClient()
  const [currentUserId, setCurrentUserId] = useState<string | undefined>(
    defaultUserId,
  )
  const [isDisabled, setIsDisabled] = useState(false)

  const { data: mySchoolUsers } = useMySchoolUsers(
    useStores,
    {},
    { enabled: isTransferOwnership },
  )
  const { usersStore } = useStores()
  const userList = useComputed(() => usersStore.users, [usersStore.users])

  const userOptions = useComputed(
    () =>
      mySchoolUsers?.data
        .filter((user) => user.id !== currentUserId && !user.isDisabled)
        .map((user) => ({
          label: user.email,
          value: user.id,
        })) ?? [],
    [mySchoolUsers, currentUserId],
  )

  const [
    isTransferConfirmationDialogOpen,
    setIsTransferConfirmationDialogOpen,
  ] = useState(false)

  const handleOpenTransferConfirmationDialog = useCallback(
    (userId?: string) => {
      if (defaultUserId == null) {
        setCurrentUserId(userId)
      }
      if (currentUserId == null && userId == null) return
      setIsTransferConfirmationDialogOpen(true)
    },
    [currentUserId, defaultUserId],
  )

  const { mutateAsync: transferUserLessonPlans } = useTransferUserLessonPlans(
    useStores,
    currentUserId ?? '',
    {
      onSuccess: () => {
        clearUserQueryCaches(queryClient)
        setIsTransferConfirmationDialogOpen(false)
        showSnackbar({
          message: t('users.actions.transferOwnership.successMessage'),
        })
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('users.actions.errorMessage.transfer.title'),
        })
      },
    },
  )

  const handleTransferDialogOnSubmit = useCallback(
    async (
      value: string,
      selectedUser?: {
        label: string
        value: string
      },
    ) => {
      if (value != 'transfer' || selectedUser == null) return
      try {
        showSpinner()
        await transferUserLessonPlans(selectedUser?.value ?? '')
      } finally {
        hideSpinner()
      }
    },
    [hideSpinner, showSpinner, transferUserLessonPlans],
  )

  const renderTransferConfirmationDialog = useMemo(() => {
    return (
      <TransferOwnershipConfirmDialog
        open={isTransferConfirmationDialogOpen}
        setOpen={setIsTransferConfirmationDialogOpen}
        title={t('users.actions.transferOwnership.confirmationDialog.title')}
        content={t(
          'users.actions.transferOwnership.confirmationDialog.content',
        )}
        dropdownLabel={t(
          'users.actions.transferOwnership.confirmationDialog.dropdownLabel',
        )}
        dropdownPlaceholder={t(
          'users.actions.transferOwnership.confirmationDialog.dropdownPlaceholder',
        )}
        remarks={t('common.remark')}
        userOptions={userOptions}
        onSubmit={handleTransferDialogOnSubmit}
      />
    )
  }, [
    handleTransferDialogOnSubmit,
    isTransferConfirmationDialogOpen,
    t,
    userOptions,
  ])

  const [
    isDisableAndTransferConfirmationDialogOpen,
    setIsDisableAndTransferConfirmationDialogOpen,
  ] = useState(false)

  const [isDisableConfirmationDialogOpen, setIsDisableConfirmationDialogOpen] =
    useState(false)

  const handleOpenDisableConfirmationDialog = useCallback(
    (
      userId?: string,
      isUserAccountDisabled?: boolean,
      isTransferable?: boolean,
    ) => {
      if (userId == null) return
      setIsDisabled(isUserAccountDisabled ?? false)
      setCurrentUserId(userId)
      if (isTransferable) {
        setIsDisableAndTransferConfirmationDialogOpen(true)
      } else {
        setIsDisableConfirmationDialogOpen(true)
      }
    },
    [],
  )

  const { mutateAsync: disableUser } = useDisableUser(
    useStores,
    currentUserId ?? '',
    {
      onSuccess: () => {
        showSnackbar({
          message: t('users.actions.disableAccount.successMessage'),
        })
        clearUserQueryCaches(queryClient)
        setIsDisableAndTransferConfirmationDialogOpen(false)
        setIsDisableConfirmationDialogOpen(false)
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('users.actions.errorMessage.disable.title'),
        })
      },
    },
  )

  const { mutateAsync: enableUser } = useEnableUser(useStores, {
    onSuccess: () => {
      clearUserQueryCaches(queryClient)
      showSnackbar({
        message: t('users.actions.enableAccount.successMessage'),
      })
    },
    onError: (error) => {
      standardErrorHandler(error, {
        defaultTitle: t('users.actions.errorMessage.enable.title'),
      })
    },
  })

  const handleEnableUser = useCallback(
    async (userId?: string) => {
      try {
        showSpinner()
        const targetUser = userList.find((user) => user.id === userId)
        await enableUser({
          id: userId ?? '',
          rowVersion: targetUser?.rowVersion ?? '',
          isTransferLessonPlan: false,
        })
      } finally {
        hideSpinner()
      }
    },
    [enableUser, hideSpinner, showSpinner, userList],
  )

  const handleDisableAndTransferDialogOnSubmit = useCallback(
    async (
      value: string,
      selectedUser?: {
        label: string
        value: string
      },
    ) => {
      if (value === 'dismiss' || value == null) {
        setIsDisableAndTransferConfirmationDialogOpen(false)
        setIsDisableConfirmationDialogOpen(false)
        return
      }
      try {
        showSpinner()
        const targetUser = userList.find((user) => user.id === currentUserId)
        const payload: PostUserStatusPayload =
          value === 'transfer'
            ? {
                isTransferLessonPlan: true,
                transferToUserId: selectedUser?.value ?? '',
                rowVersion: targetUser?.rowVersion ?? '',
              }
            : {
                isTransferLessonPlan: false,
                rowVersion: targetUser?.rowVersion ?? '',
              }
        await disableUser(payload)
      } finally {
        hideSpinner()
      }
    },
    [currentUserId, disableUser, hideSpinner, showSpinner, userList],
  )

  const renderDisableAndTransferConfirmationDialog = useMemo(() => {
    return (
      <TransferOwnershipConfirmDialog
        open={isDisableAndTransferConfirmationDialogOpen}
        setOpen={setIsDisableAndTransferConfirmationDialogOpen}
        title={t('users.actions.disableAccount.confirmationDialog.title')}
        content={t('users.actions.disableAccount.confirmationDialog.content')}
        dropdownLabel={t(
          'users.actions.disableAccount.confirmationDialog.dropdownLabel',
        )}
        dropdownPlaceholder={t(
          'users.actions.disableAccount.confirmationDialog.dropdownPlaceholder',
        )}
        remarks={t('common.remark')}
        userOptions={userOptions}
        isDisableUser
        isDisabled={isDisabled}
        onSubmit={handleDisableAndTransferDialogOnSubmit}
      />
    )
  }, [
    isDisableAndTransferConfirmationDialogOpen,
    t,
    userOptions,
    isDisabled,
    handleDisableAndTransferDialogOnSubmit,
  ])

  const actions = useMemo((): DialogAction<string>[] => {
    return [
      {
        text: t('common.close'),
        type: 'outlined',
        value: 'dismiss',
      },
      {
        text: t('common.confirm'),
        type: 'contained',
        value: 'disable',
      },
    ]
  }, [t])

  const renderDisableConfirmationDialog = useMemo(() => {
    return (
      <Dialog
        open={isDisableConfirmationDialogOpen}
        title={t('users.actions.disableAccount.confirmationDialog.title')}
        content={t(
          'users.actions.disableAccount.disableOnlyConfirmationDialog.content',
        )}
        actions={actions}
        onSelected={(value: string) => {
          handleDisableAndTransferDialogOnSubmit(value)
        }}
        maxWidth="sm"
        fullWidth
      />
    )
  }, [
    actions,
    handleDisableAndTransferDialogOnSubmit,
    isDisableConfirmationDialogOpen,
    t,
  ])

  return {
    handleOpenTransferConfirmationDialog,
    handleOpenDisableConfirmationDialog,
    renderTransferConfirmationDialog,
    renderDisableAndTransferConfirmationDialog,
    renderDisableConfirmationDialog,
    handleEnableUser,
  }
}
