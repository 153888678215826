import { ApiCore } from '@polyu-dip/api-core'
import { GetContactUsDetailResponse } from './api-configs.types'

const PATH = '/configs'

export function getContactUsDetails() {
  return ApiCore.primary.get<GetContactUsDetailResponse>(
    `${PATH}/contact-us`,
    {},
  )
}
