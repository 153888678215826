import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { LessonPlanGeneralInformationTab } from '../../../../../components'
import { AdvancedKnowledge } from './advanced-knowledge'
import { LearningTarget } from './learning-target'
import { Overview } from './overview'
import { TeachingProcess } from './teaching-process'

type Props = {
  printable?: boolean
  isExample?: boolean
  isHideTeachingClass?: boolean
}

const PrintingRoot = styled.div`
  margin-bottom: 16px;
`

export const TeachingPlanTab = observer<Props>(
  ({ printable, isExample, isHideTeachingClass }) => {
    const [activeTab, setActiveTab] = useState(0.1)

    const tabMenu = useMemo(
      () => [
        {
          id: 0.1,
          render: (
            <Overview
              isExample={isExample}
              isHideTeachingClass={isHideTeachingClass}
            />
          ),
        },
        {
          id: 0.2,
          render: <LearningTarget />,
        },
        {
          id: 0.3,
          render: <AdvancedKnowledge />,
        },
        {
          id: 0.4,
          render: <TeachingProcess />,
        },
      ],
      [isExample, isHideTeachingClass],
    )

    const renderTab = useMemo(() => {
      return tabMenu.find((it) => it.id === activeTab)?.render
    }, [activeTab, tabMenu])

    return (
      <Box>
        {(printable
          ? tabMenu
          : [
              {
                id: tabMenu.length,
                render: null,
              },
            ]
        ).map((tab) => {
          return (
            <PrintingRoot key={tab.id}>
              <LessonPlanGeneralInformationTab
                activeTab={tab.id === tabMenu.length ? activeTab : tab.id}
                onActiveTabChange={setActiveTab}
                printable={printable}
              />
              <Box mt={5}>
                {tab.id === tabMenu.length
                  ? renderTab
                  : tabMenu.find((it) => it.id === tab.id)?.render}
              </Box>
            </PrintingRoot>
          )
        })}
      </Box>
    )
  },
)
