import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { User } from './user.model'

export const LessonPlanConsultModel = BaseEntityModel.named(
  'LessonPlanConsultModel',
)
  .props({
    id: types.string,
    lessonPlanId: types.string,
    status: types.union(
      types.literal('noPendingRequest'),
      types.literal('pending'),
      types.literal('completed'),
      types.literal('followingUp'),
    ),
    content: types.string,
    isSiteVisitNeeded: types.boolean,
    assignedExpertUserId: types.maybe(types.string),
    assignedDateTime: types.maybe(types.string),
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .views((self) => ({
    get assignedExpertUser() {
      if (self.assignedExpertUserId == null) return
      return (self.rootStore.usersStore as { users: User[] }).users.find(
        (user) => user.id === self.assignedExpertUserId,
      )
    },
  }))

export type LessonPlanConsult = Instance<typeof LessonPlanConsultModel>
