import { Box, Button, Grid, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { getInputNumberValue } from '@polyu-dip/utilities'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Control,
  Controller,
  FieldValues,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../../../assets'
import { FormLabel, TextField, SwitchGroup } from '../../../../../components'
import { useStores } from '../../../../../stores'
import { GeneralInformationFormData } from '../../../lesson-plan-form-provider'
import { DIStrategyModal } from './di-strategy-popup'
import { v4 as uuidV4 } from 'uuid'
import { Strategy } from '../../../../../hooks'
import { sortByNullableOrder } from '../../../../../utilities'

type TeachingFlowGroupProps = {
  control: Control<GeneralInformationFormData, any>
  teachingFlowIndex?: number
  fieldArrayName: any //!FIXME consider to use FieldPath<GeneralInformationFormData>
  studentGroupIndex: number
}

const PlusButtonContainer = styled.div`
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DeleteButtonContainer = styled(PlusButtonContainer)`
  padding-right: ${({ theme }) => theme.spacings.general[1]}px;
`

const DiStrategyButton = styled(Button)`
  flex: 1;
  padding-left: ${({ theme }) => theme.spacings.general[2]}px;
  margin-bottom: ${({ theme }) => theme.spacings.general[1]}px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    cursor: unset;
  }
`

const DIStrategyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const TeachingFlowGroup = observer<TeachingFlowGroupProps>(
  ({ control, fieldArrayName, studentGroupIndex }) => {
    const [dialogOpenForIndex, setDialogOpenForIndex] = useState<number | null>(
      null,
    )

    const {
      watch,
      setValue,
      trigger,
      formState: { errors },
    } = useFormContext()

    const lessonPlanTeachingStepsErrors = useMemo(() => {
      const formError = { ...errors } as any
      const lessonPlanTeachingStepFormErrors =
        formError?.lessonPlanStudentGroups?.[studentGroupIndex]
          ?.lessonPlanTeachingSteps
      if (lessonPlanTeachingStepFormErrors == null) return
      return lessonPlanTeachingStepFormErrors
    }, [errors, studentGroupIndex])

    const lessonPlanTeachingSteps = watch(
      `lessonPlanStudentGroups.${studentGroupIndex}.lessonPlanTeachingSteps`,
    )

    const { t } = useTranslation()

    const { masterDataStore } = useStores()

    const diStrategyData = useComputed(
      () =>
        masterDataStore.diStrategies.map((diStrategy) => ({
          id: diStrategy.id,
          displayLabel: t('lessonPlan.createForm.diStrategies.label', {
            diStrategyClassLabel: diStrategy?.diStrategyClass?.label,
            diStrategyLabelNumber: diStrategy?.order,
            diStrategyName: diStrategy?.displayName,
          }),
        })),
      [masterDataStore.diStrategies],
    )

    const { fields, append, update } = useFieldArray({
      control,
      name: `lessonPlanStudentGroups.${studentGroupIndex}.lessonPlanTeachingSteps`,
    })

    const data = useWatch<
      FieldValues,
      `lessonPlanStudentGroups.${number}.lessonPlanTeachingSteps`
    >({
      name: `lessonPlanStudentGroups.${studentGroupIndex}.lessonPlanTeachingSteps`,
      defaultValue: fields,
    })

    const [dataWithKey, setDataWithKey] = useState(
      data.map((it: any) => ({ ...it, id: uuidV4() })),
    )

    useEffect(
      () => setDataWithKey(data.map((it: any) => ({ ...it, id: uuidV4() }))),
      [data, lessonPlanTeachingSteps],
    )

    const openTeachingStepDiStrategyDialog = useCallback((index: number) => {
      setDialogOpenForIndex(index)
    }, [])

    const onNumberChange = useCallback(
      (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string,
      ) => {
        const numberValue = getInputNumberValue(e.target.value)
        setValue(fieldName, numberValue)
      },
      [setValue],
    )

    const orderDiStrategies = useCallback(
      (lessonPlanTeachingStepDiStrategies: { diStrategyId: string }[]) => {
        return (
          lessonPlanTeachingStepDiStrategies
            ?.map((it) =>
              masterDataStore.diStrategies
                .slice()
                ?.find((diStrategy) => diStrategy.id == it.diStrategyId),
            )
            .map((it) => ({
              diStrategyId: it?.id,
              order: (it?.order ?? 0) + (it?.diStrategyClass?.order ?? 0),
            })) as Strategy[]
        ).sort(sortByNullableOrder)
      },
      [masterDataStore.diStrategies],
    )

    const handleSwitchOrderOnClicked = useCallback(
      async (index1: number, index2: number) => {
        let newData = dataWithKey.slice()
        let temp = newData[index1]
        newData[index1] = { ...newData[index2], order: index1 }
        newData[index2] = { ...temp, order: index2 }
        temp = lessonPlanTeachingSteps[index1]
        lessonPlanTeachingSteps[index1] = {
          ...lessonPlanTeachingSteps[index2],
          order: index1,
        }
        lessonPlanTeachingSteps[index2] = { ...temp, order: index2 }

        setDataWithKey(newData)
      },
      [dataWithKey, lessonPlanTeachingSteps],
    )

    return (
      <Box mt={5}>
        {dataWithKey.map((item: any, flowIndex: number) => (
          <Grid key={item.id} container columnSpacing={3}>
            <Grid item flexShrink={0} width="60px">
              {flowIndex === 0 && (
                <Box textAlign="center">
                  <FormLabel
                    label={t(
                      'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingProcess.teachingFlow.order',
                    )}
                  />
                </Box>
              )}
              <Grid container flexDirection="row">
                <Grid
                  sm={6}
                  alignItems="center"
                  textAlign="right"
                  display="flex"
                >
                  <Typography width="100%">{flowIndex + 1}</Typography>
                </Grid>
                <Grid sm={6} container flexDirection="column" flexWrap="nowrap">
                  <SwitchGroup
                    index={flowIndex}
                    totalRows={dataWithKey.length}
                    onClickedSwitchUp={(event) => {
                      event.stopPropagation()
                      handleSwitchOrderOnClicked(flowIndex, flowIndex - 1)
                    }}
                    onClickedSwitchDown={(event) => {
                      event.stopPropagation()
                      handleSwitchOrderOnClicked(flowIndex, flowIndex + 1)
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item flexShrink={1}>
              <Controller
                control={control}
                name={`${fieldArrayName}[${flowIndex}].duration` as any}
                rules={{
                  min: 0,
                  onBlur: async () => {
                    //! FIXME field path always error, causing onBlur validation never trigger
                    // manually trigger all here
                    await trigger()
                  },
                }}
                render={({ field }) => (
                  <TextField
                    label={
                      flowIndex === 0
                        ? t(
                            'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingProcess.teachingFlow.duration.label',
                          )
                        : undefined
                    }
                    placeholder={t(
                      'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingProcess.teachingFlow.duration.placeholder',
                    )}
                    mb={2}
                    type="number"
                    inputProps={{ min: 0, step: 5 }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    value={watch(`${fieldArrayName}[${flowIndex}].duration`)}
                    onChange={(e) =>
                      onNumberChange(
                        e,
                        `${fieldArrayName}[${flowIndex}].duration`,
                      )
                    }
                    error={
                      lessonPlanTeachingStepsErrors?.[flowIndex]?.duration
                        ?.message
                    }
                    isReserverForHelperText
                  />
                )}
              />
            </Grid>
            <Grid item flexShrink={1}>
              <Controller
                control={control}
                name={`${fieldArrayName}[${flowIndex}].activity` as any}
                render={({ field }) => (
                  <TextField
                    label={
                      flowIndex === 0
                        ? t(
                            'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingProcess.teachingFlow.activity.label',
                          )
                        : undefined
                    }
                    placeholder={t(
                      'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingProcess.teachingFlow.activity.placeholder',
                    )}
                    multiline
                    rows={3}
                    mb={2}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item flex={4} textAlign="center">
              <Box mb={1}>
                <FormLabel
                  label={
                    flowIndex === 0
                      ? t(
                          'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingProcess.teachingFlow.diStrategyButton',
                        )
                      : ''
                  }
                />
              </Box>
              <DIStrategyContainer>
                {lessonPlanTeachingSteps[flowIndex]
                  ?.lessonPlanTeachingStepDiStrategies[0]?.diStrategyId && (
                  <>
                    {orderDiStrategies(
                      lessonPlanTeachingSteps[flowIndex]
                        ?.lessonPlanTeachingStepDiStrategies,
                    ).map((it: Strategy) => (
                      <DiStrategyButton key={it.diStrategyId} disableRipple>
                        <DeleteButtonContainer>
                          <Svg.Close
                            cursor="pointer"
                            onClick={() =>
                              update(flowIndex, {
                                ...lessonPlanTeachingSteps[flowIndex],
                                lessonPlanTeachingStepDiStrategies:
                                  lessonPlanTeachingSteps[
                                    flowIndex
                                  ]?.lessonPlanTeachingStepDiStrategies.filter(
                                    (diStrategy: Strategy) =>
                                      diStrategy.diStrategyId !==
                                      it.diStrategyId,
                                  ),
                              })
                            }
                            width={14}
                          />
                        </DeleteButtonContainer>
                        {
                          diStrategyData?.find(
                            (diStrategy) => diStrategy.id === it.diStrategyId,
                          )?.displayLabel
                        }
                      </DiStrategyButton>
                    ))}
                  </>
                )}
              </DIStrategyContainer>
              <PlusButtonContainer>
                <Svg.Plus
                  cursor="pointer"
                  onClick={() => openTeachingStepDiStrategyDialog(flowIndex)}
                />
              </PlusButtonContainer>
            </Grid>
            <Grid item flexGrow={6}>
              <Controller
                control={control}
                name={`${fieldArrayName}[${flowIndex}].description` as any}
                render={({ field }) => (
                  <TextField
                    label={
                      flowIndex === 0
                        ? t(
                            'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingProcess.teachingFlow.description.label',
                          )
                        : undefined
                    }
                    placeholder={t(
                      'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingProcess.teachingFlow.description.placeholder',
                    )}
                    mb={2}
                    multiline
                    rows={8}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item flexShrink={0} xs={1}>
              <Box mb={3} mt={flowIndex === 0 ? 6 : 0}>
                {flowIndex > 0 ? (
                  <Svg.Close
                    onClick={() => {
                      setValue(
                        `lessonPlanStudentGroups.${studentGroupIndex}.lessonPlanTeachingSteps`,
                        lessonPlanTeachingSteps.filter(
                          (inst: any, index: number) => index != flowIndex,
                        ),
                      )
                    }}
                  />
                ) : (
                  <Box width="24px" height="24px" />
                )}
              </Box>
            </Grid>
            <DIStrategyModal
              open={dialogOpenForIndex === flowIndex}
              onClose={() => setDialogOpenForIndex(null)}
              onSubmit={(diStrategyIds) => {
                update(flowIndex, {
                  ...lessonPlanTeachingSteps[flowIndex],
                  lessonPlanTeachingStepDiStrategies:
                    diStrategyIds == null
                      ? []
                      : diStrategyIds.map((it) => ({ diStrategyId: it })),
                })
                setDialogOpenForIndex(null)
              }}
              initialStrategyIds={
                lessonPlanTeachingSteps[flowIndex]
                  ?.lessonPlanTeachingStepDiStrategies
              }
            />
          </Grid>
        ))}
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Svg.Plus
              width={24}
              height={24}
              cursor="pointer"
              onClick={() =>
                append({
                  activity: '',
                  description: '',
                  duration: undefined,
                  lessonPlanTeachingStepDiStrategies: [],
                  order: fields.length,
                  resource: '',
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
    )
  },
)
