import { Grid, Typography, Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { SelectedDiStrategies } from '../../../../components'
import { useActiveDiStrategyMaster } from '../../../../hooks'
import { useLessonPlanDetail } from '../../use-lesson-plan-detail-context'

type Props = { printable?: boolean }
export const DiStrategiesTab = observer<Props>(({ printable }) => {
  const { t } = useTranslation()
  const { lessonPlan } = useLessonPlanDetail()

  const { activeDiStrategyClasses, activeDiStrategies } =
    useActiveDiStrategyMaster(
      lessonPlan.lessonPlanDiStrategies.map((it) => it.diStrategyId),
    )

  return (
    <Box>
      <Grid container justifyContent="flex-end" columnSpacing={3}>
        <Grid item mr="auto">
          <Typography fontWeight={600} marginTop={4}>
            {t('lessonPlan.detail.general.diStrategiesTab.title')}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={5}>
        {lessonPlan.lessonPlanDiStrategies.length !== 0 && (
          <SelectedDiStrategies
            diStrategies={activeDiStrategies}
            diStrategyClasses={activeDiStrategyClasses}
            selectedDiStrategies={lessonPlan.lessonPlanDiStrategies}
            isEditMode={false}
            printable={printable}
          />
        )}
      </Box>
    </Box>
  )
})
