import { RoleType, User } from '@polyu-dip/models'
import { useCallback, useMemo } from 'react'
import { useStores } from '../stores'

export function useUserManagementPermission() {
  const { userProfileStore } = useStores()

  const currentUserProfile = useMemo(
    () => userProfileStore.userProfile,
    [userProfileStore.userProfile],
  )

  const userManagementPermissionHandler = useCallback(
    (user: User) => {
      if (currentUserProfile == null) {
        return {
          canTransferOwnership: false,
          canDisableUser: false,
          canEditUser: false,
        }
      }
      if (currentUserProfile?.role?.label == RoleType.schoolAdmin) {
        return {
          canTransferOwnership: true,
          canDisableUser: true,
          canEditUser: true,
        }
      }
      if (currentUserProfile?.role?.label == RoleType.diTeam) {
        return {
          canTransferOwnership: false,
          canDisableUser: true,
          canEditUser: true,
        }
      }
      return {
        canTransferOwnership: false,
        canDisableUser: false,
        canEditUser: user?.id == currentUserProfile.id,
      }
    },
    [currentUserProfile],
  )

  return { userManagementPermissionHandler }
}
