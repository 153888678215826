import { Button, Grid } from '@mui/material'
import { LessonPlanPublishRequestResponsePayload } from '@polyu-dip/apis'
import {
  useCopyLessonPlanExample,
  useIncrementLessonPlanExportCountById,
  useIncrementLessonPlanViewCountById,
  useLessonPlanDetail,
  useResourceTypes,
} from '@polyu-dip/queries'
import { delay } from '@polyu-dip/utilities'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Breadcrumbs, FullscreenLoading, useOverlay } from '../../components'
import { contentPaths } from '../../content-paths'
import {
  useApiErrorHandle,
  useLessonPlanPermission,
  useMasterDataInit,
} from '../../hooks'
import { useStores } from '../../stores'
import { Title } from './components'
import {
  BasicInformation,
  DetailTabs,
  DiStrategiesTab,
} from './lesson-plan-detail'
import { ExampleDetailActions } from './lesson-plan-example-detail'
import { LessonPlanExportDocument } from './lesson-plan-export-document'
import { LessonPlanDetailContext } from './use-lesson-plan-detail-context'

const Container = styled.div``

const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  flex: 3;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-shrink: 2;
  flex-direction: row;
`

type LessonPlanExampleDetailPageProps = {
  lessonPlanId?: string
  processingLessonPlanPublishRequest?: LessonPlanPublishRequestResponsePayload
  isPublishCopy?: boolean
}

export const LessonPlanExampleDetailPage =
  observer<LessonPlanExampleDetailPageProps>(
    ({ lessonPlanId, processingLessonPlanPublishRequest, isPublishCopy }) => {
      const { t } = useTranslation()
      const { showSnackbar } = useOverlay()
      const { standardErrorHandler } = useApiErrorHandle()
      const navigate = useNavigate()

      const { isAllMasterDataFetchSuccess } = useMasterDataInit(
        processingLessonPlanPublishRequest != null
          ? contentPaths.lessonPlanPublishRequests()
          : contentPaths.lessonPlanExamples(),
      )

      const { lessonPlanId: lessonPlanIdFromParam } = useParams<{
        lessonPlanId: string
      }>()

      const currentLessonPlanId = useMemo(
        () => lessonPlanId ?? lessonPlanIdFromParam ?? '',
        [lessonPlanId, lessonPlanIdFromParam],
      )

      const { lessonPlanPermissionHandler } = useLessonPlanPermission()

      useResourceTypes(useStores)

      const { mutateAsync: incrementViewCount } =
        useIncrementLessonPlanViewCountById(useStores, currentLessonPlanId, {})

      const { mutateAsync: incrementExportCount } =
        useIncrementLessonPlanExportCountById(
          useStores,
          currentLessonPlanId,
          {},
        )

      const { data, isLoading } = useLessonPlanDetail(
        useStores,
        currentLessonPlanId,
        {},
        {
          enabled: currentLessonPlanId.length > 0,
          onError: (error) => {
            standardErrorHandler(error, {
              onDismiss: () => {
                navigate(contentPaths.lessonPlanExamples())
              },
            })
          },
          onSuccess: () => {
            incrementViewCount()
          },
        },
      )

      const [showPrintableWindow, setShowPrintableWindow] = useState(false)
      const handleRequestPrint = useCallback(() => {
        ;(async () => {
          incrementExportCount()
          setShowPrintableWindow(true)
          await delay(1000)
          // setShowPrintableWindow(false)
        })()
      }, [incrementExportCount])

      const { mutateAsync: copy } = useCopyLessonPlanExample(
        useStores,
        currentLessonPlanId,
        {
          onSuccess: () => {
            showSnackbar({
              message: t('lessonPlanExample.action.copy.successMessage'),
            })
          },
          onError: (error) => {
            standardErrorHandler(error, {
              defaultTitle: t('lessonPlanExample.action.copy.error.title'),
            })
          },
        },
      )

      const handleRequestCopy = useCallback(() => {
        copy()
      }, [copy])

      const { canEditLessonPlanExample, canCopyLessonPlanExample } =
        lessonPlanPermissionHandler(data)

      const breadcrumbs = useMemo(() => {
        const commonBreadCrumbs = [
          {
            to: '#',
            children: data?.code,
          },
        ]
        if (processingLessonPlanPublishRequest != null) {
          return [
            {
              to: contentPaths.lessonPlanPublishRequests(),
              children: t('sidebar.publishManagement'),
            },
            ...commonBreadCrumbs,
          ]
        }
        if (isPublishCopy) {
          return [
            {
              to: contentPaths.myLessonPlan(),
              children: t('sidebar.myLessonPlan'),
            },
            ...commonBreadCrumbs,
          ]
        }
        return [
          {
            to: canEditLessonPlanExample
              ? contentPaths.lessonPlanExampleManagements()
              : contentPaths.lessonPlanExamples(),
            children: t('sidebar.lessonPlanExamples'),
          },
          ...commonBreadCrumbs,
        ]
      }, [
        canEditLessonPlanExample,
        data?.code,
        isPublishCopy,
        processingLessonPlanPublishRequest,
        t,
      ])

      if (isLoading || !data || !isAllMasterDataFetchSuccess) {
        return <FullscreenLoading />
      }

      return (
        <LessonPlanDetailContext.Provider value={{ lessonPlan: data }}>
          <Container>
            <Breadcrumbs breadcrumbs={breadcrumbs} />

            <HeaderContainer>
              <TitleContainer>
                <Title />
              </TitleContainer>
              {canEditLessonPlanExample && (
                <ButtonContainer>
                  <ExampleDetailActions
                    lessonPlanId={data.id}
                    processingLessonPlanPublishRequest={
                      processingLessonPlanPublishRequest
                    }
                  />
                </ButtonContainer>
              )}
            </HeaderContainer>

            <BasicInformation
              onRequestPrint={handleRequestPrint}
              onRequestCopy={
                canCopyLessonPlanExample ? handleRequestCopy : undefined
              }
              isExample
              isPublishCopy={isPublishCopy}
              isDisplayPublishStatus={canEditLessonPlanExample || isPublishCopy}
              isDisplayAgreePublish={canEditLessonPlanExample || isPublishCopy}
            />

            <DiStrategiesTab />
            <DetailTabs lessonPlanId={data.id} isExample />
            {processingLessonPlanPublishRequest != null &&
            canEditLessonPlanExample &&
            processingLessonPlanPublishRequest.initiatedLessonPlanId != null ? (
              <Grid container justifyContent="flex-end">
                <Grid item width={193}>
                  <Button
                    fullWidth
                    color="orange"
                    onClick={() =>
                      navigate(
                        contentPaths.lessonPlanPublishRequest(
                          processingLessonPlanPublishRequest.id,
                          'view-comments',
                        ),
                      )
                    }
                  >
                    {t('lessonPlanExample.detail.viewInitiatedLessonPlan')}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Container>

          {showPrintableWindow && (
            <LessonPlanExportDocument
              lessonPlanId={data.id}
              isExample
              onClose={() => setShowPrintableWindow(false)}
            />
          )}
        </LessonPlanDetailContext.Provider>
      )
    },
  )
