import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { LoginLoading } from '../components'
import { useRestoreSession } from '../services'
import { LoginPage } from './login'

export const PrivatePage = observer<{ children: ReactNode }>(({ children }) => {
  const sessionRestored = useRestoreSession()

  if (sessionRestored == null) {
    return <LoginLoading />
  }

  if (sessionRestored === false) {
    // ? to persist the URL, render the login page here
    return <LoginPage />
  }

  return <>{children}</>
})
