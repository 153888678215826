export const resources = {
  title: 'Resources',

  tags: {
    onlineResources: 'Online resources',
    downloadableResource: 'Download area',
  },

  tableFields: {
    title: 'Title',
    resourceType: 'Type',
    lastModifiedDateTime: 'Last updated on',

    learningPhase: 'Learning phases',
    classLevel: 'Grade',
    subject: 'Subject',
    download: 'Download',
  },

  pagination: {},

  createOnlineResource: {
    title: 'Add project',
    field: {
      title: {
        label: 'Title',
        placeholder: 'Title',
      },
      url: {
        label: 'Link',
        placeholder: 'URL',
      },
      type: {
        label: 'Resources category',
        placeholder: 'Resources category',
      },
    },
    action: {
      create: 'Add project',
    },
  },
  editOnlineResource: {
    title: 'Edit project',
    action: {
      update: 'Confirm edit',
      delete: 'Delete project',
    },
  },

  filter: {
    title: 'Title',
    resourceType: 'Type',
    learningPhase: 'Learning phases',
    classLevel: 'Grade',
    subject: 'Subject',
  },

  actions: {
    delete: 'Delete',
    add: 'Add',
    upload: 'Upload',
    selfLearningModule: 'Self-learning module',
  },

  createDownloadableResource: {
    title: 'Upload project',

    field: {
      url: {
        label: 'Link',
        placeholder: 'URL',
      },
      title: {
        label: 'Title',
        placeholder: 'Title',
      },
      classLevel: {
        label: 'Grade',
        placeholder: 'Grade',
      },
      subject: {
        label: 'Subject',
        placeholder: 'Subject',
      },
      type: {
        label: 'Resources category',
        placeholder: 'Resources category',
      },
    },

    action: {
      create: 'Add project',
    },
  },

  editDownloadableResource: {
    title: 'Edit project',
    action: {
      update: 'Confirm edit',
      delete: 'Delete',
    },
  },

  deleteResources: {
    error: 'Fail to delete project',
    success: 'Project deleted successfully',
  },
  updateResources: {
    error: 'Fail to edit project',
    success: 'Project edited successfully',
  },
  addResources: {
    error: 'Fail to add project',
    success: 'Project added successfully',
  },
  uploadResources: {
    error: 'Fail to upload project',
    success: 'Project uploaded successfully',
  },
}
