export const lessonPlanRequest = {
  entityName: {
    consultRequest: 'Consultation request',
    publishRequest: 'Publish request',
  },
  followingUp: {
    title: 'Follow up on consultation',

    tabs: {
      all: 'All',
      followingUp: 'In progress',
      completed: 'Replied',
    },
  },
  consultManagement: {
    title: 'Consultation management',

    tabs: {
      pending: 'To follow up',
      followingUp: 'In progress',
      completed: 'Replied',
    },

    pendingItems: 'Pending projects ',

    consultDetail: {
      title: 'Enquiries or questions from teachers',
      label: 'Enquiry about DI strategies and related contents',
      placeholder: 'Filled enquiry form',
      requireObservation:
        'Wish to have experts to observe my lesson (recorded or face-to-face)',

      actions: {
        viewLessonPlan: 'View lesson plan',
      },
    },
  },
  publishManagement: {
    title: 'Approval to publish ',

    tabs: {
      pending: 'Pending',
      published: 'Published',
      unPublished: 'Removed',
    },
  },

  columnTitles: {
    isConsultRequest: 'Consult',
    teachingTopic: 'Name of lesson plan',
    authorUser: 'Author',
    expert: 'Expert-in-charge',
    assignedDate: 'Appointment date',
    lastModifiedDateTime: 'Last updated on',
    publishedDateTime: 'Publication time',
    unPublishedOrRejectedDateTime: 'Removal / Rejection time ',
    class: 'Grade',
    subject: 'Subject',
    status: 'Status',
    isAgreePublish: 'Consent to publish',
  },
}
