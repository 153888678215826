import { Button, Grid, Typography } from '@mui/material'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledTabButton = styled(Button)<{ $isActive?: boolean }>(
  ({ theme, $isActive }) => ({
    ...($isActive
      ? {
          backgroundColor: theme.colors.tertiary,
        }
      : {
          backgroundColor: theme.palettes.general.white,
          color: theme.palettes.general.greys[1],
          fontWeight: 400,
        }),
  }),
)

const PrintableGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.colors.tertiary};
  -webkit-print-color-adjust: exact;
`

const StyledTitle = styled(Typography)`
  padding: 5px 6px;
  color: ${({ theme }) => theme.colors.primary};
`

type Props = {
  activeTab: number
  onActiveTabChange: (val: number) => void
  printable?: boolean
}

export const LessonPlanGeneralInformationTab = memo<Props>(
  ({ activeTab, onActiveTabChange, printable }) => {
    const { t } = useTranslation()

    const generalTabs = useMemo(
      () => [
        {
          id: 0.1,
          name: 'OVERVIEW',
          label: t('lessonPlan.createForm.generalInformation.tabs.overview'),
        },
        {
          id: 0.2,
          name: 'LEARNING_TARGET',
          label: t(
            'lessonPlan.createForm.generalInformation.tabs.learningTarget',
          ),
        },
        {
          id: 0.3,
          name: 'ADVANCED_KNOWLEDGE',
          label: t(
            'lessonPlan.createForm.generalInformation.tabs.advancedKnowledge',
          ),
        },
        {
          id: 0.4,
          name: 'TEACHING_PROCESS',
          label: t(
            'lessonPlan.createForm.generalInformation.tabs.teachingProcess',
          ),
        },
      ],
      [t],
    )

    return (
      <Grid container columnSpacing={3}>
        {printable ? (
          <PrintableGrid xs={12}>
            <StyledTitle>
              {generalTabs.find((it) => it.id === activeTab)?.label}
            </StyledTitle>
          </PrintableGrid>
        ) : (
          generalTabs.map((it) => (
            <Grid key={it.id} item>
              <StyledTabButton
                variant="text"
                $isActive={it.id === activeTab}
                onClick={() => onActiveTabChange(it.id)}
              >
                {it.label}
              </StyledTabButton>
            </Grid>
          ))
        )}
      </Grid>
    )
  },
)
