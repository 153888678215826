import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField } from '../../../../../components'
import { SectionBox } from '../../../components'
import { GeneralInformationFormData } from '../../../lesson-plan-form-provider'
import { TabHeader } from '../tab-header'

type AdvancedKnowledgeTabProps = {}

export const AdvancedKnowledgeTab = observer<AdvancedKnowledgeTabProps>(() => {
  const { t } = useTranslation()
  const { control } = useFormContext<GeneralInformationFormData>()

  return (
    <SectionBox>
      <TabHeader />
      <Grid container columnSpacing={5} mt={4}>
        <Grid item sm={6}>
          <Controller
            control={control}
            render={({ field }) => (
              <TextField
                label={t(
                  'lessonPlan.createForm.generalInformation.fields.advancedKnowledge.requiredKnowledge.label',
                )}
                placeholder={t(
                  'lessonPlan.createForm.generalInformation.fields.advancedKnowledge.requiredKnowledge.placeholder',
                )}
                multiline
                rows={3}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
              />
            )}
            name="requiredKnowledge"
          />
        </Grid>
        <Grid item sm={6}>
          <Controller
            control={control}
            render={({ field }) => (
              <TextField
                label={t(
                  'lessonPlan.createForm.generalInformation.fields.advancedKnowledge.expectedDifficulty.label',
                )}
                placeholder={t(
                  'lessonPlan.createForm.generalInformation.fields.advancedKnowledge.expectedDifficulty.placeholder',
                )}
                multiline
                rows={3}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
              />
            )}
            name="expectedDifficulty"
          />
        </Grid>
        <Grid item sm={12} mt={4}>
          <Controller
            control={control}
            render={({ field }) => (
              <TextField
                label={t(
                  'lessonPlan.createForm.generalInformation.fields.advancedKnowledge.remark.label',
                )}
                placeholder={t(
                  'lessonPlan.createForm.generalInformation.fields.advancedKnowledge.remark.placeholder',
                )}
                multiline
                rows={2}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
              />
            )}
            name="remark"
          />
        </Grid>
      </Grid>
    </SectionBox>
  )
})
