type Optional<T> = T extends null ? Exclude<T, null> | undefined : T
type OptionalObject<T> = T extends object
  ? {
      [key in keyof T]: OptionalObject<T[key]>
    }
  : Optional<T>

export function convertNullToUndefined(payload: undefined | null): undefined
export function convertNullToUndefined<T extends object>(
  payload: T,
): OptionalObject<T>

export function convertNullToUndefined<T extends object>(
  payload: T | null | undefined,
): OptionalObject<T> | undefined {
  if (payload == null) return undefined

  const isObject = typeof payload === 'object'
  const isArray = Array.isArray(payload)

  if (isArray) {
    return payload.map(convertNullToUndefined) as any
  }
  if (isObject)
    return Object.entries(payload).reduce((prev, [key, value]) => {
      return {
        ...prev,
        [key]: convertNullToUndefined(value),
      }
    }, {} as any)

  return payload ?? undefined
}
