import { Box, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Svg } from '../../../../assets'
import { UploadFile } from '../../../../components'
import { AttachmentList, AttachmentListProps } from './attachment-list'

type UploadFileProps = {
  onUploadFile: (file: File) => void
  onAddUrlAttachment?: never
  maxSizeInMb: number
  acceptedFileType?: string
}

type UploadLinkProps = {
  onAddUrlAttachment: (url: string) => void
  onUploadFile?: never
  maxSizeInMb: number
  acceptedFileType?: never
}

type UploadSectionProps = (UploadFileProps | UploadLinkProps) &
  AttachmentListProps

export const UploadAttachmentSection = observer<UploadSectionProps>(
  ({
    onUploadFile,
    onAddUrlAttachment,
    maxSizeInMb,
    acceptedFileType,
    targetField,
    ...attachmentTabProps
  }) => {
    return (
      <Box>
        <AttachmentList
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attachmentTabProps}
          targetField={targetField}
        />
        {onUploadFile != null ? (
          <Grid container justifyContent="center" mt={5}>
            <UploadFile
              onUploadFile={onUploadFile}
              maxSizeInMb={maxSizeInMb}
              acceptedFileType={acceptedFileType}
            />
          </Grid>
        ) : (
          <>
            {targetField === 'linkResources' && (
              <Grid container justifyContent="flex-end">
                <Svg.Plus
                  cursor="pointer"
                  onClick={() => onAddUrlAttachment('')}
                />
              </Grid>
            )}
          </>
        )}
      </Box>
    )
  },
)
