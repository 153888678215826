import { useStores } from '../stores'
import { useRoles, useSchools } from '@polyu-dip/queries'
import { useComputed } from '@polyu-dip/helpers'
import { useState } from 'react'
import { SelectOption } from '../components'
import { Role, RoleType, School } from '@polyu-dip/models'

export function useUserFilter(isFilterBySchool: boolean = false) {
  const { data: roles } = useRoles(useStores)

  const [selectedRole, setSelectedRole] = useState<SelectOption>()
  const [selectedSchool, setSelectedSchool] = useState<SelectOption>()
  const [emailFilter, setEmailFilter] = useState<string>()
  const { data: schools } = useSchools(
    useStores,
    { isDisabled: false },
    { enabled: isFilterBySchool },
  )

  const roleOptions = useComputed(
    () =>
      roles?.data.slice().map((role: Role) => ({
        label: role.displayName,
        value: role.id,
        roleType: role.label,
      })),
    [roles],
  )

  const schoolAdminRoleOptions = useComputed(
    () =>
      roles?.data
        .slice()
        .filter(
          (it) =>
            it.label == RoleType.schoolAdmin || it.label == RoleType.teacher,
        )
        .map((role: Role) => ({
          label: role.displayName,
          value: role.id,
          roleType: role.label,
        })),
    [roles],
  )

  const schoolOptions = useComputed(
    () =>
      schools?.data.slice().map((school: School) => ({
        label: school.shortCode,
        value: school.id,
        domain: school.domain,
      })),
    [schools?.data],
  )

  return {
    roleOptions,
    schoolAdminRoleOptions,
    schoolOptions,
    selectedRole,
    selectedSchool,
    setSelectedRole,
    setSelectedSchool,
    emailFilter,
    setEmailFilter,
  }
}
