import { createContext } from 'react'
import { DialogProps } from '../dialog'
import { SnackbarProps } from '../snackbar'

type Display = undefined | 'dialog' | 'spinner' // | 'prompt'

export type DialogOption<T> = Omit<DialogProps<T>, 'onSelected'>

export type SnackbarOption = SnackbarProps & { timeout?: number | 'inf' }

type Overlay = {
  currentDisplay: Display
  showDialog<T>(option: DialogOption<T>): Promise<T | undefined>
  showSpinner(): void
  hideSpinner(): void
  showSnackbar(option: SnackbarOption & { timeout?: number | 'inf' }): void
}

export const OverlayContext = createContext<Overlay>({
  currentDisplay: undefined,
  showDialog() {
    throw new Error('overlay-context not available')
  },
  showSpinner() {
    throw new Error('overlay-context not available')
  },
  hideSpinner() {
    throw new Error('overlay-context not available')
  },
  showSnackbar() {
    throw new Error('overlay-context not available')
  },
})
