import { Button, Grid } from '@mui/material'
import { DiStrategy, DiStrategyClass } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import { Checkbox, DiStrategyDetail } from '../../../components'
import { Strategy } from '../../../hooks'

const MenuItem = styled(Button)<{ $isActive: boolean }>`
  text-align: left;
  padding: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  justify-content: flex-start;
  height: auto;
  font-size: 16px;
  font-weight: ${({ $isActive }) => ($isActive ? 600 : 400)};
  border-radius: 10px;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.tertiary : theme.palettes.general.white};
  .MuiButton-endIcon {
    margin-left: auto;
  }
  .MuiFormControlLabel-root {
    margin-right: 0px;
    margin-left: -8px;
  }
`

const Container = styled.div``

type DIStrategySelectionProps = {
  selectedStrategies: Strategy[]
  onSelectStrategies: (items: Strategy[]) => void

  diStrategyOptions: DiStrategy[]
  diStrategyClasses: DiStrategyClass[]
  columnSpacing?: number
  isShowChoiceReasonInput?: boolean
  isMultiSelect?: boolean

  onSelectReasonChange?: (id: string, reason: string) => void
  maxHeight?: number
}

export const DIStrategySelection = observer<DIStrategySelectionProps>(
  ({
    selectedStrategies,
    onSelectStrategies,
    diStrategyOptions,
    diStrategyClasses,
    columnSpacing,
    isShowChoiceReasonInput,
    onSelectReasonChange,
    isMultiSelect = true,
    maxHeight,
  }) => {
    const { t } = useTranslation()

    const defaultSelectedDiStrategy = useMemo(
      () =>
        diStrategyOptions.filter(
          (it) => it.id == selectedStrategies[0]?.diStrategyId,
        )[0],
      [diStrategyOptions, selectedStrategies],
    )

    const [activeCategory, setActiveCategory] = useState<{
      id: string
      label: string
    }>({
      id:
        defaultSelectedDiStrategy?.diStrategyClassId ?? diStrategyClasses[0].id,
      label:
        diStrategyClasses.filter(
          (it) => it.id == defaultSelectedDiStrategy?.diStrategyClassId,
        )[0]?.label ?? diStrategyClasses[0].label,
    })

    const [selectStrategyForView, setSelectStrategyForView] =
      useState<Strategy>({
        diStrategyId:
          defaultSelectedDiStrategy?.id ??
          diStrategyOptions.filter(
            (it) => it.diStrategyClassId == activeCategory.id,
          )[0]?.id,
        reason: selectedStrategies.find(
          (selectedStrategy) =>
            selectedStrategy.diStrategyId == defaultSelectedDiStrategy?.id,
        )?.reason,
      })

    const handleReasonChange = useCallback(
      (id: string, reason: string) => {
        if (onSelectReasonChange == null) return
        onSelectReasonChange(id, reason)
        setSelectStrategyForView({
          ...selectStrategyForView,
          reason: reason,
        })
      },
      [onSelectReasonChange, selectStrategyForView],
    )

    return (
      <Container>
        <Grid
          container
          columnSpacing={columnSpacing ?? 2}
          mt={5}
          flexWrap="nowrap"
        >
          <Grid
            item
            width={170}
            flexShrink={0}
            overflow="auto"
            maxHeight={maxHeight}
          >
            {diStrategyClasses.map((it) => (
              <MenuItem
                key={it.id}
                fullWidth
                $isActive={it.id === activeCategory.id}
                onClick={() =>
                  setActiveCategory({
                    id: it.id,
                    label: it.label,
                  })
                }
                endIcon={<Svg.ChevronRight />}
                variant="text"
              >
                {t('lessonPlan.createForm.diStrategyClass.label', {
                  label: it.label,
                  name: it.displayName,
                })}
              </MenuItem>
            ))}
          </Grid>
          <Grid
            item
            flex={1}
            flexShrink={0}
            overflow="auto"
            maxHeight={maxHeight}
          >
            {diStrategyOptions
              .filter((it) => it.diStrategyClassId === activeCategory.id)
              ?.map((it) => (
                <MenuItem
                  key={it.id}
                  fullWidth
                  $isActive={selectStrategyForView?.diStrategyId === it.id}
                  onClick={() => {
                    const newSelectedStrategy = selectedStrategies.find(
                      (selectedStrategy) =>
                        selectedStrategy.diStrategyId == it.id,
                    ) ?? {
                      diStrategyId: it.id,
                      order: it.order + (it.diStrategyClass?.order ?? 0),
                    }
                    setSelectStrategyForView(newSelectedStrategy)
                    if (!isMultiSelect) {
                      onSelectStrategies([newSelectedStrategy])
                    }
                  }}
                  endIcon={<Svg.ChevronRight />}
                  startIcon={
                    isMultiSelect ? (
                      <Checkbox
                        checked={selectedStrategies.some(
                          (selectedStrategy) =>
                            selectedStrategy.diStrategyId === it.id,
                        )}
                        onChange={(e) => {
                          e.stopPropagation()
                          onSelectStrategies(
                            e.target.checked
                              ? [
                                  ...selectedStrategies,
                                  {
                                    diStrategyId: it.id,
                                    order:
                                      it.order +
                                      (it.diStrategyClass?.order ?? 0),
                                  },
                                ]
                              : selectedStrategies.filter(
                                  (strategy) => strategy.diStrategyId !== it.id,
                                ),
                          )
                        }}
                      />
                    ) : (
                      <></>
                    )
                  }
                  variant="text"
                >
                  {t('lessonPlan.createForm.diStrategies.label', {
                    diStrategyClassLabel: activeCategory.label,
                    diStrategyLabelNumber: it.order,
                    diStrategyName: it.displayName,
                  })}
                </MenuItem>
              ))}
          </Grid>
          <Grid item flex={1}>
            {selectStrategyForView != null && (
              <DiStrategyDetail
                diStrategies={diStrategyOptions}
                diStrategyClasses={diStrategyClasses}
                diStrategyId={selectStrategyForView.diStrategyId}
                reason={selectStrategyForView.reason}
                isShowChoiceReasonInput={
                  selectedStrategies.some(
                    (selectedStrategy) =>
                      selectedStrategy.diStrategyId ===
                      selectStrategyForView.diStrategyId,
                  ) && isShowChoiceReasonInput
                }
                onSelectReasonChange={handleReasonChange}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    )
  },
)
