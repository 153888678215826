import { GridSortModel } from '@mui/x-data-grid'
import { useMySchoolUsers } from '@polyu-dip/queries'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { useComputed } from '@polyu-dip/helpers'
import {
  Table,
  useRowCountState,
  UserFilter,
  SponsorFooter,
  AddUserDialog,
  ListPage,
} from '../../../components'
import { useStores } from '../../../stores'
import { observer } from 'mobx-react-lite'
import { PaginationParameters } from '@polyu-dip/apis'
import { useUserFilter, useUserManagementActions } from '../../../hooks'
import { useDisplayName } from '../../../services'
import { useNavigate } from 'react-router-dom'
import { contentPaths } from '../../../content-paths'
import { useUsersColumn } from '../use-users-column'

const PAGINATION_LIMIT = 15

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

export const SchoolUsers = observer(() => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { userProfileStore } = useStores()
  const navigate = useNavigate()

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [sort, setSort] = useState<GridSortModel>()
  const [isAddUserDialogOpen, setAddUserDialogOpen] = useState<boolean>(false)

  const currentUserSchoolId = useMemo(
    () => userProfileStore.userProfile?.schoolId ?? '',
    [userProfileStore.userProfile?.schoolId],
  )

  const { schoolAdminRoleOptions, ...filterProps } = useUserFilter()
  const { renderColumn } = useUsersColumn()
  const { getDisplayName } = useDisplayName()

  const filterItems = useMemo(() => {
    return {
      roleId: filterProps.selectedRole?.value as string,
      email: filterProps.emailFilter,
    }
  }, [filterProps])

  const { data, isLoading } = useMySchoolUsers(useStores, {
    sort: sort?.map((it) => ({
      direction: it.sort ?? 'desc',
      parameter: it.field,
    })) ?? [{ parameter: 'email', direction: 'asc' }],
    ...pagination,
    ...filterItems,
  })

  const totalRecord = useMemo(() => {
    return data?.total
  }, [data])

  const { rowCountState } = useRowCountState(totalRecord)

  const {
    handleOpenTransferConfirmationDialog,
    renderTransferConfirmationDialog,
    handleOpenDisableConfirmationDialog,
    renderDisableAndTransferConfirmationDialog,
    handleEnableUser,
  } = useUserManagementActions()

  const handleSwitchUserStatusOnClicked = useCallback(
    async (userId: string, isDisabled?: boolean) => {
      if (isDisabled) {
        await handleEnableUser(userId)
      } else {
        handleOpenDisableConfirmationDialog(userId, isDisabled, true)
      }
    },
    [handleEnableUser, handleOpenDisableConfirmationDialog],
  )

  const handleTransferOwnership = useCallback(
    (userId: string) => {
      handleOpenTransferConfirmationDialog(userId)
    },
    [handleOpenTransferConfirmationDialog],
  )

  const currentUserId = useComputed(
    () => userProfileStore.userProfile?.id ?? '',
    [userProfileStore.userProfile?.id],
  )

  const computedData = useComputed(
    () =>
      (data?.data ?? []).map((it) => ({
        ...it,
        role: {
          ...it.role,
          displayName: getDisplayName(it.role?.name),
        },
        school: {
          ...it.school,
        },
      })),
    [data?.data, getDisplayName],
  )

  const handleAddUserButtonClicked = useCallback(() => {
    setAddUserDialogOpen(true)
  }, [])

  return (
    <ListPage
      sectionTitleProps={{
        title: t('users.title.schoolUser'),
        color: 'brown',
      }}
    >
      <UserFilter
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...filterProps}
        roleOptions={schoolAdminRoleOptions}
        onAddUserButtonClicked={handleAddUserButtonClicked}
      />

      <Table
        columns={renderColumn(
          currentUserId,
          true,
          handleSwitchUserStatusOnClicked,
          handleTransferOwnership,
        )}
        rows={computedData}
        padding={theme.spacings.general[4]}
        paddingTop={0}
        pageSize={PAGINATION_LIMIT}
        loading={isLoading}
        paginationMode="server"
        page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
        hideFooterPagination={
          rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
        }
        initialState={{
          sorting: {
            sortModel: [{ field: 'email', sort: 'asc' }],
          },
        }}
        rowCount={rowCountState}
        sortModel={sort}
        onSortModelChange={setSort}
        onRowClick={({ row }) => navigate(contentPaths.userManagement(row.id))}
        onPageChange={(page) =>
          setPagination((old) => ({
            ...old,
            offset: page * PAGINATION_LIMIT,
          }))
        }
      />

      <SponsorFooter />

      <AddUserDialog
        isOpen={isAddUserDialogOpen}
        setOpen={setAddUserDialogOpen}
        userRoleOptions={schoolAdminRoleOptions ?? []}
        currentUserSchoolId={currentUserSchoolId}
      />

      {renderTransferConfirmationDialog}
      {renderDisableAndTransferConfirmationDialog}
    </ListPage>
  )
})
