import { memo } from 'react'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { TruncatedCellContent } from '../table'

const StyledBadgeIcon = styled(Svg.Badge)<{ isExpert?: boolean }>`
  margin-left: 8px;
  display: flex;
  fill: ${({ theme }) => theme.palettes.general.blue};
  visibility: ${({ isExpert }) => (isExpert ? 'visible' : 'hidden')};
`

type UserTagProps = {
  userName: string
  isExpert?: boolean
  userNameMaxContentWidth?: number
}

export const UserTag = memo<UserTagProps>(
  ({ userName, isExpert, userNameMaxContentWidth }) => {
    return (
      <>
        <TruncatedCellContent
          content={userName}
          maxContentWidth={userNameMaxContentWidth}
        />
        <StyledBadgeIcon isExpert={isExpert} />
      </>
    )
  },
)
