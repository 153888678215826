import { createTheme } from '@mui/material/styles'
import { colors } from './colors'
import { colorPalette } from '@polyu-dip/theme'

export const muiTheme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: [
      'lato',
      'Noto Sans TC',
      'ArialUnicode',
      'Roboto',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    blue: {
      main: colorPalette.general.blue,
      contrastText: colorPalette.general.white,
    },
    lightRed: {
      main: colorPalette.general.lightRed,
      contrastText: colorPalette.general.white,
    },
    sharpRed: {
      main: colorPalette.general.sharpRed,
      contrastText: colorPalette.general.white,
    },
    cascade: {
      main: colorPalette.general.cascade,
      contrastText: colorPalette.general.white,
    },
    brown: {
      main: colorPalette.semantic.brown,
      contrastText: colorPalette.general.white,
    },
    success: {
      main: colorPalette.general.success,
      contrastText: colorPalette.general.white,
    },
    darkestGrey: {
      main: colorPalette.general.greys[0],
      contrastText: colorPalette.general.white,
    },
    darkGrey: {
      main: colorPalette.general.greys[2],
      contrastText: colorPalette.general.white,
    },
    redLabel: {
      main: '#F9DBDB',
      contrastText: colors.accent,
    },
    greenLabel: {
      main: colorPalette.general.lightGreen,
      contrastText: colorPalette.general.cascade,
    },
    orange: {
      main: '#F49625',
      contrastText: colorPalette.general.white,
    },
    astronautBlue: {
      main: colorPalette.semantic.astronautBlue,
      contrastText: colorPalette.general.white,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:after': {
            borderBottomColor: colorPalette.general.greys[3],
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        type: 'button',
        style: { boxShadow: 'none' },
      },
      styleOverrides: {
        sizeMedium: {
          height: 35,
          borderRadius: 10,
          fontSize: 16,
          fontWeight: 600,
          textTransform: 'none',
          ':disabled': {
            backgroundColor: colorPalette.general.greys[3],
            color: colorPalette.general.white,
          },
        },
        sizeLarge: {
          height: 43,
          borderRadius: 10,
          fontSize: 16,
          fontWeight: 600,
          textTransform: 'none',
          ':disabled': {
            backgroundColor: colorPalette.general.greys[3],
            color: colorPalette.general.white,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          width: 24,
          height: 24,
          minWidth: 24,
          '&.Mui-selected': {
            backgroundColor: colorPalette.general.blue,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 600,
          borderRadius: 8,
          padding: '12px 16px',
          alignItems: 'center',
        },
        filledSuccess: {
          backgroundColor: colorPalette.general.success,
          color: colorPalette.general.white,
        },
        icon: {
          padding: 0,
          fontSize: 16,
          marginRight: 8,
        },
        action: {
          padding: 0,
          marginLeft: 20,
        },
        message: {
          padding: 0,
        },
      },
    },
  },
})

declare module '@mui/material/styles' {
  interface Palette {
    blue: Palette['primary']
    lightRed: Palette['primary']
    sharpRed: Palette['primary']
    cascade: Palette['primary']
    brown: Palette['primary']
    darkestGrey: Palette['primary']
    darkGrey: Palette['primary']
    redLabel: Palette['primary']
    greenLabel: Palette['primary']
    orange: Palette['primary']
    astronautBlue: Palette['primary']
  }
  interface PaletteOptions {
    blue: PaletteOptions['primary']
    lightRed: PaletteOptions['primary']
    sharpRed: PaletteOptions['primary']
    cascade: PaletteOptions['primary']
    brown: PaletteOptions['primary']
    darkestGrey: PaletteOptions['primary']
    darkGrey: PaletteOptions['primary']
    redLabel: PaletteOptions['primary']
    greenLabel: PaletteOptions['primary']
    orange: PaletteOptions['primary']
    astronautBlue: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    blue: true
    lightRed: true
    sharpRed: true
    cascade: true
    brown: true
    darkGrey: true
    darkestGrey: true
    orange: true
    astronautBlue: true
  }
}

declare module '@mui/material/Chip' {
  export interface ChipPropsColorOverrides {
    cascade: true
    redLabel: true
    greenLabel: true
    sharpRed: true
    brown: true
    orange: true
  }
}
