import dayjs from 'dayjs'
import { types } from 'mobx-state-tree'

export const BaseEntityModel = types
  .model('BaseEntity', {
    createdByUserId: types.string,
    lastModifiedByUserId: types.string,
    createdDateTime: types.string,
    lastModifiedDateTime: types.string,
    rowVersion: types.string,
  })
  .views((self) => ({
    get lastModifiedTimeDayJs() {
      return dayjs(self.lastModifiedDateTime)
    },
    get createdTimeDayJs() {
      return dayjs(self.createdDateTime)
    },
  }))
