import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { LessonPlanExpectedOutcomeModel } from './lesson-plan-expected-outcome.model'
import { LessonPlanTeachingGoalModel } from './lesson-plan-teaching-goal.model'
import { LessonPlanTeachingStepModel } from './lesson-plan-teaching-step.model'

export const LessonPlanStudentGroupModel = BaseEntityModel.named(
  'LessonPlanStudentGroupModel',
)
  .props({
    id: types.string,
    groupCharacteristic: types.maybe(types.string),
    order: types.maybe(types.number),
    lessonPlanTeachingSteps: types.optional(
      types.array(LessonPlanTeachingStepModel),
      [],
    ),
    lessonPlanExpectedOutcomes: types.optional(
      types.array(LessonPlanExpectedOutcomeModel),
      [],
    ),
    lessonPlanTeachingGoals: types.optional(
      types.array(LessonPlanTeachingGoalModel),
      [],
    ),
  })
  .extend(withUpdate)

export type LessonPlanStudentGroup = Instance<
  typeof LessonPlanStudentGroupModel
>

export function createEmptyLessonPlanStudentGroup(
  existingStudentGroupCount: number,
) {
  return {
    order: existingStudentGroupCount + 1,
    groupCharacteristic: '',
    lessonPlanExpectedOutcomes: [
      {
        description: '',
        order: 1,
      },
    ],
    lessonPlanTeachingGoals: [
      {
        description: '',
        order: 1,
      },
    ],
    lessonPlanTeachingSteps: [
      {
        duration: undefined,
        activity: '',
        description: '',
        order: 1,
        lessonPlanTeachingStepDiStrategies: [],
      },
    ],
  }
}
