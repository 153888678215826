import { ApiCore } from '@polyu-dip/api-core'
import {
  convertExpandParameters,
  convertSortParameters,
  GetPayload,
} from '../base'
import { LessonPlan } from '../entity'
import { SchoolExpandParameter, SchoolResponsePayload } from '../schools'
import {
  PutUserPayload,
  PostUserPayload,
  PostUsersPayload,
  PostUsersResponsePayload,
  PostUserStatusPayload,
  UserExpandParameter,
  UserResponsePayload,
  UsersQueryParameters,
  UsersResponsePayload,
} from './api-users.types'

const PATH = '/users'

export function getUsers(param?: UsersQueryParameters) {
  return ApiCore.primary.get<UsersResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: convertExpandParameters(param),
    },
  })
}

export function getUserById(id: string, param?: UserExpandParameter) {
  return ApiCore.primary.get<UserResponsePayload>(`${PATH}/${id}`, {
    param: {
      ...param,
      expand: convertExpandParameters(param),
    },
  })
}

export function postUser(payload: PostUserPayload) {
  return ApiCore.primary.post<UserResponsePayload>(PATH, {
    body: payload,
  })
}

export function putUser(id: string, payload: PutUserPayload) {
  return ApiCore.primary.put<UserResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}

export function getCurrentUserProfile(param?: UsersQueryParameters) {
  return ApiCore.primary.get<UserResponsePayload>(`${PATH}/me`, {
    param: {
      ...param,
      expand: convertExpandParameters(param),
    },
  })
}

export function postCurrentUserProfile(payload: PostUserPayload) {
  return ApiCore.primary.post<UserResponsePayload>(`${PATH}/me`, {
    body: payload,
  })
}

export function getCurrentSchool(param?: SchoolExpandParameter) {
  return ApiCore.primary.get<SchoolResponsePayload>(`${PATH}/me/school`, {
    param: {
      ...param,
      expand: convertExpandParameters(param),
    },
  })
}

export function getCurrentSchoolUsers(param?: UsersQueryParameters) {
  return ApiCore.primary.get<UsersResponsePayload>(`${PATH}/me/school/users`, {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: convertExpandParameters(param),
    },
  })
}

export function getExperts(param?: UsersQueryParameters) {
  return ApiCore.primary.get<UsersResponsePayload>(`${PATH}/experts`, {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: convertExpandParameters(param),
    },
  })
}

export function postUsers(payload: PostUsersPayload) {
  return ApiCore.primary.post<PostUsersResponsePayload>(`${PATH}/bulk`, {
    body: payload,
  })
}

export function postUserTransferLessonPlans(
  id: string,
  transferToUserId: string,
) {
  return ApiCore.primary.post<GetPayload<LessonPlan>[]>(
    `${PATH}/${id}/transfer-lesson-plans`,
    {
      body: {
        transferToUserId,
      },
    },
  )
}
export function postUserDisable(id: string, payload: PostUserStatusPayload) {
  return ApiCore.primary.post<UserResponsePayload>(`${PATH}/${id}/disable`, {
    body: payload,
  })
}

export function postUserEnable(id: string, payload: PostUserStatusPayload) {
  return ApiCore.primary.post<UserResponsePayload>(`${PATH}/${id}/enable`, {
    body: payload,
  })
}
