import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form'
import { NewFormData } from './master-data-list'
import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Checkbox, Dropdown, FormLabel, TextField } from '../form'
import { Svg } from '../../assets'
import { useMasterData } from '../../hooks'
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input'
import styled from 'styled-components'
import { useComputed } from '@polyu-dip/helpers'

const StyledMuiColorInput = styled(MuiColorInput)`
  width: 0px;
`

type Props = {
  control: Control<NewFormData, any>
  errors?: Partial<FieldErrorsImpl<NewFormData>>
  watch: UseFormWatch<NewFormData>
  setValue: UseFormSetValue<NewFormData>
  getValues: UseFormGetValues<NewFormData>
  reset: UseFormReset<NewFormData>
  selectedDataId: string
  withTagGroupName?: boolean
}

const fieldName = 'newData'
const fieldIndex = 0

export const TagGroupDetailManagement = observer<Props>(
  ({
    // reset,
    // selectedDataId,
    setValue,
    control,
    errors,
    withTagGroupName,
    watch,
  }) => {
    const { t } = useTranslation()

    const { tagTypesOptions } = useMasterData()

    const { fields, remove, append, move } = useFieldArray({
      control,
      name: `${fieldName}.${fieldIndex}.tagGroupTags`,
    })

    const tagTypeValueOnOpen = useComputed(() => {
      return watch(`${fieldName}.0.tagTypeId`)
    }, [fieldName])

    return (
      <Grid container direction="column">
        <Grid container direction="row" wrap="nowrap">
          <Grid item xs={1} pr={2}>
            <Controller
              control={control}
              name={`${fieldName}.${fieldIndex}.order`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  type="number"
                  inputProps={{ min: 0 }}
                  label={t(
                    'masterData.editDiStrategyDetailDialog.field.order.label',
                  )}
                  placeholder={t(
                    'masterData.editDiStrategyDetailDialog.field.order.placeholder',
                  )}
                  error={errors?.newData?.[fieldIndex]?.order?.message}
                  isReserverForHelperText
                />
              )}
            />
          </Grid>
          <Grid item xs={2} pr={2}>
            <Controller
              control={control}
              name={`${fieldName}.${fieldIndex}.tagTypeId`}
              render={({ field }) => (
                <Dropdown
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  options={tagTypesOptions
                    ?.filter(
                      (it) => !it.disabled || it.value == tagTypeValueOnOpen,
                    )
                    ?.map((option) => ({
                      ...option,
                      disabled: false,
                    }))}
                  label={t(
                    `masterData.addMasterDataDialog.field.tagType.label`,
                  )}
                  placeholder={t(
                    `masterData.addMasterDataDialog.field.tagType.label`,
                  )}
                  error={errors?.newData?.[fieldIndex]?.tagTypeId?.message}
                  isReserverForHelperText
                />
              )}
            />
          </Grid>
          {withTagGroupName && (
            <>
              <Grid item xs={2} pr={2}>
                <Controller
                  control={control}
                  name={`${fieldName}.${fieldIndex}.chineseName`}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      label={t(
                        'masterData.editDiStrategyDetailDialog.field.tagGroup.chineseName.label',
                      )}
                      placeholder={t(
                        'masterData.editDiStrategyDetailDialog.field.chineseName.placeholder',
                      )}
                      error={
                        errors?.newData?.[fieldIndex]?.chineseName?.message
                      }
                      isReserverForHelperText
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} pr={2}>
                <Controller
                  control={control}
                  name={`${fieldName}.${fieldIndex}.englishName`}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      label={t(
                        'masterData.editDiStrategyDetailDialog.field.tagGroup.englishName.label',
                      )}
                      placeholder={t(
                        'masterData.editDiStrategyDetailDialog.field.englishName.placeholder',
                      )}
                      error={
                        errors?.newData?.[fieldIndex]?.englishName?.message
                      }
                      isReserverForHelperText
                    />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid item xs={1} pr={2} flexShrink={0} minWidth={120}>
            <Controller
              control={control}
              name={`${fieldName}.${fieldIndex}.colorCode`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  label={t(
                    'masterData.editDiStrategyDetailDialog.field.colorCode.label',
                  )}
                  placeholder={t(
                    'masterData.editDiStrategyDetailDialog.field.colorCode.placeholder',
                  )}
                  error={errors?.newData?.[fieldIndex]?.colorCode?.message}
                  isReserverForHelperText
                />
              )}
            />
          </Grid>

          <Grid item mt={6}>
            <Controller
              control={control}
              name={`${fieldName}.${fieldIndex}.colorCode`}
              render={({ field }) => (
                <StyledMuiColorInput
                  value={field.value ?? '#ffffff'}
                  format="hex"
                  placeholder={t(
                    'masterData.addMasterDataDialog.field.colorCode.placeholder',
                  )}
                  isAlphaHidden
                  variant="standard"
                  onChange={(val: string, color: MuiColorInputColors) => {
                    setValue(
                      `${fieldName}.${fieldIndex}.colorCode`,
                      color.hex.toUpperCase(),
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item pl={10} flexShrink={0}>
            <FormLabel
              label={t(
                'masterData.addMasterDataDialog.field.isMultiSelect.label',
              )}
            />
            <Controller
              control={control}
              name={`${fieldName}.${fieldIndex}.isMultiSelect`}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </Grid>

          <Grid item xs={5}>
            {fields.map((field, index) => (
              <Grid
                container
                gap={2}
                key={field.id}
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item mb={4} width={40}>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    watch(
                      `${fieldName}.${fieldIndex}.tagGroupTags.${index}.id`,
                    ) != null ? (
                      watch(
                        `${fieldName}.${fieldIndex}.tagGroupTags.${index}.isDisabled`,
                      ) ? (
                        <Svg.Plus
                          width={20}
                          cursor="pointer"
                          onClick={() =>
                            setValue(
                              `${fieldName}.${fieldIndex}.tagGroupTags.${index}.isDisabled`,
                              false,
                            )
                          }
                        />
                      ) : (
                        <Svg.Close
                          width={20}
                          cursor="pointer"
                          onClick={() =>
                            setValue(
                              `${fieldName}.${fieldIndex}.tagGroupTags.${index}.isDisabled`,
                              true,
                            )
                          }
                        />
                      )
                    ) : (
                      fields.length > 1 && (
                        <Svg.Close
                          width={20}
                          cursor="pointer"
                          onClick={() => remove(index)}
                        />
                      )
                    )
                  }
                </Grid>
                <Grid item>
                  <Controller
                    control={control}
                    name={`${fieldName}.${fieldIndex}.tagGroupTags.${index}.chineseName`}
                    render={({ field: textField }) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...textField}
                        label={t(
                          'masterData.addMasterDataDialog.field.chineseName.label',
                        )}
                        placeholder={t(
                          'masterData.addMasterDataDialog.field.chineseName.placeholder',
                        )}
                        error={
                          (
                            errors?.newData?.[fieldIndex]?.tagGroupTags as any
                          )?.[index]?.chineseName?.message
                        }
                        isReserverForHelperText
                        disabled={watch(
                          `${fieldName}.${fieldIndex}.tagGroupTags.${index}.isDisabled`,
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    control={control}
                    name={`${fieldName}.${fieldIndex}.tagGroupTags.${index}.englishName`}
                    render={({ field: textField }) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...textField}
                        label={t(
                          'masterData.addMasterDataDialog.field.englishName.label',
                        )}
                        placeholder={t(
                          'masterData.addMasterDataDialog.field.englishName.placeholder',
                        )}
                        error={
                          (
                            errors?.newData?.[fieldIndex]?.tagGroupTags as any
                          )?.[index]?.englishName?.message
                        }
                        isReserverForHelperText
                        disabled={watch(
                          `${fieldName}.${fieldIndex}.tagGroupTags.${index}.isDisabled`,
                        )}
                      />
                    )}
                  />
                </Grid>
                {fields.length > 1 && (
                  <Grid item pb={2}>
                    {index > 0 && (
                      <Svg.ChevronUp
                        cursor="pointer"
                        onClick={() => move(index, index - 1)}
                      />
                    )}
                    {index < fields.length - 1 && (
                      <Svg.ChevronDown
                        cursor="pointer"
                        onClick={() => move(index, index + 1)}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid container mt={2} justifyContent="end">
          <Grid item>
            <Svg.Plus cursor="pointer" onClick={() => append({} as any)} />
          </Grid>
        </Grid>
      </Grid>
    )
  },
)
