import {
  PaginatedPayload,
  PostTagTypePayload,
  PutTagTypePayload,
  TagTypesQueryParameters,
} from '@polyu-dip/apis'
import { TagType } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'tag-types'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useTagTypes(
  useStores: UseStoresType,
  param?: TagTypesQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<TagType>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getTagTypes(param),
    opts,
  )
}

export function useCreateTagTypes(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostTagTypePayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payloads) => masterDataStore.createTagTypes(payloads), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useUpdateTagType(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<TagType, unknown, PutTagTypePayload>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => masterDataStore.updateTagType(id, payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useSwitchTagTypeStatus(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    TagType,
    unknown,
    PutTagTypePayload & { id: string }
  >,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => masterDataStore.updateTagType(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export async function clearTagTypeQueryCaches(queryClient: QueryClient) {
  await queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
