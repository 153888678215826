import {
  getSubjects,
  PaginatedPayload,
  SubjectResponsePayload,
  SubjectsQueryParameters,
  SubjectsResponsePayload,
  LearningUnitResponsePayload,
  LearningUnitsQueryParameters,
  LearningUnitsResponsePayload,
  getLearningUnits,
  LearningPhaseResponsePayload,
  LearningPhasesQueryParameters,
  LearningPhasesResponsePayload,
  getLearningPhases,
  EducationLevelResponsePayload,
  EducationLevelsQueryParameters,
  EducationLevelsResponsePayload,
  getEducationLevels,
  ClassLevelResponsePayload,
  ClassLevelsQueryParameters,
  ClassLevelsResponsePayload,
  getClassLevels,
  TeachingClassResponsePayload,
  TeachingClassesQueryParameters,
  TeachingClassesResponsePayload,
  getTeachingClasses,
  TagTypeResponsePayload,
  TagTypesQueryParameters,
  TagTypesResponsePayload,
  getTagTypes,
  TagResponsePayload,
  TagsQueryParameters,
  TagsResponsePayload,
  getTags,
  DiStrategyResponsePayload,
  DiStrategiesQueryParameters,
  DiStrategiesResponsePayload,
  getDiStrategies,
  DiStrategyClassResponsePayload,
  DiStrategyClassesQueryParameters,
  DiStrategyClassesResponsePayload,
  getDiStrategyClasses,
  ResourceTypeResponsePayload,
  getResourceTypes,
  ResourceTypesResponsePayload,
  ResourceTypesQueryParameters,
  DiStrategyExpandableParameter,
  getDiStrategyDetails,
  postSubjectsBulk,
  PostSubjectPayload,
  PutSubjectPayload,
  putSubjectById,
  PostResourceTypePayload,
  postResourceTypesBulk,
  PutResourceTypePayload,
  putResourceTypeById,
  PostLearningPhasePayload,
  postLearningPhasesBulk,
  PutLearningPhasePayload,
  putLearningPhaseById,
  PostEducationLevelPayload,
  postEducationLevelsBulk,
  PutEducationLevelPayload,
  putEducationLevelById,
  PostDiStrategyClassPayload,
  postDiStrategyClassesBulk,
  PutDiStrategyClassPayload,
  putDiStrategyClassById,
  PostDiStrategyPayload,
  postDiStrategiesBulk,
  PutDiStrategyPayload,
  putDiStrategyById,
  PostClassLevelPayload,
  postClassLevelBulk,
  PutClassLevelPayload,
  putClassLevelById,
  PostTagPayload,
  postTagBulk,
  PutTagPayload,
  putTagById,
  PostTagTypePayload,
  postTagTypeBulk,
  PutTagTypePayload,
  putTagTypeById,
  SystemParameterResponsePayload,
  SystemParametersQueryParameters,
  SystemParametersResponsePayload,
  getSystemParameters,
  PostSystemParameterPayload,
  postSystemParametersBulk,
  PutSystemParameterPayload,
  putSystemParameterByName,
  TagGroupResponsePayload,
  TagGroupsQueryParameters,
  TagGroupsResponsePayload,
  getTagGroups,
  PostTagGroupPayload,
  postTagGroupBulk,
  PutTagGroupPayload,
  putTagGroupById,
} from '@polyu-dip/apis'
import { withRootStore } from '@polyu-dip/model-core'
import {
  ClassLevel,
  ClassLevelModel,
  DiStrategy,
  DiStrategyClass,
  DiStrategyClassModel,
  DiStrategyModel,
  EducationLevel,
  EducationLevelModel,
  LearningPhase,
  LearningPhaseModel,
  LearningUnit,
  LearningUnitModel,
  ResourceType,
  ResourceTypeModel,
  Subject,
  SubjectModel,
  SystemParameter,
  SystemParameterModel,
  Tag,
  TagGroup,
  TagGroupModel,
  TagModel,
  TagType,
  TagTypeModel,
  TeachingClass,
  TeachingClassModel,
} from '@polyu-dip/models'
import { upsertList } from '@polyu-dip/utilities'
import { flow, Instance, types } from 'mobx-state-tree'

const emptyPayloadMessage = 'empty-payload'

export type MasterDataTable =
  | 'subjects'
  | 'learningUnits'
  | 'learningPhases'
  | 'educationLevels'
  | 'classLevels'
  | 'teachingClasses'
  | 'tagTypes'
  | 'tags'
  | 'resourceTypes'
  | 'diStrategies'
  | 'diStrategyClasses'
  | 'diStrategyTags'
  | 'systemParameters'
  | 'tagGroups'

export const MasterDataStoreModel = types
  .model('MasterDataStore')
  .props({
    subjects: types.array(SubjectModel),
    learningUnits: types.array(LearningUnitModel),
    learningPhases: types.array(LearningPhaseModel),
    educationLevels: types.array(EducationLevelModel),
    classLevels: types.array(ClassLevelModel),
    teachingClasses: types.array(TeachingClassModel),
    tagGroups: types.array(TagGroupModel),
    tagTypes: types.array(TagTypeModel),
    tags: types.array(TagModel),
    resourceTypes: types.array(ResourceTypeModel),
    diStrategies: types.array(DiStrategyModel),
    diStrategyClasses: types.array(DiStrategyClassModel),
    systemParameters: types.array(SystemParameterModel),
  })
  .views((self) => ({
    get activeSubjects() {
      if (self.subjects == null) return []
      return self.subjects
        .slice()
        .sort((a, b) => (a?.order ?? 0) - (b.order ?? 0))
        .filter((it) => !it.isDisabled)
    },
    get activeLearningPhases() {
      if (self.learningPhases == null) return []
      return self.learningPhases
        .slice()
        .sort((a, b) => (a?.order ?? 0) - (b.order ?? 0))
        .filter((it) => !it.isDisabled)
    },
    get activeEducationLevels() {
      if (self.educationLevels == null) return []
      return self.educationLevels
        .slice()
        .sort((a, b) => (a?.order ?? 0) - (b.order ?? 0))
        .filter((it) => !it.isDisabled)
    },
    get activeClassLevels() {
      if (self.classLevels == null) return []
      return self.classLevels
        .slice()
        .sort((a, b) => (a?.order ?? 0) - (b.order ?? 0))
        .filter((it) => !it.isDisabled)
    },
    get activeTagTypes() {
      if (self.tagTypes == null) return []
      return self.tagTypes
        .slice()
        .sort((a, b) => (a?.order ?? 0) - (b.order ?? 0))
        .filter((it) => !it.isDisabled)
    },
    get activeTags() {
      if (self.tags == null) return []
      return self.tags
        .slice()
        .sort((a, b) => (a?.order ?? 0) - (b.order ?? 0))
        .filter((it) => !it.isDisabled)
    },
    get activeResourceTypes() {
      if (self.resourceTypes == null) return []
      return self.resourceTypes
        .slice()
        .sort((a, b) => (a?.order ?? 0) - (b.order ?? 0))
        .filter((it) => !it.isDisabled)
    },
    get activeDiStrategies() {
      if (self.diStrategies == null) return []
      return self.diStrategies
        .slice()
        .sort((a, b) => (a?.order ?? 0) - (b.order ?? 0))
        .filter((it) => !it.isDisabled)
    },
    get activeDiStrategyClasses() {
      if (self.diStrategyClasses == null) return []
      return self.diStrategyClasses
        .slice()
        .sort((a, b) => (a?.order ?? 0) - (b.order ?? 0))
        .filter((it) => !it.isDisabled)
    },
  }))
  .extend(withRootStore)
  .actions((self) => {
    const actions = {
      upsertSubject(inPayload: SubjectResponsePayload) {
        return upsertList(self.subjects, inPayload)
      },
      upsertSubjects(payloads: SubjectResponsePayload[]) {
        return payloads.map(actions.upsertSubject)
      },
      getSubjects: flow(function* getSubjectsAction(
        param?: SubjectsQueryParameters,
      ): Generator<any, PaginatedPayload<Subject>, SubjectsResponsePayload> {
        const payload = yield getSubjects(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertSubjects(payload.data),
        }
      }),
      createSubjects: flow(function* createSubjectsAction(
        subjects: PostSubjectPayload[],
      ): Generator<any, Subject[], SubjectResponsePayload[]> {
        const payload = yield postSubjectsBulk(subjects)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertSubjects(payload)
      }),
      updateSubject: flow(function* updateSubjectAction(
        id: string,
        subject: PutSubjectPayload,
      ): Generator<any, Subject, SubjectResponsePayload> {
        const payload = yield putSubjectById(id, subject)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertSubject(payload)
      }),
      upsertLearningUnit(inPayload: LearningUnitResponsePayload) {
        return upsertList(self.learningUnits, inPayload)
      },
      upsertLearningUnits(payloads: LearningUnitResponsePayload[]) {
        return payloads.map(actions.upsertLearningUnit)
      },
      getLearningUnits: flow(function* getLearningUnitsAction(
        param?: LearningUnitsQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<LearningUnit>,
        LearningUnitsResponsePayload
      > {
        const payload = yield getLearningUnits(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertLearningUnits(payload.data),
        }
      }),
      upsertLearningPhase(inPayload: LearningPhaseResponsePayload) {
        const { educationLevel, ...maybeNullPayload } = inPayload
        if (educationLevel != null) {
          actions.upsertEducationLevel(educationLevel)
        }
        return upsertList(self.learningPhases, maybeNullPayload)
      },
      upsertLearningPhases(payloads: LearningPhaseResponsePayload[]) {
        return payloads.map(actions.upsertLearningPhase)
      },
      getLearningPhases: flow(function* getLearningPhasesAction(
        param?: LearningPhasesQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<LearningPhase>,
        LearningPhasesResponsePayload
      > {
        const payload = yield getLearningPhases(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertLearningPhases(payload.data),
        }
      }),
      createLearningPhases: flow(function* createLearningPhasesAction(
        learningPhases: PostLearningPhasePayload[],
      ): Generator<any, LearningPhase[], LearningPhaseResponsePayload[]> {
        const payload = yield postLearningPhasesBulk(learningPhases)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertLearningPhases(payload)
      }),
      updateLearningPhase: flow(function* updateLearningPhaseAction(
        id: string,
        learningPhase: PutLearningPhasePayload,
      ): Generator<any, LearningPhase, LearningPhaseResponsePayload> {
        const payload = yield putLearningPhaseById(id, learningPhase)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertLearningPhase(payload)
      }),
      upsertEducationLevel(inPayload: EducationLevelResponsePayload) {
        return upsertList(self.educationLevels, inPayload)
      },
      upsertEducationLevels(payloads: EducationLevelResponsePayload[]) {
        return payloads.map(actions.upsertEducationLevel)
      },
      getEducationLevels: flow(function* getEducationLevelsAction(
        param?: EducationLevelsQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<EducationLevel>,
        EducationLevelsResponsePayload
      > {
        const payload = yield getEducationLevels(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertEducationLevels(payload.data),
        }
      }),
      createEducationLevels: flow(function* createEducationLevelsAction(
        educationLevels: PostEducationLevelPayload[],
      ): Generator<any, EducationLevel[], EducationLevelResponsePayload[]> {
        const payload = yield postEducationLevelsBulk(educationLevels)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertEducationLevels(payload)
      }),
      updateEducationLevel: flow(function* updateEducationLevelAction(
        id: string,
        educationLevel: PutEducationLevelPayload,
      ): Generator<any, EducationLevel, EducationLevelResponsePayload> {
        const payload = yield putEducationLevelById(id, educationLevel)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertEducationLevel(payload)
      }),
      upsertClassLevel(inPayload: ClassLevelResponsePayload) {
        const { learningPhase, ...maybeNullPayload } = inPayload

        if (learningPhase != null) {
          actions.upsertLearningPhase(learningPhase)
        }

        return upsertList(self.classLevels, maybeNullPayload)
      },
      upsertClassLevels(payloads: ClassLevelResponsePayload[]) {
        return payloads.map(actions.upsertClassLevel)
      },
      getClassLevels: flow(function* getClassLevelsAction(
        param?: ClassLevelsQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<ClassLevel>,
        ClassLevelsResponsePayload
      > {
        const payload = yield getClassLevels(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertClassLevels(payload.data),
        }
      }),
      createClassLevels: flow(function* createClassLevelsAction(
        classLevels: PostClassLevelPayload[],
      ): Generator<any, ClassLevel[], ClassLevelResponsePayload[]> {
        const payload = yield postClassLevelBulk(classLevels)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertClassLevels(payload)
      }),
      updateClassLevel: flow(function* updateClassLevelAction(
        id: string,
        classLevel: PutClassLevelPayload,
      ): Generator<any, ClassLevel, ClassLevelResponsePayload> {
        const payload = yield putClassLevelById(id, classLevel)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertClassLevel(payload)
      }),
      upsertTeachingClass(inPayload: TeachingClassResponsePayload) {
        return upsertList(self.teachingClasses, inPayload)
      },
      upsertTeachingClasses(payloads: TeachingClassResponsePayload[]) {
        return payloads.map(actions.upsertTeachingClass)
      },
      getTeachingClasses: flow(function* getTeachingClassesAction(
        param?: TeachingClassesQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<TeachingClass>,
        TeachingClassesResponsePayload
      > {
        const payload = yield getTeachingClasses(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertTeachingClasses(payload.data),
        }
      }),
      upsertTagType(inPayload: TagTypeResponsePayload) {
        return upsertList(self.tagTypes, inPayload)
      },
      upsertTagTypes(payloads: TagTypeResponsePayload[]) {
        return payloads.map(actions.upsertTagType)
      },
      getTagTypes: flow(function* getTagTypesAction(
        param?: TagTypesQueryParameters,
      ): Generator<any, PaginatedPayload<TagType>, TagTypesResponsePayload> {
        const payload = yield getTagTypes(param)

        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertTagTypes(payload.data),
        }
      }),
      createTagTypes: flow(function* createTagTypesAction(
        tagType: PostTagTypePayload[],
      ): Generator<any, TagType[], TagTypeResponsePayload[]> {
        const payload = yield postTagTypeBulk(tagType)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertTagTypes(payload)
      }),
      updateTagType: flow(function* updateTagTypeAction(
        id: string,
        tagType: PutTagTypePayload,
      ): Generator<any, TagType, TagTypeResponsePayload> {
        const payload = yield putTagTypeById(id, tagType)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertTagType(payload)
      }),
      upsertTagGroup(inPayload: TagGroupResponsePayload) {
        const { tagType, tags, ...payload } = inPayload

        if (tagType != null) {
          actions.upsertTagType(tagType)
        }

        if (tags != null) {
          actions.upsertTags(tags)
        }

        return upsertList(self.tagGroups, payload)
      },
      upsertTagGroups(payloads: TagGroupResponsePayload[]) {
        return payloads.map(actions.upsertTagGroup)
      },
      getTagGroups: flow(function* getTagGroupsAction(
        param?: TagGroupsQueryParameters,
      ): Generator<any, PaginatedPayload<TagGroup>, TagGroupsResponsePayload> {
        const payload = yield getTagGroups(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertTagGroups(payload.data),
        }
      }),
      createTagGroups: flow(function* createTagGroupsAction(
        tagGroup: PostTagGroupPayload[],
      ): Generator<any, TagGroup[], TagGroupResponsePayload[]> {
        const payload = yield postTagGroupBulk(tagGroup)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertTagGroups(payload)
      }),
      updateTagGroup: flow(function* updateTagGroupAction(
        id: string,
        tagGroup: PutTagGroupPayload,
      ): Generator<any, TagGroup, TagGroupResponsePayload> {
        const payload = yield putTagGroupById(id, tagGroup)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertTagGroup(payload)
      }),
      upsertTag(inPayload: TagResponsePayload) {
        const { tagGroup, ...maybeNullPayload } = inPayload
        if (tagGroup != null) {
          actions.upsertTagGroup(tagGroup)
        }

        return upsertList(self.tags, maybeNullPayload)
      },
      upsertTags(payloads: TagResponsePayload[]) {
        return payloads.map(actions.upsertTag)
      },
      getTags: flow(function* getTagsAction(
        param?: TagsQueryParameters,
      ): Generator<any, PaginatedPayload<Tag>, TagsResponsePayload> {
        const payload = yield getTags(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertTags(payload.data),
        }
      }),
      createTags: flow(function* createTagsAction(
        tags: PostTagPayload[],
      ): Generator<any, Tag[], TagResponsePayload[]> {
        const payload = yield postTagBulk(tags)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertTags(payload)
      }),
      updateTag: flow(function* updateTagAction(
        id: string,
        tag: PutTagPayload,
      ): Generator<any, Tag, TagResponsePayload> {
        const payload = yield putTagById(id, tag)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertTag(payload)
      }),
      upsertResourceType(inPayload: ResourceTypeResponsePayload) {
        return upsertList(self.resourceTypes, inPayload)
      },
      upsertResourceTypes(payloads: ResourceTypeResponsePayload[]) {
        return payloads.map(actions.upsertResourceType)
      },
      getResourceTypes: flow(function* getResourceTypesAction(
        param?: ResourceTypesQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<ResourceType>,
        ResourceTypesResponsePayload
      > {
        const payload = yield getResourceTypes(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertResourceTypes(payload.data),
        }
      }),
      createResourceTypes: flow(function* createResourcesAction(
        resources: PostResourceTypePayload[],
      ): Generator<any, ResourceType[], ResourceTypeResponsePayload[]> {
        const payload = yield postResourceTypesBulk(resources)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertResourceTypes(payload)
      }),
      updateResourceType: flow(function* updateResourceAction(
        id: string,
        resource: PutResourceTypePayload,
      ): Generator<any, ResourceType, ResourceTypeResponsePayload> {
        const payload = yield putResourceTypeById(id, resource)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertResourceType(payload)
      }),
      upsertDiStrategy(inPayload: DiStrategyResponsePayload) {
        const { diStrategyClass, ...payload } = inPayload
        if (diStrategyClass != null) {
          actions.upsertDiStrategyClass(diStrategyClass)
        }
        if (payload.diStrategyResources != null) {
          payload.diStrategyResources.forEach((diStrategyResource) => {
            if (diStrategyResource?.file == null) return
            self.rootStore.filesStore.upsertFile(diStrategyResource.file)
          })
        }
        return upsertList(self.diStrategies, payload)
      },
      upsertDiStrategies(payloads: DiStrategyResponsePayload[]) {
        return payloads.map(actions.upsertDiStrategy)
      },
      getDiStrategies: flow(function* getDiStrategiesAction(
        param?: DiStrategiesQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<DiStrategy>,
        DiStrategiesResponsePayload
      > {
        const payload = yield getDiStrategies(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertDiStrategies(payload.data),
        }
      }),
      getDiStrategyDetails: flow(function* getDiStrategyDetailsAction(
        id: string,
        param?: DiStrategyExpandableParameter,
      ): Generator<any, DiStrategy, DiStrategyResponsePayload> {
        const payload = yield getDiStrategyDetails(id, param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertDiStrategy(payload)
      }),
      createDiStrategies: flow(function* createDiStrategiesAction(
        diStrategies: PostDiStrategyPayload[],
      ): Generator<any, DiStrategy[], DiStrategyResponsePayload[]> {
        const payload = yield postDiStrategiesBulk(diStrategies)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertDiStrategies(payload)
      }),
      updateDiStrategy: flow(function* updateDiStrategyAction(
        id: string,
        diStrategy: PutDiStrategyPayload,
      ): Generator<any, DiStrategy, DiStrategyResponsePayload> {
        const payload = yield putDiStrategyById(id, diStrategy)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertDiStrategy(payload)
      }),
      upsertDiStrategyClass(inPayload: DiStrategyClassResponsePayload) {
        return upsertList(self.diStrategyClasses, inPayload)
      },
      upsertDiStrategyClasses(payloads: DiStrategyClassResponsePayload[]) {
        return payloads.map(actions.upsertDiStrategyClass)
      },
      getDiStrategyClasses: flow(function* getDiStrategyClassesAction(
        param?: DiStrategyClassesQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<DiStrategyClass>,
        DiStrategyClassesResponsePayload
      > {
        const payload = yield getDiStrategyClasses(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertDiStrategyClasses(payload.data),
        }
      }),
      createDiStrategyClasses: flow(function* createDiStrategyClassesAction(
        diStrategyClasses: PostDiStrategyClassPayload[],
      ): Generator<any, DiStrategyClass[], DiStrategyClassResponsePayload[]> {
        const payload = yield postDiStrategyClassesBulk(diStrategyClasses)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertDiStrategyClasses(payload)
      }),
      updateDiStrategyClass: flow(function* updateDiStrategyClassAction(
        id: string,
        diStrategyClass: PutDiStrategyClassPayload,
      ): Generator<any, DiStrategyClass, DiStrategyClassResponsePayload> {
        const payload = yield putDiStrategyClassById(id, diStrategyClass)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertDiStrategyClass(payload)
      }),
      upsertSystemParameter(inPayload: SystemParameterResponsePayload) {
        return upsertList(self.systemParameters, inPayload)
      },
      upsertSystemParameters(payloads: SystemParameterResponsePayload[]) {
        return payloads.map(actions.upsertSystemParameter)
      },
      getSystemParameters: flow(function* getSystemParametersAction(
        param?: SystemParametersQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<SystemParameter>,
        SystemParametersResponsePayload
      > {
        const payload = yield getSystemParameters(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertSystemParameters(payload.data),
        }
      }),
      createSystemParameters: flow(function* createSystemParametersAction(
        systemParameters: PostSystemParameterPayload[],
      ): Generator<any, SystemParameter[], SystemParameterResponsePayload[]> {
        const payload = yield postSystemParametersBulk(systemParameters)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertSystemParameters(payload)
      }),
      updateSystemParameter: flow(function* updateSystemParameterAction(
        systemParameter: PutSystemParameterPayload,
      ): Generator<any, SystemParameter, SystemParameterResponsePayload> {
        const payload = yield putSystemParameterByName(
          systemParameter.name,
          systemParameter,
        )
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertSystemParameter(payload)
      }),
    }
    return actions
  })

export type MasterDataStore = Instance<typeof MasterDataStoreModel>
