import { Grid } from '@mui/material'
import { memo, ReactNode } from 'react'
import { TabButton } from './tab-button'

type Props = {
  activeTab: number
  tabMenus?: { id: number; label: ReactNode }[]
  onActiveTabChange?: (id: number) => void
}

export const HorizontalTabs = memo<Props>(
  ({ tabMenus, onActiveTabChange, activeTab }) => {
    return (
      <Grid container columnSpacing={3}>
        {tabMenus?.map(({ id, label }) => (
          <Grid item key={id}>
            <TabButton
              $isActive={id === activeTab}
              label={label}
              onClick={() => onActiveTabChange?.(id)}
            />
          </Grid>
        ))}
      </Grid>
    )
  },
)
