import { PostSwitchMasterDataOrderPayload } from '@polyu-dip/apis/src/master-data/master-data-api.types'
import {
  clearClassLevelQueryCaches,
  clearDiStrategyClassQueryCaches,
  clearDiStrategyQueryCaches,
  clearEducationLevelQueryCaches,
  clearLearningPhaseQueryCaches,
  clearResourceTypeQueryCaches,
  clearSubjectQueryCaches,
  clearTagQueryCaches,
  clearTagTypeQueryCaches,
  useCreateClassLevels,
  useCreateDiStrategies,
  useCreateDiStrategyClasses,
  useCreateEducationLevels,
  useCreateLearningPhases,
  useCreateResourceTypes,
  useCreateSubjects,
  useCreateSystemParameters,
  useCreateTags,
  useCreateTagTypes,
  useSwitchMasterDataStatus,
  useSwitchMasterDataOrder,
  useUpdateClassLevel,
  useUpdateDiStrategy,
  useUpdateDiStrategyClass,
  useUpdateEducationLevel,
  useUpdateLearningPhase,
  useUpdateResourceType,
  useUpdateSubject,
  useUpdateSystemParameter,
  useUpdateTag,
  useUpdateTagType,
  useCreateTagGroups,
  useUpdateTagGroup,
  clearTagTagGroupQueryCaches,
} from '@polyu-dip/queries'
import { MasterDataTable } from '@polyu-dip/stores'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogAction, useOverlay } from '../components'
import { useStores } from '../stores'
import { useApiErrorHandle } from './use-api-error-handle'

export function useMasterDataManagement(id?: string) {
  const { t } = useTranslation()
  const { standardErrorHandler } = useApiErrorHandle()
  const queryClient = useQueryClient()
  const { showSpinner, hideSpinner, showSnackbar } = useOverlay()
  const [targetMasterData, setTargetMasterData] = useState<any>()
  const [targetMasterDataName, setTargetMasterDataName] =
    useState<MasterDataTable>()
  const [isDisableConfirmationDialogOpen, setIsDisableConfirmationDialogOpen] =
    useState(false)

  const handleOpenDisableConfirmationDialog = useCallback(
    (targetMaster: any, targetMasterName: MasterDataTable) => {
      setTargetMasterData(targetMaster)
      setTargetMasterDataName(targetMasterName)
      setIsDisableConfirmationDialogOpen(true)
    },
    [],
  )

  const getTargetMasterDataName = useCallback(
    (
      masterDataTable: MasterDataTable,
      selectedContent?: string,
    ): MasterDataTable | undefined => {
      if (
        masterDataTable === 'classLevels' ||
        (masterDataTable === 'learningPhases' &&
          selectedContent === 'classLevels')
      ) {
        return 'classLevels'
      } else if (
        masterDataTable === 'educationLevels' ||
        (masterDataTable === 'learningPhases' &&
          selectedContent === 'educationLevels')
      ) {
        return 'educationLevels'
      } else if (
        masterDataTable === 'learningPhases' ||
        (masterDataTable != null && selectedContent === 'learningPhases')
      ) {
        return 'learningPhases'
      } else if (
        masterDataTable === 'subjects' ||
        (masterDataTable != null && selectedContent === 'subjects')
      ) {
        return 'subjects'
      } else if (
        masterDataTable === 'diStrategies' ||
        (selectedContent != null && selectedContent === 'diStrategies')
      ) {
        return 'diStrategies'
      } else if (
        masterDataTable === 'resourceTypes' ||
        (selectedContent != null && selectedContent === 'resourceTypes')
      ) {
        return 'resourceTypes'
      } else if (
        masterDataTable === 'diStrategyClasses' ||
        (selectedContent != null && selectedContent === 'diStrategyClasses')
      ) {
        return 'diStrategyClasses'
      } else if (
        masterDataTable === 'tags' ||
        (selectedContent != null && selectedContent === 'tags')
      ) {
        return 'tags'
      } else if (
        masterDataTable === 'tagTypes' ||
        (selectedContent != null && selectedContent === 'tagTypes')
      ) {
        return 'tagTypes'
      } else if (
        masterDataTable === 'tagGroups' ||
        (selectedContent != null && selectedContent === 'tagGroups')
      ) {
        return 'tagGroups'
      } else if (
        masterDataTable === 'systemParameters' ||
        (selectedContent != null && selectedContent === 'systemParameters')
      ) {
        return 'systemParameters'
      } else {
        return undefined
      }
    },
    [],
  )

  const handleError = useCallback(
    (error: unknown, mode: 'create' | 'edit') => {
      standardErrorHandler(error, {
        defaultTitle: t(
          `masterData.addMasterDataDialog.errorMessage.${mode}.title`,
        ),
      })
    },
    [standardErrorHandler, t],
  )

  const handleSwitchStatusSuccess = useCallback(
    (isDisabled: boolean) => {
      showSnackbar({
        message: t(
          `masterData.confirmationDialog.action.${
            isDisabled ? 'delete' : 'activate'
          }.successMessage`,
        ),
      })
    },
    [showSnackbar, t],
  )

  const handleUpdateSuccess = useCallback(() => {
    showSnackbar({
      message: t('masterData.addMasterDataDialog.successMessage.edit.title'),
    })
  }, [showSnackbar, t])

  const handleCreateSuccess = useCallback(() => {
    showSnackbar({
      message: t('masterData.addMasterDataDialog.successMessage.create.title'),
    })
  }, [showSnackbar, t])

  const { mutateAsync: createLearningPhases } = useCreateLearningPhases(
    useStores,
    {
      onSuccess: () => handleCreateSuccess(),
      onError: (error) => handleError(error, 'create'),
    },
  )
  const { mutateAsync: updateLearningPhase } = useUpdateLearningPhase(
    useStores,
    id ?? '',
    {
      onSuccess: () => handleUpdateSuccess(),
      onError: (error) => handleError(error, 'edit'),
    },
  )
  const { mutateAsync: createEducationLevels } = useCreateEducationLevels(
    useStores,
    {
      onSuccess: () => handleCreateSuccess(),
      onError: (error) => handleError(error, 'create'),
    },
  )
  const { mutateAsync: updateEducationLevel } = useUpdateEducationLevel(
    useStores,
    id ?? '',
    {
      onSuccess: () => handleUpdateSuccess(),
      onError: (error) => handleError(error, 'edit'),
    },
  )

  const { mutateAsync: createClassLevels } = useCreateClassLevels(useStores, {
    onSuccess: () => handleCreateSuccess(),
    onError: (error) => handleError(error, 'create'),
  })
  const { mutateAsync: updateClassLevel } = useUpdateClassLevel(
    useStores,
    id ?? '',
    {
      onSuccess: () => handleUpdateSuccess(),
      onError: (error) => handleError(error, 'edit'),
    },
  )

  const { mutateAsync: createSubjects } = useCreateSubjects(useStores, {
    onSuccess: () => handleCreateSuccess(),
    onError: (error) => handleError(error, 'create'),
  })
  const { mutateAsync: updateSubject } = useUpdateSubject(useStores, id ?? '', {
    onSuccess: () => handleUpdateSuccess(),
    onError: (error) => handleError(error, 'edit'),
  })
  const { mutateAsync: createDiStrategies } = useCreateDiStrategies(useStores, {
    onSuccess: () => handleCreateSuccess(),
    onError: (error) => handleError(error, 'create'),
  })
  const { mutateAsync: updateDiStrategy } = useUpdateDiStrategy(
    useStores,
    id ?? '',
    {
      onSuccess: () => handleUpdateSuccess(),
      onError: (error) => handleError(error, 'edit'),
    },
  )

  const { mutateAsync: createDiStrategyClasses } = useCreateDiStrategyClasses(
    useStores,
    {
      onSuccess: () => handleCreateSuccess(),
      onError: (error) => handleError(error, 'create'),
    },
  )
  const { mutateAsync: updateDiStrategyClass } = useUpdateDiStrategyClass(
    useStores,
    id ?? '',
    {
      onSuccess: () => {
        handleUpdateSuccess()
        clearDiStrategyQueryCaches(queryClient)
      },
      onError: (error) => handleError(error, 'edit'),
    },
  )
  const { mutateAsync: createResourceTypes } = useCreateResourceTypes(
    useStores,
    {
      onSuccess: () => handleCreateSuccess(),
      onError: (error) => handleError(error, 'create'),
    },
  )
  const { mutateAsync: updateResourceType } = useUpdateResourceType(
    useStores,
    id ?? '',
    {
      onSuccess: () => handleUpdateSuccess(),
      onError: (error) => handleError(error, 'edit'),
    },
  )

  const { mutateAsync: createTags } = useCreateTags(useStores, {
    onSuccess: () => handleCreateSuccess(),
    onError: (error) => handleError(error, 'create'),
  })
  const { mutateAsync: updateTag } = useUpdateTag(useStores, id ?? '', {
    onSuccess: () => handleUpdateSuccess(),
    onError: (error) => handleError(error, 'edit'),
  })

  const { mutateAsync: createTagTypes } = useCreateTagTypes(useStores, {
    onSuccess: () => handleCreateSuccess(),
    onError: (error) => handleError(error, 'create'),
  })
  const { mutateAsync: updateTagType } = useUpdateTagType(useStores, id ?? '', {
    onSuccess: () => handleUpdateSuccess(),
    onError: (error) => handleError(error, 'edit'),
  })

  const { mutateAsync: createTagGroups } = useCreateTagGroups(useStores, {
    onSuccess: () => handleCreateSuccess(),
    onError: (error) => handleError(error, 'create'),
  })
  const { mutateAsync: updateTagGroup } = useUpdateTagGroup(
    useStores,
    id ?? '',
    {
      onSuccess: () => handleUpdateSuccess(),
      onError: (error) => handleError(error, 'edit'),
    },
  )

  const { mutateAsync: createSystemParameters } = useCreateSystemParameters(
    useStores,
    {
      onSuccess: () => handleCreateSuccess(),
      onError: (error) => handleError(error, 'create'),
    },
  )
  const { mutateAsync: updateSystemParameter } = useUpdateSystemParameter(
    useStores,
    {
      onSuccess: () => handleUpdateSuccess(),
      onError: (error) => handleError(error, 'edit'),
    },
  )

  const { mutateAsync: switchMasterDataStatus } = useSwitchMasterDataStatus({
    onSuccess: (res) => handleSwitchStatusSuccess(res.isDisabled),
    onError: (error) => handleError(error, 'edit'),
  })

  const handleClearQueryCaches = useCallback(
    async (tableName: MasterDataTable) => {
      if (tableName === 'classLevels') {
        await clearClassLevelQueryCaches(queryClient)
      }
      if (tableName === 'educationLevels') {
        await clearEducationLevelQueryCaches(queryClient)
      }
      if (tableName === 'learningPhases') {
        await clearLearningPhaseQueryCaches(queryClient)
      }
      if (tableName === 'subjects') {
        await clearSubjectQueryCaches(queryClient)
      }
      if (tableName === 'resourceTypes') {
        await clearResourceTypeQueryCaches(queryClient)
      }
      if (tableName === 'diStrategies') {
        await clearDiStrategyQueryCaches(queryClient)
      }
      if (tableName === 'diStrategyClasses') {
        await clearDiStrategyClassQueryCaches(queryClient)
      }
      if (tableName === 'tags') {
        await clearTagQueryCaches(queryClient)
      }
      if (tableName === 'tagTypes') {
        await clearTagTypeQueryCaches(queryClient)
      }
      if (tableName === 'tagGroups') {
        await clearTagTagGroupQueryCaches(queryClient)
      }
    },
    [queryClient],
  )

  const handleSwitchMasterDataStatus = useCallback(
    async (payload: any, tableName: MasterDataTable) => {
      try {
        showSpinner()
        await switchMasterDataStatus({
          type: tableName,
          id: payload?.id,
          rowVersion: payload?.rowVersion,
        })
        await handleClearQueryCaches(tableName)
      } finally {
        hideSpinner()
      }
    },
    [handleClearQueryCaches, hideSpinner, showSpinner, switchMasterDataStatus],
  )

  const { mutateAsync: switchMasterDataOrder } = useSwitchMasterDataOrder({
    onError: (error) => handleError(error, 'edit'),
  })

  const handleSwitchMasterDataOrder = useCallback(
    async (
      payload: PostSwitchMasterDataOrderPayload,
      tableName: MasterDataTable,
    ) => {
      try {
        showSpinner()
        await switchMasterDataOrder({
          type: tableName,
          payload: payload,
        })
        await handleClearQueryCaches(tableName)
      } finally {
        hideSpinner()
      }
    },
    [handleClearQueryCaches, hideSpinner, showSpinner, switchMasterDataOrder],
  )

  const handleDisableConfirmationDialogOnSelect = useCallback(
    async (value: boolean) => {
      if (value && targetMasterData != null && targetMasterDataName != null) {
        handleSwitchMasterDataStatus(targetMasterData, targetMasterDataName)
      }
      setIsDisableConfirmationDialogOpen(false)
    },
    [handleSwitchMasterDataStatus, targetMasterData, targetMasterDataName],
  )

  const renderDisableConfirmationDialog = useMemo(() => {
    const actions: DialogAction<boolean>[] = [
      {
        text: t('common.cancel'),
        type: 'outlined',
        value: false,
      },
      {
        text: t('masterData.confirmationDialog.action.delete.title'),
        type: 'contained',
        color: 'sharpRed',
        value: true,
      },
    ]

    return (
      <Dialog
        open={isDisableConfirmationDialogOpen}
        title={t('masterData.confirmationDialog.title')}
        content={t('masterData.confirmationDialog.content')}
        actions={actions}
        onSelected={handleDisableConfirmationDialogOnSelect}
        maxWidth="md"
        fullWidth
      />
    )
  }, [
    handleDisableConfirmationDialogOnSelect,
    isDisableConfirmationDialogOpen,
    t,
  ])

  return {
    createLearningPhases,
    updateLearningPhase,
    createEducationLevels,
    updateEducationLevel,
    createClassLevels,
    updateClassLevel,
    createSubjects,
    updateSubject,
    createDiStrategies,
    updateDiStrategy,
    createDiStrategyClasses,
    updateDiStrategyClass,
    createResourceTypes,
    updateResourceType,
    createTags,
    updateTag,
    createTagTypes,
    updateTagType,
    createTagGroups,
    updateTagGroup,
    createSystemParameters,
    updateSystemParameter,
    getTargetMasterDataName,
    handleOpenDisableConfirmationDialog,
    handleSwitchMasterDataStatus,
    handleSwitchMasterDataOrder,
    renderDisableConfirmationDialog,
    isDisableConfirmationDialogOpen,
  }
}
