import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  LearningUnitsQueryParameters,
  LearningUnitsResponsePayload,
} from './learning-units-api.type'

const PATH = '/learning-units'

export function getLearningUnits(param?: LearningUnitsQueryParameters) {
  return ApiCore.primary.get<LearningUnitsResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}
