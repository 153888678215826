import { memo } from 'react'
import styled from 'styled-components'
import { Svg } from '../../assets'

const SearchIconContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacings.general[1]}px;
  padding-left: ${({ theme }) => theme.spacings.general[2]}px;
`

export const SearchLeftIcon = memo(() => (
  <SearchIconContainer>
    <Svg.Search />
  </SearchIconContainer>
))
