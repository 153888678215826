import { Box, Button, Dialog, Grid, Typography } from '@mui/material'
import { memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { RadioGroup, Tag } from '../../components'
import { useTranslation } from 'react-i18next'
import { Svg } from '../../assets'

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TagName = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  display: inline-block;
  margin-left: 4px;
`

type Props = {
  open: boolean
  onClose(): void
  onSelect(type: boolean): void
}

export const SelectGroupTagTypeDialog = memo(
  ({ open, onSelect, onClose }: Props) => {
    const [type, setSelectedType] = useState<string | undefined>()

    const { t } = useTranslation()

    const options = useMemo(
      () => [
        {
          value: '1',
          label: (
            <Grid container gap={4} alignItems="center" my={2}>
              <Grid item>
                {t('masterData.selectTagGroupTypeDialog.options.withName')}
              </Grid>
              <Grid item>
                <Tag
                  colorCustomize="light-green"
                  label={
                    <Grid
                      container
                      gap={2}
                      display="inline-flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Svg.Close width={16} height={16} />
                      {t(
                        'masterData.selectTagGroupTypeDialog.tagExampleLabel.withName.text',
                      )}
                      <TagName>
                        {t(
                          'masterData.selectTagGroupTypeDialog.tagExampleLabel.withName.highlighted',
                        )}
                      </TagName>
                      <Svg.ChevronDown />
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          ),
        },
        {
          value: '2',
          label: (
            <Grid container gap={4} alignItems="center" my={2}>
              <Grid item>
                {t('masterData.selectTagGroupTypeDialog.options.noName')}
              </Grid>
              <Grid item>
                <Tag
                  colorCustomize="light-gray"
                  label={
                    <Grid
                      container
                      gap={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Svg.Close width={16} height={16} />
                      {t(
                        'masterData.selectTagGroupTypeDialog.tagExampleLabel.noName.text',
                      )}
                      <TagName>
                        {t(
                          'masterData.selectTagGroupTypeDialog.tagExampleLabel.noName.highlighted',
                        )}
                      </TagName>
                      <Svg.ChevronDown />
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
          ),
        },
      ],
      [t],
    )

    const handleSelect = useCallback(() => {
      if (type) onSelect(type === '1')
    }, [onSelect, type])

    return (
      <Dialog maxWidth={false} open={open}>
        <StyledBox width={960} p={10} height={600}>
          <Box>
            <Typography fontSize={22} fontWeight={600}>
              {t('masterData.selectTagGroupTypeDialog.title')}
            </Typography>

            <Box>
              <Typography>
                {t('masterData.selectTagGroupTypeDialog.description')}
              </Typography>

              <RadioGroup
                options={options ?? []}
                // row
                onChange={(e, value) => {
                  setSelectedType(value)
                }}
                value={type}
              />
            </Box>
          </Box>

          <Grid mt={10} container justifyContent="flex-end" columnSpacing={5}>
            <Grid item width={200}>
              <Button variant="outlined" onClick={onClose} fullWidth>
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item width={200}>
              <Button fullWidth onClick={handleSelect} disabled={type == null}>
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </StyledBox>
      </Dialog>
    )
  },
)
