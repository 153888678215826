import { RoleType } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useStores } from '../../stores'
import { LessonPlanConsultRequestFollowingUp } from './lesson-plan-consult-requests-following-up'
import { LessonPlanConsultRequestsManagement } from './lesson-plan-consult-requests-management'

export const LessonPlanConsultRequests = observer(() => {
  const { userProfileStore } = useStores()
  const currentUserRole = useMemo(
    () => userProfileStore.userProfile?.role?.label,
    [userProfileStore.userProfile],
  )

  if (currentUserRole === RoleType.diTeam)
    return <LessonPlanConsultRequestsManagement />

  return <LessonPlanConsultRequestFollowingUp />
})
