import { CardMedia as MuiCardMedia, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactNode, useMemo, useState } from 'react'
import styled from 'styled-components'

type IFrame = {
  elementType: 'iframe'
  src?: never
  image: string
  autoPlay?: boolean
}

type Video = {
  elementType: 'video'
  src: string
  image?: never
  autoPlay?: boolean
}

type Image = {
  elementType: 'img'
  src?: never
  image: string
  autoPlay?: never
}

type Props = {
  children?: ReactNode
  controls?: boolean
  alt?: string
  height?: number
  title?: string
} & (IFrame | Video | Image)

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CardMediaContainer = styled.div<{
  $isLoaded: boolean
}>`
  display: ${({ $isLoaded }) => ($isLoaded ? 'block' : 'none')};
  height: 100%;
  width: 100%;
`

export const CardMedia = observer<Props>(
  ({ elementType, src, image, controls, children, alt, title, autoPlay }) => {
    const componentType = useMemo(
      () =>
        // eslint-disable-next-line no-nested-ternary
        elementType === 'iframe' && image.includes('watch?v=')
          ? 'iframe'
          : elementType === 'video'
          ? 'video'
          : 'img',
      [elementType, image],
    )
    const mediaProps = useMemo(
      () =>
        // eslint-disable-next-line no-nested-ternary
        elementType === 'iframe' && image.includes('watch?v=')
          ? {
              image: image.replace('watch?v=', 'embed/'),
              autoPlay,
            }
          : elementType === 'video'
          ? {
              src,
              autoPlay,
            }
          : {
              image,
            },
      [autoPlay, elementType, image, src],
    )
    const [isLoaded, setIsLoaded] = useState(false)
    return (
      <Container>
        {!isLoaded && <CircularProgress />}
        <CardMediaContainer $isLoaded={isLoaded}>
          <MuiCardMedia
            style={{ objectFit: 'contain', width: '60%' }}
            component={componentType}
            controls={controls}
            alt={alt}
            title={title}
            onLoad={() => setIsLoaded(true)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...mediaProps}
          >
            {children}
          </MuiCardMedia>
        </CardMediaContainer>
      </Container>
    )
  },
)
