import { Box, Grid } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { Text } from './Text'

const TickIconWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  background: ${({ theme }) => theme.palettes.general.white};
  border: 1px solid #828282;
  border-radius: 30px;
`

type FullScreenTextProps = {
  title: string
}

export const TextWithTickIcon = memo<FullScreenTextProps>(({ title }) => {
  return (
    <Grid container alignItems="center" ml={4}>
      <Grid item flexShrink={0} mr={2}>
        <TickIconWrapper>
          <Svg.Tick />
        </TickIconWrapper>
      </Grid>
      <Text>{title}</Text>
    </Grid>
  )
})
