import { Box, Button } from '@mui/material'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import {
  Checkbox,
  Dropdown,
  HorizontalTabs,
  MasterDataManagementDialog,
  RadioGroup,
  Tag,
  TextField,
  VerticalTabs,
} from '../../components'

const Page = styled.div`
  padding: 80px;
`

const countries = [
  {
    label: 'Vietnam',
    value: 'VN',
  },
  {
    label: 'China',
    value: 'CN',
  },
]

export const UiComponent = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [isAddMasterDataDialogOpen, setIsAddMasterDataDialogOpen] =
    useState(false)
  const [isEditMasterDataDialogOpen, setIsEditMasterDataDialogOpen] =
    useState(false)
  const handleOnActiveChange = useCallback((value: number) => {
    setActiveTab(value)
  }, [])
  return (
    <Page>
      <Box mb={5} width={400}>
        <div>Input</div>
        <TextField placeholder="Username" required label="Username" />
      </Box>

      <Box mb={5} width={400}>
        <div>Textarea</div>
        <TextField
          placeholder="Description"
          required
          label="Description"
          multiline
          rows={3}
        />
      </Box>

      <Box mb={5} width={400}>
        <div>Dropdown</div>
        <Dropdown
          placeholder="Select Country"
          label="Country"
          options={countries}
          required
        />
      </Box>

      <Box mb={5} width={400}>
        <div>Radio Group</div>
        <RadioGroup row label="Country" options={countries} />
      </Box>

      <Box mb={5} width={400}>
        <div>Checkbox</div>
        <Checkbox label="B1. 多元的學習目標" />
      </Box>

      <Box mb={5}>
        <Tag label="PRIMARY" color="primary" />
        <Tag label="DEFAULT" color="default" />
        <Tag label="ERROR" color="error" />
        <Tag label="INFO" color="info" />
        <Tag label="SECONDARY" color="secondary" />
        <Tag label="SUCCESS" color="success" />
        <Tag label="WARNING" color="warning" />
        <Tag label="GRAY 6" colorCustomize="gray-6" />
        <Tag label="light-blue" colorCustomize="light-blue" />
        <Tag label="light-gray" colorCustomize="light-gray" />
        <Tag label="light-green" colorCustomize="light-green" />
      </Box>

      <Box mb={5} bgcolor={'#FFFFFF'}>
        <HorizontalTabs
          onActiveTabChange={handleOnActiveChange}
          activeTab={activeTab}
          tabMenus={[
            {
              id: 0,
              label: 'tab 1',
            },
            { id: 1, label: 'tab 2' },
          ]}
        />
      </Box>

      <Box
        mb={5}
        bgcolor={'#FFFFFF'}
        sx={{
          height: '300px',
        }}
      >
        <VerticalTabs
          activeTab={0}
          tabMenus={[]}
          onActiveTabChange={handleOnActiveChange}
        />
      </Box>

      <Button onClick={() => setIsAddMasterDataDialogOpen(true)}>
        Open add master data management dialog
      </Button>
      <Button onClick={() => setIsEditMasterDataDialogOpen(true)}>
        Open edti master data management dialog
      </Button>

      {/* <MasterDataManagementDialog
        mode="create"
        masterDataTable="diStrategies"
        isOpen={isAddMasterDataDialogOpen}
        setIsOpen={setIsAddMasterDataDialogOpen}
      />
      <MasterDataManagementDialog
        mode="edit"
        selectedId="57982B43-EAD8-4A94-B2A6-07ED0AFA4F12"
        masterDataTable="diStrategies"
        isOpen={isEditMasterDataDialogOpen}
        setIsOpen={setIsEditMasterDataDialogOpen}
      /> */}
      {/* <MasterDataManagementDialog
        mode="create"
        masterDataTable="diStrategyClasses"
        isOpen={isAddMasterDataDialogOpen}
        setIsOpen={setIsAddMasterDataDialogOpen}
      />
      <MasterDataManagementDialog
        mode="edit"
        selectedId="11234e75-0341-4a8e-9d6e-31a9c1f17780"
        masterDataTable="diStrategyClasses"
        isOpen={isEditMasterDataDialogOpen}
        setIsOpen={setIsEditMasterDataDialogOpen}
      /> */}
      <MasterDataManagementDialog
        mode="create"
        masterDataTable="learningPhases"
        isOpen={isAddMasterDataDialogOpen}
        setIsOpen={setIsAddMasterDataDialogOpen}
      />
      <MasterDataManagementDialog
        mode="edit"
        selectedId="5E529B95-76B5-48F8-AF38-0C8AF0B976B2"
        masterDataTable="learningPhases"
        isOpen={isEditMasterDataDialogOpen}
        setIsOpen={setIsEditMasterDataDialogOpen}
      />
    </Page>
  )
}
