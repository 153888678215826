import { ApiCore } from '@polyu-dip/api-core'
import { convertExpandParameters, convertSortParameters } from '../base'
import {
  LessonPlanExpandableParameter,
  LessonPlanResponsePayload,
  LessonPlansOwnedQueryParameters,
  LessonPlansOwnedResponsePayload,
  LessonPlansQueryParameters,
  LessonPlansResponsePayload,
  LessonPlansUpdatedExamplesAndResourcesParameters,
  LessonPlansSharedQueryParameters,
  LessonPlansSharedResponsePayload,
  PostLessonPlanPayload,
  PostLessonPlanSharingPayload,
  PutLessonPlanPayload,
  PostLessonPlanCommentPayload,
  LessonPlanCommentsQueryParameters,
  LessonPlanCommentsResponsePayload,
  GetLessonPlanAttachmentDownloadUrlPayload,
  LessonPlanExamplesQueryParameters,
  LessonPlanExamplesResponsePayload,
  PostLessonPlanConsultPayload,
  PostLessonPlanTransferOwnerPayload,
} from './api-lesson-plans.types'

const PATH = '/lesson-plans'

export function getLessonPlans(param?: LessonPlansQueryParameters) {
  return ApiCore.primary.get<LessonPlansResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: convertExpandParameters(param),
    },
  })
}

export function getLessonPlanById(
  id: string,
  param?: LessonPlanExpandableParameter,
) {
  return ApiCore.primary.get<LessonPlanResponsePayload>(`${PATH}/${id}`, {
    param: {
      ...param,
      expand: convertExpandParameters(param),
    },
  })
}

export function getLessonPlanDetailById(
  id: string,
  param?: LessonPlanExpandableParameter,
) {
  return ApiCore.primary.get<LessonPlanResponsePayload>(
    `${PATH}/${id}/details`,
    {
      param: {
        ...param,
      },
    },
  )
}

export function postLessonPlan(payload: PostLessonPlanPayload) {
  return ApiCore.primary.post<LessonPlanResponsePayload>(PATH, {
    body: payload,
  })
}

export function putLessonPlan(id: string, payload: PutLessonPlanPayload) {
  return ApiCore.primary.put<LessonPlanResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}

export function getLessonPlansOwned(param?: LessonPlansOwnedQueryParameters) {
  return ApiCore.primary.get<LessonPlansOwnedResponsePayload>(PATH + '/owned', {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: convertExpandParameters(param),
    },
  })
}

export function getLessonPlansShared(param?: LessonPlansSharedQueryParameters) {
  return ApiCore.primary.get<LessonPlansSharedResponsePayload>(
    `${PATH}/shared`,
    {
      param: {
        ...param,
        sort: convertSortParameters(param),
        expand: convertExpandParameters(param),
      },
    },
  )
}

export function getLessonPlansOwnedPublished(
  param?: LessonPlansOwnedQueryParameters,
) {
  return ApiCore.primary.get<LessonPlansResponsePayload>(
    PATH + '/owned-publish-copy',
    {
      param: {
        ...param,
        sort: convertSortParameters(param),
        expand: convertExpandParameters(param),
      },
    },
  )
}

export function getLessonPlanExamples(
  param?: LessonPlanExamplesQueryParameters,
) {
  return ApiCore.primary.get<LessonPlanExamplesResponsePayload>(
    PATH + '/examples',
    {
      param: {
        ...param,
        sort: convertSortParameters(param),
        expand: convertExpandParameters(param),
      },
    },
  )
}

export function getLessonPlanSummary(
  param?: LessonPlanExamplesQueryParameters,
) {
  return ApiCore.primary.get<LessonPlanExamplesResponsePayload>(
    PATH + '/summary',
    {
      param: {
        ...param,
        sort: convertSortParameters(param),
        expand: convertExpandParameters(param),
      },
    },
  )
}

export function getLessonPlansUpdatedExamplesAndResources(
  param?: LessonPlansUpdatedExamplesAndResourcesParameters,
) {
  return ApiCore.primary.get<LessonPlansResponsePayload>(
    `${PATH}/updated-examples-and-resources`,
    {
      param: {
        ...param,
        sort: convertSortParameters(param),
        expand: convertExpandParameters(param),
      },
    },
  )
}

export function postLessonPlanSharingById(
  id: string,
  payload: PostLessonPlanSharingPayload,
) {
  return ApiCore.primary.post<LessonPlanResponsePayload>(
    `${PATH}/${id}/sharing`,
    {
      body: payload,
    },
  )
}

export function bulkPostLessonPlanSharingsById(
  id: string,
  payloads: PostLessonPlanSharingPayload[],
) {
  return ApiCore.primary.post<LessonPlanResponsePayload[]>(
    `${PATH}/${id}/sharing/bulk`,
    {
      body: payloads,
    },
  )
}

export function getLessonPlanComments(
  id: string,
  param?: LessonPlanCommentsQueryParameters,
) {
  return ApiCore.primary.get<LessonPlanCommentsResponsePayload>(
    `${PATH}/${id}/comments`,
    {
      param: {
        ...param,
        sort: convertSortParameters(param),
        expand: convertExpandParameters(param),
      },
    },
  )
}

export function postLessonPlanComment(
  id: string,
  payload: PostLessonPlanCommentPayload,
) {
  return ApiCore.primary.post<LessonPlanResponsePayload>(
    `${PATH}/${id}/comments`,
    {
      body: payload,
    },
  )
}

export function getLessonPlanAttachmentsDownloadUrl(
  id: string,
  timeout: string,
) {
  ApiCore.setConfig()
  return ApiCore.primary.get<GetLessonPlanAttachmentDownloadUrlPayload>(
    `${PATH}/${id}/attachments`,
    {
      config: {
        timeout: +timeout,
      },
    },
  )
}

export function postSubmitPublishRequest(
  id: string,
  rowVersion: string,
  isAgreePublish: boolean,
) {
  return ApiCore.primary.post<LessonPlanResponsePayload>(
    `${PATH}/${id}/submit-publish-request`,
    {
      body: {
        rowVersion: rowVersion,
        isAgreePublish: isAgreePublish,
      },
    },
  )
}

export function postPublishLessonPlanExample(id: string, rowVersion: string) {
  return ApiCore.primary.post<LessonPlanResponsePayload>(
    `${PATH}/${id}/publish`,
    {
      body: {
        rowVersion: rowVersion,
      },
    },
  )
}

export function postUnPublishLessonPlanExample(id: string, rowVersion: string) {
  return ApiCore.primary.post<LessonPlanResponsePayload>(
    `${PATH}/${id}/unpublish`,
    {
      body: {
        rowVersion: rowVersion,
      },
    },
  )
}

export function postCopyLessonPlanExample(id: string) {
  return ApiCore.primary.post(`${PATH}/${id}/copy`)
}

export function postLessonPlanConsultById(
  id: string,
  payload: PostLessonPlanConsultPayload,
) {
  return ApiCore.primary.post<LessonPlanResponsePayload>(
    `${PATH}/${id}/consult`,
    {
      body: payload,
    },
  )
}

export function postLessonPlanTransferOwner(
  id: string,
  payload: PostLessonPlanTransferOwnerPayload,
) {
  return ApiCore.primary.post<LessonPlanResponsePayload>(
    `${PATH}/${id}/transfer-owner`,
    {
      body: payload,
    },
  )
}

export function postLessonPlanViewCount(id: string) {
  return ApiCore.primary.post<number>(`${PATH}/${id}/views`, {})
}

export function postLessonPlanExportCount(id: string) {
  return ApiCore.primary.post<number>(`${PATH}/${id}/exports`, {})
}
