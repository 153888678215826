import { Typography } from '@mui/material'
import { colorPalette } from '@polyu-dip/theme'
import { memo } from 'react'
import styled from 'styled-components'

export const PageTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`

const SectionTitleText = styled.h1`
  word-break: break-word;
  color: inherit;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
`

const SectionTitleSubTitle = styled.span`
  word-break: break-word;
  color: inherit;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`

const SectionTitleContainer = styled.div<{
  color: keyof typeof colorPalette.semantic
  colorCustomize?: string
  mb?: number
}>`
  border-left: 8px solid
    ${({ theme, color, colorCustomize }) =>
      colorCustomize ?? theme.palettes.semantic[color]};
  padding-left: 8px;
  color: ${({ theme, color, colorCustomize }) =>
    colorCustomize ?? theme.palettes.semantic[color]};
  margin-bottom: ${({ mb = 6 }) => mb * 4}px;
`

export type SectionTitleProps = {
  colorCustomize?: string
  color?: keyof typeof colorPalette.semantic
  title: string
  subTitle?: string
  className?: string
  mb?: number
}

export const SectionTitle = memo(
  ({
    color = 'astronautBlue',
    subTitle,
    title,
    ...props
  }: SectionTitleProps) => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <SectionTitleContainer color={color} {...props}>
        {subTitle && <SectionTitleSubTitle>{subTitle}</SectionTitleSubTitle>}
        <SectionTitleText>{title}</SectionTitleText>
      </SectionTitleContainer>
    )
  },
)

type FullScreenTextProps = {
  title: string
}

const Root = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  color: grey;
  align-items: center;
  justify-content: center;
`

export const Text = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palettes.semantic.astronautBlue};
  white-space: pre-wrap;
  word-break: break-word;
`

export const FullScreenText = memo<FullScreenTextProps>(({ title }) => {
  return (
    <Root>
      <Typography variant="h4">{title}</Typography>
    </Root>
  )
})

export const TruncatedTitle = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
