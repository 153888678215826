type ObjectWithOrder<T> = T & {
  order: number
}
type ObjectWithNullableOrder<T> = T & {
  order?: number
}
export function sortByOrder<T>(a: ObjectWithOrder<T>, b: ObjectWithOrder<T>) {
  return a.order - b.order
}

export function sortByNullableOrder<T>(
  a: ObjectWithNullableOrder<T>,
  b: ObjectWithNullableOrder<T>,
) {
  return (a.order ?? 0) - (b.order ?? 0)
}
