import { Chip, Typography } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'
import { useLessonPlanExamples, useLessonPlanSummary } from '@polyu-dip/queries'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { useComputed } from '@polyu-dip/helpers'
import {
  LessonPlanFilter,
  ListPage,
  SelectOption,
  Tab,
  Table,
  Tabs,
  useRowCountState,
} from '../../components'
import { contentPaths } from '../../content-paths'
import { useStores } from '../../stores'
import { observer } from 'mobx-react-lite'
import {
  ClassLevelResponsePayload,
  GetPayload,
  PaginationParameters,
} from '@polyu-dip/apis'
import { useLessonPlanExamplesColumn } from './use-lesson-plan-examples-column'
import { useLessonPlanFilter, ViewModeType } from '../../hooks'
import { LessonPlanExamplesGridList } from './lesson-plan-examples-grid-list'
import { LearningPhase } from '@polyu-dip/models'

const StyledChip = styled(Chip)`
  font-size: 14px;
  line-height: 120%;
  height: auto;
  margin-left: 12px;

  .MuiChip-label {
    padding: 0px 8px;
  }
`

const FlexRow = styled.div`
  display: flex;
  direction: row;
  white-space: nowrap;
`

const PAGINATION_LIMIT = 15
const GRID_MODE_PAGINATION_LIMIT = 16

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

enum TabMenuEnum {
  FIRST_TAB = 'FIRST_TAB',
  SECONDARY_TAB = 'SECONDARY_TAB',
}

type LessonPlanExamplesProps = {
  isManagementPage?: boolean
}

export const LessonPlanExamples = observer<LessonPlanExamplesProps>(
  ({ isManagementPage = false }) => {
    const { t, i18n } = useTranslation()
    const theme = useTheme()
    const navigate = useNavigate()
    const { masterDataStore } = useStores()
    const { columns } = useLessonPlanExamplesColumn()
    const {
      isAllMasterDataFetchSuccess,
      educationLevelOptions,
      setSelectedEducationLevel,
      setSelectedLearningPhase,
      viewMode,
      ...filterProps
    } = useLessonPlanFilter()

    const filterItems = useMemo(() => {
      return {
        educationLevelId: filterProps.selectedEducationLevel?.value as string,
        learningPhaseId: filterProps.selectedLearningPhase?.value as string,
        subjectId: filterProps.selectedSubject?.value as string,
        diStrategyClassId: filterProps.selectedDiStrategyClass?.value as string,
        diStrategyId: filterProps.selectedDiStrategy?.value as string,
        TeachingTopic: filterProps.inputTeachingTopic,
      }
    }, [filterProps])

    const sortItem: {
      parameter: string
      direction: 'desc' | 'asc'
    } = useMemo(() => {
      return {
        parameter: filterProps.selectedSortItem,
        direction: 'desc',
      }
    }, [filterProps.selectedSortItem])

    const [activeTab, setActiveTab] = useState<TabMenuEnum>(
      TabMenuEnum.FIRST_TAB,
    )
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
    const [firstTabSort, setFirstTabSort] = useState<GridSortModel>()
    const [secondaryTabSort, setSecondaryTabSort] = useState<GridSortModel>()
    const [gridPage, setGridPage] = useState(1)

    const handleGridModePageOnChange = useCallback(
      (value: number) => {
        setPagination({
          ...pagination,
          offset: (value - 1) * GRID_MODE_PAGINATION_LIMIT,
        })
        setGridPage(value)
      },
      [pagination],
    )

    const {
      data: primaryLessonPlanExampleData,
      isLoading: isPrimaryLessonPlanExamplesLoading,
    } = useLessonPlanExamples(
      useStores,
      {
        sort: [
          ...(firstTabSort?.map((it) => ({
            direction: it.sort ?? 'desc',
            parameter: it.field,
          })) ?? []),
          sortItem,
        ],
        ...pagination,
        ...filterItems,
        educationLevelId: educationLevelOptions?.[0]?.value,
      },
      {
        enabled: !isManagementPage && educationLevelOptions?.[0]?.value != null,
      },
    )

    const {
      data: secondaryLessonPlanExampleData,
      isLoading: isSecondaryLessonPlanExamplesLoading,
    } = useLessonPlanExamples(
      useStores,
      {
        sort: [
          ...(secondaryTabSort?.map((it) => ({
            direction: it.sort ?? 'desc',
            parameter: it.field,
          })) ?? []),
          sortItem,
        ],
        ...pagination,
        ...filterItems,
        educationLevelId: educationLevelOptions?.[1]?.value,
      },
      {
        enabled: !isManagementPage && educationLevelOptions?.[1]?.value != null,
      },
    )

    const {
      data: publishedLessonPlanExamplesData,
      isLoading: ispublishedLessonPlanExamplesData,
    } = useLessonPlanSummary(
      useStores,
      {
        'latestSourcePublishRequest.status': 'completed',
        'latestSourcePublishRequest.isPublishing': [true],
        sort: [
          ...(firstTabSort?.map((it) => ({
            direction: it.sort ?? 'desc',
            parameter: it.field,
          })) ?? []),
          sortItem,
        ],
        ...pagination,
        ...filterItems,
      },
      {
        enabled: isManagementPage,
      },
    )

    const {
      data: allLessonPlanExamplesData,
      isLoading: isallLessonPlanExamplesData,
    } = useLessonPlanSummary(
      useStores,
      {
        'latestSourcePublishRequest.status': 'completed',
        sort: [
          ...(secondaryTabSort?.map((it) => ({
            direction: it.sort ?? 'desc',
            parameter: it.field,
          })) ?? []),
          sortItem,
        ],
        ...pagination,
        ...filterItems,
      },
      {
        enabled: isManagementPage,
      },
    )

    const totalRecord = useMemo(() => {
      if (isManagementPage) {
        return activeTab === TabMenuEnum.FIRST_TAB
          ? publishedLessonPlanExamplesData?.total
          : allLessonPlanExamplesData?.total
      }
      return activeTab === TabMenuEnum.FIRST_TAB
        ? primaryLessonPlanExampleData?.total
        : secondaryLessonPlanExampleData?.total
    }, [
      isManagementPage,
      activeTab,
      primaryLessonPlanExampleData?.total,
      secondaryLessonPlanExampleData?.total,
      publishedLessonPlanExamplesData?.total,
      allLessonPlanExamplesData?.total,
    ])

    const { rowCountState } = useRowCountState(totalRecord)

    const lessonPlanData = useComputed(() => {
      if (isManagementPage) {
        return (
          (activeTab === TabMenuEnum.FIRST_TAB
            ? publishedLessonPlanExamplesData?.data
            : allLessonPlanExamplesData?.data) ?? []
        )
      }
      return (
        (activeTab === TabMenuEnum.FIRST_TAB
          ? primaryLessonPlanExampleData?.data
          : secondaryLessonPlanExampleData?.data) ?? []
      )
    }, [
      isManagementPage,
      activeTab,
      primaryLessonPlanExampleData?.data,
      secondaryLessonPlanExampleData?.data,
      publishedLessonPlanExamplesData?.data,
      allLessonPlanExamplesData?.data,
    ])

    const computedLessonPlanData = useComputed(() => {
      return lessonPlanData.map((it) => ({
        ...it,
        classLevel: {
          ...it.classLevel,
          learningPhase: masterDataStore.learningPhases.find(
            (learningPhase) =>
              learningPhase.id == it.classLevel?.learningPhaseId,
          ) as GetPayload<LearningPhase>,
        } as ClassLevelResponsePayload,
      }))
    }, [lessonPlanData, masterDataStore.learningPhases])

    const totalPage = useComputed(() => {
      return Math.ceil((totalRecord ?? 0) / GRID_MODE_PAGINATION_LIMIT)
    }, [totalRecord])

    const isLoading = useComputed(() => {
      if (!isAllMasterDataFetchSuccess) {
        return true
      }
      if (isManagementPage) {
        return activeTab === TabMenuEnum.FIRST_TAB
          ? ispublishedLessonPlanExamplesData
          : isallLessonPlanExamplesData
      }
      return activeTab === TabMenuEnum.FIRST_TAB
        ? isPrimaryLessonPlanExamplesLoading
        : isSecondaryLessonPlanExamplesLoading
    }, [
      isAllMasterDataFetchSuccess,
      isManagementPage,
      activeTab,
      isPrimaryLessonPlanExamplesLoading,
      isSecondaryLessonPlanExamplesLoading,
      ispublishedLessonPlanExamplesData,
      isallLessonPlanExamplesData,
    ])

    const handleTabChange = useCallback(
      (event: any, value: any) => {
        if (!isManagementPage) {
          setSelectedLearningPhase(undefined)
          filterProps.setSelectedSubject(undefined)
        }
        setActiveTab(value)
        setPagination({
          limit:
            viewMode === 'LIST_MODE'
              ? PAGINATION_LIMIT
              : GRID_MODE_PAGINATION_LIMIT,
          offset: 0,
        })
        setGridPage(1)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isManagementPage, setSelectedLearningPhase, viewMode],
    )

    const renderTabs = useMemo(() => {
      const firstTabTitle = isManagementPage
        ? t('lessonPlanExample.tags.lessonPlanExample')
        : educationLevelOptions?.[0]?.label ??
          t('lessonPlanExample.tags.primarySchool')

      const firstTabTotal =
        (isManagementPage
          ? publishedLessonPlanExamplesData?.total
          : primaryLessonPlanExampleData?.total) ?? 0

      const secondTabTitle = isManagementPage
        ? t('lessonPlanExample.tags.lessonPlanSummary')
        : educationLevelOptions?.[1]?.label ??
          t('lessonPlanExample.tags.secondarySchool')

      const secondTabTotal =
        (isManagementPage
          ? allLessonPlanExamplesData?.total
          : secondaryLessonPlanExampleData?.total) ?? 0
      return (
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          $activeColor={theme.palettes.general.sharpRed}
          $activeIndicatorColor={theme.palettes.general.sharpRed}
        >
          <Tab
            value={TabMenuEnum.FIRST_TAB}
            label={
              <FlexRow>
                <Typography>{firstTabTitle}</Typography>
                <StyledChip
                  label={firstTabTotal}
                  color={
                    activeTab === TabMenuEnum.FIRST_TAB ? 'sharpRed' : 'default'
                  }
                />
              </FlexRow>
            }
            $width={320}
          />
          <Tab
            value={TabMenuEnum.SECONDARY_TAB}
            label={
              <FlexRow>
                <Typography>{secondTabTitle}</Typography>

                <StyledChip
                  label={secondTabTotal}
                  color={
                    activeTab === TabMenuEnum.SECONDARY_TAB
                      ? 'sharpRed'
                      : 'default'
                  }
                />
              </FlexRow>
            }
            $width={320}
          />
        </Tabs>
      )
    }, [
      isManagementPage,
      t,
      educationLevelOptions,
      publishedLessonPlanExamplesData?.total,
      primaryLessonPlanExampleData?.total,
      allLessonPlanExamplesData?.total,
      secondaryLessonPlanExampleData?.total,
      activeTab,
      handleTabChange,
      theme.palettes.general.sharpRed,
    ])

    const handleOnItemClick = useCallback(
      (lessonPlanId: string) => {
        if (isManagementPage) {
          navigate(
            contentPaths.lessonPlanExampleManagement(lessonPlanId, 'view'),
          )
        } else {
          navigate(contentPaths.lessonPlanExamplesDetail(lessonPlanId))
        }
      },
      [isManagementPage, navigate],
    )

    useEffect(() => {
      if (!isManagementPage) {
        if (activeTab == TabMenuEnum.FIRST_TAB) {
          setSelectedEducationLevel(educationLevelOptions?.[0])
        } else {
          setSelectedEducationLevel(educationLevelOptions?.[1])
        }
      }
      if (
        viewMode === ViewModeType.list &&
        pagination.limit !== PAGINATION_LIMIT
      ) {
        setPagination({
          limit: PAGINATION_LIMIT,
          offset: 0,
        })
      } else if (
        viewMode === ViewModeType.Grid &&
        pagination.limit !== GRID_MODE_PAGINATION_LIMIT
      ) {
        setPagination({
          limit: GRID_MODE_PAGINATION_LIMIT,
          offset: 0,
        })
      }
    }, [
      activeTab,
      educationLevelOptions,
      isManagementPage,
      pagination,
      setSelectedEducationLevel,
      viewMode,
    ])

    const handleSelectLearningPhase: Dispatch<
      SetStateAction<SelectOption | undefined>
    > = useCallback((data) => {
      setSelectedLearningPhase(data)
      filterProps.setSelectedSubject(undefined)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectEducationLevel: Dispatch<
      SetStateAction<SelectOption | undefined>
    > = useCallback((data) => {
      setSelectedEducationLevel(data)
      filterProps.setSelectedSubject(undefined)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const subjectOptionsByEducationLevel = useMemo(() => {
      if (isManagementPage) {
        const learningPhase = filterProps.learningPhaseOptions.find(
          (it) => it.value === filterProps.selectedLearningPhase?.value,
        )

        return filterProps.subjectOptions.filter(
          (it) =>
            (learningPhase == null ||
              it.educationLevelIds.includes(learningPhase.educationLevelId)) &&
            (filterProps.selectedEducationLevel?.value == null ||
              it.educationLevelIds.includes(
                filterProps.selectedEducationLevel.value as string,
              )),
        )
      }

      const educationLevelIndex = activeTab === TabMenuEnum.FIRST_TAB ? 0 : 1

      return filterProps.subjectOptions.filter(
        (it) =>
          educationLevelOptions?.[educationLevelIndex]?.value != null &&
          it.educationLevelIds.includes(
            educationLevelOptions?.[educationLevelIndex]?.value,
          ),
      )
    }, [
      isManagementPage,
      activeTab,
      filterProps.subjectOptions,
      filterProps.learningPhaseOptions,
      filterProps.selectedLearningPhase?.value,
      filterProps.selectedEducationLevel,
      educationLevelOptions,
    ])

    return (
      <ListPage
        sectionTitleProps={{
          title: t('lessonPlanExample.title'),
          color: 'sharpRed',
        }}
      >
        {renderTabs}

        <LessonPlanFilter
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...filterProps}
          resourceTypeOptions={undefined}
          subjectOptions={subjectOptionsByEducationLevel}
          viewMode={viewMode}
          setSelectedEducationLevel={handleSelectEducationLevel}
          setSelectedLearningPhase={handleSelectLearningPhase}
          educationLevelOptions={
            isManagementPage ? educationLevelOptions : undefined
          }
        />

        {viewMode === 'LIST_MODE' ? (
          <Table
            columns={columns}
            rows={computedLessonPlanData}
            rowHeight={i18n.language === 'en' ? 64 : 50}
            padding={theme.spacings.general[4]}
            paddingTop={0}
            pageSize={PAGINATION_LIMIT}
            loading={isLoading}
            paginationMode="server"
            page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
            hideFooterPagination={
              rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
            }
            rowCount={rowCountState}
            sortModel={
              activeTab === TabMenuEnum.FIRST_TAB
                ? firstTabSort
                : secondaryTabSort
            }
            onSortModelChange={
              activeTab === TabMenuEnum.FIRST_TAB
                ? setFirstTabSort
                : setSecondaryTabSort
            }
            onRowClick={({ row }) => {
              handleOnItemClick(row.id)
            }}
            onPageChange={(page) =>
              setPagination((old) => ({
                ...old,
                offset: page * PAGINATION_LIMIT,
              }))
            }
          />
        ) : (
          <LessonPlanExamplesGridList
            computedLessonPlanData={computedLessonPlanData}
            totalPage={totalPage}
            page={gridPage}
            handleGridModePageOnChange={handleGridModePageOnChange}
            handleOnItemClick={handleOnItemClick}
          />
        )}
      </ListPage>
    )
  },
)
