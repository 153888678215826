import { Button, Grid, Pagination, Stack } from '@mui/material'
import {
  DataGrid,
  DataGridProps,
  GridColumnHeaderSortIconProps,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../assets'

export function useRowCountState(totalRecord?: number) {
  const [rowCountState, setRowCountState] = useState(totalRecord)

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalRecord !== undefined ? totalRecord : prevRowCountState,
    )
  }, [totalRecord])
  return { rowCountState }
}

const StyledDataGrid = styled(DataGrid)<TableOwnProps>(
  ({ theme, contentFontSize, padding, paddingTop, isReadOnly }) => ({
    padding: padding ?? 30,
    paddingTop: paddingTop ?? 30,
    background: theme.palettes.general.white,
    borderRadius: 0,
    border: 0,
    letterSpacing: 'normal',
    '.MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '19px',
      color: theme.palettes.semantic.astronautBlue,
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid ' + theme.palettes.semantic.astronautBlue,
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      '&:focus, &:focus-within': {
        outline: 'none',
      },
    },
    '.MuiDataGrid-row': {
      borderBottom: `1px solid ` + theme.colors.tertiary,
      cursor: isReadOnly ? 'auto' : 'pointer',
    },
    '.MuiDataGrid-row:hover': {
      backgroundColor: isReadOnly ? theme.palettes.general.white : undefined,
    },
    '.MuiDataGrid-footerContainer': {
      borderTopWidth: 0,
      flexDirection: 'row-reverse',
    },
    '.MuiDataGrid-cell': {
      fontSize: contentFontSize ?? 16,
    },
  }),
)

const StyledFooterButton = styled(Button)``

const StyledFooterButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
`

export const CustomPagination = () => {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      hideNextButton
    />
  )
}

export const CustomFooter = ({
  hideFooterPagination,
  showMoreButton,
  handleShowMore,
}: TableProps) => {
  const { t } = useTranslation()

  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)

  return (
    <Grid pt={3} container justifyContent="space-between">
      <Grid item>{!hideFooterPagination && <CustomPagination />}</Grid>

      {!hideFooterPagination && (
        <Grid item>
          <StyledFooterButton
            variant="text"
            onClick={() => apiRef.current.setPage(page + 1)}
          >
            <StyledFooterButtonText>
              {t('myLessonPlan.pagination.next')}
            </StyledFooterButtonText>
            <Svg.ChevronRight />
          </StyledFooterButton>
        </Grid>
      )}
      {showMoreButton ? (
        <Grid item>
          <StyledFooterButton variant="text" onClick={handleShowMore}>
            <StyledFooterButtonText>
              {`${t('submissionRecords.pagination.more')} >`}
            </StyledFooterButtonText>
          </StyledFooterButton>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}

const NoRowsOverlay = ({ noRowsOverlayTitle }: TableProps) => {
  const { t } = useTranslation()

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      {noRowsOverlayTitle ?? t('common.noRecord')}
    </Stack>
  )
}

const SortIcon = memo<{ ascending: boolean; unsorted?: boolean }>(
  ({ ascending, unsorted }) => {
    const theme = useTheme()

    return (
      <Svg.Chevron
        fill={theme.palettes.semantic.astronautBlue}
        opacity={unsorted ? 0.38 : 1}
        transform={ascending ? 'rotate(180)' : undefined}
      />
    )
  },
)

const ColumnSortedAscendingIcon = memo<GridColumnHeaderSortIconProps>(() => (
  <SortIcon ascending />
))

const ColumnSortedDescendingIcon = memo<GridColumnHeaderSortIconProps>(() => (
  <SortIcon ascending={false} />
))

const ColumnUnsortedIcon = memo<GridColumnHeaderSortIconProps>(() => (
  <SortIcon ascending unsorted />
))

type TableOwnProps = {
  contentFontSize?: number
  padding?: number | string
  paddingTop?: number | string
  noDataMessage?: string
  showMoreButton?: boolean
  handleShowMore?: () => void
  noRowsOverlayTitle?: string
  isReadOnly?: boolean
}

export type TableProps = DataGridProps & TableOwnProps

export const Table = observer(
  ({
    hideFooterPagination,
    showMoreButton,
    handleShowMore,
    noRowsOverlayTitle,
    ...props
  }: TableProps) => {
    const { t } = useTranslation()
    return (
      <StyledDataGrid
        localeText={{ columnHeaderSortIconLabel: t('common.sort') }}
        components={{
          Pagination: CustomPagination,
          Footer: CustomFooter,
          ColumnUnsortedIcon,
          ColumnSortedAscendingIcon,
          ColumnSortedDescendingIcon,
          NoRowsOverlay: NoRowsOverlay,
          NoResultsOverlay: NoRowsOverlay,
        }}
        componentsProps={{
          footer: {
            hideFooterPagination,
            showMoreButton,
            handleShowMore,
          },
          noRowsOverlay: { noRowsOverlayTitle },
        }}
        autoHeight
        headerHeight={50}
        pagination
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        disableColumnMenu
        {...props}
      />
    )
  },
)
