import { Tooltip } from '@mui/material'
import { memo } from 'react'
import { getTextWidth } from '../../services'
import { TruncatedTitle } from '../text'

export type TruncatedCellContentProps = {
  content?: string
  maxContentWidth?: number
}

export const TruncatedCellContent = memo<TruncatedCellContentProps>(
  ({ content, maxContentWidth }) => {
    return (
      <Tooltip
        title={
          (getTextWidth(content ?? '', 'lato') ?? 0) > (maxContentWidth ?? 200)
            ? content
            : ''
        }
        arrow
      >
        <TruncatedTitle>{content ?? '---'}</TruncatedTitle>
      </Tooltip>
    )
  },
)
