export const home = {
  title: 'Homepage',
  teacher: {
    topLink: {
      teachingSample: 'DI examples',
      diPlanning: 'DI strategies',
      resourceLibrary: 'Resources',
    },

    updatedExamplesAndResourcesTable: {
      headerTitle: 'DI resource updates',
      fields: {
        type: 'Resources classification',
        title: 'Title',
        learningPhase: 'Learning phases ',
        classLevel: 'Grade',
        subject: 'Subject',
        sharedBy: 'Shared from',
        lastModifiedDateTime: 'Last updated on',

        updatedDate: 'Updated date ',
        name: 'Template name',
        teacherName: 'Teacher-in-charge',
        organize: 'Project',
        action: 'Option',
      },
      dataType: {
        example: 'DI examples',
        resource: 'Resources',
      },
    },
  },

  diTeam: {
    topLink: {
      consultManagement: 'Consultation',
      publishManagement: 'Approve to publish',
      lessonPlanExamples: 'DI examples',
    },
  },

  diExpert: {
    topLink: {
      consultRequests: 'Follow up on consultation',
      lessonPlanSharing: 'Exchange of lesson plan',
      lessonPlanExamples: 'DI examples ',
    },
  },

  outstandingLessonPlanTable: {
    consult: {
      headerTitle: 'Pending for consultation ({{pendingCount}})',
    },
    publish: {
      headerTitle: 'Pending for publication approval ({{approvedCount}})',
    },
  },
}
