import { Button, Grid } from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid'
import { Dispatch, memo, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import { AutoComplete, SelectOption, TextField } from '../../form'
import { SearchLeftIcon } from '../search-left-icon'

const Container = styled.div`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding: ${({ theme }) => theme.spacings.general[4]}px;
  padding-bottom: 0px;
`

const StyledButton = styled(Button)`
  margin-left: 8px;
`

export enum ResourceTabMenu {
  onlineResources = 'ONLINE_RESOURCES',
  downloadableResources = 'DOWNLOADABLE_RESOURCES',
}

export type ResourceFilterProps = {
  selectionModel: GridSelectionModel
  isManagementPage: boolean
  activeTab: ResourceTabMenu
  inputTitle?: string
  setInputTitle?: Dispatch<SetStateAction<string | undefined>>
  learningPhaseOptions?: SelectOption[]
  classLevelOptions?: SelectOption[]
  subjectOptions?: SelectOption[]
  resourceTypeOptions?: SelectOption[]

  selectedLearningPhase?: SelectOption
  selectedClassLevel?: SelectOption
  selectedSubject?: SelectOption
  selectedResourceType?: SelectOption

  setSelectedLearningPhase?: Dispatch<SetStateAction<SelectOption | undefined>>
  setSelectedClassLevel?: Dispatch<SetStateAction<SelectOption | undefined>>
  setSelectedSubject?: Dispatch<SetStateAction<SelectOption | undefined>>
  setSelectedResourceType?: Dispatch<SetStateAction<SelectOption | undefined>>

  handleDelete: () => Promise<void>
  handleAdd: () => void
  handleUpload: () => void
}

export const ResourceFilter = memo<ResourceFilterProps>(
  ({
    selectionModel,
    isManagementPage,
    activeTab,
    inputTitle,
    setInputTitle,
    learningPhaseOptions,
    classLevelOptions,
    subjectOptions,
    resourceTypeOptions,
    selectedLearningPhase,
    selectedClassLevel,
    selectedSubject,
    selectedResourceType,
    setSelectedLearningPhase,
    setSelectedClassLevel,
    setSelectedSubject,
    setSelectedResourceType,
    handleDelete,
    handleAdd,
    handleUpload,
  }) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState(inputTitle)

    const handleInputOnChange = useCallback(
      (
        action?: Dispatch<SetStateAction<string | undefined>>,
        value?: string,
      ) => {
        if (action == null) return
        action(value)
      },
      [],
    )

    const handleSelectedOnChange = useCallback(
      (
        action?: Dispatch<SetStateAction<SelectOption | undefined>>,
        value?: SelectOption,
      ) => {
        if (action == null) return
        action(value)
      },
      [],
    )

    const handleApplyTitleFilter = useCallback(() => {
      setInputTitle?.(title)
    }, [setInputTitle, title])

    return (
      <Container>
        <Grid container columnGap={2} wrap="nowrap">
          <Grid item sm={activeTab === ResourceTabMenu.onlineResources ? 4 : 2}>
            <TextField
              value={title ?? ''}
              placeholder={t('resources.filter.title')}
              leftIcon={<SearchLeftIcon />}
              onChange={(e) => {
                handleInputOnChange(setTitle, e.target.value)
              }}
              onStopInput={handleApplyTitleFilter}
            />
          </Grid>

          {activeTab === ResourceTabMenu.downloadableResources ? (
            <>
              {learningPhaseOptions != null ? (
                <Grid item sm={2}>
                  <AutoComplete
                    sx={{ borderRadius: 2 }}
                    placeholder={t('resources.filter.learningPhase')}
                    options={learningPhaseOptions}
                    value={selectedLearningPhase}
                    onChange={(e, value) => {
                      handleSelectedOnChange(setSelectedLearningPhase, value)
                      setSelectedClassLevel?.(undefined)
                    }}
                  />
                </Grid>
              ) : (
                <></>
              )}

              {classLevelOptions != null ? (
                <Grid item sm={1.5}>
                  <AutoComplete
                    sx={{ borderRadius: 2 }}
                    placeholder={t('resources.filter.classLevel')}
                    options={classLevelOptions}
                    value={selectedClassLevel ?? null}
                    onChange={(e, value) =>
                      handleSelectedOnChange(setSelectedClassLevel, value)
                    }
                  />
                </Grid>
              ) : (
                <></>
              )}

              {subjectOptions != null ? (
                <Grid item sm={1.5}>
                  <AutoComplete
                    sx={{ borderRadius: 2 }}
                    placeholder={t('resources.filter.subject')}
                    options={subjectOptions}
                    value={selectedSubject}
                    onChange={(e, value) =>
                      handleSelectedOnChange(setSelectedSubject, value)
                    }
                  />
                </Grid>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {resourceTypeOptions != null ? (
            <Grid
              item
              sm={activeTab === ResourceTabMenu.onlineResources ? 4 : 1.5}
            >
              <AutoComplete
                sx={{ borderRadius: 2 }}
                placeholder={t('resources.filter.resourceType')}
                options={resourceTypeOptions}
                value={selectedResourceType}
                onChange={(e, value) =>
                  handleSelectedOnChange(setSelectedResourceType, value)
                }
              />
            </Grid>
          ) : (
            <></>
          )}

          {isManagementPage ? (
            <Grid item sm={3}>
              <Grid container>
                <StyledButton
                  variant="outlined"
                  startIcon={<Svg.Delete />}
                  onClick={handleDelete}
                  disabled={
                    selectionModel == null || selectionModel?.length === 0
                  }
                >
                  {t('resources.actions.delete')}
                </StyledButton>
                {activeTab === ResourceTabMenu.onlineResources ? (
                  <StyledButton
                    startIcon={<Svg.Plus />}
                    color="blue"
                    onClick={handleAdd}
                  >
                    {t('resources.actions.add')}
                  </StyledButton>
                ) : (
                  <StyledButton
                    startIcon={<Svg.Upload />}
                    color="blue"
                    onClick={handleUpload}
                  >
                    {t('resources.actions.upload')}
                  </StyledButton>
                )}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    )
  },
)
