import { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { HorizontalTabs } from '../../components'

type Props = {
  activeTab: number
  onActiveTabChange?: (id: number) => void
  tabMenus?: { id: number; label: ReactNode }[]
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

export const MasterDataTabs = memo<Props>(
  ({ activeTab, onActiveTabChange, tabMenus }) => {
    return (
      <Container>
        <HorizontalTabs
          activeTab={activeTab}
          onActiveTabChange={onActiveTabChange}
          tabMenus={tabMenus}
        />
      </Container>
    )
  },
)
