import { TagGroup } from '@polyu-dip/models'
import { memo, useMemo, useState } from 'react'
import { Tag } from '../../components'
import { LANGUAGES, useDisplayName } from '../../services'
import { Svg } from '../../assets'
import { Box, Popover, Typography } from '@mui/material'
import { useMasterData } from '../../hooks'
import styled from 'styled-components'

const Group = styled(Box)``

const TagName = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  display: inline;
`

const ArrowIcon = styled(Svg.ChevronDown)`
  fill: ${({ theme }) => theme.colors.primary};
`

const ClickArea = styled(Box)`
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`

const GroupItem = styled(Box)`
  margin: 12px;
`

type Props = {
  tagGroup: TagGroup
  language: LANGUAGES
}

export const TagGroupCell = memo(({ tagGroup, language }: Props) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const { tags } = useMasterData()

  const { getDisplayName } = useDisplayName()

  const tagLists = useMemo(
    () => tags.filter((it) => it.tagGroupId === tagGroup.id),
    [tagGroup.id, tags],
  )

  return (
    <Box position="relative">
      <Tag
        label={
          <Typography>
            {getDisplayName(tagGroup.name, language)}{' '}
            <ClickArea
              onClick={(e) => {
                setAnchorEl(e.currentTarget)
                e.stopPropagation()
                setOpen(!open)
              }}
            >
              <TagName>{getDisplayName(tagLists[0]?.name, language)}</TagName>
              <ArrowIcon />
            </ClickArea>
          </Typography>
        }
        colorCustomize={tagGroup.colorCode}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Group>
          {tagLists.map((it) => (
            <GroupItem key={it.id}>
              <Tag label={getDisplayName(it.name, language)} />
            </GroupItem>
          ))}
        </Group>
      </Popover>
    </Box>
  )
})
