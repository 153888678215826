import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  PostTagPayload,
  PutTagPayload,
  TagResponsePayload,
  TagsQueryParameters,
  TagsResponsePayload,
} from './tags-api.type'

const PATH = '/tags'

export function getTags(param?: TagsQueryParameters) {
  return ApiCore.primary.get<TagsResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: param?.expand == null ? null : param.expand.join(','),
    },
  })
}

export function postTagBulk(payloads: PostTagPayload[]) {
  return ApiCore.primary.post<TagResponsePayload>(`${PATH}/bulk`, {
    body: payloads,
  })
}

export function putTagById(id: string, payload: PutTagPayload) {
  return ApiCore.primary.put<TagResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}
