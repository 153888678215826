import { Button, FormControlLabel, Switch } from '@mui/material'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Svg } from '../../assets'
import { TruncatedCellContent } from '../../components'

export function useUsersColumn() {
  const { t } = useTranslation()

  const renderColumn = useCallback(
    (
      currentUserId: string,
      canTransferOwnership: boolean,
      handleSwitchUserStatusOnClicked?: (
        userId: string,
        isDisabled?: boolean,
      ) => void,
      handleTransferOwnership?: (userId: string) => void,
    ) => {
      const transferOwnershipColumn = [
        {
          field: 'transferOwnership',
          headerName: t('users.actions.transferOwnership.title'),
          sortable: false,
          width: 280,
          renderCell: ({ row }: any) => (
            <Button
              onClick={(event) => {
                event.stopPropagation()
                handleTransferOwnership?.(row.id)
              }}
            >
              <Svg.Transfer fill="white" />
            </Button>
          ),
        },
      ]
      return [
        {
          field: 'email',
          headerName: t('users.fields.email'),
          width: 400,
          renderCell: ({ row }: any) => (
            <TruncatedCellContent content={row.email} />
          ),
        },
        {
          field: 'role.label',
          headerName: t('users.fields.role'),
          width: 200,
          renderCell: ({ row }: any) => row.role?.displayName ?? '---',
        },
        {
          field: 'status',
          headerName: t('users.fields.status'),
          sortable: false,
          width: 200,
          renderCell: ({ row }: any) => (
            <>
              <FormControlLabel
                disabled={
                  row?.id === row?.school?.mainSchoolAdminUserId ||
                  row?.id === currentUserId
                }
                control={<Switch color="success" checked={!row?.isDisabled} />}
                label={
                  row?.isDisabled
                    ? t('users.status.disabled')
                    : t('users.status.active')
                }
                onClick={(event) => {
                  event.stopPropagation()
                  if (
                    row?.id === row?.school?.mainSchoolAdminUserId ||
                    row?.id === currentUserId
                  )
                    return
                  handleSwitchUserStatusOnClicked?.(row.id, row?.isDisabled)
                }}
              />
            </>
          ),
        },
        ...(canTransferOwnership && handleTransferOwnership != null
          ? transferOwnershipColumn
          : []),
      ]
    },
    [t],
  )

  return { renderColumn }
}
