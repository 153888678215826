import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { LessonPlanTeachingStepDiStrategyModel } from './lesson-plan-teaching-step-di-strategy.model'

export const LessonPlanTeachingStepModel = BaseEntityModel.named(
  'LessonPlanTeachingStepModel',
)
  .props({
    id: types.string,
    lessonPlanStudentGroupId: types.string,
    activity: types.string,
    description: types.string,
    order: types.number,
    duration: types.maybe(types.number),

    lessonPlanTeachingStepDiStrategies: types.optional(
      types.array(LessonPlanTeachingStepDiStrategyModel),
      [],
    ),
  })
  .extend(withUpdate)

export type LessonPlanTeachingStep = Instance<
  typeof LessonPlanTeachingStepModel
>
