import { Box, Button, Dialog, Grid, Typography } from '@mui/material'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Checkbox } from '../../../components'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: (isAgreePublish: boolean) => void
}

const ConfirmationDiv = styled.div`
  margin-bottom: 20px;
`

const StyledCheckboxDiv = styled.div`
  padding-left: 10.5px;
`

export const CreateApprovePublishDialog = observer<Props>(
  ({ open, onClose, onSubmit }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const [checked, setChecked] = useState(false)

    const handleOnClose = useCallback(() => {
      setChecked(false)
      onClose()
    }, [onClose])

    const handleOnSubmit = useCallback(() => {
      onSubmit(checked)
    }, [checked, onSubmit])

    return (
      <Dialog maxWidth={false} open={open} onClose={handleOnClose}>
        <Box width={875} p={10}>
          <Typography
            fontSize={theme.fontSizes.heading4}
            fontWeight={600}
            mb={5}
          >
            {t('lessonPlan.detail.publishRequest.approve.title')}
          </Typography>
          <Typography fontSize={theme.fontSizes.medium} fontWeight={400} mb={5}>
            {t('lessonPlan.detail.publishRequest.approve.content')}
          </Typography>
          <ConfirmationDiv>
            <Typography fontSize={theme.fontSizes.medium} fontWeight={600}>
              {t('lessonPlan.detail.publishRequest.approve.teacherIntention')}
            </Typography>
            <StyledCheckboxDiv>
              <Checkbox
                label={t(
                  'lessonPlan.detail.publishRequest.approve.confirmation',
                )}
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
                checkboxSize="small"
                helperText={t(
                  'lessonPlan.detail.publishRequest.approve.acknowledgement',
                )}
              />
            </StyledCheckboxDiv>
          </ConfirmationDiv>
          <Typography mb={10}>
            {t('lessonPlan.detail.publishRequest.approve.remind')}
          </Typography>
          <Grid container justifyContent="flex-end" columnSpacing={5}>
            <Grid item width={200}>
              <Button fullWidth variant="outlined" onClick={handleOnClose}>
                {t('lessonPlan.detail.publishRequest.actions.close')}
              </Button>
            </Grid>
            <Grid item width={200}>
              <Button fullWidth color="cascade" onClick={handleOnSubmit}>
                {t('lessonPlan.detail.publishRequest.actions.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    )
  },
)
