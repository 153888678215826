export const masterData = {
  learningUnits: {
    entityName: '學習範疇',
  },
  educationLevels: {
    entityName: '教育程度',
  },
  teachingClasses: {
    entityName: '班別',
  },
  diStrategies: {
    entityName: '適異教學策略',
  },
  tags: {
    entityName: '標籤',
  },
  tagGroups: {
    entityName: 'Tag Group',
  },
  subjects: {
    entityName: '科目',
  },
  learningPhases: {
    entityName: '學習階段',
  },
  classLevels: {
    entityName: '年級',
  },
  diStrategyClasses: {
    entityName: '適異教學策略類別',
  },
  tagTypes: {
    entityName: '標籤類型',
  },
  resourceTypes: {
    entityName: '資源類別',
  },
  diStrategyTags: {
    entityName: '適異教學策略標籤',
  },
  systemParameters: {
    entityName: '其它',
  },

  filter: {
    title: '標籤',
  },

  action: {
    add: '新增',
    addClass: '加入類別',
  },

  table: {
    chineseName: '標籤（中文）',
    englishName: '標籤（英文）',
    labelNumber: '次序',
    hexColorCode: 'HEX 色碼',
    status: '狀態',
    educationLevel: '教育程度',
    learningPhase: '學習階段',

    name: '標籤',
    description: '描述',
    category: '類別',
    dataType: '數據類型',
    valueInteger: 'Value_Integer',
    valueDecimal: 'Value_Decimal',
    valueDateTime: 'Value_DateTime',
    valueText: 'Value_Text',
    valueBoolean: 'Value_Boolean',
  },

  confirmationDialog: {
    title: '停用數據',
    content: '此系統數據即將停用',
    action: {
      delete: {
        title: '停用',
        successMessage: '已成功停用',
      },
      activate: {
        successMessage: '已成功啟用',
      },
    },
  },

  selectTagGroupTypeDialog: {
    title: '請選擇類別',
    description: '新增標籤',
    options: {
      withName: 'A. 包括分組及程度之標籤，樣式：',
      noName: 'B. 僅包括分組之標籤，樣式：',
    },
    tagExampleLabel: {
      withName: {
        text: `學習信心`,
        highlighted: '一般',
      },
      noName: {
        text: '',
        highlighted: '發現學習',
      },
    },
  },

  editDiStrategyDetailDialog: {
    field: {
      chineseName: {
        label: '標籤中文',
        placeholder: '標籤中文',
      },
      englishName: {
        label: '標籤（英文）',
        placeholder: '標籤英文',
      },
      tagGroup: {
        englishName: {
          label: '分組（英文）',
        },
        chineseName: {
          label: '分組（中文）',
        },
      },
      order: {
        label: '次序',
        placeholder: '次序',
      },
      colorCode: {
        label: 'HexCode',
        placeholder: 'HexCode',
      },
      displayVideo: {
        label: '插圖連結',
        placeholder: 'URL',
      },
      chineseDetail: {
        label: '中文描述',
        placeholder: '中文描述',
      },
      englishDetail: {
        label: '英文描述',
        placeholder: '英文描述',
      },
      url: {
        label: '連結',
        placeholder: 'URL',
      },
    },
    video: '適異教學策略課堂短片',
    furtherReadingAndExamples: '例子及延伸閱讀',
    diStrategyTag: '對應的特質',
  },

  addMasterDataDialog: {
    title: {
      create: '新增標籤',
      edit: '修改項目',
    },
    field: {
      chineseName: {
        label: '標籤（中文）',
        placeholder: '標籤（中文）',
      },
      englishName: {
        label: '標籤（英文）',
        placeholder: '標籤（英文）',
      },
      order: {
        label: '次序',
        placeholder: '次序',
      },
      colorCode: {
        label: 'HEX 色碼',
        placeholder: 'HEX 色碼',
      },
      learningPhase: {
        label: '階段',
        placeholder: '階段',
      },
      educationLevel: {
        label: '教育程度',
        placeholder: '教育程度',
      },
      educationLevelIds: {
        placeholder: '請選擇教育程度',
      },
      diStrategyClass: {
        label: '適異教學策略類別',
        placeholder: '適異教學策略類別',
      },
      label: {
        label: '適異教學策略編號',
        placeholder: '適異教學策略編號',
      },
      tagType: {
        label: '標籤類型',
        placeholder: '標籤類型',
      },
      name: {
        label: '標籤',
        placeholder: '標籤',
      },
      description: {
        label: '描述',
        placeholder: '描述',
      },
      category: {
        label: '類別',
        placeholder: '類別',
      },
      dataType: {
        label: '數據類型',
        placeholder: '數據類型',
      },
      isMultiSelect: {
        label: '可多選',
      },
      value: {
        label: '參數',
        placeholder: '參數',
      },
      value_Integer: {
        label: '參數_整數',
        placeholder: '參數_整數',
      },
      value_Decimal: {
        label: '參數_點數',
        placeholder: '參數_點數',
      },
      value_DateTime: {
        label: '參數_日期時間',
        placeholder: '參數_日期時間',
      },
      value_Text: {
        label: '參數_文字',
        placeholder: '參數_文字',
      },
      value_Boolean: {
        label: '參數_是/否',
        placeholder: '是/否',
      },
    },
    action: {
      create: '新增項目',
      delete: '停用',
      activate: '啟用',
    },
    errorMessage: {
      create: {
        title: '新增標籤失敗',
      },
      edit: {
        title: '修改項目失敗',
      },
    },
    successMessage: {
      create: {
        title: '已成功新增標籤',
      },
      edit: {
        title: '已成功修改項目',
      },
    },
  },
}
