import { Tag as TagUI } from './tag'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { Tag } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'

const TagName = styled(Typography)<{ selected?: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.palettes.general.white : theme.colors.primary};
  font-weight: 700;
  display: inline;
`

type Props = {
  tag: Tag
}

export const TagGroupTag = observer(({ tag }: Props) => {
  return (
    <TagUI
      label={
        <Typography>
          {tag.tagGroup?.displayName} <TagName>{tag.displayName}</TagName>
        </Typography>
      }
      colorCustomize={tag.tagGroup?.colorCode}
    />
  )
})
