import { Button, Grid, Typography } from '@mui/material'
import { ChangeEvent, memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { isValidUrl } from '../../utilities'
import { TextField } from '../form'

const StyleButtonIcon = styled.div`
  display: flex;
  width: 12px;
`

const ConfirmButton = styled(Button)`
  width 84px;
  display: flex;
  justify-content: space-between;
`

type UploadSectionProps = {
  onConfirmButtonClicked: (url: string) => void
}

export const UploadFileByUrl = memo<UploadSectionProps>(
  ({ onConfirmButtonClicked }) => {
    const { t } = useTranslation()
    const [inputUrl, setInputUrl] = useState<string>()
    const [error, setError] = useState<boolean>(false)

    const handleInputUrlChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setError(false)
        setInputUrl(event.target.value)
      },
      [],
    )

    const handleConfirmButtonClicked = useCallback(() => {
      if (inputUrl == null) return
      if (isValidUrl(inputUrl)) {
        onConfirmButtonClicked(inputUrl)
        setInputUrl('')
      } else {
        setError(true)
      }
    }, [inputUrl, onConfirmButtonClicked])

    return (
      <>
        <Typography>{t('attachment.uploadByUrl.label')}</Typography>
        <Grid container columnSpacing={3}>
          <Grid item flexGrow={1}>
            <TextField
              placeholder={t('attachment.uploadByUrl.placeholder')}
              value={inputUrl}
              onChange={handleInputUrlChange}
              error={
                error
                  ? t('attachment.errorMessage.invalidUrlFormat')
                  : undefined
              }
              isReserverForHelperText
            />
          </Grid>
          <Grid item flexShrink={0}>
            <ConfirmButton
              onClick={handleConfirmButtonClicked}
              disabled={inputUrl == null || inputUrl.length <= 0}
            >
              <StyleButtonIcon>
                <Svg.Plus cursor="pointer" />
              </StyleButtonIcon>
              {t('lessonPlan.actions.addUrl')}
            </ConfirmButton>
          </Grid>
        </Grid>
      </>
    )
  },
)
