import { useComputed } from '@polyu-dip/helpers'
import { useResourceTypes } from '@polyu-dip/queries'
import { useEffect, useState } from 'react'
import { SelectOption } from '../components'
import { contentPaths } from '../content-paths'
import { useStores } from '../stores'
import { useMasterDataInit } from './use-master-data-init'

export function useResourceFilter() {
  useResourceTypes(useStores)
  const { isAllMasterDataFetchSuccess } = useMasterDataInit(
    contentPaths.resources(),
  )

  const { masterDataStore } = useStores()

  const [inputTitle, setInputTitle] = useState<string>()
  const [selectedLearningPhase, setSelectedLearningPhase] =
    useState<SelectOption>()
  const [selectedClassLevel, setSelectedClassLevel] = useState<SelectOption>()
  const [selectedSubject, setSelectedSubject] = useState<SelectOption>()
  const [selectedResourceType, setSelectedResourceType] =
    useState<SelectOption>()

  const learningPhaseOptions = useComputed(
    () =>
      masterDataStore.activeLearningPhases?.map((learningPhase) => ({
        label: learningPhase.displayName,
        value: learningPhase.id,
        educationLevelId: learningPhase.educationLevelId,
      })),
    [masterDataStore.activeLearningPhases],
  )

  const classLevelOptions = useComputed(
    () =>
      masterDataStore.activeClassLevels
        .filter((classLevel) => {
          if (selectedLearningPhase?.value == null) return true
          return classLevel.learningPhaseId === selectedLearningPhase?.value
        })
        .map((classLevel) => ({
          label: classLevel.displayName,
          value: classLevel.id,
          educationLevelId: classLevel.learningPhase?.educationLevelId,
        })),
    [masterDataStore.activeClassLevels, selectedLearningPhase],
  )

  const subjectOptions = useComputed(() => {
    const options = masterDataStore.activeSubjects?.map((subject) => ({
      label: subject.displayName,
      value: subject.id,
      educationLevelIds: subject.subjectEducationLevelMappings.map(
        (it) => it.educationLevelId,
      ),
    }))

    const learningPhase = learningPhaseOptions.find(
      (it) => it.value === selectedLearningPhase?.value,
    )

    const classLevel = classLevelOptions.find(
      (it) => it.value === selectedClassLevel?.value,
    )

    return options.filter(
      (it) =>
        (learningPhase == null ||
          it.educationLevelIds.includes(learningPhase.educationLevelId)) &&
        (classLevel?.educationLevelId == null ||
          it.educationLevelIds.includes(classLevel.educationLevelId)),
    )
  }, [
    masterDataStore.activeSubjects,
    learningPhaseOptions,
    selectedLearningPhase,
    selectedClassLevel,
    classLevelOptions,
  ])

  useEffect(() => {
    if (selectedLearningPhase != null || selectedClassLevel != null) {
      setSelectedSubject(undefined)
    }
  }, [selectedLearningPhase, selectedClassLevel])

  const resourceTypeOptions = useComputed(
    () =>
      masterDataStore.activeResourceTypes?.map((resourceType) => ({
        label: resourceType.displayName,
        value: resourceType.id,
      })),
    [masterDataStore.activeResourceTypes],
  )

  return {
    isAllMasterDataFetchSuccess,
    learningPhaseOptions,
    classLevelOptions,
    subjectOptions,
    resourceTypeOptions,

    inputTitle,
    setInputTitle,
    selectedLearningPhase,
    setSelectedLearningPhase,
    selectedClassLevel,
    setSelectedClassLevel,
    selectedSubject,
    setSelectedSubject,
    selectedResourceType,
    setSelectedResourceType,
  }
}
