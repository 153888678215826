import { Box, BoxProps, Chip, ChipProps } from '@mui/material'
import { ConsultDisplayStatus, PublishDisplayStatus } from '@polyu-dip/apis'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { isHexColorDark } from '../../utilities'

const StyledChip = styled(Chip)<
  OwnProps & {
    labelColor?: string
    backgroundColor?: string
    isStatus?: boolean
  }
>`
  -webkit-print-color-adjust: exact;
  height: auto;
  padding: 4px ${({ isStatus }) => (isStatus ? 12 : 8)}px;
  border-radius: ${({ rounded = 8, isStatus }) => (isStatus ? 20 : rounded)}px;
  font-size: 16px;
  font-weight: ${({ fontWeight }) => fontWeight};
  .MuiChip-label {
    padding: 0px 4px;
    color: ${({ labelColor }) => labelColor};
    white-space: pre-wrap;
  }
  background-color: ${({ backgroundColor }) => backgroundColor};
`

type OwnProps = {
  fontWeight?: number | string
  colorCustomize?:
    | 'gray-6'
    | 'gray-5'
    | 'orange-1'
    | 'light-gray'
    | 'light-blue'
    | 'light-green'
    | string
  rounded?: number
  containerProps?: BoxProps
  isStatus?: boolean
  statusType?: 'publishRequest' | 'consultRequest'
}

type TagProps = ChipProps & OwnProps

export const Tag = observer<TagProps>(
  ({
    containerProps,
    colorCustomize,
    color,
    isStatus,
    statusType,
    label,
    ...props
  }) => {
    const theme = useTheme()
    const { t } = useTranslation()

    const tagLabel = useMemo(() => {
      if (!isStatus) return label
      if (statusType === 'publishRequest')
        return t(
          `lessonPlan.publishRequestStatus.${label as PublishDisplayStatus}`,
        )
      if (statusType === 'consultRequest')
        return t(
          `lessonPlan.consultRequestStatus.${label as ConsultDisplayStatus}`,
        )
      return t(`lessonPlan.status.${label}` as any)
    }, [isStatus, label, statusType, t])

    const extractColor = useMemo(() => {
      if (isStatus) {
        if (statusType == 'consultRequest') {
          switch (label) {
            case 'pending':
              return {
                labelColor: theme.colors.accent,
                backgroundColor: '#F9DBDB',
              }
            case 'followingUp':
              return {
                labelColor: theme.palettes.general.greys[2],
                backgroundColor: theme.palettes.general.greys[4],
              }
            case 'completed':
              return {
                labelColor: theme.palettes.general.white,
                backgroundColor: theme.palettes.semantic.brown,
              }
            default:
              return {
                labelColor: isHexColorDark(colorCustomize)
                  ? theme.palettes.general.white
                  : theme.palettes.general.black,
                backgroundColor: colorCustomize,
              }
          }
        } else if (statusType === 'publishRequest') {
          switch (label) {
            case 'pending':
              return {
                labelColor: theme.palettes.general.cascade,
                backgroundColor: theme.palettes.semantic.hummingBird,
              }
            case 'approved':
              return {
                labelColor: theme.palettes.general.white,
                backgroundColor: theme.palettes.general.success,
              }
            case 'rejected':
              return {
                labelColor: theme.palettes.general.white,
                backgroundColor: theme.palettes.general.greys[1],
              }
            case 'cancelled':
            case 'unPublishing':
              return {
                labelColor: theme.palettes.general.white,
                backgroundColor: theme.palettes.general.greys[3],
              }
            case 'completed':
              return {
                labelColor: theme.palettes.general.white,
                backgroundColor: theme.palettes.semantic.brown,
              }
            case 'publishing':
              return {
                labelColor: theme.palettes.general.white,
                backgroundColor: theme.palettes.semantic.astronautBlue,
              }
            case 'noPendingRequest':
              return {
                labelColor: '#2C65A8',
                backgroundColor: '#E0EBF9',
              }
            default:
              return {
                labelColor: isHexColorDark(colorCustomize)
                  ? theme.palettes.general.white
                  : theme.palettes.general.black,
                backgroundColor: colorCustomize,
              }
          }
        } else {
          switch (label) {
            case 'toBeViewed':
              return {
                labelColor: theme.palettes.general.cascade,
                backgroundColor: theme.palettes.general.lightGreen,
              }
            case 'agreeToPublish':
              return {
                labelColor: theme.palettes.general.white,
                backgroundColor: theme.palettes.general.cascade,
              }
            case 'accepted':
              return {
                labelColor: theme.palettes.general.white,
                backgroundColor: theme.palettes.general.success,
              }
            case 'published':
              return {
                labelColor: theme.palettes.general.white,
                backgroundColor: theme.colors.primary,
              }
            case 'toBeProcessed':
              return {
                labelColor: theme.colors.accent,
                backgroundColor: '#F9DBDB',
              }
            case 'replied':
              return {
                labelColor: theme.palettes.general.white,
                backgroundColor: theme.palettes.semantic.brown,
              }
            case 'followingUp':
              return {
                labelColor: theme.palettes.general.greys[2],
                backgroundColor: theme.palettes.general.greys[4],
              }
            case 'replyReceived':
              return {
                labelColor: theme.colors.primary,
                backgroundColor: theme.colors.tertiary,
              }
            default:
              return {
                labelColor: isHexColorDark(colorCustomize)
                  ? theme.palettes.general.white
                  : theme.palettes.general.black,
                backgroundColor: colorCustomize,
              }
          }
        }
      } else {
        switch (colorCustomize) {
          case 'gray-6':
            return {
              labelColor: theme.palettes.general.greys[0],
              backgroundColor: theme.palettes.general.greys[5],
            }
          case 'gray-5':
            return {
              labelColor: theme.palettes.general.greys[0],
              backgroundColor: theme.palettes.general.greys[4],
            }
          case 'orange-1':
            return {
              labelColor: theme.palettes.general.greys[0],
              backgroundColor: theme.palettes.general.oranges[1],
            }
          case 'light-gray': {
            return {
              labelColor: theme.palettes.general.greys[0],
              backgroundColor: theme.palettes.general.lightGray,
            }
          }
          case 'light-blue': {
            return {
              labelColor: theme.colors.tertiary,
              backgroundColor: theme.colors.primary,
            }
          }
          case 'light-green': {
            return {
              labelColor: theme.palettes.general.cascade,
              backgroundColor: theme.palettes.general.lightGreen,
            }
          }
          default:
            return {
              labelColor: isHexColorDark(colorCustomize)
                ? theme.palettes.general.white
                : theme.palettes.general.black,
              backgroundColor: colorCustomize,
            }
        }
      }
    }, [
      colorCustomize,
      isStatus,
      label,
      statusType,
      theme.colors.accent,
      theme.colors.primary,
      theme.colors.tertiary,
      theme.palettes.general.black,
      theme.palettes.general.cascade,
      theme.palettes.general.greys,
      theme.palettes.general.lightGray,
      theme.palettes.general.lightGreen,
      theme.palettes.general.oranges,
      theme.palettes.general.success,
      theme.palettes.general.white,
      theme.palettes.semantic.astronautBlue,
      theme.palettes.semantic.brown,
      theme.palettes.semantic.hummingBird,
    ])

    return (
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...containerProps}
      >
        <StyledChip
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          isStatus={isStatus}
          label={tagLabel}
          color={color}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...extractColor}
        />
      </Box>
    )
  },
)
