import { useLessonPlanPublishRequest } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FullscreenLoading } from '../../components'
import { contentPaths } from '../../content-paths'
import { useApiErrorHandle } from '../../hooks'
import { LessonPlanEditPage } from '../lesson-plan'

export const LessonPlanPublishRequestEditPage = observer(() => {
  const { standardErrorHandler } = useApiErrorHandle()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { lessonPlanPublishRequestId } = useParams<{
    lessonPlanPublishRequestId: string
  }>()

  const { data, isLoading } = useLessonPlanPublishRequest(
    lessonPlanPublishRequestId ?? '',
    {},
    {
      enabled: lessonPlanPublishRequestId != null,
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('error.fetchEntityFailure', {
            entityName: t('lessonPlanRequest.entityName.publishRequest'),
          }),
          onDismiss: () => {
            navigate(contentPaths.lessonPlanPublishRequests())
          },
        })
      },
    },
  )

  if (isLoading || !data) {
    return <FullscreenLoading />
  }

  return (
    <LessonPlanEditPage
      isExample
      lessonPlanId={data.publishingLessonPlanId}
      saveAndViewNavigatingPath={contentPaths.lessonPlanPublishRequest(
        data.id,
        'view',
      )}
    />
  )
})
