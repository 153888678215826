export const myLessonPlan = {
  title: '我的教案',
  publicVersion: '已投稿版本',

  actions: {
    createLessonPlan: '建立教案',
    editLessonPlan: '修改教案',
  },

  tableFields: {
    class: '年級',
    subject: '科目',
    teachingTopic: '教案名稱',
    latestMessage: '最新留言',
    isAgreePublish: '同意公開',
    consultDisplayStatus: '諮詢狀態',
    publishDisplayStatus: '投稿狀態',
    status: '狀態',
    submissionDate: '提交日期',
    releaseOrUnPublishedTime: '發佈時間/下架時間',
  },

  pagination: {
    next: '下頁',
  },
}
