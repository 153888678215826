import { Box, Grid, Link, Typography } from '@mui/material'
import { File, LessonPlanAttachment } from '@polyu-dip/models'
import { memo } from 'react'
import styled from 'styled-components'
import { Svg } from '../../../../assets'
import { Dropdown, SelectOption, Text } from '../../../../components'

type Props = {
  isFileResource?: boolean
  printable?: boolean
  resources: LessonPlanAttachment[]
  handleOnFileNameClick: (file?: File) => void
  resourceTypeOptions: SelectOption[]
}

const StyledLink = styled(Link)`
  cursor: pointer;
`

const AttachmentLabel = styled(Typography)`
  font-size: 14px;
  word-break: break-word;
`

export const ResourcesList = memo<Props>(
  ({
    isFileResource,
    printable,
    resources,
    handleOnFileNameClick,
    resourceTypeOptions,
  }) => {
    return (
      <Box mt={5}>
        {resources.map((it) => (
          <Grid
            container
            key={it.id}
            columnSpacing={5}
            alignItems="center"
            mb={2}
          >
            {printable ? (
              <></>
            ) : (
              <Grid item xs={0.5} alignItems="center">
                {isFileResource ? <Svg.Uploaded /> : <Svg.Link />}
              </Grid>
            )}
            <Grid item xs={9.5}>
              <StyledLink onClick={() => handleOnFileNameClick(it.file)}>
                <AttachmentLabel>{it.attachmentName}</AttachmentLabel>
              </StyledLink>
            </Grid>
            <Grid item marginLeft="auto" xs={2} width={160}>
              {printable ? (
                <Text>{it.resourceType?.displayName}</Text>
              ) : (
                <Dropdown
                  defaultValue={it.resourceType?.id}
                  disabled
                  options={resourceTypeOptions}
                  mb={0}
                />
              )}
            </Grid>
          </Grid>
        ))}
      </Box>
    )
  },
)
