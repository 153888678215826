import {
  PaginatedPayload,
  TeachingClassesQueryParameters,
} from '@polyu-dip/apis'
import { TeachingClass } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'teaching-classes'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useTeachingClasses(
  useStores: UseStoresType,
  param?: TeachingClassesQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<TeachingClass>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getTeachingClasses(param),
    opts,
  )
}
