import { Box, Button, Dialog, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ConfirmationDialog = observer<Props>(
  ({ open, onClose, onSubmit }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    return (
      <Dialog maxWidth={false} open={open} onClose={onClose}>
        <Box width={875} p={10}>
          <Typography fontSize={22} fontWeight={600} mb={5}>
            {t(
              'lessonPlan.createForm.requestConsultingPopup.confirmation.title',
            )}
          </Typography>
          <Typography fontSize={theme.fontSizes.medium} fontWeight={400} mb={5}>
            {t(
              'lessonPlan.createForm.requestConsultingPopup.confirmation.content',
            )}
          </Typography>
          <Grid container justifyContent="flex-end" columnSpacing={5}>
            <Grid item width={200}>
              <Button fullWidth variant="outlined" onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item width={200}>
              <Button fullWidth onClick={onSubmit}>
                {t('common.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    )
  },
)
