export const home = {
  title: '主頁',
  teacher: {
    topLink: {
      teachingSample: '教學示例',
      diPlanning: '適異教學策略',
      resourceLibrary: '資源庫',
    },

    updatedExamplesAndResourcesTable: {
      headerTitle: '適異教學資源更新',
      fields: {
        type: '資源分類',
        title: '標題',
        learningPhase: '學習階段',
        classLevel: '年級',
        subject: '科目',
        sharedBy: '分享自',
        lastModifiedDateTime: '最後更新',

        updatedDate: '更新日期',
        name: '範本名稱',
        teacherName: '負責老師',
        organize: '項目',
        action: '操作選項',
      },
      dataType: {
        example: '教學示例',
        resource: '資源庫',
      },
    },
  },

  diTeam: {
    topLink: {
      consultManagement: '諮詢管理',
      publishManagement: '發佈審批',
      lessonPlanExamples: '教學示例',
    },
  },

  diExpert: {
    topLink: {
      consultRequests: '跟進諮詢',
      lessonPlanSharing: '教案交流',
      lessonPlanExamples: '教學示例',
    },
  },

  outstandingLessonPlanTable: {
    consult: {
      headerTitle: '待處理諮詢 ({{pendingCount}})',
    },
    publish: {
      headerTitle: '待審批發佈 ({{approvedCount}})',
    },
  },
}
