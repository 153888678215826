export const attachment = {
  uploadByUrl: {
    sectionTitle: '插入連結',
    label:
      '如上傳檔案大於500MB而要透過One Drive或Google Drive等上載, 請將連結填寫於此',
    placeholder: 'http://www.example.com',
  },
  uploadFile: {
    sectionTitle: '加入附件',
    title: '將檔案拖曳至此或點此上傳',
    description: '不大於 {{maxSizeInMb}}MB 之檔案',
    dropTitle: '放下檔案以上傳',
  },
  bulkDownload: {
    title: '全部下載',
    noAttachmentDownloaded: '沒有任何可下載的檔案',
  },
  errorMessage: {
    popupReminder: {
      title: '允許彈跳視窗',
      content: '請允許彈跳視窗以下載檔案',
    },
    invalidUrlFormat: '請輸入包含https://的正確下載連結',
    uploadAttachment: {
      title: '上傳檔案失敗',
      exceedMaxFileSize: {
        content: '檔案大小不能大於{{maxSizeInMb}}MB',
      },
    },
    downloadAttachment: {
      title: '下載檔案失敗',
    },
  },
}
