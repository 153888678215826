import { useExperts, useLessonPlanConsultRequest } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FullscreenLoading } from '../../components'
import { contentPaths } from '../../content-paths'
import { useApiErrorHandle } from '../../hooks'
import { useStores } from '../../stores'
import { LessonPlanDetailPage } from '../lesson-plan'

export const LessonPlanConsultRequestDetailPage = observer(() => {
  const { standardErrorHandler } = useApiErrorHandle()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { lessonPlanConsultRequestId } = useParams<{
    lessonPlanConsultRequestId: string
  }>()

  const { isLoading: isExpertLoading } = useExperts(useStores, {
    expand: ['Role'],
  })

  const { data, isLoading } = useLessonPlanConsultRequest(
    useStores,
    lessonPlanConsultRequestId ?? '',
    {},
    {
      enabled: lessonPlanConsultRequestId != null,
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t('error.fetchEntityFailure', {
            entityName: t('lessonPlanRequest.entityName.consultRequest'),
          }),
          onDismiss: () => {
            navigate(contentPaths.lessonPlanPublishRequests())
          },
        })
      },
    },
  )

  if (isLoading || isExpertLoading || !data) {
    return <FullscreenLoading />
  }

  return (
    <LessonPlanDetailPage
      lessonPlanId={data.lessonPlanId}
      consultRequest={data}
    />
  )
})
