import { Box, Grid, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { MasterDataTable } from '@polyu-dip/stores'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { MasterDataManagementDialog, Table } from '../../components'
import { useMasterData, useMasterDataManagement } from '../../hooks'
import { useMasterDataManagementColumn } from './use-master-data-management-column'

type Props = {
  selectedContentId?: string
  title?: string
  selectedMasterDataTab: MasterDataTable
  inputTitle?: string
  handleEditClass?: (id: string) => void
}

const Container = styled(Box)``

const Title = styled(Typography)`
  display: flex;
  align-items: center;
  height: 42px;
  padding: 8px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palettes.general.background};
`

const StyledTable = styled(Table)`
  padding-left: 0;
  padding-top: 12px;
`

const PAGINATION_LIMIT = 15

export const MasterDataList = observer<Props>(
  ({
    selectedContentId,
    selectedMasterDataTab,
    title,
    inputTitle,
    handleEditClass,
  }) => {
    const masterDataTabTransform = useMemo(
      () =>
        selectedMasterDataTab === 'tags' ? 'tagGroups' : selectedMasterDataTab,
      [selectedMasterDataTab],
    )

    const theme = useTheme()
    const { targetMasterData } = useMasterData(
      masterDataTabTransform,
      selectedContentId,
    )

    const { renderColumn } = useMasterDataManagementColumn()

    const [isOpen, setIsOpen] = useState(false)
    const [selectedId, setSelectedId] = useState<string>()

    const computedMasterData = useComputed(() => {
      return inputTitle == null || inputTitle.length === 0
        ? targetMasterData.map((it, index) => ({
            ...it,
            index: index,
          }))
        : targetMasterData
            .filter((it) => {
              const name = it.name.toLocaleLowerCase()
              const filterString = inputTitle.toLocaleLowerCase()
              return name.includes(filterString)
            })
            .map((it, index) => ({
              ...it,
              index: index,
            }))
    }, [inputTitle, targetMasterData])

    const handleOnRowClick = useCallback((id: string) => {
      setSelectedId(id)
      setIsOpen(true)
    }, [])

    const {
      getTargetMasterDataName,
      handleSwitchMasterDataStatus,
      handleSwitchMasterDataOrder,
      handleOpenDisableConfirmationDialog,
      renderDisableConfirmationDialog,
    } = useMasterDataManagement(selectedId)

    const handleSwitchStatusOnClick = useCallback(
      async (id: string, isDisabled?: boolean) => {
        const targetMasterDataName = getTargetMasterDataName(
          masterDataTabTransform,
          selectedContentId,
        )
        if (targetMasterDataName == null) return
        const selectedData = computedMasterData.find((data) => data.id === id)
        const payload = {
          ...selectedData,
          isDisabled: !isDisabled,
          id: id,
        }
        if (isDisabled) {
          await handleSwitchMasterDataStatus(payload, targetMasterDataName)
        } else {
          handleOpenDisableConfirmationDialog(payload, targetMasterDataName)
        }
      },
      [
        getTargetMasterDataName,
        masterDataTabTransform,
        selectedContentId,
        computedMasterData,
        handleSwitchMasterDataStatus,
        handleOpenDisableConfirmationDialog,
      ],
    )

    const handleSwitchOrderOnClick = useCallback(
      async (index1: number, index2: number) => {
        const targetMasterDataName = getTargetMasterDataName(
          masterDataTabTransform,
          selectedContentId,
        )

        if (targetMasterDataName == null) return
        const selectedFirstData = computedMasterData.find(
          (data) => data.index === index1,
        )
        const selectedSecondData = computedMasterData.find(
          (data) => data.index === index2,
        )
        const payload = {
          firstSwitchOrderItemId: selectedFirstData.id,
          secondSwitchOrderItemId: selectedSecondData.id,
          firstSwitchOrderItemRowVersion: selectedFirstData.rowVersion,
          secondSwitchOrderItemRowVersion: selectedSecondData.rowVersion,
        }
        handleSwitchMasterDataOrder(payload, targetMasterDataName)
      },
      [
        computedMasterData,
        getTargetMasterDataName,
        handleSwitchMasterDataOrder,
        masterDataTabTransform,
        selectedContentId,
      ],
    )

    const withTagGroupWithName = useMemo(
      () =>
        selectedId &&
        masterDataTabTransform === 'tagGroups' &&
        targetMasterData.find((it) => it.id === selectedId)?.name,
      [masterDataTabTransform, selectedId, targetMasterData],
    )

    return (
      <Container>
        <Title fontWeight={600} fontSize={theme.fontSizes.heading4}>
          <Grid container direction="row">
            <Grid item>{title}</Grid>
            {masterDataTabTransform === 'diStrategies' ||
            masterDataTabTransform === 'tagGroups' ? (
              <Grid item ml={3}>
                <Svg.Edit
                  fill={theme.palettes.general.black}
                  height={12}
                  width={12}
                  cursor="pointer"
                  onClick={() => {
                    handleEditClass?.(selectedContentId ?? '')
                  }}
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Title>
        <StyledTable
          columns={renderColumn(
            computedMasterData.length,
            selectedMasterDataTab,
            selectedContentId,
            handleSwitchStatusOnClick,
            handleSwitchOrderOnClick,
          )}
          rows={computedMasterData}
          hideFooterPagination={computedMasterData.length <= PAGINATION_LIMIT}
          pageSize={PAGINATION_LIMIT}
          onRowClick={({ row }) => handleOnRowClick(row?.id ?? '')}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'order',
                  sort: 'asc',
                },
              ],
            },
          }}
        />
        <MasterDataManagementDialog
          mode="edit"
          masterDataTable={masterDataTabTransform}
          selectedContentId={selectedContentId}
          selectedId={selectedId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          withTagGroupWithName={withTagGroupWithName}
        />
        {renderDisableConfirmationDialog}
      </Container>
    )
  },
)
