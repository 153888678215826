import { PaginatedPayload, RolesQueryParameters } from '@polyu-dip/apis'
import { Role } from '@polyu-dip/models'
import { UsersStore } from '@polyu-dip/stores'
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'roles'

type UseStoresType = () => { usersStore: UsersStore }

export function useRoles(
  useStores: UseStoresType,
  param?: RolesQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<Role>>,
) {
  const { usersStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => usersStore.getRoles(param),
    opts,
  )
}

export function useRole(useStores: UseStoresType, id: string) {
  const { usersStore } = useStores()
  return useQuery([QUERY_CACHE_KEY_ROOT, id] as QueryKey, () =>
    usersStore.getRole(id),
  )
}
