import {
  PaginatedPayload,
  PostResourceDisableBulk,
  PostResourcePayload,
  PutResourcePayload,
  ResourceQueryParameters,
} from '@polyu-dip/apis'
import { Resource } from '@polyu-dip/models'
import { FilesStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'resources'

type UseStoresType = () => { filesStore: FilesStore }

export function useResources(
  useStores: UseStoresType,
  param?: ResourceQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<Resource>>,
) {
  const { filesStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => filesStore.getResources(param),
    opts,
  )
}

export function useCreateResource(
  useStores: UseStoresType,
  opts?: UseMutationOptions<Resource, unknown, PostResourcePayload>,
) {
  const { filesStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => filesStore.createResource(payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useCreateResources(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostResourcePayload[]>,
) {
  const { filesStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payloads) => filesStore.createResources(payloads), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useUpdateResource(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<Resource, unknown, PutResourcePayload>,
) {
  const { filesStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => filesStore.updateResource(id, payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useDisableResource(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<Resource, unknown, string>,
) {
  const { filesStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (rowVersion) => filesStore.disableResource(id, rowVersion),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useDisableResources(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostResourceDisableBulk>,
) {
  const { filesStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payloads) => filesStore.disableResources(payloads), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function clearResourcesQueryCaches(queryClient: QueryClient) {
  queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
