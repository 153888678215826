import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { NameModel } from './languages-json'
import { LearningPhase } from './learning-phase.model'

export const ClassLevelModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    isDisabled: types.boolean,
    learningPhaseId: types.string,
    order: types.number,
    colorCode: types.string,
  })
  .extend(withUpdate)
  .named('ClassLevelModel')
  .extend(withRootStore)
  .views((self) => ({
    get learningPhase() {
      if (self.learningPhaseId == null) return undefined
      return (
        self.rootStore.masterDataStore as { learningPhases: LearningPhase[] }
      ).learningPhases.find(
        (learningPhase) => learningPhase.id === self.learningPhaseId,
      )
    },
  }))

export type ClassLevel = Instance<typeof ClassLevelModel>
