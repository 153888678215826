import { useComputed } from '@polyu-dip/helpers'
import { useCallback, useMemo, useState } from 'react'
import {
  AddSchoolDialog,
  Dialog,
  DialogAction,
  useOverlay,
} from '../components'
import {
  clearSchoolQueryCaches,
  useCreateSchool,
  useDisableSchool,
  useEducationLevels,
  useEnableSchool,
  useRoles,
  useUpdateSchool,
  useUsers,
} from '@polyu-dip/queries'
import { useStores } from '../stores'
import { sortByOrder } from '../utilities'
import { RoleType, School } from '@polyu-dip/models'
import { useTranslation } from 'react-i18next'
import { useApiErrorHandle } from './use-api-error-handle'
import { useDisplayName } from '../services'
import {
  NewSchoolFormData,
  useSchoolFormProvider,
} from './use-school-form-provider'
import { useQueryClient } from '@tanstack/react-query'

export function useSchoolManagementActions() {
  const { masterDataStore, usersStore } = useStores()
  const { t } = useTranslation()
  const { standardErrorHandler } = useApiErrorHandle()
  const { createJsonName, getDisplayName } = useDisplayName()
  const { showSnackbar, showSpinner, hideSpinner } = useOverlay()
  const queryClient = useQueryClient()

  const { defaultNewSchool, form } = useSchoolFormProvider()
  const [isAddSchoolDialogOpen, setIsAddSchoolDialogOpen] = useState(false)
  const [schoolDialogMode, setSchoolDialogMode] = useState<'edit' | 'add'>(
    'add',
  )
  const [editingSchool, setEditingSchool] = useState<School>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEducationLevels(useStores, {})
  useRoles(useStores, {})

  const schoolAdminRoleId = useComputed(
    () =>
      usersStore.roles.slice().find((it) => it.label == RoleType.schoolAdmin)
        ?.id,
    [usersStore],
  )

  const { data, isFetching } = useUsers(
    useStores,
    {
      schoolId: editingSchool?.id ?? '',
      roleId: schoolAdminRoleId,
      isDisabled: false,
    },
    {
      enabled:
        editingSchool?.id != null &&
        editingSchool?.id != '' &&
        schoolAdminRoleId != null,
    },
  )

  const educationLevelOptions = useComputed(
    () =>
      masterDataStore.educationLevels
        .slice()
        .sort(sortByOrder)
        .map((educationLevel) => ({
          label: educationLevel.displayName,
          value: educationLevel.id,
          disabled: educationLevel.isDisabled,
        })),
    [masterDataStore.educationLevels],
  )

  const schoolAdminOptions = useComputed(
    () =>
      data?.data.slice().map((schoolAdmin) => ({
        label: schoolAdmin.email,
        value: schoolAdmin.id,
      })),
    [data?.data],
  )

  const { mutateAsync: createSchool } = useCreateSchool(useStores, {
    onSuccess: async () => {
      showSnackbar({
        message: t('schools.actions.add.successMessage'),
      })
      setIsAddSchoolDialogOpen(false)
      setIsSubmitting(false)
      clearSchoolQueryCaches(queryClient)
    },
    onError: (error) => {
      setIsSubmitting(false)
      standardErrorHandler(error, {
        defaultTitle: t('schools.actions.add.errorMessage'),
      })
    },
  })

  const { mutateAsync: updateSchool } = useUpdateSchool(
    useStores,
    editingSchool?.id ?? '',
    {
      onSuccess: async () => {
        showSnackbar({
          message: t('schools.actions.edit.successMessage'),
        })
        setIsAddSchoolDialogOpen(false)
        setIsSubmitting(false)
        clearSchoolQueryCaches(queryClient)
      },
      onError: (error) => {
        setIsSubmitting(false)
        standardErrorHandler(error, {
          defaultTitle: t('schools.actions.edit.errorMessage'),
        })
      },
    },
  )

  const { mutateAsync: enableSchool } = useEnableSchool(useStores, {
    onSuccess: async () => {
      showSnackbar({
        message: t('schools.actions.enableAccount.successMessage'),
      })
      setIsAddSchoolDialogOpen(false)
      setIsSubmitting(false)
      clearSchoolQueryCaches(queryClient)
    },
    onError: (error) => {
      setIsSubmitting(false)
      standardErrorHandler(error, {
        defaultTitle: t('schools.actions.enableAccount.errorMessage'),
      })
    },
  })

  const { mutateAsync: disableSchool } = useDisableSchool(useStores, {
    onSuccess: async () => {
      showSnackbar({
        message: t('schools.actions.disableAccount.successMessage'),
      })
      setIsAddSchoolDialogOpen(false)
      setIsSubmitting(false)
      clearSchoolQueryCaches(queryClient)
    },
    onError: (error) => {
      setIsSubmitting(false)
      standardErrorHandler(error, {
        defaultTitle: t('schools.actions.disableAccount.errorMessage'),
      })
    },
  })

  const renderDisableButton: DialogAction<string> = useMemo(() => {
    return {
      text: t('schools.actions.disableAccount.title'),
      type: 'contained',
      color: 'sharpRed',
      value: 'disable',
    }
  }, [t])

  const renderEnableButton: DialogAction<string> = useMemo(() => {
    return {
      text: t('schools.actions.enableAccount.title'),
      type: 'contained',
      color: 'success',
      value: 'disable',
    }
  }, [t])

  const handleCreateSchool = useCallback(
    async (formData: NewSchoolFormData) => {
      try {
        showSpinner()
        createSchool({
          name: createJsonName(formData.englishName, formData.chineseName),
          shortCode: formData.shortCode,
          domain: formData.domain,
          educationLevelId: formData.educationLevelId,
          mainSchoolAdminUserName: formData.mainSchoolAdminUserEmail,
          mainSchoolAdminUserEmail: formData.mainSchoolAdminUserEmail,
        })
      } finally {
        hideSpinner()
      }
    },
    [createJsonName, createSchool, hideSpinner, showSpinner],
  )

  const handleUpdateSchool = useCallback(
    async (formData: NewSchoolFormData) => {
      try {
        showSpinner()
        updateSchool({
          name: createJsonName(formData.englishName, formData.chineseName),
          shortCode: formData.shortCode,
          domain: formData.domain,
          mainSchoolAdminUserId: formData.mainSchoolAdminUserId,
          educationLevelId: formData.educationLevelId,
          rowVersion: editingSchool?.rowVersion ?? '',
        })
      } finally {
        hideSpinner()
      }
    },
    [
      createJsonName,
      editingSchool?.rowVersion,
      hideSpinner,
      showSpinner,
      updateSchool,
    ],
  )

  const handleDisableSchool = useCallback(
    async (school: School) => {
      try {
        showSpinner()
        disableSchool({
          id: school.id,
          rowVersion: school.rowVersion ?? '',
        })
      } finally {
        hideSpinner()
      }
    },
    [disableSchool, hideSpinner, showSpinner],
  )

  const handleEnableSchool = useCallback(
    async (school: School) => {
      try {
        showSpinner()
        enableSchool({
          id: school.id,
          rowVersion: school?.rowVersion ?? '',
        })
      } finally {
        hideSpinner()
      }
    },
    [enableSchool, hideSpinner, showSpinner],
  )

  const handleAddSchoolDialogOnSubmit = useCallback(
    async (value: string, formData?: NewSchoolFormData) => {
      if (value == 'disable' && editingSchool != null) {
        if (editingSchool?.isDisabled) {
          handleEnableSchool(editingSchool)
        } else {
          handleDisableSchool(editingSchool)
        }
      } else if (formData != null) {
        if (schoolDialogMode == 'add') {
          setIsSubmitting(true)
          handleCreateSchool(formData)
        } else {
          setIsSubmitting(true)
          handleUpdateSchool(formData)
        }
      }
    },
    [
      editingSchool,
      handleCreateSchool,
      handleDisableSchool,
      handleEnableSchool,
      handleUpdateSchool,
      schoolDialogMode,
    ],
  )

  const renderAddSchoolDialog = useMemo(
    () => (
      <AddSchoolDialog
        open={isAddSchoolDialogOpen}
        setOpen={setIsAddSchoolDialogOpen}
        isLoading={isFetching}
        isSubmitting={isSubmitting}
        educationLevelOptions={educationLevelOptions}
        form={form}
        mode={schoolDialogMode}
        extraActionButton={
          // eslint-disable-next-line no-nested-ternary
          schoolDialogMode == 'edit'
            ? editingSchool?.isDisabled
              ? renderEnableButton
              : renderDisableButton
            : undefined
        }
        schoolAdminOptions={schoolAdminOptions}
        onSubmit={handleAddSchoolDialogOnSubmit}
      />
    ),
    [
      isAddSchoolDialogOpen,
      isFetching,
      isSubmitting,
      educationLevelOptions,
      form,
      schoolDialogMode,
      editingSchool?.isDisabled,
      renderEnableButton,
      renderDisableButton,
      schoolAdminOptions,
      handleAddSchoolDialogOnSubmit,
    ],
  )

  const handleOpenAddSchoolDialog = useCallback(
    (mode: 'edit' | 'add', school?: School) => {
      if (mode == 'edit' && school == null) return
      setSchoolDialogMode(mode)
      setEditingSchool(school)
      if (mode == 'edit') {
        form.reset({
          chineseName: getDisplayName(school?.name, 'zh-Hant'),
          englishName: getDisplayName(school?.name, 'en'),
          educationLevelId: school?.educationLevelId,
          shortCode: school?.shortCode,
          domain: school?.domain,
          mainSchoolAdminUserEmail: school?.mainSchoolAdminUser?.email,
          mainSchoolAdminUserId: school?.mainSchoolAdminUserId,
          mode: 'edit',
        })
      } else {
        form.reset({ ...defaultNewSchool, mode: 'add' })
      }
      form.clearErrors()
      setIsAddSchoolDialogOpen(true)
    },
    [defaultNewSchool, form, getDisplayName],
  )

  const [isDisableConfirmationDialogOpen, setIsDisableConfirmationDialogOpen] =
    useState(false)

  const handleOpenDisableConfirmationDialog = useCallback((school: School) => {
    setEditingSchool(school)
    setIsDisableConfirmationDialogOpen(true)
  }, [])

  const handleDisableConfirmationDialogOnSelect = useCallback(
    async (value: boolean) => {
      if (value && editingSchool != null) {
        handleDisableSchool(editingSchool)
      }
      setIsDisableConfirmationDialogOpen(false)
    },
    [editingSchool, handleDisableSchool],
  )

  const renderDisableConfirmationDialog = useMemo(() => {
    const actions: DialogAction<boolean>[] = [
      {
        text: t('common.cancel'),
        type: 'outlined',
        value: false,
      },
      {
        text: t('schools.actions.disableAccount.title'),
        type: 'contained',
        color: 'sharpRed',
        value: true,
      },
    ]

    return (
      <Dialog
        open={isDisableConfirmationDialogOpen}
        title={t('schools.actions.disableAccount.confirmationDialog.title')}
        content={t('schools.actions.disableAccount.confirmationDialog.content')}
        actions={actions}
        onSelected={handleDisableConfirmationDialogOnSelect}
        maxWidth="md"
        fullWidth
      />
    )
  }, [
    handleDisableConfirmationDialogOnSelect,
    isDisableConfirmationDialogOpen,
    t,
  ])

  return {
    handleOpenAddSchoolDialog,
    handleOpenDisableConfirmationDialog,
    renderAddSchoolDialog,
    renderDisableConfirmationDialog,
    handleUpdateSchool,
    handleCreateSchool,
    handleEnableSchool,
    handleDisableSchool,
  }
}
