import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  EducationLevelResponsePayload,
  EducationLevelsQueryParameters,
  EducationLevelsResponsePayload,
  PostEducationLevelPayload,
  PutEducationLevelPayload,
} from './education-levels-api.type'

const PATH = 'education-levels'

export function getEducationLevels(param?: EducationLevelsQueryParameters) {
  return ApiCore.primary.get<EducationLevelsResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}

export function postEducationLevelsBulk(payloads: PostEducationLevelPayload[]) {
  return ApiCore.primary.post<EducationLevelResponsePayload>(`${PATH}/bulk`, {
    body: payloads,
  })
}

export function putEducationLevelById(
  id: string,
  payload: PutEducationLevelPayload,
) {
  return ApiCore.primary.put<EducationLevelResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}
