export const sidebar = {
  home: '主頁',
  myLessonPlan: '我的教案',
  disposedLessonPlan: '已作廢教案',
  lessonPlanSharing: '教案交流',
  consultManagement: '諮詢管理',
  consultFollowingUp: '跟進諮詢',
  publishManagement: '發佈審批',
  lessonPlanExamples: '教學示例',
  diStrategies: '適異教學策略',
  resources: '資源庫',
  contactUs: '簡介及聯絡',
  disclaimer: '免責聲明',
  lessonPlanSummary: '教案概覽',
  schoolSummary: '學校總覽',
  submitRecord: '提交紀錄',
  systemUser: '用户總覽',
  schoolUser: '校內用戶',
  manageContent: '內容管理',
  systemParameter: '系統數據',
}
