import { Button, Grid, Typography } from '@mui/material'
import { useDiStrategyDetails } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { useDisplayName } from '../../services'
import { useStores } from '../../stores'
import { sortByOrder } from '../../utilities'
import { TextField } from '../form'
import { NewFormData } from './master-data-list'
import { ResourceList } from './resource-list'
import { SelectTagDialog } from '../select-tag-dialog'

type Props = {
  control: Control<NewFormData, any>
  errors?: Partial<FieldErrorsImpl<NewFormData>>
  watch: UseFormWatch<NewFormData>
  setValue: UseFormSetValue<NewFormData>
  getValues: UseFormGetValues<NewFormData>
  reset: UseFormReset<NewFormData>
  selectedDataId: string
}

const fieldName = 'newData'
const fieldIndex = 0

const ExpandButton = styled(Button)``

export const DiStrategyDetailManagement = observer<Props>(
  ({ reset, selectedDataId, getValues, control, errors, watch, setValue }) => {
    const { t } = useTranslation()
    const { getDisplayName } = useDisplayName()
    const { data: diStrategyDetail, isSuccess } = useDiStrategyDetails(
      useStores,
      selectedDataId,
    )

    const [isVideosExpanded, setIsVideosExpanded] = useState(true)
    const [
      isFurtherReadingAndExamplesExpanded,
      setIsFurtherReadingAndExamplesExpanded,
    ] = useState(true)

    useEffect(() => {
      if (isSuccess) {
        const defaultData = getValues('newData')[0]
        reset({
          newData: [
            {
              ...defaultData,
              chineseName:
                getDisplayName(diStrategyDetail?.name, 'zh-Hant') ?? '',
              englishName: getDisplayName(diStrategyDetail?.name, 'en') ?? '',
              order: diStrategyDetail?.order,
              chineseDetail:
                getDisplayName(diStrategyDetail?.detail, 'zh-Hant') ?? '',
              englishDetail:
                getDisplayName(diStrategyDetail?.detail, 'en') ?? '',
              colorCode: diStrategyDetail?.colorCode,
              diStrategyClassId: diStrategyDetail?.diStrategyClassId,
              diStrategyTags:
                diStrategyDetail.diStrategyTags == null
                  ? []
                  : diStrategyDetail.diStrategyTags
                      .slice()
                      .sort(sortByOrder)
                      .map((data) => ({
                        id: data?.id,
                        colorCode: data.tag?.tagGroup?.colorCode,
                        label: data.tag?.displayName,
                        value: data.tagId,
                      })),
              displayVideo:
                diStrategyDetail.displayVideo == null
                  ? undefined
                  : {
                      chineseName:
                        getDisplayName(
                          diStrategyDetail.displayVideo.name,
                          'zh-Hant',
                        ) ?? '',
                      englishName:
                        getDisplayName(
                          diStrategyDetail.displayVideo.name,
                          'en',
                        ) ?? '',
                      order: diStrategyDetail.displayVideo.order,
                      resourceType: diStrategyDetail.displayVideo.resourceType,
                      file: {
                        url: diStrategyDetail.displayVideo.file?.url ?? '',
                        source:
                          diStrategyDetail.displayVideo.file?.source ?? '',
                      },
                      id: diStrategyDetail.displayVideo.id,
                    },
              videos:
                diStrategyDetail.videos == null
                  ? []
                  : diStrategyDetail.videos
                      .slice()
                      .sort(sortByOrder)
                      .map((data) => ({
                        chineseName: getDisplayName(data.name, 'zh-Hant') ?? '',
                        englishName: getDisplayName(data.name, 'en') ?? '',
                        order: data.order,
                        resourceType: data.resourceType,
                        file: {
                          url: data.file?.url ?? '',
                          source: data.file?.source ?? '',
                        },
                        id: data.id,
                      })),
              furtherReadingAndExamples:
                diStrategyDetail.furtherReadingAndExamples == null
                  ? []
                  : diStrategyDetail.furtherReadingAndExamples
                      .slice()
                      .sort(sortByOrder)
                      .map((data) => ({
                        chineseName: getDisplayName(data.name, 'zh-Hant') ?? '',
                        englishName: getDisplayName(data.name, 'en') ?? '',
                        order: data.order,
                        resourceType: data.resourceType,
                        file: {
                          url: data.file?.url ?? '',
                          source: data.file?.source ?? '',
                        },
                        id: data.id,
                      })),
            },
          ],
        })
      }
    }, [diStrategyDetail, getDisplayName, getValues, isSuccess, reset])

    return (
      <Grid container direction="column">
        <Grid container direction="row" wrap="nowrap">
          <Grid item xs={2} pr={2}>
            <Controller
              control={control}
              name={`${fieldName}.${fieldIndex}.order`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  label={t(
                    'masterData.editDiStrategyDetailDialog.field.order.label',
                  )}
                  placeholder={t(
                    'masterData.editDiStrategyDetailDialog.field.order.placeholder',
                  )}
                  error={errors?.newData?.[fieldIndex]?.order?.message}
                  isReserverForHelperText
                />
              )}
            />
          </Grid>
          <Grid item xs={4} pr={2}>
            <Controller
              control={control}
              name={`${fieldName}.${fieldIndex}.chineseName`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  label={t(
                    'masterData.editDiStrategyDetailDialog.field.chineseName.label',
                  )}
                  placeholder={t(
                    'masterData.editDiStrategyDetailDialog.field.chineseName.placeholder',
                  )}
                  error={errors?.newData?.[fieldIndex]?.chineseName?.message}
                  isReserverForHelperText
                />
              )}
            />
          </Grid>
          <Grid item xs={4} pr={2}>
            <Controller
              control={control}
              name={`${fieldName}.${fieldIndex}.englishName`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  label={t(
                    'masterData.editDiStrategyDetailDialog.field.englishName.label',
                  )}
                  placeholder={t(
                    'masterData.editDiStrategyDetailDialog.field.englishName.placeholder',
                  )}
                  error={errors?.newData?.[fieldIndex]?.englishName?.message}
                  isReserverForHelperText
                />
              )}
            />
          </Grid>

          <Grid item xs={2} pr={2}>
            <Controller
              control={control}
              name={`${fieldName}.${fieldIndex}.colorCode`}
              render={({ field }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  label={t(
                    'masterData.editDiStrategyDetailDialog.field.colorCode.label',
                  )}
                  placeholder={t(
                    'masterData.editDiStrategyDetailDialog.field.colorCode.placeholder',
                  )}
                  error={errors?.newData?.[fieldIndex]?.colorCode?.message}
                  isReserverForHelperText
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item mt={3}>
          <Controller
            control={control}
            name={`${fieldName}.${fieldIndex}.displayVideo.file.url`}
            render={({ field }) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                label={t(
                  'masterData.editDiStrategyDetailDialog.field.displayVideo.label',
                )}
                placeholder={t(
                  'masterData.editDiStrategyDetailDialog.field.displayVideo.placeholder',
                )}
                error={
                  errors?.newData?.[fieldIndex]?.displayVideo == null
                    ? undefined
                    : (errors?.newData?.[fieldIndex]?.displayVideo as any)?.file
                        ?.url?.message
                }
                isReserverForHelperText
              />
            )}
          />
        </Grid>
        <Grid item mt={3}>
          <Controller
            control={control}
            name={`${fieldName}.${fieldIndex}.chineseDetail`}
            render={({ field }) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                multiline
                label={t(
                  'masterData.editDiStrategyDetailDialog.field.chineseDetail.label',
                )}
                placeholder={t(
                  'masterData.editDiStrategyDetailDialog.field.chineseDetail.placeholder',
                )}
                error={errors?.newData?.[fieldIndex]?.chineseDetail?.message}
                isReserverForHelperText
              />
            )}
          />
        </Grid>
        <Grid item mt={3}>
          <Controller
            control={control}
            name={`${fieldName}.${fieldIndex}.englishDetail`}
            render={({ field }) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                multiline
                label={t(
                  'masterData.editDiStrategyDetailDialog.field.englishDetail.label',
                )}
                placeholder={t(
                  'masterData.editDiStrategyDetailDialog.field.englishDetail.placeholder',
                )}
                error={errors?.newData?.[fieldIndex]?.englishDetail?.message}
                isReserverForHelperText
              />
            )}
          />
        </Grid>
        <Grid item mt={3} xs={2}>
          <ExpandButton
            variant="text"
            startIcon={isVideosExpanded ? <Svg.ChevronUp /> : <Svg.ArrowDown />}
            onClick={() => setIsVideosExpanded(!isVideosExpanded)}
          >
            <Typography fontWeight={400}>
              {t('masterData.editDiStrategyDetailDialog.video')}
            </Typography>
          </ExpandButton>
        </Grid>
        {isVideosExpanded && (
          <Grid item mt={3}>
            <ResourceList control={control} type="videos" errors={errors} />
          </Grid>
        )}
        <Grid item mt={3} xs={2}>
          <ExpandButton
            variant="text"
            startIcon={
              isFurtherReadingAndExamplesExpanded ? (
                <Svg.ChevronUp />
              ) : (
                <Svg.ArrowDown />
              )
            }
            onClick={() =>
              setIsFurtherReadingAndExamplesExpanded(
                !isFurtherReadingAndExamplesExpanded,
              )
            }
          >
            <Typography fontWeight={400}>
              {t(
                'masterData.editDiStrategyDetailDialog.furtherReadingAndExamples',
              )}
            </Typography>
          </ExpandButton>
        </Grid>
        {isFurtherReadingAndExamplesExpanded && (
          <Grid item mt={3}>
            <ResourceList
              control={control}
              type="furtherReadingAndExamples"
              errors={errors}
            />
          </Grid>
        )}
        <Grid item mt={3} xs={2}>
          <Typography fontWeight={400}>
            {t('masterData.editDiStrategyDetailDialog.diStrategyTag')}
          </Typography>
        </Grid>
        <SelectTagDialog
          onSelect={(value) =>
            setValue(
              `${fieldName}.${fieldIndex}.diStrategyTags`,
              value.map(
                (it) =>
                  ({
                    value: it,
                  } as any),
              ),
            )
          }
          initialTagIds={
            (watch(`${fieldName}.${fieldIndex}.diStrategyTags`)
              ?.map((it) => it.value)
              .filter((it) => it != null) ?? []) as string[]
          }
        />
        {/* <Grid item mt={3}>
          <Controller
            control={control}
            name={`${fieldName}.${fieldIndex}.diStrategyTags`}
            render={({ field }) => (
              <AutoComplete
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                multiple
                placeholder={t(
                  'masterData.editDiStrategyDetailDialog.diStrategyTag',
                )}
                options={tagOptions}
                renderTags={(values, getTagProps) =>
                  values.map((option, index) => {
                    const selectedTag = tagOptions.find(
                      (tag) => tag.value === option.value,
                    )
                    const { key, ...tagProps } = getTagProps({ index })
                    return (
                      <Tag
                        label={selectedTag?.label}
                        colorCustomize={selectedTag?.colorCode}
                        key={option}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...tagProps}
                      />
                    )
                  })
                }
                onChange={(e, value) => {
                  field.onChange({ target: { value } })
                }}
              />
            )}
          />
        </Grid> */}
      </Grid>
    )
  },
)
