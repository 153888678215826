import { Box, Button, Grid, Typography } from '@mui/material'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Tag, TagType } from '@polyu-dip/models'
import { useMasterData } from '../../hooks'
import { TagGroupTag, Tag as TagUI } from '../tag'
import { Svg } from '../../assets'
import { Dialog, DialogAction } from '../dialog'

const MenuItem = styled(Button)<{ $isActive: boolean }>`
  text-align: left;
  padding: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  justify-content: flex-start;
  height: auto;
  font-size: 16px;
  font-weight: ${({ $isActive }) => ($isActive ? 600 : 400)};
  border-radius: 10px;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.tertiary : theme.palettes.general.white};
  .MuiButton-endIcon {
    margin-left: auto;
  }
  .MuiFormControlLabel-root {
    margin-right: 0px;
    margin-left: -8px;
  }
`

const TagName = styled(Typography)<{ selected?: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.palettes.general.white : theme.colors.primary};
  font-weight: 700;
  display: inline;
`

type Props = {
  onSelect(tagIds: string[]): void
  initialTagIds?: string[]
}

export const SelectTagDialog = memo(({ onSelect, initialTagIds }: Props) => {
  const { t } = useTranslation()

  const [activeTagType, setActiveTagType] = useState<TagType>()
  const [selectedTags, setSelectedTags] = useState<Tag[]>([])

  const [open, setOpen] = useState(false)

  const { tagTypes, tagGroups, tags } = useMasterData()

  const activeTagList = useMemo(
    () => tags.filter((it) => initialTagIds?.includes(it.id)) ?? [],
    [initialTagIds, tags],
  )

  useEffect(() => {
    if (open && activeTagList) {
      setSelectedTags(activeTagList)

      let tagTypeIndex = 0
      if (activeTagList?.length) {
        const tag = activeTagList[0]
        const tagTypeId = tagGroups.find(
          (it) => it.id === tag?.tagGroupId,
        )?.tagTypeId
        tagTypeIndex = tagTypes.findIndex((it) => it.id === tagTypeId)
      }

      setActiveTagType(tagTypes[tagTypeIndex])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTagIds, open])

  const handleSelectTag = useCallback(
    (tag: Tag) => {
      const existing = selectedTags.find((it) => it.id === tag.id)
      if (existing)
        setSelectedTags((list) => list.filter((it) => it.id !== tag.id))
      else {
        const existingGroup = selectedTags.find(
          (it) =>
            it.tagGroupId === tag.tagGroupId &&
            tag.tagGroup?.isMultiSelect === false,
        )
        if (existingGroup)
          setSelectedTags((list) => [
            ...list.filter((it) => it.tagGroupId !== tag.tagGroupId),
            tag,
          ])
        else setSelectedTags((list) => [tag, ...list])
      }
    },
    [selectedTags],
  )

  const onConfirmSelectTags = useCallback(
    (value: boolean) => {
      if (value) {
        onSelect(selectedTags.map((it) => it.id))
      }
      setOpen(false)
    },
    [onSelect, selectedTags],
  )

  const extractTagColor = useCallback(
    (tag: Tag) => {
      const selected = selectedTags.find((it) => it.id === tag.id)
      if (selected) return 'light-blue'
      return tag.tagGroup?.colorCode
    },
    [selectedTags],
  )

  const actions = useMemo((): DialogAction<boolean>[] => {
    return [
      {
        text: t('common.close'),
        type: 'outlined',
        value: false,
      },
      {
        text: t('common.confirm'),
        type: 'contained',
        color: 'blue',
        value: true,
      },
    ]
  }, [t])

  const computedTagTypes = useMemo(
    () =>
      tagTypes.filter(
        (it) =>
          !it.isDisabled ||
          activeTagList.some(
            (activeTag) => activeTag.tagGroup?.tagTypeId == it.id,
          ),
      ),
    [activeTagList, tagTypes],
  )

  const computedTagGroups = useMemo(
    () =>
      tagGroups.filter(
        (it) =>
          !it.isDisabled ||
          activeTagList.some((activeTag) => activeTag.tagGroupId == it.id),
      ),
    [activeTagList, tagGroups],
  )

  const computedTags = useMemo(
    () =>
      tags.filter(
        (it) =>
          !it.isDisabled ||
          activeTagList.some((activeTag) => activeTag.id == it.id),
      ),
    [activeTagList, tags],
  )

  return (
    <Box>
      <Box>
        <Grid flexDirection="row" alignItems="center" gap={2} container mt={1}>
          {activeTagList.map((tag) => (
            <Grid item key={tag.id}>
              <TagGroupTag tag={tag} />
            </Grid>
          ))}
          <Grid item>
            <Svg.Plus cursor="pointer" onClick={() => setOpen(true)} />
          </Grid>
        </Grid>
      </Box>
      <Dialog
        title={t(
          'lessonPlan.createForm.generalInformation.fields.classCharacteristics.label',
        )}
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        onSelected={(value: boolean) => onConfirmSelectTags(value)}
        actions={actions}
      >
        <Box>
          <Box>
            <Grid container columnSpacing={2} mt={5} flexWrap="nowrap">
              <Grid item width={250} flexShrink={0} overflow="auto">
                {computedTagTypes.map((it) => (
                  <MenuItem
                    key={it.id}
                    fullWidth
                    $isActive={it.id === activeTagType?.id}
                    onClick={() => setActiveTagType(it)}
                    endIcon={<Svg.ChevronRight />}
                    variant="text"
                  >
                    {it.displayName}
                  </MenuItem>
                ))}
              </Grid>
              <Grid item flex={1} flexShrink={0} overflow="auto">
                {computedTagGroups
                  .filter((it) => it.tagTypeId === activeTagType?.id)
                  ?.map((it) => (
                    <Grid container key={it.id} wrap="nowrap">
                      {computedTags
                        .filter((tag) => tag.tagGroupId === it.id)
                        .map((tag) => (
                          <Grid item key={tag.id} flexShrink={0} p={2}>
                            <TagUI
                              label={
                                <Typography>
                                  {it.displayName}
                                  <TagName
                                    selected={selectedTags.some(
                                      (selectedItem) =>
                                        selectedItem.id === tag.id,
                                    )}
                                  >
                                    {tag.displayName}
                                  </TagName>
                                </Typography>
                              }
                              colorCustomize={extractTagColor(tag)}
                              onClick={() => handleSelectTag(tag)}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
})
