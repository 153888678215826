import { ApiCore } from '@polyu-dip/api-core'
import { convertExpandParameters, convertSortParameters } from '../base'
import {
  NotificationQueryParameters,
  NotificationResponsePayload,
} from './notifications-api.types'

const PATH = '/notifications'

export function getMyNotifications(param?: NotificationQueryParameters) {
  return ApiCore.primary.get<NotificationResponsePayload>(`${PATH}/me`, {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: convertExpandParameters(param),
    },
  })
}
export function postIsRead(id: string) {
  return ApiCore.primary.get<NotificationResponsePayload>(`${PATH}/${id}/isRead`, {
   
  })
}
