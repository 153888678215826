export const common = {
  version: 'Version {{version}}',
  setting: 'Settings',
  logout: 'Logout',
  tbi: 'This part of function is yet to be developed ',
  forbidden: 'No access permissions',
  more: 'More',
  submit: 'Submit',
  yes: 'Yes',
  no: 'No',
  confirm: 'Confirm',
  cancel: 'Cancel',
  delete: 'Delete',
  add: 'Add',
  search: 'Search',
  clear: 'Clear',
  reset: 'Reset',
  change: 'Edit',
  warning: 'Warning',
  next: 'Next',
  approve: 'Approve',
  post: 'Propose',
  continue: 'Continue',
  new: 'Add',
  export: 'Export',
  duplicate: 'Copy',
  download: 'Download',
  close: 'Close',

  view: 'Review',
  edit: 'Revise',

  discard: 'Withdraw',
  back: 'Back',
  save: 'Save',

  to: 'From',
  from: 'to',

  ok: 'OK',

  today: 'Today',
  format: {
    datetime: '$t(common.format.date) HH:mm',
    date: 'DD MMM, YYYY',
    dayMonthNameYear: 'DD MMM, YYYY',
    datepicker: 'DD/MM/YYYY',
    dateTimePicker: 'DD/MM/YYYY HH:mm',
    dateBefore: '{{diff}} days ago',
  },

  retry: 'Retry',

  minute: 'Minutes',

  sort: 'Sort',

  noRecord: 'No available records',
  remark: 'Note: This operation cannot be restored',
  noOptions: 'No applicable options',
}
