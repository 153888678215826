import { ApiCore } from '@polyu-dip/api-core'
import { convertExpandParameters, convertSortParameters } from '../base'
import {
  LessonPlanConsultRequestExpandableParameter,
  LessonPlanConsultRequestQueryParameters,
  LessonPlanConsultRequestResponsePayload,
  LessonPlanConsultRequestsResponsePayload,
  PostLessonPlanConsultWithExpertPayload,
} from './lesson-plan-consult-requests-api.type'

const PATH = '/lesson-plan-consults'

export function getLessonPlanConsultRequestById(
  id: string,
  param?: LessonPlanConsultRequestExpandableParameter,
) {
  return ApiCore.primary.get<LessonPlanConsultRequestResponsePayload>(
    `${PATH}/${id}`,
    {
      param: {
        ...param,
        expand: convertExpandParameters(param),
      },
    },
  )
}

export function postLessonPlanConsultRequestAssignExpert(
  id: string,
  payload: PostLessonPlanConsultWithExpertPayload,
) {
  return ApiCore.primary.post<LessonPlanConsultRequestResponsePayload>(
    `${PATH}/${id}/assign-expert`,
    {
      body: payload,
    },
  )
}

export function getLessonPlanConsultRequestsAssigned(
  param?: LessonPlanConsultRequestQueryParameters,
) {
  return ApiCore.primary.get<LessonPlanConsultRequestsResponsePayload>(
    `${PATH}/assigned`,
    {
      param: {
        ...param,
        sort: convertSortParameters(param),
      },
    },
  )
}

export function postLessonPlanConsultComplete(id: string, rowVersion: string) {
  return ApiCore.primary.post<LessonPlanConsultRequestResponsePayload>(
    `${PATH}/${id}/complete`,
    {
      body: {
        rowVersion,
      },
    },
  )
}
