import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'

export const LessonPlanStatisticsModel = types
  .model('LessonPlanStatisticsModel', {
    viewCount: types.number,
    exportCount: types.number,
  })
  .extend(withUpdate)

export type LessonPlanStatistics = Instance<typeof LessonPlanStatisticsModel>
