import { observer } from 'mobx-react-lite'
import { forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'
import { OverviewTab } from './overview-tab'
// eslint-disable-next-line import/no-internal-modules
import { useFormContext } from 'react-hook-form'

import { LearningTargetTab } from './learning-target-tab'
import { AdvancedKnowledgeTab } from './advanced-knowledge'
import { TeachingProcessTab } from './teaching-process'
import { GeneralInformationFormContext } from './generalInformationContext'
import { GeneralInformationFormData } from '../../lesson-plan-form-provider'

const FormRoot = styled.form``

type GeneralInformationProps = {
  activeStep: number
  setActiveStep: (step: number) => void
}

export const GeneralInformation = observer<GeneralInformationProps>(
  forwardRef(({ activeStep, setActiveStep }, ref) => {
    const {
      handleSubmit,
      trigger,
      formState: { errors },
      getValues,
      setValue,
    } = useFormContext<GeneralInformationFormData>()

    useImperativeHandle(ref, () => ({
      triggerValidation() {
        return trigger()
      },
      getErrors() {
        return errors
      },
      getGeneralInformationValues() {
        return getValues()
      },
      trimArrayFields() {
        const trimmedLessonPlanStudentGroups = getValues(
          'lessonPlanStudentGroups',
        )
          .map((lessonPlanStudentGroup) => ({
            ...lessonPlanStudentGroup,
            lessonPlanTeachingGoals:
              lessonPlanStudentGroup.lessonPlanTeachingGoals
                .filter(
                  (lessonPlanTeachingGoal) =>
                    lessonPlanTeachingGoal?.description != null &&
                    lessonPlanTeachingGoal.description.length > 0,
                )
                .map((lessonPlanTeachingGoal, index) => ({
                  ...lessonPlanTeachingGoal,
                  order: index,
                })),
            lessonPlanTeachingSteps:
              lessonPlanStudentGroup.lessonPlanTeachingSteps
                .filter(
                  (lessonPlanTeachingStep) =>
                    (lessonPlanTeachingStep?.activity != null &&
                      lessonPlanTeachingStep.activity.length > 0) ||
                    (lessonPlanTeachingStep?.description != null &&
                      lessonPlanTeachingStep.description.length > 0) ||
                    (lessonPlanTeachingStep?.duration != null &&
                      lessonPlanTeachingStep.duration > 0) ||
                    (lessonPlanTeachingStep?.lessonPlanTeachingStepDiStrategies !=
                      null &&
                      lessonPlanTeachingStep?.lessonPlanTeachingStepDiStrategies
                        .length > 0),
                )
                .map((lessonPlanTeachingStep, index) => ({
                  ...lessonPlanTeachingStep,
                  order: index,
                })),
            lessonPlanExpectedOutcomes:
              lessonPlanStudentGroup.lessonPlanExpectedOutcomes
                .filter(
                  (lessonPlanExpectedOutcome) =>
                    lessonPlanExpectedOutcome?.description != null &&
                    lessonPlanExpectedOutcome.description.length > 0,
                )
                .map((lessonPlanExpectedOutcome, index) => ({
                  ...lessonPlanExpectedOutcome,
                  order: index,
                })),
          }))
          // trim grouping
          .filter((grp) => {
            const isGoalFilled =
              grp.lessonPlanTeachingGoals.filter((it) => it.description != null)
                .length > 0
            const isExpectedOutcomeFilled =
              grp.lessonPlanExpectedOutcomes.filter(
                (it) => it.description != null,
              ).length > 0
            const isTeachingStepFilled =
              grp.lessonPlanTeachingSteps.filter(
                (it) =>
                  it.activity != null ||
                  (it.description != null && it.description.length > 0) ||
                  it.duration != null,
              ).length > 0

            return (
              grp.groupCharacteristic !== '' ||
              isGoalFilled ||
              isExpectedOutcomeFilled ||
              isTeachingStepFilled
            )
          })
          .map((studentGroup, index) => ({
            ...studentGroup,
            order: index,
          }))

        // Here add placeholder dummy item
        if (trimmedLessonPlanStudentGroups.length === 0) {
          trimmedLessonPlanStudentGroups.push({
            groupCharacteristic: '',
            lessonPlanTeachingGoals: [],
            lessonPlanTeachingSteps: [],
            lessonPlanExpectedOutcomes: [],
            order: 1,
          })
        }

        trimmedLessonPlanStudentGroups.forEach((it) => {
          if (it.lessonPlanTeachingGoals.length === 0)
            it.lessonPlanTeachingGoals.push({
              description: '',
              order: 1,
            })
          if (it.lessonPlanExpectedOutcomes.length === 0)
            it.lessonPlanExpectedOutcomes.push({
              description: '',
              order: 1,
            })
          if (it.lessonPlanTeachingSteps.length === 0) {
            it.lessonPlanTeachingSteps.push({
              order: 1,
              lessonPlanTeachingStepDiStrategies: [],
              activity: '',
              description: '',
              duration: undefined,
              resource: '',
            })
          }
        })
        setValue('lessonPlanStudentGroups', trimmedLessonPlanStudentGroups)
      },
    }))

    const onSubmit = (data: GeneralInformationFormData) => {
      alert(JSON.stringify(data))
    }

    return (
      <GeneralInformationFormContext.Provider
        value={{ activeStep, setActiveStep }}
      >
        <FormRoot onSubmit={handleSubmit(onSubmit)}>
          {activeStep === 0.1 && <OverviewTab />}
          {activeStep === 0.2 && <LearningTargetTab />}
          {activeStep === 0.3 && <AdvancedKnowledgeTab />}
          {activeStep === 0.4 && <TeachingProcessTab />}
        </FormRoot>
      </GeneralInformationFormContext.Provider>
    )
  }),
)
