import { convertNullToUndefined } from './convert-null'

export function upsertList(targetList: any[], payload: { id: any }) {
  const existing = targetList.find(
    (item: { id: any }) => item.id === payload.id,
  )
  if (existing) {
    existing.update(payload)
    return existing
  }
  targetList.push(convertNullToUndefined(payload))
  return targetList[targetList.length - 1]
}
