import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { LessonPlanExampleDetailPage } from './lesson-plan-example-detail-page'

export const LessonPlanPublishCopyDetailPage = observer(() => {
  const { lessonPlanId } = useParams<{
    lessonPlanId: string
  }>()

  return (
    <LessonPlanExampleDetailPage lessonPlanId={lessonPlanId} isPublishCopy />
  )
})
