import { Box, Grid } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { memo } from 'react'
import {
  Control,
  Controller,
  FieldErrorsImpl,
  useFieldArray,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Svg } from '../../assets'
import { Dropdown, SelectOption, TextField } from '../form'

const fieldName = 'newResources'

export type NewResourceFormData = {
  newResources: {
    title: string
    resourceTypeId: string
    url: string
  }[]
}

type Props = {
  control: Control<NewResourceFormData, any>
  errors?: Partial<FieldErrorsImpl<NewResourceFormData>>
  resourceTypeOptions: SelectOption[]
  allowAppendRow?: boolean
  allowDeleteRow?: boolean
}

export const NewResourceList = memo<Props>(
  ({
    control,
    errors,
    resourceTypeOptions,
    allowAppendRow,
    allowDeleteRow,
  }) => {
    const { t } = useTranslation()
    const {
      fields: newResourceFields,
      remove: removeNewResourceField,
      append: appendNewResourceField,
    } = useFieldArray({
      control,
      name: fieldName,
    })

    const dropdownValueOnOpen = useComputed(() => {
      return {
        resourceTypeId: newResourceFields?.[0]?.resourceTypeId,
      }
    }, [fieldName])

    return (
      <Box>
        {newResourceFields.map((newResourceField, index) => (
          <Grid key={newResourceField.id} container>
            <Grid item xs={5} pr={5}>
              <Controller
                control={control}
                name={`${fieldName}.${index}.title`}
                render={({ field }) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    placeholder={t(
                      'resources.createOnlineResource.field.title.placeholder',
                    )}
                    error={errors?.newResources?.[index]?.title?.message}
                    isReserverForHelperText
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} pr={5}>
              <Controller
                control={control}
                name={`${fieldName}.${index}.url`}
                render={({ field }) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    placeholder={t(
                      'resources.createOnlineResource.field.url.placeholder',
                    )}
                    error={errors?.newResources?.[index]?.url?.message}
                    isReserverForHelperText
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} pr={5}>
              <Controller
                control={control}
                name={`${fieldName}.${index}.resourceTypeId`}
                render={({ field }) => (
                  <Dropdown
                    placeholder={t(
                      'resources.createOnlineResource.field.type.placeholder',
                    )}
                    options={resourceTypeOptions
                      .filter(
                        (option) =>
                          !option.disabled ||
                          option.value == dropdownValueOnOpen.resourceTypeId,
                      )
                      .map((option) => ({
                        ...option,
                        disabled: false,
                      }))}
                    error={
                      errors?.newResources?.[index]?.resourceTypeId?.message
                    }
                    isReserverForHelperText
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />
            </Grid>
            {(allowAppendRow ?? true) && (
              <Grid container justifyContent="flex-end" xs={1}>
                <Grid item>
                  <Svg.Close
                    cursor="pointer"
                    onClick={() => removeNewResourceField(index)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
        {(allowDeleteRow ?? true) && (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Svg.Plus
                cursor="pointer"
                onClick={() =>
                  appendNewResourceField({
                    title: '',
                    url: '',
                    resourceTypeId: '',
                  })
                }
              />
            </Grid>
          </Grid>
        )}
      </Box>
    )
  },
)
