import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'

export const LessonPlanExpectedOutcomeModel = BaseEntityModel.named(
  'LessonPlanExpectedOutcomeModel',
)
  .props({
    id: types.string,
    description: types.string,
    order: types.number,
  })
  .extend(withUpdate)

export type LessonPlanExpectedOutcome = Instance<
  typeof LessonPlanExpectedOutcomeModel
>
