import { ReactNode, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useComputed } from '@polyu-dip/helpers'
import { useLessonPlanFormContext } from './use-lesson-plan-form-context'
import { useStores } from '../../stores'
import { LessonPlanStudentGroup, LessonPlanTag } from '@polyu-dip/models'
import { sortByOrder } from '../../utilities'
import { useTranslation } from 'react-i18next'
import { signedInteger } from '@polyu-dip/utilities'
import { Strategy, useFormErrorTranslationTrigger } from '../../hooks'

type LessonPlanAttachmentData = {
  attachmentName: string
  resourceTypeId?: string
  file: {
    url: string
    source: string
  }
  order: number
}

export type UploadDocumentFormData = {
  fileResources: LessonPlanAttachmentData[]
  linkResources: LessonPlanAttachmentData[]
}

export type ExpectedOutcome = {
  description?: string
  order: number
}

export type TeachingGoal = {
  description?: string
  order: number
}

export type TeachingStep = {
  duration?: number
  activity?: string
  description?: string
  order: number
  lessonPlanTeachingStepDiStrategies: {
    diStrategyId?: string
    order?: number
  }[]
  resource?: string
}

export type StudentGroup = {
  groupCharacteristic?: string
  order: number
  lessonPlanExpectedOutcomes: ExpectedOutcome[]
  lessonPlanTeachingGoals: TeachingGoal[]
  lessonPlanTeachingSteps: TeachingStep[]
}

export type GeneralInformationFormData = {
  overview: {
    educationLevelId: string
  }
  classLevelId?: string
  subjectId: string
  learningUnit?: string
  teachingTopic: string
  sectionTotal: number
  sectionDuration: number
  academicYear: number
  teachingClass?: string
  classCharacteristicTags: string[]
  isMultiGroup?: boolean
  lessonPlanStudentGroups: StudentGroup[]
  requiredKnowledge?: string
  expectedDifficulty?: string
  remark?: string
  followUp?: string
  selfLearningResource?: string

  lessonPlanDiStrategies: Strategy[]

  diStrategyTeachingConceptExcerpt?: string
  schoolBackgroundInformation?: string
}

type LessonPlanFormProviderPropType = {
  currentUserEducationLevelId?: string
  children: ReactNode
}

export const LessonPlanFormProvider: React.FunctionComponent<
  LessonPlanFormProviderPropType
> = ({ currentUserEducationLevelId, children }) => {
  const { t } = useTranslation()
  const schema: yup.SchemaOf<GeneralInformationFormData> = useMemo(
    () =>
      yup
        .object({
          subjectId: yup.string().required(t('error.required')),
          learningUnit: yup.string(),
          classLevelId: yup.string().required(t('error.required')),
          teachingClass: yup.string(),
          teachingTopic: yup.string().required(t('error.required')),
          sectionTotal: yup
            .number()
            .required(t('error.required'))
            .integer(t('error.integer'))
            .min(0, t('error.minInteger', { minValue: 0 }))
            .max(
              signedInteger,
              t('error.maxInteger', { maxValue: signedInteger }),
            ),
          sectionDuration: yup
            .number()
            .required(t('error.required'))
            .integer(t('error.integer'))
            .min(0, t('error.minInteger', { minValue: 0 }))
            .max(
              signedInteger,
              t('error.maxInteger', { maxValue: signedInteger }),
            ),
          academicYear: yup.number().required(t('error.required')),
          requiredKnowledge: yup.string(),
          expectedDifficulty: yup.string(),
          remark: yup.string(),
          followUp: yup.string(),
          selfLearningResource: yup.string(),
          lessonPlanStudentGroups: yup
            .array(
              yup.object({
                groupCharacteristic: yup.string().when('isMultiGroup', {
                  is: (isMultiGroup: boolean) => isMultiGroup,
                  then: yup.string(),
                  otherwise: yup.string(),
                }),
                order: yup.number().required(t('error.required')),
                lessonPlanExpectedOutcomes: yup
                  .array(
                    yup.object({
                      description: yup.string(),
                      order: yup.number().required(t('error.required')),
                    }),
                  )
                  .required(t('error.required')),
                lessonPlanTeachingGoals: yup
                  .array(
                    yup.object({
                      description: yup.string(),
                      order: yup.number().required(t('error.required')),
                    }),
                  )
                  .required(t('error.required')),
                lessonPlanTeachingSteps: yup
                  .array(
                    yup.object({
                      duration: yup
                        .number()
                        .integer(t('error.integer'))
                        .min(0, t('error.minInteger', { minValue: 0 }))
                        .max(
                          signedInteger,
                          t('error.maxInteger', { maxValue: signedInteger }),
                        ),
                      activity: yup.string(),
                      description: yup.string(),
                      order: yup.number().required(),
                      lessonPlanTeachingStepDiStrategies: yup.array(
                        yup.object({
                          diStrategyId: yup.string(),
                          order: yup.number(),
                        }),
                      ),
                      resource: yup.string(),
                    }),
                  )
                  .required(t('error.required')),
              }),
            )
            .required(t('error.required')),
          classCharacteristicTags: yup.array().min(1, t('error.required')),
          createdDateTime: yup.string(),
          isMultiGroup: yup.boolean().required(t('error.required')),

          overview: yup.object({
            educationLevelId: yup.string().required(t('error.required')),
          }),

          lessonPlanDiStrategies: yup.array(
            yup.object({
              diStrategyId: yup.string().required(t('error.required')),
              reason: yup.string(),
              order: yup.number(),
            }),
          ),
          diStrategyTeachingConceptExcerpt: yup.string(),
          schoolBackgroundInformation: yup.string(),
        })
        .required(t('error.required')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  const { lessonPlanFormData, setLessonPlanFormData } =
    useLessonPlanFormContext()

  const { masterDataStore } = useStores()

  const classLevelOptions = useComputed(
    () =>
      masterDataStore.classLevels.map((classLevel) => ({
        value: classLevel.id,
        educationLevelId: classLevel.learningPhase?.educationLevelId,
        label: classLevel.displayName,
      })),
    [masterDataStore.classLevels],
  )

  const defaultEducationLevelId = useComputed(() => {
    const classLevelId = lessonPlanFormData.classLevelId
    if (!classLevelId?.length) {
      if (currentUserEducationLevelId != null) {
        return currentUserEducationLevelId
      }
      return
    }
    const classLevel = masterDataStore.classLevels
      .map((it) => ({
        ...it,
        learningPhase: it.learningPhase,
      }))
      .find((it) => it.id === classLevelId)
    return classLevel?.learningPhase?.educationLevelId
  }, [
    lessonPlanFormData.classLevelId,
    masterDataStore.classLevels,
    masterDataStore.educationLevels,
  ])

  const lessonPlanStudentGroups = useComputed(() => {
    const studentGroups = lessonPlanFormData.lessonPlanStudentGroups
    if (studentGroups == null) return []
    return studentGroups.map((studentGroup: LessonPlanStudentGroup) => ({
      ...studentGroup,
      lessonPlanTeachingSteps: studentGroup?.lessonPlanTeachingSteps
        .slice()
        .sort(sortByOrder),
      lessonPlanTeachingGoals: studentGroup?.lessonPlanTeachingGoals
        .slice()
        .sort(sortByOrder),
      lessonPlanExpectedOutcomes: studentGroup?.lessonPlanExpectedOutcomes
        .slice()
        .sort(sortByOrder),
    }))
  }, [lessonPlanFormData.lessonPlanStudentGroups])

  const formMethods = useForm<GeneralInformationFormData>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      overview: {
        educationLevelId: defaultEducationLevelId,
      },
      classLevelId: classLevelOptions.find(
        (it) => it.value === lessonPlanFormData.classLevelId,
      )?.value,
      subjectId: lessonPlanFormData.subjectId?.length
        ? lessonPlanFormData.subjectId
        : undefined,
      learningUnit: lessonPlanFormData.learningUnit?.length
        ? lessonPlanFormData.learningUnit
        : undefined,
      teachingTopic: lessonPlanFormData.teachingTopic?.length
        ? lessonPlanFormData.teachingTopic
        : undefined,
      sectionTotal: lessonPlanFormData.sectionTotal,
      sectionDuration: lessonPlanFormData.sectionDuration,
      academicYear: lessonPlanFormData.academicYear,
      teachingClass: lessonPlanFormData.teachingClass?.length
        ? lessonPlanFormData.teachingClass
        : undefined,
      classCharacteristicTags: lessonPlanFormData.lessonPlanTags?.map(
        (it: LessonPlanTag) => it.tagId,
      ),
      isMultiGroup: lessonPlanFormData.isMultiGroup,
      lessonPlanStudentGroups: lessonPlanStudentGroups?.length
        ? lessonPlanStudentGroups.slice().sort(sortByOrder)
        : [
            {
              order: 1,
              groupCharacteristic: '',
              lessonPlanTeachingGoals: [
                {
                  description: '',
                  order: 1,
                },
              ],
              lessonPlanExpectedOutcomes: [{ description: '', order: 1 }],
              lessonPlanTeachingSteps: [
                {
                  duration: undefined,
                  activity: '',
                  description: '',
                  order: 1,
                  lessonPlanTeachingStepDiStrategies: [],
                  resource: '',
                },
              ],
            },
          ],
      requiredKnowledge: lessonPlanFormData.requiredKnowledge?.length
        ? lessonPlanFormData.requiredKnowledge
        : undefined,
      expectedDifficulty: lessonPlanFormData.expectedDifficulty,
      remark: lessonPlanFormData.remark,
      followUp: lessonPlanFormData.followUp,
      selfLearningResource: lessonPlanFormData.selfLearningResource,

      lessonPlanDiStrategies: lessonPlanFormData.lessonPlanDiStrategies ?? [],

      diStrategyTeachingConceptExcerpt:
        lessonPlanFormData.diStrategyTeachingConceptExcerpt,
      schoolBackgroundInformation:
        lessonPlanFormData.schoolBackgroundInformation,
    },
  })

  useFormErrorTranslationTrigger(
    formMethods.formState.errors,
    formMethods.trigger,
  )

  useEffect(() => {
    const subscription = formMethods.watch((values, { name }) => {
      if (name == null) return
      const newFormData = { ...lessonPlanFormData, ...values }
      if (name === 'classCharacteristicTags') {
        const classCharacteristicTags =
          values.classCharacteristicTags?.map((tagId) => ({
            tagId,
          })) ?? []
        newFormData.lessonPlanTags = [...classCharacteristicTags]
      }
      if (name === 'overview.educationLevelId') {
        newFormData.classLevelId = ''
      }
      if (name === 'classLevelId') {
        const selectedClassLevel = values.classLevelId
        const selectedEducationLevelId = values.overview?.educationLevelId
        if (selectedClassLevel == null || selectedEducationLevelId == null)
          return

        const classLevelId = classLevelOptions.find(
          (it) =>
            it.educationLevelId === selectedEducationLevelId &&
            it.label === selectedClassLevel,
        )?.value
        newFormData.classLevelId = classLevelId
      }
      if (name === 'academicYear') {
        if (values.academicYear == null) return
        newFormData.academicYear = +values.academicYear
      }
      newFormData[name] = formMethods.getValues(name)
      setLessonPlanFormData(newFormData)
    })
    return () => subscription.unsubscribe()
  }, [
    classLevelOptions,
    formMethods,
    lessonPlanFormData,
    setLessonPlanFormData,
  ])
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <FormProvider {...formMethods}>{children}</FormProvider>
}
