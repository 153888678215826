import { Button, Chip, Typography } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'
import {
  useLessonPlansOwned,
  useLessonPlansOwnedPublished,
} from '@polyu-dip/queries'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useComputed } from '@polyu-dip/helpers'
import { Svg } from '../../assets'
import { ListPage, Tab, Table, Tabs, useRowCountState } from '../../components'
import { contentPaths } from '../../content-paths'
import { useStores } from '../../stores'
import { observer } from 'mobx-react-lite'
import { PaginationParameters } from '@polyu-dip/apis'
import { useMasterDataInit } from '../../hooks'
import { useLessonPlansColumn } from './use-lesson-plan-column'

const StyledChip = styled(Chip)`
  font-size: 14px;
  line-height: 120%;
  height: auto;
  margin-left: 12px;

  .MuiChip-label {
    padding: 0px 8px;
  }
`

const FlexRow = styled.div`
  display: flex;
  direction: row;
  white-space: nowrap;
`

const PAGINATION_LIMIT = 15

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

enum TabMenuEnum {
  MY_PLAN = 'MY_PLAN',
  PUBLIC_PLAN = 'PUBLIC_PLAN',
}

export const MyLessonPlan = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isAllMasterDataFetchSuccess } = useMasterDataInit()
  const { renderColumn } = useLessonPlansColumn()

  const [activeTab, setActiveTab] = useState<TabMenuEnum>(TabMenuEnum.MY_PLAN)
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [ownedSort, setOwnedSort] = useState<GridSortModel>()
  const [publishedSort, setPublishedSort] = useState<GridSortModel>()

  const { data: ownedLessonPlansData, isLoading: isOwnedLessonPlanLoading } =
    useLessonPlansOwned(useStores, {
      expand: ['ClassLevel'],
      sort: ownedSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      ...pagination,
    })
    
  const {
    data: ownedLessonPlanPublicCopies,
    isLoading: isPublicCopiesLoading,
  } = useLessonPlansOwnedPublished(useStores, {
    expand: ['ClassLevel'],
    sort: publishedSort?.map((it) => ({
      direction: it.sort ?? 'desc',
      parameter: it.field,
    })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
    ...pagination,
  })
  
  const totalRecord = useMemo(() => {
    return activeTab === TabMenuEnum.MY_PLAN
      ? ownedLessonPlansData?.total
      : ownedLessonPlanPublicCopies?.total
  }, [activeTab, ownedLessonPlansData, ownedLessonPlanPublicCopies])

  const { rowCountState } = useRowCountState(totalRecord)

  const computedLessonPlanData = useComputed(
    () =>
      (
        (activeTab === TabMenuEnum.MY_PLAN
          ? ownedLessonPlansData?.data
          : ownedLessonPlanPublicCopies?.data) ?? []
      ).map((it) => ({
        ...it,
        classLevel: {
          ...it.classLevel,
        },
        subject: {
          ...it.subject,
        },
        latestLessonPlanComment: {
          ...it.latestLessonPlanComment,
        },
        latestSourcePublishRequest: {
          ...it.latestSourcePublishRequest,
        },
      })),
    [ownedLessonPlansData, ownedLessonPlanPublicCopies, activeTab],
  )

  const handleTabChange = useCallback((event: any, value: any) => {
    setActiveTab(value)
    setPagination(DEFAULT_PAGINATION)
  }, [])

  return (
    <ListPage
      sectionTitleProps={{
        title: t('myLessonPlan.title'),
      }}
      sectionTitleChildren={
        <Link to={contentPaths.lessonPlans('new', 'edit')}>
          <Button startIcon={<Svg.Create />} color="lightRed">
            {t('myLessonPlan.actions.createLessonPlan')}
          </Button>
        </Link>
      }
    >
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab
          value={TabMenuEnum.MY_PLAN}
          label={
            <FlexRow>
              <Typography>{t('myLessonPlan.title')} </Typography>
              <StyledChip
                label={ownedLessonPlansData?.total ?? 0}
                color={
                  activeTab === TabMenuEnum.MY_PLAN ? 'primary' : 'default'
                }
              />
            </FlexRow>
          }
          $width={320}
        />
        <Tab
          value={TabMenuEnum.PUBLIC_PLAN}
          label={
            <FlexRow>
              <Typography>{t('myLessonPlan.publicVersion')} </Typography>

              <StyledChip
                label={ownedLessonPlanPublicCopies?.total ?? 0}
                color={
                  activeTab === TabMenuEnum.PUBLIC_PLAN ? 'primary' : 'default'
                }
              />
            </FlexRow>
          }
          $width={320}
        />
      </Tabs>
      <Table
        columns={renderColumn(activeTab)}
        rows={computedLessonPlanData}
        pageSize={PAGINATION_LIMIT}
        loading={
          !isAllMasterDataFetchSuccess ||
          (activeTab === TabMenuEnum.MY_PLAN
            ? isOwnedLessonPlanLoading
            : isPublicCopiesLoading)
        }
        paginationMode="server"
        page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
        hideFooterPagination={
          rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
        }
        rowCount={rowCountState}
        sortModel={
          activeTab === TabMenuEnum.MY_PLAN ? ownedSort : publishedSort
        }
        onSortModelChange={
          activeTab === TabMenuEnum.MY_PLAN ? setOwnedSort : setPublishedSort
        }
        onRowClick={({ row }) =>
          activeTab === TabMenuEnum.MY_PLAN
            ? navigate(contentPaths.lessonPlans(row.id, 'view'))
            : navigate(contentPaths.lessonPlanPublishCopy(row.id))
        }
        onPageChange={(page) =>
          setPagination((old) => ({
            ...old,
            offset: page * PAGINATION_LIMIT,
          }))
        }
      />
    </ListPage>
  )
})
