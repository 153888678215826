import { memo } from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  activeValue: number | string
  value: number | string
}

export const TabPanel = memo((props: TabPanelProps) => {
  const { children, value, activeValue, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== activeValue}
      id={`simple-tabpanel-${activeValue}`}
      {...other}
    >
      {value === activeValue && children}
    </div>
  )
})
