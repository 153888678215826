import { memo } from 'react'
import { Tabs as MuiTabs, TabsProps } from '@mui/material'
import styled from 'styled-components'
import { colorPalette } from '@polyu-dip/theme'

function calculateBorderColor($printable?: boolean) {
  if ($printable) return colorPalette.general.borderStroke
  return colorPalette.general.white
}

const StyledTabs = styled(MuiTabs)<OwnProps>`
  min-height: auto;
  margin-top: -4px;
  -webkit-print-color-adjust: exact;

  .MuiTabs-scroller {
    padding-top: 4px;
  }

  .MuiTabs-indicator {
    height: 4px;
    background-color: ${({ theme, $activeColor, $activeIndicatorColor }) =>
      $activeIndicatorColor ??
      $activeColor ??
      theme.palettes.semantic.astronautBlue};
    ${(props) =>
      props.$activeBarPosition === 'top' ? 'top: 1px' : 'bottom: 0'};
  }

  .MuiTab-root.Mui-selected {
    color: ${({ theme, $activeColor }) =>
      $activeColor ?? theme.palettes.semantic.astronautBlue};
    background-color: ${({ theme }) => theme.palettes.general.white};
    border-bottom-color: ${({ theme }) => theme.palettes.general.white};
  }

  .Mui-selected {
    &.MuiTab-root:first-child {
      border-left-color: ${({ $printable }) =>
        calculateBorderColor($printable)};
    }
    &.MuiTab-root:last-child {
      border-right-color: ${({ $printable }) =>
        calculateBorderColor($printable)};
    }
  }
`

type OwnProps = {
  $activeColor?: string
  $activeIndicatorColor?: string
  $activeBarPosition?: 'top' | 'bottom'
  $printable?: boolean
}

type Props = TabsProps & OwnProps

export const Tabs = memo((props: Props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledTabs {...props} />
})
