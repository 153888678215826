import { Popover } from '@mui/material'
import { useCurrentUserProfile } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { contentPaths } from '../../content-paths'
import { useApiErrorHandle } from '../../hooks'
import { useLogout } from '../../services'
import { useStores } from '../../stores'
import { Avatar } from '../avatar'
import { PageTitle } from '../text'

const ProfileDialog = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
`

const ProfilePic = styled.div`
  border-radius: 50%;
  margin: 0 4px;
  display: flex;
`

const Username = styled.div`
  color: #74938a;
  margin: 0 4px;
  width: 100%;
  text-align: center;
`

const MenuItem = styled.div(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.palettes.general.background};
  }
`,
)

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.01), 0px 1px 4px rgba(0, 0, 0, 0.1),
      0px 10px 16px rgba(0, 0, 0, 0.01);
    border-radius: 0px 0px 8px 8px;
  }
`

const Label = styled.span`
  margin-left: 4px;
`

const StyledArrow = styled(Svg.ArrowDown)<{ open?: boolean }>(({ open }) => ({
  width: 30,
  transform: `rotate(${open ? '180deg' : '0deg'})`,
  transition: 'all',
}))

export const UserPopover = observer(() => {
  const { t } = useTranslation()
  const { userProfileStore } = useStores()
  const logout = useLogout()
  const navigate = useNavigate()
  const { standardErrorHandler } = useApiErrorHandle()

  const [popOverHtmlElement, setPopOverHtmlElement] =
    useState<HTMLDivElement | null>(null)
  const handleLogout = useCallback(async () => {
    await logout()
    navigate(contentPaths.home())
  }, [logout, navigate])

  const isPopOverOpen = useMemo(
    () => popOverHtmlElement != null,
    [popOverHtmlElement],
  )

  const id = useMemo(
    () => (isPopOverOpen ? 'user-popover' : undefined),
    [isPopOverOpen],
  )

  const { data: currentUser, refetch } = useCurrentUserProfile(
    useStores,
    {
      expand: ['Role', 'School'],
    },
    {
      onSuccess(res) {
        userProfileStore.upsertUserProfile({
          id: res.id,
          email: res.email,
          roleId: res.roleId,
          schoolId: res.schoolId,
          isDisabled: res.isDisabled,
          name: res.name,
          createdByUserId: res.createdByUserId,
          createdDateTime: res.createdDateTime,
          lastModifiedByUserId: res.lastModifiedByUserId,
          lastModifiedDateTime: res.lastModifiedDateTime,
          rowVersion: res.rowVersion,
        })
      },
      onError: (error) => {
        standardErrorHandler(error, {
          onDismiss: logout,
          onRetry: refetch,
          errorFormatter: {
            formatted: (apiError) => {
              return {
                title: t('error.fetchEntityFailure', {
                  entityName: t('users.entityName'),
                }),
                content: t('error.default.content', {
                  error: apiError.kind,
                }),
                actions: [
                  { text: t('common.logout'), value: '' },
                  { text: t('error.action.retry'), value: 'retry' },
                ],
              }
            },
          },
        })
      },
    },
  )

  return (
    <Fragment>
      <ProfileDialog
        aria-describedby={id}
        onClick={(e) => setPopOverHtmlElement(e.currentTarget)}
      >
        <ProfilePic>
          <Avatar name={currentUser?.displayName} />
        </ProfilePic>
        <Username>
          <PageTitle>
            {t('topBar.hi', { username: currentUser?.displayName })}
          </PageTitle>
        </Username>
        <StyledArrow open={isPopOverOpen} />
      </ProfileDialog>
      <StyledPopover
        id={id}
        open={isPopOverOpen}
        onClose={() => setPopOverHtmlElement(null)}
        anchorEl={popOverHtmlElement}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <StyledLink
          to={contentPaths.myProfile()}
          onClick={() => setPopOverHtmlElement(null)}
        >
          <MenuItem>
            <Svg.Setting />
            <Label>{t('topBar.accountSetting')}</Label>
          </MenuItem>
        </StyledLink>

        <MenuItem onClick={handleLogout}>
          <Svg.Logout />
          <Label>{t('login.logout')}</Label>
        </MenuItem>
      </StyledPopover>
    </Fragment>
  )
})
