import { useMsal } from '@azure/msal-react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { PostCreateBlobStorageContainerResponsePayload } from '@polyu-dip/apis'
import { LessonPlanComment } from '@polyu-dip/models'
import {
  useLessonPlan,
  useLessonPlans,
  useLessonPlanSharings,
  useLessonPlansOwned,
  useRoles,
  useSchools,
  useUpdateLessonPlan,
  useCurrentUserProfile,
  useUsers,
  useLessonPlansShared,
  useCreateLessonPlanComment,
  useUpdateLessonPlanCommentById,
  useDeleteLessonPlanComment,
  clearLessonPlansQueryCaches,
  useLessonPlanComments,
  useCreateBlobStorageContainer,
  useOutstandingLessonPlanRequests,
  useMyNotifications,
  useTransferLessonPlan,
  useMySchoolUsers,
  useUser,
} from '@polyu-dip/queries'
import { useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useStores } from '../../stores'
import { useTestInit } from './use-test-init'

type ApiTestContentProp = {
  title: string
  data: any
}

const ApiTestContent = observer<ApiTestContentProp>(({ title, data }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ color: data == null ? 'red' : 'black' }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <pre>{JSON.stringify(data, null, '\t')}</pre>
      </AccordionDetails>
    </Accordion>
  )
})

/**
 * A page for testing api integrations
 * */
type TestPropType = {}
export const ApiTestPage = observer<TestPropType>(() => {
  useTestInit()
  const queryClient = useQueryClient()

  const [targetComment, setTargetComment] = useState<LessonPlanComment>()
  const [blobStorageData, setBlobStorageData] =
    useState<PostCreateBlobStorageContainerResponsePayload | null>()
  const { data: currentUserProfile } = useCurrentUserProfile(useStores, {
    expand: ['School', 'School.Users'],
  })
  const { data: mySchoolUserData } = useMySchoolUsers(useStores)
  const { data: userData } = useUsers(useStores)
  const { data: roleData } = useRoles(useStores)
  const { data: schoolData } = useSchools(useStores)
  const { data: lessonPlanData } = useLessonPlans(useStores)
  const { data: lessonPlanOwnedData } = useLessonPlansOwned(useStores, {})
  const { data: lessonPlansSharedData } = useLessonPlansShared(useStores)
  const { masterDataStore } = useStores()
  const { data: lessonPlanRequests } = useOutstandingLessonPlanRequests()
  const { data: myNotifications } = useMyNotifications()
  const { data: targetUserData } = useUser(
    useStores,
    mySchoolUserData?.data?.[0].id ?? '',
  )
  const { data: targetLessonPlan } = useLessonPlan(
    useStores,
    lessonPlanOwnedData?.data?.[0].id ?? '',
    {},
    {
      enabled: lessonPlanOwnedData?.data?.length ? true : false,
      onSuccess(res) {
        if (res.latestLessonPlanComment != null) {
          setTargetComment(res.latestLessonPlanComment)
        }
      },
    },
  )

  const { data: LessonPlanCommentsData } = useLessonPlanComments(
    useStores,
    targetLessonPlan?.id ?? '',
    {
      expand: ['LessonPlan', 'CreatedByUser', 'RepliedToComment'],
      sort: [
        {
          direction: 'desc',
          parameter: 'CreatedDateTime',
        },
      ],
      isDeleted: false,
    },
    {
      enabled: targetLessonPlan?.id != null,
    },
  )
  const { mutateAsync: createBlobStorageContainer } =
    useCreateBlobStorageContainer()

  const { data: lessonPlanSharingsData } = useLessonPlanSharings(useStores, {
    expand: ['LessonPlan', 'SharedToUser'],
  })

  const { mutateAsync: update } = useUpdateLessonPlan(
    useStores,
    targetLessonPlan?.id ?? '',
  )

  const { mutateAsync: createComment } = useCreateLessonPlanComment(
    useStores,
    targetLessonPlan?.id ?? '',
  )

  const { mutateAsync: updateComment } = useUpdateLessonPlanCommentById(
    useStores,
    targetComment?.id ?? '',
    {
      onSuccess() {
        clearLessonPlansQueryCaches(queryClient, targetLessonPlan?.id ?? '')
      },
    },
  )

  const { mutateAsync: deleteComment } = useDeleteLessonPlanComment(useStores, {
    onSuccess() {
      clearLessonPlansQueryCaches(queryClient, targetLessonPlan?.id ?? '')
    },
  })

  const { mutateAsync: transferLessonPlan } = useTransferLessonPlan(
    useStores,
    targetLessonPlan?.id ?? '',
  )

  const testCreateBlobStorageContainer = useCallback(async () => {
    const res = await createBlobStorageContainer()
    setBlobStorageData(res)
  }, [createBlobStorageContainer])

  const updateLessonPlan = useCallback(() => {
    if (targetLessonPlan == null) return
    const postPayload = { ...targetLessonPlan }
    update({
      ...postPayload,
      teachingTopic: `Testing Put Topic 1 - ${dayjs().format('hh:mm:ss')}`,
      lessonPlanAttachments: postPayload.lessonPlanAttachments.map((it) => ({
        ...it,
        file: it.file,
      })),
    } as any)
  }, [targetLessonPlan, update])

  const createLessonPlanComment = useCallback(
    async (repliedToCommentId?: string) => {
      if (targetLessonPlan == null) return
      const res = await createComment({
        content: `Testing create comment - ${dayjs().format('hh:mm:ss')}`,
        repliedToCommentId: repliedToCommentId,
        LessonPlanCommentAttachments: [],
      })
      if (res.lessonPlanComments.length > 0) {
        setTargetComment(
          res.lessonPlanComments[res.lessonPlanComments.length - 1],
        )
      }
    },
    [createComment, targetLessonPlan],
  )

  const updateLessonPlanComment = useCallback(async () => {
    if (targetComment == null) return
    await updateComment({
      content: `Testing update comment - ${dayjs().format('hh:mm:ss')}`,
      rowVersion: targetComment.rowVersion,
    })
  }, [targetComment, updateComment])

  const deleteLessonPlanComment = useCallback(async () => {
    if (targetComment == null) return
    await deleteComment(targetComment.id)
  }, [deleteComment, targetComment])

  const msalInfo = useMsal()

  return (
    <>
      <Button
        onClick={() => {
          transferLessonPlan({
            ownedByUserId: targetUserData?.id ?? '',
            rowVersion: targetLessonPlan?.rowVersion ?? '',
          })
        }}
      >
        {`transfer lesson plan (${targetLessonPlan?.teachingTopic}) to ${targetUserData?.displayName}`}
      </Button>
      <Button
        onClick={updateLessonPlan}
      >{`Update Lesson Plan: ${targetLessonPlan?.teachingTopic}`}</Button>
      <Grid mt={4} mb={4}>
        <Typography>{`Lesson Plan Comment Actions: ${targetComment?.id}`}</Typography>
        <Button
          onClick={() => createLessonPlanComment()}
        >{`Create Lesson Plan Comment`}</Button>
        {targetComment == null ? (
          <></>
        ) : (
          <>
            <Button
              onClick={() => createLessonPlanComment(targetComment.id)}
            >{`Reply Lesson Plan Comment`}</Button>
            <Button
              onClick={updateLessonPlanComment}
            >{`Update Lesson Plan Comment`}</Button>
            <Button
              onClick={deleteLessonPlanComment}
            >{`Delete Lesson Plan Comment`}</Button>
          </>
        )}
      </Grid>
      <Button onClick={testCreateBlobStorageContainer}>
        Click to create blob storage container
      </Button>
      <ApiTestContent
        title="New Blob Storage Container Data"
        data={blobStorageData}
      />
      <ApiTestContent title="Target Lesson Plan Data" data={targetLessonPlan} />
      <ApiTestContent title="My msal Data" data={msalInfo} />
      <ApiTestContent
        title="Current User Profile Data"
        data={currentUserProfile}
      />
      <ApiTestContent title="User Data" data={userData} />
      <ApiTestContent title="Role Data" data={roleData} />
      <ApiTestContent title="School Data" data={schoolData} />
      <ApiTestContent title="Lesson Plan Data" data={lessonPlanData} />
      <ApiTestContent
        title="Lesson Plan Owned Data"
        data={lessonPlanOwnedData}
      />
      <ApiTestContent
        title="Lesson Plan Shared Data"
        data={lessonPlansSharedData}
      />
      <ApiTestContent
        title="Resource Types Data"
        data={masterDataStore.resourceTypes}
      />
      <ApiTestContent
        title="Learning Unit Data"
        data={masterDataStore.learningUnits}
      />
      <ApiTestContent
        title="Education Level Data"
        data={masterDataStore.educationLevels}
      />
      <ApiTestContent
        title="Teaching Class Data"
        data={masterDataStore.teachingClasses}
      />
      <ApiTestContent
        title="Di Strategy Data"
        data={masterDataStore.diStrategies}
      />
      <ApiTestContent title="Tag Data" data={masterDataStore.tags} />
      <ApiTestContent title="Subject Data" data={masterDataStore.subjects} />
      <ApiTestContent
        title="Learning Phase Data"
        data={masterDataStore.learningPhases}
      />
      <ApiTestContent
        title="Class Level Data"
        data={masterDataStore.classLevels}
      />
      <ApiTestContent
        title="Di Strategy Class Data"
        data={masterDataStore.diStrategyClasses}
      />
      <ApiTestContent title="Tag Type Data" data={masterDataStore.tagTypes} />
      <ApiTestContent
        title="Lesson Plan Sharing Data"
        data={lessonPlanSharingsData}
      />
      <ApiTestContent
        title="Lesson Plan Comments Data"
        data={LessonPlanCommentsData}
      />
      <ApiTestContent
        title="Outstanding Lesson Plan Data"
        data={lessonPlanRequests}
      />
      <ApiTestContent title="My Notifications Data" data={myNotifications} />
      <ApiTestContent title="My school user data" data={mySchoolUserData} />
    </>
  )
})
