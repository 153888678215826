import {
  ClassLevelsQueryParameters,
  PaginatedPayload,
  PostClassLevelPayload,
  PutClassLevelPayload,
} from '@polyu-dip/apis'
import { ClassLevel } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'class-levels'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useClassLevels(
  useStores: UseStoresType,
  param?: ClassLevelsQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<ClassLevel>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getClassLevels(param),
    opts,
  )
}

export function useCreateClassLevels(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostClassLevelPayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payloads) => masterDataStore.createClassLevels(payloads),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useUpdateClassLevel(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<ClassLevel, unknown, PutClassLevelPayload>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => masterDataStore.updateClassLevel(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useSwitchClassLevelStatus(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    ClassLevel,
    unknown,
    PutClassLevelPayload & { id: string }
  >,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => masterDataStore.updateClassLevel(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export async function clearClassLevelQueryCaches(queryClient: QueryClient) {
  await queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
