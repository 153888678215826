import { Box, Grid, IconButton } from '@mui/material'
import { RoleType } from '@polyu-dip/models'
import { memo, useCallback } from 'react'
import {
  Control,
  Controller,
  FieldErrorsImpl,
  useFieldArray,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Svg } from '../../assets'
import { AutoComplete, Dropdown, TextField } from '../form'

const fieldName = 'newUsers'

export type NewUserFormData = {
  newUsers: {
    email: string
    roleId?: string
    schoolId?: string
    domain?: string
  }[]
}

type Props = {
  duplicatedEmailList: string[]
  getValues: UseFormGetValues<NewUserFormData>
  setValue: UseFormSetValue<NewUserFormData>
  watch: UseFormWatch<NewUserFormData>
  control: Control<NewUserFormData, any>
  errors?: Partial<
    FieldErrorsImpl<{
      newUsers: {
        email: string
        roleId: string
        schoolId: string
        domain: string
      }[]
    }>
  >
  userRoleOptions: { label: string; value: string; roleType: string }[]
  schoolOptions?: { label: string; value: string; domain: string }[]
  currentUserSchoolId?: string
  domain?: string
}

const schoolUserRoles = [RoleType.schoolAdmin, RoleType.teacher]

const diTeamUserRoles = [RoleType.diTeam, RoleType.expert]

export const NewAccountList = memo<Props>(
  ({
    control,
    errors,
    userRoleOptions,
    schoolOptions,
    duplicatedEmailList,
    getValues,
    setValue,
    watch,
    currentUserSchoolId,
    domain,
  }) => {
    const { t } = useTranslation()
    const {
      fields: newUserFields,
      remove: removeNewUserField,
      append: appendNewUserField,
    } = useFieldArray({
      control,
      name: fieldName,
    })

    const getSelectableUserRoleOptions = useCallback(
      (index: number) => {
        const schoolIdData = watch(`${fieldName}.${index}.schoolId`)
        const selectableRoles =
          schoolIdData == null || schoolIdData.length === 0
            ? diTeamUserRoles
            : schoolUserRoles
        return userRoleOptions.map((option) => ({
          ...option,
          disabled: !selectableRoles.some(
            (schoolUserRole) => schoolUserRole === option.roleType,
          ),
        }))
      },
      [userRoleOptions, watch],
    )

    const checkDuplicateEmailMessage = useCallback(
      (index: number) => {
        return duplicatedEmailList.some(
          (email) => email === getValues(`${fieldName}[${index}].email` as any),
        )
          ? t('error.duplicatedEmailInSameRequest')
          : undefined
      },
      [duplicatedEmailList, getValues, t],
    )

    const renderEndAdornment = useCallback(
      (index: number) => {
        return (
          <IconButton
            size="small"
            onMouseDown={(event) => event.stopPropagation()}
            onClick={() => {
              setValue(`${fieldName}.${index}.roleId`, undefined)
            }}
          >
            <Svg.Close height={20} width={20} />
          </IconButton>
        )
      },
      [setValue],
    )

    return (
      <Box>
        {newUserFields.map((newUserField, index) => (
          <Grid key={newUserField.id} container columnSpacing={3}>
            {schoolOptions == null ? (
              <></>
            ) : (
              <Grid item xs={4} mt={4}>
                <Controller
                  control={control}
                  name={`${fieldName}[${index}].schoolId` as any}
                  render={({ field }) => (
                    <AutoComplete
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      placeholder={t('users.fields.school')}
                      isReserverForHelperText
                      error={errors?.newUsers?.[index]?.schoolId?.message}
                      options={schoolOptions.map((option) => option.value)}
                      getOptionLabel={(option) =>
                        schoolOptions.find((school) => school.value === option)
                          ?.label ?? ''
                      }
                      onChange={(e, value) => {
                        field.onChange({ target: { value } })
                        setValue(`newUsers.${index}.roleId`, undefined)
                        setValue(`newUsers.${index}.schoolId`, value)
                        setValue(
                          `newUsers.${index}.domain`,
                          schoolOptions.find((option) => option.value === value)
                            ?.domain,
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={schoolOptions == null ? 6 : 4} pr={3} mt={4}>
              <Controller
                control={control}
                name={`${fieldName}[${index}].email` as any}
                render={({ field }) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    placeholder={t('users.fields.email')}
                    error={
                      checkDuplicateEmailMessage(index) ??
                      errors?.newUsers?.[index]?.email?.message
                    }
                    isReserverForHelperText
                  />
                )}
              />
            </Grid>
            <Grid item xs={schoolOptions == null ? 5.5 : 3.5} mt={4}>
              <Controller
                control={control}
                name={`${fieldName}[${index}].roleId` as any}
                render={({ field }) => {
                  const targetValue = watch(`${fieldName}.${index}.roleId`)
                  return (
                    <Dropdown
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      value={getValues(`${fieldName}.${index}.roleId`) ?? null}
                      placeholder={t('users.fields.role')}
                      options={getSelectableUserRoleOptions(index)}
                      isReserverForHelperText
                      error={errors?.newUsers?.[index]?.roleId?.message}
                      clearable={targetValue != null && targetValue.length > 0}
                      clearableEndAdornment={renderEndAdornment(index)}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item mt={4}>
              <Svg.Close
                cursor="pointer"
                onClick={() => removeNewUserField(index)}
              />
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Svg.Plus
              cursor="pointer"
              onClick={() =>
                appendNewUserField({
                  email: '',
                  roleId: undefined,
                  schoolId: currentUserSchoolId,
                  domain: domain,
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
    )
  },
)
