export const notifications = {
  noRowsOverlayTitle: 'There is no notice yet',
  entityName: 'Notify',
  title: 'All notice',

  content: {
    lessonPlanTeachingTopicPrefix: 'Your lesson plan',
    share: 'Share lesson plan(s) with you',
    comment: 'responded to lesson plan',
    sharecomment: 'Responded to the lesson plan shared with you',
    repliedComment: 'responded to your message',
    plancomment:'Responded to the consultation lesson plan',
    submittedPublishRequest: 'submitted new lesson plan',
    withdrawPublishRequest: 'removed lesson plan',
    approvedPublishRequest: 'publication is accepted',
    rejectedPublishRequest: 'application is rejected',
    published: 'has been published as DI examples',
    unpublished: 'has been removed',
    consult: 'lesson plan in consultation ',
    assigned: 'Delegate new consultation to you ',
    newLessonPlanFromOwner: 'lesson plan ownership transferred to you',
    newLessonPlanFromSchoolAdmin:
      'transferred the ownership of some lesson plans with you',
    lessonPlanTransferred: 'The ownership of lesson plans are transffered',
    userAccountDisabled: 'your user account is suspended ',
    userAccountReEnabled: 'you user account is re-activated ',
  },
}
