import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogProps as MuiDialogProps,
} from '@mui/material'
import { memo, ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export type MuiColorProps =
  | 'error'
  | 'warning'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'inherit'
  | 'info'
  | 'blue'
  | 'brown'
  | 'lightRed'
  | 'sharpRed'
  | 'cascade'
  | 'darkGrey'
  | 'darkestGrey'
  | undefined

const StyledDialogTitle = styled(DialogTitle)`
  padding: 24px;
`

const StyledDialogActions = styled(DialogActions)`
  padding: 24px;
`

export type DialogAction<T> = {
  text: string
  type?: 'text' | 'outlined' | 'contained'
  value: T
  disabled?: boolean
  color?: MuiColorProps
  onClick?: () => void
}

export type DialogProps<T = string | boolean> = Omit<MuiDialogProps, 'open'> & {
  open?: boolean
  title: string
  content?: string
  children?: ReactNode
  width?: number
  actions?: DialogAction<T>[]
} & (
    | {
        dismissable?: undefined
        onSelected?(action: T): void
      }
    | {
        dismissable: true
        onSelected?(action: T | undefined): void
      }
  )

const StyledDialogContentText = styled(DialogContentText)`
  white-space: pre-line;
`

export const Dialog = memo<DialogProps>(
  ({
    open,
    title,
    content,
    width,
    onSelected,
    dismissable,
    actions,
    children,
    ...props
  }) => {
    const { t } = useTranslation()
    const dialogButtons = useMemo((): NonNullable<DialogProps['actions']> => {
      if (actions != null) {
        return actions
      }

      return [
        {
          text: t('common.cancel'),
          type: 'outlined',
          value: false,
        },
        {
          text: t('common.confirm'),
          color: 'secondary',
          value: true,
        },
      ]
    }, [actions, t])

    return (
      <MuiDialog
        fullWidth
        maxWidth="xs"
        disableEscapeKeyDown={!dismissable}
        {...props}
        onClose={() => {
          if (dismissable) {
            onSelected?.(undefined)
          }
        }}
        open={open ?? true}
      >
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <DialogContent>
          <StyledDialogContentText>{content}</StyledDialogContentText>
          {children != null && children}
        </DialogContent>
        <StyledDialogActions>
          {dialogButtons.map((it) => {
            return (
              <Button
                key={it.text}
                variant={it.type}
                onClick={() => onSelected?.(it.value)}
                disabled={it.disabled}
                color={it.color}
              >
                {it.text}
              </Button>
            )
          })}
        </StyledDialogActions>
      </MuiDialog>
    )
  },
)
