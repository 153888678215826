import styled from 'styled-components'
import React, { useMemo } from 'react'
import { ReactI18NextChild } from 'react-i18next'
import { Link } from 'react-router-dom'

type Props = {
  isHidden?: boolean
  iconSrc: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  isActive: boolean
  isFocus: boolean
  text: ReactI18NextChild | Iterable<ReactI18NextChild>
  to: string
  onClick?: () => void
}

const Container = styled.div<Pick<Props, 'isActive' | 'isHidden'>>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  align-items: center;
  background: ${({ isActive }) =>
    isActive && 'linear-gradient(90deg, #ec796a 1.98%, #f7ca7c 100.5%)'};
  border-radius: 10px;
  padding: 8px 12px;
  margin-bottom: 12px;
  text-decoration: none;
`

const Text = styled.div<Pick<Props, 'isActive' | 'isFocus'>>`
  font-size: 20px;
  color: ${({ isFocus, isActive }) => {
    if (!isFocus) return 'grey'
    return isActive ? 'white' : 'black'
  }};
  margin-left: 25px;
  width: calc(100% - 50px);
  white-space: pre-wrap;
`

export const SidebarLink: React.FunctionComponent<Props> = ({
  isActive,
  isFocus,
  text,
  to,
  iconSrc,
  onClick,
}) => {
  const Icon = iconSrc
  const iconColor = useMemo(() => {
    if (!isFocus) return 'gray'
    return isActive ? 'white' : 'black'
  }, [isActive, isFocus])

  return (
    <Link to={to} onClick={onClick}>
      <Container isActive={isActive}>
        <Icon fill={iconColor} />
        <Text isActive={isActive} isFocus={isFocus}>
          {text}
        </Text>
      </Container>
    </Link>
  )
}
