import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { Tag } from './tag.model'

export enum LessonPlanTagType {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ClassCharacteristic = 'Class_Characteristic',
}

export const LessonPlanTagModel = BaseEntityModel.named('LessonPlanTagModel')
  .props({
    id: types.string,
    tagId: types.string,
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .views((self) => ({
    get tag() {
      if (self.tagId == null) return undefined
      return (
        self.rootStore.masterDataStore as {
          tags: Tag[]
        }
      ).tags.find((tag) => tag.id === self.tagId)
    },
  }))

export type LessonPlanTag = Instance<typeof LessonPlanTagModel>
