import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { DiStrategy } from './di-strategy.model'

export const LessonPlanDiStrategyModel = BaseEntityModel.named(
  'LessonPlanDiStrategyModel',
)
  .props({
    id: types.string,
    reason: types.maybe(types.string),
    diStrategyId: types.string,
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .views((self) => ({
    get diStrategy() {
      if (self.diStrategyId == null) return undefined
      return (
        self.rootStore.masterDataStore as { diStrategies: DiStrategy[] }
      ).diStrategies.find((diStrategy) => diStrategy.id === self.diStrategyId)
    },
  }))

export type LessonPlanDiStrategy = Instance<typeof LessonPlanDiStrategyModel>
