import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  LearningPhaseResponsePayload,
  LearningPhasesQueryParameters,
  LearningPhasesResponsePayload,
  PostLearningPhasePayload,
  PutLearningPhasePayload,
} from './learning-phases-api.type'

const PATH = '/learning-phases'

export function getLearningPhases(param?: LearningPhasesQueryParameters) {
  return ApiCore.primary.get<LearningPhasesResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}

export function postLearningPhasesBulk(payloads: PostLearningPhasePayload[]) {
  return ApiCore.primary.post<LearningPhaseResponsePayload>(`${PATH}/bulk`, {
    body: payloads,
  })
}

export function putLearningPhaseById(
  id: string,
  payload: PutLearningPhasePayload,
) {
  return ApiCore.primary.put<LearningPhaseResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}
