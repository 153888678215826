export const users = {
  entityName: 'Account information',
  userDropdownLabel: '{{name}} ({{school}}) <{{email}}>',
  forbidden: 'Account has been suspended.',

  title: {
    schoolUser: 'On-campus users',
    systemUsers: 'System users',
    myProfile: 'Account setting',
    userProfile: 'User information',
  },
  fields: {
    email: 'Registered email',
    schoolName: 'Name of school',
    role: 'User group',
    status: 'User status',
    name: 'Username',
    educationLevel: 'Primary school / Secondary school',
    schoolShortCode: 'Abbreviation of school name',
    school: 'School',
  },
  helpText: {
    email: '(For school email only)',
  },

  actions: {
    editUser: {
      successMessage: 'User account has been edited successfully',
    },
    addUser: {
      title: 'Add user',
      confirm: 'Establish an account',
      successMessage: 'User account has been added successfully',
      errorMessage: 'Fail to establish account',
      retryDialog: {
        title: 'Fail to add the following user account',
        content: 'Are you trying to add more users?',
        retry: 'Try again',
      },
    },
    transferOwnership: {
      title: 'Transfer lesson plan ownership',
      successMessage:
        'Lesson plan ownership has been transferred successfully! ',
      confirmationDialog: {
        title: 'Confirm to transfer ownership',
        content: 'Confirm to transfer the ownership of this lesson plan?',
        dropdownLabel:
          'The ownership of lesson plan will be transferred to the following user account: ',
        dropdownPlaceholder: '@ newmember@ccss.edu.hk',
      },
    },
    disableAccount: {
      title: 'Suspend account',
      successMessage: 'This account has been suspended! ',
      confirmationDialog: {
        title: 'This account will be suspended',
        content:
          'This account will be suspended. \nPlease confirm to transfer the ownership of "My Lesson Plan"?',
        dropdownLabel:
          'The ownership of lesson plan will be transferred to the following user account: ',
        dropdownPlaceholder: '@ newmember@ccss.edu.hk',
        disableAction: 'Suspend only',
        disableAndTransferAction: 'Transfer',
      },
      disableOnlyConfirmationDialog: {
        title: 'This account will be suspended',
        content: 'This account will be suspended',
      },
    },
    enableAccount: {
      title: 'Enable account',
      successMessage: 'This account has been enabled successfully! ',
    },
    save: { title: 'Save' },
    edit: { title: 'Edit' },
    errorMessage: {
      save: { title: 'Fail to save' },
      transfer: { title: 'Lesson plan ownership fails to transfer' },
      disable: { title: 'Fail to suspend account' },
      enable: { title: 'Fail to enable account' },
    },
  },
  status: {
    active: 'In use',
    disabled: 'Suspended',
  },
}
