import {
  PaginatedPayload,
  PutUserPayload,
  PostUserPayload,
  PostUsersPayload,
  PostUsersResponsePayload,
  PostUserStatusPayload,
  SchoolExpandParameter,
  UserExpandParameter,
  UsersQueryParameters,
} from '@polyu-dip/apis'
import { LessonPlan, School, User } from '@polyu-dip/models'
import { UsersStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'users'

type UseStoresType = () => { usersStore: UsersStore }

export function useUsers(
  useStores: UseStoresType,
  param?: UsersQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<User>>,
) {
  const { usersStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => usersStore.getUsers(param),
    opts,
  )
}

export function useUser(
  useStores: UseStoresType,
  id: string,
  param?: UserExpandParameter,
  opts?: UseQueryOptions<User>,
) {
  const { usersStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, id, param] as QueryKey,
    () => usersStore.getUser(id, param),
    opts,
  )
}

export function useCreateUser(
  useStores: UseStoresType,
  opts?: UseMutationOptions<User, unknown, PostUserPayload>,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => usersStore.createUser(payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useCreateUsers(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    PostUsersResponsePayload,
    unknown,
    PostUsersPayload
  >,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => usersStore.createUsers(payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useUpdateUser(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<User, unknown, PutUserPayload>,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => usersStore.updateUser(id, payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useCurrentUserProfile(
  useStores: UseStoresType,
  param?: UserExpandParameter,
  opts?: UseQueryOptions<User>,
) {
  const { usersStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'me', param] as QueryKey,
    () => usersStore.getCurrentUserProfile(param),
    opts,
  )
}

export function useCreateCurrentUserProfile(
  useStores: UseStoresType,
  opts?: UseMutationOptions<User, unknown, PostUserPayload>,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => usersStore.createCurrentUserProfile(payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useMySchool(
  useStores: UseStoresType,
  param?: SchoolExpandParameter,
  opts?: UseQueryOptions<School>,
) {
  const { usersStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'me/school', param] as QueryKey,
    () => usersStore.getCurrentSchool(param),
    opts,
  )
}

export function useMySchoolUsers(
  useStores: UseStoresType,
  param?: UsersQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<User>>,
) {
  const { usersStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'me/school/users', param] as QueryKey,
    () => usersStore.getCurrentSchoolUsers(param),
    opts,
  )
}

export function useExperts(
  useStores: UseStoresType,
  param?: UsersQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<User>>,
) {
  const { usersStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'expert', param] as QueryKey,
    () => usersStore.getExperts(param),
    opts,
  )
}

export function useTransferUserLessonPlans(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<LessonPlan[], unknown, string>,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (transferToUserId) => usersStore.transferLessonPlans(id, transferToUserId),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useDisableUser(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<User, unknown, PostUserStatusPayload>,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => usersStore.disableUser(id, payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useEnableUser(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    User,
    unknown,
    PostUserStatusPayload & { id: string }
  >,
) {
  const { usersStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => usersStore.enableUser(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function clearUserQueryCaches(queryClient: QueryClient) {
  queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
