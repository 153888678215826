import { withRootStore } from '@polyu-dip/model-core'
import { types } from 'mobx-state-tree'

export const DetailModel = types
  .model('Detail', {
    detail: types.maybe(types.string),
  })
  .extend(withRootStore)
  .views((self) => ({
    get displayDetail() {
      try {
        if (self.detail == null) return
        const language = self.rootStore.uiStore.language
        return JSON.parse(self.detail)[language]
      } catch {
        return self.detail
      }
    },
  }))
