import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { DiStrategyClass } from './di-strategy-class.model'
import {
  DiStrategyResourceModel,
  LessonPlanDiStrategyResourceType,
} from './di-strategy-resource.model'
import { DiStrategyTagModel } from './di-strategy-tag.model'
import { DetailModel, NameModel } from './languages-json'

export const DiStrategyModel = types
  .compose(BaseEntityModel, NameModel, DetailModel)
  .props({
    id: types.string,
    diStrategyClassId: types.string,
    order: types.number,
    isDisabled: types.boolean,
    diStrategyResources: types.maybe(types.array(DiStrategyResourceModel)),
    diStrategyTags: types.maybe(types.array(DiStrategyTagModel)),
    colorCode: types.string,
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .named('DiStrategyModel')
  .views((self) => ({
    get diStrategyClass() {
      if (self.diStrategyClassId == null) return undefined
      return (
        self.rootStore.masterDataStore as {
          diStrategyClasses: DiStrategyClass[]
        }
      ).diStrategyClasses.find(
        (diStrategyClass) => diStrategyClass.id === self.diStrategyClassId,
      )
    },
    get displayVideo() {
      if (self.diStrategyResources == null) return undefined
      return self.diStrategyResources.find(
        (diStrategyResource) =>
          diStrategyResource.resourceType ===
          LessonPlanDiStrategyResourceType.displayVideo,
      )
    },
    get videos() {
      if (self.diStrategyResources == null) return []
      return self.diStrategyResources.filter(
        (diStrategyResource) =>
          diStrategyResource.resourceType ===
          LessonPlanDiStrategyResourceType.video,
      )
    },
    get furtherReadingAndExamples() {
      if (self.diStrategyResources == null) return []
      return self.diStrategyResources.filter(
        (diStrategyResource) =>
          diStrategyResource.resourceType ===
          LessonPlanDiStrategyResourceType.furtherReadingAndExamples,
      )
    },
  }))

export type DiStrategy = Instance<typeof DiStrategyModel>
