import { Box, Grid, Skeleton } from '@mui/material'
import { observer } from 'mobx-react-lite'

import styled from 'styled-components'
import { Avatar } from '../../../components'

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.palettes.general.white};
  border-radius: 20px;
  padding: 8px 20px 16px 20px;
  min-height: 140px;
  justify-content: center;
  align-items: center;
`

export const LessonPlanCommentLoadingCard = observer(() => {
  return (
    <Box mt={6}>
      <Container>
        <Grid container direction="column">
          <Grid container direction="row" justifyContent="flex-end">
            <Skeleton width="10%" height={30} />
          </Grid>
          <Grid container direction="row">
            <Grid item mr={2}>
              <Skeleton variant="circular" width={28} height={28}>
                <Avatar size="small" />
              </Skeleton>
            </Grid>
            <Skeleton width="10%" height={30} />
          </Grid>
          <Grid item mt={3}>
            <Skeleton width="30%" />
            <Skeleton width="50%" />
            <Skeleton width="70%" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
})
