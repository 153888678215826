import {
  AuthenticationResult,
  AuthError,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PopupRequest,
  SilentRequest,
} from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { ApiCore } from '@polyu-dip/api-core'
import { useCallback, useEffect, useState } from 'react'
import { env } from '../../utilities'

export const loginRequest: SilentRequest | PopupRequest = {
  scopes: [env.aadServerApiScope],
}

export function handleLogin(
  instance: IPublicClientApplication,
  response: AuthenticationResult,
  request: SilentRequest | PopupRequest,
) {
  if (response.account == null) {
    throw new Error('response with empty account')
  }
  ApiCore.primary.setAuthorizationHeader(`Bearer ${response.accessToken}`)
  instance.setActiveAccount(response.account)

  ApiCore.primary.setUnauthorizedHandler({
    handler: async () => {
      try {
        const session = await instance.acquireTokenSilent(request)
        return `Bearer ${session.accessToken}`
      } catch (err) {
        // refresh fail due to refresh token expired, do interactive login
        if (err instanceof InteractionRequiredAuthError) {
          const updatedSession = await instance
            .acquireTokenPopup(request)
            .catch(async (error: AuthError) => {
              throw new Error(
                `interactive login failed (${error.errorCode}): ${error.errorMessage}`,
              )
            })
          return `Bearer ${updatedSession.accessToken}`
        }
        return null
      }
    },
    delayTimeout: true,
  })
}

export function useLogin() {
  const { instance } = useMsal()

  return useCallback(
    async (type?: 'redirect' | 'popup', isActiveAccount?: boolean) => {
      const request = loginRequest
      if (isActiveAccount) {
        request.authority = `${env.aadInstance}/${env.aadDomain}/${env.aadUserFlowActivate}`
      } else {
        request.authority = `${env.aadInstance}/${env.aadDomain}/${env.aadUserFlowLogin}`
      }
      let result: AuthenticationResult
      try {
        result = await instance.acquireTokenSilent(request)
      } catch {
        if (type !== 'popup') {
          await instance.acquireTokenRedirect(request)
          return
        }
        result = await instance.acquireTokenPopup(request)
      }
      handleLogin(instance, result, request)
    },
    [instance],
  )
}

export function useRestoreSession() {
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const [result, setResult] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      if (isAuthenticated === false) {
        setResult(false)
        return
      }
      if (instance == null) {
        return
      }

      try {
        const session = await instance.acquireTokenSilent(loginRequest)
        handleLogin(instance, session, loginRequest)
        setResult(true)
      } catch (error) {
        setResult(false)
      }
    })()
  }, [instance, isAuthenticated])

  return result
}

export function useLogout() {
  const { instance } = useMsal()
  return useCallback(() => instance.logoutPopup(), [instance])
}
