import { Box, Button, Dialog, Grid, Typography } from '@mui/material'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Checkbox, TextField } from '../form'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit?: (requestId: string) => void
  requestId?: string
  requestText?: string
  requireObservation?: boolean
}

export const ConsultDetailDialog = observer<Props>(
  ({ open, onClose, onSubmit, requestId, requestText, requireObservation }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    return (
      <Dialog maxWidth={false} open={open} onClose={onClose}>
        <Box width={875} p={10}>
          <Typography
            fontSize={theme.fontSizes.heading4}
            fontWeight={600}
            mb={5}
          >
            {t('lessonPlanRequest.consultManagement.consultDetail.title')}
          </Typography>
          <TextField
            label={t('lessonPlan.createForm.requestConsultingPopup.label')}
            placeholder={t(
              'lessonPlanRequest.consultManagement.consultDetail.placeholder',
            )}
            multiline
            rows={5}
            value={requestText}
            inputProps={{ readOnly: true }}
          />
          <Box mt={3}>
            <Checkbox
              label={t(
                'lessonPlanRequest.consultManagement.consultDetail.requireObservation',
              )}
              checked={requireObservation ?? false}
            />
            <Grid mt={10} container justifyContent="flex-end" columnSpacing={5}>
              <Grid item width={200}>
                <Button variant="outlined" onClick={onClose} fullWidth>
                  {t('common.close')}
                </Button>
              </Grid>
              {onSubmit == null || requestId == null ? (
                <></>
              ) : (
                <Grid item width={200}>
                  <Button
                    fullWidth
                    color="blue"
                    onClick={() => onSubmit(requestId)}
                  >
                    {t(
                      'lessonPlanRequest.consultManagement.consultDetail.actions.viewLessonPlan',
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Dialog>
    )
  },
)
