import { memo } from 'react'
import styled from 'styled-components'
import { Img } from '../../../assets'
import { SponsorFooter } from '../../../components'
import { LessonPlansUpdatedExamplesAndResourcesTable } from './lesson-plans-updated-examples-and-resources-table'
import { TopLink } from './top-link'

const Container = styled.div`
  margin-left: -32px;
  margin-right: -96px;
  padding-left: 32px;
  padding-right: 96px;
  background-image: url('${Img.TeacherTopLinkBG}');
  background-size: 100% 20vh;
  background-repeat: no-repeat;
  background-position: 0px 20px;
`

export const TeacherHomePage = memo(() => {
  return (
    <Container>
      <TopLink />
      <LessonPlansUpdatedExamplesAndResourcesTable />
      <SponsorFooter />
    </Container>
  )
})
