import { Box, BoxProps } from '@mui/material'
import styled from 'styled-components'

const Section = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palettes.general.white,
  borderRadius: 30,
  padding: 28,
  margin: '20px 0px',
}))

type SectionBoxProps = BoxProps

export const SectionBox = (props: SectionBoxProps) => {
  return <Section {...props} />
}
