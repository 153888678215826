import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { NameModel } from './languages-json'

export enum RoleType {
  teacher = 'Teacher',
  diTeam = 'DI_Team',
  schoolAdmin = 'School_Admin',
  expert = 'Expert',
  outteacher ='OutTeacher'
}

export const RoleModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    label: types.string,
  })
  .extend(withUpdate)
  .named('RoleModel')

export type Role = Instance<typeof RoleModel>
