import { Chip, Typography } from '@mui/material'
import { GridColDef, GridSortModel } from '@mui/x-data-grid'
import {
  PaginationParameters,
  SchoolSubmissionRecordsResponse,
} from '@polyu-dip/apis'
import { useComputed } from '@polyu-dip/helpers'
import {
  useEducationLevels,
  useSchoolSubmissionRecords,
} from '@polyu-dip/queries'
import dayjs from 'dayjs'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import {
  ListPage,
  Tab,
  Table,
  Tabs,
  TruncatedCellContent,
} from '../../components'
import { contentPaths } from '../../content-paths'
import { useApiErrorHandle } from '../../hooks'
import { useDisplayName } from '../../services'
import { useStores } from '../../stores'
import { observer } from 'mobx-react-lite'

type Props = {}

enum TabMenuEnum {
  PRIMARY_SCHOOL = 'PRIMARY_SCHOOL',
  SECONDARY_SCHOOL = 'SECONDARY_SCHOOL',
}

const StyledChip = styled(Chip)`
  font-size: 14px;
  line-height: 120%;
  height: auto;
  margin-left: 12px;
  .MuiChip-label {
    padding: 0px 8px;
  }
`

const FlexRow = styled.div`
  display: flex;
  direction: row;
  white-space: nowrap;
`

const PAGINATION_LIMIT = 15

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

export const SubmissionRecords = observer<Props>(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { standardErrorHandler } = useApiErrorHandle()
  const { getDisplayName } = useDisplayName()
  const { masterDataStore } = useStores()

  const {
    isSuccess: isEducationLevelSuccess,
    refetch: refetchEducationLevels,
  } = useEducationLevels(
    useStores,
    { isDisabled: false },
    {
      onError: (error) => {
        standardErrorHandler(error, {
          onExtraActions: () => {
            navigate(contentPaths.home())
          },
          onRetry: refetchEducationLevels,
        })
      },
    },
  )

  const computedEducationLevelData = useComputed(
    () => masterDataStore.activeEducationLevels,
    [masterDataStore.activeEducationLevels],
  )

  const [activeTab, setActiveTab] = useState(TabMenuEnum.PRIMARY_SCHOOL)
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [primarySchoolSort, setPrimarySchoolSort] = useState<GridSortModel>()
  const [secondarySchoolSort, setSecondarySchoolSort] =
    useState<GridSortModel>()

  const {
    data: primarySchoolSubmissionRecordData,
    isLoading: isPrimarySchoolSubmissionRecordLoading,
  } = useSchoolSubmissionRecords(
    {
      educationLevelId: computedEducationLevelData?.[0]?.id,
      isDisabled: false,
      sort: primarySchoolSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'createdDateTime', direction: 'desc' }],
      ...pagination,
    },
    { enabled: isEducationLevelSuccess },
  )

  const {
    data: secondarySchoolSubmissionRecordData,
    isLoading: isSecondarySchoolSubmissionRecordLoading,
  } = useSchoolSubmissionRecords(
    {
      educationLevelId: computedEducationLevelData?.[1]?.id,
      isDisabled: false,
      sort: secondarySchoolSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'createdDateTime', direction: 'desc' }],
      ...pagination,
    },
    { enabled: isEducationLevelSuccess },
  )

  const computedSubmissionRecordData = useComputed(
    () =>
      (activeTab === TabMenuEnum.PRIMARY_SCHOOL
        ? primarySchoolSubmissionRecordData?.data
        : secondarySchoolSubmissionRecordData?.data) ?? [],
    [
      activeTab,
      primarySchoolSubmissionRecordData,
      secondarySchoolSubmissionRecordData,
    ],
  )

  const [rowCountState, setRowCountState] = useState(
    primarySchoolSubmissionRecordData?.total ?? 0,
  )

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      primarySchoolSubmissionRecordData?.total !== undefined
        ? primarySchoolSubmissionRecordData.total
        : prevRowCountState,
    )
  }, [primarySchoolSubmissionRecordData?.total])

  const columns: GridColDef<SchoolSubmissionRecordsResponse>[] = [
    {
      field: 'name',
      headerName: t('submissionRecords.tableFields.schoolName'),
      width: 300,
      renderCell: ({ row }) => (
        <TruncatedCellContent content={getDisplayName(row.name)} />
      ),
    },
    {
      field: 'createdDateTime',
      headerName: t('submissionRecords.tableFields.createdDateTime'),
      width: 190,
      renderCell: ({ row }) =>
        row.createdDateTime
          ? dayjs(row.createdDateTime).format(t('common.format.date'))
          : '---',
    },
    {
      field: 'schoolStatistics.lessonPlanCount',
      headerName: t('submissionRecords.tableFields.totalMyLessonPlan'),
      width: 150,
      renderCell: ({ row }) => row.schoolStatistics?.lessonPlanCount,
    },
    {
      field: 'schoolStatistics.userCount',
      headerName: t('submissionRecords.tableFields.totalUser'),
      width: 150,
      renderCell: ({ row }) => row.schoolStatistics?.userCount,
    },
    {
      field: 'schoolStatistics.lessonPlanCountPerUser',
      headerName: t('submissionRecords.tableFields.totalLessonPlanPerUser'),
      width: 150,
      renderCell: ({ row }) =>
        row.schoolStatistics?.lessonPlanCountPerUser?.toFixed(2),
    },
    {
      field: 'schoolStatistics.consultRate',
      headerName: t('submissionRecords.tableFields.consultationRate'),
      width: 150,
      renderCell: ({ row }) =>
        row.schoolStatistics?.lessonPlanExampleCount?.toFixed(2),
    },
    {
      field: 'schoolStatistics.lessonPlanExampleCount',
      headerName: t('submissionRecords.tableFields.totalLessonPlanExample'),
      width: 150,
      renderCell: ({ row }) => row.schoolStatistics?.lessonPlanExampleCount,
    },
    {
      field: 'schoolStatistics.latestLessonPlanSubmissionDateTime',
      headerName: t(
        'submissionRecords.tableFields.latestLessonPlanSubmissionDateTime',
      ),
      width: 190,
      renderCell: ({ row }) =>
        row.schoolStatistics?.latestLessonPlanSubmissionDateTime
          ? dayjs(
              row.schoolStatistics?.latestLessonPlanSubmissionDateTime,
            ).format(t('common.format.date'))
          : '---',
    },
  ]

  const handleTabChange = useCallback(
    (event: SyntheticEvent<Element, Event>, value: any) => {
      setActiveTab(value)
      setPagination(DEFAULT_PAGINATION)
    },
    [],
  )

  return (
    <ListPage
      sectionTitleProps={{
        title: t('submissionRecords.title'),
        color: 'brown',
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        $activeColor={theme.palettes.semantic.brown}
        $activeIndicatorColor={theme.palettes.semantic.brown}
      >
        <Tab
          value={TabMenuEnum.PRIMARY_SCHOOL}
          label={
            <FlexRow>
              <Typography>
                {computedEducationLevelData?.[0]?.displayName ??
                  t('submissionRecords.tabs.primarySchool')}
              </Typography>
              <StyledChip
                label={primarySchoolSubmissionRecordData?.total ?? 0}
                color={
                  activeTab === TabMenuEnum.PRIMARY_SCHOOL ? 'brown' : 'default'
                }
              />
            </FlexRow>
          }
          $width={320}
        />
        <Tab
          value={TabMenuEnum.SECONDARY_SCHOOL}
          label={
            <FlexRow>
              <Typography>
                {computedEducationLevelData?.[1]?.displayName ??
                  t('submissionRecords.tabs.secondarySchool')}
              </Typography>
              <StyledChip
                label={secondarySchoolSubmissionRecordData?.total ?? 0}
                color={
                  activeTab === TabMenuEnum.SECONDARY_SCHOOL
                    ? 'brown'
                    : 'default'
                }
              />
            </FlexRow>
          }
          $width={320}
        />
      </Tabs>

      <Table
        columns={columns}
        rows={computedSubmissionRecordData}
        autoHeight
        loading={
          activeTab === TabMenuEnum.PRIMARY_SCHOOL
            ? isPrimarySchoolSubmissionRecordLoading
            : isSecondarySchoolSubmissionRecordLoading
        }
        sortModel={
          activeTab === TabMenuEnum.PRIMARY_SCHOOL
            ? primarySchoolSort
            : secondarySchoolSort
        }
        onSortModelChange={
          activeTab === TabMenuEnum.PRIMARY_SCHOOL
            ? setPrimarySchoolSort
            : setSecondarySchoolSort
        }
        pageSize={PAGINATION_LIMIT}
        // paginationMode="server"
        page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
        hideFooterPagination={
          rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
        }
        rowCount={rowCountState}
        onPageChange={(page) =>
          setPagination((old) => ({
            ...old,
            offset: page * PAGINATION_LIMIT,
          }))
        }
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdDateTime', sort: 'desc' }],
          },
        }}
      />
    </ListPage>
  )
})
