import { memo, PropsWithChildren, useCallback, useState } from 'react'
import NewWindow from 'react-new-window'
import { StyleSheetManager } from 'styled-components'

type Props = PropsWithChildren<{ onClose(): void }>

function copyStyles(src: Document, dest: Document) {
  Array.from(src.styleSheets).forEach((styleSheet) => {
    if (styleSheet.ownerNode == null) return
    if (styleSheet.ownerNode.nodeName.toLowerCase() === 'link') {
      return
    }
    dest.head.appendChild(styleSheet.ownerNode.cloneNode(true))
  })
  Array.from(src.fonts).forEach((font) => {
    if (font.family.startsWith('Noto')) return
    dest.fonts.add(font)
  })
}

export const PrintWindow = memo<Props>(({ children, onClose }) => {
  const [newWindowNode, setNewWindowNode] = useState<HTMLElement | ShadowRoot>()
  const ref = useCallback((node: HTMLDivElement) => setNewWindowNode(node), [])

  return (
    <StyleSheetManager target={newWindowNode}>
      <NewWindow
        onUnload={onClose}
        copyStyles={false}
        onOpen={async (evt) => {
          await document.fonts.load('1em ArialUnicode')

          copyStyles(window.document, evt.document)

          evt.print()
          evt.close()
        }}
      >
        <div ref={ref}>{children}</div>
      </NewWindow>
    </StyleSheetManager>
  )
})
