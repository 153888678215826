import {
  getRoleById,
  getRoles,
  PaginatedPayload,
  RoleResponsePayload,
  RolesQueryParameters,
  RolesResponsePayload,
  PostSchoolsPayload,
  SchoolResponsePayload,
  SchoolsResponsePayload,
  UserResponsePayload,
  UsersQueryParameters,
  UsersResponsePayload,
  getUsers,
  UserExpandParameter,
  getUserById,
  PostUserPayload,
  postUser,
  PutUserPayload,
  SchoolsQueryParameters,
  getSchools,
  SchoolExpandParameter,
  getSchoolById,
  postSchool,
  getCurrentUserProfile,
  postCurrentUserProfile,
  getCurrentSchool,
  getExperts,
  getCurrentSchoolUsers,
  PostUsersPayload,
  postUsers,
  PostUsersResponsePayload,
  PostUserStatusPayload,
  postUserDisable,
  postUserEnable,
  putSchool,
  PutSchoolsPayload,
  putUser,
  postUserTransferLessonPlans,
  LessonPlanResponsePayload,
  PostSchoolDisablePayload,
  postSchoolDisable,
  PostSchoolEnablePayload,
  postSchoolEnable,
} from '@polyu-dip/apis'
import { withRootStore } from '@polyu-dip/model-core'
import {
  LessonPlan,
  Role,
  RoleModel,
  RoleType,
  School,
  SchoolModel,
  User,
  UserModel,
} from '@polyu-dip/models'
import { upsertList } from '@polyu-dip/utilities'
import { flow, Instance, types } from 'mobx-state-tree'

const emptyPayloadMessage = 'empty-payload'

export const UsersStoreModel = types
  .model('UsersStore')
  .props({
    roles: types.array(RoleModel),
    schools: types.array(SchoolModel),
    users: types.array(UserModel),
  })
  .extend(withRootStore)
  .views((self) => ({
    get experts() {
      return self.users.filter((user) => user.role?.label === RoleType.expert)
    },
    userName(id: string) {
      return self.users.find((user) => user.id === id)?.name ?? undefined
    },
  }))
  .actions((self) => {
    const actions = {
      upsertRole(inPayload: RoleResponsePayload) {
        return upsertList(self.roles, inPayload)
      },
      upsertRoles(payloads: RoleResponsePayload[]) {
        return payloads.map(actions.upsertRole)
      },
      getRoles: flow(function* getRolesAction(
        param?: RolesQueryParameters,
      ): Generator<any, PaginatedPayload<Role>, RolesResponsePayload> {
        const payload = yield getRoles(param)
        if (payload == null) throw new Error(emptyPayloadMessage)

        return {
          ...payload,
          data: actions.upsertRoles(payload.data),
        }
      }),
      getRole: flow(function* getRoleByIDAction(
        id: string,
      ): Generator<any, Role, RoleResponsePayload> {
        const payload = yield getRoleById(id)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertRole({
          ...payload,
        })
      }),
      upsertSchool(inPayload: SchoolResponsePayload) {
        const { educationLevel, mainSchoolAdminUser, ...payload } = inPayload
        if (educationLevel != null) {
          self.rootStore.masterDataStore.upsertEducationLevel(educationLevel)
        }
        if (mainSchoolAdminUser != null) {
          upsertList(self.users, mainSchoolAdminUser)
        }

        return upsertList(self.schools, payload)
      },
      upsertSchools(payloads: SchoolResponsePayload[]) {
        return payloads.map(actions.upsertSchool)
      },
      getSchools: flow(function* getSchoolsAction(
        param?: SchoolsQueryParameters,
      ): Generator<any, PaginatedPayload<School>, SchoolsResponsePayload> {
        const payload = yield getSchools(param)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return {
          ...payload,
          data: actions.upsertSchools(payload.data),
        }
      }),
      getSchool: flow(function* getSchoolAction(
        id: string,
        param?: SchoolExpandParameter,
      ): Generator<any, School, SchoolResponsePayload> {
        const payload = yield getSchoolById(id, param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertSchool({
          ...payload,
        })
      }),
      createSchool: flow(function* createSchoolAction(
        school: PostSchoolsPayload,
      ): Generator<any, School, SchoolResponsePayload> {
        const payload = yield postSchool(school)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertSchool(payload)
      }),
      updateSchool: flow(function* updateSchoolAction(
        id: string,
        school: PutSchoolsPayload,
      ): Generator<any, School, SchoolResponsePayload> {
        const payload = yield putSchool(id, school)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertSchool(payload)
      }),
      disableSchool: flow(function* disableSchoolAction(
        id: string,
        school: PostSchoolDisablePayload,
      ): Generator<any, School, SchoolResponsePayload> {
        const payload = yield postSchoolDisable(id, school)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertSchool(payload)
      }),
      enableSchool: flow(function* enableSchoolAction(
        id: string,
        school: PostSchoolEnablePayload,
      ): Generator<any, School, SchoolResponsePayload> {
        const payload = yield postSchoolEnable(id, school)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertSchool(payload)
      }),
      upsertUser(inPayload: UserResponsePayload) {
        const { school, role, ...payload } = inPayload
        if (school != null) {
          upsertList(self.schools, school)
        }
        if (role != null) {
          upsertList(self.roles, role)
        }
        return upsertList(self.users, payload)
      },
      upsertUsers(payloads: UserResponsePayload[]) {
        return payloads.map(actions.upsertUser)
      },
      getUsers: flow(function* getUsersAction(
        params?: UsersQueryParameters,
      ): Generator<any, PaginatedPayload<User>, UsersResponsePayload> {
        const payload = yield getUsers(params)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return {
          ...payload,
          data: actions.upsertUsers(payload.data),
        }
      }),
      getExperts: flow(function* getExpertsAction(
        params?: UsersQueryParameters,
      ): Generator<any, PaginatedPayload<User>, UsersResponsePayload> {
        const payload = yield getExperts(params)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return {
          ...payload,
          data: actions.upsertUsers(payload.data),
        }
      }),
      getUser: flow(function* getUserAction(
        id: string,
        param?: UserExpandParameter,
      ): Generator<any, User, UserResponsePayload> {
        const payload = yield getUserById(id, param)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return actions.upsertUser(payload)
      }),
      createUser: flow(function* createUserAction(
        user: PostUserPayload,
      ): Generator<any, User, UserResponsePayload> {
        const payload = yield postUser(user)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return actions.upsertUser(payload)
      }),
      createUsers: flow(function* createUsersAction(
        user: PostUsersPayload,
      ): Generator<any, PostUsersResponsePayload, PostUsersResponsePayload> {
        const payload = yield postUsers(user)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return payload
      }),
      updateUser: flow(function* updateUserAction(
        id: string,
        user: PutUserPayload,
      ): Generator<any, User, UserResponsePayload> {
        const payload = yield putUser(id, user)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return actions.upsertUser(payload)
      }),
      transferLessonPlans: flow(function* transferLessonPlansAction(
        id: string,
        transferToUserId: string,
      ): Generator<any, LessonPlan[], LessonPlanResponsePayload[]> {
        const payload = yield postUserTransferLessonPlans(id, transferToUserId)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return self.rootStore.lessonPlansStore.upsertLessonPlans(payload)
      }),
      disableUser: flow(function* disableUserAction(
        id: string,
        user: PostUserStatusPayload,
      ): Generator<any, User, UserResponsePayload> {
        const payload = yield postUserDisable(id, user)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertUser(payload)
      }),
      enableUser: flow(function* enableUserAction(
        id: string,
        user: PostUserStatusPayload,
      ): Generator<any, User, UserResponsePayload> {
        const payload = yield postUserEnable(id, user)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertUser(payload)
      }),
      getCurrentUserProfile: flow(function* getCurrentUserProfileAction(
        param?: UserExpandParameter,
      ): Generator<any, User, UserResponsePayload> {
        const payload = yield getCurrentUserProfile(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        const { school, role, ...userMePayload } = payload
        if (role != null) {
          actions.upsertRole(role)
        }
        if (school != null) {
          actions.upsertSchool(school)
        }
        if (school?.users != null) {
          actions.upsertUsers(school.users)
        }
        return actions.upsertUser(userMePayload)
      }),
      createCurrentUserProfile: flow(function* createCurrentUserProfileAction(
        user: PostUserPayload,
      ): Generator<any, User, UserResponsePayload> {
        const payload = yield postCurrentUserProfile(user)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertUser(payload)
      }),
      getCurrentSchool: flow(function* getCurrentSchoolAction(
        param?: SchoolExpandParameter,
      ): Generator<any, School, SchoolResponsePayload> {
        const payload = yield getCurrentSchool(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        if (payload.users != null) {
          actions.upsertUsers(payload.users as UserResponsePayload[])
        }
        return actions.upsertSchool({
          ...payload,
        })
      }),
      getCurrentSchoolUsers: flow(function* getCurrentSchoolUsersAction(
        param?: UsersQueryParameters,
      ): Generator<any, PaginatedPayload<User>, UsersResponsePayload> {
        const payload = yield getCurrentSchoolUsers(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertUsers(payload.data),
        }
      }),
    }
    return actions
  })

export type UsersStore = Instance<typeof UsersStoreModel>
