import { Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SelectedDiStrategies } from '../../../../components'
import { useActiveDiStrategyMaster, Strategy } from '../../../../hooks'
import { DIStrategySelection, SectionBox } from '../../components'
import { GeneralInformationFormData } from '../../lesson-plan-form-provider'
import { useLessonPlanFormContext } from '../../use-lesson-plan-form-context'

const Container = styled.div``

type Props = {}

export const DiStrategiesTab = observer<Props>(
  forwardRef((props, ref) => {
    const { t } = useTranslation()

    const { watch, setValue, getValues } =
      useFormContext<GeneralInformationFormData>()

    useImperativeHandle(ref, () => ({
      getDiStrategyValues() {
        return getValues()
      },
    }))

    const { lessonPlanFormData } = useLessonPlanFormContext()

    const { activeDiStrategyClasses, activeDiStrategies } =
      useActiveDiStrategyMaster(
        watch('lessonPlanDiStrategies').map((it) => it.diStrategyId),
      )

    const selectedDiStrategies: Strategy[] = useMemo(
      () => lessonPlanFormData.lessonPlanDiStrategies,
      [lessonPlanFormData.lessonPlanDiStrategies],
    )

    const onSelectStrategies = useCallback(
      (items: Strategy[]) => {
        setValue('lessonPlanDiStrategies', items)
      },
      [setValue],
    )

    const onSelectReasonChange = useCallback(
      (diStrategyId: string, selectReason: string) => {
        setValue(
          'lessonPlanDiStrategies',
          getValues('lessonPlanDiStrategies').map((it) =>
            it.diStrategyId === diStrategyId
              ? { ...it, reason: selectReason }
              : it,
          ),
        )
      },
      [setValue, getValues],
    )

    return (
      <Container>
        <SectionBox>
          <Grid container alignItems="center">
            <Grid item>
              <Typography component="span">
                <Typography component="span" fontWeight={600}>
                  {t('lessonPlan.createForm.diStrategies.title')}
                </Typography>{' '}
                {t('lessonPlan.createForm.diStrategies.subTitle')}
              </Typography>
            </Grid>
          </Grid>

          <DIStrategySelection
            selectedStrategies={watch('lessonPlanDiStrategies')}
            onSelectStrategies={onSelectStrategies}
            diStrategyOptions={activeDiStrategies}
            diStrategyClasses={activeDiStrategyClasses}
            isShowChoiceReasonInput
            columnSpacing={5}
            onSelectReasonChange={onSelectReasonChange}
          />

          {selectedDiStrategies.length !== 0 && (
            <SelectedDiStrategies
              diStrategies={activeDiStrategies}
              diStrategyClasses={activeDiStrategyClasses}
              selectedDiStrategies={watch('lessonPlanDiStrategies')}
              isEditMode={true}
            />
          )}
        </SectionBox>
      </Container>
    )
  }),
)
