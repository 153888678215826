import { Grid, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { LessonPlanAttachment, FileSource } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FormLabel } from '../../../../components'
import { useBlobStorage } from '../../../../services'
import { useStores } from '../../../../stores'
import { SectionBox } from '../../components'
import { UploadDocumentFormData } from '../../lesson-plan-form-provider'
import { useLessonPlanFormContext } from '../../use-lesson-plan-form-context'
import { UploadAttachmentSection } from './upload-attachment-section'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFormErrorTranslationTrigger } from '../../../../hooks'
import { sortByOrder } from '../../../../utilities'

const MAX_FILE_SIZE_IN_MB = 100

const FormRoot = styled.form``

const StyledFormLabel = styled(FormLabel)`
  padding-bottom: 4px;
`

type Props = {
  setHasUploadError: (hasError: boolean) => void
}

export const UploadAttachmentTab = observer<Props>(
  forwardRef(({ setHasUploadError }, ref) => {
    const { t } = useTranslation()

    const { lessonPlanFormData, setLessonPlanFormData } =
      useLessonPlanFormContext()

    const { masterDataStore } = useStores()
    const { handleUpload } = useBlobStorage(
      t('lessonPlan.createForm.uploadSuccess'),
    )

    const schema = useMemo(
      () =>
        yup.object({
          fileResources: yup.array(
            yup.object({
              attachmentName: yup.string().required(t('error.required')),
              resourceTypeId: yup.string().required(t('error.required')),
              file: yup.object({
                url: yup.string(),
                source: yup.string(),
              }),
              order: yup.number(),
            }),
          ),
          linkResources: yup.array(
            yup.object({
              attachmentName: yup.string().required(t('error.required')),
              resourceTypeId: yup.string().required(t('error.required')),
              file: yup.object({
                url: yup
                  .string()
                  .required(t('error.required'))
                  .url(t('error.invalidUrlFormat')),
                source: yup.string(),
              }),
              order: yup.number(),
            }),
          ),
        }),
      [t],
    )

    const fileResources = useComputed(
      () =>
        lessonPlanFormData.lessonPlanAttachments
          .filter(
            (it: LessonPlanAttachment) =>
              it.file?.source === FileSource.blobStorage,
          )
          .map((it: LessonPlanAttachment) => ({
            ...it,
            file: it.file,
          }))
          .slice()
          .sort(sortByOrder),
      [lessonPlanFormData.lessonPlanAttachments],
    )

    const linkResources = useComputed(
      () =>
        lessonPlanFormData.lessonPlanAttachments
          .filter(
            (it: LessonPlanAttachment) => it.file?.source === FileSource.user,
          )
          .map((it: LessonPlanAttachment) => ({
            ...it,
            file: it.file,
          }))
          .slice()
          .sort(sortByOrder),
      [lessonPlanFormData.lessonPlanAttachments],
    )

    const {
      control,
      formState: { errors },
      setValue,
      getValues,
      watch,
      trigger,
    } = useForm<UploadDocumentFormData>({
      resolver: yupResolver(schema),
      defaultValues: {
        fileResources: fileResources,
        linkResources: linkResources,
      },
    })

    useFormErrorTranslationTrigger(errors, trigger)

    const resourceTypeOptions = useComputed(() => {
      return masterDataStore.resourceTypes
        .filter(
          (type) =>
            !type.isDisabled ||
            getValues('fileResources')
              .map((fileResource) => fileResource.resourceTypeId)
              ?.some((resourceTypeId) => resourceTypeId == type.id) ||
            getValues('linkResources')
              .map((linkResource) => linkResource.resourceTypeId)
              ?.some((resourceTypeId) => resourceTypeId == type.id),
        )
        .map((type) => ({
          label: type.displayName,
          value: type.id,
        }))
        .sort((a, b) => (a.value > b.value ? 0 : 1))
    }, [masterDataStore.resourceTypes])

    useImperativeHandle(ref, () => ({
      getUploadedAttachmentValues() {
        const formData = getValues()
        const fileData = formData.fileResources ?? []
        const linkData = formData.linkResources ?? []
        return [...fileData, ...linkData]
      },
    }))

    const handleUploadFile = useCallback(
      async (file: File) => {
        const url = await handleUpload(file)
        const fileResourceData = getValues('fileResources')
        setValue('fileResources', [
          ...fileResourceData,
          {
            attachmentName: file.name,
            file: {
              url: url,
              source: FileSource.blobStorage,
            },
            order: fileResourceData.length + 1,
            resourceTypeId: '',
          },
        ])
      },
      [getValues, handleUpload, setValue],
    )

    const handleAddUrlAttachment = useCallback(
      (url: string) => {
        const linkResourceData = getValues('linkResources')
        setValue('linkResources', [
          ...linkResourceData,
          {
            attachmentName: '',
            file: {
              url: url,
              source: FileSource.user,
            },
            order: linkResourceData.length + 1,
            resourceTypeId: '',
          },
        ])
      },
      [getValues, setValue],
    )

    useEffect(() => {
      const subscription = watch(async (values) => {
        const newFormData = { ...lessonPlanFormData }
        const fileData = values.fileResources ?? []
        const linkData = values.linkResources ?? []
        newFormData.lessonPlanAttachments = [...fileData, ...linkData]
        setLessonPlanFormData(newFormData)
        const canSubmit = await trigger()
        setHasUploadError(!canSubmit)
      })
      return () => subscription.unsubscribe()
    }, [
      lessonPlanFormData,
      setHasUploadError,
      setLessonPlanFormData,
      trigger,
      watch,
    ])

    return (
      <FormRoot>
        <SectionBox>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography>
                {t('lessonPlan.createForm.uploadDocuments.label')}
              </Typography>
            </Grid>
          </Grid>
        </SectionBox>

        <SectionBox>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <StyledFormLabel
                label={t('attachment.uploadByUrl.sectionTitle')}
                fontWeight={400}
              />
            </Grid>
          </Grid>

          <UploadAttachmentSection
            onAddUrlAttachment={handleAddUrlAttachment}
            maxSizeInMb={MAX_FILE_SIZE_IN_MB}
            targetField={'linkResources'}
            control={control}
            resourceTypeOptions={resourceTypeOptions}
            errors={errors}
          />
        </SectionBox>

        <SectionBox>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <FormLabel
                label={t('attachment.uploadFile.sectionTitle')}
                fontWeight={400}
              />
            </Grid>
          </Grid>

          <UploadAttachmentSection
            onUploadFile={handleUploadFile}
            maxSizeInMb={MAX_FILE_SIZE_IN_MB}
            targetField={'fileResources'}
            control={control}
            resourceTypeOptions={resourceTypeOptions}
            errors={errors}
          />
        </SectionBox>
      </FormRoot>
    )
  }),
)
