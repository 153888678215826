import {
  LessonPlanSharingsQueryParameters,
  PaginatedPayload,
} from '@polyu-dip/apis'
import { LessonPlanSharing } from '@polyu-dip/models'
import { LessonPlansStore } from '@polyu-dip/stores'
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'lesson-plan-sharings'

type UseStoresType = () => { lessonPlansStore: LessonPlansStore }

export function useLessonPlanSharings(
  useStores: UseStoresType,
  param?: LessonPlanSharingsQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<LessonPlanSharing>>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => lessonPlansStore.getLessonPlanSharings(param),
    opts,
  )
}

export function useDeleteLessonPlanSharings(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, string>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (lessonPlanSharingId) =>
      lessonPlansStore.deleteLessonPlanSharings(lessonPlanSharingId),
    {
      ...opts,
      onSuccess: (...params) => {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}
