import { Grid, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { Tag, TruncatedTitle } from '../../components'
import { getTextWidth } from '../../services'

const StyledHeader = styled(TruncatedTitle)`
  color: ${({ theme }) => theme.palettes.general.blue};
`

export function useLessonPlanConsultRequestColumn() {
  const { t } = useTranslation()

  const renderColumn = useCallback(
    (
      handleLetterOnClick?: (
        requestId: string,
        requestText: string,
        requireObservation: boolean,
      ) => void,
    ) => {
      return [
        {
          field: 'id',
          headerName: t('lessonPlanRequest.columnTitles.isConsultRequest'),
          width: 60,
          renderCell: ({ row }: any) => (
            <Grid item mt={2}>
              <Svg.Letter
                onClick={(e) => {
                  e.stopPropagation()
                  handleLetterOnClick?.(
                    row?.id ?? '',
                    row?.content ?? '',
                    row?.isSiteVisitNeeded ?? false,
                  )
                }}
              />
            </Grid>
          ),
          sortable: false,
        },
        {
          field: 'lessonPlan.teachingTopic',
          headerName: t('lessonPlanRequest.columnTitles.teachingTopic'),
          width: 400,
          renderCell: ({ row }: any) => (
            <Tooltip
              title={
                (getTextWidth(row.lessonPlan?.teachingTopic, 'lato') ?? 0) > 200
                  ? row.lessonPlan?.teachingTopic
                  : ''
              }
              arrow
            >
              <StyledHeader fontWeight={600}>
                {row.lessonPlan?.teachingTopic}
              </StyledHeader>
            </Tooltip>
          ),
        },
        {
          field: 'assignedDateTime',
          headerName: t('lessonPlanRequest.columnTitles.assignedDate'),
          width: 200,
          renderCell: ({ row }: any) => (
            <StyledHeader>
              {row.assignedDateTime
                ? dayjs(row.assignedDateTime).format(
                    t('common.format.dayMonthNameYear'),
                  )
                : '---'}
            </StyledHeader>
          ),
        },
        {
          field: 'lastModifiedDateTime',
          headerName: t('lessonPlanRequest.columnTitles.lastModifiedDateTime'),
          width: 200,
          renderCell: ({ row }: any) => (
            <StyledHeader>
              {row.lastModifiedDateTime != null
                ? dayjs(row.lastModifiedDateTime).format(
                    t('common.format.datetime'),
                  )
                : '---'}
            </StyledHeader>
          ),
        },
        {
          field: 'status',
          headerName: t('lessonPlanRequest.columnTitles.status'),
          width: 170,
          renderCell: ({ row }: any) => (
            <Tag isStatus statusType="consultRequest" label={row.status} />
          ),
        },
      ]
    },
    [t],
  )

  return { renderColumn }
}
