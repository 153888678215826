import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { Tag } from './tag.model'

export const DiStrategyTagModel = BaseEntityModel.named('DiStrategyTagModel')
  .props({
    id: types.string,
    diStrategyId: types.string,
    tagId: types.string,
    order: types.number,
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .views((self) => ({
    get tag() {
      if (self.tagId == null) return undefined
      return (
        self.rootStore.masterDataStore as {
          tags: Tag[]
        }
      ).tags.find((tag) => tag.id === self.tagId)
    },
  }))

export type DiStrategyTag = Instance<typeof DiStrategyTagModel>
