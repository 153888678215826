import { Box, Button, Grid, Link, LinkProps, Typography } from '@mui/material'
import { File, FileSource, LessonPlanComment } from '@polyu-dip/models'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import { RepliedToCommentItem } from './replied-to-comment-item'
import {
  CommentActionModal,
  CommentAttachmentType,
  ModalType,
  ReplyValue,
} from './comment-action-modal'
import { useQueryClient } from '@tanstack/react-query'
import {
  clearLessonPlansQueryCaches,
  useCreateLessonPlanComment,
  useDeleteLessonPlanComment,
  useUpdateLessonPlanCommentById,
} from '@polyu-dip/queries'
import { useStores } from '../../../stores'
import { useApiErrorHandle } from '../../../hooks'
import {
  Avatar,
  BYTES_FOR_ONE_MB,
  MAX_FILE_SIZE_IN_MB,
  useOverlay,
} from '../../../components'
import { useBlobStorage } from '../../../services'

const Container = styled(Box)<{ isConsult: boolean }>`
  background-color: ${({ theme }) => theme.palettes.general.white};
  border-radius: 20px;
  padding: 8px 20px 16px 20px;
  box-shadow: 0px 0px 4px
    ${({ isConsult, theme }) =>
      isConsult
        ? theme.palettes.general.highlight
        : theme.palettes.general.transparent};
`

const ProfilePic = styled.div`
  border-radius: 50%;
  margin: 0 8px 0 0;
  display: flex;
`

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palettes.general.blue};
`

const ActionButton = styled(Link)<LinkProps>`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.palettes.general.blue};
  cursor: pointer;
`

const StyledBadgeIcon = styled(Svg.Badge)<{ isExpert?: boolean }>`
  margin-left: 8px;
  display: flex;
  fill: ${({ theme }) => theme.palettes.general.blue};
  visibility: ${({ isExpert }) => (isExpert ? 'visible' : 'hidden')};
`

const CommentContent = styled(Typography)`
  word-break: break-word;
`

const AttachmentLabel = styled(Button)`
  padding-left: ${({ theme }) => theme.spacings.general[2]}px;
  padding-right: ${({ theme }) => theme.spacings.general[2]}px;
  margin-top: ${({ theme }) => theme.spacings.general[2]}px;
  margin-bottom: ${({ theme }) => theme.spacings.general[1]}px;
  border: 1px solid ${({ theme }) => theme.palettes.general.greys[3]};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palettes.general.lightBlue};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  margin-right: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.palettes.general.lightBlue};
    cursor: unset;
  }
`

const StyledLink = styled(Link)`
  cursor: pointer;
`

const ConsultLabel = styled.div`
  padding-left: ${({ theme }) => theme.spacings.general[2]}px;
  padding-right: ${({ theme }) => theme.spacings.general[2]}px;
  padding-top: ${({ theme }) => theme.spacings.general[1]}px;
  padding-bottom: ${({ theme }) => theme.spacings.general[1]}px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palettes.general.blue};
`

const ConsultContent = styled(Typography)`
  color: ${({ theme }) => theme.palettes.general.white};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizes.medium}px;
`

type Props = {
  lessonPlanId: string
  lessonPlanComment: LessonPlanComment
  isOwner?: boolean
  resetPage: () => void
  canComment?: boolean
}

export const LessonPlanCommentCard = observer<Props>(
  ({ lessonPlanComment, isOwner, lessonPlanId, resetPage, canComment }) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const { showSnackbar, showDialog } = useOverlay()
    const { standardErrorHandler } = useApiErrorHandle()
    const { handleUpload, getDownloadUrl } = useBlobStorage()

    const [open, setOpen] = useState(false)
    const [modalType, setModalType] = useState<ModalType>('reply')
    const [newAttachments, setNewAttachments] = useState<
      CommentAttachmentType[]
    >([])
    const [editAttachments, setEditAttachments] = useState<
      CommentAttachmentType[]
    >(
      lessonPlanComment.lessonPlanCommentAttachments.map((it) => ({
        name: it.attachmentName,
        file: {
          url: it.file?.url ?? '',
          source: it.file?.source ?? '',
        },
      })),
    )

    const maxSizeInMb = useMemo(() => MAX_FILE_SIZE_IN_MB, [])

    const isConsult = useMemo(
      () => lessonPlanComment.lessonPlanConsultId != null,
      [lessonPlanComment.lessonPlanConsultId],
    )

    const { mutateAsync: createComment } = useCreateLessonPlanComment(
      useStores,
      lessonPlanId,
      {
        onSuccess() {
          showSnackbar({
            message: t(
              'lessonPlan.action.createLessonPlanComment.successMessage',
            ),
          })
          resetPage()
          clearLessonPlansQueryCaches(queryClient, lessonPlanId, 'comments')
        },
        onError(error) {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.createLessonPlanComment.title',
            ),
          })
        },
      },
    )

    const { mutateAsync: updateComment } = useUpdateLessonPlanCommentById(
      useStores,
      lessonPlanComment.id,
      {
        onSuccess() {
          showSnackbar({
            message: t(
              'lessonPlan.action.updateLessonPlanComment.successMessage',
            ),
          })
          clearLessonPlansQueryCaches(queryClient, lessonPlanId, 'comments')
        },
        onError(error) {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.updateLessonPlanComment.title',
            ),
          })
        },
      },
    )

    const { mutateAsync: deleteComment } = useDeleteLessonPlanComment(
      useStores,
      {
        onSuccess() {
          showSnackbar({
            message: t(
              'lessonPlan.action.deleteLessonPlanComment.successMessage',
            ),
          })
          clearLessonPlansQueryCaches(queryClient, lessonPlanId, 'comments')
        },
        onError(error) {
          standardErrorHandler(error, {
            defaultTitle: t(
              'lessonPlan.errorMessage.deleteLessonPlanComment.title',
            ),
          })
        },
      },
    )

    const handleOnSubmit = useCallback(
      async (action: ModalType, replyValue?: ReplyValue) => {
        if (action === 'delete') {
          await deleteComment(lessonPlanComment.id)
        } else if (action === 'edit') {
          await updateComment({
            content: replyValue?.content,
            rowVersion: lessonPlanComment.rowVersion,
            LessonPlanCommentAttachments: editAttachments.map((attachment) => ({
              attachmentName: attachment.name,
              file: {
                url: attachment.file.url,
                source: attachment.file.source,
              },
            })),
          })
        } else if (action === 'reply') {
          await createComment({
            content: replyValue?.content as string,
            repliedToCommentId: replyValue?.repliedToCommentId,
            LessonPlanCommentAttachments: newAttachments.map((attachment) => ({
              attachmentName: attachment.name,
              file: {
                url: attachment.file.url,
                source: attachment.file.source,
              },
            })),
          })
        }
        setOpen(false)
      },
      [
        newAttachments,
        editAttachments,
        createComment,
        deleteComment,
        lessonPlanComment.id,
        lessonPlanComment.rowVersion,
        updateComment,
      ],
    )

    const handleSelectedFile = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        const { files: fileList } = event.target
        let appendedAttachment: CommentAttachmentType[] = []

        if (!fileList || fileList?.length === 0) {
          return
        }

        for (let i = 0; i < fileList.length; i++) {
          if (
            maxSizeInMb != null &&
            fileList[i].size > maxSizeInMb * BYTES_FOR_ONE_MB
          ) {
            showDialog({
              title: t('attachment.errorMessage.uploadAttachment.title'),
              content: t(
                'attachment.errorMessage.uploadAttachment.exceedMaxFileSize.content',
                { maxSizeInMb },
              ),
              actions: [{ text: t('common.ok'), value: null }],
            })
            return
          }

          // eslint-disable-next-line no-await-in-loop
          const url = await handleUpload(fileList[i])
          appendedAttachment = [
            ...appendedAttachment,
            {
              name: fileList[i].name,
              file: {
                url: url,
                source: FileSource.blobStorage,
              },
            },
          ]
        }
        if (modalType === 'edit') {
          setEditAttachments([...editAttachments, ...appendedAttachment])
        }
        setNewAttachments([...newAttachments, ...appendedAttachment])
      },
      [
        modalType,
        newAttachments,
        editAttachments,
        maxSizeInMb,
        handleUpload,
        showDialog,
        t,
      ],
    )

    const handleOnFilenameClick = useCallback(
      (file?: File) => {
        console.log('handleOnFilenameClick', file)
        if (file == null) {
          return
        }
        if (file.source == FileSource.user) {
          window.open(file.url, '_blank')
        } else {
          getDownloadUrl(file.id).then((downloadUrl: string) => {
            window.open(downloadUrl, '_blank')
          })
        }
      },
      [getDownloadUrl],
    )

    const renderActionButtons = useMemo(() => {
      if (isOwner)
        return (
          <Grid container direction="row" alignItems="center">
            <Grid item mr={3}>
              <ActionButton
                underline="hover"
                onClick={() => {
                  setModalType('edit')
                  setOpen(true)
                }}
              >
                {t('lessonPlan.detail.lessonPlanComments.actions.edit')}
              </ActionButton>
            </Grid>
            <Grid item>
              <StyledTypography>·</StyledTypography>
            </Grid>
            <Grid item ml={3}>
              <ActionButton
                underline="hover"
                onClick={() => {
                  setModalType('delete')
                  setOpen(true)
                }}
              >
                {t('lessonPlan.detail.lessonPlanComments.actions.delete')}
              </ActionButton>
            </Grid>
          </Grid>
        )
      if (canComment)
        return (
          <ActionButton
            underline="hover"
            onClick={() => {
              setModalType('reply')
              setOpen(true)
            }}
          >
            {t('lessonPlan.detail.lessonPlanComments.actions.reply')}
          </ActionButton>
        )
      return <Grid item marginBottom={4} />
    }, [canComment, isOwner, t])

    return (
      <Box mt={6}>
        <Container isConsult={isConsult}>
          <Grid container direction="column">
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item>
                <Typography>
                  {lessonPlanComment.displayCommentNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" mt={1}>
              <Grid item>
                <ProfilePic>
                  <Avatar
                    size="small"
                    name={lessonPlanComment.createdByUser?.displayName}
                  />
                </ProfilePic>
              </Grid>
              <Grid item>
                <Typography>
                  {lessonPlanComment.createdByUser?.displayName}
                </Typography>
              </Grid>
              {lessonPlanComment.isExpert && (
                <Grid item>
                  <StyledBadgeIcon isExpert={lessonPlanComment.isExpert} />
                </Grid>
              )}
              <Grid item ml={2} mr={2}>
                <Typography>·</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {dayjs(lessonPlanComment.lastModifiedDateTime).format(
                    t('common.format.date'),
                  )}
                </Typography>
              </Grid>
              {isConsult && (
                <Grid item ml={2}>
                  <ConsultLabel>
                    <ConsultContent>
                      {t('lessonPlan.detail.lessonPlanComments.consultComment')}
                    </ConsultContent>
                  </ConsultLabel>
                </Grid>
              )}
            </Grid>
            <Grid item mt={3}>
              <CommentContent>{lessonPlanComment.content}</CommentContent>
            </Grid>
            <Grid container direction="column">
              <Grid container direction="row" justifyContent="flex-start">
                {lessonPlanComment.lessonPlanCommentAttachments.map(
                  (attachment) => (
                    <AttachmentLabel
                      key={attachment.attachmentName}
                      disableFocusRipple
                      disableRipple
                    >
                      <StyledLink
                        onClick={() => handleOnFilenameClick(attachment.file)}
                      >
                        {attachment.attachmentName}
                      </StyledLink>
                    </AttachmentLabel>
                  ),
                )}
              </Grid>
            </Grid>
            {lessonPlanComment.repliedToComment != null ? (
              <RepliedToCommentItem
                repliedToComment={lessonPlanComment.repliedToComment}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid container direction="column">
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item>{renderActionButtons}</Grid>
            </Grid>
          </Grid>
          <CommentActionModal
            open={open}
            onClose={() => setOpen(false)}
            onSubmit={handleOnSubmit}
            onAttachFile={handleSelectedFile}
            id={lessonPlanComment.id}
            content={lessonPlanComment.content}
            repliedToCommentContent={
              lessonPlanComment.repliedToComment?.isDeleted
                ? t('lessonPlan.detail.lessonPlanComments.deletedComment')
                : lessonPlanComment.repliedToComment?.content
            }
            modalType={modalType}
            attachments={
              modalType === 'reply' ? newAttachments : editAttachments
            }
            setAttachments={
              modalType === 'reply' ? setNewAttachments : setEditAttachments
            }
          />
        </Container>
      </Box>
    )
  },
)
