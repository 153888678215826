import {
  PaginatedPayload,
  PostSubjectPayload,
  PutSubjectPayload,
  SubjectsQueryParameters,
} from '@polyu-dip/apis'
import { Subject } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'subjects'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useSubjects(
  useStores: UseStoresType,
  param?: SubjectsQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<Subject>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getSubjects(param),
    opts,
  )
}

export function useCreateSubjects(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostSubjectPayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payloads) => masterDataStore.createSubjects(payloads), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useUpdateSubject(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<Subject, unknown, PutSubjectPayload>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => masterDataStore.updateSubject(id, payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useSwitchSubjectStatus(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    Subject,
    unknown,
    PutSubjectPayload & { id: string }
  >,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => masterDataStore.updateSubject(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export async function clearSubjectQueryCaches(queryClient: QueryClient) {
  await queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
