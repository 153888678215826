import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Img } from '../../../assets'
import { contentPaths } from '../../../content-paths'

const Menu = styled.div`
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  justify-content: space-around;
`

const Item = styled(Link)`
  position: relative;
  margin: 0px 8px;
  max-width: 350px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const StyledText = styled.span`
  display: block;
  position: absolute;
  bottom: 15%;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: ${({ theme }) => theme.palettes.general.white};
`

export const TopLink = memo(() => {
  const { t } = useTranslation()

  const menu = [
    {
      text: t('home.teacher.topLink.teachingSample'),
      to: contentPaths.lessonPlanExamples(),
      src: Img.TeacherTeachingSampleBG,
      alt: 'teacher-teaching-sample-bg',
    },
    {
      text: t('home.teacher.topLink.diPlanning'),
      to: contentPaths.diStrategies(),
      src: Img.TeacherDIGuidelineBG,
      alt: 'teacher-di-guideline-bg',
    },
    {
      text: t('home.teacher.topLink.resourceLibrary'),
      to: contentPaths.resources(),
      src: Img.TeacherResourceLibraryBG,
      alt: 'teacher-resource-library-bg',
    },
  ]

  return (
    <Menu>
      {menu.map((it) => (
        <Item to={it.to} key={it.to}>
          <Image src={it.src} alt={it.alt} />
          <StyledText>{it.text}</StyledText>
        </Item>
      ))}
    </Menu>
  )
})
