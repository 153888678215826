import { Button, Grid } from '@mui/material'
import { MasterDataTable } from '@polyu-dip/stores'
import { Dispatch, memo, SetStateAction, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import { TextField } from '../../form'
import { SearchLeftIcon } from '../search-left-icon'

export type MasterDataManagementFilterProps = {
  inputTitle?: string
  setInputTitle?: Dispatch<SetStateAction<string | undefined>>
  handleAddButtonOnClick: () => void
  selectedMasterDataTab: MasterDataTable
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.palettes.general.white};
  padding-bottom: 0px;
`

const StyledButton = styled(Button)`
  margin-left: 8px;
`

export const MasterDataManagementFilter = memo<MasterDataManagementFilterProps>(
  ({
    inputTitle,
    setInputTitle,
    handleAddButtonOnClick,
    selectedMasterDataTab,
  }) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState(inputTitle)

    const handleInputOnChange = useCallback(
      (
        action?: Dispatch<SetStateAction<string | undefined>>,
        value?: string,
      ) => {
        if (action == null) return
        action(value)
      },
      [],
    )

    const handleApplyTitleFilter = useCallback(() => {
      setInputTitle?.(title)
    }, [setInputTitle, title])

    return (
      <Container>
        <Grid container columnGap={2} wrap="nowrap">
          <Grid item flexGrow={1}>
            <TextField
              value={title ?? ''}
              placeholder={t('masterData.filter.title')}
              leftIcon={<SearchLeftIcon />}
              onChange={(e) => {
                handleInputOnChange(setTitle, e.target.value)
              }}
              onStopInput={handleApplyTitleFilter}
            />
          </Grid>
          {selectedMasterDataTab != 'systemParameters' && (
            <Grid item>
              <StyledButton
                startIcon={<Svg.Plus />}
                color="blue"
                onClick={handleAddButtonOnClick}
              >
                {t('masterData.action.add')}
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </Container>
    )
  },
)
