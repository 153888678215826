import { FormControlLabel, Radio } from '@mui/material'
import { memo } from 'react'

export const EmptyRadioLabel = memo(() => (
  <FormControlLabel
    label=""
    value=""
    disabled
    sx={{
      visibility: 'hidden',
    }}
    control={<Radio />}
  />
))
