import { Button, Grid } from '@mui/material'
import { useTheme } from '@polyu-dip/theme'
import { Dispatch, memo, SetStateAction } from 'react'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { ViewModeType } from '../../hooks'

type Props = {
  viewMode: ViewModeType
  setViewMode: Dispatch<SetStateAction<ViewModeType>>
}

const StyledViewModeButton = styled(Button)`
  min-width: 0px;
  padding: ${({ theme }) => theme.spacings.general[2]}px;
`

export const ViewModeButtons = memo<Props>(({ viewMode, setViewMode }) => {
  const theme = useTheme()

  const activeVariant = 'contained'
  const inActiveVariant = 'outlined'

  const activeBackgroundColor = 'lightRed'
  const inActiveBackgroundColor = 'darkestGrey'

  const activeFontColor = theme.palettes.general.white
  const inActiveFontColor = theme.palettes.general.greys[6]

  return (
    <>
      <Grid item>
        <StyledViewModeButton
          variant={
            viewMode === ViewModeType.list ? activeVariant : inActiveVariant
          }
          color={
            viewMode === ViewModeType.list
              ? activeBackgroundColor
              : inActiveBackgroundColor
          }
          onClick={() => setViewMode(ViewModeType.list)}
        >
          <Svg.List
            fill={
              viewMode === ViewModeType.list
                ? activeFontColor
                : inActiveFontColor
            }
          />
        </StyledViewModeButton>
      </Grid>
      <Grid item>
        <StyledViewModeButton
          variant={
            viewMode === ViewModeType.Grid ? activeVariant : inActiveVariant
          }
          color={
            viewMode === ViewModeType.Grid
              ? activeBackgroundColor
              : inActiveBackgroundColor
          }
          onClick={() => setViewMode(ViewModeType.Grid)}
        >
          <Svg.Grid
            fill={
              viewMode === ViewModeType.Grid
                ? activeFontColor
                : inActiveFontColor
            }
          />
        </StyledViewModeButton>
      </Grid>
    </>
  )
})
