import { Grid } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'
import { useComputed } from '@polyu-dip/helpers'
import { useLessonPlanConsultsAssigned } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ConsultDetailDialog, SectionTitle, Table } from '../../../components'
import { contentPaths } from '../../../content-paths'
import { useLessonPlanConsultRequestColumn } from '../../lesson-plan-requests'

type Props = {}

const Container = styled.div`
  margin-top: 56px;
`

const PAGINATION_LIMIT = 5

export const OutstandingLessonPlansTable = observer<Props>(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { renderColumn } = useLessonPlanConsultRequestColumn()

  const [sort, setSort] = useState<GridSortModel>()

  const [openConsultDetailDialog, setOpenConsultDetailDialog] = useState(false)
  const [targetRequestId, setTargetRequestId] = useState<string>()
  const [requestText, setRequestText] = useState<string>()
  const [requireObservation, setRequireObservation] = useState<boolean>()

  const handleLetterOnClick = useCallback(
    (requestId: string, consultContent: string, isSiteVisitNeeded: boolean) => {
      setOpenConsultDetailDialog(true)
      setTargetRequestId(requestId)
      setRequestText(consultContent)
      setRequireObservation(isSiteVisitNeeded)
    },
    [],
  )

  const { data: followingUpRequests, isLoading: isFollowingUpRequestsLoading } =
    useLessonPlanConsultsAssigned({
      expand: ['LessonPlan'],
      status: 'followingUp',
      sort: sort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'status', direction: 'desc' }],
    })

  const computedConsultRequestData = useComputed(() => {
    return followingUpRequests?.data ?? []
  }, [followingUpRequests])

  const consultRequestTotalRecord = useComputed(() => {
    return followingUpRequests?.total ?? 0
  }, [followingUpRequests])

  const isConsultRequestLoading = useComputed(() => {
    return isFollowingUpRequestsLoading
  }, [isFollowingUpRequestsLoading])

  return (
    <Container>
      <Grid container direction="column">
        <Grid item>
          <SectionTitle
            title={t('home.outstandingLessonPlanTable.consult.headerTitle', {
              pendingCount: consultRequestTotalRecord,
            })}
            color="brown"
          />

          <Table
            columns={renderColumn(handleLetterOnClick)}
            rows={computedConsultRequestData}
            pageSize={PAGINATION_LIMIT}
            loading={isConsultRequestLoading}
            hideFooterPagination
            showMoreButton
            handleShowMore={() => {
              navigate(contentPaths.lessonPlanConsultRequests())
            }}
            sortModel={sort}
            onSortModelChange={setSort}
            onRowClick={({ row }) => {
              navigate(contentPaths.lessonPlanConsultRequest(row.id))
            }}
          />
        </Grid>
      </Grid>
      <ConsultDetailDialog
        open={openConsultDetailDialog}
        onClose={() => setOpenConsultDetailDialog(false)}
        requestId={targetRequestId}
        requestText={requestText}
        requireObservation={requireObservation}
        onSubmit={() => {
          navigate(contentPaths.lessonPlanConsultRequest(targetRequestId ?? ''))
        }}
      />
    </Container>
  )
})
