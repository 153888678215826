import { Box, Tabs } from '@mui/material'
import { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { Tab } from './tab'

type OwnProps = {
  $activeTab: number
  $activeColor?: string
  $isTabHidden?: boolean
}

type Props = {
  isTabHidden?: boolean
  minTabWidth?: number
  activeTab: number
  activeColor?: string
  tabMenus?: { label: ReactNode; id: string }[]
  onActiveTabChange?: (id: number) => void
  children?: ReactNode
}

function a11yProps(index: number | string) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const StyledTabs = styled(Tabs)<OwnProps>`
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.palettes.general.greys[3]};
  padding-right: 20px;
  display: ${({ $isTabHidden }) => ($isTabHidden ? 'none' : 'block')};

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTab-root.Mui-selected {
    color: ${({ theme, $activeColor }) =>
      $activeColor ?? theme.palettes.general.white};
    background-color: ${({ theme }) => theme.palettes.general.blue};
  }

  .Mui-selected {
    &.MuiTab-root:first-child {
      border-left-color: ${({ theme }) => theme.palettes.general.blue};
    }
    &.MuiTab-root:last-child {
      border-right-color: ${({ theme }) => theme.palettes.general.blue};
    }
  }
`

const TabPanelContainer = styled.div`
  width: 100%;
`

export const VerticalTabs = memo<Props>(
  ({
    activeTab,
    activeColor,
    tabMenus,
    onActiveTabChange,
    minTabWidth,
    children,
    isTabHidden,
  }) => {
    return (
      <Box display="flex" height={'95%'}>
        <StyledTabs
          $activeTab={activeTab}
          $activeColor={activeColor}
          $isTabHidden={isTabHidden}
          orientation="vertical"
          value={activeTab}
          onChange={(e, value) => onActiveTabChange?.(value)}
        >
          {tabMenus?.map(({ label }, index) => (
            <Tab
              $isVertical
              key={index}
              icon={<Svg.ChevronRight />}
              iconPosition="start"
              label={label}
              value={index}
              onClick={() => onActiveTabChange?.(index)}
              $minWidth={minTabWidth}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>
        <TabPanelContainer>{children}</TabPanelContainer>
      </Box>
    )
  },
)
