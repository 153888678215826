import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectOption } from '../components'

export function useSchoolFilter() {
  const { t } = useTranslation()

  const [selectedStatus, setSelectedStatus] = useState<SelectOption>()
  const [nameFilter, setNameFilter] = useState<string>()
  const [shortCodeFilter, setShortCodeFilter] = useState<string>()

  const statusOptions = useMemo(
    (): SelectOption[] => [
      {
        label: t('schools.status.active'),
        value: 'active',
      },
      {
        label: t('schools.status.disabled'),
        value: 'disabled',
      },
    ],
    [t],
  )

  const filterItems = useMemo(() => {
    return {
      isDisabled:
        selectedStatus == null
          ? undefined
          : selectedStatus?.value === 'disabled',
      name: nameFilter == null || nameFilter == '' ? undefined : nameFilter,
      shortCode:
        shortCodeFilter == null || shortCodeFilter == ''
          ? undefined
          : shortCodeFilter,
    }
  }, [nameFilter, selectedStatus, shortCodeFilter])

  return {
    filterItems,
    statusOptions,
    selectedStatus,
    setSelectedStatus,
    nameFilter,
    setNameFilter,
    shortCodeFilter,
    setShortCodeFilter,
  }
}
