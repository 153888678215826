import { Box, Typography } from '@mui/material'
import { createEmptyLessonPlanStudentGroup } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RadioGroup, SectionTitle, useOverlay } from '../../../../../components'
import { SectionBox } from '../../../components'
import { GeneralInformationFormData } from '../../../lesson-plan-form-provider'
import { TabHeader } from '../tab-header'
import { LearningTargetExtra } from './learning-target-extra'

type LearningTargetTabProps = {}

export const LearningTargetTab = observer<LearningTargetTabProps>(() => {
  const { t } = useTranslation()
  const { control, watch, setValue } =
    useFormContext<GeneralInformationFormData>()

  const isMultiGroup = watch('isMultiGroup')

  const teachingTypeOptions = useMemo(() => {
    return [
      {
        label: t(
          'lessonPlan.createForm.generalInformation.fields.teachingType.options.wholeClass',
        ),
        value: false,
      },
      {
        label: t(
          'lessonPlan.createForm.generalInformation.fields.teachingType.options.basedOnStudentTraitGroups',
        ),
        value: true,
      },
    ]
  }, [t])

  const { showDialog } = useOverlay()

  const handleIsMultiGroupChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChangedToMultiGroup =
        (event.target as HTMLInputElement).value === 'true'

      let allowed: boolean | undefined = true

      const groups = watch('lessonPlanStudentGroups')

      const isStringValid = (str?: string) => str != null && str.length > 0

      if (
        isChangedToMultiGroup !== watch('isMultiGroup') &&
        groups.some(
          ({ order, groupCharacteristic, ...group }) =>
            isStringValid(groupCharacteristic) ||
            group.lessonPlanExpectedOutcomes.some((it) =>
              isStringValid(it.description),
            ) ||
            group.lessonPlanTeachingGoals.some((it) =>
              isStringValid(it.description),
            ) ||
            group.lessonPlanTeachingSteps.some(
              (it) =>
                isStringValid(it.activity) ||
                isStringValid(it.description) ||
                isStringValid(it.duration?.toString()) ||
                isStringValid(it.resource) ||
                it.lessonPlanTeachingStepDiStrategies.some(
                  (di) => di.diStrategyId != null,
                ),
            ),
        )
      ) {
        allowed = await showDialog({
          title: t(
            'lessonPlan.createForm.generalInformation.fields.teachingType.changeTypeConfirmTitle',
          ),
        })
      }

      if (allowed) {
        setValue('isMultiGroup', isChangedToMultiGroup)
        if (isChangedToMultiGroup) {
          setValue('lessonPlanStudentGroups', [
            createEmptyLessonPlanStudentGroup(0),
            createEmptyLessonPlanStudentGroup(1),
            createEmptyLessonPlanStudentGroup(2),
          ])
        } else {
          setValue('lessonPlanStudentGroups', [
            createEmptyLessonPlanStudentGroup(0),
          ])
        }
      }
    },
    [setValue, showDialog, t, watch],
  )

  return (
    <Box>
      <SectionBox>
        <TabHeader />
        <Box mt={4}>
          <Box>
            <Controller
              control={control}
              render={({ field }) => (
                <RadioGroup
                  label={t(
                    'lessonPlan.createForm.generalInformation.fields.teachingType.label',
                  )}
                  options={teachingTypeOptions}
                  row
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  onChange={handleIsMultiGroupChange}
                  size="small"
                />
              )}
              name="isMultiGroup"
            />
          </Box>
        </Box>
      </SectionBox>
      <SectionBox>
        <Box>
          {isMultiGroup === undefined ? (
            <Typography color="primary">
              {t(
                'lessonPlan.createForm.generalInformation.fields.teachingType.remark',
              )}
            </Typography>
          ) : (
            <SectionTitle
              mb={0}
              color={isMultiGroup ? 'astronautBlue' : 'orange'}
              title={
                isMultiGroup
                  ? t(
                      'lessonPlan.createForm.generalInformation.fields.teachingType.options.basedOnStudentTraitGroups',
                    )
                  : t(
                      'lessonPlan.createForm.generalInformation.fields.teachingType.options.wholeClass',
                    )
              }
            />
          )}
        </Box>
      </SectionBox>
      <LearningTargetExtra />
    </Box>
  )
})
