import { Grid, Typography } from '@mui/material'
import { LessonPlanComment } from '@polyu-dip/models'
import dayjs from 'dayjs'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../assets'

const Container = styled(Grid)`
  border-left: 2px solid;
  border-color: ${({ theme }) => theme.palettes.general.greys[3]};
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 99px;
  padding-left: 10px;
  padding-right: 43px;
`

const StyledTypography = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palettes.general.greys[2]};
`

const RepliedNumber = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
`

const Content = styled(Typography)`
  color: ${({ theme }) => theme.palettes.general.greys[2]};
  word-break: break-word;
`

const StyledReplyIcon = styled(Svg.Reply)`
  path {
    fill: ${({ theme }) => theme.palettes.general.greys[2]};
  }
`

const StyledBadgeIcon = styled(Svg.Badge)<{ isExpert?: boolean }>`
  margin-left: 8px;
  display: flex;
  fill: ${({ theme }) => theme.palettes.general.blue};
  visibility: ${({ isExpert }) => (isExpert ? 'visible' : 'hidden')};
`

type Props = {
  repliedToComment: LessonPlanComment
}

export const RepliedToCommentItem = memo<Props>(({ repliedToComment }) => {
  const { t } = useTranslation()
  return (
    <Container mt={1}>
      {repliedToComment.isDeleted ? (
        <Grid item>
          <Content>
            {t('lessonPlan.detail.lessonPlanComments.deletedComment')}
          </Content>
        </Grid>
      ) : (
        <>
          <Grid container direction="row">
            <Grid item mr={2}>
              <RepliedNumber>{`${t(
                'lessonPlan.detail.lessonPlanComments.reply',
              )} ${repliedToComment.displayCommentNumber}`}</RepliedNumber>
            </Grid>
            <Grid item mr={2} display="flex" alignItems="center">
              <StyledReplyIcon />
            </Grid>
            <Grid item display="flex" alignItems="center">
              <StyledTypography>
                {repliedToComment.createdByUser?.displayName}
              </StyledTypography>
            </Grid>
            {repliedToComment.isExpert && (
              <Grid item display="flex" alignItems="center">
                <StyledBadgeIcon isExpert={repliedToComment.isExpert} />
              </Grid>
            )}
            <Grid item display="flex" alignItems="center" ml={2} mr={2}>
              <StyledTypography>·</StyledTypography>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <StyledTypography>
                {dayjs(repliedToComment.lastModifiedDateTime).format(
                  t('common.format.date'),
                )}
              </StyledTypography>
            </Grid>
          </Grid>
          <Grid item>
            <Content>{repliedToComment.content}</Content>
          </Grid>
        </>
      )}
    </Container>
  )
})
