import { createContext, useContext } from 'react'

type Context = {
  lessonPlanFormData: any
  setLessonPlanFormData: (lessonPlanFormData: any) => void
}

export const LessonPlanFormContext = createContext<Context>({} as any)

export function useLessonPlanFormContext() {
  return useContext(LessonPlanFormContext)
}
