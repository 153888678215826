import {
  FormControl,
  RadioGroupProps,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
} from '@mui/material'
import { useMemo } from 'react'
import styled from 'styled-components'
import { v4 as uuidV4 } from 'uuid'
import { FormLabel } from '../form-label'
import { FormHelperText } from '../text-field'
import { EmptyRadioLabel } from './empty-radio-label'

const StyledFormControl = styled(FormControl)<{ row?: boolean }>`
  margin-bottom: 16px;
  width: 100%;
  ${({ row }) =>
    row
      ? `
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    `
      : ''}
`

const StyledRadioGroup = styled(MuiRadioGroup)<{ row?: boolean }>`
  display: flex;
  flex: 4;
  margin-top: ${({ row }) => (row ? 0 : 24)}px;
  margin-left: ${({ row }) => (row ? 14 : 24)}px;
  .MuiRadio-root {
    padding: 0 6px;
  }
`

const LabelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const StyledFormHelperText = styled(FormHelperText)`
  margin-left: 0px;
`

type Props = RadioGroupProps & {
  label?: string
  required?: boolean
  options: Omit<FormControlLabelProps, 'control'>[]
  error?: string
  isReserverForHelperText?: boolean
  size?: 'small' | 'medium'
}

export const RadioGroup = ({
  label,
  id,
  required,
  options,
  row,
  error,
  isReserverForHelperText,
  size,
  ...props
}: Props) => {
  const uuid = useMemo(() => uuidV4(), [])

  return (
    <>
      <StyledFormControl row={row}>
        <LabelContainer>
          {label && (
            <FormLabel label={label} id={id ?? uuid} required={required} />
          )}
          {isReserverForHelperText && error != null && (
            <StyledFormHelperText error={error} />
          )}
        </LabelContainer>
        <StyledRadioGroup
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          row={row}
        >
          {options.length > 0 ? (
            options.map((it, i) => (
              <FormControlLabel
                key={`${it.value ?? i}`}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...it}
                control={<Radio size={size} />}
              />
            ))
          ) : (
            <EmptyRadioLabel />
          )}
        </StyledRadioGroup>
      </StyledFormControl>
    </>
  )
}
