import { memo } from 'react'
import styled from 'styled-components'
import { Spinner } from '../spinner'

const Root = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FullscreenLoading = memo(() => (
  <Root>
    <Spinner />
  </Root>
))
