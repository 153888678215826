import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Dialog, DialogAction } from '../dialog'
import { AutoComplete } from '../form'

type Props = {
  open: boolean
  onClose: () => void
  title: string
  onSubmit: (selectedUser: { label: string; value: string }) => Promise<void>
  defaultSelectedUser?: { label: string; value: string }
  userOptions: { label: string; value: string }[]
}

const Container = styled.div`
  padding-top: 20px;
`

export const SelectUserDialog = observer<Props>(
  ({ open, onClose, onSubmit, defaultSelectedUser, userOptions, title }) => {
    const { t } = useTranslation()

    const [selectedUser, setSelectedUser] = useState<
      | {
          label: string
          value: string
        }
      | undefined
    >(defaultSelectedUser)

    useEffect(() => {
      if (open) {
        setSelectedUser(defaultSelectedUser)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const handleOnClose = useCallback(() => {
      setSelectedUser(defaultSelectedUser)
      onClose()
    }, [defaultSelectedUser, onClose])

    const handleOnSubmit = useCallback(
      async (value: boolean) => {
        if (!value) {
          handleOnClose()
          return
        }
        if (selectedUser == null) return
        await onSubmit(selectedUser)
        setSelectedUser(defaultSelectedUser)
      },
      [defaultSelectedUser, handleOnClose, onSubmit, selectedUser],
    )
    const actions = useMemo((): DialogAction<boolean>[] => {
      return [
        {
          text: t('common.close'),
          type: 'outlined',
          value: false,
        },
        {
          text: t('common.confirm'),
          type: 'contained',
          color: 'blue',
          disabled: selectedUser == null,
          value: true,
        },
      ]
    }, [selectedUser, t])

    return (
      <Dialog
        open={open}
        title={title}
        actions={actions}
        onSelected={handleOnSubmit}
        maxWidth="md"
        fullWidth
      >
        <Container>
          <AutoComplete
            value={selectedUser}
            placeholder={t('lessonPlan.detail.assignExpert.assign.email')}
            options={userOptions}
            onChange={(e, value) => setSelectedUser(value)}
          />
        </Container>
      </Dialog>
    )
  },
)
