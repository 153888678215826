import {
  getLessonPlanConsultRequestsAssigned,
  getLessonPlanPublishRequestById,
  getOutstandingLessonPlanRequests,
  LessonPlanConsultRequestExpandableParameter,
  LessonPlanConsultRequestQueryParameters,
  LessonPlanConsultRequestResponsePayload,
  LessonPlanConsultRequestsResponsePayload,
  LessonPlanPublishRequestExpandableParameter,
  LessonPlanPublishRequestResponsePayload,
  OutstandingLessonPlanRequestQueryParameters,
  OutstandingLessonPlanRequestResponsePayload,
  PostLessonPlanConsultWithExpertPayload,
} from '@polyu-dip/apis'
import { LessonPlanConsult } from '@polyu-dip/models'
import { LessonPlansStore } from '@polyu-dip/stores'
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'lesson-plan-requests'

type UseStoresType = () => { lessonPlansStore: LessonPlansStore }

export function useOutstandingLessonPlanRequests(
  param?: OutstandingLessonPlanRequestQueryParameters,
  opts?: UseQueryOptions<OutstandingLessonPlanRequestResponsePayload | null>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => getOutstandingLessonPlanRequests(param),
    opts,
  )
}

export function useLessonPlanPublishRequest(
  id: string,
  param?: LessonPlanPublishRequestExpandableParameter,
  opts?: UseQueryOptions<LessonPlanPublishRequestResponsePayload | null>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, id] as QueryKey,
    () => getLessonPlanPublishRequestById(id, param),
    opts,
  )
}

export function useWithdrawLessonPlanSubmitPublishRequest(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown, string>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (rowVersion) =>
      lessonPlansStore.withdrawLessonPlanPublishRequest(id, rowVersion),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([
          QUERY_CACHE_KEY_ROOT,
          id,
          'withdraw-publish-request',
        ])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useApproveLessonPlanPublishRequest(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown, string>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (rowVersion) =>
      lessonPlansStore.approveLessonPlanPublishRequest(id, rowVersion),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useRejectLessonPlanPublishRequest(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown, string>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (rowVersion) =>
      lessonPlansStore.rejectLessonPlanPublishRequest(id, rowVersion),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useCompleteAndPublishLessonPlanPublishRequest(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<any, unknown, string>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (rowVersion) =>
      lessonPlansStore.completeAndPublishLessonPlanPublishRequest(
        id,
        rowVersion,
      ),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useLessonPlanConsultsAssigned(
  param?: LessonPlanConsultRequestQueryParameters,
  opts?: UseQueryOptions<LessonPlanConsultRequestsResponsePayload | null>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => getLessonPlanConsultRequestsAssigned(param),
    opts,
  )
}
export function useLessonPlanConsultRequest(
  useStores: UseStoresType,
  id: string,
  param?: LessonPlanConsultRequestExpandableParameter,
  opts?: UseQueryOptions<LessonPlanConsultRequestResponsePayload | null>,
) {
  const { lessonPlansStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, id] as QueryKey,
    () => lessonPlansStore.getConsultRequest(id, param),
    opts,
  )
}

export function useCreateLessonPlanConsultRequestWithExpert(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<
    LessonPlanConsult,
    unknown,
    PostLessonPlanConsultWithExpertPayload
  >,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => lessonPlansStore.createConsultRequestWithExpert(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useCreateLessonPlanConsultRequestComplete(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<LessonPlanConsult, unknown, string>,
) {
  const { lessonPlansStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (rowVersion) =>
      lessonPlansStore.createConsultRequestComplete(id, rowVersion),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}
