import {
  LearningPhasesQueryParameters,
  PaginatedPayload,
  PostLearningPhasePayload,
  PutLearningPhasePayload,
} from '@polyu-dip/apis'
import { LearningPhase } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'learning-phases'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useLearningPhases(
  useStores: UseStoresType,
  param?: LearningPhasesQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<LearningPhase>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getLearningPhases(param),
    opts,
  )
}

export function useCreateLearningPhases(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostLearningPhasePayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payloads) => masterDataStore.createLearningPhases(payloads),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useUpdateLearningPhase(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<LearningPhase, unknown, PutLearningPhasePayload>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => masterDataStore.updateLearningPhase(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useSwitchLearningPhaseStatus(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    LearningPhase,
    unknown,
    PutLearningPhasePayload & { id: string }
  >,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => masterDataStore.updateLearningPhase(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export async function clearLearningPhaseQueryCaches(queryClient: QueryClient) {
  await queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
