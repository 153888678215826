import { GridSortModel } from '@mui/x-data-grid'
import { useLessonPlansOwned } from '@polyu-dip/queries'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ListPage, Table, useRowCountState } from '../../components'
import { contentPaths } from '../../content-paths'
import { useStores } from '../../stores'
import { observer } from 'mobx-react-lite'
import { PaginationParameters } from '@polyu-dip/apis'
import { useMasterDataInit } from '../../hooks'
import { useDisposedLessonPlansColumn } from './use-disposed-lesson-plan-column'

const PAGINATION_LIMIT = 15

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

export const DisposedLessonPlan = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isAllMasterDataFetchSuccess } = useMasterDataInit()
  const { renderColumn } = useDisposedLessonPlansColumn()

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [disposedSort, setDisposedSort] = useState<GridSortModel>()

  const {
    data: disposedLessonPlansData,
    isLoading: isdisposedLessonPlanLoading,
  } = useLessonPlansOwned(useStores, {
    expand: ['ClassLevel'],
    sort: disposedSort?.map((it) => ({
      direction: it.sort ?? 'desc',
      parameter: it.field,
    })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
    ...pagination,
  })

  const { rowCountState } = useRowCountState(disposedLessonPlansData?.total)

  return (
    <ListPage
      sectionTitleProps={{
        title: t('disposedLessonPlan.title'),
      }}
    >
      <Table
        columns={renderColumn()}
        rows={disposedLessonPlansData?.data ?? []}
        pageSize={PAGINATION_LIMIT}
        loading={!isAllMasterDataFetchSuccess || isdisposedLessonPlanLoading}
        paginationMode="server"
        page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
        hideFooterPagination={
          rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
        }
        rowCount={rowCountState}
        sortModel={disposedSort}
        onSortModelChange={setDisposedSort}
        onRowClick={({ row }) =>
          navigate(contentPaths.disposedLessonPlans(row.id, 'view'))
        }
        onPageChange={(page) =>
          setPagination((old) => ({
            ...old,
            offset: page * PAGINATION_LIMIT,
          }))
        }
      />
    </ListPage>
  )
})
