import { ApiCore } from '@polyu-dip/api-core'
import { LessonPlanCommentsResponsePayload } from '../lesson-plans'
import { PatchLessonPlanCommentPayload } from './lesson-plan-comments-api.types'

const PATH = 'lesson-plan-comments'

export function patchLessonPlanCommentById(
  id: string,
  payload: PatchLessonPlanCommentPayload,
) {
  return ApiCore.primary.patch<LessonPlanCommentsResponsePayload>(
    `${PATH}/${id}`,
    {
      body: payload,
    },
  )
}

export function deleteLessonPlanComment(id: string) {
  return ApiCore.primary.delete(`${PATH}/${id}`)
}
