import { types } from 'mobx-state-tree'

/**
 * data for ui
 * */
export const UiStoreModel = types
  .model('UiStore')
  .props({
    language: types.optional(types.string, 'zh-Hant'),
  })
  .actions((self) => ({
    changeLanguage: (language: string) => {
      self.language = language
    },
  }))
