import { Box, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormSetValue,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  AutoComplete,
  FormHelperText,
  FormLabel,
  SelectTagDialog,
  TextField,
} from '../../../../../components'
import { GeneralInformationFormData } from '../../../lesson-plan-form-provider'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useLessonPlanFormContext } from '../../../use-lesson-plan-form-context'
import { useLessonPlanPermission } from '../../../../../hooks'

const Container = styled.div``

const Section = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palettes.general.white,
  borderRadius: 30,
  padding: 28,
  margin: '20px 0px',
}))

type OverviewTabExtraProps = {
  control: Control<GeneralInformationFormData, any>
  errors: Partial<FieldErrorsImpl<GeneralInformationFormData>>
  setValue: UseFormSetValue<GeneralInformationFormData>
  tagIds?: string[]
}

export const OverviewTabExtra = observer(
  ({ control, errors, setValue, tagIds }: OverviewTabExtraProps) => {
    const { t } = useTranslation()
    const { lessonPlanFormData } = useLessonPlanFormContext()
    const { lessonPlanPermissionHandler } = useLessonPlanPermission()
    const { canEditLessonPlan } =
      lessonPlanPermissionHandler(lessonPlanFormData)

    const academicYearOptions = useMemo(() => {
      const currentYear = dayjs().get('year')
      if (currentYear == null) return undefined
      return Array(7)
        .fill(currentYear)
        .map((year, index) => year + index - 5)
    }, [])

    return (
      <Container>
        <Section>
          <Grid container columnSpacing={5}>
            <Grid item sm={6}>
              <Controller
                control={control}
                render={({ field }) => (
                  <AutoComplete
                    label={t(
                      'lessonPlan.createForm.generalInformation.fields.academicYear.label',
                    )}
                    placeholder={t(
                      'lessonPlan.createForm.generalInformation.fields.academicYear.placeholder',
                    )}
                    required
                    options={academicYearOptions ?? []}
                    getOptionLabel={(option) =>
                      option != null
                        ? t(
                            'lessonPlan.createForm.generalInformation.fields.academicYear.displayFormat',
                            {
                              academicYearStart: option,
                              academicYearEnd: option + 1,
                            },
                          )
                        : ''
                    }
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    onChange={(e, value) => {
                      field.onChange({ target: { value } })
                    }}
                  />
                )}
                name="academicYear"
              />
            </Grid>
            {canEditLessonPlan || lessonPlanFormData.id == '' ? (
              <Grid item flexGrow={1}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t(
                        'lessonPlan.createForm.generalInformation.fields.teachingClass.label',
                      )}
                      placeholder={t(
                        'lessonPlan.createForm.generalInformation.fields.teachingClass.placeholder',
                      )}
                      error={errors?.teachingClass?.message}
                      isReserverForHelperText
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                  )}
                  name="teachingClass"
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Box>
            <FormLabel
              label={t(
                'lessonPlan.createForm.generalInformation.fields.classCharacteristics.label',
              )}
              required
            />
          </Box>
          {errors.classCharacteristicTags?.message && (
            <FormHelperText error={errors.classCharacteristicTags?.message} />
          )}
          <SelectTagDialog
            onSelect={(value) => setValue('classCharacteristicTags', value)}
            initialTagIds={tagIds}
          />
        </Section>
      </Container>
    )
  },
)
