import { ApiError } from './api-error'

export type ErrorDetailPayloadItem<ExtraType = never> = {
  code: string
  extra: ExtraType extends never
    ? { [key: string]: any | any[] } | undefined
    : ExtraType
} & { [key: string]: any | any[] }

export type ErrorPayload<ExtraType = never> = {
  traceId: string
  details?: ErrorDetailPayloadItem<ExtraType>[]
}

export type ApiFormattedError<D = never> = ApiError<ErrorPayload<D>>

export type FormattedApiError<ExtraType = never> = {
  kind: ApiError['kind']
  formatted: true
  isTemporary: boolean
  traceId: string
  errors: ErrorDetailPayloadItem<ExtraType>[]
}

export function formatApiError<ExtraType = never>(
  error: any,
): FormattedApiError | (ApiError & { formatted?: undefined }) | null {
  if (error instanceof ApiError) {
    const apiError = error as ApiError<ErrorPayload<ExtraType>>

    if (apiError.response?.data?.details !== undefined) {
      return {
        kind: apiError.kind,
        formatted: true,
        isTemporary: apiError.isTemporary,
        traceId: apiError.response.data.traceId,
        errors: (apiError.response.data.details ??
          []) as ErrorDetailPayloadItem[],
      }
    }

    return error
  }
  return null
}
