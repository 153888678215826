import { MsalProvider } from '@azure/msal-react'
import { QueryClientProvider } from '@tanstack/react-query'
import { memo, PropsWithChildren } from 'react'
import { ErrorBoundary, LoginLoading } from './components'
import { Content } from './content'
import { useInit } from './init'
import { useAuthInit } from './services'
import { RootStoreProvider } from './stores'
import { UiProvider } from './theme'

const MsalContainer = memo<PropsWithChildren<{}>>(({ children }) => {
  const msalInstance = useAuthInit()
  if (msalInstance == null) return <LoginLoading />

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
})

const InitContainer = memo<PropsWithChildren<{}>>(({ children }) => {
  const initResult = useInit()

  if (initResult == null) return <LoginLoading />

  return (
    <QueryClientProvider client={initResult.queryClient}>
      <RootStoreProvider value={initResult.rootStore}>
        {children}
      </RootStoreProvider>
    </QueryClientProvider>
  )
})

export const App = memo(() => (
  <UiProvider>
    <ErrorBoundary>
      <InitContainer>
        <MsalContainer>
          <Content />
        </MsalContainer>
      </InitContainer>
    </ErrorBoundary>
  </UiProvider>
))
