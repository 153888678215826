import { Box, Grid, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { LessonPlanTeachingStepDiStrategy } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { SectionTitle, Tag, Text } from '../../../../../components'
import { sortByOrder } from '../../../../../utilities'
import { useLessonPlanDetail } from '../../../use-lesson-plan-detail-context'
import { GroupHeader } from './group-header'

function sortByStrategy(
  a: LessonPlanTeachingStepDiStrategy,
  b: LessonPlanTeachingStepDiStrategy,
) {
  return (
    (a.diStrategy?.diStrategyClass?.order ?? 0) +
    (a.diStrategy?.order ?? 0) -
    ((b.diStrategy?.diStrategyClass?.order ?? 0) + (b.diStrategy?.order ?? 0))
  )
}

export const TeachingProcess = observer(() => {
  const { t } = useTranslation()

  const { lessonPlan } = useLessonPlanDetail()

  const lessonPlanStudentGroups = useComputed(() => {
    return lessonPlan.sortedLessonPlanStudentGroups.map((group) => ({
      ...group,
      lessonPlanExpectedOutcomes: group?.lessonPlanExpectedOutcomes
        .slice()
        .sort(sortByOrder),
      lessonPlanTeachingGoals: group?.lessonPlanTeachingGoals
        .slice()
        .sort(sortByOrder),
      lessonPlanTeachingSteps: group?.lessonPlanTeachingSteps
        .slice()
        .sort(sortByOrder),
    }))
  }, [])

  return (
    <Box>
      {lessonPlan.isMultiGroup && (
        <SectionTitle
          color="lightBlue"
          title={t(
            'lessonPlan.detail.general.teachingPlanTab.teachingProcess.processOfEachGroup.title',
          )}
        />
      )}
      {lessonPlanStudentGroups.map((group, i) => (
        <Box key={i} mb={5}>
          {lessonPlan.isMultiGroup && (
            <GroupHeader groupCharacteristic={group.groupCharacteristic} />
          )}
          <Grid container columnSpacing={3} flexWrap="nowrap" mb={3}>
            <Grid item width={120} flexShrink={0}>
              <Typography fontWeight={600}>
                {t(
                  'lessonPlan.detail.general.teachingPlanTab.teachingProcess.processOfEachGroup.timeInMinute',
                )}
              </Typography>
            </Grid>
            <Grid item width={160} flexShrink={0}>
              <Typography fontWeight={600}>
                {t(
                  'lessonPlan.detail.general.teachingPlanTab.teachingProcess.processOfEachGroup.activity',
                )}
              </Typography>
            </Grid>
            <Grid item width={250} flexShrink={0}>
              <Typography fontWeight={600}>
                {t(
                  'lessonPlan.detail.general.teachingPlanTab.teachingProcess.processOfEachGroup.strategy',
                )}
              </Typography>
            </Grid>
            <Grid item flexGrow={1}>
              <Typography fontWeight={600}>
                {t(
                  'lessonPlan.detail.general.teachingPlanTab.teachingProcess.processOfEachGroup.description',
                )}
              </Typography>
            </Grid>
          </Grid>
          {group.lessonPlanTeachingSteps.map((step) => (
            <Grid
              key={step.id}
              container
              columnSpacing={3}
              flexWrap="nowrap"
              mb={3}
            >
              <Grid item width={120} flexShrink={0}>
                <Text>{step.duration}</Text>
              </Grid>
              <Grid item width={160} flexShrink={0}>
                <Text>{step.activity}</Text>
              </Grid>
              <Grid item width={250} flexShrink={0}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                  {step.lessonPlanTeachingStepDiStrategies
                    .slice()
                    .sort(sortByStrategy)
                    .map(({ diStrategy, ...strategy }) => (
                      <Grid item key={strategy.id} maxWidth={250}>
                        <Tag
                          label={t('lessonPlan.createForm.diStrategies.label', {
                            diStrategyClassLabel:
                              diStrategy?.diStrategyClass?.label,
                            diStrategyLabelNumber: diStrategy?.order,
                            diStrategyName: diStrategy?.displayName,
                          })}
                          color="primary"
                          colorCustomize={diStrategy?.colorCode}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid item flexGrow={1}>
                <Text>{step.description}</Text>
              </Grid>
            </Grid>
          ))}
        </Box>
      ))}

      <Grid container columnSpacing={5} flexWrap="nowrap" my={5}>
        <Grid item width={180} flexShrink={0}>
          <Typography fontWeight={600} mb={2}>
            {t(
              'lessonPlan.createForm.generalInformation.fields.followUpItems.label',
            )}
          </Typography>
        </Grid>
        <Grid item flexGrow={1}>
          <Text>{lessonPlan.followUp}</Text>
        </Grid>
      </Grid>
      <Grid container columnSpacing={5} flexWrap="nowrap">
        <Grid item width={180} flexShrink={0}>
          <Typography fontWeight={600} mb={2}>
            {t(
              'lessonPlan.createForm.generalInformation.fields.learningResource.label',
            )}
          </Typography>
        </Grid>
        <Grid item flexGrow={1}>
          <Text>{lessonPlan.selfLearningResource}</Text>
        </Grid>
      </Grid>
    </Box>
  )
})
