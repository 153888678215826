import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  RoleResponsePayload,
  RolesQueryParameters,
  RolesResponsePayload,
} from './api-roles.types'

const PATH = '/roles'

export function getRoles(param?: RolesQueryParameters) {
  return ApiCore.primary.get<RolesResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}

export function getRoleById(id: string) {
  return ApiCore.primary.get<RoleResponsePayload>(`${PATH}/${id}`)
}
