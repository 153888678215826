import { Button, ListItem, Typography } from '@mui/material'
import { File } from '@polyu-dip/models'
import { memo } from 'react'
import { Svg } from '../../assets'

type Props = {
  label: string
  file?: File
  handleOnClick: (file?: File) => void
  color?: string
}

export const ListItemLink = memo<Props>(
  ({ label, file, handleOnClick, color }) => {
    return (
      <ListItem>
        <Button
          variant="text"
          onClick={() => handleOnClick(file)}
          startIcon={<Svg.Link fill={color} />}
        >
          <Typography color={color}>{label}</Typography>
        </Button>
      </ListItem>
    )
  },
)
