import { Box, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { IndexedListItem, SectionTitle } from '../../../../../components'
import { sortByOrder } from '../../../../../utilities'
import { useLessonPlanDetail } from '../../../use-lesson-plan-detail-context'
import { GroupHeader } from './group-header'

export const LearningTarget = observer(() => {
  const { t } = useTranslation()

  const { lessonPlan } = useLessonPlanDetail()

  return (
    <Box>
      <SectionTitle
        mb={0}
        color={lessonPlan.isMultiGroup ? 'lightBlue' : 'orange'}
        title={
          lessonPlan.isMultiGroup
            ? t(
                'lessonPlan.createForm.generalInformation.fields.teachingType.options.basedOnStudentTraitGroups',
              )
            : t(
                'lessonPlan.createForm.generalInformation.fields.teachingType.options.wholeClass',
              )
        }
      />
      <Box mt={5}>
        {lessonPlan.sortedLessonPlanStudentGroups.map((group) => (
          <Box key={group.id}>
            <Box my={5}>
              {lessonPlan.isMultiGroup && (
                <GroupHeader groupCharacteristic={group.groupCharacteristic} />
              )}
              <Grid container mb={3}>
                <Grid item flexShrink={0} fontWeight={600} sm={2}>
                  {lessonPlan.isMultiGroup
                    ? t(
                        'lessonPlan.createForm.generalInformation.fields.basedOnStudentTraitGroupsTeachingType.teachingContents.label',
                      )
                    : t(
                        'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingType.fields.teachingContents.label',
                      )}
                </Grid>
                <Grid item flexGrow={1} pl={5} sm={10}>
                  {group.lessonPlanTeachingGoals
                    .slice()
                    .sort(sortByOrder)
                    .map((it, i) => (
                      <IndexedListItem
                        key={i}
                        index={i}
                        description={it.description}
                      />
                    ))}
                </Grid>
              </Grid>
              <Grid container mb={3}>
                <Grid item flexShrink={0} fontWeight={600} sm={2}>
                  {t(
                    'lessonPlan.createForm.generalInformation.fields.basedOnStudentTraitGroupsTeachingType.expectedResultLessons.label',
                  )}
                </Grid>
                <Grid item flexGrow={1} pl={5} sm={10}>
                  {group.lessonPlanExpectedOutcomes
                    .slice()
                    .sort(sortByOrder)
                    .map((it, i) => (
                      <IndexedListItem
                        key={i}
                        index={i}
                        description={it.description}
                      />
                    ))}
                </Grid>
              </Grid>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
})
