import { withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { NameModel } from './languages-json'

export const LearningUnitModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    isDisabled: types.boolean,
  })
  .extend(withUpdate)
  .named('LearningUnitModel')

export type LearningUnit = Instance<typeof LearningUnitModel>
