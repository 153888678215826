import { useGetLessonPlanAttachmentsDownloadUrl } from '@polyu-dip/queries'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useOverlay } from '../components'
import { env } from '../utilities'
import { useApiErrorHandle } from './use-api-error-handle'

export function useLessonPlanAttachmentsDownload(lessonPlanId?: string) {
  const { t } = useTranslation()
  const { showDialog } = useOverlay()
  const { standardErrorHandler } = useApiErrorHandle()

  const { mutateAsync: getLessonPlanAttachmentsDownloadUrl } =
    useGetLessonPlanAttachmentsDownloadUrl(env.bulkDownloadApiTimeout, {
      onError(error) {
        standardErrorHandler(error, {
          defaultTitle: t('attachment.errorMessage.downloadAttachment.title'),
        })
      },
    })

  const handleDownloadAttachments = useCallback(async () => {
    if (lessonPlanId == null) throw new Error('Missing lesson plan id')
    const res = await getLessonPlanAttachmentsDownloadUrl(lessonPlanId)
    if (res == null) {
      showDialog({
        title: t('attachment.errorMessage.downloadAttachment.title'),
        content: t('error.default.content'),
        actions: [{ text: t('common.ok'), value: null }],
      })
      throw new Error('Failed to upload attachment')
    } else if (res.url == null) {
      showDialog({
        title: t('attachment.bulkDownload.title'),
        content: t('attachment.bulkDownload.noAttachmentDownloaded'),
        actions: [{ text: t('common.ok'), value: null }],
      })
    } else {
      const newWindow = window.open(res.url, '_blank')
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed == 'undefined'
      ) {
        showDialog({
          title: t('attachment.errorMessage.popupReminder.title'),
          content: t('attachment.errorMessage.popupReminder.content'),
          actions: [{ text: t('common.ok'), value: null }],
        })
      }
    }
    // TODO handle success attachmentList
  }, [getLessonPlanAttachmentsDownloadUrl, lessonPlanId, showDialog, t])

  return handleDownloadAttachments
}
