import {
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material'
import { memo, ReactNode } from 'react'
import { Svg } from '../../assets'

export type NestedItem =
  | {
      isButton: true
      id: string
      label: string
      isSelected?: boolean
      item?: never
    }
  | {
      isButton?: false
      id: string
      label?: never
      isSelected?: never
      item: ReactNode
    }

type Props = {
  open?: boolean
  nestedItems?: NestedItem[]
  handleItemOnClick?: (id: string) => void
  dense?: boolean
}

export const NestedList = memo<Props>(
  ({ open, nestedItems, handleItemOnClick, dense }) => {
    return (
      <Collapse in={open}>
        <List dense={dense}>
          {nestedItems?.map((nestedItem) => {
            if (nestedItem.isButton) {
              return (
                <ListItemButton
                  key={nestedItem.id}
                  onClick={() => handleItemOnClick?.(nestedItem.id)}
                >
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography
                        fontWeight={nestedItem.isSelected ? 600 : 400}
                      >
                        {nestedItem.label}
                      </Typography>
                    </Grid>
                    {nestedItem.isSelected ? (
                      <Grid item>
                        <Svg.Tick />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </ListItemButton>
              )
            }
            return <ListItem key={nestedItem.id}>{nestedItem.item}</ListItem>
          })}
        </List>
      </Collapse>
    )
  },
)
