import { List } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import styled from 'styled-components'

type ScrollableListProps = {
  $maxHeight: number
}

type Props = {
  children?: ReactNode
} & ScrollableListProps

const StyleList = styled(List)<ScrollableListProps>`
  max-height: ${({ $maxHeight }) => $maxHeight}px;
  overflow: auto;
`

export const ScrollableList = observer<Props>(({ children, $maxHeight }) => {
  return <StyleList $maxHeight={$maxHeight}>{children}</StyleList>
})
