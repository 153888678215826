import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { File } from './file.model'
import { ResourceType } from './resource-type.model'

export const LessonPlanCommentAttachmentModel = BaseEntityModel.named(
  'LessonPlanCommentAttachmentModel',
)
  .props({
    id: types.string,
    attachmentName: types.string,
    fileId: types.string,
    resourceTypeId: types.maybe(types.string),
    order: types.number,
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .views((self) => ({
    get resourceType() {
      if (self.resourceTypeId == null) return undefined
      return (
        self.rootStore.masterDataStore as {
          resourceTypes: ResourceType[]
        }
      ).resourceTypes.find(
        (resourceType) => resourceType.id === self.resourceTypeId,
      )
    },
    get file() {
      if (self.fileId == null) return undefined
      return (
        self.rootStore.filesStore as {
          files: File[]
        }
      ).files.find((file) => file.id === self.fileId)
    },
  }))

export type LessonPlanCommentAttachment = Instance<
  typeof LessonPlanCommentAttachmentModel
>
