import {
  PaginatedPayload,
  PostTagGroupPayload,
  PutTagGroupPayload,
  TagGroupsQueryParameters,
} from '@polyu-dip/apis'
import { TagGroup } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'tag-groups'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useTagGroups(
  useStores: UseStoresType,
  param?: TagGroupsQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<TagGroup>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getTagGroups(param),
    opts,
  )
}

export function useCreateTagGroups(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostTagGroupPayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payloads) => masterDataStore.createTagGroups(payloads), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
      opts?.onSuccess?.(...params)
    },
  })
}

export function useUpdateTagGroup(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<TagGroup, unknown, PutTagGroupPayload>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation((payload) => masterDataStore.updateTagGroup(id, payload), {
    ...opts,
    onSuccess(...params) {
      queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
      opts?.onSuccess?.(...params)
    },
  })
}

export async function clearTagTagGroupQueryCaches(queryClient: QueryClient) {
  await queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
