import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { File } from './file.model'
import { NameModel } from './languages-json'

export enum LessonPlanDiStrategyResourceType {
  displayVideo = 'Display Video',
  video = 'Video',
  furtherReadingAndExamples = 'Further Reading and Examples',
}

export const DiStrategyResourceModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    diStrategyId: types.string,
    order: types.number,
    resourceType: types.union(
      types.literal(LessonPlanDiStrategyResourceType.displayVideo),
      types.literal(LessonPlanDiStrategyResourceType.video),
      types.literal(LessonPlanDiStrategyResourceType.furtherReadingAndExamples),
    ),
    fileId: types.string,
    isDisabled: types.boolean,
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .named('DiStrategyResourceModel')
  .views((self) => ({
    get file() {
      if (self.fileId == null) return undefined
      return (
        self.rootStore.filesStore as {
          files: File[]
        }
      ).files.find((file) => file.id === self.fileId)
    },
  }))

export type DiStrategyResource = Instance<typeof DiStrategyResourceModel>
