export const submissionRecords = {
  title: '提交紀錄',

  tabs: {
    primarySchool: '小學',
    secondarySchool: '中學',
  },

  tableFields: {
    schoolName: '學校名稱',
    createdDateTime: '參加日期',
    totalMyLessonPlan: '我的教案 總數',
    totalUser: '用戶總數',
    totalLessonPlanPerUser: '教案總數 /每位',
    consultationRate: '諮詢率',
    totalLessonPlanExample: '教學示例 總數',
    latestLessonPlanSubmissionDateTime: '最後提交日期',
  },

  pagination: {
    more: '更多',
  },
}
