import { FormControl } from '@mui/material'
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TextField } from '../text-field'

const StyledFormControl = styled(FormControl)<{ fullWidth?: boolean }>`
  position: relative;
  margin-bottom: 16px;
`

const StyledDatetimePicker = styled(MuiDateTimePicker)<{
  $isHaveValue?: boolean
}>`
  .MuiInputAdornment-root {
    background-color: ${({ theme, $isHaveValue }) =>
      theme.palettes.general[$isHaveValue ? 'white' : 'highlighted']};
    height: 100%;
    margin-left: 0px;
    width: 40px;
    flex-shrink: 0;
  }
`

type DateTimePickerProps = Omit<
  MuiDateTimePickerProps<any, any>,
  'renderInput' | 'value'
> & {
  id?: string
  required?: boolean
  error?: string
  fullWidth?: boolean
  value?: string
}

export const DateTimePicker = ({
  label,
  id,
  required,
  error,
  fullWidth = true,
  value,
  ...props
}: DateTimePickerProps) => {
  const [baseValue, setBaseValue] = useState<Dayjs | null>(
    value ? dayjs(value) : null,
  )
  const { t } = useTranslation()

  const handleChange = useCallback(
    (newValue: any) => {
      if (newValue?.isValid() || newValue === null) {
        setBaseValue(newValue)
        props.onChange?.({
          target: {
            value: newValue?.toISOString(),
          },
        })
      }
    },
    [props],
  )

  return (
    <StyledFormControl fullWidth={fullWidth}>
      <StyledDatetimePicker
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        value={baseValue}
        label={undefined}
        inputFormat={t('common.format.dateTimePicker')}
        onChange={handleChange}
        $isHaveValue={baseValue != null}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            value={baseValue?.format(t('common.format.dateTimePicker'))}
            inputProps={{
              ...params.inputProps,
              placeholder: t('common.format.dateTimePicker'),
            }}
            id={id}
            label={label}
            error={params?.error ? '' : error}
          />
        )}
      />
    </StyledFormControl>
  )
}
