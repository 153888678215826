import { Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogAction } from '../dialog'
import { AutoComplete } from '../form'

type Props = {
  open: boolean
  setOpen: (value: boolean) => void
  title: string
  content: string
  dropdownLabel: string
  dropdownPlaceholder: string
  remarks?: string
  isDisableUser?: boolean
  isDisabled?: boolean
  onSubmit: (
    value: string,
    selectedUser?: { label: string; value: string },
  ) => void
  userOptions: { label: string; value: string }[]
}

export const TransferOwnershipConfirmDialog = observer<Props>(
  ({
    open,
    setOpen,
    onSubmit,
    title,
    content,
    dropdownLabel,
    dropdownPlaceholder,
    remarks,
    isDisableUser,
    isDisabled,
    userOptions,
  }) => {
    const { t } = useTranslation()

    const [selectedUser, setSelectedUser] = useState<{
      label: string
      value: string
    }>()

    const actions = useMemo((): DialogAction<string>[] => {
      if (isDisableUser) {
        return [
          {
            text: t('common.close'),
            type: 'outlined',
            value: 'dismiss',
          },
          {
            text: t(
              'users.actions.disableAccount.confirmationDialog.disableAction',
            ),
            type: 'contained',
            color: 'sharpRed',
            value: 'disable',
            disabled: isDisabled,
          },
          {
            text: t(
              'users.actions.disableAccount.confirmationDialog.disableAndTransferAction',
            ),
            type: 'contained',
            disabled: selectedUser == null,
            value: 'transfer',
          },
        ]
      }
      return [
        {
          text: t('common.close'),
          type: 'outlined',
          value: 'dismiss',
        },
        {
          text: t('common.confirm'),
          type: 'contained',
          disabled: selectedUser == null,
          value: 'transfer',
        },
      ]
    }, [isDisableUser, t, selectedUser, isDisabled])

    const handleOnSelected = useCallback(
      async (value: string) => {
        onSubmit(value, selectedUser)
        setOpen(false)
        setSelectedUser(undefined)
      },
      [onSubmit, selectedUser, setOpen],
    )

    return (
      <Dialog
        open={open}
        title={title}
        actions={actions}
        onSelected={handleOnSelected}
        maxWidth="md"
        fullWidth
      >
        <Grid container direction="column" spacing={5}>
          <Grid item>
            <Typography>{content}</Typography>
          </Grid>
          <Grid item>
            <Typography>{dropdownLabel}</Typography>
          </Grid>
          <Grid item>
            <AutoComplete
              value={selectedUser}
              options={userOptions}
              placeholder={dropdownPlaceholder}
              onChange={(e, value) => setSelectedUser(value)}
            />
          </Grid>
          <Grid item>
            <Typography color="red">{remarks}</Typography>
          </Grid>
        </Grid>
      </Dialog>
    )
  },
)
