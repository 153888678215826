import { FilterOptionsState, Grid } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { useUsers } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrorsImpl,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Svg } from '../../../../assets'
import { AutoComplete } from '../../../../components'
import { useDisplayName } from '../../../../services'
import { useStores } from '../../../../stores'

export type SharedToUsersFormData = {
  users: { value: string; label: string }[],
  textusers:{ value: string; }[],
}

type UserSelectionDropdownProps = {
  item: FieldArrayWithId<SharedToUsersFormData, 'users', 'id'>
  index: number
  control: Control<SharedToUsersFormData, any>
  errors: Partial<
    FieldErrorsImpl<{
      users: {
        value: string
        label: string
      }[]
    }>
  >
  remove: (index?: number | number[] | undefined) => void
  isLoading: boolean
  sharedToUserOptions: { value: string; label: string }[]
  filteredIds: string[]
  filterOptions:
    | ((options: any[], state: FilterOptionsState<any>) => any[])
    | undefined
}

export const UserSelectionDropdown = observer<UserSelectionDropdownProps>(
  ({
    item,
    index,
    control,
    remove,
    isLoading,
    /*errors,*/
    filteredIds,
    sharedToUserOptions,
    filterOptions,
  }) => {
    const { t } = useTranslation()
    const { getDisplayName } = useDisplayName()
    const [filter, setFilter] = useState<string>()
    const [appliedFilter, setAppliedFilter] = useState<string>()

    const { data, isFetching: isUserFetching } = useUsers(
      useStores,
      { emailOrName: appliedFilter, expand: ['School'] },
      {
        enabled: appliedFilter != null,
      },
    )

    const userOptions = useComputed(
      () =>
        appliedFilter == null || appliedFilter == ''
          ? [...sharedToUserOptions]
          : data?.data
              ?.filter(
                (user: any) =>
                  !filteredIds.includes(user.id) &&
                  !user.isDisabled &&
                  !user.school?.isDisabled,
              )
              .map((user: any) => ({
                label: t('users.userDropdownLabel', {
                  name: user?.name,
                  school: getDisplayName(user?.school?.name) ?? '---',
                  email: user?.email,
                }),
                value: user.id,
              })) ?? [],
      [
        appliedFilter,
        data?.data,
        filteredIds,
        getDisplayName,
        sharedToUserOptions,
        t,
      ],
    )

    const handleInputOnChange = useCallback(
      (
        action?: Dispatch<SetStateAction<string | undefined>>,
        value?: string,
      ) => {
        if (action == null) return
        action(value)
      },
      [],
    )

    return (
      <Grid container key={item.id} columnSpacing={3}>
        <Grid item flexGrow={1}>
          <Controller
            control={control}
            name={`users[${index}]` as any}
            render={({ field }) => (
              <AutoComplete
                loading={isLoading || isUserFetching}
                placeholder={t(
                  'lessonPlan.createForm.shareAndRequestPublish.shareInputPlaceholder',
                )}
                getOptionLabel={(option) => option.label ?? ''}
                options={userOptions}
                filterOptions={filterOptions}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                onChange={(e, value) => field.onChange({ target: { value } })}
                /*error={errors?.users?.[index]?.value?.message}*/
                disableClearable
                isReserverForHelperText
                filterSelectedOptions
                required={false}
                onTextFieldValueChanged={(e) => {
                  handleInputOnChange(setFilter, e.target.value)
                }}
                onStopInput={() => setAppliedFilter(filter)}
              />
            )}
          />
        </Grid>
        <Grid item flexShrink={0} mt={2}>
          <Svg.Close
            cursor="pointer"
            width={20}
            height={20}
            onClick={() => remove(index)}
          />
        </Grid>
      </Grid>
    )
  },
)
