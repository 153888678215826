import { ButtonBase, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { MouseEventHandler } from 'react'
import { Svg } from '../../assets'

type Props = {
  index: number
  totalRows: number
  onClickedSwitchUp: MouseEventHandler<HTMLButtonElement> | undefined
  onClickedSwitchDown: MouseEventHandler<HTMLButtonElement> | undefined
}

export const SwitchGroup = observer<Props>(
  ({ index, totalRows, onClickedSwitchUp, onClickedSwitchDown }) => {
    return (
      <Grid container flexDirection="column" flexWrap="nowrap">
        {index != 0 && (
          <ButtonBase onClick={onClickedSwitchUp}>
            <Svg.ChevronUp />
          </ButtonBase>
        )}
        {index < totalRows - 1 && (
          <ButtonBase onClick={onClickedSwitchDown}>
            <Svg.ChevronDown />
          </ButtonBase>
        )}
      </Grid>
    )
  },
)
