import { useComputed } from '@polyu-dip/helpers'
import {
  FullscreenLoading,
  ListPage,
  useOverlay,
  UserProfile,
  UserProfileActionButtons,
} from '../../components'
import { useStores } from '../../stores'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { RoleType, User } from '@polyu-dip/models'
import {
  useApiErrorHandle,
  useUserManagementActions,
  useUserManagementPermission,
} from '../../hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { contentPaths } from '../../content-paths'
import { useCallback, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import {
  useEducationLevels,
  useRoles,
  useUpdateUser,
  useUser,
} from '@polyu-dip/queries'
import { PutUserPayload } from '@polyu-dip/apis'

export type UserProfilePageProps = {
  isMyProfile?: boolean
}

export const UserProfilePage = observer<UserProfilePageProps>(
  ({ isMyProfile }) => {
    const { t } = useTranslation()
    const { usersStore, userProfileStore } = useStores()
    const { hideSpinner, showSpinner, showSnackbar } = useOverlay()
    const { standardErrorHandler } = useApiErrorHandle()
    const navigate = useNavigate()
    const [isEditView, setIsEditView] = useState(false)
    const { userManagementPermissionHandler } = useUserManagementPermission()

    const { userId } = useParams<{
      userId?: string
    }>()

    const { isFetching: isEducationLevelFetching } = useEducationLevels(
      useStores,
      {},
      {},
    )

    const {
      data: roles,
      isFetching: isRoleFetching,
      refetch: refetchRole,
    } = useRoles(
      useStores,
      {},
      {
        onError: (error) => {
          standardErrorHandler(error, {
            onExtraActions: () => {
              navigate(contentPaths.home())
            },
            onRetry: refetchRole,
          })
        },
      },
    )

    const currentUserProfileId = useComputed(
      () => userProfileStore.userProfile?.id,
      [userProfileStore.userProfile?.id],
    )
    const currentUserId = useComputed(() => {
      if (isMyProfile) {
        return currentUserProfileId
      } else {
        return userId
      }
    }, [isMyProfile, userId, currentUserProfileId])

    const { data, isLoading, refetch } = useUser(
      useStores,
      currentUserId ?? '',
      {
        expand: ['Role', 'School'],
      },
      {
        onError: (error) => {
          standardErrorHandler(error, {
            onRetry: refetch,
            errorFormatter: {
              formatted: (apiError) => {
                return {
                  title: t('error.fetchEntityFailure', {
                    entityName: t('users.entityName'),
                  }),
                  content: t('error.default.content', {
                    error: apiError.kind,
                  }),
                }
              },
            },
          })
        },
        enabled: currentUserId != null,
      },
    )

    const {
      handleOpenTransferConfirmationDialog,
      handleOpenDisableConfirmationDialog,
      renderTransferConfirmationDialog,
      renderDisableAndTransferConfirmationDialog,
      renderDisableConfirmationDialog,
      handleEnableUser,
    } = useUserManagementActions(!isMyProfile, currentUserId)

    const currentUserProfile = useComputed(() => data as User, [data])

    const rolesOptions = useComputed(
      () =>
        roles?.data
          .slice()
          .filter(
            (it) =>
              it.label == RoleType.schoolAdmin || it.label == RoleType.teacher,
          )
          .map((role) => ({
            label: role.displayName,
            value: role.id,
          })) ?? [],
      [roles],
    )

    const { canTransferOwnership, canDisableUser } =
      userManagementPermissionHandler(currentUserProfile)

    const currentSchool = useComputed(
      () =>
        usersStore.schools.find((it) => it.id == currentUserProfile?.schoolId),
      [currentUserProfile?.schoolId, usersStore.schools],
    )

    const currentRole = useComputed(
      () => usersStore.roles.find((it) => it.id == currentUserProfile?.roleId),
      [currentUserProfile?.roleId, usersStore.roles],
    )

    const { mutateAsync: updateUser } = useUpdateUser(
      useStores,
      currentUserProfile?.id ?? '',
      {
        onSuccess: () => {
          setIsEditView(false)
          showSnackbar({
            message: t('users.actions.editUser.successMessage'),
          })
        },
        onError: (error) => {
          standardErrorHandler(error, {
            defaultTitle: t('users.actions.errorMessage.save.title'),
          })
        },
      },
    )

    const [newRoleId, setNewRoleId] = useState(currentUserProfile?.roleId)
    const [newName, setNewName] = useState(currentUserProfile?.name)

    const handleRoleOnChange = useCallback((roleId: string) => {
      setNewRoleId(roleId)
    }, [])
    const handleNameOnChange = useCallback((name: string) => {
      setNewName(name)
    }, [])

    const handleSaveOnClick = useCallback(async () => {
      try {
        showSpinner()
        if (currentUserProfile == null || newName?.length === 0) return
        const payload: PutUserPayload = {
          email: currentUserProfile?.email,
          roleId: newRoleId ?? currentUserProfile.roleId,
          schoolId: currentUserProfile.schoolId,
          name: newName ?? currentUserProfile.name,
          isDisabled: currentUserProfile.isDisabled,
          rowVersion: currentUserProfile.rowVersion,
        }
        await updateUser(payload)
      } finally {
        hideSpinner()
      }
    }, [
      currentUserProfile,
      hideSpinner,
      newName,
      newRoleId,
      showSpinner,
      updateUser,
    ])

    const isMainSchoolAdmin = useMemo(() => {
      return currentSchool?.mainSchoolAdminUserId === currentUserId
    }, [currentSchool?.mainSchoolAdminUserId, currentUserId])

    const isCurrentUser = useMemo(() => {
      return currentUserProfileId === userId || isMyProfile === true
    }, [currentUserProfileId, isMyProfile, userId])

    const isExpert = useMemo(() => {
      return currentUserProfile?.role?.label === RoleType.expert
    }, [currentUserProfile?.role?.label])

    const isDiTeam = useMemo(() => {
      return currentUserProfile?.role?.label === RoleType.diTeam
    }, [currentUserProfile?.role?.label])

    if (isLoading || isRoleFetching || isEducationLevelFetching) {
      return <FullscreenLoading />
    }

    return (
      <ListPage
        sectionTitleProps={{
          title: isMyProfile
            ? t('users.title.myProfile')
            : t('users.title.userProfile'),
          color: 'astronautBlue',
        }}
      >
        <UserProfile
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...currentUserProfile}
          school={currentSchool}
          role={currentRole}
          rolesOptions={
            isMainSchoolAdmin || isCurrentUser || isExpert || isDiTeam
              ? undefined
              : rolesOptions
          }
          isEditView={isEditView}
          handleRoleOnChange={handleRoleOnChange}
          handleNameOnChange={handleNameOnChange}
        />
        <Grid container mt={4}>
          <UserProfileActionButtons
            isEditView={isEditView}
            handleTransferLessonPlan={
              !canTransferOwnership || isMyProfile
                ? undefined
                : () => {
                    handleOpenTransferConfirmationDialog(currentUserId)
                  }
            }
            handleDisableUser={
              !canDisableUser ||
              isMainSchoolAdmin ||
              isCurrentUser ||
              currentUserProfile?.isDisabled
                ? undefined
                : () => {
                    handleOpenDisableConfirmationDialog(
                      currentUserId,
                      undefined,
                      canTransferOwnership,
                    )
                  }
            }
            handleEnableUser={
              !canDisableUser || isMyProfile || !currentUserProfile?.isDisabled
                ? undefined
                : () => {
                    handleEnableUser(currentUserId)
                  }
            }
            handleSave={handleSaveOnClick}
            handleEdit={() => setIsEditView(true)}
          />
        </Grid>
        {renderTransferConfirmationDialog}
        {renderDisableAndTransferConfirmationDialog}
        {renderDisableConfirmationDialog}
      </ListPage>
    )
  },
)
