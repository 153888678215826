import { Chip, Grid, Typography } from '@mui/material'
import { useComputed } from '@polyu-dip/helpers'
import { DiStrategy, DiStrategyClass } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../assets'
import { Tag } from '../tag'

const Text = styled(Typography)`
  overflow-wrap: break-word;
  font-size: 14px;
  color: ${({ theme }) => theme.palettes.semantic.astronautBlue};
  white-space: pre-wrap;
`

const StyledChip = styled(Chip)`
  background-color: ${({ theme }) => theme.palettes.semantic.astronautBlue};
  border-radius: 8px;
  color: ${({ theme }) => theme.palettes.general.white};
  font-weight: 600;
`

const TickIconWrapper = styled.div<{ printable?: boolean }>`
  width: ${({ printable }) => (printable ? 21 : 32)}px;
  height: ${({ printable }) => (printable ? 21 : 32)}px;
  border: 1px solid ${({ theme }) => theme.palettes.general.greys[1]};
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export type SelectedDiStrategiesProps = {
  diStrategies: DiStrategy[]
  diStrategyClasses: DiStrategyClass[]
  selectedDiStrategies: {
    diStrategyId: string
    reason?: string
  }[]
  isEditMode?: boolean
  printable?: boolean
}

export const SelectedDiStrategies = observer<SelectedDiStrategiesProps>(
  ({
    diStrategies,
    diStrategyClasses,
    selectedDiStrategies,
    isEditMode,
    printable,
  }) => {
    const { t } = useTranslation()

    const displaySelectedDiStrategies = useComputed(
      () =>
        selectedDiStrategies
          .map((selectedDiStrategy) => {
            const diStrategy = diStrategies.find(
              (it) => it.id == selectedDiStrategy.diStrategyId,
            )
            const diStrategyClass = diStrategyClasses.find(
              (it) => it.id == diStrategy?.diStrategyClassId,
            )

            return {
              ...selectedDiStrategy,
              order: diStrategy?.order,
              colorCode: diStrategy?.colorCode,
              strategyClassLabel: diStrategyClass?.label,
              displayName: diStrategy?.displayName,
              displayDetail: diStrategy?.displayDetail,
            }
          })
          .sort((a, b) => {
            if (
              a.strategyClassLabel == null ||
              b.strategyClassLabel == null ||
              a.strategyClassLabel < b.strategyClassLabel
            ) {
              return -1
            }
            if (
              a.strategyClassLabel > b.strategyClassLabel ||
              (a.order ?? 0) > (b.order ?? 0)
            ) {
              return 1
            }
            return -1
          }),
      [diStrategies, diStrategyClasses, selectedDiStrategies],
    )

    if (isEditMode) {
      return (
        <Grid container mt={5} spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight="600">
              {t('lessonPlan.createForm.diStrategies.selectedStrategies')}
            </Typography>
          </Grid>
          <Grid item flexShrink={0}>
            <TickIconWrapper>
              <Svg.Tick />
            </TickIconWrapper>
          </Grid>
          {displaySelectedDiStrategies?.map((it) => (
            <Grid key={it.diStrategyId} item flexShrink={0}>
              <StyledChip
                label={t('lessonPlan.createForm.diStrategies.label', {
                  diStrategyClassLabel: it.strategyClassLabel,
                  diStrategyLabelNumber: it.order,
                  diStrategyName: it.displayName,
                })}
                variant="filled"
              />
            </Grid>
          ))}
        </Grid>
      )
    }

    return (
      <>
        {displaySelectedDiStrategies.map((it) => (
          <Grid
            container
            key={it.diStrategyId}
            columnSpacing={5}
            mb={2}
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Grid container flexWrap="nowrap">
                <Grid item mr={2}>
                  <TickIconWrapper printable={printable}>
                    <Svg.Tick />
                  </TickIconWrapper>
                </Grid>
                <Grid item>
                  {printable ? (
                    <Text>
                      {t('lessonPlan.createForm.diStrategies.label', {
                        diStrategyClassLabel: it.strategyClassLabel,
                        diStrategyLabelNumber: it.order,
                        diStrategyName: it.displayName,
                      })}
                    </Text>
                  ) : (
                    <Tag
                      label={t('lessonPlan.createForm.diStrategies.label', {
                        diStrategyClassLabel: it.strategyClassLabel,
                        diStrategyLabelNumber: it.order,
                        diStrategyName: it.displayName,
                      })}
                      colorCustomize={it?.colorCode}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} mb={5}>
              <Text>{it.reason ?? it.displayDetail}</Text>
            </Grid>
          </Grid>
        ))}
      </>
    )
  },
)
