export const signedInteger = Math.pow(2, 31) - 1

export function getInputNumberValue(
  inputNumber?: string,
  isInteger?: boolean,
  maxNumber?: number,
) {
  if (inputNumber == null || inputNumber.length === 0) return undefined
  const value = isInteger ? +inputNumber.replace(/[^0-9]/g, '') : +inputNumber
  if (maxNumber != null && value > maxNumber) return maxNumber
  return value
}
