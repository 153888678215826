export const lessonPlanCommunication = {
  title: '教案交流',
  pagination: {
    next: '下頁',
  },
  tableFields: {
    classLevel: '年級',
    subject: '學習領域',
    sharingCreatedDateTime: '分享日期',
    ownedByUserName: '教案作者',
    teachingTopic: '教案名稱',
    latestCommentCreatedBy: '留言用戶',
    latestComment: '最新留言',
  },
}
