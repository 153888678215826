import {
  PaginatedPayload,
  PostResourceTypePayload,
  PutResourceTypePayload,
  ResourceTypesQueryParameters,
} from '@polyu-dip/apis'
import { ResourceType } from '@polyu-dip/models'
import { MasterDataStore } from '@polyu-dip/stores'
import {
  QueryClient,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'resource-types'

type UseStoresType = () => { masterDataStore: MasterDataStore }

export function useResourceTypes(
  useStores: UseStoresType,
  param?: ResourceTypesQueryParameters,
  opts?: UseQueryOptions<PaginatedPayload<ResourceType>>,
) {
  const { masterDataStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, param] as QueryKey,
    () => masterDataStore.getResourceTypes(param),
    opts,
  )
}

export function useCreateResourceTypes(
  useStores: UseStoresType,
  opts?: UseMutationOptions<any, unknown, PostResourceTypePayload[]>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payloads) => masterDataStore.createResourceTypes(payloads),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useUpdateResourceType(
  useStores: UseStoresType,
  id: string,
  opts?: UseMutationOptions<ResourceType, unknown, PutResourceTypePayload>,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => masterDataStore.updateResourceType(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT, id])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export function useSwitchResourceType(
  useStores: UseStoresType,
  opts?: UseMutationOptions<
    ResourceType,
    unknown,
    PutResourceTypePayload & { id: string }
  >,
) {
  const { masterDataStore } = useStores()
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, ...payload }) => masterDataStore.updateResourceType(id, payload),
    {
      ...opts,
      onSuccess(...params) {
        queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
        opts?.onSuccess?.(...params)
      },
    },
  )
}

export async function clearResourceTypeQueryCaches(queryClient: QueryClient) {
  await queryClient.invalidateQueries([QUERY_CACHE_KEY_ROOT])
}
