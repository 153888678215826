import { ApiCore } from '@polyu-dip/api-core'
import {
  PostCreateBlobStorageContainerResponsePayload,
  PostGetBlobDownloadUrlResponsePayload,
} from './files-api.types'

const PATH = '/files'

export function postCreateBlobStorageContainer() {
  return ApiCore.primary.post<PostCreateBlobStorageContainerResponsePayload>(
    PATH + '/create-container',
  )
}

export function getBlobDownloadUrl(id: string) {
  return ApiCore.primary.get<PostGetBlobDownloadUrlResponsePayload>(
    `${PATH}/${id}/url`,
  )
}
