import { observer } from 'mobx-react-lite'
import { FunctionComponent, useMemo } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import styled from 'styled-components'
import { useRoute } from './routers'
import { env } from './utilities'

type Props = {}

const Container = styled.div`
  overscroll-behavior: none;
`

export const Content: FunctionComponent<Props> = observer(() => {
  const routeItems = useRoute()

  const router = useMemo(
    () => createBrowserRouter(routeItems, { basename: env.basePath }),
    [routeItems],
  )
  return (
    <Container>
      <RouterProvider router={router} />
    </Container>
  )
})
