export const notifications = {
  noRowsOverlayTitle: '目前尚未有任何通知',
  entityName: '通知',
  title: '所有通知',

  content: {
    lessonPlanTeachingTopicPrefix: '你的教案 ',
    share: '與你分享 教案',
    comment: '回應了教案 ',
    sharecomment: '回應了與你分享的教案',
    repliedComment: '回覆了你的留言',
    plancomment:'回應了你咨詢的教案',
    submittedPublishRequest: '新教案已提交',
    withdrawPublishRequest: '新教案已撤回',
    approvedPublishRequest: '發佈申請已被接納',
    rejectedPublishRequest: '發佈申請已被拒絕',
    published: '已在教學示例發佈',
    unpublished: '已下架',
    consult: '已完成諮詢的教案',
    assigned: '委派新諮詢給你',
    newLessonPlanFromOwner: '轉移了教案的擁有權給你',
    newLessonPlanFromSchoolAdmin: '轉移了部分教案的擁有權給你',
    lessonPlanTransferred: '所有教案的擁有權已被轉移',
    userAccountDisabled: '你的帳戶已被停用',
    userAccountReEnabled: '你的帳戶已重新啟用',
  },
}
