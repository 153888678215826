export const resources = {
  title: '資源庫',

  tags: {
    onlineResources: '網上資源',
    downloadableResource: '下載專區',
  },

  tableFields: {
    title: '標題',
    resourceType: '類型',
    lastModifiedDateTime: '最後更新',

    learningPhase: '學習階段',
    classLevel: '年級',
    subject: '科目',
    download: '下載',
  },

  pagination: {},

  createOnlineResource: {
    title: '新增項目',
    field: {
      title: {
        label: '標題',
        placeholder: '標題',
      },
      url: {
        label: '連結',
        placeholder: 'URL',
      },
      type: {
        label: '資源類別',
        placeholder: '資源類別',
      },
    },
    action: {
      create: '新增項目',
    },
  },
  editOnlineResource: {
    title: '修改項目',
    action: {
      update: '確認修改',
      delete: '刪除項目',
    },
  },

  filter: {
    title: '標題',
    resourceType: '類型',
    learningPhase: '學習階段',
    classLevel: '年級',
    subject: '科目',
  },

  actions: {
    delete: '刪除',
    add: '新增',
    upload: '上載',
    selfLearningModule: '自學資源',
  },

  createDownloadableResource: {
    title: '上載項目',

    field: {
      url: {
        label: '連結',
        placeholder: 'URL',
      },
      title: {
        label: '標題',
        placeholder: '標題',
      },
      classLevel: {
        label: '年級',
        placeholder: '年級',
      },
      subject: {
        label: '科目',
        placeholder: '科目',
      },
      type: {
        label: '資源類別',
        placeholder: '資源類別',
      },
    },

    action: {
      create: '新增項目',
    },
  },

  editDownloadableResource: {
    title: '修改項目',
    action: {
      update: '確認修改',
      delete: '刪除',
    },
  },

  deleteResources: {
    error: '刪除項目失敗',
    success: '成功刪除項目',
  },
  updateResources: {
    error: '修改項目失敗',
    success: '已成功修改項目',
  },
  addResources: {
    error: '新增項目失敗',
    success: '已成功新增項目',
  },
  uploadResources: {
    error: '上載項目失敗',
    success: '已成功上載項目',
  },
}
