import { useEffect } from 'react'
import { FieldErrors, UseFormTrigger } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const useFormErrorTranslationTrigger = (
  errors: FieldErrors<any>,
  trigger?: UseFormTrigger<any>,
) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    if (errors != null && Object.keys(errors).length > 0) trigger?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])
}
