import { ApiCore } from '@polyu-dip/api-core'
import { convertExpandParameters, convertSortParameters } from '../base'
import {
  LessonPlanSharingsQueryParameters,
  LessonPlanSharingsResponsePayload,
} from './lesson-plan-sharings-api.types'

const PATH = 'lesson-plan-sharings'

export function getLessonPlanSharings(
  param?: LessonPlanSharingsQueryParameters,
) {
  return ApiCore.primary.get<LessonPlanSharingsResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
      expand: convertExpandParameters(param),
    },
  })
}

export function deleteLessonPlanSharing(id: string) {
  return ApiCore.primary.delete(`${PATH}/${id}`)
}
