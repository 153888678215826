import { memo } from 'react'
import { Tab as MuiTab, TabProps } from '@mui/material'
import styled from 'styled-components'

const StyledTab = styled(MuiTab)<OwnProps>`
  -webkit-print-color-adjust: exact;
  background-color: ${({ theme, type }) =>
    type === 'light'
      ? theme.palettes.general.white
      : theme.palettes.general.greys[5]};
  border: 1px solid
    ${({ theme, type }) =>
      type === 'light'
        ? theme.palettes.general.white
        : theme.palettes.general.greys[4]};

  font-size: 16px;
  line-height: 19px;
  width: ${({ $width: width }) => width}px;
  min-width: ${(props) => props.$minWidth}px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: auto;
  display: flex;
  justify-content: ${({ $isVertical }) =>
    $isVertical ? 'flex-start' : 'center'};
`

type OwnProps = {
  $width?: number
  $minWidth?: number
  type?: 'grey' | 'light'
  $isVertical?: boolean
}

type Props = TabProps & OwnProps

export const Tab = memo(({ type = 'light', ...props }: Props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledTab type={type} {...props} />
})
