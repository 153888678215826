import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { EducationLevel } from './education-level.model'
import { NameModel } from './languages-json'

export const LearningPhaseModel = types
  .compose(BaseEntityModel, NameModel)
  .props({
    id: types.string,
    educationLevelId: types.string,
    colorCode: types.string,
    order: types.number,
    isDisabled: types.boolean,
  })
  .extend(withUpdate)
  .named('LearningPhaseModel')
  .extend(withRootStore)
  .views((self) => ({
    get educationLevel() {
      if (self.educationLevelId == null) return undefined
      return (
        self.rootStore.masterDataStore as { educationLevels: EducationLevel[] }
      ).educationLevels.find(
        (educationLevel) => educationLevel.id === self.educationLevelId,
      )
    },
  }))

export type LearningPhase = Instance<typeof LearningPhaseModel>
