import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { EducationLevel } from './education-level.model'

export const SubjectEducationLevelModel = BaseEntityModel.named(
  'SubjectEducationLevelModel',
)
  .props({
    subjectId: types.string,
    educationLevelId: types.string,
  })
  .extend(withUpdate)
  .extend(withRootStore)
  .views((self) => ({
    get educationLevel() {
      if (self.educationLevelId == null) return undefined
      return (
        self.rootStore.masterDataStore as { educationLevels: EducationLevel[] }
      ).educationLevels.find(
        (educationLevel) => educationLevel.id === self.educationLevelId,
      )
    },
  }))

export type SubjectEducationLevel = Instance<typeof SubjectEducationLevelModel>
