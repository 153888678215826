import { Chip, Typography } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid'
import {
  OutstandingLessonPlanRequestStatus,
  OutstandingRequestType,
  PaginationParameters,
} from '@polyu-dip/apis'
import { useComputed } from '@polyu-dip/helpers'
import { useOutstandingLessonPlanRequests } from '@polyu-dip/queries'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ListPage, Tab, Table, Tabs, useRowCountState } from '../../components'
import { contentPaths } from '../../content-paths'
import { useOutstandingLessonPlanRequestColumn } from './use-lesson-plan-request-column'

const StyledChip = styled(Chip)`
  font-size: 14px;
  line-height: 120%;
  height: auto;
  margin-left: 12px;

  .MuiChip-label {
    padding: 0px 8px;
  }
`

const FlexRow = styled.div`
  display: flex;
  direction: row;
  white-space: nowrap;
`

const PAGINATION_LIMIT = 15

const DEFAULT_PAGINATION: PaginationParameters = {
  limit: PAGINATION_LIMIT,
  offset: 0,
}

enum TabMenuEnum {
  PENDING = 'pending',
  PUBLISHED = 'published',
  OTHER = 'other',
}

export const LessonPlanPublishRequests = observer(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { renderColumn } = useOutstandingLessonPlanRequestColumn()
  const [activeTab, setActiveTab] = useState<TabMenuEnum>(TabMenuEnum.PENDING)

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION)
  const [pendingRequestSort, setPendingRequestSort] = useState<GridSortModel>()
  const [publishedRequestSort, setPublishedRequestSort] =
    useState<GridSortModel>()
  const [otherRequestSort, setOtherRequestSort] = useState<GridSortModel>()

  const { data: pendingRequests, isLoading: isPendingRequestsLoading } =
    useOutstandingLessonPlanRequests({
      status: [
        OutstandingLessonPlanRequestStatus.pending,
        OutstandingLessonPlanRequestStatus.approved,
      ],
      type: [OutstandingRequestType.publishRequest],
      sort: pendingRequestSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      ...pagination,
    })

  const { data: publishedRequests, isLoading: isPublishedRequestsLoading } =
    useOutstandingLessonPlanRequests({
      status: [OutstandingLessonPlanRequestStatus.completed],
      type: [OutstandingRequestType.publishRequest],
      isPublishing: [true],
      sort: publishedRequestSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'publishedDateTime', direction: 'desc' }],
      ...pagination,
    })

  const { data: otherRequests, isLoading: isOtherRequestsLoading } =
    useOutstandingLessonPlanRequests({
      status: [
        OutstandingLessonPlanRequestStatus.completed,
        OutstandingLessonPlanRequestStatus.rejected,
      ],
      isPublishing: [false],
      type: [OutstandingRequestType.publishRequest],
      sort: otherRequestSort?.map((it) => ({
        direction: it.sort ?? 'desc',
        parameter: it.field,
      })) ?? [{ parameter: 'lastModifiedDateTime', direction: 'desc' }],
      ...pagination,
    })

  const activeData = useComputed(
    () =>
      // eslint-disable-next-line no-nested-ternary
      activeTab === TabMenuEnum.PENDING
        ? pendingRequests
        : activeTab === TabMenuEnum.PUBLISHED
        ? publishedRequests
        : otherRequests,
    [activeTab, pendingRequests, publishedRequests, otherRequests],
  )

  const isLoading = useComputed(
    () =>
      // eslint-disable-next-line no-nested-ternary
      activeTab === TabMenuEnum.PENDING
        ? isPendingRequestsLoading
        : activeTab === TabMenuEnum.PUBLISHED
        ? isPublishedRequestsLoading
        : isOtherRequestsLoading,
    [activeTab, pendingRequests, publishedRequests, otherRequests],
  )

  const computedOutstandingLessonPlanData = useComputed(() => {
    return (
      activeData?.data.map((it) => ({
        ...it,
        id: it.id,
        assignedToUserName: it.assignedToUserName ?? '---',
      })) ?? []
    )
  }, [activeData])

  const totalRecord = useMemo(() => {
    return activeData?.total
  }, [activeData])

  const { rowCountState } = useRowCountState(totalRecord)

  const handleTabChange = useCallback((event: any, value: any) => {
    setActiveTab(value)
    setPagination(DEFAULT_PAGINATION)
  }, [])

  return (
    <ListPage
      sectionTitleProps={{
        title: t('lessonPlanRequest.publishManagement.title', {
          pendingCount: totalRecord ?? 0,
        }),
        color: 'astronautBlue',
      }}
    >
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab
          value={TabMenuEnum.PENDING}
          label={
            <FlexRow>
              <Typography>
                {t('lessonPlanRequest.publishManagement.tabs.pending')}
              </Typography>
              <StyledChip
                label={pendingRequests?.total ?? 0}
                color={
                  activeTab === TabMenuEnum.PENDING ? 'primary' : 'default'
                }
              />
            </FlexRow>
          }
          $width={320}
        />
        <Tab
          value={TabMenuEnum.PUBLISHED}
          label={
            <FlexRow>
              <Typography>
                {t('lessonPlanRequest.publishManagement.tabs.published')}
              </Typography>

              <StyledChip
                label={publishedRequests?.total ?? 0}
                color={
                  activeTab === TabMenuEnum.PUBLISHED ? 'primary' : 'default'
                }
              />
            </FlexRow>
          }
          $width={320}
        />
        <Tab
          value={TabMenuEnum.OTHER}
          label={
            <FlexRow>
              <Typography>
                {t('lessonPlanRequest.publishManagement.tabs.unPublished')}
              </Typography>

              <StyledChip
                label={otherRequests?.total ?? 0}
                color={activeTab === TabMenuEnum.OTHER ? 'primary' : 'default'}
              />
            </FlexRow>
          }
          $width={320}
        />
      </Tabs>

      <Table
        columns={renderColumn('publish', activeTab)}
        rows={computedOutstandingLessonPlanData}
        pageSize={PAGINATION_LIMIT}
        loading={isLoading}
        paginationMode="server"
        page={(pagination.offset ?? 0) / PAGINATION_LIMIT}
        hideFooterPagination={
          rowCountState != null ? rowCountState <= PAGINATION_LIMIT : true
        }
        rowCount={rowCountState}
        sortModel={
          activeTab === TabMenuEnum.PENDING
            ? pendingRequestSort
            : activeTab === TabMenuEnum.PUBLISHED
            ? publishedRequestSort
            : otherRequestSort
        }
        onSortModelChange={
          activeTab === TabMenuEnum.PENDING
            ? setPendingRequestSort
            : activeTab === TabMenuEnum.PUBLISHED
            ? setPublishedRequestSort
            : setOtherRequestSort
        }
        onRowClick={({ row }) =>
          navigate(contentPaths.lessonPlanPublishRequest(row.id, 'view'))
        }
        onPageChange={(page) =>
          setPagination((old) => ({
            ...old,
            offset: page * PAGINATION_LIMIT,
          }))
        }
      />
    </ListPage>
  )
})
