import { Box, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Text } from '../../../../../components'
import { useLessonPlanDetail } from '../../../use-lesson-plan-detail-context'

export const AdvancedKnowledge = observer(() => {
  const { t } = useTranslation()

  const { lessonPlan } = useLessonPlanDetail()

  return (
    <Box>
      <Grid container columnSpacing={5} rowSpacing={4}>
        <Grid item xs={6}>
          <Typography fontWeight={600} mb={2}>
            {t(
              'lessonPlan.createForm.generalInformation.fields.advancedKnowledge.requiredKnowledge.label',
            )}
          </Typography>
          <Text>{lessonPlan.requiredKnowledge}</Text>
        </Grid>
        <Grid item xs={6}>
          <Typography fontWeight={600} mb={2}>
            {t(
              'lessonPlan.createForm.generalInformation.fields.advancedKnowledge.expectedDifficulty.label',
            )}
          </Typography>
          <Text>{lessonPlan.expectedDifficulty}</Text>
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={600} mb={2}>
            {t(
              'lessonPlan.createForm.generalInformation.fields.advancedKnowledge.remark.label',
            )}
          </Typography>
          <Text>{lessonPlan.remark}</Text>
        </Grid>
      </Grid>
    </Box>
  )
})
