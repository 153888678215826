import { ApiCore } from '@polyu-dip/api-core'
import { convertExpandParameters } from '../base'
import {
  LessonPlanPublishRequestExpandableParameter,
  LessonPlanPublishRequestResponsePayload,
} from './lesson-plan-publish-requests-api.types'

const PATH = '/lesson-plan-publish-requests'

export function getLessonPlanPublishRequestById(
  id: string,
  param?: LessonPlanPublishRequestExpandableParameter,
) {
  return ApiCore.primary.get<LessonPlanPublishRequestResponsePayload>(
    `${PATH}/${id}`,
    {
      param: {
        ...param,
        expand: convertExpandParameters(param),
      },
    },
  )
}

export function postWithdrawPublishRequest(id: string, rowVersion: string) {
  return ApiCore.primary.post<LessonPlanPublishRequestResponsePayload>(
    `${PATH}/${id}/withdraw`,
    {
      body: {
        rowVersion: rowVersion,
      },
    },
  )
}

export function approveLessonPlanPublishRequest(
  id: string,
  rowVersion: string,
) {
  return ApiCore.primary.post<LessonPlanPublishRequestResponsePayload>(
    `${PATH}/${id}/approve`,
    {
      body: {
        rowVersion: rowVersion,
      },
    },
  )
}

export function rejectLessonPlanPublishRequest(id: string, rowVersion: string) {
  return ApiCore.primary.post<LessonPlanPublishRequestResponsePayload>(
    `${PATH}/${id}/reject`,
    {
      body: {
        rowVersion: rowVersion,
      },
    },
  )
}

export function completeAndPublishLessonPlanPublishRequest(
  id: string,
  rowVersion: string,
) {
  return ApiCore.primary.post<LessonPlanPublishRequestResponsePayload>(
    `${PATH}/${id}/complete-and-publish`,
    {
      body: {
        rowVersion: rowVersion,
      },
    },
  )
}
