import { withRootStore, withUpdate } from '@polyu-dip/model-core'
import { Instance, types } from 'mobx-state-tree'
import { BaseEntityModel } from './base-entity'
import { ClassLevel } from './class-level.model'
import { File } from './file.model'
import { ResourceType } from './resource-type.model'
import { Subject } from './subject.model'

export const ResourceModel = BaseEntityModel.props({
  id: types.string,
  title: types.string,
  isOnlineResource: types.boolean,
  subjectId: types.maybe(types.string),
  classLevelId: types.maybe(types.string),
  resourceTypeId: types.string,
  fileId: types.string,
  isDisabled: types.boolean,
})
  .extend(withUpdate)
  .extend(withRootStore)
  .views((self) => ({
    get subject() {
      if (self.subjectId == null) return undefined
      return (
        self.rootStore.masterDataStore as { subjects: Subject[] }
      ).subjects.find((subject) => subject.id === self.subjectId)
    },
    get classLevel() {
      if (self.classLevelId == null) return undefined
      return (
        self.rootStore.masterDataStore as { classLevels: ClassLevel[] }
      ).classLevels.find((classLevel) => classLevel.id === self.classLevelId)
    },
    get resourceType() {
      if (self.resourceTypeId == null) return undefined
      return (
        self.rootStore.masterDataStore as { resourceTypes: ResourceType[] }
      ).resourceTypes.find(
        (resourceType) => resourceType.id === self.resourceTypeId,
      )
    },
    get file() {
      if (self.fileId == null) return undefined
      return (self.rootStore.filesStore as { files: File[] }).files.find(
        (file) => file.id === self.fileId,
      )
    },
    get learningPhase() {
      if (self.classLevelId == null) return undefined
      return (
        self.rootStore.masterDataStore as { classLevels: ClassLevel[] }
      ).classLevels.find((classLevel) => classLevel.id === self.classLevelId)
        ?.learningPhase
    },
  }))

export type Resource = Instance<typeof ResourceModel>
