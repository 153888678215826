import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  TeachingClassesQueryParameters,
  TeachingClassesResponsePayload,
} from './teaching-classes-api.type'

const PATH = '/teaching-classes'

export function getTeachingClasses(param?: TeachingClassesQueryParameters) {
  return ApiCore.primary.get<TeachingClassesResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}
