import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { LessonPlanDetailPage } from './lesson-plan-detail-page'

export const LessonPlanSharingDetailPage = observer(() => {
  const { lessonPlanId } = useParams<{
    lessonPlanId: string
  }>()

  return <LessonPlanDetailPage lessonPlanId={lessonPlanId} isSharing />
})
