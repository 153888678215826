import { withRootStore, withUpdate } from '@polyu-dip/model-core'  
import { Instance, types } from 'mobx-state-tree'  
import { BaseEntityModel } from './base-entity'  
import { User } from './user.model'  
  
export const LessonPlanSharingModel = BaseEntityModel.named(  
  'LessonPlanSharingModel',  
)  
  .props({  
    id: types.string,  
    sharedToUserId: types.string,  
    // 添加 roleId 属性  
    roleId: types.maybeNull(types.string),  
  })  
  .extend(withUpdate)  
  .extend(withRootStore)  
  .views((self) => ({  
    get sharedToUser() {  
      if (self.sharedToUserId == null) return undefined  
      return (self.rootStore.usersStore as { users: User[] }).users.find(  
        (user) => user.id === self.sharedToUserId,  
      )  
    },  
  }))  
  
export type LessonPlanSharing = Instance<typeof LessonPlanSharingModel>