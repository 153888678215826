import {
  getLessonPlanById,
  getLessonPlans,
  getLessonPlansOwned,
  getLessonPlansOwnedPublished,
  getLessonPlansUpdatedExamplesAndResources,
  getLessonPlanExamples,
  LessonPlanExpandableParameter,
  LessonPlanResponsePayload,
  LessonPlansOwnedResponsePayload,
  LessonPlansQueryParameters,
  LessonPlansResponsePayload,
  LessonPlansUpdatedExampleAndResourceResponsePayload,
  LessonPlansUpdatedExamplesAndResourcesParameters,
  LessonPlansUpdatedExamplesAndResourcesResponsePayload,
  PaginatedPayload,
  postLessonPlan,
  PostLessonPlanPayload,
  postLessonPlanSharingById,
  PostLessonPlanSharingPayload,
  bulkPostLessonPlanSharingsById,
  putLessonPlan,
  PutLessonPlanPayload,
  getLessonPlanSharings,
  LessonPlanSharingsQueryParameters,
  LessonPlanSharingsResponsePayload,
  deleteLessonPlanSharing,
  LessonPlanSharingResponsePayload,
  LessonPlansSharedQueryParameters,
  LessonPlansSharedResponsePayload,
  getLessonPlansShared,
  PostLessonPlanCommentPayload,
  postLessonPlanComment,
  deleteLessonPlanComment,
  patchLessonPlanCommentById,
  PatchLessonPlanCommentPayload,
  LessonPlanCommentsQueryParameters,
  LessonPlanCommentsResponsePayload,
  getLessonPlanComments,
  LessonPlanCommentResponsePayload,
  postSubmitPublishRequest,
  postWithdrawPublishRequest,
  LessonPlanExamplesQueryParameters,
  LessonPlanPublishRequestResponsePayload,
  approveLessonPlanPublishRequest,
  rejectLessonPlanPublishRequest,
  completeAndPublishLessonPlanPublishRequest,
  postPublishLessonPlanExample,
  postUnPublishLessonPlanExample,
  LessonPlanExamplesResponsePayload,
  postCopyLessonPlanExample,
  getLessonPlanSummary,
  PostLessonPlanConsultPayload,
  postLessonPlanConsultById,
  LessonPlanConsultResponsePayload,
  postLessonPlanConsultRequestAssignExpert,
  PostLessonPlanConsultWithExpertPayload,
  getLessonPlanConsultRequestById,
  LessonPlanConsultRequestExpandableParameter,
  LessonPlanConsultRequestResponsePayload,
  postLessonPlanConsultComplete,
  PostLessonPlanTransferOwnerPayload,
  postLessonPlanTransferOwner,
  getLessonPlanDetailById,
  postLessonPlanViewCount,
  postLessonPlanExportCount,
} from '@polyu-dip/apis'
import { withRootStore } from '@polyu-dip/model-core'
import {
  LessonPlan,
  LessonPlanComment,
  LessonPlanConsult,
  LessonPlanModel,
  LessonPlanPublishRequest,
} from '@polyu-dip/models'
import { convertNullToUndefined } from '@polyu-dip/utilities'
import _ from 'lodash'
import { flow, Instance, types } from 'mobx-state-tree'

const emptyPayloadMessage = 'empty-payload'

export const LessonPlansStoreModel = types
  .model('LessonPlansStore')
  .props({
    lessonPlans: types.array(LessonPlanModel),
  })
  .extend(withRootStore)
  .actions((self) => {
    const actions = {
      upsertLessonPlan(inPayload: LessonPlanResponsePayload) {
        const {
          createdByUser,
          ownedByUser,
          lastModifiedByUser,
          subject,
          classLevel,
          authorUser,
          lessonPlanTags,
          lessonPlanAttachments,
          lessonPlanDiStrategies,
          sectionDuration,
          sectionTotal,
          lessonPlanStudentGroups,
          lessonPlanComments,
          lessonPlanPublishRequests,
          sourcePublishRequests,
          latestLessonPlanComment,
          latestLessonPlanPublishRequest,
          latestSourcePublishRequest,
          lessonPlanConsults,
          latestLessonPlanConsult,
          lessonPlanSharing,
          lessonPlanSharings,
          ...maybeNullPayload
        } = inPayload

        const payload = convertNullToUndefined({
          ...maybeNullPayload,
          lessonPlanAttachments: lessonPlanAttachments,
          lessonPlanTags: lessonPlanTags,
          lessonPlanDiStrategies: lessonPlanDiStrategies,
          sectionDuration: sectionDuration ?? 0,
          sectionTotal: sectionTotal ?? 0,
          lessonPlanStudentGroups: lessonPlanStudentGroups?.map((group) => ({
            ...group,
            lessonPlanTeachingSteps: group?.lessonPlanTeachingSteps?.map(
              (steps) => ({
                ...steps,
                duration: steps?.duration ?? 0,
              }),
            ),
          })),
          lessonPlanSharings: lessonPlanSharings,
          lessonPlanPublishRequests: lessonPlanPublishRequests,
          lessonPlanConsults: lessonPlanConsults,
          sourcePublishRequests:
            sourcePublishRequests == null && latestSourcePublishRequest != null
              ? [latestSourcePublishRequest]
              : sourcePublishRequests,
        })

        if (lessonPlanSharings != null) {
          const sharedToUser = lessonPlanSharings
            .map((sharing) => {
              return sharing?.sharedToUser
            })
            .filter((it) => it != null)
          self.rootStore.usersStore.upsertUsers(sharedToUser)
        }

        if (lessonPlanDiStrategies != null) {
          const diStrategies = lessonPlanDiStrategies
            .map(({ diStrategy }) => {
              if (diStrategy == null) return
              return {
                ...diStrategy,
              }
            })
            .filter((it) => it != null)
          if (diStrategies.length) {
            self.rootStore.masterDataStore.upsertDiStrategies(diStrategies)
          }
        }

        if (lessonPlanStudentGroups != null) {
          const diStrategies = _.flattenDeep(
            lessonPlanStudentGroups.map((lessonPlanStudentGroup) => {
              const lessonPlanTeachingSteps =
                lessonPlanStudentGroup?.lessonPlanTeachingSteps
              return lessonPlanTeachingSteps.map((lessonPlanTeachingStep) =>
                lessonPlanTeachingStep.lessonPlanTeachingStepDiStrategies.map(
                  (lessonPlanTeachingStepDiStrategy) =>
                    lessonPlanTeachingStepDiStrategy?.diStrategy,
                ),
              )
            }),
          ).filter((it) => it != null)
          if (diStrategies.length) {
            self.rootStore.masterDataStore.upsertDiStrategies(diStrategies)
          }
        }

        if (lessonPlanAttachments != null) {
          self.rootStore.filesStore.upsertFiles(
            lessonPlanAttachments
              .filter((it) => it.file != null)
              .map((it) => it.file),
          )
          self.rootStore.masterDataStore.upsertResourceTypes(
            lessonPlanAttachments
              .filter((it) => it.resourceType != null)
              .map((it) => it.resourceType),
          )
        }

        if (lessonPlanTags != null) {
          self.rootStore.masterDataStore.upsertTags(
            lessonPlanTags.filter((it) => it.tag != null).map((it) => it.tag),
          )
        }

        if (createdByUser != null) {
          self.rootStore.usersStore.upsertUser(createdByUser)
        }

        if (ownedByUser != null) {
          self.rootStore.usersStore.upsertUser(ownedByUser)
        }

        if (lastModifiedByUser != null) {
          self.rootStore.usersStore.upsertUser(lastModifiedByUser)
        }

        if (subject != null) {
          self.rootStore.masterDataStore.upsertSubject(subject)
        }

        if (classLevel != null) {
          self.rootStore.masterDataStore.upsertClassLevel(classLevel)
        }

        if (authorUser != null) {
          self.rootStore.usersStore.upsertUser(authorUser)
        }

        let existing = self.lessonPlans.find(
          (lessonPlan) => lessonPlan.id === payload.id,
        )
        if (existing == null) {
          self.lessonPlans.push(payload)
          existing = self.lessonPlans[self.lessonPlans.length - 1]
        } else {
          existing.update(payload)
        }

        if (lessonPlanPublishRequests != null) {
          actions.upsertLessonPlanPublishRequests(lessonPlanPublishRequests)
        }
        if (lessonPlanConsults != null) {
          actions.upsertLessonPlanConsultRequests(lessonPlanConsults)
        }
        if (lessonPlanSharing != null) {
          actions.upsertLessonPlanSharing(lessonPlanSharing)
        }
        if (latestLessonPlanComment != null) {
          actions.upsertLessonPlanComment(latestLessonPlanComment)
        }
        if (lessonPlanComments != null) {
          actions.upsertLessonPlanComments(lessonPlanComments)
        }
        return existing
      },
      upsertLessonPlans(payloads: LessonPlanResponsePayload[]) {
        return payloads.map(actions.upsertLessonPlan)
      },
      getLessonPlans: flow(function* getLessonPlansAction(
        param?: LessonPlansQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<LessonPlan>,
        LessonPlansResponsePayload
      > {
        const payload = yield getLessonPlans(param)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return {
          ...payload,
          data: actions.upsertLessonPlans(payload.data),
        }
      }),
      getLessonPlanExamples: flow(function* getLessonPlanExamplesAction(
        param?: LessonPlanExamplesQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<LessonPlanExamplesResponsePayload>,
        PaginatedPayload<LessonPlanExamplesResponsePayload>
      > {
        const payload = yield getLessonPlanExamples(param)

        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
        }
      }),
      getLessonPlanSummary: flow(function* getLessonPlanSummaryAction(
        param?: LessonPlanExamplesQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<LessonPlanExamplesResponsePayload>,
        PaginatedPayload<LessonPlanExamplesResponsePayload>
      > {
        const payload = yield getLessonPlanSummary(param)

        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
        }
      }),
      getLessonPlansOwned: flow(function* getLessonPlansOwnedAction(
        param?: LessonPlansQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<LessonPlan>,
        LessonPlansOwnedResponsePayload
      > {
        const payload = yield getLessonPlansOwned(param)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return {
          ...payload,
          data: actions.upsertLessonPlans(payload.data),
        }
      }),
      getLessonPlansShared: flow(function* getLessonPlansSharedAction(
        param?: LessonPlansSharedQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<LessonPlan>,
        LessonPlansSharedResponsePayload
      > {
        const payload = yield getLessonPlansShared(param)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return {
          ...payload,
          data: actions.upsertLessonPlans(payload.data),
        }
      }),
      getLessonPlansOwnedPublished: flow(
        function* getLessonPlansOwnedPublishedAction(
          param?: LessonPlansQueryParameters,
        ): Generator<
          any,
          PaginatedPayload<LessonPlan>,
          LessonPlansOwnedResponsePayload
        > {
          const payload = yield getLessonPlansOwnedPublished(param)

          if (payload == null) throw new Error(emptyPayloadMessage)

          return {
            ...payload,
            data: actions.upsertLessonPlans(payload.data),
          }
        },
      ),
      upsertLessonPlanUpdatedExampleAndResource(
        inPayload: LessonPlansUpdatedExampleAndResourceResponsePayload,
      ) {
        const { classLevel, school, subject, ...payload } = inPayload
        return {
          ...payload,
          classLevel:
            classLevel != null
              ? self.rootStore.masterDataStore.upsertClassLevel(classLevel)
              : undefined,
          school:
            school != null
              ? self.rootStore.usersStore.upsertSchool(school)
              : undefined,
          subject:
            subject != null
              ? self.rootStore.masterDataStore.upsertSubject(subject)
              : undefined,
        }
      },
      upsertLessonPlanUpdatedExamplesAndResources(
        payloads: LessonPlansUpdatedExampleAndResourceResponsePayload[],
      ) {
        return payloads.map(actions.upsertLessonPlanUpdatedExampleAndResource)
      },
      getLessonPlansUpdatedExamplesAndResources: flow(
        function* getLessonPlansUpdatedExamplesAndResourcesAction(
          param?: LessonPlansUpdatedExamplesAndResourcesParameters,
        ): Generator<
          any,
          PaginatedPayload<LessonPlansUpdatedExampleAndResourceResponsePayload>,
          LessonPlansUpdatedExamplesAndResourcesResponsePayload
        > {
          const payload = yield getLessonPlansUpdatedExamplesAndResources(param)
          if (payload == null) throw new Error(emptyPayloadMessage)
          return {
            ...payload,
            data: actions.upsertLessonPlanUpdatedExamplesAndResources(
              payload.data,
            ),
          }
        },
      ),
      getLessonPlan: flow(function* getLessonPlanAction(
        id: string,
        param?: LessonPlanExpandableParameter,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield getLessonPlanById(id, param)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return actions.upsertLessonPlan(payload)
      }),
      getLessonPlanDetail: flow(function* getLessonPlanAction(
        id: string,
        param?: LessonPlanExpandableParameter,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield getLessonPlanDetailById(id, param)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return actions.upsertLessonPlan(payload)
      }),
      createLessonPlan: flow(function* createLessonPlanAction(
        lessonPlan: PostLessonPlanPayload,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield postLessonPlan(lessonPlan)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return actions.upsertLessonPlan(payload)
      }),
      upsertLessonPlanSharing(inPayload: LessonPlanSharingResponsePayload) {
        const { lessonPlan, sharedToUser, ...maybeNullPayload } = inPayload
        const payload = convertNullToUndefined(maybeNullPayload)
        if (lessonPlan != null) {
          actions.upsertLessonPlan(lessonPlan)
        }
        if (sharedToUser != null) {
          self.rootStore.usersStore.upsertUser(sharedToUser)
        }
        const targetLessonPlan = self.lessonPlans.find(
          (it) => it.id === payload.lessonPlanId,
        )
        if (targetLessonPlan == null) throw new Error('lesson plan not found')
        targetLessonPlan.update({
          ...targetLessonPlan,
          lessonPlanSharings: [...targetLessonPlan.lessonPlanSharings, payload],
        })
        return targetLessonPlan
      },
      upsertLessonPlanSharings(payloads: LessonPlanSharingResponsePayload[]) {
        return payloads.map(actions.upsertLessonPlanSharing)
      },
      getLessonPlanSharings: flow(function* getLessonPlanSharingsAction(
        param?: LessonPlanSharingsQueryParameters,
      ): Generator<any, any, LessonPlanSharingsResponsePayload> {
        const payload = yield getLessonPlanSharings(param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertLessonPlanSharings(payload.data),
        }
      }),
      deleteLessonPlanSharings: flow(function* deleteLessonPlanSharingsAction(
        id: string,
      ) {
        const payload = yield deleteLessonPlanSharing(id)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return true
      }),
      createLessonPlanSharing: flow(function* createLessonPlanSharingAction(
        id: string,
        lessonPlanSharing: PostLessonPlanSharingPayload,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield postLessonPlanSharingById(id, lessonPlanSharing)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertLessonPlan(payload)
      }),
      createLessonPlanSharings: flow(function* createLessonPlanSharingsAction(
        id: string,
        lessonPlanSharings: PostLessonPlanSharingPayload[],
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield bulkPostLessonPlanSharingsById(
          id,
          lessonPlanSharings,
        )
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertLessonPlan(payload)
      }),
      createLessonPlanComment: flow(function* createLessonPlanCommentAction(
        id: string,
        lessonPlanComment: PostLessonPlanCommentPayload,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield postLessonPlanComment(id, lessonPlanComment)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertLessonPlan(payload)
      }),
      upsertLessonPlanComment(
        inPayload: LessonPlanCommentResponsePayload,
      ): LessonPlanComment {
        const {
          lessonPlan,
          createdByUser,
          repliedToComment,
          lessonPlanCommentAttachments,
          ...maybeNullPayload
        } = inPayload
        const payload = convertNullToUndefined({
          ...maybeNullPayload,
          lessonPlanCommentAttachments: lessonPlanCommentAttachments?.map(
            (it) => ({
              attachmentName: it.attachmentName,
              fileId: it.fileId,
              id: it.id,
              order: it.order,
              resourceTypeId: it.resourceTypeId,
              createdByUserId: it.createdByUserId,
              lastModifiedByUserId: it.lastModifiedByUserId,
              createdDateTime: it.createdDateTime,
              lastModifiedDateTime: it.lastModifiedDateTime,
              rowVersion: it.rowVersion,
            }),
          ),
        })
        if (repliedToComment != null) {
          actions.upsertLessonPlanComment(repliedToComment)
        }
        if (lessonPlan != null) {
          actions.upsertLessonPlan(lessonPlan)
        }
        if (createdByUser != null) {
          self.rootStore.usersStore.upsertUser(createdByUser)
        }
        if (lessonPlanCommentAttachments != null) {
          self.rootStore.filesStore.upsertFiles(
            lessonPlanCommentAttachments
              .filter((it) => it.file != null)
              .map((it) => it.file),
          )
        }
        const targetLessonPlan = self.lessonPlans.find(
          (it) => it.id === payload.lessonPlanId,
        )
        if (targetLessonPlan?.lessonPlanComments == null)
          throw new Error('lesson plan not found')

        const existing = targetLessonPlan.lessonPlanComments.find(
          (it) => it.id === payload.id,
        )

        if (existing) {
          existing.update(payload)
          return existing
        }
        targetLessonPlan.lessonPlanComments.push(payload)

        return targetLessonPlan.lessonPlanComments[
          targetLessonPlan.lessonPlanComments.length - 1
        ]
      },
      upsertLessonPlanComments(payloads: LessonPlanCommentResponsePayload[]) {
        return payloads.map(actions.upsertLessonPlanComment)
      },
      deleteLessonPlanComment: flow(function* deleteLessonPlanCommentAction(
        id: string,
      ): Generator<any, LessonPlanComment, LessonPlanCommentResponsePayload> {
        const payload = yield deleteLessonPlanComment(id)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertLessonPlanComment(payload)
      }),
      createLessonPlanPublishRequest: flow(
        function* createLessonPlanPublishRequestAction(
          id: string,
          rowVersion: string,
          isAgreePublish: boolean,
        ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
          const payload = yield postSubmitPublishRequest(
            id,
            rowVersion,
            isAgreePublish,
          )
          if (payload == null) throw new Error(emptyPayloadMessage)
          return actions.upsertLessonPlan(payload)
        },
      ),
      updateLessonPlan: flow(function* updateLessonPlanAction(
        id: string,
        lessonPlan: PutLessonPlanPayload,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield putLessonPlan(id, lessonPlan)

        if (payload == null) throw new Error(emptyPayloadMessage)

        return actions.upsertLessonPlan(payload)
      }),
      transferLessonPlan: flow(function* transferLessonPlanAction(
        id: string,
        owner: PostLessonPlanTransferOwnerPayload,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield postLessonPlanTransferOwner(id, owner)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertLessonPlan(payload)
      }),
      updateLessonPlanCommentById: flow(
        function* updateLessonPlanCommentByIdAction(
          id: string,
          lessonPlanComment: PatchLessonPlanCommentPayload,
        ): Generator<any, LessonPlanComment, LessonPlanCommentResponsePayload> {
          const payload = yield patchLessonPlanCommentById(
            id,
            lessonPlanComment,
          )
          if (payload == null) throw new Error(emptyPayloadMessage)
          return actions.upsertLessonPlanComment(payload)
        },
      ),
      getLessonPlanComments: flow(function* getLessonPlanCommentsAction(
        id: string,
        param?: LessonPlanCommentsQueryParameters,
      ): Generator<
        any,
        PaginatedPayload<LessonPlanComment>,
        LessonPlanCommentsResponsePayload
      > {
        const payload = yield getLessonPlanComments(id, param)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return {
          ...payload,
          data: actions.upsertLessonPlanComments(payload.data),
        }
      }),
      publishLessonPlanExample: flow(function* publishLessonPlanExample(
        id: string,
        rowVersion: string,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield postPublishLessonPlanExample(id, rowVersion)
        if (payload == null) throw new Error(emptyPayloadMessage)

        return actions.upsertLessonPlan(payload)
      }),
      unpublishLessonPlanExample: flow(function* unpublishLessonPlanExample(
        id: string,
        rowVersion: string,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield postUnPublishLessonPlanExample(id, rowVersion)
        if (payload == null) throw new Error(emptyPayloadMessage)

        return actions.upsertLessonPlan(payload)
      }),
      upsertLessonPlanPublishRequest(
        inPayload: LessonPlanPublishRequestResponsePayload,
      ): LessonPlanPublishRequest {
        const { initiatedLessonPlan, ...maybeNullPayload } = inPayload
        const payload = convertNullToUndefined({
          ...maybeNullPayload,
          initiatedLessonPlan: initiatedLessonPlan,
        })

        if (initiatedLessonPlan != null) {
          actions.upsertLessonPlan(initiatedLessonPlan)
        }

        const targetLessonPlan = self.lessonPlans.find(
          (it) => it.id === payload.initiatedLessonPlanId,
        )
        if (targetLessonPlan == null) throw new Error('lesson plan not found')

        const existing = targetLessonPlan.lessonPlanPublishRequests.find(
          (it) => it.id === payload.id,
        )

        if (existing) {
          existing.update(payload)
          return existing
        }
        targetLessonPlan.lessonPlanPublishRequests.push(payload)

        return targetLessonPlan.lessonPlanPublishRequests[
          targetLessonPlan.lessonPlanPublishRequests.length - 1
        ]
      },
      upsertLessonPlanPublishRequests(
        payloads: LessonPlanPublishRequestResponsePayload[],
      ) {
        return payloads.map(actions.upsertLessonPlanPublishRequest)
      },
      withdrawLessonPlanPublishRequest: flow(
        function* withdrawLessonPlanPublishRequestAction(
          id: string,
          rowVersion: string,
        ): Generator<
          any,
          LessonPlanPublishRequest,
          LessonPlanPublishRequestResponsePayload
        > {
          const payload = yield postWithdrawPublishRequest(id, rowVersion)
          if (payload == null) throw new Error(emptyPayloadMessage)
          return actions.upsertLessonPlanPublishRequest(payload)
        },
      ),
      approveLessonPlanPublishRequest: flow(
        function* approveLessonPlanPublishRequestAction(
          id: string,
          rowVersion: string,
        ): Generator<
          any,
          LessonPlanPublishRequest,
          LessonPlanPublishRequestResponsePayload
        > {
          const payload = yield approveLessonPlanPublishRequest(id, rowVersion)
          if (payload == null) throw new Error(emptyPayloadMessage)
          return actions.upsertLessonPlanPublishRequest(payload)
        },
      ),
      rejectLessonPlanPublishRequest: flow(
        function* rejectLessonPlanPublishRequestAction(
          id: string,
          rowVersion: string,
        ): Generator<
          any,
          LessonPlanPublishRequest,
          LessonPlanPublishRequestResponsePayload
        > {
          const payload = yield rejectLessonPlanPublishRequest(id, rowVersion)
          if (payload == null) throw new Error(emptyPayloadMessage)
          return actions.upsertLessonPlanPublishRequest(payload)
        },
      ),
      completeAndPublishLessonPlanPublishRequest: flow(
        function* completeAndPublishLessonPlanPublishRequestAction(
          id: string,
          rowVersion: string,
        ): Generator<
          any,
          LessonPlanPublishRequest,
          LessonPlanPublishRequestResponsePayload
        > {
          const payload = yield completeAndPublishLessonPlanPublishRequest(
            id,
            rowVersion,
          )
          return actions.upsertLessonPlanPublishRequest(payload)
        },
      ),
      copyLessonPlanExample: flow(function* copyLessonPlanExampleAction(
        id: string,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield postCopyLessonPlanExample(id)
        if (payload == null) throw new Error(emptyPayloadMessage)

        return actions.upsertLessonPlan(payload)
      }),
      getConsultRequest: flow(function* getConsultRequestAction(
        id: string,
        param?: LessonPlanConsultRequestExpandableParameter,
      ): Generator<
        any,
        LessonPlanConsultResponsePayload,
        LessonPlanConsultResponsePayload
      > {
        const payload = yield getLessonPlanConsultRequestById(id, param)

        if (payload == null) throw new Error(emptyPayloadMessage)

        if (payload?.lessonPlan != null) {
          actions.upsertLessonPlan(payload.lessonPlan)
        }

        return {
          ...payload,
        }
      }),
      createConsultRequest: flow(function* createConsultRequestAction(
        id: string,
        lessonPlanConsult: PostLessonPlanConsultPayload,
      ): Generator<any, LessonPlan, LessonPlanResponsePayload> {
        const payload = yield postLessonPlanConsultById(id, lessonPlanConsult)
        if (payload == null) throw new Error(emptyPayloadMessage)
        return actions.upsertLessonPlan(payload)
      }),
      createConsultRequestWithExpert: flow(
        function* createConsultRequestWithExpertAction(
          id: string,
          lessonPlanConsultWithExpert: PostLessonPlanConsultWithExpertPayload,
        ): Generator<
          any,
          LessonPlanConsult,
          LessonPlanConsultRequestResponsePayload
        > {
          const payload = yield postLessonPlanConsultRequestAssignExpert(
            id,
            lessonPlanConsultWithExpert,
          )
          if (payload == null) throw new Error(emptyPayloadMessage)
          return actions.upsertLessonPlanConsultRequest(payload)
        },
      ),
      createConsultRequestComplete: flow(
        function* createConsultRequestCompleteAction(
          id: string,
          rowVersion: string,
        ): Generator<
          any,
          LessonPlanConsult,
          LessonPlanConsultRequestResponsePayload
        > {
          const payload = yield postLessonPlanConsultComplete(id, rowVersion)
          if (payload == null) throw new Error(emptyPayloadMessage)
          return actions.upsertLessonPlanConsultRequest(payload)
        },
      ),
      upsertLessonPlanConsultRequest(
        inPayload: LessonPlanConsultResponsePayload,
      ): LessonPlanConsult {
        const { lessonPlan, assignedExpertUser, ...maybeNullPayload } =
          inPayload
        const payload = convertNullToUndefined(maybeNullPayload)

        if (lessonPlan != null) {
          actions.upsertLessonPlan(lessonPlan)
        }

        if (assignedExpertUser != null) {
          self.rootStore.usersStore.upsertUser(assignedExpertUser)
        }

        const targetLessonPlan = self.lessonPlans.find(
          (it) => it.id === payload.lessonPlanId,
        )
        if (targetLessonPlan?.lessonPlanConsults == null)
          throw new Error('lesson plan consults not found')

        const existing = targetLessonPlan.lessonPlanConsults.find(
          (it) => it.id === payload.id,
        )

        if (existing) {
          existing.update(payload)
          return existing
        }

        targetLessonPlan.lessonPlanConsults.push(payload)
        return targetLessonPlan.lessonPlanConsults[
          targetLessonPlan.lessonPlanConsults.length - 1
        ]
      },
      upsertLessonPlanConsultRequests(
        payloads: LessonPlanConsultResponsePayload[],
      ) {
        return payloads.map(actions.upsertLessonPlanConsultRequest)
      },
      incrementLessonPlanViewCount: flow(
        function* incrementLessonPlanViewCountAction(
          id: string,
        ): Generator<any, number, number> {
          const payload = yield postLessonPlanViewCount(id)
          if (payload == null) throw new Error(emptyPayloadMessage)
          return payload
        },
      ),
      incrementLessonPlanExportCount: flow(
        function* incrementLessonPlanExportCountAction(
          id: string,
        ): Generator<any, number, number> {
          const payload = yield postLessonPlanExportCount(id)
          if (payload == null) throw new Error(emptyPayloadMessage)
          return payload
        },
      ),
    }
    return actions
  })

export type LessonPlansStore = Instance<typeof LessonPlansStoreModel>
