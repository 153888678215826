import { Box, Button, Grid, Typography } from '@mui/material'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormLabel, TextField } from '../../../../../components'
import styled from 'styled-components'
import { GeneralInformationFormData } from '../../../lesson-plan-form-provider'
import { Svg } from '../../../../../assets'

const Container = styled.div``

const Section = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palettes.general.white,
  borderRadius: 30,
  padding: 28,
  margin: '20px 0px',
}))

type CharacteristicProps = {
  groupIndex: number
  group: FieldArrayWithId<
    GeneralInformationFormData,
    'lessonPlanStudentGroups',
    'id'
  >
  onRemoveStudentTrailGroup(): void
}

export const Characteristic = observer<CharacteristicProps>(
  ({ group, groupIndex, onRemoveStudentTrailGroup }) => {
    const theme = useTheme()

    const { t } = useTranslation()

    const {
      control,
      watch,
      formState: { errors },
    } = useFormContext<GeneralInformationFormData>()
    const isMultiGroup = watch('isMultiGroup') ?? false

    const {
      fields: teachingContentFields,
      append: appendTeachingContent,
      remove: removeTeachingContent,
    } = useFieldArray({
      control,
      name: `lessonPlanStudentGroups[${groupIndex}].lessonPlanTeachingGoals` as any,
    })

    const {
      fields: expectedResultsLessonFields,
      append: appendExpectedResultsLesson,
      remove: removeExpectedResultsLesson,
    } = useFieldArray({
      control,
      name: `lessonPlanStudentGroups[${groupIndex}].lessonPlanExpectedOutcomes` as any,
    })

    if (!isMultiGroup)
      return (
        <Container>
          <Section>
            <Box>
              <FormLabel
                label={t(
                  'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingType.fields.teachingContents.label',
                )}
              />
              <Box mt={2}>
                {teachingContentFields.map((field, index) => (
                  <Grid key={field.id} container columnSpacing={3}>
                    <Grid item flexShrink={0} pr={2} marginTop="6px">
                      <Typography fontWeight={600}>{index + 1}.</Typography>
                    </Grid>
                    <Grid item flexGrow={1} xs={11}>
                      <Controller
                        control={control}
                        name={
                          `lessonPlanStudentGroups[${groupIndex}].lessonPlanTeachingGoals[${index}].description` as any
                        }
                        defaultValue={(field as any).description}
                        render={({ field: params }) => (
                          <TextField
                            placeholder={t(
                              'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingType.fields.teachingContents.placeholder',
                            )}
                            mb={2}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            error={
                              errors?.lessonPlanStudentGroups?.[groupIndex]
                                ?.lessonPlanTeachingGoals?.[index]?.description
                                ?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item flexShrink={0} mt={2}>
                      <Svg.Close
                        cursor="pointer"
                        onClick={() => removeTeachingContent(index)}
                        fill={theme.palettes.general.greys[2]}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Box>
              <Grid container justifyContent="flex-end">
                <Svg.Plus
                  onClick={() =>
                    appendTeachingContent({
                      description: '',
                      order: teachingContentFields.length + 1,
                    })
                  }
                  cursor="pointer"
                  fill={theme.palettes.general.blue}
                  height={24}
                  width={24}
                />
              </Grid>
            </Box>

            <Box mt={12}>
              <FormLabel
                label={t(
                  'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingType.fields.expectedResultLessons.label',
                )}
              />
              <Box mt={2}>
                {expectedResultsLessonFields.map((field, index) => (
                  <Grid key={field.id} container columnSpacing={3}>
                    <Grid item flexShrink={0} pr={2} marginTop="6px">
                      <Typography fontWeight={600}>{index + 1}.</Typography>
                    </Grid>
                    <Grid item flexGrow={1} xs={11}>
                      <Controller
                        key={field.id}
                        control={control}
                        name={
                          `lessonPlanStudentGroups[${groupIndex}].lessonPlanExpectedOutcomes[${index}].description` as any
                        }
                        defaultValue={(field as any).description}
                        render={({ field: params }) => (
                          <TextField
                            placeholder={t(
                              'lessonPlan.createForm.generalInformation.fields.wholeClassTeachingType.fields.expectedResultLessons.placeholder',
                            )}
                            mb={2}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            error={
                              errors?.lessonPlanStudentGroups?.[groupIndex]
                                ?.lessonPlanExpectedOutcomes?.[index]
                                ?.description?.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item flexShrink={0} mt={2}>
                      <Svg.Close
                        cursor="pointer"
                        onClick={() => removeExpectedResultsLesson(index)}
                        fill={theme.palettes.general.greys[2]}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Box>
              <Grid container justifyContent="flex-end">
                <Svg.Plus
                  onClick={() =>
                    appendExpectedResultsLesson({
                      description: '',
                      order: expectedResultsLessonFields.length + 1,
                    })
                  }
                  cursor="pointer"
                  fill={theme.palettes.general.blue}
                  height={24}
                  width={24}
                />
              </Grid>
            </Box>
          </Section>
        </Container>
      )

    return (
      <Section>
        <Grid container>
          <Grid item flex={2} mt={2}>
            <FormLabel
              label={t(
                'lessonPlan.createForm.generalInformation.fields.basedOnStudentTraitGroupsTeachingType.characteristic.label',
              )}
            />
          </Grid>
          <Grid item flex={6}>
            <Controller
              control={control}
              name={
                `lessonPlanStudentGroups[${groupIndex}].groupCharacteristic` as any
              }
              defaultValue={(group as any).groupCharacteristic}
              render={({ field: params }) => (
                <TextField
                  placeholder={t(
                    'lessonPlan.createForm.generalInformation.fields.basedOnStudentTraitGroupsTeachingType.characteristic.placeholder',
                  )}
                  mb={2}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  error={
                    errors?.lessonPlanStudentGroups?.[groupIndex]
                      ?.groupCharacteristic?.message
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flex={2} mt={2}>
            <FormLabel
              label={t(
                'lessonPlan.createForm.generalInformation.fields.basedOnStudentTraitGroupsTeachingType.teachingContents.label',
              )}
            />
          </Grid>
          <Grid item flex={6}>
            {teachingContentFields.map((field, index) => (
              <Grid
                key={field.id}
                container
                justifyContent="space-between"
                flexWrap="nowrap"
              >
                <Grid item flexShrink={0} pr={2} marginTop="6px">
                  <Typography fontWeight={600}>{index + 1}.</Typography>
                </Grid>
                <Grid flexGrow={1} item xs={11}>
                  <Controller
                    control={control}
                    name={
                      `lessonPlanStudentGroups[${groupIndex}].lessonPlanTeachingGoals[${index}].description` as any
                    }
                    defaultValue={(field as any).description}
                    render={({ field: params }) => (
                      <TextField
                        placeholder={t(
                          'lessonPlan.createForm.generalInformation.fields.basedOnStudentTraitGroupsTeachingType.teachingContents.placeholder',
                        )}
                        mb={2}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        error={
                          errors?.lessonPlanStudentGroups?.[groupIndex]
                            ?.lessonPlanTeachingGoals?.[index]?.description
                            ?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item flexShrink={1} mt={2} pl={2}>
                  <Svg.Close
                    cursor="pointer"
                    onClick={() => removeTeachingContent(index)}
                    fill={theme.palettes.general.greys[2]}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item flexShrink={0} marginTop={2}>
            <Svg.Plus
              cursor="pointer"
              onClick={() =>
                appendTeachingContent({
                  description: '',
                  order: teachingContentFields.length + 1,
                })
              }
              fill={theme.palettes.general.blue}
              height={24}
              width={24}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item flex={2} mt={2}>
            <FormLabel
              label={t(
                'lessonPlan.createForm.generalInformation.fields.basedOnStudentTraitGroupsTeachingType.expectedResultLessons.label',
              )}
            />
          </Grid>
          <Grid item flex={6}>
            {expectedResultsLessonFields.map((field, index) => (
              <Grid
                key={field.id}
                container
                justifyContent="space-between"
                flexWrap="nowrap"
              >
                <Grid item flexShrink={0} pr={2} marginTop="6px">
                  <Typography fontWeight={600}>{index + 1}.</Typography>
                </Grid>
                <Grid flexGrow={1} item xs={11}>
                  <Controller
                    control={control}
                    name={
                      `lessonPlanStudentGroups[${groupIndex}].lessonPlanExpectedOutcomes[${index}].description` as any
                    }
                    defaultValue={(field as any).description}
                    render={({ field: params }) => (
                      <TextField
                        placeholder={t(
                          'lessonPlan.createForm.generalInformation.fields.basedOnStudentTraitGroupsTeachingType.expectedResultLessons.placeholder',
                        )}
                        mb={2}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        error={
                          errors?.lessonPlanStudentGroups?.[groupIndex]
                            ?.lessonPlanExpectedOutcomes?.[index]?.description
                            ?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item flexShrink={0} mt={2} pl={2}>
                  <Svg.Close
                    cursor="pointer"
                    onClick={() => removeExpectedResultsLesson(index)}
                    fill={theme.palettes.general.greys[2]}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item flexShrink={0} marginTop={2}>
            <Svg.Plus
              cursor="pointer"
              onClick={() =>
                appendExpectedResultsLesson({
                  description: '',
                  order: expectedResultsLessonFields.length + 1,
                })
              }
              height={24}
              width={24}
              fill={theme.palettes.general.blue}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end" mt={2}>
          <Grid item width={100}>
            <Button
              fullWidth
              color="cascade"
              variant="outlined"
              startIcon={<Svg.Delete />}
              onClick={onRemoveStudentTrailGroup}
            >
              {t('common.delete')}
            </Button>
          </Grid>
        </Grid>
      </Section>
    )
  },
)
