import { common } from './common'
import { contactUs } from './contact-us'
import { error } from './error'
import { footer } from './footer'
import { home } from './home'
import { lessonPlan } from './lesson-plan'
import { login } from './login'
import { myLessonPlan } from './my-lesson-plan'
import { sidebar } from './sidebar'
import { topBar } from './top-bar'
import { lessonPlanCommunication } from './lesson-plan-communication'
import { attachment } from './attachment'
import { masterData } from './master-data'
import { submissionRecords } from './submission-records'
import { lessonPlanExample } from './lesson-plan-example'
import { lessonPlanRequest } from './lesson-plan-request'
import { users } from './users'
import { notifications } from './notifications'
import { pagination } from './pagination'
import { resources } from './resources'
import { schools } from './schools'
import { diStrategy } from './di-strategy'
import { disposedLessonPlan } from './disposed-lesson-plan'

export const en = {
  common,
  footer,
  login,
  home,
  contactUs,
  myLessonPlan,
  disposedLessonPlan,
  lessonPlan,
  lessonPlanExample,
  lessonPlanRequest,
  sidebar,
  topBar,
  error,
  lessonPlanCommunication,
  attachment,
  masterData,
  submissionRecords,
  users,
  notifications,
  pagination,
  resources,
  schools,
  diStrategy,
}
