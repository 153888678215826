import { Box } from '@mui/material'
import { memo, ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  children?: ReactNode
  index: number | string
  value: number | string
  isTabHidden?: boolean
}

const Container = styled.div``

export const VerticalTabPanel = memo<Props>(
  ({ children, index, value, isTabHidden }) => {
    return (
      <Container>
        {value === index && (
          <Box p={isTabHidden ? 0 : 1} ml={isTabHidden ? 0 : 5}>
            {children}
          </Box>
        )}
      </Container>
    )
  },
)
