export const schools = {
  title: {
    schoolSummary: '學校總覽',
  },
  entityName: '帳戶資料',

  fields: {
    name: '學校名稱',
    chineseName: '學校中文名稱',
    englishName: '學校英文名稱',
    shortCode: '名稱簡寫',
    schoolAdminEmail: '學校管理員電郵',
    domain: '學校網域名稱',
    status: '學校狀態',
    educationLevel: '小學/中學',
  },

  actions: {
    add: {
      title: '新增學校',
      confirm: '建立帳戶',
      successMessage: '成功新增學校資料！',
      errorMessage: '建立帳戶失敗',
      placeholder: {
        chineseName: '中文全名',
        englishName: '英文全名',
        shortCode: '英文簡寫',
        schoolAdminEmail: '管理員電郵',
        domain: '網域名稱',
        educationLevel: '小學/中學',
      },
    },
    edit: {
      title: '修改學校資料',
      confirm: '修改帳戶資料',
      successMessage: '成功修改學校資料',
      errorMessage: '修改帳戶資料失敗',
    },
    assignNewSchoolAdmin: {
      title: '指派學校管理員',
    },
    disableAccount: {
      title: '停用帳戶',
      successMessage: '學校已成功停用! ',
      errorMessage: '停用帳戶失敗',
      confirmationDialog: {
        title: '停用帳戶',
        content: '此學校帳戶即將被停用',
      },
    },
    enableAccount: {
      title: '啟用帳戶',
      successMessage: '學校帳戶已成功啟用! ',
      errorMessage: '啟用帳戶失敗',
    },
  },

  status: {
    active: '使用中',
    disabled: '已停用',
  },
}
