export const disposedLessonPlan = {
  title: '已作廢教案',

  tableFields: {
    class: '年級',
    subject: '科目',
    teachingTopic: '教案名稱',
    lastModifiedBy: '最後更新',
    lastModifiedDateTime: '最後更新日期',
  },

  pagination: {
    next: '下頁',
  },
}
