import { Box } from '@mui/material'
import { memo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SectionTitle } from '../../../../../components'
import { SectionBox } from '../../../components'
import { GeneralInformationFormData } from '../../../lesson-plan-form-provider'
import { TabHeader } from '../tab-header'
import { TeachingFlowGroup } from './teaching-flow-group'
import { TeachingProcessExtra } from './teaching-process-extra'

type TeachingProcessTabProps = {}

export const TeachingProcessTab = memo<TeachingProcessTabProps>(() => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext<GeneralInformationFormData>()
  const isMultiGroup = watch('isMultiGroup')

  const { fields } = useFieldArray({
    control,
    name: 'lessonPlanStudentGroups',
  })

  return (
    <Box>
      <SectionBox>
        <TabHeader />
        {isMultiGroup && (
          <Box mt={5}>
            <SectionTitle
              mb={0}
              title={t(
                'lessonPlan.createForm.generalInformation.teachingProcess.teachingProcessForEachGroupTitle',
              )}
              color="lightBlue"
            />
          </Box>
        )}
        {isMultiGroup === false && (
          <TeachingFlowGroup
            control={control}
            fieldArrayName="lessonPlanStudentGroups[0].lessonPlanTeachingSteps"
            studentGroupIndex={0}
          />
        )}
      </SectionBox>

      <TeachingProcessExtra
        control={control}
        groups={fields}
        isMultiGroup={isMultiGroup}
      />
    </Box>
  )
})
