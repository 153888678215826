import { RoleType } from '@polyu-dip/models'
import { useMemo } from 'react'
import { RouteObject, Navigate } from 'react-router-dom'
import { PageFrame } from '../components'
import { contentPaths } from '../content-paths'
import { contentPermissions } from '../content-permissions'
import {
  ApiTestPage,
  ContactUs,
  Disclaimer,
  DevOnlyPageGuard,
  HealthCheckPage,
  LessonPlanDetailPage,
  LessonPlanEditPage,
  LessonPlanCommunication,
  MyLessonPlan,
  PrivatePage,
  UiComponent,
  LessonPlanExampleDetailPage,
  HomePage,
  SubmissionRecords,
  LessonPlanExamples,
  LessonPlanPublishRequestDetailPage,
  LessonPlanPublishRequests,
  LessonPlanConsultRequestDetailPage,
  LessonPlanConsultRequests,
  SchoolUsers,
  Resources,
  UserProfilePage,
  Notifications,
  LessonPlanPublishCopyDetailPage,
  Schools,
  DiStrategies,
  MasterDataManagement,
  SystemUsers,
  ForbiddenPage,
  LessonPlanSharingDetailPage,
  ResourceTabMenu,
  DisposedLessonPlan,
} from '../pages'
import { LessonPlanPublishRequestEditPage } from '../pages/lesson-plan-requests/lesson-plan-publish-request-edit-page'
import { useStores } from '../stores'

export type RouteObjectWithPermission = Omit<RouteObject, 'children'> & {
  permittedRoles?: RoleType[]
  children?: RouteObjectWithPermission[]
}

export function useRoute(): RouteObject[] {
  const { userProfileStore } = useStores()

  const routeItems: RouteObjectWithPermission[] = useMemo(
    () => [
      // TODO: redirect lesson plan with only ID to ID/view
      {
        path: contentPaths.health(),
        element: <HealthCheckPage />,
      },
      {
        path: contentPaths.home(),
        element: (
          <PrivatePage>
            <PageFrame />
          </PrivatePage>
        ),
        children: [
          {
            path: contentPaths.myProfile(),
            element: <UserProfilePage isMyProfile />,
          },
          {
            path: contentPaths.home(),
            element: <HomePage />,
            permittedRoles: contentPermissions.home,
          },
          {
            path: contentPaths.myLessonPlan(),
            element: <MyLessonPlan />,
            permittedRoles: contentPermissions.myLessonPlan,
          },
          {
            path: contentPaths.disposedLessonPlan(),
            element: <DisposedLessonPlan />,
            permittedRoles: contentPermissions.disposedLessonPlan,
          },
          {
            path: contentPaths.lessonPlans(':lessonPlanId', 'edit'),
            element: <LessonPlanEditPage isExample={false} />,
            permittedRoles: contentPermissions.myLessonPlan,
          },
          {
            path: contentPaths.lessonPlans(':lessonPlanId', 'view'),
            element: <LessonPlanDetailPage />,
            permittedRoles: contentPermissions.myLessonPlan,
          },
          {
            path: contentPaths.disposedLessonPlans(':lessonPlanId', 'view'),
            element: <LessonPlanDetailPage isDisposed />,
            permittedRoles: contentPermissions.disposedLessonPlan,
          },
          {
            path: contentPaths.allLessonPlan(':lessonPlanId'),
            element: <LessonPlanDetailPage />,
            permittedRoles: contentPermissions.allLessonPlan,
          },
          {
            path: contentPaths.sharedLessonPlans(':lessonPlanId'),
            element: <LessonPlanSharingDetailPage />,
            permittedRoles: contentPermissions.lessonPlanSharing,
          },
          {
            path: contentPaths.lessonPlanSharing(),
            element: <LessonPlanCommunication />,
            permittedRoles: contentPermissions.lessonPlanSharing,
          },
          {
            path: contentPaths.submissionRecords(),
            element: <SubmissionRecords />,
            permittedRoles: contentPermissions.submissionRecords,
          },
          {
            path: contentPaths.lessonPlanExampleManagements(),
            element: <LessonPlanExamples isManagementPage />,
            permittedRoles: contentPermissions.lessonPlanExampleManagement,
          },
          {
            path: contentPaths.lessonPlanExampleManagement(
              ':lessonPlanId',
              'view',
            ),
            element: <LessonPlanExampleDetailPage />,
            permittedRoles: contentPermissions.lessonPlanExampleManagement,
          },
          {
            path: contentPaths.lessonPlanExampleManagement(
              ':lessonPlanId',
              'edit',
            ),
            element: <LessonPlanEditPage isExample />,
            permittedRoles: contentPermissions.lessonPlanExampleManagement,
          },
          {
            path: contentPaths.lessonPlanExamples(),
            element: <LessonPlanExamples />,
            permittedRoles: contentPermissions.lessonPlanExamples,
          },
          {
            path: contentPaths.lessonPlanPublishCopy(':lessonPlanId'),
            element: <LessonPlanPublishCopyDetailPage />,
            permittedRoles: contentPermissions.lessonPlanPublishCopy,
          },
          {
            path: contentPaths.lessonPlanExamplesDetail(':lessonPlanId'),
            element: <LessonPlanExampleDetailPage />,
            permittedRoles: contentPermissions.lessonPlanExamples,
          },
          {
            path: contentPaths.lessonPlanConsultRequests(),
            element: <LessonPlanConsultRequests />,
            permittedRoles: contentPermissions.lessonPlanConsultRequest,
          },
          {
            path: contentPaths.lessonPlanConsultRequest(
              ':lessonPlanConsultRequestId',
            ),
            element: <LessonPlanConsultRequestDetailPage />,
            permittedRoles: contentPermissions.lessonPlanConsultRequest,
          },
          {
            path: contentPaths.lessonPlanPublishRequests(),
            element: <LessonPlanPublishRequests />,
            permittedRoles: contentPermissions.lessonPlanPublishRequest,
          },
          {
            path: contentPaths.lessonPlanPublishRequest(
              ':lessonPlanPublishRequestId',
              'view',
            ),
            element: <LessonPlanPublishRequestDetailPage />,
            permittedRoles: contentPermissions.lessonPlanPublishRequest,
          },
          {
            path: contentPaths.lessonPlanPublishRequest(
              ':lessonPlanPublishRequestId',
              'view-comments',
            ),
            element: <LessonPlanPublishRequestDetailPage isViewComment />,
            permittedRoles: contentPermissions.lessonPlanPublishRequest,
          },
          {
            path: contentPaths.lessonPlanPublishRequest(
              ':lessonPlanPublishRequestId',
              'edit',
            ),
            element: <LessonPlanPublishRequestEditPage />,
            permittedRoles: contentPermissions.lessonPlanPublishRequest,
          },
          {
            path: contentPaths.diStrategies(),
            element: <DiStrategies />,
            permittedRoles: contentPermissions.diStrategies,
          },
          {
            path: contentPaths.resourcesManagement(),
            element: <Resources isManagementPage />,
            permittedRoles: contentPermissions.resourcesManagement,
          },
          {
            path: contentPaths.resources(),
            element: <Resources />,
            permittedRoles: contentPermissions.resources,
          },
          {
            path: contentPaths.resources('downloadable'),
            element: (
              <Resources defaultTab={ResourceTabMenu.downloadableResources} />
            ),
            permittedRoles: contentPermissions.resources,
          },
          {
            path: contentPaths.resources('online'),
            element: <Resources defaultTab={ResourceTabMenu.onlineResources} />,
            permittedRoles: contentPermissions.resources,
          },
          {
            path: contentPaths.masterDataManagement(),
            element: <MasterDataManagement />,
            permittedRoles: contentPermissions.masterDataManagement,
          },
          {
            path: contentPaths.schools(),
            element: <Schools />,
            permittedRoles: contentPermissions.schoolManagement,
          },
          {
            path: contentPaths.schoolUsers(),
            element: <SchoolUsers />,
            permittedRoles: contentPermissions.schoolUsers,
          },
          {
            path: contentPaths.systemUsers(),
            element: <SystemUsers />,
            permittedRoles: contentPermissions.systemUsers,
          },
          {
            path: contentPaths.userManagement(':userId'),
            element: <UserProfilePage />,
          },
          {
            path: contentPaths.contactUs(),
            element: <ContactUs />,
          },
          {
            path: contentPaths.disclaimer(),
            element: <Disclaimer />,
          },
          {
            path: contentPaths.notifications(),
            element: <Notifications />,
            permittedRoles: contentPermissions.notifications,
          },
          {
            path: '/',
            element: <DevOnlyPageGuard />,
            children: [
              {
                path: contentPaths.apiTests(),
                element: <ApiTestPage />,
              },
              {
                path: contentPaths.uiComponent(),
                element: <UiComponent />,
              },
            ],
          },
          {
            path: '*',
            element: <Navigate to="/" />,
          },
        ],
      },
    ],
    [],
  )

  const permittedRoutes = useMemo(
    () =>
      (routeItems?.map((it) => ({
        ...it,
        children: [
          ...(it.children?.map((children) => ({
            ...children,
            element:
              children.permittedRoles == null ||
              children.permittedRoles.some(
                (role) => role == userProfileStore.userProfile?.role?.label,
              ) ? (
                children.element
              ) : (
                <ForbiddenPage />
              ),
          })) ?? []),
        ],
      })) ?? []) as RouteObject[],
    [routeItems, userProfileStore.userProfile?.role?.label],
  )

  return permittedRoutes
}
