import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  OutstandingLessonPlanRequestQueryParameters,
  OutstandingLessonPlanRequestResponsePayload,
} from './lesson-plan-requests-api.types'

const PATH = '/lesson-plan-requests'

export function getOutstandingLessonPlanRequests(
  param?: OutstandingLessonPlanRequestQueryParameters,
) {
  return ApiCore.primary.get<OutstandingLessonPlanRequestResponsePayload>(
    `${PATH}/outstanding`,
    {
      param: {
        ...param,
        sort: convertSortParameters(param),
      },
    },
  )
}
