import {
  CheckboxProps,
  FormControlLabel,
  Checkbox as MuiCheckbox,
  FormHelperText,
} from '@mui/material'
import { memo, ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { Svg } from '../../../assets'

type Props = CheckboxProps & {
  label?: ReactNode
  disabled?: boolean
  checkboxSize?: 'small' | 'medium'
  helperText?: string
}

const StyledFormHelperText = styled(FormHelperText)<{ marginLeft?: number }>`
  margin-left: ${({ marginLeft }) => marginLeft ?? 0}px;
  margin-top: 0px;
  padding-left: 9px;
`

export const Checkbox = memo<Props>(
  ({ label, disabled, checkboxSize, helperText, ...props }) => {
    const iconSize = useMemo(
      () => (checkboxSize === 'small' ? 25 : 30),
      [checkboxSize],
    )
    return (
      <>
        <FormControlLabel
          label={label}
          disabled={disabled}
          control={
            <MuiCheckbox
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              icon={<Svg.CheckboxUncheck height={iconSize} width={iconSize} />}
              checkedIcon={
                <Svg.CheckboxChecked height={iconSize} width={iconSize} />
              }
              size={checkboxSize}
            />
          }
        />
        {helperText == null ? (
          <></>
        ) : (
          <StyledFormHelperText marginLeft={iconSize}>
            {helperText}
          </StyledFormHelperText>
        )}
      </>
    )
  },
)
