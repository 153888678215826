import { Box, CircularProgress } from '@mui/material'
import { memo } from 'react'

export const Spinner = memo(() => {
  return (
    <Box>
      <CircularProgress />
    </Box>
  )
})
