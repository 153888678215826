import { ApiCore } from '@polyu-dip/api-core'
import { convertSortParameters } from '../base'
import {
  DiStrategyClassesQueryParameters,
  DiStrategyClassesResponsePayload,
  DiStrategyClassResponsePayload,
  PostDiStrategyClassPayload,
  PutDiStrategyClassPayload,
} from './di-strategy-classes-api.type'

const PATH = '/di-strategy-classes'

export function getDiStrategyClasses(param?: DiStrategyClassesQueryParameters) {
  return ApiCore.primary.get<DiStrategyClassesResponsePayload>(PATH, {
    param: {
      ...param,
      sort: convertSortParameters(param),
    },
  })
}

export function postDiStrategyClassesBulk(
  payloads: PostDiStrategyClassPayload[],
) {
  return ApiCore.primary.post<DiStrategyClassResponsePayload[]>(
    `${PATH}/bulk`,
    {
      body: payloads,
    },
  )
}

export function putDiStrategyClassById(
  id: string,
  payload: PutDiStrategyClassPayload,
) {
  return ApiCore.primary.put<DiStrategyClassResponsePayload>(`${PATH}/${id}`, {
    body: payload,
  })
}
