import { Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useLessonPlanDetail } from '../use-lesson-plan-detail-context'
import { Tag } from '../../../components'

export const ExpectedDifficulty = observer(() => {
  const { t } = useTranslation()
  const { lessonPlan } = useLessonPlanDetail()

  return (
    <Grid container direction="column" mt={4} mb={4}>
      <Grid item mb={4}>
        <Typography fontWeight={600}>
          {t('lessonPlan.detail.expectedDifficulty')}
        </Typography>
      </Grid>
      {lessonPlan.expectedDifficulty != null && (
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Tag label={lessonPlan.expectedDifficulty} fontWeight={600} />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
})
