import { delay } from '@polyu-dip/utilities'
import {
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { Dialog } from '../dialog'
import { Spinner } from '../spinner'
import { Snackbar } from '../snackbar'
import { DialogOption, OverlayContext, SnackbarOption } from './overlay-context'

type Props = PropsWithChildren<{}>

const TOAST_TIMEOUT = 8000

const SpinnerModal = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palettes.general.greyTransparent};

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: ${({ theme }) => theme.spacings.general[5]}px;

  align-items: center;
  justify-content: center;
  z-index: 100000;
`

const SpinnerContainer = styled.div`
  background-color: ${({ theme }) => theme.palettes.general.white};
  align-self: center;
  padding: ${({ theme }) => theme.spacings.general[6]}px;
  border-radius: ${({ theme }) => theme.spacings.general[2]}px;
`

export const OverlayProvider = memo<Props>(({ children }) => {
  const [dialog, setDialog] = useState<DialogOption<any> | undefined>()
  const [dialogVisible, setDialogVisible] = useState<boolean>(false)
  const [spinnerVisible, setSpinnerVisible] = useState(false)

  const spinnerCountRef = useRef(0)
  const dialogResolveRef = useRef<(resolution: any) => void>()

  const showDialog = useCallback(function <T>(dialogOption: DialogOption<T>) {
    if (dialogResolveRef.current != null) dialogResolveRef.current(undefined)

    setDialog(dialogOption)
    setDialogVisible(true)
    return new Promise<T | undefined>((resolve) => {
      dialogResolveRef.current = resolve
    })
  }, [])

  const showSpinner = useCallback(() => {
    if (spinnerCountRef.current === 0) {
      setSpinnerVisible(true)
    }
    spinnerCountRef.current = spinnerCountRef.current + 1
  }, [])

  const hideSpinner = useCallback(() => {
    if (spinnerCountRef.current === 0) {
      console.warn(
        'spinner already hidden. Check your code for double hiding. Ignore',
      )
    }
    spinnerCountRef.current = spinnerCountRef.current - 1
    if (spinnerCountRef.current === 0) {
      setSpinnerVisible(false)
    }
  }, [])

  const currentDisplay = useMemo(() => {
    if (dialog != null && dialogVisible) return 'dialog'
    if (spinnerVisible) return 'spinner'
    return undefined
  }, [dialog, dialogVisible, spinnerVisible])

  const handleDialogClose = useCallback((value: any) => {
    dialogResolveRef.current?.(value)
    dialogResolveRef.current = undefined
    setDialogVisible(false)
  }, [])

  const [snackbarOption, setSnackbarOption] = useState<
    SnackbarOption | undefined
  >()

  const snackbarCounterRef = useRef(0)

  const showSnackbar = useCallback(
    async (opts: SnackbarOption & { timeout?: number | 'inf' }) => {
      setSnackbarOption(opts)

      if (opts.timeout === 'inf') return

      const cnt = snackbarCounterRef.current + 1
      snackbarCounterRef.current = cnt

      await delay(opts.timeout ?? TOAST_TIMEOUT)

      if (snackbarCounterRef.current !== cnt) return // avoid dismissing any overriding snackbar
      setSnackbarOption(undefined)
    },
    [],
  )

  return (
    <OverlayContext.Provider
      value={{
        currentDisplay,
        showDialog,
        showSpinner,
        hideSpinner,
        showSnackbar,
      }}
    >
      {children}

      {currentDisplay == 'dialog' && dialog != null && (
        <Dialog {...dialog} onSelected={handleDialogClose} />
      )}

      {currentDisplay === 'spinner' && (
        <SpinnerModal>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </SpinnerModal>
      )}

      {snackbarOption != null && <Snackbar {...snackbarOption} />}
    </OverlayContext.Provider>
  )
})

export const useOverlay = () => useContext(OverlayContext)
